<div>
  <div class="tile is-ancestor admin-tile">
    <div class="tile is-3 is-parent is-vertical" [mcCanNavigate]="'ACTIVITY_STUDY_CREATE'" displayType="hide">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar green"></div>
        <p class="title is-5 has-text-weight-bold">Study Builder</p>
        <div>
          <button type="button" (click)="createNewStudy()" class="button is-primary  is-radiusless">
            <span class="icon ">
              <i class="fas fa-plus " aria-hidden="true"></i>
            </span>
            <span>Create New</span>
          </button>
        </div>
      </div>
    </div>
    <!-- /.study-create-half tile -->

    <div class="tile is-3 is-parent is-vertical " [mcCanNavigate]="'ACTIVITY_NOTIFICATION_SETUP'" displayType="hide">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar orange"></div>
        <p class="title is-5 has-text-weight-bold">Group Notifications</p>
        <div>
          <button type="button" (click)="manageNotifications()" class="button is-primary  is-radiusless">
            <span class="icon ">
              <i class="fas fa-bell" aria-hidden="true"></i>
            </span>
            <span>Manage</span>
          </button>
        </div>
      </div>
    </div>

    <!-- /.manage-notifications-half tile -->



    <div class="tile is-3 is-parent is-vertical "  [mcCanNavigate]="'ACTIVITY_REPORTS'"  displayType="hide">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar orange"></div>
        <p class="title is-5 has-text-weight-bold">Reports</p>
        <div>
          <button type="button" (click)="viewReports()" class="button is-primary  is-radiusless">
            <span class="icon ">
              <i class="fas fa-bell" aria-hidden="true"></i>
            </span>
            <span>Select Report</span>
          </button>
        </div>
      </div>
    </div><!-- /Report tile [mcCanNavigate]="'ACTIVITY_REPORTS'"-->


    <div class="tile is-3 is-parent is-vertical "  [mcCanNavigate]="'ACTIVITY_STUDY_TEMPLATE'"  displayType="hide">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar orange"></div>
        <p class="title is-5 has-text-weight-bold">Study Templates</p>
        <div>
          <button type="button" (click)="manageStudyTemplates()" class="button is-primary  is-radiusless">
            <span class="icon ">
              <i class="fas fa-clipboard" aria-hidden="true"></i>
            </span>
            <span>Manage</span>
          </button>
        </div>
      </div>
    </div><!-- /Study Templates tile [mcCanNavigate]="'ACTIVITY_STUDY_TEMPLATES'"-->


  </div>
  
  <div class="tile is-ancestor admin-tile">
    <div class="tile is-4 is-parent" [mcCanNavigate]="'ACTIVITY_HOME_TILE_STUDY_ADMIN'" displayType="hide">
      <div class="tile is-child box is-radiusless">
        <div class="color-bar green"></div>
        <p class="title is-5 has-text-weight-bold">Studies</p>
        <p class="title is-1 has-text-weight-bold is-spaced">{{underConstructionCount}}</p>
        <p class="subtitle is-6">under construction</p>
        <button type="button" (click)="viewStudiesUnderConstruction()" class="button is-primary is-radiusless">
          <span class="icon is-small"><i class="fas fa-caret-up" aria-hidden="true"></i>
          </span> <span>View Studies</span>
        </button>
      </div>
    </div> <!-- /.view-studies tile -->
    <div class="tile is-4 is-parent" [mcCanNavigate]="'ACTIVITY_HOME_TILE_STUDY'" displayType="hide"> 
      <div class="tile is-child box is-radiusless">
        <div class="color-bar green"></div>
        <p class="title is-5 has-text-weight-bold">Studies</p>
        <p class="title is-1 has-text-weight-bold is-spaced">{{totalStudies}}</p>
        <p class="subtitle is-6">available studies</p>
        <button type="button" (click)="viewStudies()" class="button is-primary is-radiusless">
          <span class="icon is-small"><i class="fas fa-caret-up" aria-hidden="true"></i>
          </span> <span>View Studies</span>
        </button>
      </div>
    </div> <!-- /.view-patients tile -->
     <div *ngIf="patientCount>0" class="tile is-4 is-parent" [mcCanNavigate]="'ACTIVITY_HOME_TILE_PATIENT'" displayType="hide">
      <div class="tile is-child box is-radiusless">
        <div class="color-bar peacock-blue"></div>
          <p class="title is-5 has-text-weight-bold">{{patientOrSubjectTitle}}</p>    
        <p class="title is-1 has-text-weight-bold is-spaced">{{patientCount}}</p>
        <p class="subtitle is-6">in progress registrations</p>
        <button type="button" (click)="viewPatients()" class="button is-primary is-radiusless">
          <span class="icon is-small"><i class="fas fa-user" aria-hidden="true"></i>
          </span> <span>View {{patientOrSubjectTitle}}</span>
          
        </button>
      </div>
    </div> <!-- /.view-patients tile -->


    <div class="tile is-3 is-parent" [mcCanNavigate]="'ACTIVITY_NOTIFICATION_RECENT'" displayType="hide">
      <div class="tile is-child box is-radiusless">
        <div class="color-bar red"></div>
        <p class="title is-5 has-text-weight-bold">Notifications</p>
        <!-- <p class="title is-1 has-text-weight-bold is-spaced">{{notificationsCount}}</p> -->
        <!-- <p class="subtitle is-6">recent notifications</p> -->
        <div class = "button-spacing">
        <button type="button" (click)="viewNotifications()" class="button title is-primary is-radiusless">
          <span class="icon is-small">
            <i class="fas fa-bell" aria-hidden="true"></i>
          </span>
          <span>Recent Notifications</span>
        </button>
        <button type="button" (click)="searchNotificationLanding()" class="button title is-primary is-radiusless">
          <span class="icon is-small">
            <i class="fas fa-search" aria-hidden="true"></i>
          </span>
          <span>Search Notifications</span>
        </button>
      </div>
      </div>
    </div>
  </div>
   <!-- /.view-notifications tile -->


</div>

