 <aside class="menu sidebarStyle">
  <ul class="menu-list">
    <li class="addBarStyle" *ngFor="let sectionItem of sectionLinkItems; let i = index">
      <a class="{{sectionItem.active}} sectionLink" (click)="sideBarNavScrollSection(sectionItem.linkID)"><i class="fa fa-circle-o menuLink" aria-hidden="true">
      <i class="fa fa-circle smallCircle" aria-hidden="true"></i>
      </i>
        <span>{{sectionItem.title}}</span></a>
    </li>
  </ul>
</aside>
