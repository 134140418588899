<div>
<div class="search-form">
    <div class="field is-horizontal is-narrow">
        <div class="search-label">
            <span class="" > Search by: </span>
        </div>
        <div class="field-body">
            <div class="field is-grouped">
                <div class="control has-min-right-margin ">
                    <div class="select ">
                        <select class="select is-search-dropdown" 
                        name="selectSearchType" (change)="searchTypeChanged()"
                         [(ngModel)]="protocolSearch.searchType">
                            <option *ngFor="let item of searchTypes"
                             [ngValue]="item.listValue">
                                {{item.listLabel}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="field is-normal">
                    <span class="icon is-left help-icon-placement" (click)="toggleHelp()">
                        <i class="far fa-question-circle"></i>
                    </span>
                </div>

                <div class="field has-search-criteria">
                    <p class="control is-expanded ">
                        <input class="input is-criteria-text" type="text" type="search"
                        (keyup.enter)="searchClick()"
                        placeholder="{{ getLabelForSelection()}}" name="searchTerm"
                            [(ngModel)]="protocolSearch.searchTerm" />
                    </p>
                </div>
                <div class="has-search-button">
                    <button [disabled]="isInvalid()" type="button"
                    (keyup.enter)="searchClick()"
                     (click)="searchClick()" class="button is-primary is-radiusless">
                        <span class="icon is-small">
                            <i class="fas fa-search" aria-hidden="true"></i>
                        </span>
                        <span>Search</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="field is-horizontal columns ">
        <div class="field-label is-normal column ">
            <div class="help-text-display" *ngIf="shouldShowHelp">
                <div class="notification is-link has-text-left">
                    <button class="delete is-radiusless" (click)="toggleHelp()"></button> 
                    <div *ngIf="helpTypeProtocol">
                            Search by full or part study references of a study
                    </div>
                    <div *ngIf="helpTypeSubject">
                            Search by full or part <span *ngIf="helpTypeSubject">subject</span> <span *ngIf="!helpTypeSubject">patient</span> ID
                     </div>
                     <div *ngIf="helpTypeTrackingNumber">
                        Search by full tracking number of a study
                    </div>
                     
                </div>
            </div>
        </div>
    </div>
</div>
</div>