import { PossibleValuesHierarchy } from "./possible-values-hierarchy";

export class PaymentMethod {
  constructor(
    public studyId: number | null,
    public studyTemplateId: number | null,
    public studyPaymentClassificationId: number | null,
    public paymentMethodClassificationId: number | null,
    public required: boolean | null,
    public tupleVersion: number | null,
    public possibleValues?: PossibleValuesHierarchy[] | null
) { }
}
