import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { AppConfigService } from 'app/app-config/app-config.service';
import { RuntimeConfig } from 'app/app-config/runtime-config';
import { share } from 'rxjs';
import { AppModule } from './app/app.module';

const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
const promise
  = httpClient.get(window.location.origin + '/' + AppConfigService.jsonFile)
    .pipe(share())
    .toPromise();
let configs: RuntimeConfig;

promise.then((value: any): void => {
  // Since the protectedResourceMap comes in as an object and not a map, make it a map
  const originalProtectedResourceMap = value.msalConfig.protectedResourceMap as any;
  value.msalConfig.protectedResourceMap = new Map<string, string[]>();
  for (const key in originalProtectedResourceMap) {
    if (Object.prototype.hasOwnProperty.call(originalProtectedResourceMap, key)) {
      const element = originalProtectedResourceMap[key];
      value.msalConfig.protectedResourceMap.set(key, element);
    }
  }

  configs = new RuntimeConfig(
    value.version,
    value.production,
    value.envName,
    value.serviceUrl,
    value.serviceUrlv2,
    value.logoutUrl,
    value.msalConfig
  );

  AppConfigService.setConfigs(configs);

  if (configs.production) {
    enableProdMode();
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
},
  (error) => {
    console.error('Could not load configs', error);
  }
);
