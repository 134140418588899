import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroupNotificationComponent } from 'app/admin/group-notification/group-notification.component';
import { NotificationComponent } from 'app/admin/study-setup/notification/notification.component';
import { CanDeactivateGuard } from 'app/can-deactivate-guard.service';


const groupNotificationRoutes: Routes = [
  {
    path: '',
    component: GroupNotificationComponent,
    children: [
      {
        path: '',
        redirectTo: 'notification',
        pathMatch: 'full'
      },
      { path: 'notification', component: NotificationComponent, canDeactivate: [CanDeactivateGuard] },
      {
        path: 'notifications/:notificationId',
        loadChildren: () => import('app/admin/notification-setup/notification-setup.module').then(m => m.NotificationSetupModule),
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(groupNotificationRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class GroupNotificationRoutingModule { }
