<div class="edit-bar is-pulled-right">

  <div class="is-pulled-left">
    <a class="is-tooltip-info tooltip" data-tooltip="Add" (click)="actionClicked(ADD)">
      <span [ngClass]="{'disabled':isActionDisabled(ADD)}">
        <i class="fas fa-plus fa-lg"></i>
      </span>
    </a>
  </div>

  <div class="is-pulled-left">
    <a id="editSpan" class="is-tooltip-info tooltip" data-tooltip="Edit" (click)="actionClicked(EDIT)">
      <span [ngClass]="{'disabled':isActionDisabled(EDIT)}">
        <i class="fas fa-pencil-alt fa-lg"></i>
      </span>
    </a>
  </div>

  <div class="is-pulled-left" *ngIf="isActionDisplayed(INFO)">
    <a class="is-tooltip-info tooltip" data-tooltip="Info" (click)="actionClicked(INFO)">
      <span [ngClass]="{'disabled':isActionDisabled(INFO)}">
        <i class="fas fa-info fa-lg"></i>
      </span>
    </a>
  </div>

  <div class="is-pulled-left" *ngIf="isActionDisplayed(FILTER)">
    <a class="is-tooltip-info tooltip" data-tooltip="Filter" (click)="actionClicked(FILTER)">
      <span [ngClass]="{'disabled':isActionDisabled(FILTER)}">
        <i class="fas fa-filter fa-lg"></i>
      </span>
    </a>
  </div>
  <div class="is-pulled-left" *ngIf="isActionDisplayed(UP)">
    <a class="is-tooltip-info tooltip" data-tooltip="Move Up" (click)="actionClicked(UP)">
      <span [ngClass]="{'disabled':isActionDisabled(UP)}">
        <i class="fas fa-arrow-up fa-lg"></i>
      </span>
    </a>
  </div>

  <div class="is-pulled-left" *ngIf="isActionDisplayed(DOWN)">
    <a class="is-tooltip-info tooltip" data-tooltip="Move Down" (click)="actionClicked(DOWN)">
      <span [ngClass]="{'disabled':isActionDisabled(DOWN)}">
        <i class="fas fa-arrow-down fa-lg"></i>
      </span>
    </a>
  </div>

  <div class="is-pulled-left">
    <a class="is-tooltip-info tooltip" data-tooltip="Delete" (click)="actionClicked(DELETE)">
      <span [ngClass]="{'disabled':isActionDisabled(DELETE)}">
        <i class="fas fa-trash-alt fa-lg"></i>
      </span>
    </a>
  </div>
</div>