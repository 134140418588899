export class ModalDialogArg {
    constructor(
        /**
         * primary, info, warning, danger,success, custom-save
         */
         public dialogType: string,
         /**
          * Cancel, StudySaveConfirm, VelosStudy data
          */
         public contentType: string,

         /**
          * Variable pipe "|" delimited data
          * e.g. StudyNumber:1234 | StudyId:890
          */
         public variableData: string | null = '',

         public displayName: string  = 'Landing Page',

         /**
          * if any custom display message should be displayed
          * the override the static content for the dialog
          */
         public displayMessage?: string
    ) {}
}
