import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable, } from '@angular/core';
import { Code } from 'app/common/model/code';
import { Observable } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../../app-config/app-config.module';
import { StudySignOffElement } from '../model/study-sign-off-element';
import { StudySignOffElementHistory } from '../model/study-sign-off-element-history';
import { BaseService } from './base-service';


@Injectable()
export class StudySignOffService extends BaseService {

    http: HttpClient;
    config: AppConfig;

    /**
    *  Gets url-pattern from config file.
    */
    serviceUrlPrefix: string;


    constructor(http: HttpClient,
        @Inject(APP_CONFIG) config: AppConfig,
        componentFactoryResolver: ComponentFactoryResolver
    ) {
        super(componentFactoryResolver);
        this.config = config;
        this.http = http;
        this.serviceUrlPrefix = config.apiEndpoint;
    }

    /**
     * Returns the current status of the sign off as a whole
     * @param studyId current studyId
     * @returns the combined status of all sign off elements
     */
    public getStudySignOffStatus(studyId: number): Observable<string> {
        const url = 'studies/' + studyId + '/sign-off/status';
        return this.http.get(this.serviceUrlPrefix + url, { responseType: 'text' });
    }

    /**
     * Saves the given StudySignOffElement to the DataBase
     * @param studyId Current Study Id
     * @param studySignOffElement StudySignOffElement object being added or updated
     * @returns Updated study sign off element
     */
    public saveStudySignOffElementStatus(studyId: number, studySignOffElement: StudySignOffElement): Observable<StudySignOffElement> {
        const url: string = `studies/${studyId}/sign-off/element-status`;
        return this.http.put<StudySignOffElement>(this.serviceUrlPrefix + url, studySignOffElement);
    }

    /**
    * Get Study sign-off elements
    * 
    * @param studyId 
    * @returns 
    */
    public getStudySignOffElements(studyId: number): Observable<StudySignOffElement[]> {
        return this.http.get<StudySignOffElement[]>(this.serviceUrlPrefix + 'studies/' + studyId + '/sign-off/elements');
    }

    /**
     * Saves the updated comment
     * @param studyId Current Study ID
     * @param studySignOffElement StudySignOffElement object having its comment updated
     * @returns Updated study sign off element
     */
    public addStudySignOffElementComment(studyId: number, studySignOffElement: StudySignOffElement): Observable<StudySignOffElement> {
        const url: string = `studies/${studyId}/sign-off/element-status/comment`;
        return this.http.put<StudySignOffElement>(this.serviceUrlPrefix + url, studySignOffElement);
    }

    /**
     * Get Study sign-off status list
     * 
     * @param studyId 
     * @returns 
    */
    public getStudySignOffStatusList(): Observable<Code[]> {
        return this.http.get<Code[]>(this.serviceUrlPrefix + 'configuration/study-sign-off-status-list');
    }

    /**
     * Get Study sign-off element history
     * 
     * @param studyId 
     * @returns 
     */
    public getStudySignOffElementHistory(studyId: number, attributeElementId: number, modNumber?: number): Observable<StudySignOffElementHistory[]> {
        let params;
        if (modNumber) {
            params = {
                'modNumber': modNumber
            };
        }

        return this.http.get<StudySignOffElementHistory[]>(this.serviceUrlPrefix + 'studies/' + studyId + '/sign-off/element-status/' + attributeElementId + '/history', { params: params });
    }

    public getStudySignOffElementsApprovalSummaryHistory(studyId: number): Observable<StudySignOffElementHistory[]> {
        return this.http.get<StudySignOffElementHistory[]>(this.serviceUrlPrefix + 'studies/' + studyId + '/sign-off/elements/approval-summary-history');
    }

    /**
     * Get signOff elements
     */
    public getSignOffElements(): Observable<Code[]> {
        return this.http.get<Code[]>(this.serviceUrlPrefix + 'configuration/sign-off-elements');
    }

    /**
     * Deletes the given study sign off element
     */
    deleteSignOffElement(studyId: number, studySignOffElement: StudySignOffElement): Observable<void> {
        return this.http.delete<void>(`${this.serviceUrlPrefix}studies/${studyId}/sign-off/event/${studySignOffElement.attributeElement.id}/mod/${studySignOffElement.modNumber}`);
    }
}
