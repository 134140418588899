import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app-config/app-config.module';
import { ApplicationHistory } from '../model/application-history';
import { Code } from '../model/code';
import { Form } from '../model/form';
import { ReportAttributeRequest } from '../model/report-attribute-request';
import { ReportStudyAccrual } from '../model/report-study-accual';
import { SchemaReportCriteria } from '../model/schema-report-criteria';
import { StudyNetworkReportCriteria } from '../model/study-network-report-criteria';
import { BaseService } from './base-service';

@Injectable()
export class ReportsService extends BaseService {
  http: HttpClient;
  config: AppConfig;
  serviceUrlPrefix: string;

  // constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }
  constructor(
    http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super(componentFactoryResolver);
    this.config = config;
    this.http = http;
    this.serviceUrlPrefix = config.apiEndpoint;
  }
  /**
   * Returns Study Accrual Report data
   * @param studyID The get the study details
   */
  public getStudyAccrualReport(
    studyID: string,
  ): Observable<ReportStudyAccrual> {
    return this.http.get<ReportStudyAccrual>(
      this.serviceUrlPrefix + 'reports/study/' + studyID + '/accrual-totals',
    );
  }
  /**
   * This will return the string that contains the log summary data.
   * @param   studyRef
   * @returns Observable - return the plain text format so we can export the string into CSV.
   */
  public getRandomizationLogSummary(studyRef: string): Observable<string> {
    const options = { responseType: 'text' as 'text' };
    return this.http.get(
      this.serviceUrlPrefix +
      'reports/study/' +
      studyRef +
      '/randomization-log/summary',
      options,
    );
  }
  /**
   * This will return the string that contains the log details
   * @param   studyRef
   * @returns Observable - return the plain text format.
   */
  public getRandomizationLogDetail(studyRef: string): Observable<string> {
    const options = { responseType: 'text' as 'text' };
    return this.http.get(
      this.serviceUrlPrefix +
      'reports/study/' +
      studyRef +
      '/randomization-log/details',
      options,
    );
  }

  /**
   * Returns data dictionary (Pform() data for the Data Dictionary Report tile
   * @param   studyRef
   * @returns Observable - return the PDF format.
   */
  public getDataDictionaryReport(studyRef: string): Observable<Blob> {
    return this.http.get(
      this.serviceUrlPrefix + 'reports/study/' + studyRef + '/data-dictionary',
      {
        responseType: 'blob',
      },
    );
  }

  /**
   * Returns SubjectRegistrationDataExtract report for the Subject Registration Data  Report tile
   * @param  studyRef
   * @returns Observable - return the xsls format.
   */
  public getSubjectRegistrationDataExtract(studyRef: string): Observable<any> {
    const url = `${this.serviceUrlPrefix}reports/study/${studyRef}/subject-registration-data-extract`;
    return this.http.get(url, {
      observe: 'response',
      responseType: 'arraybuffer',
    });

    // For testing:
    // let simulation = {numberOfLocations: "1", numberOfPhysicians: "1"};
    // const url = `${this.serviceUrlPrefix}studies/9208/events/2058/simulations/template`;
    // return this.http.post(url, simulation, {
    //   observe: "response",
    //   responseType: "arraybuffer",
    // });

    // let errorResult = {"code":400,"status":"Bad Request","message":"Study does not exist"};
    // return of(errorResult);
  }

  /**
   * Returns Difference report for the Difference Report tile
   *
   * @param  studyId, oldFormId, newFormId
   * @returns Observable - return the xsls format.
   */
  public getDifferenceReport(
    studyId: number,
    oldFormId: number,
    newFormId: number,
  ): Observable<any> {
    const url = `${this.serviceUrlPrefix}reports/study/${studyId}/forms/old/${oldFormId}/new/${newFormId}/differences`;
    return this.http.get(url, {
      observe: 'response',
      responseType: 'arraybuffer',
    });
  }

  /**
   * Returns FullStudyUnblindReport report for the Full study Unblind Report tile
   * @param  studyRef
   * @returns Observable - return the xsls format.
   */
  public getFullStudyUnblindReport(studyRef: string): Observable<any> {
    const url = `${this.serviceUrlPrefix}reports/study/${studyRef}/full-study-unblind`;
    return this.http.get(url, {
      observe: 'response',
      responseType: 'arraybuffer',
    });
  }

  /**
   * Returns application history for a given source
   * @param   source
   * @returns Observable - return array of ApplicationHistory records.
   */
  public getApplicationHistory(
    source: string,
  ): Observable<ApplicationHistory[]> {
    const url =
      this.serviceUrlPrefix + 'application-history/info?source=' + source;
    console.log('getApplicationHistory url ', url);
    return this.http.get<ApplicationHistory[]>(url);

    // For testing:
    // let recs: ApplicationHistory[] = [
    // {
    //     "applicationHistoryId": 1,
    //     "contentText": "abc",
    //     "startDate": new Date(1543259313951),
    //     "source": source,
    //     "contentType": "HISTORY",
    //     "modifiedTs": new Date(1543259313950),
    //     "modifiedById": "m0000000",
    //     "tupleVersion": 1
    // },
    // {
    //   "applicationHistoryId": 2,
    //   "contentText": "Opening",
    //   "startDate": new Date(1543259313950),
    //   "source": source,
    //   "contentType": "OPENING",
    //   "modifiedTs": new Date(1543259313950),
    //   "modifiedById": "m0000000",
    //   "tupleVersion": 1
    // },
    // {
    //   "applicationHistoryId": 2,
    //   "contentText": "Closing.",
    //   "startDate": new Date(1543259313950),
    //   "source": source,
    //   "contentType": "CLOSING",
    //   "modifiedTs": new Date(1543259313950),
    //   "modifiedById": "m0000000",
    //   "tupleVersion": 1
    // },
    // {
    //     "applicationHistoryId": 2,
    //     "contentText": "def",
    //     "startDate": new Date(1543259313950),
    //     "source": source,
    //     "contentType": "HISTORY",
    //     "modifiedTs": new Date(1543259313950),
    //     "modifiedById": "m0000000",
    //     "tupleVersion": 1
    // }
    // ];
    // return of(recs);
  }

  /**
   * Returns all the forms for a study protocol number, regardless of form type, status, or effective date

   * @param studyRefNum The studyRefNum whose forms are to be retrieved
   */
  public getStudyFormsByProtocolNumber(
    studyRefNum: string,
  ): Observable<Array<Form>> {
    // const url = 'studies/' + 11766 + '/forms';
    const url = 'reports/study/' + studyRefNum + '/forms';
    return this.http.get<Array<Form>>(`${this.config.apiEndpoint}` + url);
  }

  /**
   * Returns report for the Accrual by Site Report tile
   * @param   studyRef
   * @returns Observable - return the PDF format.
   */
  public getAccrualBySiteReport(studyRef: string): Observable<Blob> {
    return this.http.get(
      this.serviceUrlPrefix + 'reports/study/' + studyRef + '/accrual-by-site',
      {
        responseType: 'blob',
      },
    );
  }

  /**
   *
   * @returns Get Schema Events
   */
  public getSchemaEvents(): Observable<Array<Code>> {
    const url = 'configuration/schema-events';
    return this.http.get<Array<Code>>(`${this.config.apiEndpoint}` + url);
  }

  /**
   * call for schema report
   * @param schemaReportCriteria
   * @returns
   */
  public schemaSearchCriteria(
    schemaReportCriteria: SchemaReportCriteria,
  ): Observable<any> {
    return this.http.post(
      this.serviceUrlPrefix + 'reports/schema',
      schemaReportCriteria,
      {
        observe: 'response',
        responseType: 'arraybuffer',
      },
    );
  }

  /**
   * Returns report for the Accrual by Site Report tile
   * @param   studyRef
   * @returns Observable - return the PDF format.
   */
  public getStudyAttributeReport(
    attributeRequest: ReportAttributeRequest,
  ): Observable<any> {
    return this.http.post(
      this.serviceUrlPrefix + 'reports/study-attribute',
      attributeRequest,
      {
        observe: 'response',
        responseType: 'blob',
      },
    );
  }

  /**
   * Retrieves a list of study networks
   * @returns Observable - list of participating org
   */
  public getParticipatingOrgs(): Observable<Code[]> {
    return this.http.get<Code[]>(`${this.serviceUrlPrefix}study-networks`);
  }

  /**
   * Retrieves a list of study network statuses
   * @returns Observable - list of participating org statuses
   */
  public getParticipatingOrgStatuses(): Observable<Code[]> {
    return this.http.get<Code[]>(`${this.serviceUrlPrefix}study-networks/statuses`);
  }

  /**
   * Returns the study network report for the given criteria
   * @param studyNetworkReportCriteria 
   * @returns Observable - returns the study network report as an excel spreadsheet
   */
  public getStudyNetworkReport(studyNetworkReportCriteria: StudyNetworkReportCriteria): Observable<any> {
    return this.http.post(`${this.serviceUrlPrefix}reports/study-network`, studyNetworkReportCriteria, { observe: 'response', responseType: 'blob' });
  }
}
