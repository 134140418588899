import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../../app-config/app-config.module';
import { BaseService } from './base-service';

@Injectable()
export class ExportFileService extends BaseService {

    http: HttpClient ;
    config: AppConfig;
    serviceUrlPrefix: string;

    constructor(http: HttpClient, @Inject(APP_CONFIG) config: AppConfig, componentFactoryResolver: ComponentFactoryResolver) {

        super(componentFactoryResolver);

        this.config = config;
        this.http = http;
        this.serviceUrlPrefix = config.apiEndpoint;
      }

      public getPatientConfirmationPdf(caseEventId: number):  Observable<Blob> {
        return this.http.get(this.serviceUrlPrefix + 'patients/generate/pdf/' + caseEventId , {
          responseType: 'blob'
        });
       }

       public getSimStudyParameters(studyId: number, enrollmentStudySchemaEventId: number):  Observable<Blob> {
        return this.http.get(this.serviceUrlPrefix + 'studies/' + studyId + '/simulations/study-data/generate/pdf/'+ enrollmentStudySchemaEventId, {
          responseType: 'blob'
        });
       }
}
