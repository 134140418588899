import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { MsalConfig } from './runtime-config';

@Injectable({
  providedIn: 'root'
})
export class AppConfigLoaderService {

  private _version: string;
  private _production: boolean;
  private _envName: string;
  private _apiEndpoint: string;
  private _v2_apiEndpoint: string;
  private _coreEndPoint: string;
  private _logoutUrl: string;
  private _msalConfig: MsalConfig;

  /**
   * returns the instance of the production key
   */
  public get production(): boolean {
    if (this._production == undefined) {
      this._production = AppConfigService.runConfig.production;
    }
    return this._production;
  }

  /**
   * sets the instance of the production key
   */
  public set production(ep: boolean) {
    this._production = ep;
  }

  /**
    * returns the instance of the env key
    */
  public get envName(): string {
    if (this._envName == undefined) {
      this._envName = AppConfigService.runConfig.envName;
    }
    return this._envName;
  }

  /**
   * sets the instance of the env key
   */
  public set envName(ep: string) {
    this._envName = ep;
  }

  /**
    * returns the instance of the version key
    */
  public get version(): string {
    if (this._version == undefined) {
      this._version = AppConfigService.runConfig.version;
    }
    return this._version;
  }

  /**
   * sets the instance of the env key
   */
  public set version(ep: string) {
    this._version = ep;
  }


  /**
   * returns the instance of the apiEndPoint
   */
  public get apiEndpoint(): string {
    if (this._apiEndpoint == undefined) {
      this._apiEndpoint = AppConfigService.runConfig.serviceUrl;
    }
    return this._apiEndpoint;
  }

  /**
  * returns the instance of the apiEndPoint
  */
  public get v2_apiEndpoint(): string {
    if (this._v2_apiEndpoint == undefined) {
      this._v2_apiEndpoint = AppConfigService.runConfig.serviceUrlv2;
    }
    return this._v2_apiEndpoint;
  }

  /**
  * sets the instance of the apiEndPoint
  */
  public set v2_apiEndpoint(ep: string) {
    this.v2_apiEndpoint = ep;
  }

  /**
   * sets the instance of the apiEndPoint
   */
  public set apiEndpoint(ep: string) {
    this._apiEndpoint = ep;
  }

  /**
  * returns the instance of the logout URL
  */
  public get logoutUrl(): string {
    if (this._logoutUrl == undefined) {
      this._logoutUrl = AppConfigService.runConfig.logoutUrl;
    }
    return this._logoutUrl;
  }

  /**
   * sets the instance of the logout URL
   */
  public set logoutUrl(ep: string) {
    this._logoutUrl = ep;
  }

  /**
   * returns the instance of MSAL config
   */
  public get msalConfig(): MsalConfig {
    if (this._msalConfig === undefined) {
      this._msalConfig = AppConfigService.runConfig.msalConfig;
    }
    return this._msalConfig;
  }

  /**
   * sets the instance of MSAL config
   */
  public set msalConfig(config: MsalConfig) {
    this._msalConfig = config;
  }

  constructor(private configLoader: AppConfigService) {
    // console.log("Configu loader ", configLoader, "servurl", configLoader.runConfig)
  }



}
