export class NotificationCode {

    constructor(
        public id: number,
        public value: string,
        public desc: string,
        public level?: string | null,
        public keyEventMappingId?: number,
      ) {}
}
