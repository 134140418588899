<section class="">  
    <div class="has-fixed-table-header form-table-container">    
        <table class="is-scroll-table header table is-striped is-fullwidth " ngClass="{{config.className || ''}}" 
        role="grid">
          <thead>
            <tr role="row">
              <ng-container *ngFor="let column of columns">
                <th *ngIf="column.display" class="table-header " [mcTableSorting]="config" [column]="column" title="{{column.title}}" (sortChanged)="onChangeTable($event)"
                  ngClass="{{column.className || ''}}">
                  <span> {{column.title}}</span>            
                  <span *ngIf="config && column.sort" class="sort is-text-centered">          
                    <span *ngIf="column.sort === 'desc'">                          
                        <i class="fas fa-sort-down"></i>
                    </span>
                    <span *ngIf="column.sort === 'asc'">                          
                      <i class="fas fa-sort-up"></i>
                    </span>               
                  </span>
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="showFilterRow">
              <ng-container *ngFor="let column of columns" >
              <td ngClass="{{column.className || ''}}" *ngIf="column.display" >
                <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" 
                  class="form-control filter-width"
                   (tableChanged)="onChangeTable(config)" />
              </td>
              </ng-container>
            </tr>
            <tr #orgRow *ngFor="let row of rows" (click)="selectOrganization(row)" 
                [ngClass]="{'is-selected': isOrganizationSelected(row)}"
                  id="{{row.orgNumber}}" >
              <td class="ctep-width">
                {{row.ctepId}}
              </td>
              <td class="org-width">
                {{row.orgNumber}}
              </td>
              <td class="abbrev-width">
                {{row.abrev}}
              </td>
              <td class="name-width">
                {{row.name}}
              </td>
             <!--  <td>
                {{row.type}}
              </td>
              <td>
                {{row.status}}
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <div class="">
        <div *ngIf="shouldShowPagination()">
          <res-table-paging [currentPage]="page" [totalItems]="data.length" [itemsPerPage]="itemsPerPage"
            [numPages]="numPages" (pageChanged)="onChangeTable(config, $event)">
          </res-table-paging>
        </div>
      </div>
  
</section>