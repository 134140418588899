import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/common/services/auth.service';
import { UserService } from 'app/common/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent implements OnInit, OnDestroy {
  subscriptionList: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((x) => {
      x.unsubscribe();
    });
  }

  ngOnInit(): void {
    if (this.userService.appUserEntity) {
      this.router.navigate(['home']);
    } else {
      this.subscriptionList.push(
        this.authService.acquireTokenPopup().subscribe(() => {
          this.userService.initializeUserData()
            .then(() => {
              this.router.navigate(['home']);
            })
            .catch(() => {
              this.router.navigate(["/not-authorized"], { skipLocationChange: true });
            });
        })
      );
    }
  }
}
