
/**
 * Class to hold information about the orientation and state of the preview panel
 * This would allow the preview panel and the host share the states and react
 */
export class PreviewPanelModel {
    constructor(
        public isVertical: boolean = false,
        public isCollapsed: boolean = true
    ) { }
}
