import { Component, OnInit, Input } from '@angular/core';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { Subject } from 'rxjs';
import { RedcapStudy } from '../model/redcap-study';
@Component({
  selector: 'mc-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit {

  /**
   * The input for building the component and UI
   */
  @Input() dialogArgType: ModalDialogArg ;
  @Input() redcapStudy: RedcapStudy | null = null;
 

  variableData: any = {};


 /**
  * This is the Subject (Queue) holding the boolean of the selected user response
  */
  actionSubject: Subject<boolean> = new Subject<boolean>();
  showModal = true;
  constructor() { }
/**
 * Initializes the argument object with default settings if nothing was submited.
 */
  ngOnInit() {
    if (this.dialogArgType == undefined || this.dialogArgType == null) {
      this.dialogArgType = new ModalDialogArg('modal-warn', 'Cancel')
    }
    this.initializeVariableData();
   // this.updateUIClass();
  }

  /**
   * Returns the class string that defines the styles for the modal body
   */
  modalBodyClass(): string {
    let classString: string;
  
    switch (true) {
      case this.isSimulation():
        classString = 'modal-simulation';
        break;
      case this.isRedcap():
        classString = 'modal-redcap';
        break;
      case this.isSaveSuccess():
        classString = 'modal-save';
        break;
      case this.isGenericYesNoWarn():
        classString = 'modal-generic-yes-no';
        break;
      case this.isGenericSaveSuccess():
        classString = 'modal-generic-save-success';
        break;
      case this.isCannotNavigateWithHtml():
        classString = 'modal-cancel-other';
        break;
      default:
        classString = 'modal-cancel';
    }
  
    return classString;
  }
  

  /**
   * Initialize the Variable data. If something was submitted the string is parsed
   * into appropriate objects for consumption on the View
   */
  initializeVariableData() {
    if (this.hasVariableData()) {
      const dataArray: Array<string> = this.dialogArgType.variableData.split('|');
      if (dataArray.length > 0) {
        dataArray.forEach(eachProp => {
          if (eachProp.indexOf (':') > 0) {
           const propKey: string = eachProp.substring(0, eachProp.indexOf (':'));
           const propValue: string = eachProp.substring(eachProp.indexOf (':')+1);
           this.variableData[propKey] = propValue;
          }
        });
      }
    }
  }


  /**
   * True if the args dialog provided variable data for display
   */
  hasVariableData (): boolean {
    return this.dialogArgType.variableData != undefined &&
      this.dialogArgType.variableData != null;
  }

  /**
   * True if the dialog should display content for the runsimulation page
   */
  isSimulation(): boolean {
    return this.dialogArgType.displayName && this.dialogArgType.displayName == 'Run Simulation';
  }

  isRedcap(): boolean {
    return this.redcapStudy != null;
  }

  /**
   * True if the dialog to be displayed is for prompting the user for unsaved changes
   */
  isCancel(): boolean {
    return this.dialogArgType.contentType == 'Cancel';
  }

  /**
   * True if the dialog should display content for the StudySave confirmation
   */
  isSaveSuccess(): boolean {
    return this.dialogArgType.contentType == 'Save';
  }

  /**
   * True if the dialog should display content for the StudySave confirmation
   */
  isCreateFailed(): boolean {
    return this.dialogArgType.contentType == 'CreateFailed';
  }

  /**
   * True if the modal should display the Velos study confirmation
   */
  isVelosStudy(): boolean {
    return (this.dialogArgType.contentType == 'VelosStudy') ;
  }

  isCustom(): boolean {
    return (this.dialogArgType.contentType == 'Custom') ;
  }

  /**
   * True if the modal should display the Delete confirmation
   */
  isDelete(): boolean {
    return (this.dialogArgType.contentType == 'ConfirmDelete');
  }


  /**
   * True if the dialog should display content for the HttpException
   */
  isGeneralException(): boolean {
    return (this.dialogArgType.contentType == 'GeneralException') ;
  }

/**
   * True if the dialog should display content for the Generic Save Success
   */
  isGenericSaveSuccess(): boolean {
     return (this.dialogArgType.contentType == 'GeneralSaveSuccess') ;
  }


/**
   * True if the dialog should display content for the Cannot Navigate action
   */
  isCannotNavigate(): boolean {
    return (this.dialogArgType.contentType == 'CannotNavigate') ;
  }

  /**
   * True if the dialog should display content for the Cannot Navigate action and message includes html tags
   */
  isCannotNavigateWithHtml(): boolean {
    return (this.dialogArgType.contentType == 'CannotNavigateWithHtml') ;
  }



  /**
   * True if the dialog should display a generic message passed in through variable Data
   */
  isGenericYesNoWarn(): boolean {
    return (this.dialogArgType.contentType == 'GenericYesNo') ;
  }


/**
 * This is the method wired to the button on the UI that returns true/false
 * based on which button was clicked.
 * @param value true/false response from the button clicked
 */
  action(value: boolean) {
    this.showModal = false;
    this.actionSubject.next(value);
    this.actionSubject.complete();
  }

}
