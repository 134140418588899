export class CalendarOptions {
    constructor(
        public startDate: Date | null,
        public dataFormat: string,
        public closeOnSelect: boolean,
        public parentComponent: any,
        // for callback functions
        public onSelect: any,
        public onOpen: any,
        public onClose: any
    ) {}
}
