<mc-header-bar [headerModel]="headerModel">
</mc-header-bar>

<div class="container is-fluid form-page-ct">
  <div class=""
    [ngClass]="{ 'switchToHorizontal': previewModelHome.isVertical,'is-vertical-open': previewModelHome.isCollapsed  }">
    <!-- <div  id="formSection"> -->
    <div id="mainContainer" [ngClass]="{'switchVertical columns': previewModelHome.isVertical }">
      <div [ngClass]="{ 'column is-main-section-vertical': previewModelHome.isVertical,
       'is-6' : isExpandedVertical(), 'is-11' : isCollapsedVertical() }" class="main-section" id="mainSection">

        <div class="search-section form-page" id="main-content">
          <form novalidate>
            <div class="columns is-marginless">
              <div class="column form-content is-12 ">
                <mc-search (searchSelectionEvent)="doSearch()">
                </mc-search>
                <mc-report-tiles (displayTypeSelectedEvent)="tileClicked($event)">

                </mc-report-tiles>
              </div>
            </div>
          </form>

        </div>
      </div>

      <div class="preview-section" id="previewSection" [ngClass]="{ 'togglePerviewOverlay' : isExpandedHorizontal(),                
       'is-6 preview-vertical-expanded' : isExpandedVertical(),
        'is-1' : isCollapsedVertical(),
       'column ': previewModelHome.isVertical }">
        <mc-preview [formTitle]="" [formCaption]="previewTitle" [previewModel]="previewModelHome" #previewSection>
          <div preview-data>
            <div>
              <mc-protocol-search-results (returnToSearchEvent)="returnToSearch()">
              </mc-protocol-search-results>
            </div>
            <mc-study-accrual-report-view *ngIf="displayStudyAccReport" [contentType]="contentType" [param]="param">
            </mc-study-accrual-report-view>
          </div>
        </mc-preview>
      </div>
    </div>
  </div>

  <div>
    <mc-email-modal></mc-email-modal>
  </div>


</div>