<article class="message error-message-block is-danger" *ngIf="showErrorSummary()">
    <div class="message-header">
      <p>Errors Detected!</p>
      <button class="delete" aria-label="delete" (click)="hideErrorSummary()" ></button>
    </div>
    <div class="message-body">
      <ul>
        <li *ngFor="let eMsg of errorMessages">
          <span>{{eMsg.message}} </span>
        </li>
      </ul>
    </div>
  </article>