import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mc-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrl: './logged-out.component.scss'
})
export class LoggedOutComponent {

  constructor(
    private router: Router
  ) { }

  login() {
    this.router.navigate(['auth']);
  }
}
