import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config/app-config.module';
import { Code } from 'app/common/model/code';
import { StudySchema } from 'app/common/model/study-schema';
import { Observable } from 'rxjs';

@Injectable()
export class SchemaManagementService {

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) { }

  /**
   * Get all schema versions for the passed study
   */
  public getSchemaVersions(studyId: number): Observable<StudySchema[]> {
    return this.http.get<StudySchema[]>(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema-version`));
  }

  /**
   * Creates a new schema for the passed study
   */
  public saveSchemaVersion(studyId: number, studySchema: StudySchema): Observable<StudySchema> {
    return this.http.post<StudySchema>(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema-version`), studySchema);
  }

  /**
   * Creates a copy of an existing schema version for the passed study
   */
  public versionExistingSchemaVersion(studyId: number, studySchema: StudySchema, studySchemaId: number): Observable<StudySchema> {
    return this.http.post<StudySchema>(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema-version/${studySchemaId}`), studySchema);
  }

  /**
   * Updates an existing schema version for the passed study (does not create a new version)
   */
  public updateSchemaVersion(studyId: number, studySchema: StudySchema, studySchemaId: number): Observable<StudySchema> {
    return this.http.put<StudySchema>(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema-version/${studySchemaId}`), studySchema);
  }

  /**
   * Deletes the passed schema version for the passed study
   */
  public deleteSchemaVersion(studyId: number, studySchemaId: number, tupleVersion: number) : Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
    return this.http.delete(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema-version/${studySchemaId}`), { headers: header, responseType: 'json' });
  }

  /**
   * Retrieves an array containing the schema build statuses (Validated or In-Development) 
   */
  public getSchemaBuildStatuses(): Observable<Code[]> {
    return this.http.get<Code[]>(encodeURI(`${this.config.apiEndpoint}configuration/schema-build-status`));
  }

  /**
   * Check for study configuration affected by deleted events
   * @param studyId 
   * @param studySchema 
   * @returns 
   */
  public checkForDeletedEventsInStudyConfiguration(studyId: number, studySchema: StudySchema): Observable<any> {
    return this.http.post<StudySchema>(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema-version/${studySchema.studySchemaId}/deleted-events`), studySchema);
  }
}
