import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateRange } from 'app/common/model/date-range';
import { NotificationSearchCriteria } from 'app/common/model/notification-search-criteria';
import { NotificationTileDetail } from 'app/common/model/notification-tile-detail';
import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { StudyServiceBase } from 'app/common/services/study-service-base.service';
import { ToastService } from 'app/common/services/toast.service';
import * as moment from 'moment';
import { throwError } from 'rxjs';


@Component({
  selector: 'mc-notification-tile-results',
  templateUrl: './notification-tile-results.component.html',
  styleUrls: ['./notification-tile-results.component.scss']
})
export class NotificationTileResultsComponent implements OnInit {

  @Input() isNotificationSearch: boolean= false;

  // event emitter for displaying notification email from preview pane on the home.component
  @Output()  emailDetailSelected = new  EventEmitter<NotificationTileDetail>();
 
  recentNotification: NotificationTileDetail[];
  notificationFound = false;
  selectedEmailLogId = 0;
  

  // Datatable headers
  public columns: Array<any> = [
    {
      title: 'Study #',
      name: 'studyReferenceNum',
      sort: false,
      display: true,
      filtering: false
    },
    {
      title: 'Subject Id',
      name: 'patientId',
      sort: false,
      display: true,
      filtering: false
    },
    {
      title: 'Notification Name',
      name: 'notificationName',
      sort: false,
      display: true,
      filtering: false
    },
    {
      title: 'Notification Type',
      name: 'notificationType',
      sort: false,
      display: true,
      filtering: false
    },
     {
      title: 'Email Link',
      name: 'emailLink',
      sort: false,
      display: true,
      filtering: false
    },
    {
      title: 'Date Sent',
      name: 'emailSentDate',
      sort: false,
      display: true,
      filtering: false
    }

  ];


  // /**
  //  * Reference to the child modal component
  //  */
  // @ViewChild(EmailModalComponent, { static: false })
  // private modalComponent: EmailModalComponent;

    /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: false,
    className: ['is-striped', 'is-bordered']
  };

  constructor(private studyLandingService: StudyServiceBase,
    private notificationDashboardService :NotificationDashboardService,
    private toast: ToastService ) { }


  public onChangeTable(config: any): any {
    // TODO not sure if we need to do sorting
    // 1/5/2022 - no sorting required on this page
  }

  emailDetails(row: NotificationTileDetail) {
    this.emailDetailSelected.emit(row);
  }

  /**
   * Call the backend endpoint to fetch the recent notifications
   */

  ngOnInit() {
    if (this.isNotificationSearch) {
      this.notificationDashboardService.notificationSearch.subscribe(response => {
        if (response) {
          this.studyLandingService.getNotificationSearch(response)
            .subscribe(results => {
              if (results.length > 0) {
                this.recentNotification = results;
                this.notificationFound = true;
              }else{
                this.recentNotification = [];
                this.notificationFound = false;
              }
              if (this.toast.getMessages().length > 0) {
                this.toast.messages.pop();
              }
            }, (err: any) => {
              this.recentNotification = [];
              this.notificationFound = false;
              if (err.status === 400) {
                if (err.error && err.error.message) {
                  const msg: string = err.error.message;
                  this.toast.messages.pop();
                  this.toast.sendMessage(msg, 'danger', null, true);
                }
              }
              throwError(err);
            });
        }
      });
    } else {
      //Reusing notifcaiton search endpoint to get recent notifcaitons for last 30 days
      const startDate = new Date(moment().subtract(30, 'days').calendar());
      const endDate = new Date(moment().format('MM/DD/YYYY'));
      const notificationSearchCriteria = new NotificationSearchCriteria("", "", null, "", new DateRange(startDate, endDate), "");
      this.studyLandingService.getNotificationSearch(notificationSearchCriteria).subscribe(results => {
          if (results.length > 0) {
            this.recentNotification = results;
            this.notificationFound = true;
          }
        });
    }
  }
}
