<div class="modal" [ngClass]="{'is-active': showModal}">
    <div class="modal-background"></div>
    <div class="modal-card">
        <section class="modal-card-body">
            <div *ngIf="notificationEmailDetail">
                <div class="columns">
                    <div class="column is-2 contains-label">
                        <label class="label"> From: </label>
                    </div>
                    <div class="column">
                        {{notificationEmailDetail.fromAddress}}
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-2 contains-label">
                        <label class="label"> To: </label>
                    </div>
                    <div class="column">
                        {{getRecipientsList()}}
                    </div>
                </div>
 
                <div class="columns">
                    <div class="column is-2 contains-label">
                        <label class="label"> Subject: </label>
                    </div>
                    <div class="column">
                        {{notificationEmailDetail.subject}}
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-2 contains-label">
                        <label class="label"> Body: </label>
                    </div>
                    <div class="column">
                        <!-- <span>{{notificationEmailDetail.body}}</span> -->
                        <div class="ql-editor" [innerHTML]="notificationEmailDetail.body|safeHtml"></div>
                    </div>
                </div>
        
            </div>

        </section>
        <footer class="modal-card-foot">
            <div class="is-centered footer-button ">
                <button class="button is-primary is-radiusless" 
                    (click)="closeModal()">
                    Close</button>
                </div>
        </footer>
    </div>
</div>
