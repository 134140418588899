<div class="is-vertical-panel " [ngClass]="{'is-collapsed': isCollapsed, 'is-expanded': !isCollapsed}">
  <div class="is-panel-container">

    <div class="is-pullout-tab">
      <span class="has-left-icon" (click)="isCollapsed= !isCollapsed">
        <span *ngIf="isCollapsed">
          <i class="fas fa-chevron-left fa-lg"></i>
        </span>
        <span *ngIf="!isCollapsed">
          <i class="fas fa-chevron-right fa-lg"></i>
        </span>
      </span>
    </div>
    <div class="pullout-content" [ngClass]="{'is-hidden': isCollapsed}">
      <!-- add the select attribute to ng-content -->
      <ng-content select="[pull-out-data]"></ng-content>
    </div>
  </div>
</div>