import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable, ViewContainerRef } from '@angular/core';
import { ConfirmActionComponent } from 'app/common/confirm-action/confirm-action.component';
import { CaseIntegrationStatus } from 'app/common/model/case-integration-status';
import { Code } from 'app/common/model/code';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { PatientApproval } from 'app/common/model/patient-approval';
import { PatientApprovalModel } from 'app/common/model/patient-approval-model';
import { PatientDoubleBlindTileData } from 'app/common/model/patient-double-blind-tile-data';
import { PatientEnrollmentCorrection } from 'app/common/model/patient-enrollment-correction';
import { PatientRegPreviewParam } from 'app/common/model/patient-reg-preview-param';
import { PatientRegistrationPreview } from 'app/common/model/patient-registration-preview';
import { PatientStatus } from 'app/common/model/patient-status';
import { PatientTransfer } from 'app/common/model/patient-transfer';
import { PatientTransferRequest } from 'app/common/model/patient-transfer-request';
import { PatientTransferTileData } from 'app/common/model/patient-transfer-tile-data';
import { PostProcessingHistoryRecord } from 'app/common/model/post-processing-history-record';
import { UnblindedInfo } from 'app/common/model/unblinded-info';
import { Observable, Subject } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../../app-config/app-config.module';
import { BasePatientService } from './base-patient-service';


@Injectable({
  providedIn: 'root'
})
export class PatientService extends BasePatientService {

  http: HttpClient ;
  config: AppConfig;
  serviceUrlPrefix: string;


  /**
   * Private observable subject to handle the Patient Reg Preview
   */
  private regPreviewSource = new Subject<PatientRegPreviewParam>();

  /**
   * Stream through which a user can subscribe to the PatientReg Preview
   */
  public regPreviewParams$ = this.regPreviewSource.asObservable();


  constructor(http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver) {
    super(http, config, componentFactoryResolver);

    this.config = config;
    this.http = http;
    this.serviceUrlPrefix = config.apiEndpoint;

  }

  /**
   * Allows Preview Params to be submitted to the queue for
   * subscribers to retrieve
   * @param patientRegPreviewParams Array of Preview Params that are submitted to the queue
   */
  public setRegPreviewParams(patientRegPreviewParams: PatientRegPreviewParam) {
    this.regPreviewSource.next(patientRegPreviewParams);
  }



  //  http://dev.reg-backend.mayo.edu/api/v1/patients/enrollment-event/form/121
  // patients/enrollment-event/status/121

  /**
   * Gets the Registration Status for the caseEventId
   * @param caseEventId The caseEventId for which the status is to be retrieved
   */
  getPatientRegistrationStatus(caseEventId: number): Observable<PatientStatus> {
    return this.http.get<PatientStatus>
      (this.serviceUrlPrefix + 'patients/enrollment-event/status/' + caseEventId);

  }

  /**
   * Gets the Form Status for the caseEventId
   * @param caseEventId The caseEventId for which the status is to be retrieved
   */
  getPatientFormStatus(caseEventId: number): Observable<PatientStatus> {
    return this.http.get<PatientStatus>
      (this.serviceUrlPrefix + 'patients/enrollment-event/form/' + caseEventId);
  }

  
  /**
   * Returns the approval status for this patient for this event
   * @param caseEventId The caseEventId of this event
   * @param approvalStudySchemaEventId The studySchemaEventId
   */
  getPatientApprovalStatus(caseEventId: number): Observable<PatientStatus> {
    // patients/{caseEventId}/approvals/{approvalStudySchemaEventId}/tile-status
  const url: string = 'patients/' + caseEventId + '/approvals/tile-status';
  return this.http.get<PatientStatus>
      (this.serviceUrlPrefix + url);
  }

  /**
   * Returns the transfer status for this patient
   * @param caseDetailId The caseDetailId of this event
   */
  getTransferTileStatus(caseDetailId: number): Observable<PatientTransferTileData> {
    // patients/{caseDetailId}/transfer/tile-status
  const url: string = 'patients/' + caseDetailId + '/transfers/tile-status';
  return this.http.get<PatientTransferTileData>
      (this.serviceUrlPrefix + url);
  }

  /**
   * Gets the list of transfer for the patientId
   * @param caseDetailId The patientId whose history is to be retrieved
   */
  getPatientTransferHistory(caseDetailId: number): Observable<PatientTransfer[]> {
    // let result: PatientTransfer[] = [
    //   {
    //     caseEventId:0,
    //     caseTransferId:1,
    //     "initials": "BD",
    //     "eventName": "Pre-Registration",
    //     "siteName": "Altru Cancer Center",
    //     "status": "Registered",
    //     "statusDate": new Date(1548348355273),
    //     "transferReason": null,
    //     "modifiedTs": "2019-01-23",
    //     "modifiedById": "m000000",
    //     "organizations": [
    //       {
    //         "name": "Altru Cancer Center",
    //         "ctepId": "ND028",
    //         "siteLevel": "Treating Location",
    //         "siteSeq": 30
    //       },
    //       {
    //         "name": "Addison Gilbert Hospital",
    //         "ctepId": "MA025",
    //         "siteLevel": "Membership",
    //         "siteSeq": 20
    //       },
    //       {
    //         "name": "ACCRU",
    //         "ctepId": null,
    //         "siteLevel": "Credit Group",
    //         "siteSeq": 10
    //       }
    //     ],
    //     "staff": []
    //   }
    // ]
    //   return of(result);
    // http://localhost:8080/reg-backend-service/api/v1/patients/1478/transfers
    return this.http.get<PatientTransfer[]>
      (this.serviceUrlPrefix + 'patients/' + caseDetailId + '/transfers');
  }


  /**
   * Submits the new Patient transfer request
   * @param caseDetailId The CaseDetail Id for this transfer
   * @param patientTransferRequest The Patient Transfer Request entity
   */
  addPatientTransfer(caseDetailId: number, patientTransferRequest: PatientTransferRequest): Observable<PatientTransferRequest> {
    // http://localhost:8080/reg-backend-service/api/v1/patients/1038/transfers
    return this.http.post<PatientTransferRequest>
      (this.serviceUrlPrefix + 'patients/' + caseDetailId + '/transfers', patientTransferRequest);
  }



  /**
   * Returns the data for display on the Double Blind Tile
   * @param studyId The studyId
   * @param caseDetailId The caseDetailId of the patient
   */
  getDoubleBlindTileStatus(studyId: number, caseDetailId: number): Observable<PatientDoubleBlindTileData> {
    // …/api/v1/studies/{studyId}/double-blind/manage-kits/patient/{caseDetailId}/tile-info
    const url: string = 'studies/' + studyId + '/double-blind/manage-kits/patient/' + caseDetailId + '/tile-info';
    return this.http.get<PatientDoubleBlindTileData>
      (this.serviceUrlPrefix + url);
  }


  /*****  Patient Approvals ************** */



  /**
   * Returns the list of approval status
   */
  getApprovalStatusList(): Observable<Code[]> {
    // GET …/api/v1/configuration/approval-status
    // let result: Code[] = [
    //   {
    //     "id": 1,
    //     "code": "APP",
    //     "name": "Approved"
    //   },
    //   {
    //     "id": 2,
    //     "code": "REJ",
    //     "name": "Rejected"
    //   }
    // ]
    // return of(result);
     const url = 'configuration/approval-status';
      return this.http.get<Code[]>
        (this.serviceUrlPrefix + url);
  }


  /**
   * Returns PatientApproval Model for this patient
   * @param caseEventId The caseEventId
   */
  getPatientApprovalModel(caseEventId: number): Observable<PatientApprovalModel> {
    // GET….api/v1/patients/{caseEventId}/approvals

  //   let result = {
  //         "initials": "J B",
  //         "eventName": "Pre-Reg",
  //         "approvals":
  //           [
  //             {
  //               "approvalId": 1,
  //               "studyId": 18971,
  //               "approvalType": "Generic Case Review",
  //               "studySchemaDefinitionId": 134521,
  //               "approvalStatus": {
  //                   "id": 1,
  //                   "code": "APP",
  //                   "name": "Approved"
  //               },
  //               "comments": null,
  //               "tupleVersion": 1,
  //               "modifiedById": "mrm7402",
  //               "modifiedDate": 1558010724450
  //           },
  //           {
  //               "approvalId": null,
  //               "studyId": 18971,
  //               "approvalType": "Pathology Review",
  //               "studySchemaDefinitionId": 134578,
  //               "approvalStatus": null,
  //               "comments": null,
  //               "tupleVersion": null,
  //               "modifiedById": null,
  //               "modifiedDate": null
  //           }

  //           ]
  //       };

  //  return of(result);
    const url: string = 'patients/' + caseEventId + '/approvals' ;
    return this.http.get<PatientApprovalModel>
      (this.serviceUrlPrefix + url);
  }


/**
   * Submits the new Patient approval request
   * @param caseEventId The CaseEvent Id for this approval
   * @param record The Patient Approval Request entity
   */
  addPatientApproval(caseEventId: number, record: PatientApproval): Observable<PatientApproval> {
    // record.approvalId = Math.floor(Math.random() * 1500) + 1;
    // record.tupleVersion = 1;
    // console.log("added ....")
    // return of(record)
    // …api/v1/patients/{caseEventID}/approvals
    return this.http.post<PatientApproval>
      (this.serviceUrlPrefix + 'patients/' + caseEventId + '/approvals', record);
  }


/**
   * Updates an existing Patient approval
   * @param caseEventId The CaseEvent Id for this approval
   * @param approvalId The approvalId of the update
   * @param record The Patient Approval Request entity
   */
  updatePatientApproval(caseEventId: number, approvalId: number, record: PatientApproval): Observable<PatientApproval> {
    // record.tupleVersion++;
    // console.log("updated ....")
    // return of(record);
    // api/v1/patients/}caseEventId}/approvals/{approvalId}
    return this.http.put<PatientApproval>
      (this.serviceUrlPrefix + 'patients/' + caseEventId + '/approvals/' + approvalId, record);
  }

/**
   * Deletes an existing Patient approval
   * @param caseEventId The CaseEvent Id for this approval
   * @param approvalId The approvalId of the approval
   * @param tupleVersion The tupleVersion of the approval
   */
deletePatientApproval(caseEventId: number, approvalId: number, tupleVersion: number): Observable<any> {

  const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
  const url = 'patients/' + caseEventId + '/approvals/' + approvalId;
  return this.http.delete(`${this.config.apiEndpoint}` + url, { headers: header, responseType: 'text' });

}

/*****  Patient Approvals ************** */



/***** patient enrollment events for corrections */
 /**
   * Returns PatientEnrollmentEvents Model for this patient
   * @param caseEventId The caseDetailId
   */
  getPatientEnrollmentEvents(caseDetailId: number): Observable<PatientEnrollmentCorrection[]> {
    // GET….api/v1/patients/enrollment-event/corrections/{caseDetailId}

  //  return of(result);
    return this.http.get<PatientEnrollmentCorrection[]>
      (this.serviceUrlPrefix + 'patients/enrollment-event/corrections/' + caseDetailId);
  }


/**
 * Returns PatientRegistration information for registered patient
 * @param parentCaseEventId
 * @param ancillaryStudyId
 */
  registerPatientToAncillary(parentCaseEventId: number, ancillaryStudyId: number): Observable<any> {

    return this.http.post<any>(this.serviceUrlPrefix + 'patients/' + parentCaseEventId + '/ancillary/' + ancillaryStudyId, {});

  }

  /**
   * Backend will determine if it should delete patient registered to ancillary study or patient registered to enrollment event (or protocol)
   * @param enrollmentInfo
   */
  deleteEnrollment(enrollmentInfo: PatientEnrollmentCorrection): Observable<any> {
    return this.http.put<any>(this.serviceUrlPrefix + 'patients/' + enrollmentInfo.caseDetailId, enrollmentInfo );

  }

    /**
   * delete patient including ancillaries
   * @param enrollmentInfo
   */
  deleteEntirePatient(caseDetailId: number): Observable<any> {
    return this.http.put<any>(this.serviceUrlPrefix + 'patients/enrollments/' + caseDetailId, {});
  }

/***** patient enrollment events for corrections */


/***** post processing page - start */
 /**
   * Returns list of CaseIntegrationStatus Model for this patient
   * @param caseEventId The caseEventId
   */
  getPostProcessingStatus(caseEventId: number): Observable<CaseIntegrationStatus[]> {
    // TEMP CODE!!!
    // return this.http.get<CaseIntegrationStatus[]>
    //   (this.serviceUrlPrefix + 'patients/post-processing/' + '6200' + '/status');
    return this.http.get<CaseIntegrationStatus[]>
      (this.serviceUrlPrefix + 'patients/post-processing/' + caseEventId + '/status');
  }

 /**
   * Returns CaseIntegrationStatus Model for this patient
   * @param caseEventId The caseEventId
   */
  resendToRave(caseIntegration: CaseIntegrationStatus): Observable<CaseIntegrationStatus> {
    // TEMP CODE!!!
    // let result : CaseIntegrationStatus = {
    //    "studyId": 9500,
    //    "caseEventId": 9,
    //    "eventName": "MyRegistration",
    //    "targetSystem": "RAVE",
    //    "transactionType": "Insert",
    //    "transactionGroup": 2,
    //    "transactionResult": "Failure",
    //    "failedReasonCode": "RWS00024",
    //    "failedMessage": "<Response ReferenceNumber=\"cf686e3c-662e-42e8-ad1b-c9856fd36aba\" InboundODMFileOID=\"Enrollment\" IsTransactionSuccessful = \"0\" ReasonCode=\"RWS00024\" ErrorOriginLocation=\"/ODM/ClinicalData[1]/SubjectData[1]\" SuccessStatistics=\"Rave objects touched: Subjects=0; Folders=0; Forms=0; Fields=0; LogLines=0\" ErrorClientResponseMessage=\"Subject already exists.\"> </Response> ",
    //   // "transactionResult": "Success",
    //   // "failedReasonCode": null,
    //   // "failedMessage": null,
    //   "statusId": 5 // primary key ofthe record (CaseIntegrationTransactionId, DrugOrderPMBID, DrugOrderMcKessonID)
    // }
    // return of(result);
    const myUrl = this.serviceUrlPrefix + 'patients/post-processing/' + caseIntegration.caseEventId + '/rave';
    console.log('patientService.resendToRave', myUrl);
    return this.http.post<CaseIntegrationStatus>(myUrl, {});
   }

   sendUpdatesToRedcap(caseIntegration: CaseIntegrationStatus, isUpdate: boolean): Observable<CaseIntegrationStatus> {
     
     const myUrl = this.serviceUrlPrefix + 'patients/post-processing/' + caseIntegration.caseEventId + '/redcap';
      if(isUpdate){
        console.log('patientService.updateToRedcap', myUrl);
        return this.http.put<CaseIntegrationStatus>(myUrl, {});
      }else{
        console.log('patientService.resendToRedcap', myUrl);
        return this.http.post<CaseIntegrationStatus>(myUrl, {});
      }
      
     
   }


    /**
   * Returns CaseIntegrationStatus Model for this patient
   * @param caseEventId The caseEventId
   */
  updateToRave(caseIntegration: CaseIntegrationStatus): Observable<CaseIntegrationStatus> {
    console.log('patientService.updateToRave');
    // TEMP CODE!!!
    // let result : CaseIntegrationStatus = {
    //    "studyId": 9500,
    //    "caseEventId": 9,
    //    "eventName": "MyRegistration",
    //    "targetSystem": "RAVE",
    //    "transactionType": "Insert",
    //    "transactionGroup": 2,
    //    "transactionResult": "Failure",
    //    "failedReasonCode": "RWS00024",
    //    "failedMessage": "<Response ReferenceNumber=\"cf686e3c-662e-42e8-ad1b-c9856fd36aba\" InboundODMFileOID=\"Enrollment\" IsTransactionSuccessful = \"0\" ReasonCode=\"RWS00024\" ErrorOriginLocation=\"/ODM/ClinicalData[1]/SubjectData[1]\" SuccessStatistics=\"Rave objects touched: Subjects=0; Folders=0; Forms=0; Fields=0; LogLines=0\" ErrorClientResponseMessage=\"Subject already exists.\"> </Response> ",
    //   // "transactionResult": "Success",
    //   // "failedReasonCode": null,
    //   // "failedMessage": null,
    //   "statusId": 5 // primary key ofthe record (CaseIntegrationTransactionId, DrugOrderPMBID, DrugOrderMcKessonID)
    // }
    // return of(result);

     return this.http.put<CaseIntegrationStatus>
       (this.serviceUrlPrefix + 'patients/post-processing/' + caseIntegration.caseEventId + '/rave', {});
   }

   /**
   * Returns CaseIntegrationStatus Model for this patient
   * @param caseEventId The caseEventId
   */
  resendToBioMS(caseIntegration: CaseIntegrationStatus): Observable<CaseIntegrationStatus> {
    console.log('patientService.resendToBioMS');
    // TEMP CODE!!!
    // let result : CaseIntegrationStatus = {
    //    "studyId": 9500,
    //    "caseEventId": 9,
    //    "eventName": "MyRegistration",
    //    "targetSystem": "BioMS",
    //    "transactionType": "Insert",
    //    "transactionGroup": 2,
    //   //  "transactionResult": "Failure",
    //   //  "failedReasonCode": "24",
    //   //  "failedMessage": "Endpoint does not exist.",
    //   "transactionResult": "Success",
    //   "failedReasonCode": null,
    //   "failedMessage": null,
    //   "statusId": 5 // primary key ofthe record (CaseIntegrationTransactionId, DrugOrderPMBID, DrugOrderMcKessonID)
    // }
    // return of(result);
    return this.http.post<CaseIntegrationStatus>
       (this.serviceUrlPrefix + 'patients/post-processing/' + caseIntegration.caseEventId + '/bioms', {});
   }

      /**
   * Returns CaseIntegrationStatus Model for this patient
   * @param caseEventId The caseEventId
   */
  updateToBioMS(caseIntegration: CaseIntegrationStatus): Observable<CaseIntegrationStatus> {
    console.log('patientService.updateToBioMS');
    // TEMP CODE!!!
    // let result : CaseIntegrationStatus = {
    //    "studyId": 9500,
    //    "caseEventId": 9,
    //    "eventName": "MyRegistration",
    //    "targetSystem": "BioMS",
    //    "transactionType": "Insert",
    //    "transactionGroup": 2,
    //   //  "transactionResult": "Failure",
    //   //  "failedReasonCode": "24",
    //   //  "failedMessage": "Endpoint does not exist.",
    //   "transactionResult": "Success",
    //   "failedReasonCode": null,
    //   "failedMessage": null,
    //   "statusId": 5 // primary key ofthe record (CaseIntegrationTransactionId, DrugOrderPMBID, DrugOrderMcKessonID)
    // }
    // return of(result);
    return this.http.put<CaseIntegrationStatus>
       (this.serviceUrlPrefix + 'patients/post-processing/' + caseIntegration.caseEventId + '/bioms', {});
   }

   /**
   * Returns CaseIntegrationStatus Model for this patient
   * @param caseEventId The caseEventId
   */
  resendToMcKesson(caseIntegration: CaseIntegrationStatus): Observable<CaseIntegrationStatus> {
    console.log('patientService.resendToMcKesson', caseIntegration);
    // TEMP CODE!!!
    // let result : CaseIntegrationStatus = {
    //    "studyId": 9500,
    //    "caseEventId": 9,
    //    "eventName": "MyRegistration",
    //    "targetSystem": "BioMS",
    //    "transactionType": "Insert",
    //    "transactionGroup": 2,
    //   //  "transactionResult": "Failure",
    //   //  "failedReasonCode": "24",
    //   //  "failedMessage": "Endpoint does not exist.",
    //   "transactionResult": "Success",
    //   "failedReasonCode": null,
    //   "failedMessage": null,
    //   "statusId": 5 // primary key ofthe record (CaseIntegrationTransactionId, DrugOrderPMBID, DrugOrderMcKessonID)
    // }
    // return of(result);
    return this.http.post<CaseIntegrationStatus>
       (this.serviceUrlPrefix + 'patients/post-processing/' + caseIntegration.caseEventId + '/mckesson', {});
   }

  /**
   * Returns CaseIntegrationStatus Model for this patient
   * @param caseEventId The caseEventId
   */
  resendToPmbV2(caseIntegration: CaseIntegrationStatus): Observable<CaseIntegrationStatus> {
    // TEMP CODE!!!
    // let result : CaseIntegrationStatus = {
    //    "studyId": 9500,
    //    "caseEventId": 9,
    //    "eventName": "MyRegistration",
    //    "targetSystem": "RAVE",
    //    "transactionType": "Insert",
    //    "transactionGroup": 2,
    //    "transactionResult": "Failure",
    //    "failedReasonCode": "RWS00024",
    //    "failedMessage": "<Response ReferenceNumber=\"cf686e3c-662e-42e8-ad1b-c9856fd36aba\" InboundODMFileOID=\"Enrollment\" IsTransactionSuccessful = \"0\" ReasonCode=\"RWS00024\" ErrorOriginLocation=\"/ODM/ClinicalData[1]/SubjectData[1]\" SuccessStatistics=\"Rave objects touched: Subjects=0; Folders=0; Forms=0; Fields=0; LogLines=0\" ErrorClientResponseMessage=\"Subject already exists.\"> </Response> ",
    //   // "transactionResult": "Success",
    //   // "failedReasonCode": null,
    //   // "failedMessage": null,
    //   "statusId": 5 // primary key ofthe record (CaseIntegrationTransactionId, DrugOrderPMBID, DrugOrderMcKessonID)
    // }
    // return of(result);
    const myUrl = this.serviceUrlPrefix + 'patients/post-processing/' + caseIntegration.caseEventId + '/pmbv2';
    console.log('patientService.resendToPmbV2', myUrl);
    return this.http.post<CaseIntegrationStatus>(myUrl, {});
   }

   showResultDialog(viewContainerRef: ViewContainerRef, modalDialogArg: ModalDialogArg = null) {
    return this.showModalDialog(viewContainerRef, modalDialogArg );
  }

  /**
   * Displays the modal dialog with the appropriate info
   * @param viewContainerRef the container where this modal will be appended
   * @param modalDialogArg the args for the modal
   */
  showModalDialog(viewContainerRef: ViewContainerRef,
    modalDialogArg: ModalDialogArg = null) {
    const componentRef = viewContainerRef.createComponent(ConfirmActionComponent);
    componentRef.instance.dialogArgType = modalDialogArg;
    return (<ConfirmActionComponent>componentRef.instance).actionSubject;
  }


/***** post processing page - end */



 /**
   * Returns information for patient preview pane
   * @param caseEventId The caseEventId
   */
  getPatientPreviewModel(caseEventId: number): Observable<PatientRegistrationPreview> {

  //  return of(result);
    const url: string = 'patients/' + caseEventId + '/preview';
    return this.http.get<PatientRegistrationPreview>
      (this.serviceUrlPrefix + url);
  }


  /**
   * Returns the unblinded patient info
   * @param caseEventId
   */
  getUnblindedInfo(caseEventId: number): Observable<UnblindedInfo> { 
    // …/api/v1/patients/{caseEventId}/unblinding
    const url: string = 'patients/' + caseEventId + '/unblinding';
    console.log("getUnblindedInfo url", url);
    return this.http.get<UnblindedInfo>
      (this.serviceUrlPrefix + url);
  }

  /**
   * Get post processing history
   * @param caseIntegration 
   * @returns 
   */
  getPostProcessingHistory(caseIntegration: CaseIntegrationStatus): Observable<PostProcessingHistoryRecord[]> {
    return this.http.get<PostProcessingHistoryRecord[]>
       (this.serviceUrlPrefix + 'patients/post-processing/' + caseIntegration.caseEventId + '/history/'+caseIntegration.targetSystem);
   }


}
