import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BaseComponent } from 'app/common/base/base-component';
import { EditBarActions } from 'app/common/model/edit-bar-actions';
import { ListOptionModel } from 'app/common/model/list-option';
import { StudyAttribute } from 'app/common/model/study-attribute';
import { StudySchemaEvent } from 'app/common/model/study-schema-event';
import { StudySchemaEventCode } from 'app/common/model/study-schema-event-code';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { UserService } from 'app/common/services/user.service';
import * as moment from 'moment';

@Component({
  selector: 'mc-shared-study-attributes',
  templateUrl: './study-attributes.component.html',
  styleUrls: ['./study-attributes.component.scss']
})
export class StudyAttributesComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() attributesData: StudyAttribute[]; // TableData;
  /**
   * Optional array to hold the dropdown options for the Status column selects
   */
  @Input() statusOptions: Array<ListOptionModel[]> = [];
  @Input() actionSettings: EditBarActions = new EditBarActions(true, false, false, false, true, false);
  /**
   * Variable used to display Events on the UI
   */
  @Input() displayEvents: Array<StudySchemaEvent> = [];

  @Input() isFromStudyTemplate: boolean = false;

  public showFilterRow: Boolean = false;
  public rows: StudyAttribute[];
  public columns: Array<any> = [
    {
      title: 'Group',
      name: 'group',
      className: 'has-fixed-width-175',
      sort: '',
      filtering: { filterString: '', placeholder: 'Group' }
    },
    {
      title: 'Attribute',
      name: 'attributeCode',
      sort: '',
      filtering: { filterString: '', placeholder: 'Attribute' }
    },
    {
      title: 'Study / Event',
      className: 'has-fixed-width-150',
      sort: false,
      filtering: false
    },
    {
      title: 'Description',
      name: 'attributeDesc',
      sort: '',
      filtering: { filterString: '', placeholder: 'Description' }
    },
    {
      title: 'Status',
      name: 'selected',
      sort: '',
      filtering: { filterString: '', placeholder: 'Status' }
    }
  ];

  public selectedIds: number[] = [];
  public page = 1;
  public itemsPerPage = 10;
  public maxSize = 5;
  public numPages = 1;
  public length = 0;
  public areAllRowsChecked = false;
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['']
  };
  selectedInfoRowId = 0;

  /**
   * The id of the row being currently edited
   */
  currentEditAttributeId = -1;
  // This will hold all the selected attribute Ids.
  currentEditAttributeIdList = [];
  /**
   * boolean to indicate if the event section is expanded for editing
   */
  currentEditRowExpanded = false;
  /**
   * Holds the selections of selected events
   */
  selectedEvents: boolean[] = [];
  /**
   * Boolean to track if the study or event radio button is checked on the ui
   */
  isStudySelected: boolean = null;
  isEditModeOn: boolean = false;

  STANDARD_STATUS_OPTIONS: ListOptionModel[] =
    [
      {
        listLabel: 'ON',
        listValue: true
      },
      {
        listLabel: 'OFF',
        listValue: false
      }
    ];

  constructor(
    studySetupService: StudySetupService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.onChangeTable(this.config);
  }

  /**
   * Get column headers
   * @returns 
   */
  getColumns(): Array<any> {
    if (this.isFromStudyTemplate) {
      let filterColumns: Array<any> = this.columns.filter(x => x.title != 'Study / Event');
      return filterColumns;
    }
    return this.columns;
  }

  ///// Data
  /**
   * set the event selector array
   */
  initializeEventSelectors() {
    this.selectedEvents = new Array(this.displayEvents.length).fill(false);
  }



  ///// Event Handlers
  /**
   * handler for the radio button click
   * @param isStudy True if the study radio button was clicked
   * @param item The StudyAttribute bound to this row
   */
  studyEventSelection(item: StudyAttribute, isStudy: boolean) {
    this.initializeEventSelectors();
    if (isStudy) {
      item.attributeEventIds = [];
    }
  }

  /**
   *
   * @param item The study attribute for the row
   * @param evt The studySchemaEvent being selected or deselected
   * @param idx The index of the event being displayed
   */
  eventSelectionClicked(item: StudyAttribute, evt: StudySchemaEvent, idx: number) {
    setTimeout(() => {
      // if the event was selected
      if (this.selectedEvents[idx]) {
        item.attributeEventIds.push(new StudySchemaEventCode(evt.studySchemaEventId, evt.keyEventMappingId, evt.schemaEventCode, evt.schemaEventName));
      } else {
        // find the location of this event id
        const location: number = item.attributeEventIds.findIndex((code: StudySchemaEventCode) => code.keyEventMappingId === evt.keyEventMappingId);
        item.attributeEventIds.splice(location, 1);
      }
    });
  }

  /**
   * Toggles between the show / hide state for displaying the events selection
   * @param $event The event being raised
   * @param item The studyAttribute object on the row
   */
  showHideEvents($event: Event, item: StudyAttribute) {
    if ($event) {
      $event.stopPropagation();
    }
    this.currentEditAttributeId = item.attributeId;
    this.currentEditRowExpanded = !this.currentEditRowExpanded;
    // if being expanded, set the study and or event selections
    // if expanded
    if (this.currentEditRowExpanded) {
      if (item.selected) {
        this.isStudySelected = item.attributeEventIds.length === 0;
        if (!this.isStudySelected) {
          this.displayEvents.forEach((eachEvt, idx) => {
            // if the studySchemaEventId is selected, set it to true
            this.selectedEvents[idx] = (item.attributeEventIds.findIndex((code: StudySchemaEventCode) => code.keyEventMappingId === eachEvt.keyEventMappingId) > -1);
          });
        }
      }
    } else {
      // if collapsed
      // clear the selected events boolean
      this.initializeEventSelectors();
      // reset the isStudySelected
      this.isStudySelected = null;
    }
  }

  /**
   * Selects / deselects the studyAttribute row
   * @param row The studyAttribute of the row
   */
  selectAttribute(row) {
    const selectedId: number = this.getIdIndex(row.attributeId);
    if (selectedId > -1) {
      this.selectedIds.splice(selectedId, 1);
      this.currentEditAttributeId = -1;
      if (this.currentEditRowExpanded) {
        this.currentEditRowExpanded = false;
      }
    } else {
      this.selectedIds.push(row.attributeId);
    }
    this.selectedInfoRowId = 0;
  }

  /**
   * Handles the click on the edit bar
   * @param selectedAction The action icon clicked on the edit bar
   */
  selectedEditAction(selectedAction) {
    if (selectedAction == 'info') {
      // activate the tooltip
      this.processInfoClick();
    } else if (selectedAction == 'edit') {
      this.initializeEventSelectors();
      this.isEditModeOn = !this.isEditModeOn;
      this.currentEditAttributeIdList = this.selectedIds;
    } else if (selectedAction === 'filter') {
      this.showFilterRow = !this.showFilterRow;
      if (!this.showFilterRow) {
        this.clearTableFilterSetting();
        this.onChangeTable(this.config);
      }
    }
  }

  /**
   * Clears the filter for the table being displayed
   */
  private clearTableFilterSetting() {
    if (this.columns && this.columns.length > 0) {
      this.columns.forEach(eachCol => {
        if (eachCol.filtering && eachCol.filtering.filterString) {
          eachCol.filtering.filterString = "";
        }
      });
    }
  }

  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    let column: any = null;
    if (config.title !== undefined && config.name !== undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.attributesData, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
    if (sortedData) {
      this.length = sortedData.length;
    }
  }

  ///// UI
  /**
   * Returns the comma delimited list of the events selected
   * @param item The studyAttribute bound to the row
   */
  public getEventLabelsFromIds(item: StudyAttribute): string {
    let labels = '';
    if (item && this.displayEvents.length > 0 && item.attributeEventIds.length > 0) {
      // get the events from the ids
      const events: StudySchemaEvent[] = this.displayEvents.filter(eachEvt => item.attributeEventIds.findIndex((code: StudySchemaEventCode) => code.keyEventMappingId === eachEvt.keyEventMappingId) > -1);
      labels = events.map((event: StudySchemaEvent) => {
        return (event && event.studySchemaEventLabel) ? event.studySchemaEventLabel : event.schemaEventDisplayName;
      }).join(', ');
    }
    return labels;
  }

  /**
    * Returns the display or default label name
    * @param evt The event object
    */
  getEventDisplayName(evt: StudySchemaEvent): string {
    if (evt && evt.studySchemaEventLabel) {
      return evt.studySchemaEventLabel;
    }
    return evt.schemaEventDisplayName;
  }

  getStatusSelectOptions(index: number) {
    if (this.statusOptions[index]) {
      return this.statusOptions[index];
    }
    else {
      return this.STANDARD_STATUS_OPTIONS;
    }
  }

  /**
   * True if the event / study selection should be disabled e.g.if the status is OFF
   * @param item The StudyAttribute bound to this row
   */
  isExpandedPanelDisabled(item: StudyAttribute): boolean {
    return item.selected === false;
  }

  /**
   * True if the event selection should be disabled e.g.if the event checkbox is unchecked
   * @param item The StudyAttribute bound to this row
   */
  isEventSelectionPanelDisabled(item: StudyAttribute): boolean {
    return this.isStudySelected === true;
  }

  /**
   * True if the current edit row is expanded
   * @param row The studyAttribute bound to the row
   */
  isEditRowExpanded(row: StudyAttribute): boolean {

    if (this.currentEditRowExpanded) {
      if (this.currentEditAttributeId !== -1) {
        return this.currentEditRowExpanded && row.attributeId === this.currentEditAttributeId;
      }
      return this.currentEditAttributeIdList.includes(row.attributeId);
    }
  }

  /**
   * True if the row is selected for editing
   * @param row The studyAttribute bound to the row
   */
  isSelectedForEdit(row: StudyAttribute): boolean {
    if (this.isEditModeOn) {
      return this.currentEditAttributeIdList.includes(row.attributeId);
    }
  }

  /**
   * True if the row is selected and the sub section is collapsed
   * @param row The studyAttribute object bound to the row
   */
  isSelectedAndCollapsed(row: StudyAttribute): boolean {
    if (!this.isRowSelected(row)) {
      return false;
    }
    return this.isRowSelected(row) && !this.isEditRowExpanded(row);
  }

  /**
   * True if the row is selected for edit
   * @param row The studyAttribute object bound to the row
   */
  isRowSelected(row) {
    return this.getIdIndex(row.attributeId) > -1;
  }

  /**
   * Handles the update to the selected property
   * @param row The studyAttribute bound to this row
   */
  statusChanged(row: StudyAttribute): void {

    this.currentEditAttributeId = row.attributeId;
    this.initializeEventSelectors();
    if (!row.selected) {
      // if the selected is turned off, clear all the events/attribute elements from the selection
      if (row.attributeCode === "Study Sign Off") {
        row.studyAttributeElements.forEach(elmnt => elmnt.selected = false);
      }
      else {
        row.attributeEventIds = [];
        this.isStudySelected = null;
      }
    } else {
      this.isStudySelected = row.attributeEventIds.length === 0;
    }
  }

  /**
     * True if the sub section is expanded
     */
  hasSubsectionExpanded(): boolean {
    return this.currentEditRowExpanded;

  }

  ///// Helper Functions
  getIdIndex(id: number) {
    let idx = -1;
    if (this.selectedIds.length > 0) {
      idx = (this.selectedIds.findIndex(x => x === id));
    }
    return idx;
  }

  /**
   * Returns the label for display on the ui
   * @param statusValue The selected status of the studyAttribute
   */
  getLabelForStatus(statusValue: boolean): string {
    return (statusValue ? 'ON' : 'OFF');
  }

  processInfoClick() {
    if (this.selectedIds.length > 0) {
      // if the selectedInfoRow is already the currently selected Row, deselected it and
      // allow the info tooltip to go away
      if (this.selectedInfoRowId === this.selectedIds[this.selectedIds.length - 1]) {
        this.selectedInfoRowId = 0;
      } else {
        // if it's not selected, then select this row for the info tooltip display
        this.selectedInfoRowId = this.selectedIds[this.selectedIds.length - 1];
      }
    } else {
      // if nothing is selected, remove the tooltip
      this.selectedInfoRowId = 0;
    }
  }

  isInfoSelected(row) {
    return row.attributeId === this.selectedInfoRowId;
  }

  getInfoMessage(row) {
    let message = '';
    if (row.lastModifiedDate == null) {
      message = 'Study attribute has not been modified.';
    } else {
      message = 'Modified on: ' + moment(row.lastModifiedDate).format('MM/DD/YYYY HH:mm:ss.SSS')
        + ' \n ' +
        'By: ' + this.getUserNameFromPrimaryKey(row.lastModifiedUser);
    }

    return message;
  }



  getAttributeElementNames(row) {
    let elements = "";
    if (row.studyAttributeElements != null) {
      row.studyAttributeElements.forEach((element: any) => {
        if (element.selected) {
          if (elements.length > 0) {
            elements = elements + ", ";
          }
          elements = elements + element.attributeElementName;
        }
      });
    }
    // if (elements.length === 0) {
    //   elements = "None selected";
    // }
    return elements;
  }

  public changePage(page: any, data: Array<any> = this.attributesData): Array<any> {
    const start = (page.page - 1) * page.itemsPerPage;
    const end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;
    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name] == null) {
            return true;
          }
          if (column?.name == 'selected') {
            return (item[column.name] ? 'ON' : 'OFF').toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          return (item[column.name] + '').toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name] == null || item[column.name].toString().match(this.config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

}
