import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchCriteria } from 'app/common/model/search-criteria';
import { SlotAvailabilityPreviewDetail } from 'app/common/model/slot-availability-preview-detail';
import { SlotReservationPreviewRecord } from 'app/common/model/slot-reservation-preview-record';
import { SearchInfo } from '../../common/model/search-info';
import { Subscription } from 'rxjs';
import { SearchService } from 'app/common/services/search.service';
import { UserService } from 'app/common/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import * as globalConst from 'app/common/model/app-constants';

@Component({
  selector: 'mc-slot-availability',
  templateUrl: './slot-availability.component.html',
  styleUrls: ['./slot-availability.component.scss']
})
export class SlotAvailabilityComponent implements OnInit , OnDestroy {

  @Input() hostLandingPageEnum = 0;
  @Output() returnToSearchEvent = new EventEmitter();

  searchCriteria: SearchCriteria = null;
  subscriptions: Subscription[] = [];

  studyId = 0;
  reservationEventStatus: string;
  results: SlotAvailabilityPreviewDetail[];
  rows: SlotAvailabilityPreviewDetail[];
  noResultsFound = false;
  isActiveListTitle = false;

  /**
   * The search criteria that identifies the type of patient search
   */
  currentSearchCriteria: SearchInfo = null;
  showFilterRow = true;

  public columns: Array<any> = [
    {
      title: 'Slot Group Description', name: 'slotDescription',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Slot Group' }
    },
    {
      title: 'Maximum Slots', name: 'slotsMax',
      sort: true,
      display: true
    },
    {
      title: 'Slots Available', name: 'slotsAvailable',
      sort: true,
      display: true 
    },
    {
      title: 'Status', name: 'slotsStatus',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Status' }
    }
  ];

  /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering:  { filterString: '' },
    className: ['is-striped', 'is-bordered']
  };

  constructor(private searchService: SearchService,
    private userService: UserService,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private router: Router) {

  }


  ngOnInit() {
      this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params['studyId']) {
          this.studyId = params['studyId'];
        }
      }));



    this.subscriptions.push(this.searchService.searchQuery$.subscribe(
      criteria => {
        this.currentSearchCriteria = this.searchService.getSearchQuery(this.hostLandingPageEnum)//  this.searchService.currentSearchQuery;
        this.searchCriteria = null;
        if (criteria && criteria.searchType === globalConst.SEARCH_CRITERIA_TYPE_SLOT_AVAILABILITY) {
          this.searchCriteria = criteria;
          this.isActiveListTitle = false;
          this.getSlotAvailability();
        } else {
          this.results = null;
        }
      }
    ))
  }



  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }

  returnToSearch() {
    this.fireEvent();
  }

  fireEvent() {
    this.returnToSearchEvent.emit();
  }

  /**
 * Sorts the data in the table based on the column and sort order
 * @param data The data currently being displayed on the table
 * @param config The associated configuration for the data being displayed
 */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousVal: any;
      let currentVal: any;

      // If the following columns are used, then need to get different values for previous and current
     if (columnName === 'slotsMax') { // integer values
        previousVal = previous[columnName] ;
        currentVal = current[columnName] ;
     } else if (columnName === 'slotsAvailable') { // integer values
      previousVal = previous[columnName] ;
      currentVal = current[columnName] ;
      } else { // simple values
        previousVal = previous[columnName] ? previous[columnName].toLowerCase() : '';
        currentVal = current[columnName] ? current[columnName].toLowerCase() : '';
      }

      if (previousVal > currentVal) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousVal < currentVal) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
  * Main method to handle filtering, sorting of the table
  * @param config Configuration of the datatable
  * @param page The current page being displayed
  */
  public onChangeTable(config: any): any {
    let column: any = null;
    this.rows = [];
    if (config.title !== undefined && config.name !== undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.results, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;

  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data === undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          
            if (item[column.name] != null) {
              return ((item[column.name]) + '').toLowerCase().match(column.filtering.filterString.toLowerCase());
            }
       

          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });



    return filteredData;
  }

  /**
   * Calls the service to fetch the slot reservations for the study
   */
  private getSlotAvailability() {
    this.subscriptions.push(
      this.searchService.getSlotAvailability(this.studyId)
        .subscribe(res => {
          this.results = res?.slotAvailabilityPreviewDetails;
          this.reservationEventStatus = res?.reservationEventStatus;
           this.noResultsFound = (this.results.length === 0);
          this.onChangeTable(this.config);
        }));
  }


 
 

 

 
}


