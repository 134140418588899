import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { QueryBuilderConfig, Rule } from 'ngx-angular-query-builder';
import { FieldWrapper, QueryBuilderConfigWrapper } from '../model/query-builder-config-wrapper';
import { QueryBuilderService } from '../services/query-builder.service';


@Component({
  selector: 'mc-query-builder',
  templateUrl: './query-builder-wrapper.component.html',
  styleUrls: ['./query-builder-wrapper.component.scss']
})
export class QueryBuilderWrapperComponent implements OnInit {

  // Allows for additional sub-groups of rules
  @Input() allowRuleset: boolean = true;
  // Allows user to collapse rulesets
  @Input() allowCollapse: boolean = true;
  // Configurations for the controls within the query builder
  @Input() queryConfiguration: QueryBuilderConfig | QueryBuilderConfigWrapper
    = { fields: QueryBuilderService.DEFAULT_FIELD_DEFINITION };
  @Input() queryDefinition: any  // Current state of the query
    = QueryBuilderService.MINIMUM_QUERY_RULES; // minimum required state for the query
  @Input() queryControl: FormControl;
  // Whether or not to display the query translation in real time
  @Input() displayQueryTranslation: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private queryBuilderService: QueryBuilderService
  ) {
    if (typeof this.queryControl === 'undefined') {
      this.queryControl = this.formBuilder.control(this.queryDefinition);
    }
  }

  ngOnInit(): void {
    this.queryControl.setValidators(this.queryBuilderService.getValidateQueryBuilderControlFn());
    this.queryControl.updateValueAndValidity();
  }

  getCompareFunction(field: FieldWrapper): (a: any, b: any) => boolean {
    return field.compareFunction ?? this.compareFn;
  }

  compareFn(a, b) {
    return a && b && a.Id === b.Id;
  }

  getQueryTranslation(includeFormatting: boolean): string {
    return this.queryBuilderService.convertRulesToLogicalRepresentation(this.queryDefinition, this.queryConfiguration, includeFormatting);
  }

  getDisplayError(rule: Rule, control: AbstractControl, type: string): string {
    let errorMessage: string = '';

    if (control.invalid) {
      const errors = _.cloneDeep(control.errors);
      for (const e in errors) {
        // Custom Query Builder Wrapper Errors
        if (e === 'wrapperRules') {
          for (const element of errors.wrapperRules) {
            if (element.id
              && _.isEqual(rule, element.id)
              && !errorMessage.includes(element.message)) {
              errorMessage += '\n' + element.message;
            }
          }
        }
        // Standard Query Builder and Validator errors
        else if (e === 'rules') {
          // Loop through all of the errors added automatically
          for (const element of errors.rules) {
            // Loop through all of the error types (keys) for the error added
            // Should only be 1, but could be named anything
            for (const key in element) {
              if (Object.prototype.hasOwnProperty.call(element, key)) {
                const errorObj = element[key];
                if (_.isEqual(rule, errorObj.rule)
                  && !errorMessage.includes(errorObj.message)) {
                  errorMessage += '\n' + errorObj.message;
                }
              }
            }
          }
        }
      }
    }

    return errorMessage;
  }

}
