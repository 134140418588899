import { StudyContactRecordModel } from './study-contact-record';
import { StudyStatusModel } from './study-status-model';
import { MsrStudyStatus } from './msr-study-status';
import { AncillaryStudy } from './ancillary-study';
import { StudyReferenceNumSection } from './study-create-reference-num-section';
import { StudyAuthToken } from './study-auth-token';
export class StudyRecord {

    constructor(
        public studyNumber: string,
        public studyId: number,
        public shortDescription: string,
        public longDescription: string,
        public researchEntityName: string,
        public currentMsrStudyStatus: MsrStudyStatus| null,
        public studyTypeId?: number,
        public dataCenterId?: number,
        public assignmentGroupId?: number,
        public dataCenter?: string,
        public tupleVersion?: number,
        public sponsor?: string,
        public sponsorId?: number,
        public randomCenter ?: string,
        public randomCenterId ?: number,
        public acronym?: string,
        public status?: StudyStatusModel,
        public referenceType?: string,
        public studyType?: string,
        public referenceNumber?: string,
        public statusActiveDate?: Date,
        public phase?: string,
        public contacts?: StudyContactRecordModel[],
        public studyDocuments?: string[],
        public createdByUser?: string,
        public createdDate?: Date,
        public referenceNumSections?: StudyReferenceNumSection[]
      //  public ancillaryStudy?: AncillaryStudy
    ) { }

}
