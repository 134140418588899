<mc-header-bar [headerModel]="headerModel"> </mc-header-bar>

<div class="form-page study-create">
  <div class="columns">
    <div class="column is-2">
      <span class="label-padding is-small">Study Type:</span>
    </div>
    <div class="column field">
      <div class="control input-width">
        <div class="select is-small">
          <select [(ngModel)]="selectedStudyType" (change)="checkForMatchingStudyTemplate()">
            <option [ngValue]="null" selected>-- Select --</option>
            <option *ngFor="let opt of studyTypes" [ngValue]="opt">
              {{ opt.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isStudyTypeProtocol()">
    <mc-type-protocol
      [selectedStudyType]="selectedStudyType"
      (resetSelectedStudyType)="receiveNewSelectedStudyType($event)"
    ></mc-type-protocol>
  </div>
  <div *ngIf="isStudyTypeAncillary()">
    <mc-type-ancillary
      [studyTypeId]="selectedStudyType.id"
      (resetSelectedStudyType)="receiveNewSelectedStudyType($event)"
    ></mc-type-ancillary>
  </div>

  <div *ngIf="isStudyMultiSubjType()">
    <mc-type-multi-subject
      [selectedStudyType]="selectedStudyType"
      (resetSelectedStudyType)="receiveNewSelectedStudyType($event)"
    ></mc-type-multi-subject>
  </div>
</div>
