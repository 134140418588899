import { FormVariableValue } from 'app/common/model/form-variable-value';
import { VariableQuestionText } from 'app/common/model/variable-question-text';
import { FormNavRule } from './form-nav-rule';
import { StudyFormVariableDependencyRule } from './study-form-variable-dependency-rule';
import { Code } from './code';
import { FormVariableNavRule } from './form-variable-nav-rule';

export class FormVariable {
    constructor(
        public formId: number | null,
        public formSectionId: number | null,
        public formVariableId: number | null,
        public variableId: number | null,
        public variableName: string,
        public variableAlternateName: string,
        public variableDesc: string,
        public variableMinValue: string,
        public variableMaxValue: string,
        public dictionaryCode: string,
        public dictionaryVersion: string,
        public dictionaryGroup: string,
        public variableResponseTypeId: number,
        public variableDataTypeId: number | null,
        public variableDataTypeName: string | null,
        public variableClassId: number | null,
        public sequenceNum: number | null,
        public externalVariableNum: number | null,
        public mandatoryField: boolean | null,
        public modifiedById: string,
        public questionText: VariableQuestionText,
        public activeDate: Date | null,
        public inactiveDate: Date | null,
        public formVariableValues: FormVariableValue[] = [],
        //TODO - delete navRules when all code has been converted to new nav rule model
        public navRules: FormNavRule[] = [],
        //TODO - end
        public formVariableNavRule: FormVariableNavRule,
        public skip?: boolean,
        public questionType?: string,
        public variableResponseTypeName?: string,
        public variableClassName?: string,
        public variableClassDesc?: string,
        public studyFormVariableDependencyRule?: StudyFormVariableDependencyRule | null,
        public variableType?: Code // VARIABLE, TEXT, SECTIONSTART, or SECTIONEND
    ) { }
}
