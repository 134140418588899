<div class="columns">
    <div class="column no-bottom-padding">
        <h3>Site And Person</h3>
    </div>
</div>
<div class="form-table-container site-person-section study-setup-content">

    <h2 class="subtitle">Site(s)</h2>
    <div class="site-person-section">
        <table class="table is-fullwidth is-borderless" role="grid">
            <tbody>
                <tr>
                    <td colspan="2">
                        <div class="field">
                            <div class="control">
                                <label class="radio">
                                    <input type="radio" name="site-type" [(ngModel)]="isNetworkParticipation"
                                        [value]="true" [disabled]="!isEditable"
                                        (click)="networkOptionSelected($event, true)" [checked]="isNetworkParticipation"
                                        id="network-part-true"> Network Participation
                                </label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let item of networkSiteLevelList; let i=index "
                    [ngClass]="{'is-error-row':hasError(item.siteLevel.code)}">
                    <td class="has-label-name is-error-cell">
                        <mc-error-validation-display-row [idToEvaluate]="item.siteLevel.code">
                        </mc-error-validation-display-row>
                        <div class="field is-option-div">
                            <label class="label is-size-7">
                                {{item.siteLevel.name}}
                            </label>
                        </div>
                    </td>
                    <td>
                        <div class="control ">
                            <label class="radio">
                                <input type="radio" name="network-{{item.siteLevel.code}}"
                                    [disabled]="!isNetworkParticipation || isTreatingLocationSiteLevel(item) || !isEditable"
                                    [(ngModel)]="item.required" [value]="true" [checked]="item.required"
                                    id="network-{{item.siteLevel.code}}-req"> Required
                            </label>
                            <label class="radio option-spacer">
                                <input type="radio" name="network-{{item.siteLevel.code}}"
                                    [disabled]="!isNetworkParticipation || isTreatingLocationSiteLevel(item) || !isEditable"
                                    [(ngModel)]="item.required" [value]="false" [checked]="!item.required"
                                    (click)="noClicked()" id="network-{{item.siteLevel.code}}-no"> No
                            </label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="field">
                            <div class="control">
                                <label class="radio">
                                    <input type="radio" name="site-type" [(ngModel)]="isNonNetworkParticipation"
                                        [disabled]="!isEditable" (click)="networkOptionSelected($event, false)"
                                        [value]="true" [checked]="isNonNetworkParticipation" id="network-part-false">
                                    Direct Participation
                                </label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let item of nonNetworkSiteLevelList; let i=index">
                    <td class="has-label-name">
                        <div class="field is-option-div">
                            <label class="label is-size-7">
                                {{item.siteLevel.name}}
                            </label>
                        </div>
                    </td>
                    <td>
                        <div class="control ">
                            <label class="radio">
                                <input type="radio" name="non-network-{{item.siteLevel.code}}" [disabled]="true"
                                    [(ngModel)]="item.required" [value]="true" [checked]="item.required"
                                    id="non-network-{{item.siteLevel.code}}-req"> Required
                            </label>
                            <label class="radio option-spacer">
                                <input type="radio" name="non-network-{{item.siteLevel.code}}" [disabled]="true"
                                    [(ngModel)]="item.required" [value]="false" [checked]="!item.required"
                                    id="non-network-{{item.siteLevel.code}}-no"> No
                            </label>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <h2 class="subtitle">Person Types</h2>
    <div class="site-person-section">
        <table class="table is-fullwidth is-borderless " role="grid">
            <tbody>
                <tr *ngFor="let item of studyPersonTypes; let i=index">
                    <td class="has-label-name">
                        <div class="field is-option-div">
                            <label class="label is-size-7">
                                {{item.label.name}}
                            </label>
                        </div>
                    </td>
                    <td>
                        <div class="control ">
                            <label class="radio">
                                <input type="radio" name="network-{{item.label.code}}" [value]="true"
                                    [checked]="item.required" [(ngModel)]="item.required" [disabled]="!isEditable"
                                    id="network-{{item.label.code}}-req"> Required
                            </label>
                            <label class="radio option-spacer">
                                <input type="radio" name="network-{{item.label.code}}" [checked]="!item.required"
                                    [(ngModel)]="item.required" [value]="false" [disabled]="!isEditable"
                                    id="network-{{item.label.code}}-opt"> Optional
                            </label>
                            <label class="radio option-spacer">
                                <input type="radio" name="network-{{item.label.code}}" [(ngModel)]="item.required"
                                    [value]="null" [checked]="item.required == null" [disabled]="!isEditable"
                                    id="network-{{item.label.code}}-no"> No
                            </label>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>