

    <div class="select">
        <select>
            <option [ngValue]="null" selected>-- Select --</option>
            <option *ngFor="let elem of formVariable.formVariableValues ; let elemIdx = index">
                {{elem.validValueDesc}}
            </option>
        </select>
    </div>
