export class Person {
    constructor(
        public firstName?: string,
        public lastName?: string,
        public fullName?: string,
        public email?: string,
        public id?: number | null,
        public ctepId?: string,

        public middleName?: string,
        public fax?: string,
        public phone?: string,
        public personTypeId?: number,
        public pk?: number | null,
        public personRoleStatus?: string,
        public caseEventStaffId?: number,
        public nonStdStaffId? : number

    ) { }
}
