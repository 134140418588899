import { Code } from 'app/common/model/code';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudySetupService } from 'app/common/services/study-setup.service';

import { Helper } from 'app/common/services/helper.service';
import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { StudyNotification } from '../../../common/model/study-notification';
import { EditBarActions } from 'app/common/model/edit-bar-actions';
import { NotificationDistribution } from 'app/common/model/notification-distribution';
import { NotificationCode } from 'app/common/model/notification-code';
import { Individual } from '../../../common/model/notification-person';
import * as globalConst from 'app/common/model/app-constants';
import * as _ from 'lodash';
import { EventArg } from 'app/common/model/event-arg';
import { Observable, Subscription } from 'rxjs';
import { Person } from 'app/common/model/person';
import { SearchCriteriaPerson } from 'app/common/model/search-criteria-person';
import { UserService } from 'app/common/services/user.service';
import { ACTIVITY_NOTIFICATION, ACTIVITY_NOTIFICATION_SETUP } from 'app/common/model/activity-name-constants';

@Component({
  selector: 'mc-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss'],
})
export class RecipientsComponent implements OnInit {
  constructor(
    private viewContainer: ViewContainerRef,
    private router: Router,
    private studySetupService: StudySetupService,
    private notificationService: NotificationDashboardService,
    private helper: Helper,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {
    this.subscriptions.push(
      studySetupService.changeEmitted$.subscribe(eventInfo => {
        this.parseEventInfo(eventInfo);
      }),
    );
  }

  /**
   * This will be called when the notification has been retrieved.
   * @param  {EventArg} eventArg
   */
  parseEventInfo(eventArg: EventArg) {
    if (eventArg.eventName == globalConst.EVENT_DATA_UPDATED) {
      this.setRecipientData();
    }
  }

  /**
   * The object for setting the edit bar
   */
  actionSetting: EditBarActions = new EditBarActions(false, false, false, false, false);
  /**
   * variable for selected recipients
   */
  selectedIds: string[] = [];
  /**
   * Subscription array to hold all the subscribes
   */
  subscriptions: Subscription[] = [];

  /**
   * hide/show the addbutton Div
   */
  showAddButtonDiv = false;

  /**
   * The studyd for this notification
   */
  studyId = 0;

  /**
   * variable for the newly created object's id
   */
  newId = 0;

  /**
   * Holds the roles array
   */
  roles: Array<NotificationCode> = [];

  selectedRoles: Array<Code> = [];

  /**
   * Holds the personTypes array
   */
  personTypes: Array<NotificationCode> = [];

  selectedPersonTypes: Array<Code> = [];

  sharedEmails: Array<NotificationCode> = [
    { id: 3, value: 'audit@alliancenctn.org', desc: 'Alliance Audit Group' },
    { id: 4, value: 'cancer@mayo.edu', desc: 'Cancer Center Systems' },
    { id: 2, value: 'random01@mayo.edu', desc: 'Registration Office' },
  ];

  /**
   * The different types of distributions
   */
  distributionTypes: NotificationCode[] = [];

  /**
   * A default code
   */
  newNotificationCode = new NotificationCode(0, '', '');

  /**
   * Determines if the user selected a group notification from the study page
   */
  // isGroupNav: boolean = this.notificationService.isNavigatedGroupNotification ;
  // Need to get the value dynamically
  isGroupNav = false;

  //Determines  whether user can do editable or not
  isEditable: boolean = false;
  /**
   * the recipients selected for edit
   */
  selectedEditId = '';

  showModal = false;

  /**
   * The distribution row being edited
   */
  editedDistribution: NotificationDistribution = null;

  /***  Sorting / Filtering **************************************** */

  /**
   * Page variable used during paging
   */
  public page = 1;
  /**
   * Used to determine the items on a page
   */
  public itemsPerPage = 10;
  /**
   * maximum page to be displayed
   */
  public maxSize = 5;
  /**
   * counter to hold the number of pages
   */
  public numPages = 1;
  /**
   * Length of the array containing the recipients
   */
  public length = 0;

  /**
   * variable to hold the studyNotification ids of the notifications that are deleted
   */
  deletedIds: number[] = [];

  /**
   * variable used for the studyNotification array
   *
   */
  private data: Array<NotificationDistribution>;

  /**
   * Array to track the ids selected for edit
   */
  editRowIds: number[] = [];

  /**
   * boolean to determine if the filter row should be displayed
   */
  public showFilterRow: Boolean = false;
  /**
   * variable to hold the viewModels sorted and filtered as needed for the ui
   */
  public rows: Array<NotificationDistribution> = [];
  /**
   * Columns used to render the header and sorting
   */
  public columns: Array<any> = [
    {
      title: 'None',
      className: [],
      display: false,
      filtering: false,
      name: 'select',
      sort: '',
    },
    {
      title: 'Recipient',
      className: [],
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Recipient' },
      name: 'role',
      sort: '',
    },
    {
      title: 'Person',
      display: true,
      name: 'individual',
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Type', sort: '' },
    },

    {
      title: 'Email',
      name: 'email',
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Level' },
    },
  ];

  /**
   * Configuration for the table displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['is-striped', 'is-bordered'],
  };

  /*** Person Selection Modal ***************************** */

  searchCriteriaPerson: SearchCriteriaPerson = {
    firstName: '',
    lastName: '',
    email: '',
    pk: null,
  };
  persons: Person[] = [];

  selectedPerson: Person = null;
  isPersonAddMode = false;

  /**
   * boolean to determine if the filter row should be displayed
   */
  public showPersonFilterRow = false;
  /**
   * variable to hold the viewModels sorted and filtered as needed for the ui
   */
  public personRows: Array<Person> = [];

  /**
   * Count of rows on the person table
   */
  public personDataLength = 0;

  /**
   * Columns used to render the header and sorting
   */
  public personColumns: Array<any> = [
    {
      title: 'First Name',
      className: ['has-fixed-cell-width'],
      display: true,
      type: 'person',
      filtering: { filterString: '', placeholder: 'Filter by First ' },
      name: 'firstName',
      sort: '',
    },
    {
      title: 'Last Name',
      className: ['has-fixed-cell-width'],
      display: true,
      type: 'person',
      name: 'lastName',
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Last', sort: '' },
    },

    {
      title: 'Email',
      name: 'email',
      display: true,
      type: 'person',
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Email' },
    },
  ];

  /**
   * Configuration for the table displayed
   */
  public personConfig: any = {
    paging: false,
    sorting: { columns: this.personColumns },
    filtering: { filterString: '' },
    className: ['is-striped', 'is-bordered'],
  };

  /**
   * Sets the recipient data from the current notification
   */
  setRecipientData() {
    this.isGroupNav = this.notificationService.isNavigatedGroupNotification;
    if (this.currentNotification()) {
      this.data = this.currentNotification().notificationDistributionList; // this.roles;
      if (this.data == undefined || this.data == null) {
        this.data = [];
      }
      this.length = this.data.length;
      this.onChangeTable(this.config);
    }
    this.actionSetting.isEditBarDisabled = this.isGroupNav;
  }

  /**
   * True if the list of roles have been retrieved from the service
   */
  //  rolesRetrieved : boolean = false;

  ngOnInit() {
    this.route.parent.parent.parent.params.subscribe(params => {
      this.studyId = params['studyId'];
    });

    if (!this.isGroupNav) {
      if (this.studyId) {
        //Study notification
        if (!this.userService.hasModifyAccessByStudy(ACTIVITY_NOTIFICATION, this.studyId)) {
          this.isEditable = false;
          this.actionSetting.isAddDisabled = true;
          this.actionSetting.isEditDisabled = true;
          this.actionSetting.isDeleteDisabled = true;
        } else {
          this.isEditable = true;
        }
      } else {
        // group notification
        if (!this.userService.hasModifyAccess(ACTIVITY_NOTIFICATION_SETUP)) {
          this.isEditable = false;
          this.actionSetting.isAddDisabled = true;
          this.actionSetting.isEditDisabled = true;
          this.actionSetting.isDeleteDisabled = true;
        } else {
          this.isEditable = true;
        }
      }
    }

    this.setRecipientData();
    this.subscriptions.push(
      this.notificationService.getDistributionTypes().subscribe(results => {
        this.distributionTypes = results;
      }),
    );

    this.subscriptions.push(
      this.studySetupService.getPersonResponsibilityCodes().subscribe(results => {
        const codelist = results;
        if (codelist != null) {
          codelist.forEach(eachCode => {
            this.roles.push(new NotificationCode(eachCode.id, eachCode.code, eachCode.name));
          });
        }
      }),
    );
    this.subscriptions.push(
      this.notificationService.getSharedEmails().subscribe(results => {
        this.sharedEmails = results;
      }),
    );
    // this.personTypes = [{"id":1, "desc": "Data Entry", "value": "ENTRY"}, {"id":1, "desc": "Pharmacist", "value": "PHARMCST"}];
    this.subscriptions.push(
      this.notificationService.getPersonTypes().subscribe(results => {
        const codelist = results;
        if (codelist != null) {
          codelist.forEach(eachCode => {
            this.personTypes.push(new NotificationCode(eachCode.id, eachCode.code, eachCode.name));
          });
        }
      }),
    );
  }

  /**
   * returns the current notification setup on the service
   */
  currentNotification(): StudyNotification {
    return this.notificationService.currentNotification;
  }

  selectedEditAction(selectedAction) {
      if (selectedAction == 'add') {
      this.showAddButtonDiv = !this.showAddButtonDiv;
    } else if (selectedAction == 'delete') {
      this.deleteSelected();
    } else if (selectedAction == 'filter') {
      this.showFilterRow = !this.showFilterRow;
    } else if (selectedAction == 'edit') {
      if (this.selectedIds[0] == this.selectedEditId) {
        this.selectedEditId = '';
      } else {
        this.selectedEditId = this.selectedIds[0];
      }
    }
  }

  /**
   * True if the notificationDistributionId is selected for edit
   */
  isEditMode(row: NotificationDistribution): boolean {
    if (this.isGroupNav) {
      return false;
    }
    const isEdit: boolean = this.selectedEditId == this.getCompositeKey(row);
    return isEdit;
  }

  /**
   * Returns the composite key for the notificationDistribution object
   * @param notifyDistribution The notification distribution object to evaluate
   */
  getCompositeKey(notifyDistribution: NotificationDistribution): string {
    let key = '';
    if (notifyDistribution) {
      key = notifyDistribution.notificationDistributionId + '-' + notifyDistribution.notificationDistributionType.value;
    }

    return key;
  }

  /**
   * Delete the selected Ids
   */
  deleteSelected() {
    if (this.selectedIds.length == 0) {
      return;
    }
    this.selectedIds.forEach(eachId => {
      const selectedIdx: number = this.data.findIndex(eachnd => this.getCompositeKey(eachnd) == eachId);
      this.data.splice(selectedIdx, 1);
    });
    this.selectedIds = [];
    this.currentNotification().notificationDistributionList = this.data;
    this.onChangeTable(this.config);
  }

  /**
   * Returns the formatted name of the individual
   * @param row The individual object bound to this row
   */
  getPersonName(row: NotificationDistribution): string {
    let name = '';
    if (row.individual) {
      if (row.individual.lastName) {
        name = row.individual.lastName + ', ';
      }
      if (row.individual.firstName) {
        name += row.individual.firstName;
      }
    }
    return name;
  }

  /**
   * setup the ui to create a new person type recipient
   */
  addPersonType() {
    this.showAddButtonDiv = false;
    const personType: NotificationCode = this.distributionTypes.find(eachType => eachType.value == 'Person Type');
    this.addNewRecipient(personType);
  }

  /**
   * setup the ui to create a new role recipient
   */
  addRole() {
    this.showAddButtonDiv = false;
    const roleType: NotificationCode = this.distributionTypes.find(eachType => eachType.value == 'Role');
    this.addNewRecipient(roleType);
  }

  /**
   * setup the ui to create a new person recipient
   */
  addPerson() {
    this.showAddButtonDiv = false;
    this.showPersonModal(true, null);
  }

  /**
   * Adds the selected person to the list
   * @param person The person selected on the modal lookup
   */
  addSelectedPersonToList(person: Person) {
    this.newId--;
    const roleType: NotificationCode = this.distributionTypes.find(eachType => eachType.value == 'Individual');
    const newIndividual: Individual = {
      firstName: person.firstName,
      lastName: person.lastName,
      email: person.email,
      ctepId: person.ctepId,
      fax: person.fax,
      personId: person.pk,
      phone: person.phone,
    };

    const newItem: NotificationDistribution = {
      notificationDistributionId: this.newId,
      notificationDistributionType: roleType,
      individual: newIndividual,
      role: null,
      sharedEmail: null,
      personType: null,
    };
    this.addItemToList(newItem, roleType);
  }

  /**
   * setup the ui to create a new shared email recipient
   */
  addSharedEmail() {
    this.showAddButtonDiv = false;
    const roleType: NotificationCode = this.distributionTypes.find(eachType => eachType.value == 'Shared Email');
    this.addNewRecipient(roleType);
  }

  /**
   * True if the recipient type is a Role
   * @param distributionType The recipicent type to evaluate
   */
  isRoleType(distributionType: NotificationCode): boolean {
    const isDistributionTypeRole: boolean = distributionType.value.toLowerCase().indexOf('role') > -1;
    return isDistributionTypeRole;
  }

  /**
   * True if the recipient type is a Person Type
   * @param distributionType The recipicent type to evaluate
   */
  isPersonType(distributionType: NotificationCode): boolean {
    const isDistributionTypePersonType: boolean = distributionType.value.toLowerCase().indexOf('person type') > -1;
    return isDistributionTypePersonType;
  }

  /**
   * True if the recipient type is an Individual
   * @param distributionType The recipicent type to evaluate
   */
  isIndividualType(distributionType: NotificationCode): boolean {
    const isDistributionType: boolean = distributionType.value.toLowerCase().indexOf('individual') > -1;
    return isDistributionType;
  }
  /**
   * True if the recipient type is a Shared Email
   * @param distributionType The recipicent type to evaluate
   */
  isSharedEmailType(distributionType: NotificationCode): boolean {
    const isDistributionTypeShared: boolean = distributionType.value.toLowerCase().indexOf('shared') > -1;
    return isDistributionTypeShared;
  }

  /**
   * Adds a new recipient record to the list
   * @param distributionType The type of distribution record to be created
   */
  addNewRecipient(distributionType: NotificationCode) {
    if (this.isGroupNav) {
      return;
    }
    this.newId--;
    let roleType: NotificationCode = null;
    let registrationPersonType: NotificationCode = null;
    const personType: Individual = null;
    let sharedType: NotificationCode = null;
    if (this.isRoleType(distributionType)) {
      roleType = new NotificationCode(0, '', '');
    } else if (this.isPersonType(distributionType)) {
      registrationPersonType = new NotificationCode(0, '', '');
    } else if (this.isSharedEmailType(distributionType)) {
      sharedType = new NotificationCode(0, '', '');
    }
    //  else if (this.isIndividualType(distributionType)) {
    //   personType = new Individual(0, "", "", "", "", "", "");
    // }
    const newItem: NotificationDistribution = new NotificationDistribution(
      this.newId,
      distributionType,
      roleType,
      personType,
      sharedType,
      registrationPersonType,
    );
    this.addItemToList(newItem, distributionType);
  }

  /**
   * Adds a new notificationDistribution item to the collection
   * @param newItem The item to add to the collection
   */
  addItemToList(newItem: NotificationDistribution, distributionType: NotificationCode): void {
    this.currentNotification().notificationDistributionList.unshift(newItem);
    // Setting edit mode for new row
    this.selectedIds[0] = newItem.notificationDistributionId + '-' + distributionType.value;
    this.selectedEditId = this.selectedIds[0];

    this.selectedRoles.unshift(null);
    this.data = this.currentNotification().notificationDistributionList;
    this.onChangeTable(this.config);
  }

  /**
   * Opens the modal to edit the person
   * @param row The notificationDistribution object containing the individual
   */
  editIndividual(row: NotificationDistribution): void {
    this.editedDistribution = row;
    this.showPersonModal(false, row.individual);
  }

  /**
   * Updates the edited person
   * @param person The person that was updated
   */
  updateEditedPerson(person: Person): void {
    if (this.editedDistribution) {
      this.editedDistribution.individual = {
        firstName: person.firstName,
        lastName: person.lastName,
        email: person.email,
        ctepId: person.ctepId,
        fax: person.fax,
        personId: person.pk,
        phone: person.phone,
      };
    }
    this.editedDistribution = null;
  }

  /**
   * Handles the change in the role selection dropdown
   */
  roleUpdated() {}

  /**
   * Handles the change in the person type selection dropdown
   */
  personTypeUpdated() {}

  compareRoleFn(a, b) {
    return a && b && a.desc == b.desc;
  }

  /**
   * Sorts the data in the table based on the column and sort order
   * @param data The data currently being displayed on the table
   * @param config The associated configuration for the data being displayed
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (columnName == 'role') {
        const previousItem = previous['role'];
        const currentItem = current['role'];
        if (currentItem && previousItem) {
          if (previousItem.desc > currentItem.desc) {
            return sort === 'desc' ? -1 : 1;
          } else if (previousItem.desc < currentItem.desc) {
            return sort === 'asc' ? -1 : 1;
          }
        } else if (currentItem || previousItem) {
          if (currentItem) {
            return sort === 'desc' ? -1 : 1;
          } else {
            return sort === 'asc' ? -1 : 1;
          }
        }
      } else if (columnName == 'individual') {
        const previousItem = previous['individual'];
        const currentItem = current['individual'];
        if (currentItem && previousItem) {
          if (previousItem.firstName > currentItem.firstName) {
            return sort === 'desc' ? -1 : 1;
          } else if (previousItem.firstName < currentItem.firstName) {
            return sort === 'asc' ? -1 : 1;
          }
        } else if (currentItem || previousItem) {
          if (currentItem) {
            return sort === 'desc' ? -1 : 1;
          } else {
            return sort === 'asc' ? -1 : 1;
          }
        }
      } else {
        if (previous[columnName] > current[columnName]) {
          return sort === 'desc' ? -1 : 1;
        } else if (previous[columnName] < current[columnName]) {
          return sort === 'asc' ? -1 : 1;
        }
      }
      return 0;
    });
  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }
    const columns = config.sorting.columns || [];

    columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (column.type && column.type != 'person') {
            // if the row has roleType data
            if (column.name == 'role') {
              if (this.isPersonType(item.notificationDistributionType)) {
                if (item.personType) {
                  return (item.personType.desc + '').toLowerCase().match(column.filtering.filterString.toLowerCase());
                }
              } else if (this.isRoleType(item.notificationDistributionType)) {
                if (item.role) {
                  return (item.role.desc + '').toLowerCase().match(column.filtering.filterString.toLowerCase());
                }
              } else if (this.isSharedEmailType(item.notificationDistributionType)) {
                if (item.sharedEmail) {
                  return (item.sharedEmail.desc + '').toLowerCase().match(column.filtering.filterString.toLowerCase());
                }
              }
            } else if (column.name == 'individual') {
              if (!this.isRoleType(item.notificationDistributionType)) {
                if (item.individual) {
                  return (item.individual.firstName + '')
                    .trim()
                    .toLowerCase()
                    .match(column.filtering.filterString.toLowerCase());
                }
              }
            } else if (column.name == 'email') {
              if (!this.isRoleType(item.notificationDistributionType)) {
                if (item.individual) {
                  return (item.individual.email + '')
                    .trim()
                    .toLowerCase()
                    .match(column.filtering.filterString.toLowerCase());
                }
              }
            }
          } else {
            if (item[column.name]) {
              return (item[column.name] + '').trim().toLowerCase().match(column.filtering.filterString.toLowerCase());
            }
          }
          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString),
      );
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      columns.forEach((column: any) => {
        let propertyName = column.name;
        if (
          item[propertyName] != null &&
          item[propertyName] != undefined &&
          item[propertyName].toString().match(config.filtering.filterString)
        ) {
          flag = true;
        }
        // also check the sharedEmail and personType property
        if (!flag && propertyName == 'role') {
          propertyName = 'sharedEmail';
          if (
            item[propertyName] != null &&
            item[propertyName] != undefined &&
            item[propertyName].toString().match(config.filtering.filterString)
          ) {
            flag = true;
          }
          propertyName = 'personType';
          if (
            item[propertyName] != null &&
            item[propertyName] != undefined &&
            item[propertyName].toString().match(config.filtering.filterString)
          ) {
            flag = true;
          }
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  /**
   * Main method to handle filtering, sorting of the table
   * @param config Configuration of the datatable
   * @param page The current page being displayed
   */
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    let column: any = null;
    this.rows = [];
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }
    const filteredData = this.changeFilter(this.data, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;
    if (sortedData) {
      this.length = sortedData.length;
    }
  }

  /**
   * Manages the click of the checkbox on the first column
   * @param $event The event being raised
   * @param data The row being evaluated
   * @param columnName The column name for the row
   */
  cellClick($event: Event, data: NotificationDistribution, columnName: string): any {
    if ($event) {
      $event.stopPropagation();
    }
    if (this.isGroupNav) {
      return;
    }
    this.selectedEditId = '';
    const key = this.getCompositeKey(data);
    const selectedIdx: number = this.selectedIds.indexOf(key);
    if (selectedIdx > -1) {
      this.selectedIds.splice(selectedIdx, 1);
    } else {
      this.selectedIds.push(key);
    }
  }

  /**
   * True if the row has been check for selection
   * @param row the row being evaluated
   */
  isRowSelected(row: NotificationDistribution) {
    if (row) {
      const key: string = this.getCompositeKey(row);
      const idx = this.selectedIds.indexOf(key);
      return idx > -1;
    }
    return false;
  }
  /***  Sorting / Filtering **************************************** */

  /********* Pending Changes warning************** */
  /**
   * Implemenation of the canDeactivate that will be called when we navigate away
   *
   */
  canDeactivate(): Observable<boolean> | boolean {
    return this.notificationService.notificationSetup.canDeactivate();
  }
  /*********  Pending Changes warning************** */

  /**
   * Returns True if the current notification is of type "notify"
   */
  isNotificationTypeNotify(): boolean {
    let isNotify = false;
    if (this.currentNotification()) {
      isNotify = this.notificationService.isNotificationTypeNotify(this.currentNotification());
    }
    return isNotify;
  }

  /**
   * Disables editing if it's a notify type notification or isGroupNam
   */
  isEditingDisabled() {
    return this.isNotificationTypeNotify() || this.isGroupNav || !this.isEditable;
  }

  /**
   * Opens the modal for persons to select a person to add or edit
   * @param isAddMode True if the modal is used to add a new new user
   * @param individual The person being edited
   */
  showPersonModal(isAddMode: boolean, individual: Individual) {
    this.isPersonAddMode = isAddMode;
    if (!isAddMode && individual != null) {
      if (individual.firstName) {
        this.searchCriteriaPerson.firstName = individual.firstName;
      }
      if (individual.lastName) {
        this.searchCriteriaPerson.lastName = individual.lastName;
      }
      if (individual.email) {
        //  this.searchCriteriaPerson.email = individual.email;
      }
      this.findPersonMatches();
      this.selectedPerson = {
        firstName: individual.firstName,
        email: individual.email,
        lastName: individual.lastName,
        phone: individual.phone,
        ctepId: individual.ctepId,
        pk: individual.personId,
        fax: individual.fax,
        id: null,
      };
    }
    this.showModal = true;
  }

  /**
   * True if some value is provided for the searchCriteria
   */
  isValidSearchCriteria(): boolean {
    return (
      this.searchCriteriaPerson.firstName.trim().length > 0 ||
      this.searchCriteriaPerson.lastName.trim().length > 0 ||
      this.searchCriteriaPerson.email.trim().length > 0
    );
  }

  /**
   * Calls the service to return the matches
   */
  findPersonMatches(): void {
    this.persons = [];
    this.subscriptions.push(
      this.notificationService.getPersonForCriteria(this.searchCriteriaPerson).subscribe(result => {
        this.persons = result;
        this.onChangePersonTable(this.personConfig);
      }),
    );
  }

  /**
   * Selects or deselects the person
   * @param row The row displaying the person
   */
  selectPerson(row: Person): void {
    if (this.selectedPerson && this.selectedPerson.pk == row.pk) {
      this.selectedPerson = null;
    } else {
      this.selectedPerson = row;
    }
  }

  /**
   * True if the person is the selected person
   * @param row The person row to evaluate
   */
  isPersonSelected(row: Person): boolean {
    return this.selectedPerson && this.selectedPerson.pk == +row.pk;
  }

  /**
   * Sets the selected person to the row
   */
  setSelectedPerson() {
    if (this.isPersonAddMode) {
      this.addSelectedPersonToList(this.selectedPerson);
    } else {
      this.updateEditedPerson(this.selectedPerson);
    }
    this.cancelSelectedPerson();
  }

  /**
   * Handles the cancel button click
   */
  cancelSelectedPerson() {
    this.showModal = false;
    this.persons = [];
    this.searchCriteriaPerson = {
      email: '',
      lastName: '',
      firstName: '',
      pk: null,
    };
  }

  /**
   * Main method to handle filtering, sorting of the Person table
   * @param config Configuration of the datatable
   * @param page The current page being displayed
   */
  public onChangePersonTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    let column: any = null;
    this.personRows = [];
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (this.personConfig.filtering) {
      Object.assign(this.personConfig.filtering, config.filtering);
    }
    const columns = this.personColumns;
    if (column != null) {
      columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.personConfig.sorting, config.sorting);
    }
    const filteredData = this.changeFilter(this.persons, this.personConfig);
    const sortedData = this.changeSort(filteredData, this.personConfig);
    this.personRows = sortedData;
    if (sortedData) {
      this.personDataLength = sortedData.length;
    }
  }

  /*** Person Selection Modal ***************************** */
}
