<mat-form-field appearance="fill">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input  [rangePicker]="picker">
      <input matStartDate  placeholder="Start date"  [(ngModel)]="modelStartDate" (dateChange)="onStartDateSelected($event)">
      <input matEndDate placeholder="End date"  [(ngModel)]="modelEndDate" (dateChange)="onEndDateSelected($event)">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker ></mat-date-range-picker>
     <mat-error *ngIf="modelStartDate > modelEndDate">
        Invalid Date Range. StartDate should be before or equal to the EndDate.
      </mat-error>
  </mat-form-field>