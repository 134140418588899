<mat-form-field class="datetime-picker">
  <input matInput [matDatepicker]="picker" placeholder="Choose a date" [disabled]="disabled" [(ngModel)]="modelDate"
    (dateChange)="onDateSelected($event)" dateInput="onDateSelected($event)">
  <mat-datepicker-toggle matSuffix [for]="$any(picker)">
  </mat-datepicker-toggle>
  <mat-datepicker #picker>
  </mat-datepicker>
</mat-form-field>

<mat-form-field class="datetime-picker">
  <input id="registrationTime" matInput type="time" (change)="setModelTime($event)">
</mat-form-field>