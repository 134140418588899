import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { SearchCriteria } from '../../common/model/search-criteria';
import { ListOptionModel } from 'app/common/model/list-option';
import { SearchService } from '../../common/services/search.service';
import { SearchInfo } from '../../common/model/search-info';
import * as globalConst from 'app/common/model/app-constants';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';

@Component({
  selector: 'mc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']

})
export class SearchComponent implements OnInit {



  @Output() searchSelectionEvent = new EventEmitter();

  homeLandingPageEnum: number = globalConst.LANDING_PAGE_ENUM_HOME;

  protocolSearch: SearchCriteria = {
    searchType: globalConst.SEARCH_CRITERIA_TYPE_PROTOCOL,
    searchTerm: ''
  };


  searchTypes: ListOptionModel[] = [
    new ListOptionModel('Study ', globalConst.SEARCH_CRITERIA_TYPE_PROTOCOL),
    new ListOptionModel('Patient ', globalConst.SEARCH_CRITERIA_TYPE_PATIENT),
    new ListOptionModel('Tracking Number ', globalConst.SEARCH_CRITERIA_TYPE_TRACKING_NUMBER)
  ];

  subjectType = new ListOptionModel('Subject', globalConst.SEARCH_CRITERIA_TYPE_SUBJECT);

  shouldShowHelp = false;
  helpTypeProtocol = false;
  helpTypeSubject = false;
  helpTypeTrackingNumber = false;
  testEdit = '';
  editable = false;

  constructor(private searchService: SearchService,
    @Inject(APP_CONFIG) appConfig: AppConfig) {
      const version = appConfig.version;
      if (version == 'v2') {
        const index = this.searchTypes.findIndex(type => type.listLabel == 'Patient ');
        this.searchTypes[index] = this.subjectType;
      }
  }


  ngOnInit() {
    const previousQuery: SearchInfo = this.searchService.getSearchQuery(this.homeLandingPageEnum); // currentSearchQuery;
    if (previousQuery && previousQuery.searchTypeEnum == globalConst.SEARCH_TYPE_ENUM_TEXT &&
        previousQuery.searchCriteria) {
      this.protocolSearch.searchType = previousQuery.searchCriteria.searchType;
      this.protocolSearch.searchTerm = previousQuery.searchCriteria.searchTerm;
      setTimeout(() => {
        this.searchClick();
      });
    }
   //
  }


  /**
   * Handler for the search button click
   */
  searchClick() {
    this.searchService.clearSearchQueries();
    const criteria: SearchInfo = {
      searchCriteria: this.protocolSearch,
      searchTypeEnum: globalConst.SEARCH_TYPE_ENUM_TEXT
    };
    this.searchService.setSearchQuery(this.homeLandingPageEnum, criteria);
    this.fireProtocolSearchEvent(this.protocolSearch);
  }

  /**
   * Fires the event when search is clicked or reset
   * @param searchCriteria The search criteria to be emitted
   */
  fireProtocolSearchEvent(searchCriteria: SearchCriteria) {
    this.searchService.setSearchCriteria(searchCriteria);
    this.searchSelectionEvent.emit(searchCriteria);
  }

  /**
   * Returns the label for the selection for the search type
   */
  public getLabelForSelection(): string {
    const selected: string = this.searchTypes
      .find(item => item.listValue == this.protocolSearch.searchType).listLabel;
    return selected;
  }



  /**
   * Reset the criteria when selection is changed
   */
  searchTypeChanged() {
    this.shouldShowHelp = false;
    this.fireProtocolSearchEvent(null);
  }


  /**
   * disable the search button if invalid criteria
   */
  isInvalid() {
    return this.protocolSearch.searchTerm.trim().length == 0;
  }



  /**
   * toggle the help display
   */
  toggleHelp() {
    this.shouldShowHelp = !this.shouldShowHelp;
    if (this.shouldShowHelp) {
      if ( this.protocolSearch.searchType == globalConst.SEARCH_CRITERIA_TYPE_PROTOCOL) {
      this.helpTypeProtocol = true;
      this.helpTypeSubject = false;
      this.helpTypeTrackingNumber = false;
      } else if ( this.protocolSearch.searchType == globalConst.SEARCH_CRITERIA_TYPE_SUBJECT) {
      this.helpTypeSubject = true;
      this.helpTypeProtocol = false;
      this.helpTypeTrackingNumber = false;
      } else if ( this.protocolSearch.searchType == globalConst.SEARCH_CRITERIA_TYPE_TRACKING_NUMBER) {
        this.helpTypeSubject = false;
        this.helpTypeProtocol = false;
        this.helpTypeTrackingNumber = true;
      } 
    }
  }

}
