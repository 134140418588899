
<div *ngIf="inputModel" class="modal " [ngClass]="{'is-active': inputModel.showModal}">
    <div class="modal-background"></div>
    <div class="modal-card variable-modal" [mcDraggable]="true">
      <header class="modal-card-head">
        <p class="modal-card-title">Select Variable          
        </p>
        <button class="delete is-radiusless" aria-label="close" (click)="closeModal(false,$event,false)">
        </button>
      </header>
      <div class="modal-card-body ignore-draggable no-top-padding variable-body" >
        <table class="table is-fullwidth is-striped " ngClass="{{config.className || ''}}" role="grid">
          <thead>
            <tr role="row">
              <ng-container *ngFor="let column of columns">
                <th *ngIf="column.display" class="table-header" [mcTableSorting]="config"
                 [column]="column" title="{{column.title}}" (sortChanged)="onChangeTable($event)"
                  ngClass="{{column.className || ''}}">
                  <span> {{column.title}}</span>
                  <span *ngIf="config && column.sort" class="sort  is-text-centered">
                    <span *ngIf="column.sort === 'desc'">                          
                        <i class="fas fa-sort-down"></i>
                    </span>
                    <span *ngIf="column.sort === 'asc'">                          
                      <i class="fas fa-sort-up"></i>
                    </span>               
                  </span>
                </th>
              </ng-container>
            </tr>          
          </thead>
          <tbody>
            <tr *ngIf="showFilterRow">
              <td *ngFor="let column of columns; let i=index">
                <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" class="form-control"
                  (tableChanged)="onChangeTable(config)" />
              </td>
            </tr>
         
            <tr #varRow *ngFor="let eachVariable of rows"  (click)="handleSelection(eachVariable)"
              [ngClass]="{'is-selected' :isRowSelected(eachVariable) }"
              id="{{eachVariable.variableId}}" >            
               <ng-container >
              <td>
                <span>{{eachVariable.variableId}} </span>
              </td>
              <td>
                <span>{{eachVariable.variableAlternateName}} </span>
              </td>
              <td>
                <span>{{eachVariable.variableDataTypeName}} </span>
              </td>
              <td>
                <span>{{eachVariable.variableDesc}} </span>
              </td> 
          </ng-container>     
            </tr>

          </tbody>
        </table>
      </div>
      <footer class="modal-card-foot ignore-draggable">
        
        <div class="footer-paging">
          <div *ngIf="shouldShowPagination()">
            <res-table-paging [currentPage]="page" [totalItems]="libraryVariables.length" [itemsPerPage]="itemsPerPage"
              [numPages]="numPages" (pageChanged)="onChangeTable(config, $event)">
            </res-table-paging>
          </div>
        </div>
    
  
        <div class="footer-button ">
          <button class="button is-primary is-radiusless" [disabled]="selectedVariables.length == 0" (click)="closeModal(false,$event,true)">
            Select</button>
          <button class="button is-pulled-right is-radiusless" (click)="closeModal(false,$event,false)">
            Cancel</button>
        </div>
      </footer>
    </div>
  </div>
