<div class="notification-info">

  <h3>
    <span *ngIf="!isExistingForm()">Create</span>
    <span *ngIf="isExistingForm()">Modify</span>
    Notification
  </h3>

  <div class="main-content" *ngIf="currentNotification()">
    <div class="columns">
      <div class="column is-3">
        <span>Level</span>
      </div>

      <div class="column is-5 field">
        <div *ngIf="isStudyNotification()">
          <span>Study</span>
        </div>
        <div *ngIf="!isStudyNotification()">
          <span>Group</span>
        </div>
      </div>
      <div class="column"></div>
    </div>

    <div class="columns">
      <div class="column is-3">
        <span>Notification Name</span>
      </div>

      <div class="column is-5 field">
        <div class="control">
          <input [attr.disabled]="isNotificationTypeNotify() ? '' : null" placeholder="Notification Name" id="notification-name" name="notificationName" [(ngModel)]="currentNotification().name" class="input width-100-percent" [disabled] ="isGroupNav || !isEditable">
        </div>
      </div>
      <div class="column"></div>
    </div>

    <div class="columns" *ngIf="!isStudyNotification()">
      <div class="column is-3">
        <span>Group</span>
      </div>
      <div class="column is-5 field">
        <div class="control">
          <div class="select">
            <select [(ngModel)]="currentNotification().researchEntity" [compareWith]="this.compareFn" [disabled] ="isGroupNav || !isEditable">
              <option value=null selected>-- Select --</option>
              <option *ngFor="let gType of groupsList" [ngValue]="gType">
                {{gType.name}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  
    <div class="columns">
      <div class="column is-3">
        <span>Status</span>
      </div>

      <div class="column is-5 field">
        <div class="control">
          <div class="select">
            <select [(ngModel)]="currentNotification().status" [compareWith]="this.compareFn" [disabled] ="isGroupNav || !isEditable">
              <option *ngFor="let sType of statusTypesList" [ngValue]="sType">
                {{sType.value}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>

  </div>
</div>
