<div class="columns">
    <div class="column is-three-fourth no-bottom-padding"> 
        <h3>Study Attributes</h3>
    </div>
    <div class="column no-bottom-padding edit-bar-section">
        <mc-edit-bar [selectionCount]="selectedIds.length" [isMultiSelectEnable]="true" [actionSetting]="actionSettings"
            (selectedActionEvent)="selectedEditAction($event)">
        </mc-edit-bar>
    </div>
</div>
<div class="form-table-container study-setup-content">
    <table class="table is-fullwidth is-borderless " [ngClass]="{'has-expanded-section' :hasSubsectionExpanded()}"
        role="grid">
        <thead>
            <tr role="row">
                <th class="has-text-centered">
                    <span>
                    </span>
                </th>
                <th *ngFor="let column of getColumns()" class="table-header" [mcTableSorting]="config" [column]="column"
                    title="{{column.title}}" (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
                    <span> {{column.title}}</span>
                    <span *ngIf="config && column.sort" class="sort is-text-centered">
                        <span *ngIf="column.sort === 'desc'">
                            <i class="fas fa-sort-down"></i>
                        </span>
                        <span *ngIf="column.sort === 'asc'">
                            <i class="fas fa-sort-up"></i>
                        </span>
                    </span>
                </th>
            </tr>
            <tr *ngIf="showFilterRow">
                <th class="has-text-centered">
                    <span>
                    </span>
                </th>
                    <th  *ngFor="let column of getColumns()" class="table-header">
                        <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
                            [mcTableFiltering]="column.filtering" class="form-control"
                            (tableChanged)="onChangeTable(config)" />
                    </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let row of rows; let idx = index">
                <tr [ngClass]="{'is-selected': isSelectedAndCollapsed(row) }">
                    <td (click)="selectAttribute(row)" class="has-text-centered">
                        <span *ngIf="!isRowSelected(row)">
                            <i class="far fa-square"></i>
                        </span>
                        <span *ngIf="isRowSelected(row)">
                            <i class="far fa-check-square"></i>
                        </span>
                    </td>
                    <td>
                        <span>{{row.group}}</span>
                    </td>
                    <td class="display-message is-display-message-multiline is-display-message-bottom"
                        [attr.data-display-message]="getInfoMessage(row)" [ngClass]="{'selected':isInfoSelected(row)}">
                        <span>{{row.attributeCode}}</span>
                    </td>
                    <td *ngIf="!isFromStudyTemplate">
                        <div>
                            <span>{{getEventLabelsFromIds(row)}}</span>
                            <span class="expand-collapse-icon"
                                [ngClass]="{'is-hidden' : !isSelectedForEdit(row),  'is-selected-row' :isRowSelected(row), 'is-expanded-mode' : isEditRowExpanded(row) }">
                                <a (click)="showHideEvents($event, row)">
                                    <span *ngIf="isEditRowExpanded(row)" class="is-expanded-mode">
                                        <i class="fas fa-minus-circle"></i>
                                    </span>
                                    <span *ngIf="!isEditRowExpanded(row)">
                                        <i class="fas fa-plus-circle"></i>
                                    </span>
                                </a>
                            </span>
                        </div>
                    </td>
                    <td>
                        <span>{{row.attributeDesc}}</span>
                    </td>
                    <td>
                        <div *ngIf="!isSelectedForEdit(row)">
                            {{getLabelForStatus(row.selected)}}
                        </div>
                        <div class="select" *ngIf="isSelectedForEdit(row)">
                            <select (change)="statusChanged(row)" [(ngModel)]="row.selected" name="slctStatus-{{idx}}"
                                id="slctStatus-{{idx}}">
                                <option *ngFor="let opt of getStatusSelectOptions(idx)" [ngValue]="opt.listValue">
                                    {{opt.listLabel}}
                                </option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="isEditRowExpanded(row)" class="is-expanded-subsection">
                    <td colspan="3">
                        <span>&nbsp;</span>
                    </td>
                    <td>
                        <div [ngClass]="{'is-disabled' : isExpandedPanelDisabled(row)}">
                            <div>
                                <ul>
                                    <li>
                                        <label class="radio">
                                            <input type="radio" name="study-event" [(ngModel)]="isStudySelected"
                                                (click)="studyEventSelection(row, true)" [value]="true" id="study-{{idx}}">
                                            Study
                                        </label>
                                    </li>
                                    <li>
                                        <label class="radio option-spacer">
                                            <input type="radio" name="study-event" (click)="studyEventSelection(row, false)"
                                                [(ngModel)]="isStudySelected" [value]="false" id="event-{{idx}}"> Event
                                        </label>
                                    </li>

                                </ul>
                                <ul class="has-event-list" [ngClass]="{'is-disabled' : isEventSelectionPanelDisabled(row)}">
                                    <li>
                                        <span class="has-text-weight-bold">Select Events:</span>
                                    </li>
                                    <li *ngFor="let evt of displayEvents; let ix = index">
                                        <label class="checkbox">
                                            <input type="checkbox" [(ngModel)]="selectedEvents[ix]"
                                                (click)="eventSelectionClicked(row, evt, ix)" name="attrEvt-{{ix}}">
                                            {{getEventDisplayName(evt)}} - {{evt.studySchemaEventId}}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                    </td>
                    <td colspan="2">
                        <span>&nbsp;</span>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>