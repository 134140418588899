import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorMessage } from '../model/error-message';
import { StudySetupService } from '../services/study-setup.service';

@Component({
  selector: 'mc-error-validation-display',
  templateUrl: './error-validation-display.component.html',
  styleUrls: ['./error-validation-display.component.scss']
})
export class ErrorValidationDisplayComponent  implements OnInit, OnDestroy {

  showErrSummary = true;

subscriptions: Subscription[] = [];
errorMessages: ErrorMessage[] = [];
  constructor(studySetupService: StudySetupService, ) {
   // super();
    this.subscriptions.push(studySetupService.changeErrorMessagesEmitted$.subscribe(
      msgs => {
        this.errorMessages = [];
        if (msgs && msgs.length > 0) {
          msgs.forEach(eachMsg => {
            const existing = this.errorMessages.findIndex(x => x.message == eachMsg.message) ;
            if (existing == -1) {
              this.errorMessages.push(eachMsg);
            }
          })
        }
      //  this.errorMessages = msgs;
        this.showErrSummary = true;
      }));
  }

  ngOnInit() {
  }

/**
 * Show the Error Summary
 */
  showErrorSummary(): boolean {
    return this.showErrorMessageBlock() && this.showErrSummary;
  }


  showErrorMessageBlock(): boolean {
    return this.errorMessages && this.errorMessages.length > 0
}


  /**
   * Hides the Error Summary
   */
  hideErrorSummary() {
    this.showErrSummary = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }

}
