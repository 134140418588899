<section class="section"  [ngClass]="{'is-hidden': studiesReportFound}">
    <div class="columns">
        <div class="column">
            <h2 class="has-text-weight-bold">
                {{errorMessage}}
            </h2>
        </div>
    </div>
</section>

<div class="search-result" [ngClass]="{'is-hidden': !studiesReportFound}">


<div class=" form-table-container"  >
        <table class="table is-fullwidth is-striped main-Table-width main-Table" role="grid">
            <thead>
                <tr role="row" class="is-selected" >
                  <th colspan="2"><span><b>Reported Accruals for </b> </span> <span *ngIf="studiesReportFound"><b> {{reportStudyAccrualPropResults.studyReferenceNumber}} </b></span></th>
                </tr>
            </thead>    
            <tbody>
                <tr><td>
                <table class="main-Table">
                <tr>
                    <td colspan="2" class="td-padding-first">
                            <span> Current General Accruals </span>
                    </td>
                </tr>
                <tr>
                    <td class="td-padding-second">
                            <span> Total Study Accrual: </span>
                    </td>
                    <td>
                        <span *ngIf="studiesReportFound"> {{reportStudyAccrualPropResults.studyAccrual}} </span>
                    </td>
                </tr>

                <tr>
                    <td colspan="2" class="td-padding-first">
                            <span> Exclusions from Study </span>
                    </td>
                </tr>
                <tr>
                    <td class="td-padding-second">
                            <span> Subject has been cancelled & replaced on protocol: </span>
                    </td>
                    <td>
                        <span *ngIf="studiesReportFound"> {{reportStudyAccrualPropResults.studyExclusions}} </span>
                    </td>
                </tr>
                </table>
                </td>
            </tr>
            <tr>                    
                    <td colspan="2" class="td-padding-first"> 
                    <span> Current Enrollment Event Accruals </span>
                                
                        <div class=" form-table-container" *ngFor="let rowArm of reportStudyAccrualEnrollmentEvent">
                        <table class="main-Table">
                               
                                <tr>
                                    <td class="td-padding-second">
                                        <span> {{rowArm.enrollmentEventLabel}} </span>
                                    </td>
                                    <td>
                                        <span> {{rowArm.enrollmentEventAccrualCount}} </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                    </tr> 

                <tr>
                    
                <td colspan="2" class="td-padding-first"> 
                <span> Current Arm Accruals </span>
                            
                    <div class=" form-table-container" *ngFor="let rowArm of reportStudyAccrualArm">
                    <table class="main-Table">
                           
                            <tr>
                                <td class="td-padding-second">
                                    <span> {{rowArm.armLabel}} </span>
                                </td>
                                <td>
                                    <span> {{rowArm.armAccrualCount}} </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
                </tr> 
               

                <tr>
                    <td colspan="1" class="td-padding-first">
                            <span> Grouping Accruals </span>
                    </td>
                </tr>
                <tr>
                    <td class="td-padding-first" colspan="2">
                            <span> Arm Assignments from Grouping Value </span>
                                    <div class=" form-table-container" *ngFor="let rowGrpAcc of reportStudyAccrualGrp" >
                                        <table class="main-Table">
                                            <tr>
                                                <td class="td-padding-second">
                                                        <span><p [innerHTML]="rowGrpAcc.gfVariableDesc | safeHtml"></p> ( {{rowGrpAcc.gfVariableId}} ) </span><span>{{rowGrpAcc.gfValidValueDesc}} </span>
                                                </td>
                                                <td>
                                                    <span> {{rowGrpAcc.interventionLabel}} </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                    </td>
                </tr>

                <tr>
                    <td class="td-padding-first" colspan="2">
                    <span> Current Grouping Accruals </span>
                        <div class=" form-table-container" *ngFor="let rowCurrGrpAcc of reportStudyAccrualGrp">
                            <table class="main-Table">
                                <tr>
                                    <td class="td-padding-second">
                                            <span> <p [innerHTML]="rowCurrGrpAcc.gfVariableDesc | safeHtml"></p> ( {{rowCurrGrpAcc.gfVariableId}} ) </span><span>{{rowCurrGrpAcc.gfValidValueDesc}} </span>
                                    </td>
                                    <td>
                                        <span> {{rowCurrGrpAcc.accrualCount}} </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
           
            </tbody>

        </table>
</div>


</div>

