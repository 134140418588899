<div class="form-page">
    <div class="columns">
      <div class="column is-2 along-top">
        <span class="label-padding is-small">Study Number:</span>
      </div>
      <div class="column input-width along-top">
        <div class="field is-grouped ">
          <p class="control  " class="no-right-margin">
            <input placeholder="Study Number" id="txt-study-number" name="studyNumber" [(ngModel)]="model.studyNumber" class="form-control input is-small">
          </p>
          <button type="button" class="button is-primary is-small is-radiusless" (click)="getVelosData()">
            <span class="">
              Find
            </span>
          </button>
        </div>
      </div>
      <div *ngIf= "relatedStudies != undefined && relatedStudies.relatedStudies != undefined">
         <div class="column related-studies">
                <table id="related">
                    <tr>
                      <th >Related studies</th>
                      <th >Sponsor Protocol</th>
                    </tr>
                    <tr *ngFor = "let relatedSty of relatedStudies.relatedStudies">
                        <td>{{relatedSty.relatedStudyNumber}}</td>
                        <td>{{relatedSty.sponsorProtocolId}}</td> 
                    </tr>
                  </table>
          </div> 
          <!-- <table id="related" class="column related-studies  is-scrollable">
            <tr>
              <th >Related studies</th>
              <th >Sponsor Protocol</th>
            </tr>
            <tr *ngFor = "let relatedSty of model.relatedStudyInfo.relatedStudies">
                <td>{{relatedSty.relatedStudyNumber}}</td>
                <td>{{relatedSty.sponsorProtocolId}}</td> 
            </tr>
          </table> -->
          
      </div>
    </div>

    <div class="columns">
      <div class="column is-2">
        <span class="label-padding is-small">Research Entity:</span>
      </div>
      <div class=" column field">
        <div class="control input-width">
          <div class="select is-small">
            <div *ngIf = "!isSponsorProtocolAlreadyGenerated">
              <select [(ngModel)]="model.researchEntityId" (change)="checkForMatchingStudyTemplate()">
                <option [value]= null selected>-- Select --</option>
                <option *ngFor="let opt of researchEntityTypes" [value]="opt.id">
                  {{opt.name}}
                </option>
              </select>
            </div>
            <div *ngIf = "isSponsorProtocolAlreadyGenerated">
              <select [(ngModel)]="model.researchEntityId" [disabled] = "true">
                <option *ngFor="let opt of researchEntityTypes" [value]="opt.id">
                  {{opt.name}}
                </option>
              </select>
            </div>
          
          </div>
        </div>
      </div>
    </div>
    
    <div *ngIf="showStudyTemplateQuestion" class="columns">
      <div class="column is-2">
        <span class="label-padding is-small">Apply Study Template:</span> 
      </div>
      <div class=" column field">

        <div class="control ">
          <label class="radio">
            <input type="radio" name="firstname"
              [(ngModel)]="applyStudyTemplate"
              [value]="true"
              id="applyTemplateYes">
              Yes
          </label>
          <label class="radio option-spacer">
            <input type="radio" name="firstname"
             [(ngModel)]="applyStudyTemplate"
             [value]="false"
              id="applyTemplateNo">
              No
          </label>


        </div>

      </div>
    </div>

    <div class="columns">
      <div class="column is-2">
        <span class="label-padding is-small">Data Center:</span>
      </div>
      <div class=" column field">
        <div class="control input-width ">
          <input id="dataCenter" title="can't edit/read only" class="form-control input is-small"  [(ngModel)]="model.dataCenter" [readonly]="true">
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-2">
        <span class="label-padding is-small">Sponsor:</span>
      </div>
      <div class=" column field">
        <div class="control input-width ">
          <input id="sponsor" title="can't edit/read only" class="form-control input is-small " [(ngModel)]="model.sponsor" [readonly]="true">
        </div>
      </div>
    </div>


    <div  *ngIf="isSponsorProtocolAlreadyGenerated">
      <div  class="columns">
        <div class="column is-2">
          <span class="label-padding is-small">System Generated Ref#:</span>
        </div>
        <div class=" column field">
          <div class="control input-width">
            <div class="select is-small">
              <select [(ngModel)]="selectedSysGeneratedRefNum" [disabled] = "isSponsorProtocolAlreadyGenerated">
                <option *ngFor="let opt of refList" [value]="opt.listValue">
                  {{opt.listLabel}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div  class="columns">
        <div class="column is-2">
          <span class="label-padding is-small">Sponsor ProtocolId:</span>
        </div>
        <div class=" column field">
          <div class="control input-width ">
            <input  title="can't edit/read only" class="form-control input is-small " [(ngModel)]="model.referenceNumber" [readonly]="true">
          </div>
        </div>
      </div>

  </div>

  <div  *ngIf=" !isSponsorProtocolAlreadyGenerated &&  isStudySectionAvailable()" class="columns">
    <div class="column is-2">
      <span class="label-padding is-small">System Generated Ref#:</span>
    </div>
    <div class=" column field">
      <div class="control input-width">
        <div class="select is-small">
          <select [(ngModel)]="selectedSysGeneratedRefNum">
              <option value="null" selected>-- Select --</option>
            <option *ngFor="let opt of refList" [value]="opt.listValue">
              {{opt.listLabel}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>


   <!--- -------- Assign Reference number -------------------------- -->
  
   <div *ngIf="listStudySections != null" class="assign-ref-margin">
    <div class="columns">
      <div class="column is-6 ">
        <span class="label-padding is-small">Assign Reference Number:</span>
      </div>
    </div>

    <div *ngIf="isStudySectionEmpty()">
      <div class="columns">
        <div class="column is-1 "></div>
        <div class="column is-narrow min-label-width">
          <span class="label-padding is-small">Reference Number:</span>
        </div>
        <div class=" column ">
          <div class="field-body  ">
            <div class="control  ">
              <input placeholder="Reference Number"  id="txt-disease-site" [(ngModel)]="model.referenceNumber"
                class="form-control input is-small">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf = "isSysGeneratedRefNumSelected()">
    <div *ngFor="let section of listStudySections; let i = index">
        <div class="columns">
          <div class="column is-1 "></div>
          <div class="column is-narrow min-label-width">
            <span class="label-padding is-small">{{section.sectionLabel}}:</span>
          </div>
          <div class=" column ">
            <div class="field-body  ">
              <div class="control">
                <div class="select is-small">
                  <select [(ngModel)]="sectionSelectionModels[i]" (change)="onSelectChange(section, i)">
                      <option [ngValue]="undefined" selected>-- Select --</option>
                    <option *ngFor="let opt of section.sectionLabelOptions" [ngValue]="opt">
                      {{opt.codeDesc}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="columns" *ngIf="exampleReference">
        <div class="column is-1 "></div>
          <div class="column is-narrow min-label-width">
            <span class="label-padding is-small">Example:</span>
        </div>
        <div class=" column field">
            <div class="control example-padding">
            <p>{{exampleReference}} - {{_selectedStudyType.code}}</p>

          </div>
        </div>
      </div>
  </div>
</div>

  

      
    <div class="columns">
      <div class="column is-2">
        <span class="label-padding is-small">Acronym:</span>
      </div>
      <div class=" column field">
        <div class="control input-width ">
          <input title="can't edit/read only" [(ngModel)]="model.acronym" id="txt-acronym" class="form-control input is-small " [readonly]="true">
        </div>
      </div>
    </div>
  
    <div class="columns ">
      <div class="column is-2">
        <span class="label-padding is-small">Short Title:</span>
      </div>
      <div class=" field column ">
        <div class="field-body ">
          <div class="field">
            <div class="control ">
              <input title="can't edit/read only" name="shortdesc" id="txt-short-desc" [(ngModel)]="model.shortDescription" class="form-control input description is-small" [readonly]="true">
            </div>
          </div>
        </div>
      </div>
      <div class="column is-1">
      </div>
  
    </div>
    <div class="columns">
      <div class="column is-2">
        <span class="label-padding is-small">Study Title:</span>
      </div>
      <div class=" column ">
        <div class="field  ">
          <p class="control ">
            <textarea title="can't edit/read only" name="longdesc" id="txt-long-desc" rows="3" [(ngModel)]="model.longDescription"
              class="textarea is-small" readonly="readonly">
                      </textarea>
          </p>
        </div>
      </div>
      <div class="column is-1"> </div>
  
    </div>
  
    <div class="columns">
      <div class="column is-2">
  
      </div>
      <div class="column">
        <button class="button is-primary is-radiusless" (click)="createNewStudy()">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
          <span>Create New</span>
        </button>
        <button class="button is-cancel is-radiusless" (click)="cancelChanges()">
            <span class="icon">
              <i class="fas fa-ban"></i>
            </span>
            <span>Cancel</span>
          </button>
  
      </div>
    </div>
  </div>
  