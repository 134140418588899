import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { HeaderBar } from 'app/common/model/header-bar';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { FormsDashboardService } from 'app/common/services/forms/forms-dashboard.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LibraryVariable } from '../../common/model/library-variable';
import { VariableDataType } from '../../common/model/variable-datatype';




@Component({
  selector: 'mc-library-variable',
  templateUrl: './library-variable.component.html',
  styleUrls: ['./library-variable.component.scss']
})
export class LibraryVariableComponent implements OnInit, OnDestroy {

  public libraryVariable: LibraryVariable;

  public variableList: LibraryVariable[] = [];

  public dataTypeList: VariableDataType[] = [];

  public subscriptions: Subscription[] = [];

  public rows: Array<any> = [];

  /**
   * Index of the row selected
   */
  selectedRowIndex = -1;

    /**
   * variable selected
   */
  selectedVariable: LibraryVariable;

  /**
   * model for the header bar
   */
  headerModel: HeaderBar = new HeaderBar('Forms Management - Variable Library', '', false, '', false);



  public columns: Array<any> = [
    {
      title: 'Variable Number', name: 'variableId',
      sort: '',
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Number' }
    },
     {
      title: 'Name', name: 'variableName', sort: '',
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Name', sort: '' }
    },
    {
      title: 'Alternate Name', name: 'variableAlternateName',
      sort: '',
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Alternate Name' }
    }
  ];

  /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['is-striped', 'is-borderless']
  };

  isModeReadOnly = false;

  constructor(
    private formsDashboardService: FormsDashboardService,
    private viewContainer: ViewContainerRef
  ) { }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }


  ngOnInit() {

    this.libraryVariable = {
      'variableId': 0,
      'variableName': '',
      'variableAlternateName': '',
      'variableDesc': '',
      'variableDataTypeId': null,
      'variableDataTypeName': null,
      'dictionaryCode': '',
      'dictionaryVersion': null,
      'dictionaryGroup': '',
      'externalVariableNum': null,
      'activeDate': null,
      'tupleVersion': 0,
      'modifiedById': ''
    }
    this.isModeReadOnly = false;

    const observables: Observable<any>[] = [];

    observables.push(this.formsDashboardService.getLibraryVariables()
    .pipe(tap(results => {
      this.variableList = results as LibraryVariable[];
      // this.onChangeTable(this.config);
      this.onChangeTable(this.config);
    })));

    observables.push(this.formsDashboardService.getLibraryVariableDataTypes()
    .pipe(tap(results => {
      this.dataTypeList = results as VariableDataType[];
    })));

    this.subscriptions.push(forkJoin(observables)
    .subscribe(function() {
    }, (err) => {console.log(err) },
    () => {
      })
    );
  }

  /**
   * Handles the changes on the ui for the displayed table
   * @param config
   * @param page
   */
  public onChangeTable(config: any): any {
    let column: any = null;
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    // if (column != null) {
    //   this.columns.forEach((col: any) => {
    //     if (col.name !== column.name && col.sort !== false) {
    //       col.sort = '';
    //     }
    //   });
    //   Object.assign(this.config.sorting, config.sorting);
    // }

    const filteredData = this.changeFilter(this.variableList, this.config);
    this.rows = filteredData;
  }

    /**
   * Returns the filtered data
   * @param data
   * @param config
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name] != null) {
            if (column.name == 'variableId') {
              return item[column.name].toString().match(column.filtering.filterString);
            } else {
              return item[column.name].toLowerCase().match(column.filtering.filterString.toLowerCase());
            }
          } else {return ''.match(column.filtering.filterString.toLowerCase()); }
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name] != null && item[column.name].toString().match(this.config.filtering.filterString)) {
              flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

/**
 * Called when a row of the variable library table is clicked/selected
 * @param row - the row (LibraryVariable) that is selected
 * @param rowIdx - the index of the variableList that was selected
 */
  public selectVariable(row: LibraryVariable, rowIdx: number) {
    if (this.selectedRowIndex == rowIdx) {
      this.selectedRowIndex = -1;
      this.selectedVariable = null;
      this.isModeReadOnly = false;
      this.libraryVariable = {
        'variableId': 0,
        'variableName': '',
        'variableAlternateName': '',
        'variableDesc': '',
        'variableDataTypeId': null,
        'variableDataTypeName': null,
        'dictionaryCode': '',
        'dictionaryVersion': null,
        'dictionaryGroup': '',
        'externalVariableNum': null,
        'activeDate': null,
        'tupleVersion': 0,
        'modifiedById': ''
      }
    } else {
      this.selectedRowIndex = rowIdx;
      this.selectedVariable = this.rows[rowIdx];
      this.libraryVariable = row;
      this.isModeReadOnly = true;
    }
  }

/**
 * Create new variable with the passed in data
 * and pops up error message incase of failure.
 */
createNewVariable() {
  this.subscriptions.push(
  this.formsDashboardService.addLibraryVariables(this.libraryVariable)
    .subscribe(results => {
      this.libraryVariable = results as LibraryVariable;
      const varname = this.dataTypeList.find(id => id.variableDataTypeId == this.libraryVariable.variableDataTypeId);
      this.libraryVariable.variableDataTypeName = varname.variableDataTypeName;
      const modalArg = new ModalDialogArg('custom-save', 'Save', null);
      this.formsDashboardService.showModalDialog(this.viewContainer, modalArg)
        .subscribe(actionResult => {
        });
        this.variableList.push(this.libraryVariable);
        this.libraryVariable = {
          'variableId': 0,
          'variableName': '',
          'variableAlternateName': '',
          'variableDesc': '',
          'variableDataTypeId': null,
          'variableDataTypeName': null,
          'dictionaryCode': '',
          'dictionaryVersion': null,
          'dictionaryGroup': '',
          'externalVariableNum': null,
          'activeDate': null,
          'tupleVersion': 0,
          'modifiedById': ''
        }
        this.onChangeTable(this.config);
    }, error => {
      const modalArg = new ModalDialogArg('modal-warn', 'CreateFailed', null);
      this.formsDashboardService.showModalDialog(this.viewContainer, modalArg)
        .subscribe(actionResult => {
          // do nothing
        });
    }));
}



}
