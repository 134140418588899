import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Country } from 'app/common/model/country';
import { Demographic } from 'app/common/model/demographic';
import { StudyMedDRAClassification } from 'app/common/model/demographic-settings';
import { Ethnicity } from 'app/common/model/ethnicity';
import { Gender } from 'app/common/model/gender';
import { MedDraOptionModel } from 'app/common/model/medDra-option-model';
import { PaymentMethod } from 'app/common/model/payment-method';
import { PossibleValuesHierarchy } from 'app/common/model/possible-values-hierarchy';
import { Race } from 'app/common/model/race';
import { NameSettingOptionModel, StandardOptionModel } from 'app/common/model/standard-option-model';
import { DemographyService } from 'app/common/services/demography/demography.service';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'mc-common-demographic-settings',
  templateUrl: './common-demographic-settings.component.html',
  styleUrls: ['./common-demographic-settings.component.scss']
})
export class CommonDemographicSettingsComponent implements OnInit, OnDestroy {

  @Input() isReadOnly: boolean = false;
  @Input() firstName: Demographic;
  @Input() lastName: Demographic;
  @Input() middleName: Demographic;
  @Input() gender: Gender;
  @Input() race: Race;
  @Input() ethnicity: Ethnicity;
  @Input() dob: Demographic;
  @Input() country: Country;
  @Input() postalCode: Demographic;
  @Input() meddra: StudyMedDRAClassification;
  @Input() methodOfPayment: PaymentMethod;
  @Input() otherSubjectId: Demographic;
  @Input() duplicatePatient: Demographic;


  firstNameOptions: NameSettingOptionModel[];
  middleNameOptions: NameSettingOptionModel[];
  lastNameOptions: NameSettingOptionModel[];
  genderStandards: StandardOptionModel[];
  raceStandards: StandardOptionModel[];
  ethnicityStandards: StandardOptionModel[];
  methodPaymentStandards: StandardOptionModel[];
  countryStandards: StandardOptionModel[];

  dobOptions: NameSettingOptionModel[];
  postalCodeOptions: NameSettingOptionModel[];
  medDraOptions: MedDraOptionModel[];
  medDraDiseaseOptions: PossibleValuesHierarchy[] = [];
  otherSubjectIdOptions: NameSettingOptionModel[];
  duplicatePatientOptions: NameSettingOptionModel[];


  showFirstNameOptions = false;
  showMiddleNameOptions = false;
  showLastNameOptions = false;

  showGenderStandards = false;
  showRaceStandards = false;
  showEthnicityStandards = false;
  showMethodOfPaymentStandards = false;

  showDobOptions = false;
  showCountryStandards = false;
  showPostalCodeOptions = false;
  showMedDraOptions = false;
  showMedDraDiseaseOptions = false;
  showOtherSubjectIdOptions = false;
  showDuplicatePatientOptions = false;


  reqFlagProperty = 'required';
  standardProperty = 'standard';
  demographicIdProperty = 'demographicId';
  defaultDemographicIdKey = 'studyDemographicId';

  meddraIdProperty = 'medDRAVersionId';



  firstNameProperty = 'firstName';
  lastNameProperty = 'lastName';
  middleNameProperty = 'middleName';
  genderProperty = 'gender';
  raceProperty = 'race';
  ethnicityProperty = 'ethnicity';
  dobProperty = 'dob';
  countryProperty = 'country';
  postalCodeProperty = 'postalCode';
  meddraProperty = 'meddra';
  methodOfPaymentProperty = 'methodOfPayment';
  otherSubjectIdProperty = 'otherSubjectId';
  duplicatePatientProperty = 'duplicatePatient';

  variableData = '';

  lookupMap: { [propertyName: string]: { optionBoolProperty: string, selectionsProperty: string; }; } = {};

  subscriptions: Subscription[] = [];


  leftColumnClass: string = "is-4";
  //isReadOnly = false;

  /**
   * Settings for the multiple selections dropdown that displays disease codes
   */
  medDraDiseaseDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'description',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 20,
    allowSearchFilter: true,
  };

  private saveForkjoinObservable: Subscription;

  constructor(
    private demographyService: DemographyService
  ) {

  }

  ngOnInit() {

    //initialize date
    this.initializeDataObjects();

    const observables: Observable<any>[] = [];
    observables.push(this.initGenderStandards());
    observables.push(this.initRaceStandards());
    observables.push(this.initEthnicityStandards());
    observables.push(this.initMethodOfPaymentStandards());
    observables.push(this.initFirstNameOptions());
    observables.push(this.initLastNameOptions());
    observables.push(this.initMiddleNameOptions());
    observables.push(this.initDobOptions());
    observables.push(this.initCountryStandards());
    observables.push(this.initPostalCodeOptions());
    observables.push(this.initMedDRAOptions());
    observables.push(this.initOtherSubjectIdOptions());
    observables.push(this.initDuplicatePatientOptions());
    // once all the data has been retrieved set the display
    // property and clone
    this.subscriptions.push(
      forkJoin(observables)
        .subscribe(() => {
        }, (err) => { console.log(err); },
          () => {
            //this.initializeDataObjects();
          }
        ));

  }


  ngOnChanges(changes: SimpleChanges) {
    this.initializeDataObjects();
  }



  /**
   * initialize the gender standards
   */
  initGenderStandards(): Observable<any> {
    const obs =
      this.demographyService.getGenderStandards()
        .pipe(tap(result => {
          this.genderStandards = result;
          if (this.genderStandards.length != 1) {
            this.genderStandards.unshift(new StandardOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }

  /**
    * initialize the Race standards
    */
  initRaceStandards(): Observable<any> {
    const obs =
      this.demographyService.getRaceStandards()
        .pipe(tap(result => {
          this.raceStandards = result;
          if (this.raceStandards.length != 1) {
            this.raceStandards.unshift(new StandardOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }


  /**
   * initialize the Ethnicity standards
   */
  initEthnicityStandards(): Observable<any> {
    const obs =
      this.demographyService.getEthnicityStandards()
        .pipe(tap(result => {
          this.ethnicityStandards = result;
          if (this.ethnicityStandards.length != 1) {
            this.ethnicityStandards.unshift(new StandardOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }

  /**
    * initialize the PaymentMethod standards
    */
  initMethodOfPaymentStandards(): Observable<any> {
    const obs =
      this.demographyService.getMethodPaymentStandards()
        .pipe(tap(result => {
          this.methodPaymentStandards = result;
          if (this.methodPaymentStandards.length != 1) {
            this.methodPaymentStandards.unshift(new StandardOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }

  /**
   * Initiliaze the First Name options
   */
  initFirstNameOptions(): Observable<any> {
    const obs =
      this.demographyService.getFirstNameOptions()
        .pipe(tap(result => {
          this.firstNameOptions = result;
          if (this.firstNameOptions.length != 1) {
            this.firstNameOptions.unshift(new NameSettingOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }

  /**
   * initialize the LastName Options
   */
  initLastNameOptions(): Observable<any> {
    const obs =
      this.demographyService.getLastNameOptions()
        .pipe(tap(result => {
          this.lastNameOptions = result;
          if (this.lastNameOptions.length != 1) {
            this.lastNameOptions.unshift(new NameSettingOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }


  /**
   * initialize the MiddleName Options
   */
  initMiddleNameOptions(): Observable<any> {
    const obs =
      this.demographyService.getMiddleNameOptions()
        .pipe(tap(result => {
          this.middleNameOptions = result;
          if (this.middleNameOptions.length != 1) {
            this.middleNameOptions.unshift(new NameSettingOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }

  /**
   * initialize the DateOfBirth Options
   */
  initDobOptions(): Observable<any> {
    const obs =
      this.demographyService.getDobOptions()
        .pipe(tap(result => {
          this.dobOptions = result;
          /*   if (this.dobOptions.length != 1) {
              this.dobOptions.unshift(new NameSettingOptionModel(null, "-- Select --"));
            } */
        }));
    return obs;
  }

  /**
   * Initialize the Country standards
   */
  initCountryStandards(): Observable<any> {
    const obs =
      this.demographyService.getCountryStandards()
        .pipe(tap(result => {
          this.countryStandards = result;
          if (this.countryStandards.length != 1) {
            this.countryStandards.unshift(new StandardOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }

  /**
   * initialize the PostalCode Options
   */
  initPostalCodeOptions(): Observable<any> {
    const obs =
      this.demographyService.getPostalCodeOptions()
        .pipe(tap(result => {
          this.postalCodeOptions = result;
          if (this.postalCodeOptions.length != 1) {
            this.postalCodeOptions.unshift(new NameSettingOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }

  /**
   * initialize the MedDRA Options
   */
  initMedDRAOptions(): Observable<any> {
    const obs =
      this.demographyService.getMedDRAOptions()
        .pipe(tap(result => {
          if (result && result.length > 0) {
            result.forEach(meddra => {
              meddra.version = 'Version ' + meddra.version;
            });
          }
          this.medDraOptions = result;
          if (this.medDraOptions.length != 1) {
            this.medDraOptions.unshift(new MedDraOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }

  /**
   * initialize the OtherSubjectId Options
   */
  initOtherSubjectIdOptions(): Observable<any> {
    const obs =
      this.demographyService.getOtherSubjectIdOptions()
        .pipe(tap(result => {
          this.otherSubjectIdOptions = result;
          if (this.otherSubjectIdOptions.length !== 1) {
            this.otherSubjectIdOptions.unshift(new NameSettingOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }


  /**
   * initialize the DuplicatePatient Options
   */
  initDuplicatePatientOptions(): Observable<any> {
    const obs =
      this.demographyService.getDuplicatePatientOptions()
        .pipe(tap(result => {
          this.duplicatePatientOptions = result;
          if (this.duplicatePatientOptions.length != 1) {
            this.duplicatePatientOptions.unshift(new NameSettingOptionModel(null, '-- Select --'));
          }
        }));
    return obs;
  }


  /**
   * Main method to initializs all the settings properties
   */
  initializeDataObjects() {
    this.configureLocalObjects();
    if (this.meddra.medDRAVersionId && this.meddra.medDRAVersionId > 0) {
      this.medDraSelected();
    }
  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    );
  }

  /**
   * Initializes the local data and sets the display variables
   */
  configureLocalObjects() {
    this.configureLookupMap();
    this.setDisplayVariables();
  }


  /**
   * configures the lookup map. The map will help in locating the model and the options
   */
  configureLookupMap() {
    this.lookupMap[this.firstNameProperty] = { optionBoolProperty: 'showFirstNameOptions', selectionsProperty: 'firstNameOptions' };
    this.lookupMap[this.lastNameProperty] = { optionBoolProperty: 'showLastNameOptions', selectionsProperty: 'lastNameOptions' };
    this.lookupMap[this.middleNameProperty] = { optionBoolProperty: 'showMiddleNameOptions', selectionsProperty: 'middleNameOptions' };
    this.lookupMap[this.genderProperty] = { optionBoolProperty: 'showGenderStandards', selectionsProperty: 'genderStandards' };
    this.lookupMap[this.raceProperty] = { optionBoolProperty: 'showRaceStandards', selectionsProperty: 'raceStandards' };
    this.lookupMap[this.ethnicityProperty] = { optionBoolProperty: 'showEthnicityStandards', selectionsProperty: 'ethnicityStandards' };
    this.lookupMap[this.dobProperty] = { optionBoolProperty: 'showDobOptions', selectionsProperty: 'dobOptions' };
    this.lookupMap[this.countryProperty] = { optionBoolProperty: 'showCountryStandards', selectionsProperty: 'countryStandards' };
    this.lookupMap[this.postalCodeProperty] = { optionBoolProperty: 'showPostalCodeOptions', selectionsProperty: 'postalCodeOptions' };
    this.lookupMap[this.meddraProperty] = { optionBoolProperty: 'showMedDraOptions', selectionsProperty: 'medDraOptions' };
    this.lookupMap[this.methodOfPaymentProperty] = { optionBoolProperty: 'showMethodOfPaymentStandards', selectionsProperty: 'methodPaymentStandards' };
    this.lookupMap[this.otherSubjectIdProperty] = { optionBoolProperty: 'showOtherSubjectIdOptions', selectionsProperty: 'otherSubjectIdOptions' };
    this.lookupMap[this.duplicatePatientProperty] = { optionBoolProperty: 'showDuplicatePatientOptions', selectionsProperty: 'duplicatePatientOptions' };
  }



  /**
   * Set the display variables to show/hide the drop-downs
   */
  setDisplayVariables() {
    if (this[this.firstNameProperty][this.demographicIdProperty] != null) {
      this.showFirstNameOptions = this[this.firstNameProperty].required ? true : null;
    } else {
      this.showFirstNameOptions = false;
    }

    if (this[this.middleNameProperty][this.demographicIdProperty] != null) {
      this.showMiddleNameOptions = this[this.middleNameProperty].required ? true : null;
    } else {
      this.showMiddleNameOptions = false;
    }

    if (this[this.lastNameProperty][this.demographicIdProperty] != null) {
      this.showLastNameOptions = this[this.lastNameProperty].required ? true : null;
    } else {
      this.showLastNameOptions = false;
    }

    if (this[this.genderProperty].genderClassificationId != null) {
      this.showGenderStandards = this[this.genderProperty].required ? true : null;
    } else {
      this.showGenderStandards = false;
    }

    if (this[this.raceProperty].raceCodeClassificationId != null) {
      this.showRaceStandards = this[this.raceProperty].required ? true : null;
    } else {
      this.showRaceStandards = false;
    }

    if (this[this.ethnicityProperty].ethnicityClassificationId != null) {
      this.showEthnicityStandards = this[this.ethnicityProperty].required ? true : null;
    } else {
      this.showEthnicityStandards = false;
    }

    if (this[this.methodOfPaymentProperty].paymentMethodClassificationId != null) {
      this.showMethodOfPaymentStandards = this[this.methodOfPaymentProperty].required ? true : null;
    } else {
      this.showMethodOfPaymentStandards = false;
    }

    if (this[this.dobProperty][this.demographicIdProperty] != null) {
      this.showDobOptions = this[this.dobProperty].required ? true : null;
    } else {
      this.showDobOptions = false;
    }

    if (this[this.countryProperty].countryClassificationId != null) {
      this.showCountryStandards = this[this.countryProperty].required ? true : null;
    } else {
      this.showCountryStandards = false;
    }

    if (this[this.postalCodeProperty][this.demographicIdProperty] != null) {
      this.showPostalCodeOptions = this[this.postalCodeProperty].required ? true : null;
    } else {
      this.showPostalCodeOptions = false;
    }

    if (this[this.meddraProperty][this.meddraIdProperty] != null) {
      this.showMedDraOptions = this[this.meddraProperty].required ? true : null;
    } else {
      this.showMedDraOptions = false;
    }

    if (this[this.otherSubjectIdProperty][this.demographicIdProperty] != null) {
      this.showOtherSubjectIdOptions = this[this.otherSubjectIdProperty].required ? true : null;
    } else {
      this.showOtherSubjectIdOptions = false;
    }

    if (this[this.duplicatePatientProperty][this.demographicIdProperty] != null) {
      this.showDuplicatePatientOptions = this[this.duplicatePatientProperty].required ? true : null;
    } else {
      this.showDuplicatePatientOptions = false;
    }
  }


  //   /**
  //    * Sets the property on the model for required boolean
  //    * @param modelProperty The name of the model whose value is being reset
  //    * @param otherProperty default property is demographicId but if there's another property
  //    */
  setOptionRequired(modelProperty: string, otherProperty: string = '') {
    if (_.isEmpty(this.lookupMap)) {
      return false;
    }
    const modelData = this.lookupMap[modelProperty];
    const model = this[modelProperty];
    model[this.reqFlagProperty] = true;
    if (otherProperty == undefined || otherProperty == '') {
      if (model[this.demographicIdProperty] == undefined || model[this.demographicIdProperty] == '') {
        model[this.demographicIdProperty] = null;
      }
    } else {
      if (model[otherProperty] == undefined || model[otherProperty] == '') {
        model[otherProperty] = null;
      }
    }
    this.setFirstOptionAsSelected(modelProperty, otherProperty);
  }

  //   /**
  //    * Sets the optional choice for the selected model
  //    * @param modelProperty The name of the model whose option is being set
  //    * @param otherProperty default property is demographicId but if there's another property
  //    */
  setOptionOptional(modelProperty: string, otherProperty: string = '') {
    if (_.isEmpty(this.lookupMap)) {
      return false;
    }
    const modelData = this.lookupMap[modelProperty];
    const model = this[modelProperty];
    if (modelData) {
      this[modelData.optionBoolProperty] = null;
      model[this.reqFlagProperty] = false;
      if (otherProperty == undefined || otherProperty == '') {
        if (model[this.demographicIdProperty] == undefined || model[this.demographicIdProperty] == '') {
          model[this.demographicIdProperty] = null;
        }
      } else {
        if (model[otherProperty] == undefined || model[otherProperty] == '') {
          model[otherProperty] = null;
        }
      }
    }
    this.setFirstOptionAsSelected(modelProperty, otherProperty);

  }



  //   /**
  //    * Sets the properties on the model when the NO option is chosen on the UI
  //    * @param modelProperty The name of the model whose properties need to be set to No
  //    * @param otherProperty default property is demographicId but if there's another property
  //    */
  setOptionNo(modelProperty: string, otherProperty: string = '') {
    if (_.isEmpty(this.lookupMap)) {
      return false;
    }
    const model = this[modelProperty];
    if (otherProperty == undefined || otherProperty == '') {
      model[this.demographicIdProperty] = null;
    } else {
      model[otherProperty] = null;
    }
    model[this.reqFlagProperty] = null;
  }




  //   /**
  //    * Sets the first item of the options list as the selected item
  //    * if there's just a single option to choose
  //    * @param modelProperty The name of the model to be processed
  //    * @param otherProperty Optional primaryId property other than demographicId
  //    */
  setFirstOptionAsSelected(modelProperty: string, otherProperty: string = '') {
    if (_.isEmpty(this.lookupMap)) {
      return;
    }
    const model = this[modelProperty];
    const modelData = this.lookupMap[modelProperty];
    if (modelData) {
      const optionSelection = this[modelData.selectionsProperty];
      // If the options list contains a single item,
      // the demographicId is the demographicId of the first choice
      // for non demography dto, it's the "otherProperty"
      if (optionSelection != null && optionSelection.length == 1) {
        if (otherProperty == undefined || otherProperty == '') {
          model[this.demographicIdProperty] = optionSelection[0][this.demographicIdProperty];
        } else {
          if (optionSelection[0].versionId) {
            // this is a medDra Selection
            model[otherProperty] = optionSelection[0].versionId;
            this.medDraSelected();
          } else {
            model[otherProperty] = optionSelection[0][this.standardProperty];
          }
        }
      }
    }
  }



  //   /**
  //    * Evaluates if the options for the model have values that should be displayed
  //    * @param modelProperty the name of the model whose options are to be evaluated
  //    * @returns true if the options can be displayed
  //    */
  showOptionsSection = function (modelProperty: string): boolean {
    if (_.isEmpty(this.lookupMap)) {
      return false;
    }
    const modelData = this.lookupMap[modelProperty];
    const model = this[modelProperty];
    const optionSelection = this[modelData.selectionsProperty];
    const showOptionBoolean = this[modelData.optionBoolProperty];
    return (showOptionBoolean != false) &&
      (optionSelection != undefined && optionSelection != null &&
        optionSelection.length > 0);
  };



  //   /***  Begin MedDRA selections  */

  //   /**
  //    * Handler for the meddra version drop down select box
  //    */
  medDraSelected() {
    if (this.meddra.medDRAVersionId && this.meddra.medDRAVersionId > 0) {
      // fetch the disease Codes
      this.subscriptions.push(this.demographyService.getMedDRADiseaseCodes(this.meddra.medDRAVersionId)
        .subscribe(results => {
          this.medDraDiseaseOptions = results;
          this.showMedDraDiseaseOptions = (results && results.length > 0);
        }));
    }
  }



}

