<mc-header-bar [headerModel]="headerModel" (saveEvent)="saveChanges()" (cancelEvent)="cancelChanges()">
</mc-header-bar>

<div class="main-section" id="mainSection">

  <div class="header-panel">
    <div class=" has-header">
      <div class="nav-return-bar">
        <ul>
          <li class="has-link ">
            <a (click)="navigateBack()">
              <span class="icon">
                <i class="fas fa-arrow-left"></i>
              </span>
              <span class="link-text">Back to Subject Landing</span>
            </a>
          </li>

        </ul>
      </div>
    </div>
  </div>


  <div class="  form-page columns is-marginless has-content-data">
    <div class="column  is-12 ">
      <div class="main">
        <div>

          <div class=" edit-bar-section">
            <div class="has-title">
              <h3> Manage Approvals</h3>
            </div>
            <div class="has-name has-left-padding">
              <span> Initials:
                <span class="has-text-weight-bold" *ngIf="approvalModel">
                  {{ approvalModel.initials}}
                </span>
              </span>
              <span class="has-event-name">&nbsp;Event:
                <span class="has-text-weight-bold" *ngIf="approvalModel">
                  {{ approvalModel.eventName}}
                </span>
              </span>
            </div>
            <div class="has-edit-bar is-pulled-right ">
              <mc-edit-bar [selectionCount]="selectedIds.length" [actionSetting]="actionSetting"
                (selectedActionEvent)="selectedEditAction($event)" [editSelectionCount]="selectedIds.length">
              </mc-edit-bar>
            </div>
          </div>


          <table class="table is-fullwidth is-borderless is-striped approval-table " role="grid">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="first-column">Approval Type</th>
                <th class="second-column">Approval Status</th>
                <th class="third-column">Comments</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let row of data; let idx = index">
                <tr [ngClass]="{'is-selected':isRowSelected(row),'is-error-row': hasError(row[propertyId])}"
                (click)="rowSelectedClick(row)">
                  <td class="is-error-cell">
                    <div (click)="ignoreClick($event)">
                      <mc-error-validation-display-row [idToEvaluate]="row[propertyId]">
                      </mc-error-validation-display-row>
                    </div>
                  </td>
                  <td>
                    {{row.approvalType}}
                  </td>
                  <td  [ngClass]="{'selected':isInfoSelected(row)}" class="display-message is-display-message-multiline is-display-message-bottom"
                  [attr.data-display-message]="getInfoMessage(row)">
                    <span *ngIf="!isSelectedForEdit(row) && row.approvalStatus">
                      {{row.approvalStatus.name}}
                    </span>
                    <div class="control" *ngIf="isSelectedForEdit(row)">
                      <div class="select ">
                        <select [(ngModel)]=" row.approvalStatus" [compareWith]="compareFnStatusId" (change)="noCommentsForPending(row)"
                          (click)="ignoreClick($event)">
                          <option *ngIf="!(row[propertyId] > 0)" [ngValue]=null selected>Select..</option>
                          <option *ngFor="let code of approvalStatusList" [ngValue]="code">
                            {{code.name}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span *ngIf="!isSelectedForEdit(row) ">
                      {{row.comments}}
                    </span>
                    <div *ngIf="isSelectedForEdit(row) && !isStatusPending(row)" class="field">
                      <div class="control"> 
                        <input [(ngModel)]="row.comments" type="text" id="comments-{{idx}}" maxlength="100"
                          class="input comment-input"(click)="ignoreClick($event)">
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>



    </div>
  </div>




</div>
