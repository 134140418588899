import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  SearchInputComponent,
  SearchInputOption,
} from 'app/common/controls/search-input/search-input.component';
import { Code } from 'app/common/model/code';
import { Form } from 'app/common/model/form';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { ReportAttributeRequest } from 'app/common/model/report-attribute-request';
import { SchemaReportCriteria } from 'app/common/model/schema-report-criteria';
import { StudyNetworkReportCriteria } from 'app/common/model/study-network-report-criteria';
import { ReportsService } from 'app/common/services/reports.service';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { StudyTemplateService } from 'app/common/services/study-template/study-template.service';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import * as globalConst from '../../common/model/app-constants';

@Component({
  selector: 'mc-rpt-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
})
export class ReportModalComponent implements OnInit, OnDestroy {
  @Input() showModal: boolean;
  @Input() title: string;
  @Output() getReportData = new EventEmitter();
  showDownloadButton: boolean;
  showGenerateButton: boolean;
  showGenerateButton2: boolean;
  reportName: string;
  isAttributeTile: boolean;
  studyAttributeRequest: ReportAttributeRequest = {
    studyAttributes: [],
    researchEntity: null,
    studyStatus: null,
  };

  reportLandingPageEnum: number = globalConst.LANDING_PAGE_ENUM_STUDY; // LANDING_PAGE_ENUM_REPORT;

  txtStudyID = '';
  subscriptions: Subscription[] = [];
  forms: Form[] = [];
  filteredForms: Form[] = [];
  oldForm: Form = null;
  newForm: Form = null;
  studyId: number;
  errorMessage: string = '';

  schemaReportCriteria: SchemaReportCriteria = new SchemaReportCriteria([], [], [], []);
  studyNetworkReportCriteria: StudyNetworkReportCriteria = new StudyNetworkReportCriteria([], [], [], []);

  schemaEvents: Code[] = [];
  attributeEvents: Code[] = [
    {
      id: 17,
      code: 'Blinded',
      name: 'Blinded',
    },
    {
      id: 29,
      code: 'ArmAlias',
      name: 'Arm Alias',
    },
    {
      id: 10,
      code: 'DisplayArm',
      name: 'Display Arm',
    },
    {
      id: 28,
      code: 'StratFactorBlinded',
      name: 'Is the Blinded Strat factor being utilized ?',
    },
    {
      id: 44,
      code: 'specialsf',
      name: 'Arm Balancing Stratification Factor (CTSU)',
    },
    {
      id: 45,
      code: 'GfAssignmentForm',
      name: 'Use the Intervention Assignment Form to assign treatment?',
    },
  ];

  interventionOptions: Code[] = this.attributeEvents.filter(x => x.code === "Blinded" || x.code === "ArmAlias" || x.code === "DisplayArm");

  interventionSelected: Code = null;
  interventionSelectedOld: Code = null;

  researchEntity: Code[] = [];
  selectedResearchEntity: Code = null;

  studyStatuses: Code[] = [];
  studyAttributes: Code[] = [];
  participatingOrgs: Code[] = [];
  participatingOrgStatuses: Code[] = [];

  @ViewChild(SearchInputComponent)
  private searchInputComponent: SearchInputComponent;

  constructor(
    // private userService : UserService
    private reportService: ReportsService,
    private studySetupService: StudySetupService,
    private studyTemplateService: StudyTemplateService,
    private viewContainer: ViewContainerRef,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.studyTemplateService.getResearchEntities().subscribe(res => {
        this.researchEntity = res;
      }),
    );
    this.subscriptions.push(
      this.studyTemplateService.getStudyStatuses().subscribe(res => {
        this.studyStatuses = res;
      }),
    );
    this.subscriptions.push(
      this.studySetupService.getStudyAttributesCode().subscribe(res => {
        this.studyAttributes = res;
        this.studyAttributes.forEach(s => {
          s.name = s.code;
        });
      }),
    );
  }

  generateReport(): void {
    this.generateStudyAccrualReport();
  }

  generateAttributeReport() {
    this.reportService
      .getStudyAttributeReport(this.studyAttributeRequest)
      .subscribe(
        response => {
          this.downloadFile(response);
          this.closeModal();
        },
        error => {
          if (error.status === 400) {
            this.errorMessage = error.error.message;
          } else if (error.status === 401) {
            this.errorMessage = 'User not authorized to this report';
          } else {
            this.closeModal();
          }
        },
      );
  }

  generateStudyNetworkReport() {
    this.reportService
      .getStudyNetworkReport(this.studyNetworkReportCriteria)
      .subscribe(
        response => {
          this.downloadFile(response);
          this.closeModal();
        },
        error => {
          if (error.status === 400) {
            this.errorMessage = error.error.message;
          } else if (error.status === 401) {
            this.errorMessage = 'User not authorized to this report';
          } else {
            this.closeModal();
          }
        }
      );
  }

  handleAttributeSelection(attributeSelection: Code[]) {
    this.studyAttributeRequest.studyAttributes = attributeSelection;
  }

  /**
   * This will download the file on the selection of the tile download button.
   * CSV download for the LogSummary and Text download for the LogDetail.
   * PDF for data-dictionary
   * XSL for difference
   */
  download() {
    const studyRefNum = this.txtStudyID.trim();
    // if (this.isLogSummarySelected) {
    if (this.reportName === 'summary') {
      this.reportService.getRandomizationLogSummary(studyRefNum).subscribe(
        response => {
          const blob = new Blob([response], {
            type: 'text/plain;charset=utf-8',
          });
          saveAs(blob, 'randomization-summary.csv');
        },
        error => {
          if (error.status === 400) {
            const modalArg = new ModalDialogArg(
              'modal-warn',
              'Custom',
              JSON.parse(error.error).message,
            );
            this.studySetupService
              .showModalDialog(this.viewContainer, modalArg)
              .subscribe(actionResult => {
                // do nothing
              });
          }
        },
      );
    } else if (this.reportName === 'log') {
      this.reportService.getRandomizationLogDetail(studyRefNum).subscribe(
        response => {
          const blob = new Blob([response], {
            type: 'text/plain;charset=utf-8',
          });
          saveAs(blob, 'randomization-log.txt');
        },
        error => {
          if (error.status === 400) {
            const modalArg = new ModalDialogArg(
              'modal-warn',
              'Custom',
              JSON.parse(error.error).message,
            );
            this.studySetupService
              .showModalDialog(this.viewContainer, modalArg)
              .subscribe(actionResult => {
                // do nothing
              });
          }
        },
      );
    } else if (this.reportName === 'dataDictionary') {
      this.reportService.getDataDictionaryReport(studyRefNum).subscribe(
        response => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const fname = 'DataDictionary_' + studyRefNum + '.pdf';
          saveAs(blob, fname);
        },
        error => {
          if (error.status === 400) {
            const modalArg = new ModalDialogArg(
              'modal-warn',
              'Custom',
              error.error.message,
            );
            this.studySetupService
              .showModalDialog(this.viewContainer, modalArg)
              .subscribe(actionResult => {
                // do nothing
              });
          }
        },
      );
    } else if (this.reportName === 'subjectRegistrationDataExtract') {
      this.reportService
        .getSubjectRegistrationDataExtract(studyRefNum)
        .subscribe(
          response => {
            this.downloadFile(response);
          },
          error => {
            if (error.status === 400) {
              const modalArg = new ModalDialogArg(
                'modal-warn',
                'Custom',
                error.error.message,
              );
              this.studySetupService
                .showModalDialog(this.viewContainer, modalArg)
                .subscribe(actionResult => {
                  // do nothing
                });
            }
          },
          () => {
          },
        );
    } else if (this.reportName === 'difference') {
      this.reportService
        .getDifferenceReport(
          this.studyId,
          this.oldForm.formId,
          this.newForm.formId,
        )
        .subscribe(
          response => {
            this.downloadFile(
              response,
              'DifferenceReport_' + studyRefNum + '.xlsx',
            );
          },
          error => {
            if (error.status === 400) {
              const modalArg = new ModalDialogArg(
                'modal-warn',
                'Custom',
                error.error.message,
              );
              this.studySetupService
                .showModalDialog(this.viewContainer, modalArg)
                .subscribe(actionResult => {
                  // do nothing
                });
            }
          },
          () => {
          },
        );
    } else if (this.reportName === 'fullStudyUnblind') {
      this.reportService.getFullStudyUnblindReport(studyRefNum).subscribe(
        response => {
          this.downloadFile(
            response,
            'FullStudyUnblindReport_' + studyRefNum + '.xlsx',
          );
        },
        error => {
          if (error.status === 400) {
            const modalArg = new ModalDialogArg(
              'modal-warn',
              'Custom',
              error.error.message,
            );
            this.studySetupService
              .showModalDialog(this.viewContainer, modalArg)
              .subscribe(actionResult => {
                // do nothing
              });
          }
        },
        () => {
        },
      );
    }

    this.closeModal();
  }

  /**
   * This will download the file on the selection of the tile generate button.
   * the dialog/modal will stay open until they cancel or the report is successfully generated
   * PDF download accrual by site report.
   */
  generateReport2() {
    const studyRefNum = this.txtStudyID.trim();

    if (this.reportName === 'accrualBySite') {
      this.reportService.getAccrualBySiteReport(studyRefNum).subscribe(
        response => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const fname = 'AccrualBySite_' + studyRefNum + '.pdf';
          saveAs(blob, fname);
          this.closeModal();
        },
        error => {
          if (error.status === 400) {
            this.errorMessage = error.error.message;
          } else if (error.status === 401) {
            this.errorMessage =
              'User not authorized to this report for provided protocol';
          } else {
            this.closeModal();
          }
        }
      );
    }
    else if (this.reportName === 'schemaReport') {
      this.reportService
        .schemaSearchCriteria(this.schemaReportCriteria)
        .subscribe(
          response => {
            this.downloadFile(response);
            this.closeModal();
          },
          error => {
            if (error.status === 400) {
              this.errorMessage = error.error.message;
            } else if (error.status === 401) {
              this.errorMessage = 'User not authorized to this report';
            } else {
              this.closeModal();
            }
          }
        );
    }
    else if (this.reportName === 'attributeReport') {
      this.generateAttributeReport();
    }
    else if (this.reportName === 'studyNetworkReport') {
      this.generateStudyNetworkReport();
    }
  }

  /**
   * This will emit StudyAccrualReport when clicked on the Generate button.
   */
  generateStudyAccrualReport() {
    this.getReportData.emit(this.txtStudyID.trim());
    this.closeModal();
  }

  /**
   * CLear textbox & Closes the modal
   */
  closeModal() {
    this.txtStudyID = '';
    this.forms = null;
    this.filteredForms = null;
    this.oldForm = null;
    this.newForm = null;
    this.showModal = false;
    this.errorMessage = '';
    this.studyAttributeRequest = {
      studyAttributes: [],
      researchEntity: null,
      studyStatus: null,
    };
    this.schemaReportCriteria = new SchemaReportCriteria([], [], [], []);
    this.studyNetworkReportCriteria = new StudyNetworkReportCriteria([], [], [], []);
    this.interventionSelected = null;
    this.studyAttributeRequest = {
      studyAttributes: [],
      researchEntity: null,
      studyStatus: null,
    };
    this.isAttributeTile = false;
    this.reportName = '';
    this.searchInputComponent?.clearControl();
  }

  /**
   * Disables the "Generate" button if:
   * - Required fields don't have a value
   * - The Study ID field doesn't have a value
   * - The Difference Report criteria is invalid
   */
  noSelections(): boolean {
    if (this.schemaReportCriteria.schemaEvents.length > 0
      || this.studyNetworkReportCriteria.researchEntities.length > 0
      || this.studyAttributeRequest.studyAttributes.length > 0) {
      return false;
    }

    const studyIdMissing: boolean = this.txtStudyID.trim() === '';
    let differenceReportInvalid: boolean = false;

    if (this.isDifferenceReport() && (this.oldForm == null || this.newForm == null)) {
      differenceReportInvalid = true;
    }

    return studyIdMissing || differenceReportInvalid;
  }

  isDifferenceReport(): boolean {
    if (this.reportName === 'difference') {
      return true;
    }
    return false;
  }

  checkSourceProtocolProvided() {
    if (this.txtStudyID && this.txtStudyID.length) {
      this.forms = [];
      this.errorMessage = '';
    }
  }

  isFindButtonDisabled(): boolean {
    if (this.txtStudyID && this.txtStudyID.length > 0) {
      return false;
    }
    return true;
  }

  /**
  * HAndler for the click event on the row on form elements

  * @param $event The click event raised
  */
  ignoreClick($event: Event) {
    if ($event) {
      $event.stopPropagation();
    }
  }

  /**
   * Compare objects, used in html for matching coded lists
   */
  compareFn(a, b) {
    if (a || b) {
      return a && b && a.id === b.id;
    } else {
      return true;
    }
  }

  getFormInfo() {
    if (this.isDifferenceReport()) {
      this.forms = null;
      this.filteredForms = null;
      this.oldForm = null;
      this.newForm = null;
      this.subscriptions.push(
        this.reportService
          .getStudyFormsByProtocolNumber(this.txtStudyID.trim())
          .subscribe(
            result => {
              if (result) {
                this.forms = result;
                // Sorts based on ascending order of studyFormNum amd descending order of versionNum
                this.forms.sort(
                  (a, b) =>
                    a.studyFormNum - b.studyFormNum ||
                    b.versionNum - a.versionNum,
                );
                if (this.forms.length > 0) {
                  this.studyId = this.forms[0].studyId;
                }
              }
            },
            error => {
              if (error.status === 400) {
                const modalArg = new ModalDialogArg(
                  'modal-warn',
                  'Custom',
                  error.error.message,
                );
                this.studySetupService
                  .showModalDialog(this.viewContainer, modalArg)
                  .subscribe(actionResult => { });
              }
            },
            () => {
            },
          ),
      );
    }
  }

  getNewFormDropdownValues() {
    // same form type, same schema event, and NOT the form that was selected for the "old" form
    if (this.forms) {
      this.filteredForms = this.forms.filter(
        thisForm =>
          thisForm.formType.id === this.oldForm.formType.id &&
          thisForm.studySchemaEvent &&
          thisForm.studySchemaEvent.id === this.oldForm.studySchemaEvent.id &&
          thisForm.formId !== this.oldForm.formId,
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }

  isStudy(): boolean {
    return !this.isSchemaReport() && !this.isStudyNetworkReport();
  }

  isSchemaReport(): boolean {
    return this.reportName === 'schemaReport';
  }

  isStudyNetworkReport(): boolean {
    return this.reportName === 'studyNetworkReport';
  }

  /**
   * Sets the given search request array to the chips and calls the search
   * @param requestArray To which array in the SearchRequest object the chips need to be added
   * @param chips The new list of chips for the given search field
   */
  handleCriteriaChange(criteria: Code[], chips: SearchInputOption[], criteriaType: string): void {
    const misMatchFound = !criteria.every(x =>
      chips.some(y => y.code === x.code),
    );
    if (misMatchFound) {
      return;
    }

    // Update the criteria based on the criteriaType
    if (this.isSchemaReport()) {
      switch (criteriaType) {
        case 'schemaEvents':
          this.schemaReportCriteria.schemaEvents = chips;
          break;
        case 'researchEntities':
          this.schemaReportCriteria.researchEntities = chips;
          break;
        case 'studyStatuses':
          this.schemaReportCriteria.studyStatuses = chips;
          break;
        default:
          break;
      }
    } else if (this.isStudyNetworkReport()) {
      switch (criteriaType) {
        case 'researchEntities':
          this.studyNetworkReportCriteria.researchEntities = chips;
          break;
        case 'studyStatuses':
          this.studyNetworkReportCriteria.studyStatuses = chips;
          break;
        case 'participatingOrgs':
          this.studyNetworkReportCriteria.participatingOrgs = chips;
          break;
        case 'participatingOrgStatuses':
          this.studyNetworkReportCriteria.participatingOrgStatuses = chips;
          break;
        default:
          break;
      }
    }
  }



  /**
   * Calls when intervention changes on schema report
   */
  interventionChange() {
    const indexToRemove = this.schemaReportCriteria.eventAttributes.findIndex(
      item => item.code === this.interventionSelectedOld?.code,
    );
    if (indexToRemove >= 0) {
      this.schemaReportCriteria.eventAttributes.splice(indexToRemove, 1);
    }

    if (this.interventionSelected) {
      this.schemaReportCriteria.eventAttributes.push(this.interventionSelected);
      this.interventionSelectedOld = _.cloneDeep(this.interventionSelected);
    }
  }

  /**
   * Calls when attribute check changes on schema report
   * @param ob
   * @param attributeChecked
   */
  attributeCheckChange(ob: MatCheckboxChange, attributeChecked: string) {
    if (ob.checked) {
      const attributeToAdd = this.attributeEvents.find(
        attr => attr.code === attributeChecked,
      );

      if (attributeToAdd) {
        this.addAttributeToEventAttributes(attributeChecked);
      }
    } else {
      this.removeAttributeFromEventAttributes(attributeChecked);
    }
  }

  toggleInfoTooltip(inputName: string): void {
    switch (inputName) {
      case 'researchEntities':
        this.studyNetworkReportCriteria.researchEntitiesTooltipOpen = !this.studyNetworkReportCriteria.researchEntitiesTooltipOpen;
        break;
      case 'studyStatuses':
        this.studyNetworkReportCriteria.studyStatusesTooltipOpen = !this.studyNetworkReportCriteria.studyStatusesTooltipOpen;
        break;
      case 'participatingOrgs':
        this.studyNetworkReportCriteria.participatingOrgsTooltipOpen = !this.studyNetworkReportCriteria.participatingOrgsTooltipOpen;
        break;
      case 'participatingOrgStatuses':
        this.studyNetworkReportCriteria.participatingOrgStatusesTooltipOpen = !this.studyNetworkReportCriteria.participatingOrgStatusesTooltipOpen;
        break;
      default:
        break;
    }
  }

  /**
   * Add event attribute
   * @param attributeCode
   */
  private addAttributeToEventAttributes(attributeCode: string) {
    const attribute = this.attributeEvents.find(x => x.code === attributeCode);
    if (attribute) {
      this.schemaReportCriteria.eventAttributes.push(attribute);
    }
  }

  /**
   * Removed event attribute
   * @param attributeCode
   */
  private removeAttributeFromEventAttributes(attributeCode: string) {
    const indexToRemove = this.schemaReportCriteria.eventAttributes.findIndex(
      item => item.code === attributeCode,
    );
    if (indexToRemove !== -1) {
      this.schemaReportCriteria.eventAttributes.splice(indexToRemove, 1);
    }
  }

  /**
   * This will download the file as excel into local file system
   *
   * @param  response
   * @param  fileName?
   */
  private downloadFile(response: any, fileName?: string) {
    const blob = new Blob([response.body], {
      type: response.headers.get('content-type'),
    });

    fileName =
      fileName ||
      response.headers.get('content-disposition').split(';')[1].split('=')[1];

    const file = new File([blob], fileName, {
      type: response.headers.get('content-type'),
    });
    //Filesaver to save to loal file system.
    saveAs(file);
  }
}
