import { Component, Input, OnInit } from '@angular/core';
import { FormVariable } from '../../model/form-variable';

@Component({
  selector: 'mc-input-check-box',
  templateUrl: './input-check-box.component.html',
  styleUrls: ['./input-check-box.component.scss']
})
export class InputCheckBoxComponent implements OnInit {

  @Input() formVariable: FormVariable

  constructor() { }

  ngOnInit() {
  }

}
