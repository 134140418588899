import { Injectable } from '@angular/core';
import { RuntimeConfig } from './runtime-config';



@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  static runConfig: RuntimeConfig;
  static readonly jsonFile: string = 'conf.json';

  constructor() { }

  static setConfigs(config: RuntimeConfig): void {
    AppConfigService.runConfig = config;
  }
}
