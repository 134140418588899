<div class="columns" *ngIf="searchCriteria">
    <div class="column">
      <section class="section" *ngIf="noResultsFound">
        <div class="container">
          <div class="subtitle has-text-centered">
            <h2 class="has-text-weight-bold">
              No results found. 
            </h2>
          </div>
        </div>
      </section>
      <div *ngIf="results && results.length > 0">  
        <cdk-virtual-scroll-viewport itemSize="50" class="viewport">
        <table class="table is-fullwidth is-striped ">
            <thead>
                <tr role="row" class="is-selected">
              <ng-container *ngFor="let column of columns">
                <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}"
                  (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
                  <span> {{column.title}}</span>
                  <span *ngIf="config && column.sort != undefined" class="sort is-text-centered">
                    <span *ngIf="column.sort === ''">
                      <i class="fas fa-sort"></i>
                    </span>
                    <span *ngIf="column.sort === 'desc'">
                      <i class="fas fa-sort-down"></i>
                    </span>
                    <span *ngIf="column.sort === 'asc'">
                      <i class="fas fa-sort-up"></i>
                    </span>
                  </span>
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="showFilterRow">
              <td *ngFor="let column of columns">
                <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
                 [mcTableFiltering]="column.filtering"
                  class="form-control" (tableChanged)="onChangeTable(config)" />
              </td>
            </tr>
            <ng-container *cdkVirtualFor="let row of rows">
              <tr>
                <td>
                    <a (click)="goToManageSlotReservation($event, row)">
                        <span class="has-text-weight-bold">
                         {{row.studyReservationId}}
                        </span>
                     </a>
                </td>
                <td>
                  <span>{{row.currentState}}</span>
                </td>
                <td>
                  <span> {{row.initials}} </span>
                </td>
                <td>
                  <span>{{row.slotGroupDescription}}</span>
                </td>
                <td>
                    <span>{{row.currentStateDate | date:'MM/dd/yyyy'}}</span>
                </td>
                <td>
                    <span>{{row.expirationDate | date:'MM/dd/yyyy'}}</span>
                </td>
                <td>
                    <span>{{row.treatingLocation}}</span>
                </td>
                <td>
                    <span>{{row.siteContact}}</span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>  
      </cdk-virtual-scroll-viewport>
      </div>
    </div>
    
    </div>
    
    
   
  