import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationSetupRoutingModule } from 'app/admin/notification-setup/notification-setup-routing.module';
import { SharedModule } from 'app/common/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { McTableModule } from 'app/common/directives/table/mc-table.module';
import { NotificationSetupComponent } from './notification-setup.component';
import { NotificationInfoComponent } from './notification-info/notification-info.component';
import { RecipientsComponent } from './recipients/recipients.component';
import { MessageTextComponent } from './message-text/message-text.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { QuillModule } from 'ngx-quill';
import { GroupConfigurationComponent } from './group-configuration/group-configuration.component';
import { ConfigurationBaseComponent } from './configuration-base/configuration-base.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule, ReactiveFormsModule,
    McTableModule,
    NotificationSetupRoutingModule,
    QuillModule.forRoot()
  ],
  declarations: [
    NotificationSetupComponent,
    NotificationInfoComponent,
    ConfigurationComponent,
    RecipientsComponent,
    MessageTextComponent,
    GroupConfigurationComponent,
    ConfigurationBaseComponent
  ]
})
export class NotificationSetupModule { }
