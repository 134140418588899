import { Injectable } from '@angular/core';
import { SimulationStudyData } from 'app/common/model/simulation/simulation-study-data';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SimulationInteractionService {

  private eventEnrollmentStudySchemaId: number;
  private _simulationStudyData: SimulationStudyData;
  private simEventEnrollment = new BehaviorSubject<number>(0);
  public simEventEnrollment$ = this.simEventEnrollment.asObservable();

  constructor() { }

  public set simulationStudyData(simulationStudyData: SimulationStudyData){
     this._simulationStudyData = simulationStudyData;
  }

  public get simulationStudyData(){
     return this._simulationStudyData;
 }

  public setEventEnrollmentId(studySchemaEventId: number){
    this.simEventEnrollment.next(studySchemaEventId);
  }

  public set eventEnrollmentId(studySchemaEventId: number){
     this.eventEnrollmentStudySchemaId = studySchemaEventId
  }

  public get eventEnrollmentId(): number{
    return this.eventEnrollmentStudySchemaId;
  }
}
