<div class="columns">
  <div class="column no-bottom-padding">
    <h3>Demographic Setting</h3>
  </div>
</div>
<div class="form-table-container  study-setup-content">
  <table class="table is-fullwidth is-borderless " role="grid">
    <tbody>
      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>First Name</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="firstname" [(ngModel)]="showFirstNameOptions" [value]="true"
                    (click)="setOptionRequired(firstNameProperty)" [checked]="showFirstNameOptions" id="firstNameYes"
                    [disabled]="isReadOnly">
                  Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="firstname" (click)='setOptionOptional(firstNameProperty)'
                    [checked]="showFirstNameOptions == null" [value]="null" [(ngModel)]="showFirstNameOptions"
                    id="firstNameReqNo" [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="firstname" [(ngModel)]="showFirstNameOptions" [value]="false"
                    [checked]="!showFirstNameOptions" (click)='setOptionNo(firstNameProperty)' id="firstNameNo"
                    [disabled]="isReadOnly">
                  No
                </label>


              </div>
            </div>
          </div>
          <div class="columns" *ngIf="showOptionsSection(firstNameProperty)">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Options</span>
            </div>
            <div class="column  field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="firstName.demographicId" required name="slctFNameOpt" id="slctFNameOpt"
                    [disabled]="isReadOnly">
                    <option *ngFor="let opt of firstNameOptions" [ngValue]="opt.demographicId">
                      {{opt.name}}
                    </option>
                  </select>

                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>Last Name</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="lastname" (click)="setOptionRequired(lastNameProperty)"
                    [(ngModel)]="showLastNameOptions" [value]="true" [checked]="showLastNameOptions" id="lastNameYes"
                    [disabled]="isReadOnly">
                  Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="lastname" (click)='setOptionOptional(lastNameProperty)'
                    [checked]="showLastNameOptions== null" [value]="null" [(ngModel)]="showLastNameOptions"
                    id="lastNameReqNo" [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="lastname" [(ngModel)]="showLastNameOptions" [value]="false"
                    [checked]="!showLastNameOptions" (click)='setOptionNo(lastNameProperty)' id="lastNameNo"
                    [disabled]="isReadOnly">
                  No
                </label>
              </div>
            </div>
          </div>
          <div class="columns" *ngIf="showOptionsSection(lastNameProperty)">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Options</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="lastName.demographicId" required name="slctLNameOpt" id="slctLNameOpt"
                    [disabled]="isReadOnly">
                    <option *ngFor="let opt of lastNameOptions" [ngValue]="opt.demographicId">
                      {{opt.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>Middle Name</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="middlename" (click)="setOptionRequired(middleNameProperty)"
                    [(ngModel)]="showMiddleNameOptions" [value]="true" [checked]="showMiddleNameOptions"
                    id="middleNameYes" [disabled]="isReadOnly">
                  Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="middlename" (click)='setOptionOptional( middleNameProperty)'
                    [checked]="showMiddleNameOptions== null" [value]="null" [(ngModel)]="showMiddleNameOptions"
                    id="middleNameReqNo" [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="middlename" [(ngModel)]="showMiddleNameOptions" [value]="false"
                    [checked]="!showMiddleNameOptions" (click)="setOptionNo(middleNameProperty)" id="middleNameNo"
                    [disabled]="isReadOnly">
                  No
                </label>
              </div>
            </div>
          </div>

          <div class="columns" *ngIf="showOptionsSection(middleNameProperty)">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Options</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="middleName.demographicId" required name="slctMNameOpt" id="slctMNameOpt"
                    [disabled]="isReadOnly">
                    <option *ngFor="let opt of middleNameOptions" [ngValue]="opt.demographicId">
                      {{opt.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

        </td>
      </tr>

      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>Gender </span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="genstandards" [(ngModel)]="showGenderStandards" [value]="true"
                    (click)="setOptionRequired(genderProperty, 'genderClassificationId')"
                    [checked]="showGenderStandards" id="genderYes" [disabled]="isReadOnly"> Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="genstandards"
                    (click)="setOptionOptional(genderProperty, 'genderClassificationId')"
                    [checked]="showGenderStandards == null" [value]="null" [(ngModel)]="showGenderStandards"
                    id="genderReqNo" [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="genstandards" [(ngModel)]="showGenderStandards" [value]="false"
                    [checked]="!showGenderStandards" (click)="setOptionNo(genderProperty, 'genderClassificationId')"
                    id="genderNo" [disabled]="isReadOnly"> No
                </label>


              </div>
            </div>

          </div>
          <div class="columns" *ngIf="showOptionsSection(genderProperty)">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Gender Standards</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="gender.genderClassificationId" required name="slctGenderStd" id="slctGenderStd"
                    [disabled]="isReadOnly">
                    <option *ngFor="let std of genderStandards" [ngValue]="std.standard">
                      {{std.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="columns">
            <div class="column" [ngClass]="leftColumnClass">
              <span>Race</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="race" [(ngModel)]="showRaceStandards"
                    (click)="setOptionRequired(raceProperty, 'raceCodeClassificationId')" [value]="true"
                    [checked]="showRaceStandards" id="raceYes" [disabled]="isReadOnly"> Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="race" (click)="setOptionOptional(raceProperty, 'raceCodeClassificationId')"
                    [checked]="showRaceStandards== null" [value]="null" [(ngModel)]="showRaceStandards" id="raceReqNo"
                    [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="race" [(ngModel)]="showRaceStandards" [value]="false"
                    [checked]="!showRaceStandards" (click)="setOptionNo(raceProperty, 'raceCodeClassificationId')"
                    id="raceNo" [disabled]="isReadOnly"> No
                </label>



              </div>
            </div>
          </div>
          <div class="columns" *ngIf="showOptionsSection(raceProperty)">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Race Standards</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="race.raceCodeClassificationId" required name="slctRaceStd" id="slctRaceStd"
                    [disabled]="isReadOnly">
                    <option *ngFor="let std of raceStandards" [ngValue]="std.standard">
                      {{std.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>Ethnicity</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="ethnicity" [(ngModel)]="showEthnicityStandards" [value]="true"
                    [checked]="showEthnicityStandards"
                    (click)="setOptionRequired(ethnicityProperty,'ethnicityClassificationId')" id="ethnicityYes"
                    [disabled]="isReadOnly"> Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="ethnicity"
                    (click)="setOptionOptional(ethnicityProperty,'ethnicityClassificationId')"
                    [checked]="showEthnicityStandards== null" [value]="null" [(ngModel)]="showEthnicityStandards"
                    id="ethnicityReqNo" [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="ethnicity" [(ngModel)]="showEthnicityStandards" [value]="false"
                    [checked]="!showEthnicityStandards"
                    (click)="setOptionNo(ethnicityProperty,'ethnicityClassificationId')" id="ethnicityNo"
                    [disabled]="isReadOnly"> No
                </label>


              </div>
            </div>
          </div>
          <div class="columns" *ngIf="showOptionsSection(ethnicityProperty)">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Ethnicity Standards</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="ethnicity.ethnicityClassificationId" required name="slctEthnicityStd"
                    id="slctEthnicityStd" [disabled]="isReadOnly">
                    <option *ngFor="let std of ethnicityStandards" [ngValue]="std.standard">
                      {{std.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>


      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>Date of Birth</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="dateofbirth" [(ngModel)]="showDobOptions" [value]="true"
                    [checked]="showDobOptions" (click)="setOptionRequired(dobProperty)" id="dobYes"
                    [disabled]="isReadOnly"> Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="dateofbirth" (click)="setOptionOptional(dobProperty)"
                    [checked]="showDobOptions== null" [value]="null" [(ngModel)]="showDobOptions" id="dobReqNo"
                    [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="dateofbirth" [(ngModel)]="showDobOptions" [value]="false"
                    [checked]="!showDobOptions" (click)="setOptionNo(dobProperty)" id="dobNo" [disabled]="isReadOnly">
                  No
                </label>

              </div>
            </div>
          </div>

          <div class="columns" *ngIf="false">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>DOB Options</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="dob.demographicId" required name="slctDobStd" id="slctDobStd"
                    [disabled]="isReadOnly">
                    <option *ngFor="let std of dobOptions" [ngValue]="std.demographicId">
                      {{std.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

        </td>
      </tr>

      <tr>
        <td>
          <div class="columns">
            <div class="column" [ngClass]="leftColumnClass">
              <span>Country</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="country"
                    (click)="setOptionRequired(countryProperty, 'countryClassificationId')"
                    [(ngModel)]="showCountryStandards" [value]="true" [checked]="showCountryStandards" id="countryYes"
                    [disabled]="isReadOnly"> Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="country"
                    (click)="setOptionOptional( countryProperty, 'countryClassificationId')"
                    [checked]="showCountryStandards == null" [value]="null" [(ngModel)]="showCountryStandards"
                    id="countryReqNo" [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="country" [(ngModel)]="showCountryStandards" [value]="false"
                    [checked]="!showCountryStandards" (click)="setOptionNo(countryProperty, 'countryClassificationId')"
                    id="countryNo" [disabled]="isReadOnly"> No
                </label>


              </div>
            </div>
          </div>
          <div class="columns" *ngIf="showOptionsSection(countryProperty)">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Country Standards</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="country.countryClassificationId" required name="slctCountryOpt"
                    id="slctCountryOpt" [disabled]="isReadOnly">
                    <option *ngFor="let std of countryStandards" [ngValue]="std.standard">
                      {{std.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>


        </td>
      </tr>

      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>Postal Code</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="postalcode" (click)="setOptionRequired(postalCodeProperty)"
                    [(ngModel)]="showPostalCodeOptions" [value]="true" [checked]="showPostalCodeOptions"
                    id="postalCodeYes" [disabled]="isReadOnly"> Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="postalcode" (click)="setOptionOptional(postalCodeProperty)"
                    [checked]="showPostalCodeOptions== null" [value]="null" [(ngModel)]="showPostalCodeOptions"
                    id="postalCodeReqNo" [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="postalcode" [(ngModel)]="showPostalCodeOptions" [value]="false"
                    [checked]="!showPostalCodeOptions" (click)="setOptionNo(postalCodeProperty)" id="postalCodeNo"
                    [disabled]="isReadOnly"> No
                </label>


              </div>
            </div>
          </div>
          <div class="columns" *ngIf="false">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Postal Code Options</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="postalCode.demographicId" required name="slctPostalCodeOpt"
                    id="slctPostalCodeOpt" [disabled]="isReadOnly">
                    <option *ngFor="let opt of postalCodeOptions" [ngValue]="opt.demographicId">
                      {{opt.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <div class="columns">
            <div class="column" [ngClass]="leftColumnClass">
              <span>MedDRA</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="meddra" (click)="setOptionRequired(meddraProperty, 'medDRAVersionId')"
                    [(ngModel)]="showMedDraOptions" [value]="true" [checked]="showMedDraOptions" id="meddraYes"
                    [disabled]="isReadOnly"> Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="meddra" (click)="setOptionOptional(meddraProperty,'medDRAVersionId')"
                    [checked]="showMedDraOptions== null" [value]="null" [(ngModel)]="showMedDraOptions" id="meddraReqNo"
                    [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="meddra" [(ngModel)]="showMedDraOptions"
                    (click)="setOptionNo(meddraProperty,'medDRAVersionId')" [value]="false"
                    [checked]="!showMedDraOptions" id="meddraNo" [disabled]="isReadOnly"> No
                </label>

              </div>
            </div>
          </div>
          <div class="columns" *ngIf="showOptionsSection(meddraProperty)">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>MedDRA Options</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="meddra.medDRAVersionId" change="medDraSelected()" required name="slctMeddraOpt"
                    id="slctMeddraOpt" [disabled]="isReadOnly">
                    <option *ngFor="let std of medDraOptions" [ngValue]="std.versionId">
                      {{std.version}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="control has-padding-top-15" *ngIf="showMedDraDiseaseOptions">
                <ng-multiselect-dropdown [settings]="medDraDiseaseDropdownSettings" [data]="medDraDiseaseOptions"
                  [(ngModel)]="meddra.possibleValues" [disabled]="isReadOnly">
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>

        </td>
      </tr>

      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>Method of Payment</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="methodofpayment"
                    (click)="setOptionRequired(methodOfPaymentProperty, 'paymentMethodClassificationId')"
                    [(ngModel)]="showMethodOfPaymentStandards" [value]="true" [checked]="showMethodOfPaymentStandards"
                    id="methodOfPaymentYes" [disabled]="isReadOnly"> Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="methodofpayment"
                    (click)="setOptionOptional(methodOfPaymentProperty, 'paymentMethodClassificationId')"
                    [checked]="showMethodOfPaymentStandards== null" [value]="null"
                    [(ngModel)]="showMethodOfPaymentStandards" id="methodOfPaymentReqNo" [disabled]="isReadOnly">
                  Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="methodofpayment" [(ngModel)]="showMethodOfPaymentStandards" [value]="false"
                    [checked]="!showMethodOfPaymentStandards"
                    (click)="setOptionNo(methodOfPaymentProperty, 'paymentMethodClassificationId')"
                    id="methodOfPaymentNo" [disabled]="isReadOnly"> No
                </label>



              </div>
            </div>
          </div>
          <div class="columns" *ngIf="showOptionsSection(methodOfPaymentProperty)">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Method Standards</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="methodOfPayment.paymentMethodClassificationId" required
                    name="slctMethodOfPaymentStd" id="slctmethodOfPaymentStd" [disabled]="isReadOnly">
                    <option *ngFor="let std of methodPaymentStandards" [ngValue]="std.standard">
                      {{std.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>Other Subject ID</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="otherSubjectId" (click)="setOptionRequired(otherSubjectIdProperty)"
                    [(ngModel)]="showOtherSubjectIdOptions" [value]="true" [checked]="showOtherSubjectIdOptions"
                    id="otherSubjectIdYes" [disabled]="isReadOnly">
                  Required
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="otherSubjectId" (click)="setOptionOptional(otherSubjectIdProperty)"
                    [checked]="!showOtherSubjectIdOptions" [value]="null" [(ngModel)]="showOtherSubjectIdOptions"
                    id="otherSubjectIdReqNo" [disabled]="isReadOnly"> Optional
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="otherSubjectId" [(ngModel)]="showOtherSubjectIdOptions" [value]="false"
                    (click)="setOptionNo(otherSubjectIdProperty)" [checked]="!showOtherSubjectIdOptions"
                    id="otherSubjectIdNo" [disabled]="isReadOnly"> No
                </label>

              </div>
            </div>
          </div>
          <div class="columns" *ngIf="false">
            <div class="column sub-option-spacer no-top-padding" [ngClass]="leftColumnClass">
              <span>Option</span>
            </div>
            <div class="column field no-top-padding">
              <div class="control">
                <div class="select">
                  <select [(ngModel)]="otherSubjectId.demographicId" required name="selectOtherSubjectId"
                    id="otherSubjectIdSelect" [disabled]="isReadOnly">
                    <option *ngFor="let std of otherSubjectIdOptions" [ngValue]="std.demographicId">
                      {{std.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>


      <tr>
        <td>
          <div class="columns">
            <div class="column " [ngClass]="leftColumnClass">
              <span>Allow Multiple Registrations and force new Patient ID</span>
            </div>
            <div class=" column field">
              <div class="control ">
                <label class="radio">
                  <input type="radio" name="duplicate-patients" (click)="setOptionRequired(duplicatePatientProperty)"
                    [(ngModel)]="showDuplicatePatientOptions" [value]="true" [checked]="showDuplicatePatientOptions"
                    id="duplicatePatientYes" [disabled]="isReadOnly">
                  Yes
                </label>
                <label class="radio option-spacer">
                </label>
                <label class="radio option-spacer">
                  <input type="radio" name="duplicate-patients" [(ngModel)]="showDuplicatePatientOptions"
                    [value]="false" (click)="setOptionNo(duplicatePatientProperty)"
                    [checked]="!showDuplicatePatientOptions" id="DuplicatePatientNo" [disabled]="isReadOnly"> No
                </label>

              </div>
            </div>
          </div>

        </td>
      </tr>

    </tbody>
  </table>
</div>