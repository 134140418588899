<div class="modal" [ngClass]="{'is-active': showModal}"> 
    <div class="modal-background"></div>
    <div class="modal-card">
  
      <section class="modal-card-body">
        <div>
          <span>Form Version</span>
          <div class="select is-small has-form-list ">
            <select [(ngModel)]="selectedStudyForm" (change)="formSelectionChanged()">
              <option [ngValue]=null selected>-- Select --</option>
              <option *ngFor="let item of formVersions" [ngValue]="item">
                {{item.formName}} - {{ item.versionNum}}
              </option>
            </select>
            <button class="delete is-close-button" aria-label="close" (click)="closeModal()">
  
             </button>
          </div>
          </div>
        <div *ngIf="variableList.length > 0">
          <table class="table is-fullwidth  is-borderless is-striped ">
            <thead>
              <tr>
                <ng-container *ngFor="let column of columns">
                  <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}"
                    (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
                    <span class="has-title"> {{column.title}}</span>
                    <span *ngIf="config && rows.length > 0 && column.sort != undefined" class="sort is-text-centered">
                      <span *ngIf="column.sort === ''">
                        <i class="fas fa-sort"></i>
                      </span>
                      <span *ngIf=" column.sort === 'desc'">
                        <i class="fas fa-sort-down"></i>
                      </span>
                      <span *ngIf="column.sort === 'asc'">
                        <i class="fas fa-sort-up"></i>
                      </span>
                    </span>
                  </th>
                </ng-container>
<!--             
                <th class="has-variable-class-header">Class</th>
                <th class="has-variable-name-header">Variable Name</th>
                <th clas="has-variable-value-header">Valid Value</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="showFilterRow">
                <td *ngFor="let column of columns;let i=index">
                  <input *ngIf="column.filtering" 
                  placeholder="{{column.filtering.placeholder}}"
                   [mcTableFiltering]="column.filtering"
                    class="form-control" (tableChanged)="onChangeTable(config)" />
                </td>
              </tr>
              <tr *ngFor="let row of rows; let ix = index"
                [ngClass]="{'is-selected':isVariableRowSelected(row)}"
                  (click)="rowVariableSelectedClick(row)">
                <td class="has-text-centered">
                  <a>
                    <span>
                      <span *ngIf="!isVariableRowSelected(row)">
                          <i class="far fa-square"></i>
                      </span>
                      <span *ngIf="isVariableRowSelected(row)">
                        <i class="far fa-check-square"></i>
                      </span>
                    </span>
                  </a>
                </td>
                <td>{{row.variableAlternateName}}</td>
                <td>{{row.validValue}}</td>
                <td>{{row.variableClass}}</td>
              </tr>
            </tbody>
        
          </table>
        
        </div>
      </section>
      <footer class="modal-card-foot">
          <div class="is-pulled-right footer-button ">
              <button class="button is-primary is-radiusless" [disabled]="selectedFormVariable==null "
               (click)="selectFormVariable()">
                Select</button>
              <button class="button is-radiusless" (click)="closeModal()">
                Cancel</button>
            </div>
      </footer>
    </div>
  </div>
  