import { Directive, EventEmitter, Input, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[mcTablePaging]'
})
export class McTablePagingDirective {
  @Input() public mcTablePaging = true;
  @Output() public tableChanged: EventEmitter<any> = new EventEmitter();

  @Input()
  public get config(): any {
    return this.mcTablePaging;
  }

  public set config(value: any) {
    this.mcTablePaging = value;
  }

  @HostListener('pagechanged', ['$event'])
  public onChangePage(event: any): void {
    // Object.assign(this.config, event);
    if (this.mcTablePaging) {
      this.tableChanged.emit({paging: event});
    }
  }

}
