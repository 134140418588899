import { Component, OnDestroy } from '@angular/core';
import { NotificationEmailDetail } from 'app/common/model/notification-email-detail';
import { StudyServiceBase } from 'app/common/services/study-service-base.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnDestroy {

  notificationEmailDetail: NotificationEmailDetail;
  subscriptions: Subscription[] = [];
  showModal = false;

  constructor(private studyLandingService: StudyServiceBase ) { }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }
  /**
   * This will get the email details for the passed in emailLogId.
   *
   * @param  {number} emailLogId - selected emailLogId in preview pane
   */
  getEmail(emailLogId: number) {
    this.subscriptions.push(this.studyLandingService
      .getNotificationEmailDetail(emailLogId)
      .subscribe(result => {

        if (result) {
          this.notificationEmailDetail = result;
          this.showModal = true;
        }
      }))

  }
  /**
   * Gets the recipients list and join with comma seperator if there are more than one.
   *
   * @returns string
   */
  public getRecipientsList(): string {
    let recipientsFormattedList = '';
    recipientsFormattedList = this.notificationEmailDetail.recipients[0];
    recipientsFormattedList = _.map(this.notificationEmailDetail.recipients).join(', ');
    return recipientsFormattedList;
  }


  /**
   * Closes the modal
   */
  closeModal() {
    this.showModal = false;
  }

}
