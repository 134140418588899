<div class="modal" [ngClass]="{'is-active': showModal}">
    <div class="modal-background"></div>
    <div class="modal-card">
        <div class="modal-card-head">
            <p class="modal-card-title">{{ modalTitle }}</p>
        </div>

        <div class="modal-card-body">
            <div class="description">
                <div [innerHTML]="modalBody">
                </div>
            </div>
        </div>

        <div class="modal-card-foot">
            <div class="buttons">
                <a class="button is-black is-radiusless" (click)=" setIsConfirmed(true)">
                    <span class="icon">
                        <i class="fas fa-check"></i>
                    </span>
                    <span>Yes</span>
                </a>
                <div class="button-spacer"></div>
                <a class="button is-no is-radiusless" (click)="setIsConfirmed(false)">
                    <span class="icon">
                        <i class="fas fa-times"></i>
                    </span>
                    <span>No</span>
                </a>
            </div>
        </div>
    </div>
</div>