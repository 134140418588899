<div class="error-content-div" #errorContainer >  
  <div class="error-indicator-container" *ngIf="!showErrorCallout" >
    <a (click)="showHideErrorMessage(true)">
      <span class="error-indicator" *ngIf="!errorDisplayArg.isCorrectionTile">
        <i class="fas fa-minus-square"></i>
      </span>
      <span class="success-indicator" *ngIf="errorDisplayArg.isCorrectionTile">
        <i class="fas fa-minus-square"></i>
      </span>
    </a>
  </div>
<div class="message event-error is-danger" *ngIf="showErrorCallout"
  [ngClass]="{'has-comment' :shouldDisplayOverride(), 'callout':!errorDisplayArg.isCorrectionTile, 'callout-success':errorDisplayArg.isCorrectionTile}">
  <div class="error-msg-body ">
    <div>
      <div class="has-delete-button is-pulled-right">
        <button class="delete" aria-label="delete" (click)="showHideErrorMessage(false)"></button>
      </div>
      <div class="has-message-text">


        <div>
          <ul class="has-message-text has-text-left">
            <li class="message-item is-danger" *ngFor="let msg of errorDisplayArg.currentError.errorMessages">
              <div [ngClass]="{'message-content':!errorDisplayArg.isCorrectionTile, 'message-content-success': errorDisplayArg.isCorrectionTile , 'ql-editor': true}" [innerHTML]="msg| safeHtml">
              <span *ngIf="shouldDisplayOverride()">
                (Please check and provide comment to override)
              </span>
              </div>
              <!-- <span>
                {{errorDisplayArg.currentError.errorMessage }}
              </span> -->
            </li>
          </ul>
          <div *ngIf="shouldDisplayOverride()">
            <div class="has-check-box" *ngIf="errorDisplayArg.required == null || errorDisplayArg.required != false">
              <label class="checkbox">
                <input type="checkbox" 
                [(ngModel)]="errorDisplayArg.errorCommentModel.caseFormVariableComment.overrideFlag">
                Override (Required)
              </label>
            </div>
            <div>
              <textarea class="textarea" rows="2" cols="45"
                [required]="(errorDisplayArg.required == null || errorDisplayArg.required != false) || errorDisplayArg.errorCommentModel.caseFormVariableComment.overrideFlag"
                [disabled]="(errorDisplayArg.required == null || errorDisplayArg.required == true) && !errorDisplayArg.errorCommentModel.caseFormVariableComment.overrideFlag"
                [(ngModel)]="errorDisplayArg.errorCommentModel.caseFormVariableComment.commentText" (change) = "onErrorModify($event)"></textarea>
            </div>
          </div>

        </div>
      </div>
      <div class="navigation-section is-size-7">
        <ul>
          <li class="is-pulled-right">
            <div class="navigation-link has-text-weight-bold ">
              <a *ngIf="errorDisplayArg.nextError" (click)="navigateTo($event, errorDisplayArg.nextError)">Next </a>
              <a *ngIf="!errorDisplayArg.nextError" (click)="goToFinish()">Finish</a>
            </div>
          </li>
          <li class="is-pulled-right">
            <div class="navigation-link has-text-weight-bold ">
              <a *ngIf="errorDisplayArg.previousError"
                (click)="navigateTo($event, errorDisplayArg.previousError)">Previous </a>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
  </div>

  
</div>