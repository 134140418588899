import { Directive } from '@angular/core';

@Directive({
  selector: '[mcMcFormField]'
})
export class McFormFieldDirective {

  constructor() { }

}
