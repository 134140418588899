import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import * as globalConst from 'app/common/model/app-constants';
import { Code } from 'app/common/model/code';
import { NotificationCode } from 'app/common/model/notification-code';
import { NotificationWildcard } from 'app/common/model/notification-wildcard';
import { Person } from 'app/common/model/person';
import { SearchCriteriaPerson } from 'app/common/model/search-criteria-person';
import { StudyNotification } from 'app/common/model/study-notification';
import { BaseService } from 'app/common/services/base-service';
import { Observable, of, Subject } from 'rxjs';
import { publishReplay, refCount, tap } from 'rxjs/operators';
import { NotificationSetupComponent } from '../../../admin/notification-setup/notification-setup.component';
import { StudyNotificationGroupingFactor } from '../../model/study-notification-grouping-factor';
import { StudyNotificationValidValue } from 'app/common/model/study-notification-valid-value';
import { NotificationSearchCriteria } from 'app/common/model/notification-search-criteria';
import { StudyNotificationSlotGroup } from 'app/common/model/study-notification-slot-group';


@Injectable()
export class NotificationDashboardService extends BaseService {

   notificationSearchSubject: Subject<NotificationSearchCriteria> = new Subject<NotificationSearchCriteria>();

   notificationSearch = this.notificationSearchSubject.asObservable();
  /**
   * variable for instance of notification being edited / created
   */
  private currentNotification$: StudyNotification;


  /**
     * variable for instance of notificationSetup component
     */
  private notificationSetup$: NotificationSetupComponent;

  private isNavigatedGroupNotification$: boolean;


  /****  Group Notifications ********************************** */


  /**
   * Get list of notification types to populate dropdown on create/edit notification configuration page
   * @param  level - Study or Group (the level of notification types to retrieve)
   * @returns Observable
   */
  private notificationStudyTypesList: NotificationCode[] = [];
  private tempNotificationStudyTypesObservable: Observable<any>;
  private notificationGroupTypesList: NotificationCode[] = [];
  private tempNotificationGroupTypesObservable: Observable<any>;
  private notificationAllTypesList: NotificationCode[] = [];
  private tempNotificationAllTypesObservable: Observable<any>;


  /**
   * Get list of notification accrual point types to populate dropdown on create/edit study notification page
   * @param  none
   * @returns Observable
   */
  private accrualPointsList: NotificationCode[] = [];
  private tempAccrualPointsObservable: Observable<any>;

  /**
   * Get list of notification event types to populate dropdown on create/edit group notification page
   * @param  none
   * @returns Observable
   */
  private eventTypeList: NotificationCode[] = [];
  private tempEventTypesObservable: Observable<any>;

  // TODO:  change endpoint
   /**
   * Get list of notification event types for audit notification type to populate radio buttons on create/edit group notification page
   * @param  none
   * @returns Observable
   */
  private auditEventTypeList: NotificationCode[] = [];
  private tempAuditEventTypesObservable: Observable<any>;


   /**
   * Get list of audit notification levels to populate radio buttons on create/edit group notification page
   * @param  none
   * @returns Observable
   */
  private auditLevelList: NotificationCode[] = [];
  private tempAuditLevelsObservable: Observable<any>;


  /**
   * Get list of accrual types to populate dropdown on create/edit study notification page
   * @param  none
   * @returns Observable
   */
  private accrualTypeList: NotificationCode[] = [];
  private tempAccrualTypesObservable: Observable<any>;

  /**
   * Get list of notification status types to populate dropdown on create/edit study notification page
   * @param  none
   * @returns Observable
   */
  private statusTypeList: NotificationCode[] = [];
  private tempStatusTypesObservable: Observable<any>;


  /**
   * Get list of notification distribution types  on create/edit study notification page
   * @param  none
   * @returns Observable
   */
  private distributionTypeList: NotificationCode[] = [];
  private tempDistributionTypesObservable: Observable<any>;

  /**
   * Get list of notification shared email list types  on create/edit notification page
   * @param  none
   * @returns Observable
   */
  private sharedEmailList: NotificationCode[] = [];
  private tempSharedEmailObservable: Observable<any>;

    /**
   * Get list of notification person types  on create/edit notification page
   * @param  none
   * @returns Observable
   */
  private personTypeList: Code[] = [];
  private tempPersonTypeObservable: Observable<any>;

  /**
   * Get list of wildcards to populate dropdown on the message text notification page
   * @param  none
   * @returns Observable
   */
  private wildcardList: NotificationWildcard[] = [];
  private tempWildcardObservable: Observable<any>;


   /**
   * Get list of approval types to populate dropdown on create/edit study notification page
   * @param  none
   * @returns Observable
   */
   private approvalTypeList: NotificationCode[] = [];
   private tempApprovalTypesObservable: Observable<any>;

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  /**
   * returns the instance of the notification being edited / created
   */
  public get currentNotification(): StudyNotification {
    return this.currentNotification$;
  }

  /**
   * sets the instance of the current notification being edited / created
   */
  public set currentNotification(notification: StudyNotification) {
    this.currentNotification$ = notification;
  }

  /**
   * returns the instance of the notificationSetup component
   */
  public get notificationSetup(): NotificationSetupComponent {
    return this.notificationSetup$;
  }

  /**
   * sets the instance of the  notification setup component
   */
  public set notificationSetup(notificationSetup: NotificationSetupComponent) {
    this.notificationSetup$ = notificationSetup;
  }
  /**
     * returns the instance of the isNavigatedGroupNotification value
     */
  public get isNavigatedGroupNotification(): boolean {
    return this.isNavigatedGroupNotification$;
  }

  /**
   * sets the instance of the isNavigatedGroupNotification value
   */
  public set isNavigatedGroupNotification(isNavigatedGroup: boolean) {
    this.isNavigatedGroupNotification$ = isNavigatedGroup;
  }

  /**
   * Returns True if the notification is of type "Notify"
   * @param notification the notification to verify
   */
  public isNotificationTypeNotify(notification: StudyNotification): boolean {
    let isNotify = false;
    if (notification && notification.notificationType && notification.notificationType.value) {
      isNotify = notification.notificationType.value === globalConst.NOTIFY_TYPE_NOTIFY;
    }
    return isNotify;
  }




  /**
   *
   * Calls the service to add a new study Notification
   * @param  number studyId - studyId to fetch the notification associated with it.
   * @param StudyNotification studyNotification -studyNotification object which needs to be saved to DB
   * @returns Observable
   */
  public addStudyNotification(studyId: number, studyNotification: StudyNotification): Observable<StudyNotification> {
    const url = 'studies/' + studyId + '/notifications';
    return this.http.post<StudyNotification>(`${this.config.apiEndpoint}` + url, studyNotification);
  }

  /**
   * Delete an existing studyNotification
  * @param  number studyId - studyIdof the notification associated with it.
  * @param number notificationId - notificationId to be deleted
  * @param number tupleVersion tuple version of the data
  * @returns Observable the selected notification from the db
  */
  public deleteStudyNotification(studyId: number, notificationId, tupleVersion: number): Observable<any> {
    const url = 'studies/' + studyId + '/notifications/' + notificationId;
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
    return this.http.delete(`${this.config.apiEndpoint}` + url, { headers: header, responseType: 'text' });
  }


  /**
   *  Calls the service to get notifications associated with this study
    * @param  number studyId - studyId to fetch the notification associated with it.
    * @returns Observable
    */
  public getStudyNotifications(studyId: number): Observable<Array<StudyNotification>> {
    const url = 'studies/' + studyId + '/notifications';
    return this.http.get<Array<StudyNotification>>
      (`${this.config.apiEndpoint}` + url);
  }


  /****  Group Notifications ********************************** */
  /**
   * calls service to add   Group notification
   * @param studyNotification
   * @returns Observable
   */
  public addGroupNotification(studyNotification: StudyNotification): Observable<StudyNotification> {
    const url = 'group-notifications';
    return this.http.post<StudyNotification>(`${this.config.apiEndpoint}` + url, studyNotification);
  }

  /**
   * Gets the Group notifications from the service
   * @returns Observable
   */
  public getGroupNotifications(): Observable<Array<StudyNotification>> {
    const url = 'group-notifications';
    return this.http.get<Array<StudyNotification>>
      (`${this.config.apiEndpoint}` + url);
  }

  /**
  * Delete an existing group Notification
  * @param number notificationId - notificationId to be deleted
  * @param number tupleVersion tuple version of the data
  * @returns Observable the selected notification from the db
  */
  public deleteGroupNotification(notificationId, tupleVersion: number): Observable<any> {
    const url = 'group-notifications/' + notificationId;
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
    return this.http.delete(`${this.config.apiEndpoint}` + url, { headers: header, responseType: 'text' });
  }


  /// group-notifications/{notificationId} Gets group notification
  /**
  * @param number notificationId - notificationId to be retrieved
  * @returns Observable the selected notification from the db
  */
  public getSingleGroupNotification(notificationId: number): Observable<StudyNotification> {
    const url = 'group-notifications/' + notificationId;
    return this.http.get<StudyNotification>
      (`${this.config.apiEndpoint}` + url);
  }


  /**
   * Updates the GroupNotification object
   * @param notificationId The notification Id
   * @param notification The notification to be updated
   */
  public updateGroupNotification(notificationId: number, notification: StudyNotification): Observable<StudyNotification> {
    const url = 'group-notifications/' + notificationId;
    return this.http.put<StudyNotification>
      (`${this.config.apiEndpoint}` + url, notification);
  }
  getNotificationTypes(level: string): Observable<Array<NotificationCode>> {
    if (level.toLowerCase() === 'study') {
      if (this.notificationStudyTypesList.length > 0) {
        return of(this.notificationStudyTypesList);
      } else if (this.tempNotificationStudyTypesObservable) {
        return this.tempNotificationStudyTypesObservable;
      } else {
        this.tempNotificationStudyTypesObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/notification-types?notificationLevel=' + level)
          .pipe(tap(oList => {
            this.tempNotificationStudyTypesObservable = null;
            this.notificationStudyTypesList = oList;
            return of(this.notificationStudyTypesList);
          }))
          .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
          .pipe(refCount());
        return this.tempNotificationStudyTypesObservable;
      }
    } else if(level.toLowerCase() === 'group') {// level is Group
      if (this.notificationGroupTypesList.length > 0) {
        return of(this.notificationGroupTypesList);
      } else if (this.tempNotificationGroupTypesObservable) {
        return this.tempNotificationGroupTypesObservable;
      } else {
        this.tempNotificationGroupTypesObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/notification-types?notificationLevel=' + level)
          .pipe(tap(oList => {
            this.tempNotificationGroupTypesObservable = null;
            this.notificationGroupTypesList = oList;
            return of(this.notificationGroupTypesList);
          }))
          .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
          .pipe(refCount());
        return this.tempNotificationGroupTypesObservable;
      }
    }else{ // assuming that the level is for all
      if (this.notificationAllTypesList.length > 0) {
        return of(this.notificationAllTypesList);
      } else if (this.tempNotificationAllTypesObservable) {
        return this.tempNotificationAllTypesObservable;
      } else {
        this.tempNotificationAllTypesObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/notification-types')
          .pipe(tap(oList => {
            this.tempNotificationAllTypesObservable = null;
            this.notificationAllTypesList = oList;
            return of(this.notificationAllTypesList);
          }))
          .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
          .pipe(refCount());
        return this.tempNotificationAllTypesObservable;
      }
    }
  }
  
  getAccrualPoints(): Observable<Array<NotificationCode>> {
    if (this.accrualPointsList.length > 0) {
      return of(this.accrualPointsList);
    } else if (this.tempAccrualPointsObservable) {
      return this.tempAccrualPointsObservable;
    } else {
      this.tempAccrualPointsObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/accrual-points')
        .pipe(tap(oList => {
          this.tempAccrualPointsObservable = null;
          this.accrualPointsList = oList;
          return of(this.accrualPointsList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempAccrualPointsObservable;
    }
  }
  getAccrualEventTypes(): Observable<Array<NotificationCode>> {
    if (this.eventTypeList.length > 0) {
      return of(this.eventTypeList);
    } else if (this.tempEventTypesObservable) {
      return this.tempEventTypesObservable;
    } else {
      this.tempEventTypesObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/accrual-event-types')
        .pipe(tap(oList => {
          this.tempEventTypesObservable = null;
          this.eventTypeList = oList;
          return of(this.eventTypeList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempEventTypesObservable;
    }
  }
  getAuditEventTypes(): Observable<Array<NotificationCode>> {
    if (this.auditEventTypeList.length > 0) {
      return of(this.auditEventTypeList);
    } else if (this.tempAuditEventTypesObservable) {
      return this.tempAuditEventTypesObservable;
    } else {
      this.tempAuditEventTypesObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/audit-event-types')
        .pipe(tap(oList => {
          this.tempAuditEventTypesObservable = null;
          this.auditEventTypeList = oList;
          return of(this.auditEventTypeList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempAuditEventTypesObservable;
    }
  }
  getAuditLevels(): Observable<Array<NotificationCode>> {
    if (this.auditLevelList.length > 0) {
      return of(this.auditLevelList);
    } else if (this.tempAuditLevelsObservable) {
      return this.tempAuditLevelsObservable;
    } else {
      this.tempAuditLevelsObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/audit-notification-type-levels')
        .pipe(tap(oList => {
          this.tempAuditLevelsObservable = null;
          this.auditLevelList = oList;
          return of(this.auditLevelList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempAuditLevelsObservable;
    }
  }
  getAccrualTypes(): Observable<Array<NotificationCode>> {
    if (this.accrualTypeList.length > 0) {
      return of(this.accrualTypeList);
    } else if (this.tempAccrualTypesObservable) {
      return this.tempAccrualTypesObservable;
    } else {
      this.tempAccrualTypesObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/accrual-types')
        .pipe(tap(oList => {
          this.tempAccrualTypesObservable = null;
          this.accrualTypeList = oList;
          return of(this.accrualTypeList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempAccrualTypesObservable;
    }
  }
  getStatusTypes(): Observable<Array<NotificationCode>> {
    if (this.statusTypeList.length > 0) {
      return of(this.statusTypeList);
    } else if (this.tempStatusTypesObservable) {
      return this.tempStatusTypesObservable;
    } else {
      this.tempStatusTypesObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/status-types')
        .pipe(tap(oList => {
          this.tempStatusTypesObservable = null;
          this.statusTypeList = oList;
          return of(this.statusTypeList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempStatusTypesObservable;
    }
  }
  getDistributionTypes(): Observable<Array<NotificationCode>> {
    if (this.distributionTypeList.length > 0) {
      return of(this.distributionTypeList);
    } else if (this.tempDistributionTypesObservable) {
      return this.tempDistributionTypesObservable;
    } else {
      this.tempDistributionTypesObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/distribution-types')
        .pipe(tap(oList => {
          this.tempDistributionTypesObservable = null;
          this.distributionTypeList = oList;
          return of(this.distributionTypeList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempDistributionTypesObservable;
    }
  }
  getSharedEmails(): Observable<Array<NotificationCode>> {
    if (this.sharedEmailList.length > 0) {
      return of(this.sharedEmailList);
    } else if (this.tempSharedEmailObservable) {
      return this.tempSharedEmailObservable;
    } else {
      this.tempSharedEmailObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/shared-emails')
        .pipe(tap(oList => {
          this.tempSharedEmailObservable = null;
          this.sharedEmailList = oList;
          return of(this.sharedEmailList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempSharedEmailObservable;
    }
  }
  getPersonTypes(): Observable<Array<Code>> {
    if (this.personTypeList.length > 0) {
      return of(this.personTypeList);
    } else if (this.tempPersonTypeObservable) {
      return this.tempPersonTypeObservable;
    } else {
      this.tempPersonTypeObservable = this.http.get<Array<Code>>(`${this.config.apiEndpoint}` + 'configuration/notification-person-type-codes')
        .pipe(tap(oList => {
          this.tempPersonTypeObservable = null;
          this.personTypeList = oList;
          return of(this.personTypeList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempPersonTypeObservable;
    }
  }

  /**
   * Get list of variable responses to populate checkbox choices when accrual type "Grouping Factor" is selected on the configuration component
   * @param  studyId
   * @returns Observable
   */
  // private variableResponseList: NotificationCode[] = [];
  // private tempVariableResponseObservable: Observable<any>;
  // getVariableResponses(studyId : number): Observable<Array<NotificationCode>> {
  //   if (this.variableResponseList.length > 0) {
  //     return of(this.variableResponseList);
  //   }
  //   else if (this.tempVariableResponseObservable) {
  //     return this.tempVariableResponseObservable;
  //   } else {
  //     this.tempVariableResponseObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'studies/' + studyId + '/schema/variableResponses')
  //       .do(oList => {
  //         this.tempVariableResponseObservable = null;
  //         this.variableResponseList = oList;
  //         return of(this.variableResponseList);
  //       })
  //       .publishReplay() //to ensure that this list is cached and shared with subscribers
  //       .refCount();
  //       return this.tempVariableResponseObservable;
  //   }
  // }


  /// studies/{studyId}/notifications/{notificationId}Gets study notification
  /**
  * @param  number studyId - studyId to fetch the notification associated with it.
  * @param number notificationId - notificationId to be retrieved
  * @returns Observable the selected notification from the db
  */
  public getSingleStudyNotification(studyId: number, notificationId: number): Observable<StudyNotification> {
    const url = 'studies/' + studyId + '/notifications/' + notificationId;
    return this.http.get<StudyNotification>
      (`${this.config.apiEndpoint}` + url);
  }




  /**
   * Updates the StudyNotification object
   * @param studyId Study Id
   * @param notificationId The notification Id
   * @param notification The notification to be updated
   */
  public updateStudyNotification(studyId: number, notificationId: number, notification: StudyNotification): Observable<StudyNotification> {
    const url = 'studies/' + studyId + '/notifications/' + notificationId;
    return this.http.put<StudyNotification>
      (`${this.config.apiEndpoint}` + url, notification);
  }
  /**
   * This will create group notification
   *
   * @param  StudyNotification groupNotification
   * @returns Observable - group notification
   */
  public createGroupNotification(groupNotification: StudyNotification): Observable<StudyNotification> {
    const url = 'group-notifications';
    return this.http.post<StudyNotification>(`${this.config.apiEndpoint}` + url, groupNotification);
  }




  // studies/11908/notifications/grouping-factors
  /**
  * @param  number studyId - studyId to fetch the notification associated with it.
  * @returns Array<Observable> the studies grouping factors from the db
  */
  public getGroupingFactors(studyId: number): Observable<StudyNotificationGroupingFactor[]> {
    const url = 'studies/' + studyId + '/notifications/grouping-factors';
    return this.http.get<StudyNotificationGroupingFactor[]>
      (encodeURI(`${this.config.apiEndpoint}` + url));
  }

  public getFormsValidValue(studyId: number): Observable<StudyNotificationValidValue[]> {
    const url = 'studies/' + studyId + '/notifications/forms/valid-values';
    return this.http.get<StudyNotificationValidValue[]>
      (encodeURI(`${this.config.apiEndpoint}` + url));
  }

  // studies/{studyId}/notifications/slot-groups
  /**
  * @param  studyId - studyId to fetch the notification associated with it.
  * @returns  the studies slot groups from the db
  */
   public getSlotGroups(studyId: number): Observable<StudyNotificationSlotGroup[]> {
    const url = 'studies/' + studyId + '/notifications/slot-groups';
    return this.http.get<StudyNotificationSlotGroup[]>
      (encodeURI(`${this.config.apiEndpoint}` + url));
  }



  /**
   * Searches for the person based on the criteria provided
   * @param firstName The fragment of firstName to search
   * @param lastName The fragment of last name to search
   * @param email The email to search
   */
  public getPersonForCriteria(searchCriteria: SearchCriteriaPerson): Observable<Person[]> {
    let qryParams = '';
    if (searchCriteria.firstName && searchCriteria.firstName.trim().length > 0) {
      qryParams += 'firstName=' + searchCriteria.firstName;
    }
    if (searchCriteria.lastName && searchCriteria.lastName.trim().length > 0) {
      if (qryParams.length > 0) {
        qryParams += '&';
      }
      qryParams += 'lastName=' + searchCriteria.lastName;
    }
    if (searchCriteria.email && searchCriteria.email.trim().length > 0) {
      if (qryParams.length > 0) {
        qryParams += '&';
      }
      qryParams += 'email=' + searchCriteria.email;
    }
    // https://int.reg-backend.mayo.edu/api/v1/users?firstName=test&lastName=t
    const url = 'users?' + qryParams;
    return this.http.get<Person[]>
      (`${this.config.apiEndpoint}` + url);
  }
  getNotificationWildcards(): Observable<Array<NotificationWildcard>> {
    if (this.wildcardList.length > 0) {
      return of(this.wildcardList);
    } else if (this.tempWildcardObservable) {
      return this.tempWildcardObservable;
    } else {
      this.tempWildcardObservable = this.http.get<Array<NotificationWildcard>>(`${this.config.apiEndpoint}` + 'notifications/wildcards')
        .pipe(tap(oList => {
          this.tempWildcardObservable = null;
          this.wildcardList = oList;
          return of(this.wildcardList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempWildcardObservable;
    }
  }

  /**
   * Set notification search data
   * @param value 
   */
  public setNotificationSearchData(value) {
    this.notificationSearchSubject.next(value);
  }

  /**
   * Get Approval Types
   * @returns 
   */
  getApprovalTypes(): Observable<Array<NotificationCode>> {
    if (this.approvalTypeList.length > 0) {
      return of(this.approvalTypeList);
    } else if (this.tempApprovalTypesObservable) {
      return this.tempApprovalTypesObservable;
    } else {
      this.tempApprovalTypesObservable = this.http.get<Array<NotificationCode>>(`${this.config.apiEndpoint}` + 'notifications/approval-types')
        .pipe(tap(oList => {
          this.tempApprovalTypesObservable = null;
          this.approvalTypeList = oList;
          return of(this.approvalTypeList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempApprovalTypesObservable;
    }
  }
}
