import { DateRange } from "./date-range";
import { NotificationCode } from "./notification-code";

export class NotificationSearchCriteria {
    constructor(
        public sponsorProtocolNumber : string | null,
        public subjectId : string | null,
        public notificationType : NotificationCode | null,
        public notificationName: string | null,
        public dateRange : DateRange | null,
        public recipient: string | null,
    ) {}
}
