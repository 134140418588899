import { PatientSubjectReference } from "./patient-subject-reference";

export class PatientInfo {
  constructor(
    public firstName: string | null,
    public lastName: string | null,
    public middleName: string | null,
    public genderId: number | null,
    public raceIds: number[] = [],
    public ethnicityId: number | null,
    public dateOfBirthString: string | null,
    public zipCode: string | null,
    public countryId: number | null,
    public medDraId: number | null,
    public methodOfPaymentId: number | null,
    public subjectReferences: PatientSubjectReference[] = [],
  ) {}
}
