
<div class="columns">
  <div class="column no-bottom-padding">
    <h3>Notification Dashboard</h3>
  </div>

  <div class="column no-bottom-padding edit-bar-section">
    <mc-edit-bar [selectionCount]="selectedIds.length" [actionSetting]="editBarActions" (selectedActionEvent)="selectedEditAction($event)">
    </mc-edit-bar>
  </div>
</div>


<div class=" form-table-container  study-setup-content">
  <table class="table is-fullwidth is-borderless " ngClass="{{config.className || ''}}" role="grid">
    <thead>
      <tr role="row">
        <th class="has-text-centered">
          
        </th>
        <ng-container *ngFor="let column of columns">
            <th *ngIf="column.display" class="table-header"
            [mcTableSorting]="config" [column]="column" title="{{column.title}}"
                (sortChanged)="onChangeTable($event)"
              ngClass="{{column.className || ''}}">
              <span> {{column.title}}</span>
              <span *ngIf="config && column.sort" class="sort  is-text-centered">
                <span *ngIf="column.sort === 'desc'">
                    <i class="fas fa-sort-down"></i>
                  </span>
                  <span *ngIf="column.sort === 'asc'">
                    <i class="far fa-sort-up"></i>
                  </span>
              </span>
            </th>
          </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="showFilterRow">
        <td *ngFor="let column of columns; let i=index">
          <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" class="form-control"
            (tableChanged)="onChangeTable(config)" />
        </td>
      </tr>
      <tr *ngFor="let row of rows; let rowIdx = index" [ngClass]="{'is-selected' : isRowSelected(row)}">
        <td class="has-text-centered">
          <a (click)="cellClick(row, 'select')">
            <span>
              <span *ngIf="!isRowSelected(row)">
                  <i class="far fa-square"></i>
              </span>
              <span *ngIf="isRowSelected(row)">
                <i class="far fa-check-square"></i>
              </span>
            </span>
          </a>
        </td>
        <td>
           <a (click)="navigateToNotificationSetup($event, row)">
            <span class="has-text-weight-bold">
             {{row.name}}
          </span> 
          </a>


          <span>  </span>
        </td>
        <td [ngClass]="{'selected':isInfoSelected(row)}" class="display-message is-display-message-multiline is-display-message-bottom"
        [attr.data-display-message]="getInfoMessage(row)">
          <span>{{row.notificationLevel}} </span>
        </td>
        <td>
          <span> {{row.notificationType}} </span>
        </td>        
        <td>
          <span>{{row.notificationConfiguration}} </span>
        </td>
        <td>
          <span>{{row.notificationRecipients}} </span>
        </td>
        <td *ngIf="!isGroupNotifications">          
          <div *ngIf="row.isSubscribed===null">
            <span> N/A </span>
          </div>
          <div *ngIf="row.isSubscribed!==null && !isEditMode(row.notificationId)">
            <span *ngIf="row.isSubscribed" >Yes</span>
            <span *ngIf="!row.isSubscribed" >No</span>
          </div>
          <div *ngIf="canEditSubscription(row)" >
            <select  [(ngModel)]="row.isSubscribed"  >
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false" >No</option>
            </select>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
