import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as sanitizeHtml from 'sanitize-html';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    if (value) {
      const result = sanitizeHtml(value,{
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ,'iframe' ]),
        allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat([ 'data' ]),
        allowedAttributes: {
          a: ['href', 'rel', 'target', 'style', 'class'],
          img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ],
          span: ['style', 'class'],
          h1: ['style', 'class'],
          h2: ['style', 'class'],
          strong: ['style', 'class'],
          u: ['style', 'class' ],
          em: ['style', 'class'],
          p: ['style', 'class'],
          li: ['style', 'class'],
          sub: ['style', 'class'],
          sup: ['style', 'class'],
          iframe: [ 'src', 'class', 'allowfullscreen', 'frameborder']
        },
        allowedSchemesByTag : {
          img :  [ 'data' ]
        }
      } ); 
      return this.sanitized.bypassSecurityTrustHtml(result);
    }
    return value;
  }
}
