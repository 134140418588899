<section class="section" [ngClass]="{'is-hidden': patientsFound}">
    <div class="columns">
        <div class="column">
            <h3 class="has-text-weight-bold">
                No in-progress registrations found
            </h3>
        </div>
    </div>
</section>

<div class="search-result" [ngClass]="{'is-hidden': !patientsFound}">

    <div class=" form-table-container">
        <table class="table is-fullwidth is-striped" ngClass="{{config.className || ''}}" role="grid">
            <thead>
                <tr role="row" class="is-selected">
                    <ng-container *ngFor="let column of columns">
                        <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}" (sortChanged)="onChangeTable($event)"
                            ngClass="{{column.className || ''}}">
                            <span> {{column.title}}</span>
                            <span *ngIf="config && column.sort" class="sort  is-text-centered">
                                <span *ngIf="column.sort === 'desc'">
                                    <i class="fas fa-sort-down"></i>
                                </span>
                                <span *ngIf="column.sort === 'asc'">
                                    <i class="fas fa-sort-up"></i>
                                </span>
                            </span>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of rows">
                    <td>
                        <span> {{row.patient.studyNumber}}</span>
                    </td>
                    <td>
                        <a (click)="goToPatientLanding(row.patient)">
                            <span class="is-size-6 has-text-weight-bold ">
                                {{row.patient.trackingNum}}
                            </span>
                        </a>                    
                    </td>
                    <td>
                       {{row.patient.patientId}}
                    <td>
                        <span> {{row.patient.firstName}} {{row.patient.lastName}}</span>
                    </td>
                    <td>
                        <span> {{row.patient.dateOfBirthString }}</span>
                    </td>
                    <td>
                        <span> {{row.treatingLocation.name}}</span>
                    </td>
                    <td>
                        <span> {{row.event.schemaEventDisplayName}}</span>
                    </td>
                    <td>
                            <span> {{row.registrar.firstName}}  {{row.registrar.lastName}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
