import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from 'app/admin/admin/admin.component';
import { AuthGuard } from 'app/auth-guard.service';



const adminRoutes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canLoad: [AuthGuard],
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AdminRoutingModule { }
