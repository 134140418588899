import { StudySchemaEvent } from 'app/common/model/study-schema-event';
import { Code } from './code';
import { StudySchemaEventDeleteMap } from './study-schema-event-delete-map';

export class StudySchema {
    constructor(
        public studyId: number | null,
        public studyNumber: string | null,
        public studySchemaId: number | null,
        public studySchemaVersionNum: number | null,
        public studySchemaDesc: string | null,
        public modifiedById: string | null,
        public studySchemaTupleVersion: number | null,
        public studySchemaEvents: StudySchemaEvent[] | null,
        public warnings: string | null,
        //start of new properties for schema versioning
        public studySchemaName: string | null,
        public studySchemaStatus: Code | null,
        public effectiveDate: Date | null,
        public versionDate: string | null,
        public comment: string | null,
        public useForSimulation: boolean,
        public hasDynamicAllocation: boolean,
        public modifiedByTs: Date | null,
        public modifiedDate: Date | null,
        public deleteMap?: StudySchemaEventDeleteMap[]
        //end of new properties for schema versioning
    ) { }
}
