<div class="columns notify-main">
    <div class="column  is-2 sub-menu">
      <aside class="menu">
        <ul class="menu-list">
          <li class="nav-bar-style">
             <a [routerLinkActive]="['is-active']" class=" sectionLink" routerLink="../../notification">
              <div>
                <i class="fas fa-arrow-left" aria-hidden="true">
                </i>
                <span>Back to All Notifications</span> 
              </div>
            </a>
          </li>
          <li class="nav-bar-style" *ngFor="let sectionItem of sectionItems; let i = index">
            <a [routerLinkActive]="['is-active']" class=" sectionLink" 
            routerLink="./{{sectionItem.path}}" queryParamsHandling="merge">
              <div>
                <i class="{{sectionItem.icon}}" aria-hidden="true">
                </i>
                <span>{{sectionItem.title}}</span>
              </div>
            </a>
          </li>
        </ul>
      </aside>
    </div>

    <div class="column no-pad form-content study-setup-component">
      <router-outlet></router-outlet>
    </div>
  </div>
