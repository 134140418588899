import { Component, OnDestroy, OnInit } from '@angular/core';
import { PatientRegPreviewParam } from 'app/common/model/patient-reg-preview-param';
import { NameValueClassSet, PatientRegistrationPreview } from 'app/common/model/patient-registration-preview';
import { PatientService } from 'app/common/services/patient/patient.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-patient-reg-preview',
  templateUrl: './patient-reg-preview.component.html',
  styleUrls: ['./patient-reg-preview.component.scss']
})
export class PatientRegPreviewComponent implements OnInit, OnDestroy {

  previewParams: PatientRegPreviewParam = null;

  subscriptions: Subscription[] = [];

  regPreview: PatientRegistrationPreview;

  doseLevelVars: NameValueClassSet[] = [];

  propertyFirstName = 'firstName';
  propertyLastName = 'lastName';
  propertyMiddleName = 'middleName';
  propertyGender = 'gender';
  propertyRace = 'races';
  propertyEthnicity = 'ethnicity';
  propertyDateOfBirthString = 'dateOfBirthString';
  propertyZipCode = 'zipCode';
  propertyCountry = 'country';
  propertyMedDra = 'medDra';
  propertyMethodOfPayment = 'methodOfPayment';
  propertyOtherSubjectId = 'otherSubjectId';
  propertyLinkedSubjectIds = 'linkedSubjectIds';
  propertySgc = 'sgc';
  propertyTacTad = 'tacTad';

  constructor(private patientService: PatientService) {
    this.subscriptions.push(
      this.patientService.regPreviewParams$.subscribe
        (params => {
          this.previewParams = params;
          this.getPreviewData();
        })
    );
  }

  ngOnInit() {

  }


  /**
 * Destroy implementation - closes all the subscriptions
 */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed = true;
      }
    );
  }




  currentRegModel(): PatientRegistrationPreview {
    return this.regPreview;
  }

  /**
   * Retrieves the Preview Data
   */
  getPreviewData() {
    this.regPreview = null;
    if (this.previewParams && this.previewParams.showDetails && this.previewParams.caseEventId > 0) {
      // fetch the preview Model from the service
      this.subscriptions.push(this.patientService.getPatientPreviewModel(this.previewParams.caseEventId)
        .subscribe(results => {
          this.regPreview = results;
          this.getDoseLevelVars();
        }));
    }
  }


  /**
   * True if the Property should be displayed
   */
  shouldDisplayLastName(): boolean {
    return this.shouldDisplay(this.propertyLastName);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayMiddleName(): boolean {
    return this.shouldDisplay(this.propertyMiddleName);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayFirstName(): boolean {
    return this.shouldDisplay(this.propertyFirstName);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayGender(): boolean {
    return this.shouldDisplay(this.propertyGender);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayRace(): boolean {
    return this.shouldDisplay(this.propertyRace);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayEthnicity(): boolean {
    return this.shouldDisplay(this.propertyEthnicity);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayDOB(): boolean {
    return this.shouldDisplay(this.propertyDateOfBirthString);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayZipCode(): boolean {
    return this.shouldDisplay(this.propertyZipCode);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayCountry(): boolean {
    return this.shouldDisplay(this.propertyCountry);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayMedDra(): boolean {
    return this.shouldDisplay(this.propertyMedDra);
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayPaymentMethod(): boolean {
    return this.shouldDisplay(this.propertyMethodOfPayment);
  }


  /**
   * True if the Property should be displayed
   */
  shouldDisplayOtherSubjectId(): boolean {
    return this.shouldDisplay(this.propertyOtherSubjectId);
  }

  /**
  * True if the Property should be displayed
  */
  shouldDisplayLinkedTo(): boolean {
    return this.shouldDisplay(this.propertyLinkedSubjectIds);
  }

  /**
* True if the Property should be displayed
*/
  shouldDisplaySgc(): boolean {
    return this.shouldDisplay(this.propertySgc);
  }

  /**
* True if the Property should be displayed
*/
  shouldDisplayTacTad(): boolean {
    return this.shouldDisplay(this.propertyTacTad);
  }

  public shouldDisplayAssignedKits(): boolean {
    let shouldDisplay = false;
    if (this.currentRegModel() && this.currentRegModel().doubleBlindResponse && this.currentRegModel().doubleBlindResponse.length > 0) {
      shouldDisplay = true;
    }
    return shouldDisplay;
  }

  /**
   * True if the Property should be displayed
   */
  shouldDisplayEnrolledAncillaries(): boolean {
    return this.regPreview.enrolledAncillaries && this.regPreview.enrolledAncillaries.length > 0;
  }

  /**
   * Evaluates if the property should be displayed on the UI
   * @param propertyName The name of the property to evaluate
   */
  private shouldDisplay(propertyName: string): boolean {
    let sd = false;
    if (this.currentRegModel() && this.currentRegModel()[propertyName] !== undefined) {
      sd = this.currentRegModel()[propertyName] != null;
    }
    return sd;
  }

  private getDoseLevelVars() {
    if (this.currentRegModel() && this.currentRegModel().patientForms) {
      const checklistForm = this.currentRegModel().patientForms.find(form => form.formName === "CheckList");
      if (checklistForm?.formVariables) {
        this.doseLevelVars = checklistForm.formVariables.filter(formVar => formVar.varClass === "dose_level" && formVar.nameValue?.length > 0);
      }
    }
  }


}
