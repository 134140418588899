
/* OBSOLETE ************************************
NOTE - 08/16/2018: This section is obsolete and the paths defined here are purely for backward compabitlity
Please use the runtime-configs/conf.json to manage your build and environments
*/

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const protectedResourceMap = new Map<string, string[]>();
protectedResourceMap.set('http://localhost:8080/reg-backend-service/api/', ['api://<api_client_id>/access_as_user']);

export const environment = {
  version: 'v2',
  production: false,
  envName: 'prod',
  serviceUrl: 'http://localhost:8080/reg-backend-service/api/v1/',
  serviceUrlv2: 'http://localhost:8080/reg-backend-service/api/v2/',
  logoutUrl: 'https://dev.registration.mayo.edu/signout',
  msalConfig: {
    issuer: 'aa',
    tenantId: 'bb',
    clientId: 'xx',
    authority: "yy",
    knownAuthorities: [],
    redirectUri: 'zz',
    postLogoutRedirectUri: 'zz',
    cacheLocation: 'localStorage',
    scope: 'cc',
    protectedResourceMap: protectedResourceMap
  }
};
