<mc-header-bar [headerModel]="headerModel" (saveEvent)="saveChanges()" (cancelEvent)="cancelChanges()"></mc-header-bar>
<div class=""
  [ngClass]="{ 'switchToHorizontal': previewModelHome.isVertical,'is-vertical-open': previewModelHome.isCollapsed  }">
  <div [ngClass]="{'switchVertical columns':previewModelHome.isVertical }">
    <div [ngClass]="{ 'column is-main-section-vertical': previewModelHome.isVertical,
      'is-6' : isExpandedVertical(), 'is-11' : isCollapsedVertical() }" class="main-section" id="mainSection">
      <div class="search-section form-page" id="main-content">
        <div class="columns is-marginless form-content">
          <div class="column is-12 is-paddingless">
            <div class="nav-return-bar">
              <ul>
                <li class="has-link ">
                  <a (click)="navigateBack()">
                    <span class="icon">
                      <i class="fas fa-arrow-left"></i>
                    </span>
                    <span class="link-text">Back to {{navbackTitle() }}</span>
                  </a>
                </li>
                <li *ngFor="let item of schemaEvents; let i = index">
                  <a (click)="selectEvent($event, item)"
                    [ngClass]="{'is-active' : isSelectedEvent(item), 'is-inactive':isInactiveEvent(item, i), 'is-hidden':isEventHide(item, i) }">
                    <span>{{getSchemaEventNameLabel(item)}} </span>
                  </a>
                </li>
              </ul>
            </div>
            <!-- <div class="columns is-marginless ">
          <div class="column form-content no-bottom-padding"> -->
            <div class="reg-tiles">
              <div class="tile is-ancestor reg-tile">

                <div class="tile is-one-fifth is-parent">
                  <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_PATIENT_REGISTRATION'"
                    [studyId]="studyId" accessType="create">
                    <div class="color-bar peacock-blue"></div>
                    <div class="content-div">
                      <p class="title is-5 has-text-weight-bold">Registration</p>
                      <p class="title is-3 has-text-weight-bold">{{getRegistationEventName()}}</p>
                    </div>
                    <div>
                      <button type="button" (click)="navigateForNextEvent()"
                        [ngClass]="{'is-disabled': !isPatientApprovedToContinue()}"
                        class="button is-primary  is-radiusless ">
                        <span class="icon ">
                          <i class="fas fa-pencil-alt fa-xs" aria-hidden="true"></i>
                          <span class="has-left-padding-10">Continue</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- /.Continue Registration tile-->


                <div class="tile is-one-fifth is-parent">
                  <div class="tile is-child box is-radiusless" [ngClass]="{'is-disabled': isApprovalTileDisabled()}">
                    <div class="color-bar purple"></div>
                    <div class="content-div">
                      <p class="title is-5 has-text-weight-bold">Approval</p>
                      <p class="title is-3 has-text-weight-bold">{{approvalStatus}}</p>
                    </div>
                    <div>
                      <button type="button" class="button is-primary is-radiusless"
                        [disabled]="isApprovalButtonDisabled()" (click)="navigateToApproval($event)">

                        <span class="icon">
                          <i class="far fa-thumbs-up fa-sm" aria-hidden="true"></i>
                        </span>
                        <span>Manage</span>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- /.approval-tile -->

                <div class="tile is-one-fifth is-parent">
                  <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_PATIENT_ELIGIBILITY_UPDATE'"
                    [studyId]="studyId">
                    <div class="color-bar orange"></div>
                    <div class="content-div">
                      <p class="title is-5 has-text-weight-bold">Eligibility</p>
                      <p class="title is-3 has-text-weight-bold">{{getPatientEligibilityStatus()}}</p>
                      <p class="subtitle is-6">{{getPatientEligibilityStatusDate()}}</p>
                    </div>
                    <div>
                      <button type="button" [mcCanNavigate]="'ACTIVITY_PATIENT_ELIGIBILITY_UPDATE'"
                        class="button is-primary is-radiusless" (click)="editEligibility()">
                        <span class="icon">
                          <i class="far fa-check-circle fa-sm" aria-hidden="true"></i>
                        </span>
                        <span>Corrections</span>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- /.eligibility-tile -->

                <div class="tile is-one-fifth is-parent ">
                  <div class=" tile is-child box is-radiusless " [ngClass]="{'is-disabled':invalidForTransfer() }">
                    <div class="color-bar green"></div>
                    <div class="content-div">
                      <p class="title is-5 has-text-weight-bold">Transfer Subject</p>
                      <p class="title is-3 has-text-weight-bold">{{getPatientTransferStatus()}}</p>
                      <p class="subtitle is-6">{{getPatientTransferStatusDate()}}</p>
                    </div>
                    <div>
                      <button type="button" (click)="transferPatient()" class="button is-primary  is-radiusless"
                        [attr.disabled]="invalidForTransfer() ? '' : null">
                        <span class="icon ">
                          <i class="fas fa-arrow-circle-right fa-sm" aria-hidden="true"></i>
                        </span>
                        <span>Transfer</span>
                      </button>
                    </div>
                  </div>
                  <!-- /.Transfer-patient-tile -->
                </div>
                <!-- /.Transfer tile-->

                <div class="tile is-one-fifth is-parent is-vertical ">
                  <div class=" tile is-child box is-radiusless is-half-tile" [mcCanNavigate]="'ACTIVITY_STUDY_LANDING'"
                    [studyId]="studyId">
                    <div class="color-bar red"></div>
                    <p class="title is-5 has-text-weight-bold">Study</p>
                    <div>
                      <button type="button" (click)="navigateToStudy()" class="button is-primary  is-radiusless">
                        <span class="icon "> <i class="far fa-clipboard fa-sm" aria-hidden="true"></i>
                        </span> <span>View Study</span>
                      </button>
                    </div>
                  </div>
                  <!-- /.Study half tile-->

                  <div [ngClass]="{'is-disabled':!isPatientStatusRegistered()}"
                    [mcCanNavigate]="'ACTIVITY_PATIENT_ADDITIONAL_OPTTIONS'"
                    class="tile is-child box is-radiusless is-half-tile">
                    <div class="color-bar red"></div>
                    <p class="title is-5 has-text-weight-bold">Additional Options</p>
                    <div>
                      <button type="button" [mcCanNavigate]="'ACTIVITY_PATIENT_ADDITIONAL_OPTTIONS'"
                        class="button is-primary is-radiusless" (click)="editAdditionalOptions()"
                        [disabled]="!isPatientStatusRegistered()">
                        <span class="icon">
                          <i class="far fa-check-circle fa-sm" aria-hidden="true"></i>
                        </span>
                        <span>Corrections</span>
                      </button>
                    </div>
                  </div>
                  <!-- /.Additional Options Corrections Half Tile -->
                </div>

                <!-- /.Study and Additional Options half tiles-->
              </div>
              <!-- /. row-1 tiles   -->



              <div class="reg-tiles">
                <div class="tile is-ancestor reg-tile">
                  <div class="tile is-one-fifth is-parent">
                    <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_PATIENT_REGISTRATION'"
                      [studyId]="studyId">
                      <div class="color-bar peacock-blue"></div>
                      <div class="content-div">
                        <p class="title is-5 has-text-weight-bold">Subject Status</p>
                        <p class="title is-3 has-text-weight-bold">{{getPatientStatus()}} </p>
                        <p class="subtitle is-6">{{getPatientStatusDate()}} </p>
                      </div>
                      <div>
                        <button type="button" (click)="reviewRegistration()" class="button is-primary is-radiusless">
                          <span class="icon">
                            <i class="far fa-clipboard fa-sm" aria-hidden="true"></i>
                          </span>
                          <span>Review Registration</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /.patient-status-tile -->


                  <div class="tile is-one-fifth is-parent">
                    <div class="tile is-child box is-radiusless "
                      [mcCanNavigate]="'ACTIVITY_DOUBLE_BLIND_BOTTLE_REQUEST_RESUPPLY'" [studyId]="studyId"
                      [ngClass]="{ 'is-disabled': isDoubleBlindConfigured()== false}">
                      <div class="color-bar purple"></div>
                      <div class="content-div">
                        <p class="title is-5 has-text-weight-bold">Double Blind</p>
                        <p class="title is-3 has-text-weight-bold">{{getDoubleBlindOrdersAvailable()}}</p>
                        <p class="subtitle is-6">remaining orders</p>
                      </div>
                      <div>
                        <button type="button" class="button is-primary is-radiusless"
                          [disabled]="!isDoubleBlindConfigured() || getDoubleBlindOrdersAvailable() == 0"
                          (click)="makeDoubleBlindRequest($event)">
                          <span class="icon">
                            <i class="fas fa-stethoscope fa-sm" aria-hidden="true"></i>
                          </span>
                          <span>Request Resupply</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- /.double-blind-tile -->



                  <div class="tile is-one-fifth is-parent is-vertical ">

                    <div class="tile is-child box is-radiusless is-half-tile"
                      [mcCanNavigate]="'ACTIVITY_PATIENT_INFO_UPDATE'" [studyId]="studyId">
                      <div class="color-bar orange"></div>
                      <!-- <div class="content-div"> -->
                      <p class="title is-5 has-text-weight-bold">Subject Info</p>
                      <!-- </div> -->
                      <div>
                        <button type="button" [mcCanNavigate]="'ACTIVITY_PATIENT_INFO_UPDATE'"
                          class="button is-primary is-radiusless" (click)="editDemographics()">
                          <span class="icon">
                            <i class="far fa-check-circle fa-sm" aria-hidden="true"></i>
                          </span>
                          <span>Corrections</span>
                        </button>
                      </div>
                    </div>
                    <!-- /.subject-info-half-tile -->

                    <div class="tile is-child box is-radiusless is-half-tile"
                      [mcCanNavigate]="'ACTIVITY_PATIENT_PARTICIPATION_INFO_UPDATE'" [studyId]="studyId">
                      <div class="color-bar orange"></div>
                      <!-- <div class="content-div"> -->
                      <p class="title is-5 has-text-weight-bold">Participation Info</p>
                      <!-- </div> -->
                      <div>
                        <button type="button" class="button is-primary is-radiusless "
                          [mcCanNavigate]="'ACTIVITY_PATIENT_PARTICIPATION_INFO_UPDATE'" (click)="editParticipation()">
                          <span class="icon">
                            <i class="far fa-check-circle fa-sm" aria-hidden="true"></i>
                          </span>
                          <span>Corrections</span>
                        </button>
                      </div>
                    </div>
                    <!-- /.participation-info-corrections-half-tile -->



                  </div>
                  <!-- /.subject-info and participation-info half-tiles -->

                  <div class="tile is-one-fifth is-parent is-vertical ">
                    <div class=" tile is-child box is-radiusless is-half-tile"
                      [ngClass]="{'is-disabled': isEnrollmentTileDisabled()}">
                      <div class="color-bar  green"></div>
                      <p class="title is-5 has-text-weight-bold">Enrollment</p>
                      <div>
                        <button type="button" (click)="navigateToEnrollmentCorrections($event)"
                          [disabled]="isCorrectEnrollmentDisabled()" class="button is-primary  is-radiusless">
                          <span class="icon ">
                            <i class="far fa-check-circle fa-sm" aria-hidden="true"></i>
                          </span> <span>Corrections</span>
                        </button>
                      </div>
                    </div>
                    <!-- /.enrollment-corrections  Half Tile -->

                    <div class="tile is-child box is-radiusless is-half-tile"
                      [ngClass]="{'is-disabled':isPostProcessingTileDisabled()}">
                      <div class="color-bar  green"></div>
                      <p class="title is-5 has-text-weight-bold">Post Processing</p>
                      <div>
                        <button type="button" (click)="managePostProcessing($event)"
                          [disabled]="isManagePostProcessingDisabled()" class="button is-primary  is-radiusless ">
                          <span class="icon ">
                            <i class="far fa-sticky-note"></i>
                            <span class="has-left-padding">Manage</span>
                          </span>
                        </button>
                      </div>
                    </div>
                    <!-- /.post-processing  Half Tile -->
                  </div>

                  <div class="tile is-one-fifth is-parent is-vertical">

                    <div *ngIf="shouldSeeUnblindedTile()" class=" tile is-child box is-radiusless is-half-tile">
                      <div class="color-bar  red"></div>
                      <p class="title is-5 has-text-weight-bold">Subject Unblinding</p>
                      <div>
                        <button type="button" (click)="getUnblindedInfo($event)"
                          class="button is-primary  is-radiusless">
                          <span class="icon ">
                            <i class="far fa-eye fa-sm" aria-hidden="true"></i>
                          </span>
                          <span>Unblinded Info</span>
                        </button>
                      </div>
                    </div>

                    <div *ngIf="!shouldSeeUnblindedTile()"
                      class=" tile is-child box is-radiusless is-half-tile place-holder">
                      <!-- /.a dummy tile to keep the tile columns in sync if window is resized -->
                    </div>
                    <!-- /.Unblinding/dummy Half Tile -->

                    <div class=" tile is-child box is-radiusless is-half-tile place-holder">
                      <!-- /.a dummy tile to keep the tile columns in sync if window is resized -->
                    </div>
                    <!-- /.dummy Half Tile -->

                  </div>
                </div>
                <!-- /.Unblinding  and dummy Half Tile -->

              </div>
            </div>
            <!-- /. row-2 tiles   -->

          </div>
        </div>
      </div>
    </div>

    <div class="preview-section" id="previewSection" [ngClass]="{ 'togglePerviewOverlay' : isExpandedHorizontal(),                
    'is-6 preview-vertical-expanded' : isExpandedVertical(),
     'is-1' : isCollapsedVertical(),
    'column ': previewModelHome.isVertical }">
      <mc-preview [formTitle]="formTitle" [formCaption]="previewTitle" [caseEventId]="caseEventId"
        [printDocType]="printDocType" [previewModel]="previewModelHome" #previewSection>
        <div preview-data>
          <div class="form-preview" *ngIf="selectedEnrollmentEvent">
            <mc-patient-reg-preview>
            </mc-patient-reg-preview>
          </div>
        </div>
      </mc-preview>
    </div>

  </div>
</div>

<mc-unblinded-modal [unblindedInfo]="unblindedInfo">
</mc-unblinded-modal>