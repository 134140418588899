import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';
import { ComponentFactoryResolver } from '@angular/core';
import { Injectable } from '@angular/core';
import { StudyTemplateSetupComponent } from 'app/admin/study-template/study-template-setup/study-template-setup.component';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import { Code } from 'app/common/model/code';
import { StudyAttribute } from 'app/common/model/study-attribute';
import { StudyPersonType } from 'app/common/model/study-person-type';
import { StudySiteLevel } from 'app/common/model/study-site-level';
import { StudyTemplate } from 'app/common/model/study-template';
import { Observable, of } from 'rxjs';
import { publishReplay, refCount, tap } from 'rxjs/operators';
import { BaseService } from '../base-service';

@Injectable()
export class StudyTemplateService extends BaseService {
  private _currentStudyTemplate: StudyTemplate; // instance of study template being edited / created

  private _studyTemplateSetup: StudyTemplateSetupComponent;

  // lists of types to populate dropdowns on create/edit study template configuration page
  private researchEntityList: Code[] = [];
  private studyStatusesList: Code[] = [];
  private tempResearchEntityObservable: Observable<any>;
  private tempStudyStatusesObservable: Observable<any>;
  private studyTypeList: Code[] = [];
  private tempStudyTypeObservable: Observable<any>;
  private studyTemplateStatusList: Code[] = [];
  private tempStudyTemplateStatusObservable: Observable<any>;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super(componentFactoryResolver);
  }

  /**
   * returns the instance of the study template being edited / created
   */
  public get currentStudyTemplate(): StudyTemplate {
    return this._currentStudyTemplate;
  }

  /**
   * sets the instance of the current study template being edited / created
   */
  public set currentStudyTemplate(studyTemplate: StudyTemplate) {
    this._currentStudyTemplate = studyTemplate;
  }

  /**
   * returns the instance of the studyTemplateSetup component
   */
  public get studyTemplateSetup(): StudyTemplateSetupComponent {
    return this._studyTemplateSetup;
  }

  /**
   * sets the instance of the studyTemplate setup component
   */
  public set studyTemplateSetup(
    studyTemplateSetup: StudyTemplateSetupComponent,
  ) {
    this._studyTemplateSetup = studyTemplateSetup;
  }

  /**
   * Get study templates
   * @returns
   */
  getStudyTemplates(): Observable<Array<StudyTemplate>> {
    const url = 'study-templates';
    return this.http.get<Array<StudyTemplate>>(
      `${this.config.apiEndpoint}` + url,
    );
  }

  // /**
  //  * Get study template status list
  //  * @returns
  //  */
  // getStudyTemplateStatusList(): Observable<Array<Code>>{
  //     const url = 'configuration/study-template-status-list';
  //     return this.http.get<Array<Code>>
  //       (`${this.config.apiEndpoint}` + url);
  // }

  /**
   * @param {number} studyTemplate - studyTemplate to be added
   * @returns {Observable} the selected study template with IDs and modified info
   */
  public addStudyTemplate(
    studyTemplate: StudyTemplate,
  ): Observable<StudyTemplate> {
    const url = 'study-templates';
    return this.http.post<StudyTemplate>(
      `${this.config.apiEndpoint}` + url,
      studyTemplate,
    );
  }

  /**
   * @param {number} studyTemplate - studyTemplate to be updated
   * @returns {Observable} the selected study template from the db
   */
  public updateStudyTemplate(
    studyTemplate: StudyTemplate,
  ): Observable<StudyTemplate> {
    const url = 'study-templates/' + studyTemplate.studyTemplateId;
    return this.http.put<StudyTemplate>(
      `${this.config.apiEndpoint}` + url,
      studyTemplate,
    );
  }

  /**
   * Delete an existing studyTemplate
   * @param {number} studyTemplateId - studyTemplateId to be deleted
   * @param {number} tupleVersion tuple version of the data
   * @returns {Observable} the selected study template from the db
   */
  public deleteStudyTemplate(
    studyTemplateId,
    tupleVersion: number,
  ): Observable<any> {
    const url = 'study-templates/' + studyTemplateId;
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    return this.http.delete(`${this.config.apiEndpoint}` + url, {
      headers: header,
      responseType: 'text',
    });
  }

  /**
   * @param {number} studyTemplateId - studyTemplateId to be retrieved
   * @returns {Observable} the selected study template from the db
   */
  public getStudyTemplate(studyTemplateId: number): Observable<StudyTemplate> {
    const url = 'study-templates/' + studyTemplateId;
    return this.http.get<StudyTemplate>(`${this.config.apiEndpoint}` + url);
  }

  getResearchEntities(): Observable<Array<Code>> {
    if (this.researchEntityList.length > 0) {
      return of(this.researchEntityList);
    } else if (this.tempResearchEntityObservable) {
      return this.tempResearchEntityObservable;
    } else {
      this.tempResearchEntityObservable = this.http
        .get<Array<Code>>(
          `${this.config.apiEndpoint}` + 'configuration/research-entity-codes',
        )
        .pipe(
          tap(oList => {
            this.tempResearchEntityObservable = null;
            this.researchEntityList = oList;
            return of(this.researchEntityList);
          }),
        )
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempResearchEntityObservable;
    }
  }

  getStudyStatuses(): Observable<Array<Code>> {
    if (this.studyStatusesList.length > 0) {
      return of(this.studyStatusesList);
    } else if (this.tempStudyStatusesObservable) {
      return this.tempStudyStatusesObservable;
    } else {
      this.tempStudyStatusesObservable = this.http
        .get<Array<Code>>(
          `${this.config.apiEndpoint}` + 'configuration/study-statuses',
        )
        .pipe(
          tap(oList => {
            this.tempStudyStatusesObservable = null;
            this.studyStatusesList = oList;
            return of(this.studyStatusesList);
          }),
        )
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempStudyStatusesObservable;
    }
  }

  getStudyTypesForTemplates(): Observable<Array<Code>> {
    if (this.studyTypeList.length > 0) {
      return of(this.studyTypeList);
    } else if (this.tempStudyTypeObservable) {
      return this.tempStudyTypeObservable;
    } else {
      this.tempStudyTypeObservable = this.http
        .get<Array<Code>>(
          `${this.config.v2_apiEndpoint}` +
            'configuration/study-type-codes?templateUseFlag=true',
        )
        .pipe(
          tap(oList => {
            this.tempStudyTypeObservable = null;
            this.studyTypeList = oList;
            return of(this.studyTypeList);
          }),
        )
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempStudyTypeObservable;
    }
  }

  getStudyTemplateStatuses(): Observable<Array<Code>> {
    if (this.studyTemplateStatusList.length > 0) {
      return of(this.studyTemplateStatusList);
    } else if (this.tempStudyTemplateStatusObservable) {
      return this.tempStudyTemplateStatusObservable;
    } else {
      this.tempStudyTemplateStatusObservable = this.http
        .get<Array<Code>>(
          `${this.config.apiEndpoint}` +
            'configuration/study-template-status-list',
        )
        .pipe(
          tap(oList => {
            this.tempStudyTemplateStatusObservable = null;
            this.studyTemplateStatusList = oList;
            return of(this.studyTemplateStatusList);
          }),
        )
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempStudyTemplateStatusObservable;
    }
  }

  /**
   * @returns {Observable} returns study attributes for study template
   */
  public getAttributesForTemplate(): Observable<StudyAttribute[]> {
    const url = 'study-templates/attributes';
    return this.http.get<StudyAttribute[]>(`${this.config.apiEndpoint}` + url);
  }
}
