<div class="search-result" *ngIf="isRave() ">
  <div class="form-table-container">
    <table id="rave-history-table" class="table is-fullwidth is-striped" role="grid">
      <thead>
        <tr class="is-selected" role="row">
          <ng-container *ngFor="let column of columns">
            <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column"
              title="{{column.title}}" (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
              <span> {{column.title}}</span>
              <span *ngIf="config && column.sort" class="sort is-text-centered">
                <span *ngIf="column.sort === 'desc'">
                  <i class="fas fa-sort-down"></i>
                </span>
                <span *ngIf="column.sort === 'asc'">
                  <i class="fas fa-sort-up"></i>
                </span>
              </span>
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of postProcessingHistoryRecords">
          <td>
            <span>{{row.raveGroup}}</span>
          </td>

          <td>
            <span>{{row.attribute}}</span>
          </td>
          <td>
            <span>{{row.action | titlecase}}</span>
          </td>
          <td>
            <span>{{row.status | titlecase}}</span>
          </td>
          <td>
            <span>{{row.errorMessage}}</span>
          </td>
          <td>
            <span>{{row.raveFormName}}</span>
          </td>
          <td>
            <span>{{row.dateTime | date:'MM/dd/yyyy h:mm:ss a'}}</span>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>