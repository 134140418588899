<mc-header-bar [headerModel]="headerModel" (saveEvent)="saveChanges()" (cancelEvent)="cancelChanges()"></mc-header-bar>

<div class="content-container">
    <div class="columns">
        <div class="column no-bottom-padding">
            <h3>Study Template Dashboard</h3>
        </div>

        <div class="column no-bottom-padding edit-bar-section">
            <mc-edit-bar [selectionCount]="selectedIds.length" [actionSetting]="editBarActions"
                (selectedActionEvent)="selectedEditAction($event)">
            </mc-edit-bar>
        </div>
    </div> 

    
    <table class="table is-fullwidth  is-striped is-borderless" ngClass="{{config.className || ''}}" role="grid">
        <thead>
            <tr role="row">
                <ng-container *ngFor="let column of columns">
                    <th  *ngIf="column.display" title="{{column.title}}"
                        ngClass="{{column.className || ''}}" [column]="column" [mcTableSorting]="config"   (sortChanged)="onChangeTable($event)">
                        <span> {{column.title}}</span>
                        <span *ngIf="config && column.sort" class="sort  is-text-centered">
                            <span *ngIf="column.sort === 'desc'">
                                <i class="fas fa-sort-down"></i>
                              </span>
                              <span *ngIf="column.sort === 'asc'">
                                <i class="fas fa-sort-up"></i>
                              </span>
                          </span>
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="showFilterRow">
                <td *ngFor="let column of columns; let i=index" >
                  <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" class="form-control"
                    (tableChanged)="onChangeTable(config)" />
                </td>
            </tr>
            <tr *ngFor="let row of rows; let rowIdx = index" (click)="cellClick(row, 'select')"  [ngClass]="{'is-selected':isRowSelected(row)}">
                <td class="display-message is-display-message-multiline is-display-message-bottom table-column-width" [ngClass]="{'selected':isInfoSelected(row)}"
                [attr.data-display-message]="getInfoMessage(row)" >
                    <span>{{row.templateTitle}} </span>
                </td>
                <td>
                    <span> {{row.researchEntity.name}} </span>
                </td>
                <td >
                    <span>{{row.studyType.name}} </span>
                </td>
                <td >
                    <span>{{row.templateStatus.name}} </span>
                </td>
            </tr>
        </tbody>
    </table>

</div>