import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ACTIVITY_NOTIFICATION } from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import { EventArg } from 'app/common/model/event-arg';
import { NotificationCode } from 'app/common/model/notification-code';
import { SchemaEventAttributeOption } from 'app/common/model/schema-event-attribute-option';
import { StudyNotification } from 'app/common/model/study-notification';
import { StudyNotificationArm } from 'app/common/model/study-notification-arm';
import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { UserService } from 'app/common/services/user.service';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { NotificationAccrualDetail } from '../../../common/model/notification-accrual-detail';
import { StudyNotificationEvent } from '../../../common/model/study-notification-event';
import { StudyNotificationGroupingFactor } from '../../../common/model/study-notification-grouping-factor';
import { StudySchema } from '../../../common/model/study-schema';
import { StudySchemaEvent } from '../../../common/model/study-schema-event';
import { SchemaDashboardService } from '../../../common/services/schema/schema-dashboard.service';
import { StudySetupService } from '../../../common/services/study-setup.service';
import { StudyNotificationValidValue } from 'app/common/model/study-notification-valid-value';
import { StudyNotificationSlotGroup } from 'app/common/model/study-notification-slot-group';
import { ConfigurationBaseComponent } from '../configuration-base/configuration-base.component';




@Component({
  selector: 'mc-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent  extends ConfigurationBaseComponent implements OnInit, OnDestroy {


    /**
   * The studyd for this notification
   */
    studyId = 0;

    // The list of notification types for the dropdown
    public notificationTypesList: NotificationCode[] = [];
  
    // The list of notification levels for the dropdown
    public notificationLevelsList: NotificationCode[] = [];
  
    // The list of notification levels for the dropdown
    public accrualPointsList: NotificationCode[] = [];
  
    // The list of notification levels for the dropdown
    public eventTypesList: NotificationCode[] = [];
  
    // The list of notification accrual types for the dropdown
    public accrualTypesList: NotificationCode[] = [];
  
    // The list of approval types for the dropdown
    public approvalTypesList: NotificationCode[] = [];
  
    public accrualTypeSelection = '';
  
    /**
     * Array to hold the values of selected events
     */
    selectedEventList: Array<boolean> = new Array<boolean>();
  
    /**
     * Different options for the Intervention Types
     */
    nTypeAccrual = 'Accrual';
    nTypeApproval = 'Approval';
    nTypeConfirmation = 'Confirmation';
    nTypeIntervention = 'Intervention';
    nTypeStatus = 'Status';
    nTypeAccrualLimit = "Accrual Limit";
    nTypeBlinded= "Blinded Strat Factor"
    nTypeInterventionAssignment= "Interventional Assignment"
  
    /**
     * Selected notification Type
     */
    selectedNotificationType = '';
  
    /**
     * List of source grouping factors for the study
     */
    groupingFactors: StudyNotificationGroupingFactor[] = [];
  
    /**
     * List of source valid values for the study
     */
    formsValidValueList: StudyNotificationValidValue[] = [];
  
    /**
     * List of source slot groups for the study
     */
    slotGroups: StudyNotificationSlotGroup[] = [];
    slotGroupType: NotificationCode;
  
    /**
    * The list of grouping factors selected for this notification
    */
    selectedGroupingFactors: Array<boolean> = new Array<boolean>();
  
    /**
     * The list of valid values selected for this notification
     */
    selectedValidValues: Array<boolean> = new Array<boolean>();
  
    /**
     * The list of slot groups selected for this notification
     */
    selectedSlotGroups: Array<boolean> = new Array<boolean>();
  
    /**
     * Holds the grouping factors originally on the notification
     */
    originalGfs: StudyNotificationGroupingFactor[] = [];
  
    /**
     * Holds the valid values originally on the notification
     */
    originalVVs: StudyNotificationGroupingFactor[] = [];
  
    /**
     * Holds the slot groups originally on the notification
     */
    originalSgs: StudyNotificationSlotGroup[] = [];
  
    /**
     * The id for grouping factor selection
     */
    gfId = -1;
  
    /**
     * The id for valid value selection
     */
    vvId = -1;
  
    /**
     * The id for slot group selection
     */
    sgId = -1;
  
    studySchema: StudySchema = null;
  
    interventionEvents: Array<StudySchemaEvent> = null;
  
    regEvents: Array<StudySchemaEvent> = null;
  
    approvalEvents: Array<StudySchemaEvent> = null;
    blindedEvents: Array<StudySchemaEvent> = null;
    interventionAssignmentEvents : Array<StudySchemaEvent> = null;
  
    /**
     * Variable used to display Events on the UI
     */
    displayEvents: Array<StudySchemaEvent> = [];
  
  
    //Determines  whether user can do editable or not
    isEditable: boolean = false;

    private subscriptions: Subscription[] = [];

  constructor(
    protected viewContainer: ViewContainerRef,
    protected notificationService: NotificationDashboardService,
    protected schemaService: SchemaDashboardService,
    protected studySetupService: StudySetupService,
    protected route: ActivatedRoute,
    protected userService: UserService) {
    super(notificationService, schemaService, studySetupService, userService);
    this.subscriptions.push(studySetupService.changeEmitted$.subscribe(
      eventInfo => {
        this.parseEventInfo(eventInfo);
      }));
  }


  /**
   * This will be called when the notification has been retrieved.
   * @param  eventArg
   */
  parseEventInfo(eventArg: EventArg) {
    if (eventArg.eventName === globalConst.EVENT_DATA_UPDATED || eventArg.eventName === globalConst.EVENT_DATA_RESET) {
      this.initializeNotificationData();
    }
  }


  ngOnInit() {
    // Get the studyId
    this.subscriptions.push(this.route.parent.parent.parent.params.subscribe(params => {
      if (params?.studyId) {
        this.studyId = params?.studyId;
        this.subscriptions.push(this.notificationService.getGroupingFactors(this.studyId)
          .subscribe(results => {
            this.groupingFactors = results;
            this.initializeGroupFactor();
          }),
          this.notificationService.getSlotGroups(this.studyId)
            .subscribe(results => {
              this.slotGroups = results;
              this.initializeSlotGroups();
            }),
          this.notificationService.getFormsValidValue(this.studyId)
            .subscribe(res => {
              this.formsValidValueList = res;
              this.initializeValidValues();
            })
        );
      }
    }));

    if (this.userService.hasModifyAccessByStudy(ACTIVITY_NOTIFICATION, this.studyId)) {
      this.isEditable = true;
    } else {
      this.isEditable = false;
    }



    this.subscriptions.push(this.notificationService.getAccrualPoints()
      .subscribe(results => {
        this.accrualPointsList = results as NotificationCode[];
      })
    );
    this.subscriptions.push(this.notificationService.getAccrualEventTypes()
      .subscribe(results => {
        this.eventTypesList = results as NotificationCode[];
      })
    );
    if (this.currentNotification()) {
      this.initializeNotificationData();
    }

  }

  /**
   * Method to initialize the notification data dependant on the notification object
  */
  initializeNotificationData() {
    // Initialize accrual type if existing notification
    const n = this.currentNotification();
    if (n.notificationId > 0) {
      if (n.studyNotificationGroupingFactorList != null && n.studyNotificationGroupingFactorList.length > 0) {
        this.accrualTypeSelection = 'Grouping Factor';
        if (n.studyNotificationGroupingFactorList && n.studyNotificationGroupingFactorList.length > 0) {
          n.studyNotificationGroupingFactorList.forEach(eachgf => {
            this.originalGfs.push(_.clone(eachgf));
          });
        }
      }
      else if (n.studyNotificationValidValueList != null && n.studyNotificationValidValueList.length > 0) {
        this.accrualTypeSelection = 'Variable';
        if (n.studyNotificationValidValueList && n.studyNotificationValidValueList.length > 0) {
          n.studyNotificationValidValueList.forEach(eachvv => {
            this.originalVVs.push(_.clone(eachvv));
          });
        }
      } else if (n.studyNotificationSlotGroupList != null && n.studyNotificationSlotGroupList.length > 0) {
        this.accrualTypeSelection = 'Slot Reservation';
        if (n.studyNotificationSlotGroupList && n.studyNotificationSlotGroupList.length > 0) {
          n.studyNotificationSlotGroupList.forEach(eachsg => {
            this.originalSgs.push(_.clone(eachsg));
          });
        }
      }
      else if (n.studyNotificationArmList != null && n.studyNotificationArmList.length > 0) {
        this.accrualTypeSelection = 'Intervention';
      } else if(n.notificationType?.value === "Accrual"){ // default to 'Schema Events'  if notification type is 'Accrual'
        this.accrualTypeSelection = 'Schema Events';
      }
    }
    this.subscriptions.push(this.notificationService.getNotificationTypes('Study')
      .subscribe(results => {
        this.notificationTypesList = results as NotificationCode[];
        if (this.currentNotification().notificationType) {
          this.selectedNotificationType = this.currentNotification().notificationType.value;
        }

        this.subscriptions.push(this.notificationService.getAccrualTypes()
          .subscribe(result => {
            this.accrualTypesList = result as NotificationCode[];
            if (this.currentNotification().notificationAccrualDetail &&
              this.currentNotification().notificationAccrualDetail.accrualType) {
              this.accrualTypeSelected();
            }
            this.getValidatedSchemaEvents();
            this.initializeGroupFactor();
            this.initializeValidValues();
            this.initializeSlotGroups();

          })
        );

        this.subscriptions.push(this.notificationService.getApprovalTypes()
          .subscribe(result => {
            this.approvalTypesList = result as NotificationCode[];
            if (this.currentNotification().notificationType) {
              this.selectedNotificationType =
                this.currentNotification().notificationType.value;
            }
          })
        );

      })
    );
  }


  /**
   * Initializes the grouping factor data for the ui
   */
  initializeGroupFactor() {
    if (this.currentNotification() && this.groupingFactors.length > 0) {
      const list: StudyNotificationGroupingFactor[] = [];
      const notifyGfList = this.currentNotification().studyNotificationGroupingFactorList;
      if (notifyGfList.length > 0) {
        this.groupingFactors.forEach((eachItem, idx) => {
          const match = this.findGroupingFactorMatchFromList(notifyGfList, eachItem);
          if (match) {
            this.selectedGroupingFactors[idx] = true;
          } else {
            this.selectedGroupingFactors[idx] = false;
          }
        });
      }
    }
  }

  /**
   * Initializes the valid value data for the ui
   */
  initializeValidValues() {
    if (this.currentNotification() && this.formsValidValueList.length > 0) {
      const list: StudyNotificationValidValue[] = [];
      const notifyVVList = this.currentNotification().studyNotificationValidValueList;
      if (notifyVVList.length > 0) {
        this.formsValidValueList.forEach((eachItem, idx) => {
          const match = this.findValidValueMatchFromList(notifyVVList, eachItem);
          if (match) {
            this.selectedValidValues[idx] = true;
          } else {
            this.selectedValidValues[idx] = false;
          }
        });
      }
    }
  }

  /**
   * Initializes the slot group data for the ui
   */
  initializeSlotGroups() {
    if (this.currentNotification() && this.slotGroups.length > 0) {
      const list: StudyNotificationSlotGroup[] = [];
      const notifySgList = this.currentNotification().studyNotificationSlotGroupList;
      if (notifySgList.length > 0) {
        this.slotGroups.forEach((eachItem, idx) => {
          const match = this.findSlotGroupMatchFromList(notifySgList, eachItem);
          if (match) {
            this.selectedSlotGroups[idx] = true;
          } else {
            this.selectedSlotGroups[idx] = false;
          }
        });
      }
    }
  }



  /**
  * Destroy implementation - closes all the subscriptions
  */
  ngOnDestroy() {
    this.subscriptions.forEach( x => {
        x.unsubscribe();
      }
    );
  }


  /**
   * Compare objects, used in html for matching coded lists
   */
  compareFn(a, b) {
    return a && b && a.id === b.id;
  }


  /**
   * True if a valid selection for notification type is made
  */
  isValidNotificationSelected(): boolean {
    return this.currentNotification() && this.currentNotification().notificationType != null;
  }

  /**
   * True if the selected notification type is Accrual
   */
  isTypeAccrual(): boolean {
    return (this.selectedNotificationType === this.nTypeAccrual || this.isTypeAccrualLimit());
  }

  /**
   * True if the selected notification type is Approval
   */
  isTypeApproval(): boolean {
    return this.selectedNotificationType === this.nTypeApproval;
  }

  /**
   * True if the selected notification type is Approval
   */
  isTypeConfirmation(): boolean {
    return this.selectedNotificationType === this.nTypeConfirmation;
  }

  /**
   * True if the selected notification type is Accrual Limit
   */
  isTypeAccrualLimit(): boolean {
    return this.selectedNotificationType === this.nTypeAccrualLimit;
  }


  /**
   * True if the selected notification type is Intervention
   */
  isTypeIntervention(): boolean {
    return this.selectedNotificationType === this.nTypeIntervention;
  }

  /**
   * True if the selected notification type is Status
   */
  isTypeStatus(): boolean {
    return this.selectedNotificationType === this.nTypeStatus;
  }

  /**
   * True if the selected notification type is Blinded
   */
  isTypeBlinded (): boolean {
    return this.selectedNotificationType === this.nTypeBlinded;
  }

    /**
   * True if the selected notification type is Intervention Assignment
   */
  isTypeInterventionAssignment():boolean{
    return this.selectedNotificationType === this.nTypeInterventionAssignment;
  }

  

  /**
   * True if Schema events should be displayed
   */
  shouldShowEvents(): boolean {
    let shouldShow = false;
    if (this.studySchema) {
      if (this.isTypeApproval() || this.isTypeConfirmation() || this.isTypeBlinded() || this.isTypeInterventionAssignment()) {
        shouldShow = true;
      } else if (this.isTypeAccrual() && this.accrualTypeSelection &&
        (this.accrualTypeSelection === 'Schema Events' || this.accrualTypeSelection === 'Intervention' || this.accrualTypeSelection === 'Slot Reservation')) {
        shouldShow = true;
      }
    }
    // console.log("Returning Should Show Events >> ", shouldShow)
    return shouldShow;
  }

  /**
   * True if AccrualType is Intervention and ARM events should be displayed
   */
  isAccrualTypeIntervention(): boolean {
    let shouldShow = false;
    if (this.isTypeAccrual() && this.accrualTypeSelection &&
      (this.accrualTypeSelection === 'Intervention')) {
      shouldShow = true;
    }
    return shouldShow;
  }


  /**
   * True if GroupingFactors should be displayed
   */
  shouldShowGroupingFactors(): boolean {
    let shouldShow = false;
    if (this.currentNotification() && this.isTypeAccrual() &&
      (this.currentNotification().notificationAccrualDetail.accrualType &&
        this.currentNotification().notificationAccrualDetail.accrualType.value === 'Grouping Factor')) {
      // this.accrualTypeSelection &&
      //   (this.currentNotification().notificationAccrualDetail.accrualType this.accrualTypeSelection == 'Grouping Factor')) {
      shouldShow = true;
    }
    return shouldShow;
  }

  shouldShowValidValues(): boolean {
    let shouldShow = false;
    if (this.currentNotification() && this.isTypeAccrual() &&
      (this.currentNotification().notificationAccrualDetail.accrualType &&
        this.currentNotification().notificationAccrualDetail.accrualType.value === 'Variable')) {
      shouldShow = true;
    }
    return shouldShow;
  }

  /**
   * True if SlotGroups should be displayed
   */
  shouldShowSlotGroups(): boolean {
    let shouldShow = false;
    if (this.currentNotification() && this.isTypeAccrual() &&
      (this.currentNotification().notificationAccrualDetail.accrualType &&
        this.currentNotification().notificationAccrualDetail.accrualType.value === 'Slot Reservation')) {
      shouldShow = true;
    }
    return shouldShow;
  }

  /**
   * Returns the display string for the valid values on the ui
   * @param vv Grouping factor
   */
  getValidValueDisplay(vv: StudyNotificationValidValue): string {
    let displayName = '';
    displayName = vv.studySchemaEventLabel ? vv.studySchemaEventLabel : vv.eventName;
    displayName += ' - ' + vv.alternativeVariableName + ' (' + vv.variableId + ') - ' + vv.validValueDesc;
    return displayName;
  }


  /**
   * Returns the display string for the grouping factor on the ui
   * @param gf Grouping factor
   */
  getGroupingFactorDisplay(gf: StudyNotificationGroupingFactor): string {
    let displayName = '';
    // StudySchemaEventLabel - VariableName(VariableId) - ValidValueDesc
    // if label is null, use EventName(studySchemaEventId)
    displayName = gf.studySchemaEventLabel ? gf.studySchemaEventLabel : gf.eventName;
    displayName += ' - ' + gf.alternativeVariableName + ' (' + gf.variableId + ') - ' + gf.validValueDesc;
    return displayName;
  }

  /**
   * Handler when any grouping factor selection is selected or deselected
   */
  gfSelectionClicked() {
    setTimeout(() => {
      this.currentNotification().studyNotificationGroupingFactorList = [];
      for (let n = 0; n < this.groupingFactors.length; n++) {
        if (this.selectedGroupingFactors[n] && this.selectedGroupingFactors[n] === true) {
          const gf = this.groupingFactors[n];
          let gfToAdd = true;
          // check if this was originally a selection
          if (this.originalGfs.length > 0) {
            const matchgf = this.findGroupingFactorMatchFromList(this.originalGfs, gf);
            if (matchgf) {
              this.currentNotification().studyNotificationGroupingFactorList.push(_.clone(matchgf));
              gfToAdd = false;
            }
          }
          if (gfToAdd) {
            const newGroupingFactor = _.clone(gf);
            newGroupingFactor.studyNotificationGroupingFactorId = this.gfId--;
            this.currentNotification().studyNotificationGroupingFactorList.push(newGroupingFactor);
          }
        }
      }
    });
  }

  /**
   * Handler when any valid value selection is selected or deselected
   */
  vvSelectionClicked() {
    setTimeout(() => {
      this.currentNotification().studyNotificationValidValueList = [];
      for (let n = 0; n < this.formsValidValueList.length; n++) {
        if (this.selectedValidValues[n] && this.selectedValidValues[n] === true) {
          const vv = this.formsValidValueList[n];
          let vvToAdd = true;
          // check if this was originally a selection
          if (this.originalVVs.length > 0) {
            const matchvv = this.findValidValueMatchFromList(this.originalVVs, vv);
            if (matchvv) {
              this.currentNotification().studyNotificationValidValueList.push(_.clone(matchvv));
              vvToAdd = false;
            }
          }
          if (vvToAdd) {
            const newValidValue = _.clone(vv);
            newValidValue.studyNotificationValidValueId = this.vvId--;
            this.currentNotification().studyNotificationValidValueList.push(newValidValue);
          }
        }
      }
    });
  }

  /**
   * Handler when any slot group selection is selected or deselected
   */
  sGSelectionClicked() {
    setTimeout(() => {
      this.currentNotification().studyNotificationSlotGroupList = [];
      for (let n = 0; n < this.slotGroups.length; n++) {
        if (this.selectedSlotGroups[n] && this.selectedSlotGroups[n] === true) {
          const sg = this.slotGroups[n];
          let sgToAdd = true;
          // check if this was originally a selection
          if (this.originalSgs.length > 0) {
            const matchsg = this.findSlotGroupMatchFromList(this.originalSgs, sg);
            if (matchsg) {
              this.currentNotification().studyNotificationSlotGroupList.push(_.clone(matchsg));
              sgToAdd = false;
            }
          }
          if (sgToAdd) {
            const newSlotGroup = _.clone(sg);
            newSlotGroup.studyNotificationSlotGroupId = this.sgId--;
            this.currentNotification().studyNotificationSlotGroupList.push(newSlotGroup);
          }
        }
      }
    });
  }

  /**
   * Helper method to locate a grouping factor match from a list
   * @param list The list to search
   * @param sourceGf The grouping factor whose match needs to be found
   */
  findGroupingFactorMatchFromList(list: StudyNotificationGroupingFactor[], sourceGf: StudyNotificationGroupingFactor) {
    const matchgf = list.find(groupingFactor =>
      groupingFactor.studySchemaEventId === sourceGf.studySchemaEventId &&
      groupingFactor.variableId === sourceGf.variableId &&
      groupingFactor.validValue === sourceGf.validValue);
    return matchgf;
  }


  /**
   * Helper method to locate a valid value match from a list
   * @param list The list to search
   * @param sourceGf The valid value whose match needs to be found
   */
  findValidValueMatchFromList(list: StudyNotificationValidValue[], sourceVv: StudyNotificationValidValue) {
    const matchvv = list.find(validValue =>
      validValue.studySchemaEventId === sourceVv.studySchemaEventId &&
      validValue.variableId === sourceVv.variableId &&
      validValue.validValue === sourceVv.validValue);
    return matchvv;
  }


  /**
   * Helper method to locate a slot group match from a list
   * @param list The list to search
   * @param sourceSg The slot group whose match needs to be found
   */
  findSlotGroupMatchFromList(list: StudyNotificationSlotGroup[], sourceSg: StudyNotificationSlotGroup) {
    const matchsg = list.find(slotGroup =>
      slotGroup.keyEventMappingId === sourceSg.keyEventMappingId &&
      slotGroup.reservationGroupStatusCode === sourceSg.reservationGroupStatusCode &&
      slotGroup.groupDescription === sourceSg.groupDescription);
    return matchsg;
  }

  /**
 * True if the accrualType are intervention or events or group factors
 */
  shouldDisplayAccrualDetails() {
    return this.shouldShowEvents() || this.shouldShowGroupingFactors();
  }

  /**
   * Retrieves the Validated schema events for the study
  */
  getValidatedSchemaEvents() {

    // if (this.studySchema == null) { --> This conditon is commented to reload the original studySchema  when clicks on cancel
    this.subscriptions.push(this.schemaService.getValidatedSchemaEvents(this.studyId)
      .subscribe(result => {
        this.studySchema = result;
        if (this.shouldShowEvents()) {
          this.setDisplayEvents();
          if (this.displayEvents.length > 0) {
            let eventList: StudyNotificationEvent[] = [];
            const armList = this.currentNotification().studyNotificationArmList;
            if (armList.length === 0) {
              eventList = this.currentNotification().studyNotificationEventList;
              this.displayEvents.forEach((eachEvt, idx) => {
                const match = eventList.find(each => eachEvt.keyEventMappingId === each.studySchemaEvent.keyEventMappingId);
                if (match) {
                  this.selectedEventList[idx] = true;
                } else {
                  this.selectedEventList[idx] = false;
                }
              });
            } else {
              this.displayEvents.forEach((eachEvt, idx) => {
                const match = armList.find(eachArm => eachEvt.keyEventMappingId === eachArm.studySchemaEvent.keyEventMappingId);
                if (match) {
                  this.selectedEventList[idx] = true;
                } else {
                  this.selectedEventList[idx] = false;
                }
              });
            }
          }
        }
      }));
    // }
  }

  /**
   * Returns an array of events for the eventTypes
   * @param eventTypeIdList The array of eventTypeIds to find matches
   */
  getSchemaEventsByType(eventTypeIdList: Array<number>): Array<StudySchemaEvent> {
    let schemaEventList: Array<StudySchemaEvent> = [];
    if (this.studySchema && this.studySchema.studySchemaEvents.length > 0) {
      schemaEventList = _.filter(this.studySchema.studySchemaEvents,  o  => eventTypeIdList.indexOf(o.schemaEventId) > -1 );
    }
    return schemaEventList;
  }

  /**
   * Returns an array of events with schemaEventEnrollmentFlag set to true
   */
  getSchemaEventsByEnrollmentFlag(): Array<StudySchemaEvent> {
    let schemaEventList: Array<StudySchemaEvent> = [];
    if (this.studySchema && this.studySchema.studySchemaEvents.length > 0) {
      schemaEventList = _.filter(this.studySchema.studySchemaEvents, o => o.schemaEventEnrollmentEventFlag === true);
    }
    return schemaEventList;
  }


  /**
   * returns the array of Intervention Type StudyschemaEvents
   */
  getInterventionEvents(): Array<StudySchemaEvent> {
    if (this.interventionEvents == null) {
      // Intervention is EventTypeId = 5
      const eventTypes = [5];
      this.interventionEvents = this.getSchemaEventsByType(eventTypes);
    }
    return this.interventionEvents;
  }

  /**
     * returns the array of StudyschemaEvents with schemaEventEnrollmentEventFlag==true, plus any SCREENING type events
     */
  getRegTypeEvents(): Array<StudySchemaEvent> {
    if (this.regEvents == null) {
      // EventTypeId Reg = 1, PreReg = 2, Crossover = 7, Continuation = 8, Screening = 6
      const eventTypes = [6];
      const list1 = this.getSchemaEventsByType(eventTypes);
      const list2 = this.getSchemaEventsByEnrollmentFlag();
      this.regEvents = list1.concat(list2);
    }
    return this.regEvents;
  }


  /**'
   * Returns list of approval events
   */
  getApprovalEvents() {
    if (this.approvalEvents == null) {
      // Approval schemaEventId = 11  
      const eventTypes = [11];
      this.approvalEvents = this.getSchemaEventsByType(eventTypes);
    }
    return this.approvalEvents;
  }

  /**
   * 
   * @returns Get dynamoc allocation events using blinded
   */
  getDynamicAllocationEventsUsingBlinded() {
    if (this.blindedEvents == null) {
      // DynamicAllocation schemaEventId = 3
      const eventTypes = [3];
      const daEvents = this.getSchemaEventsByType(eventTypes);
      this.blindedEvents = this.getUsingBlinded(daEvents);
    }
    return this.blindedEvents;
  }

  /**
   * Get grouping factor events using intervention assignments
   * @returns 
   */
  getGFeventsUsingInterventIonAssignment() {
    if (this.interventionAssignmentEvents == null) {
      // grouping factor schemaEventId = 9 
      const eventTypes = [9];
      const gfEvents = this.getSchemaEventsByType(eventTypes);
      this.interventionAssignmentEvents = this.getUsingInterventionAssignment(gfEvents);
    }
    return this.interventionAssignmentEvents;
  }

  /**
   * Get only dynamic allocatons with StratFactorBlinded
   * @param daEvents 
   * @returns 
   */
  getUsingBlinded(daEvents: StudySchemaEvent[]) {
    return daEvents.filter(x => {
      const studySchemaEventAttribute = x.studySchemaEventAttributeList?.find(y => y.attributeCode === "StratFactorBlinded");
      return studySchemaEventAttribute?.attributeOptions?.find(option => option.optionCode === "Yes" && option.selected) ?? false;
    });
  }

  /**
   * Get only grouping factor events with GfAssignmentForm
   * @param gfEvents 
   * @returns 
   */
  getUsingInterventionAssignment (gfEvents: StudySchemaEvent[]) {
    return gfEvents.filter(x => {
      const studySchemaEventAttribute = x.studySchemaEventAttributeList?.find(y => y.attributeCode === "GfAssignmentForm");
      return studySchemaEventAttribute?.attributeOptions?.find(option => option.optionCode === "Yes" && option.selected) ?? false;
    });
  }

  /**
   * Returns the display or default label name
   * @param evt The event object
   */
  getEventDisplayName(evt: StudySchemaEvent) {
    if (evt.studySchemaEventLabel) {
      return evt.studySchemaEventLabel;
    }
    return evt.schemaEventDisplayName;
  }

  /**
   * Returns the selected option for the attribute
   * @param evt The event being evaluated
   * @param attributeName The attribute to locate
   */
  getSelectedAttributeOption(evt: StudySchemaEvent, attributeName: string): SchemaEventAttributeOption {
    const selection = evt.studySchemaEventAttributeList
      .find(eachAttribute => eachAttribute.attributeCode.toLowerCase() === attributeName.toLowerCase());
    const singleSelected = selection.attributeOptions.find(eachOption => eachOption.selected === true);
    return singleSelected;
  }


  /**
   * Returns the events where the attribute has been set
   * @param attributeName The name of the attribute to evaluate in the event's attribute List
   */
  getEventsForAttribute(attributeName: string): Array<StudySchemaEvent> {
    let schemaEventList: Array<StudySchemaEvent> = [];
    if (this.studySchema && this.studySchema.studySchemaEvents.length > 0) {
      schemaEventList = _.filter(this.studySchema.studySchemaEvents, (eachEvent) => {
        if (eachEvent.studySchemaEventAttributeList.length > 0) {
          const selection = eachEvent.studySchemaEventAttributeList
            .find(eachAttribute => eachAttribute.attributeCode.toLowerCase() === attributeName.toLowerCase());
          if (selection != null && selection.attributeOptions != null && selection.attributeOptions.length > 0) {
            const singleSelected = selection.attributeOptions.find(eachOption => eachOption.selected === true);
            return singleSelected != null;
          }
        }
        return false;
      });
    }
    return schemaEventList;
  }


  /**
   * Handler for the notification type select box selection changed event
  */
  notificationTypeChanged() {
    this.currentNotification().notificationAccrualDetail = null;
    this.selectedNotificationType = '';
    this.accrualTypeSelection = '';

    this.selectedEventList = [];
    this.displayEvents = [];
    this.selectedGroupingFactors = [];
    this.selectedValidValues = [];
    this.selectedSlotGroups = [];
    this.currentNotification().studyNotificationEventList = [];
    this.currentNotification().studyNotificationArmList = [];
    this.currentNotification().studyNotificationGroupingFactorList = [];
    this.currentNotification().studyNotificationValidValueList = [];
    this.currentNotification().studyNotificationSlotGroupList = [];
    this.currentNotification().notificationApprovalTypeList = [];

    if (this.currentNotification().notificationType != null) {
      this.currentNotification().notificationAccrualDetail = new NotificationAccrualDetail
        (null, null, null, null,
          new NotificationCode(1, 'Person', null), null, null);

      this.selectedNotificationType = this.currentNotification().notificationType.value;
      if (this.isTypeApproval()) {
        setTimeout(() => {
          this.displayEvents = this.getApprovalEvents();
        });
      } else if (this.isTypeConfirmation()) {
        this.displayEvents = this.getRegTypeEvents();
      } else if (this.isTypeBlinded()) {
        this.displayEvents = this.getDynamicAllocationEventsUsingBlinded();
      }else if(this.isTypeInterventionAssignment()){
          this.displayEvents = this.getGFeventsUsingInterventIonAssignment();
      }
      // Accrual Limit should not include Slot Reservation accrual type - remove it if it's there
      if (this.isTypeAccrualLimit()) {
        const slotGroupIdx = this.accrualTypesList.findIndex(eachType => eachType.value === "Slot Reservation");
        if (slotGroupIdx > -1) {
          this.slotGroupType = this.accrualTypesList.find(sr => sr.value === "Slot Reservation");
          this.accrualTypesList.splice(slotGroupIdx, 1);
        }
      }
      else {
        const slotGroupIdx = this.accrualTypesList.findIndex(eachType => eachType.value === "Slot Reservation");
        if (slotGroupIdx < 0) {
          this.accrualTypesList.push(this.slotGroupType);
          this.accrualTypesList.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
        }
      }
    }
  }

  /** Handler for the accrual Type selection changed
   *
  */
  accrualTypeSelected() {
    this.accrualTypeSelection = '';
    // setTimeout(() => {
    if (this.currentNotification().notificationAccrualDetail.accrualType) {
      this.accrualTypeSelection = this.currentNotification().notificationAccrualDetail.accrualType.value;
    }
    if (this.studySchema) {
      this.selectedEventList = [];
      this.setDisplayEvents();
    }
    // setTimeout(() => {
    if (this.currentNotification() && !this.shouldShowGroupingFactors()) {
      this.selectedGroupingFactors = [];
      this.currentNotification().studyNotificationGroupingFactorList = [];
    }
    if (this.currentNotification() && !this.shouldShowValidValues()) {
      this.selectedValidValues = [];
      this.currentNotification().studyNotificationValidValueList = [];
    }
    if (this.currentNotification() && !this.shouldShowSlotGroups()) {
      this.selectedSlotGroups = [];
      this.currentNotification().studyNotificationSlotGroupList = [];
    }
    //  },1000);
  }

  /**
   * Sets the displayEvents variable for all the correct events
  */
  setDisplayEvents() {
    if (this.accrualTypeSelection === 'Schema Events' || this.accrualTypeSelection === 'Slot Reservation' || this.isTypeConfirmation()) {
      // setTimeout(() => {
      this.displayEvents = this.getRegTypeEvents();
      // });
    } else if (this.accrualTypeSelection === 'Intervention') {
      // setTimeout(() => {
      this.displayEvents = this.getInterventionEvents();
      // });
    } else if (this.isTypeApproval()) {
      this.displayEvents = this.getApprovalEvents();
    }  else if (this.isTypeBlinded()) {
        this.displayEvents = this.getDynamicAllocationEventsUsingBlinded();
    }else if(this.isTypeInterventionAssignment()){
        this.displayEvents = this.getGFeventsUsingInterventIonAssignment();
    }else if (this.accrualTypeSelection === 'Variable') {
      //this.displayEvents = this.formsValidValueList;
    } 
  }

  /**
   *
   * The handler when the check box is clicked
   */
  eventSelectionClicked() {
    setTimeout(() => {
      this.currentNotification().studyNotificationEventList = [];
      this.currentNotification().studyNotificationArmList = [];
      for (let n = 0; n < this.displayEvents.length; n++) {
        if (this.selectedEventList[n] && this.selectedEventList[n] === true) {
          const evt = this.displayEvents[n];
          if (this.isTypeAccrual() || this.isTypeConfirmation() || this.isTypeApproval()  || this.isTypeBlinded()  || this.isTypeInterventionAssignment() ) {
            if (this.isAccrualTypeIntervention()) {
              this.currentNotification().studyNotificationArmList.push(
                new StudyNotificationArm(-1, new NotificationCode(evt.studySchemaEventId, evt.schemaEventDisplayName, evt.schemaEventName, null,evt.keyEventMappingId)));
            } else {
              this.currentNotification().studyNotificationEventList.push(
                new StudyNotificationEvent(-1, new NotificationCode(evt.studySchemaEventId, evt.schemaEventDisplayName, evt.schemaEventName,null ,evt.keyEventMappingId))
              );
            }
          }
        }
      }
    });
  }



  /********* Pending Changes warning************** */
  /**
   * Implemenation of the canDeactivate that will be called when we navigate away
   *
   */
  canDeactivate(): Observable<boolean> | boolean {
    return this.notificationService.notificationSetup.canDeactivate();
  }
  /*********  Pending Changes warning************** */



  /**
   * Returns True if the current notification is of type "notify"
  */
  isNotificationTypeNotify(): boolean {
    let isNotify = false;
    if (this.currentNotification()) {
      isNotify = this.notificationService.isNotificationTypeNotify(this.currentNotification());
    }
    return isNotify;
  }




}
