import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsService } from 'app/common/services/reports.service';
import { SharedModule } from './../common/shared.module';
import { ReportDashboardComponent } from './report-dashboard.component';
import { ReportModalComponent } from './report-modal/report-modal.component';
import { ReportTilesComponent } from './report-tiles/report-tiles.component';
import { StudyAccrualReportViewComponent } from './report-tiles/study-accrual-report-view/study-accrual-report-view.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    ReportDashboardComponent,
    ReportTilesComponent,
    StudyAccrualReportViewComponent,
    ReportModalComponent
  ],
  exports: [
  ],

  providers: [ReportsService],
  // entryComponents: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class ReportsModule { }

