<section class="section" [ngClass]="{'is-hidden': notificationFound}">
    <div class="columns">
        <div class="column">
            <h2 *ngIf="!isNotificationSearch" class="has-text-weight-bold">
                No recent notifications.
            </h2>
            <h2 *ngIf="isNotificationSearch" class="has-text-weight-bold">
                No notifications.
            </h2>
        </div>
    </div>
</section>

<div class="search-result" [ngClass]="{'is-hidden': !notificationFound}">

    <div class=" form-table-container">
        <table class="table is-fullwidth is-striped" ngClass="{{config.className || ''}}" role="grid">
            <thead>
                <tr role="row" class="is-selected">
                    <ng-container *ngFor="let column of columns">
                        <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}" (sortChanged)="onChangeTable($event)"
                            ngClass="{{column.className || ''}}">
                            <span> {{column.title}}</span>
                            <span *ngIf="config && column.sort" class="sort  is-text-centered">
                                <span *ngIf="column.sort === 'desc'">
                                    <i class="fas fa-sort-down"></i>
                                </span>
                                <span *ngIf="column.sort === 'asc'">
                                    <i class="fas fa-sort-up"></i>
                                </span>
                            </span>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of recentNotification">
                    <td>
                        <span> {{row.studyReferenceNum}}  </span>
                    </td>
                    <td>
                        <span> {{row.patientId}}</span>
                    </td>
                    <td>
                        <span> {{row.notificationName}}</span>
                    </td>
                    <td>
                        <span> {{row.notificationType}}</span>
                    </td>
                    <td>
                        <a (click)="emailDetails(row)">
                            <span class="is-size-6 has-text-weight-bold ">
                                View Email
                            </span>
                        </a>                    
                    </td>
                    <td>
                        <span> {{row.emailSentDate | date: 'MM/dd/yyyy'}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
     
</div>