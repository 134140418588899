import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ComponentFactoryResolver,
  Inject,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import { Code } from 'app/common/model/code';
import { IRBApprovalRecordModel } from 'app/common/model/irb-approval-record';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { OrgNetwork } from 'app/common/model/org-network';
import { Organization } from 'app/common/model/organization';
import { ProtocolSpecificRequirements } from 'app/common/model/protocol-specific-requirements';
import { StudyAttribute } from 'app/common/model/study-attribute';
import { StudyDosage } from 'app/common/model/study-dosage';
import { StudyRecord } from 'app/common/model/study-record';
import { StudyReference } from 'app/common/model/study-reference';
import { StudyParticipant } from 'app/common/model/study-setup-participant';
import { Observable, of } from 'rxjs';
import { publishReplay, refCount, tap } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from '../../app-config/app-config.module';
import { AncillaryStudy } from '../model/ancillary-study';
import { FormCopyModel } from '../model/form-copy-model';
import { Institution } from '../model/institution';
import { OrganizationResponsibilityRecordModel } from '../model/organization-responsibility-record';
import { OrganizationResponsibilityRo } from '../model/organization-responsibility-ro';
import { PersonResponsibilityRecordModel } from '../model/person-responsibility-record';
import { PersonResponsibilityRo } from '../model/person-responsibility-ro';
import { SgcVersion } from '../model/sgc-version';
import { SgcVersionDetail } from '../model/sgc-version-detail';
import { StudyContractAccru } from '../model/study-contract-accru';
import { StudyFormForLookup } from '../model/study-form-for-lookup';
import { StudyFormVariable } from '../model/study-form-variable';
import { StudyFundingSource } from '../model/study-funding-source';
import { StudyNetwork } from '../model/study-network';
import { StudyPersonType } from '../model/study-person-type';
import { StudyRegulatoryCertification } from '../model/study-regulatory-certification';
import { StudySchemaEvent } from '../model/study-schema-event';
import { StudySelectionCriteria } from '../model/study-selection-criteria';
import { StudySiteLevel } from '../model/study-site-level';
import { StudyStatusModel } from '../model/study-status-model';
import { TacTadVersion } from '../model/tac-tad-version';
import { TacTadVersionDetail } from '../model/tac-tad-version-detail';
import { VariableValidValue } from '../model/variable-valid-value';
import { StudyServiceBase } from './study-service-base.service';
import { StudyTeam } from '../model/study-team';
import { StudyTeamRole } from '../model/study-team-role';
import { SearchCriteriaPerson } from '../model/search-criteria-person';
import { Person } from '../model/person';
import { AncillaryQualificationCriteriaType } from '../model/ancillary-qualification-criteria-type';
import { StudySlotGroup } from '../model/study-slot-group';
import { StudyAuthToken } from '../model/study-auth-token';
import { RedcapStudy } from '../model/redcap-study';

@Injectable()
export class StudySetupService extends StudyServiceBase {
  http: HttpClient;
  config: AppConfig;

  /**
   *  Gets url-pattern from config file.
   */
  serviceUrlPrefix: string;

  /**
   * variable for studyNumber for sharing across components
   */
  private _studyRecord: StudyRecord;

  /**
   * variable for ancillaryStudy for sharing across components
   */
  private _ancillaryStudy: AncillaryStudy;

  /*********** Organization Retrieval and Caching ******************************************************** */

  private organizationList: Organization[] = [];
  private tempObservable: Observable<any>;

  constructor(
    http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super(http, config, componentFactoryResolver);

    this.config = config;
    this.http = http;
    this.serviceUrlPrefix = config.apiEndpoint;
  }

  // constructor(private http: HttpClient,
  //     @Inject(APP_CONFIG) private config: AppConfig,
  //      componentFactoryResolver: ComponentFactoryResolver) {
  //         super(componentFactoryResolver);
  //     }

  showPendingChangesDialog(
    viewContainerRef: ViewContainerRef,
    modalDialogArg: ModalDialogArg = null,
  ) {
    return this.showModalDialog(viewContainerRef, modalDialogArg);
  }

  /**
   * returns the StudyNumber
   */
  public get studyRecord(): StudyRecord {
    return this._studyRecord;
  }

  /**
   * sets the studyNumber
   */
  public set studyRecord(studyRecord: StudyRecord) {
    this._studyRecord = studyRecord;
  }

  /**
   * True if the current study is an Ancillary study
   */
  public isAncillaryStudy(): boolean {
    let isAncStudy = false;
    if (this._ancillaryStudy) {
      isAncStudy = this._ancillaryStudy?.study?.studyType?.toLowerCase() === 'ancillary';
    }
    return isAncStudy;
  }

  /**
   * returns the Ancillary Study Record
   */
  public get ancillaryStudy(): AncillaryStudy {
    return this._ancillaryStudy;
  }

  /**
   * sets the Ancillary Study
   */
  public set ancillaryStudy(ancStudy: AncillaryStudy) {
    this._ancillaryStudy = ancStudy;
  }

  // /**
  //  * get the study information
  //  * @param studyId
  //  */
  // public getStudy(studyId: number) :Observable<StudyRecord> {
  //     return this.http.get<StudyRecord>(this.serviceUrlPrefix + 'studies/' + studyId);
  // }

  public getPersonResponsibilities(studyId: number) {
    return this.http.get<Array<PersonResponsibilityRecordModel>>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/responsibilities/person',
    );
  }

  public getPersonResponsibilityCodes() {
    return this.http.get<Array<Code>>(
      this.serviceUrlPrefix + 'configuration/staff-responsibility-codes',
    );
  }

  public getOrganizationResponsibilities(studyId: number) {
    return this.http.get<Array<OrganizationResponsibilityRecordModel>>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/responsibilities/organization',
    );
  }

  public getOrganizationResponsibilityCodes(studyId: number) {
    return this.http.get<Array<Code>>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/responsibilities/organization/codes',
    );
  }

  public getStudyAttributes(studyId: number) {
    return this.http.get<StudyAttribute[]>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/attributes',
    );
  }

  public updateStudyAttribute(
    studyId: number,
    record: StudyAttribute,
  ): Observable<StudyAttribute> {
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/attributes'; // 'studies/' + studyId + '/irb-approvals'
    return this.http.put<StudyAttribute>(myUrl, record);
  }

  public getStudyIRBApprovals(
    studyId: number,
  ): Observable<IRBApprovalRecordModel[]> {
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/irb-approvals';
    return this.http.get<IRBApprovalRecordModel[]>(myUrl);
  }

  public getStudyIRBCommittees(
    studyId: number,
    orgPk: number,
  ): Observable<OrgNetwork[]> {
    const myUrl =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/irb-approvals/committees/' +
      orgPk;
    return this.http.get<OrgNetwork[]>(myUrl);
  }

  public updateStudyIRBApproval(
    studyId: number,
    irbApprovalId: number,
    record: IRBApprovalRecordModel,
  ): Observable<IRBApprovalRecordModel> {
    const myUrl =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/irb-approvals/' +
      irbApprovalId;
    return this.http.put<IRBApprovalRecordModel>(myUrl, record);
  }

  public addStudyIRBApproval(
    studyId: number,
    record: IRBApprovalRecordModel,
  ): Observable<IRBApprovalRecordModel> {
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/irb-approvals';
    return this.http.post<IRBApprovalRecordModel>(myUrl, record);
  }

  /**
   * For delete you can't pass a payload so we pass the tupleVersion via the header
   *
   * @param studyId - unique identified of the study
   * @param irbApprovalId - unique identifier of the IRB Approval for this study
   * @param tupleVersion - used to detect stale data
   */
  public deleteStudyIRBApproval(
    studyId: number,
    irbApprovalId: number,
    tupleVersion: number,
  ): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const myUrl =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/irb-approvals/' +
      irbApprovalId;
    return this.http.delete(myUrl, { headers: header, responseType: 'text' });
  }

  /**
   * Retrieves the list of organizations from the services and stores it in a local variable
   */
  getOrganizations(): Observable<Array<Organization>> {
    if (this.organizationList.length > 0) {
      return of(this.organizationList);
    } else if (this.tempObservable) {
      return this.tempObservable;
    } else {
      this.tempObservable = this.http
        .get<Array<Organization>>(this.serviceUrlPrefix + 'organizations')
        .pipe(
          tap(oList => {
            this.tempObservable = null;
            this.organizationList = oList;
            return of(this.organizationList);
          }),
        )
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempObservable;
    }
  }

  /*********** Organization Retrieval and Caching ******************************************************** */

  /**************************** */

  public insertOrganizationResponsibility(
    studyId: number,
    record: OrganizationResponsibilityRecordModel,
  ): Observable<OrganizationResponsibilityRecordModel> {
    //  console.log(JSON.stringify(record));
    return this.http.post<OrganizationResponsibilityRecordModel>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/responsibilities/organization',
      record,
    );
  }

  public updateOrganizationResponsibility(
    studyId: number,
    record: OrganizationResponsibilityRecordModel,
  ): Observable<OrganizationResponsibilityRecordModel> {
    //   console.log(JSON.stringify(record));
    return this.http.put<OrganizationResponsibilityRecordModel>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/responsibilities/organization/' +
      record.organizationResponsibilityId,
      record,
    );
  }

  /**
   *
   * @param recordId - unique database ID of the record to be deleted
   * @param tupleVersion - version of the record to be deleted (used to detect stale data)
   */
  public deleteOrganizationResponsibility(
    studyId: number,
    recordId: number,
    tupleVersion: number,
  ): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const myUrl =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/responsibilities/organization/' +
      recordId;
    return this.http.delete(myUrl, { headers: header, responseType: 'text' });
  }

  public insertPersonResponsibility(
    studyId: number,
    record: PersonResponsibilityRecordModel,
  ): Observable<PersonResponsibilityRecordModel> {
    //   console.log(JSON.stringify(record));
    return this.http.post<PersonResponsibilityRecordModel>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/responsibilities/person',
      record,
    );
  }

  public updatePersonResponsibility(
    studyId: number,
    record: PersonResponsibilityRecordModel,
  ): Observable<PersonResponsibilityRecordModel> {
    //    console.log(JSON.stringify(record));
    return this.http.put<PersonResponsibilityRecordModel>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/responsibilities/person/' +
      record.personResponsibilityId,
      record,
    );
  }

  /**
   *
   * @param studyId -  ID of study for which this record is being deleted
   * @param recordId - unique database ID of the record to be deleted
   * @param tupleVersion - version of the record to be deleted (used to detect stale data)
   */
  public deletePersonResponsibility(
    studyId,
    recordId,
    tupleVersion,
  ): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const myUrl =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/responsibilities/person/' +
      recordId;
    return this.http.delete(myUrl, { headers: header, responseType: 'text' });
  }

  /***************** study participation *************************************/
  public getStudyParticipants(studyId): Observable<StudyNetwork[]> {
    // console.log("serviceUrlPrefix   " + this.serviceUrlPrefix);
    return this.http.get<StudyNetwork[]>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/setup-participations/',
    );
  }

  public insertStudyParticipant(
    studyId,
    newStudyParticipant: StudyParticipant,
  ): Observable<StudyParticipant> {
    const header = new HttpHeaders().set('content-type', 'application/json');
    const url =
      this.serviceUrlPrefix + 'studies/' + studyId + '/setup-participations/';
    return this.http.post<StudyParticipant>(url, newStudyParticipant, {
      headers: header,
    });
  }

  // note: for delete you can't pass a payload so we pass the tupleVersion via the header
  /*   public deleteStudyParticipant(studyId, paricipantId, tupleVersion):Observable<any>  {
          let header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match' , tupleVersion.toString());
          let url = this.serviceUrlPrefix + 'studies/' + studyId + "/setup-participations/" + paricipantId ;
          return this.http.delete (url, { headers: header, responseType: 'text' });
      }
  
      public updateStudyParticipant(studyId, studyParticipant: StudyParticipant): Observable<StudyParticipant> {
          let header = new HttpHeaders().set('content-type', 'application/json');
          let url = this.serviceUrlPrefix + 'studies/' + studyId + "/setup-participations/" + studyParticipant.participantId;
          return this.http.put<StudyParticipant>(url, studyParticipant, { headers: header });
      }
  
      public insertLimitedParticipant(studyId, newLimitedParticipant: StudyLimitedParticipant): Observable<StudyLimitedParticipant> {
          let header = new HttpHeaders().set('content-type', 'application/json');
          let url = this.serviceUrlPrefix + 'studies/' + studyId + "/limit-participations/";
          return this.http.post<StudyLimitedParticipant>(url, newLimitedParticipant, { headers: header });
      }
      public deleteLimitedParticipant(studyId, limitedPartId, tupleVersion):Observable<any>  {
          let header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match' , tupleVersion.toString());
          let url = this.serviceUrlPrefix + 'studies/' + studyId + "/limit-participations/" + limitedPartId ;
          return this.http.delete(url, { headers: header,  responseType: 'text' });
      }
      public updateLimitedParticipant(studyId, limitedParticipant: StudyLimitedParticipant): Observable<StudyLimitedParticipant> {
          let header = new HttpHeaders().set('content-type', 'application/json');
          let url = this.serviceUrlPrefix + 'studies/' + studyId + "/limit-participations/" + limitedParticipant.limitedPartId ;
          return this.http.put<StudyLimitedParticipant>(url, limitedParticipant, { headers: header });
      } */

  /******** Study Reference ********************** */

  public getStudyReferenceTypeCodes(studyId: number) {
    return this.http.get<Array<Code>>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/study-references/codes',
    );
  }

  public getStudyReferences(studyId: number): Observable<StudyReference[]> {
    // let refs: StudyReference[] = [{
    //     "studyRefId": 26997,
    //     "studyRefNum": "2018-101399-S ",
    //     "studyRefTypeCode": {
    //         "id": 5,
    //         "name": "MSR Sponsor Number",
    //         "code": ""
    //     },
    //     "description": "",
    //     "org": null,
    //     "activeDate": new Date(1543259313983),
    //     "lastModifiedDate": new Date(1543259313950),
    //     "lastModifiedUserName": "m0000000",
    //     "tupleVersion": 1
    // },
    // {
    //     "studyRefId": 25279,
    //     "studyRefNum": "BD-03",
    //     "studyRefTypeCode": {
    //         "id": 1,
    //         "name": "Sponsor Protocol ID",
    //         "code": ""
    //     },
    //     "description": "",
    //     "org": {
    //         "ctepId": "",
    //         "orgNumber": "86",
    //         "abrev": "ACOSOG",
    //         "name": "American College of Sergeons Oncology Group IDIG incorporation",
    //         "type": "",
    //         "status": null,
    //         "relationshipPk": null,
    //         "siteLevelId": null
    //     },

    //     "activeDate": new Date(1541542568837),
    //     "lastModifiedDate": new Date(548097215873),
    //     "lastModifiedUserName": "m143366",
    //     "tupleVersion": 2
    // },
    // {
    //     "studyRefId": 25280,
    //     "studyRefNum": "BD-3",
    //     "studyRefTypeCode": {
    //         "id": 2,
    //         "name": "Acronym",
    //         "code": ""
    //     },
    //     "description": "",
    //     "org": {
    //         "ctepId": null,
    //         "orgNumber": "86",
    //         "abrev": "ACOSOG",
    //         "name": "American College of Sergeons Oncology Group IDIG incorporation",
    //         "type": "site_type",
    //         "status": null,
    //         "relationshipPk": null,
    //         "siteLevelId": null
    //     },
    //     "activeDate": new Date(1541542568858),
    //     "lastModifiedDate": new Date(1541542568373),
    //     "lastModifiedUserName": "m0000000",
    //     "tupleVersion": 1
    // }];
    // return of(refs);
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/study-references';
    return this.http.get<StudyReference[]>(myUrl);
  }

  public updateStudyReference(
    studyId: number,
    record: StudyReference,
  ): Observable<StudyReference> {
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/study-references';
    return this.http.put<StudyReference>(myUrl, record);
  }

  public insertStudyReference(
    studyId: number,
    record: StudyReference,
  ): Observable<StudyReference> {
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/study-references';
    return this.http.post<StudyReference>(myUrl, record);
  }

  /**
   *
   * @param studyId - ID of the study being acted upon
   * @param record - Study Reference record being acted upon
   */
  public deleteStudyReference(
    studyId: number,
    record: StudyReference,
  ): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', record.tupleVersion.toString());
    const myUrl =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/study-references/' +
      record.studyRefId;
    return this.http.delete(myUrl, { headers: header, responseType: 'text' });
  }

  /******** Study PSR ********************** */

  public getProtocolSpecificRequirements(
    studyId: number,
  ): Observable<ProtocolSpecificRequirements> {
    return this.http.get<ProtocolSpecificRequirements>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/psrs',
    );
  }

  public updateProtocolSpecificRequirements(
    studyId: number,
    psrs: ProtocolSpecificRequirements,
  ): Observable<ProtocolSpecificRequirements> {
    return this.http.put<ProtocolSpecificRequirements>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/psrs',
      ProtocolSpecificRequirements,
    );
  }

  /******** Study PSR ********************** */

  /******** Site and Person setup ********************** */

  /**
   * Returns the site level codes
   */
  public getSiteLevelCodes(): Observable<Code[]> {
    return this.http.get<Code[]>(
      this.serviceUrlPrefix + 'configuration/site-level-codes',
    );
  }

  /**
   * Returns the list of studySiteLevels for the study
   * @param studyId The studyId whose studySiteLevels are to be retrieved
   */
  public getSiteLevels(studyId: number): Observable<StudySiteLevel[]> {
    /// studies/{studyId}/site-level

    const url = 'studies/' + studyId + '/site-level';
    return this.http.get<StudySiteLevel[]>(this.serviceUrlPrefix + url);
  }

  /**
   * Insert a new record for the StudySiteLevel
   * @param studyId The study Id of this siteLevel
   * @param records The studySiteLevel object to insert
   */
  public addSiteLevel(
    studyId: number,
    records: StudySiteLevel[],
  ): Observable<StudySiteLevel[]> {
    // record.studySiteLevelId =  Math.floor(Math.random() * 1500) + 1;
    // return of(record);
    const url = this.serviceUrlPrefix + 'studies/' + studyId + '/site-level';
    return this.http.post<StudySiteLevel[]>(url, records);
  }

  /**
   * Updates the site Level object
   * @param studyId The studyId for this siteLevel
   * @param records The siteLevel to update
   */
  public updateSiteLevel(
    studyId: number,
    records: StudySiteLevel[],
  ): Observable<StudySiteLevel[]> {
    // record.tupleVersion =  Math.floor(Math.random() * 1500) + 1;
    // return of(record);
    const url = this.serviceUrlPrefix + 'studies/' + studyId + '/site-level/';
    return this.http.put<StudySiteLevel[]>(url, records);
  }

  /******** Site and Person setup ********************** */
  /********** Study Person Type  **************** */

  public getPersonTypeCodes(): Observable<Code[]> {
    return this.http.get<Code[]>(
      this.serviceUrlPrefix + 'configuration/person-type-codes',
    );
  }

  /**
   * @param  number studyId - studyId
   * @returns Observable - Return list of StudyPersonType objects.
   */
  public getStudyPersonTypes(studyId: number): Observable<StudyPersonType[]> {
    const pt: StudyPersonType[] = [
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: null,
        label: {
          id: 1,
          code: 'CONPER',
          name: 'Consenting Person',
        },
        required: null,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: 2,
        label: {
          id: 2,
          code: 'CREINV',
          name: 'Credit Investigator',
        },
        required: true,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: 3,
        label: {
          id: 3,
          code: 'TREINV',
          name: 'Treating Investigator',
        },
        required: false,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: 4,
        label: {
          id: 4,
          code: 'SITREG',
          name: 'Site Registrar',
        },
        required: true,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: 5,
        label: {
          id: 5,
          code: 'STUCON',
          name: 'Study Contact',
        },
        required: false,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: null,
        label: {
          id: 6,
          code: 'RADIOL',
          name: 'Radiologist',
        },
        required: null,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: 7,
        label: {
          id: 7,
          code: 'PRIINV',
          name: 'Principle Investigator',
        },
        required: true,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: null,
        label: {
          id: 8,
          code: 'PRICRA',
          name: 'Primary CRA',
        },
        required: null,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: 9,
        label: {
          id: 9,
          code: 'PATHOL',
          name: 'Pathologist',
        },
        required: true,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
      {
        studyId: studyId,
        studyTemplateId: null,
        studyPersonTypeId: 10,
        label: {
          id: 10,
          code: 'DRSINV',
          name: 'Drug Shipment Investigator',
        },
        required: true,
        activeDate: null,
        inactiveDate: null,
        modifiedById: '',
        tupleVersion: 1,
      },
    ];
    //  return of(pt);

    return this.http.get<StudyPersonType[]>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/person-types',
    );
  }
  /**
   * @param  number studyId - studyId
   * @param  StudyPersonType personType - StudyPersonType which is to be saved in the database.
   * @returns Observable - Returns StudyPersonType Object which the latest tupleVersion.
   */
  public addStudyPersonType(
    studyId: number,
    personType: StudyPersonType,
  ): Observable<StudyPersonType> {
    // console.log("Add Person type called")
    // personType.studyPersonTypeId = Math.floor(Math.random() * 1500) + 1;
    // personType.tupleVersion = 1;
    // return of(personType);
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/person-types';
    return this.http.post<StudyPersonType>(myUrl, personType);
  }

  /**
   * Updates the studyPersonType object
   * @param studyId The studyId for this siteLevel
   * @param record The studyPersonType to update
   */
  public updateStudyPersonType(
    studyId: number,
    record: StudyPersonType,
  ): Observable<StudyPersonType> {
    // console.log("Update PersonType called=" );
    // record.tupleVersion =  Math.floor(Math.random() * 1500) + 1;
    // return of(record);
    const url = this.serviceUrlPrefix + 'studies/' + studyId + '/person-types';
    return this.http.put<StudyPersonType>(url, record);
  }

  /**
   * Delete the StudyPersonType from the db
   * @param studyId The studyId of this siteLevelId
   * @param studyPersonTypeId The studyPersonTypeId to be deleted
   * @param tupleVersion The tuple Version of the data
   */
  public deleteStudyPersonType(
    studyId,
    studyPersonTypeId,
    tupleVersion,
  ): Observable<any> {
    // console.log("TupleVersion for delete =", tupleVersion);
    // return of({ "success:": true });
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/person-types/' +
      studyPersonTypeId;
    console.log('Deleting person type ', header);
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /******** READ ONLY Organization and Person Responsibilities************************* */

  /**
   * Returns the Readonly version of the Person Responsibilities for the study
   * @param studyId The study id of the person responsibilities
   */
  public getReadonlyPersonResponsibilities(
    studyId: number,
  ): Observable<PersonResponsibilityRo[]> {
    // let personRes: PersonResponsibilityRo[] = [{ "pk": 131, "firstName": "Ritesh", "lastName": "Nimmagadda", "status": "ACTIVE", "roleDescription": "Record Creator", "email": "Nimmagadda.Ritesh@mayo.edu" }]
    // return of(personRes);
    return this.http.get<Array<PersonResponsibilityRo>>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/responsibilities/person',
    );
  }

  /**
   * Returns the Organization responsibilities for the study
   * @param studyId The study id of the org responsibilities
   */
  public getReadonlyOrganizationResponsibilities(
    studyId: number,
  ): Observable<OrganizationResponsibilityRo[]> {
    return this.http.get<Array<OrganizationResponsibilityRo>>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/responsibilities/organization',
    );
  }

  /**
   * Returns the network organization list that matches the filter
   * @param filterQuery The filter to be used to limit the response
   */
  getNetworkOrganizations(filterQuery: string): Observable<Array<OrgNetwork>> {
    return this.http.get<Array<OrgNetwork>>(
      this.serviceUrlPrefix + 'networks/' + filterQuery,
    );
  }

  /******** Tac Tad Setup functionality ************************* */

  /**
   * Returns the tac tad versions for a study
   * @param studyId The study id
   */
  public getTacTadVersions(studyId: number): Observable<TacTadVersion[]> {
    return this.http.get<Array<TacTadVersion>>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/tac-tad/versions',
    );
    // let result: TacTadVersion[] = [{"versionNum":1,"versionDesc":"Version 1","activeDate":1549324800000,"inactiveDate":1551312000000,"treatmentAssignmentCodeDeriveVersionStudyId":2},{"versionNum":2,"versionDesc":"Version 2","activeDate":1551312000000,"inactiveDate":null,"treatmentAssignmentCodeDeriveVersionStudyId":4}];
    // return of(result);
  }

  /**
   * @param  number studyId - studyId
   * @param  TacTadVersion tacTadVersion - TacTadVersion to be saved in the database.
   * @returns Observable - Returns TacTadVersion Object which the latest tupleVersion.
   */
  public addTacTadVersion(
    studyId: number,
    tacTadVersion: TacTadVersion,
  ): Observable<TacTadVersion> {
    // /studies/{studyId}/tac-tad/versions
    // Adds the TAC-TAD version for a study
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/tac-tad/versions';
    return this.http.post<TacTadVersion>(myUrl, tacTadVersion);
  }

  /**
   * Updates the TacTadVersion object
   * @param studyId The studyId for this code
   * @param record The TacTadVersion to update
   */
  public updateTacTadVersion(
    studyId: number,
    record: TacTadVersion,
  ): Observable<TacTadVersion> {
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/tac-tad/versions/' +
      record.treatmentAssignmentCodeDeriveVersionStudyId;
    return this.http.put<TacTadVersion>(url, record);
  }

  /**
   * Delete the TacTadVersion from the db
   * @param studyId The studyId of this code
   * @param versionId The TacTadVersion to be deleted
   * @param tupleVersion The tuple Version of the data
   */
  public deleteTacTadVersion(
    studyId,
    versionId,
    tupleVersion,
  ): Observable<any> {
    // console.log("TupleVersion for delete =", tupleVersion);
    // return of({ "success:": true });
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/tac-tad/versions/' +
      versionId;
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /**
   * Copies the TAC-TAD version for a study to a new version
   * @param studyId The studyIf of the tacTadVersion
   * @param tacTadSourceVersionId The Id of the source TacTadVersion
   * @param copyModel The model holding the new version information
   */
  public copyTacTadVersion(
    studyId: number,
    tacTadSourceVersionId: number,
    copyModel: FormCopyModel,
  ): Observable<TacTadVersion> {
    // studies/{studyId}/tac-tad/versions/treatmentAssignmentCodeDeriveVersionStudyId
    const result: TacTadVersion = {
      treatmentAssignmentCodeDeriveVersionStudyId: 140,
      versionNum: copyModel.versionNum,
      versionDesc:
        copyModel.formName + '(copied from version ' + tacTadSourceVersionId,
      activeDate: null,
      inactiveDate: null,
      activeDateModified: false,
      lastModifiedDate: 1552077860212,
      lastModifiedUser: 'M083328',
      tupleVersion: 1,
      // "activeDateModified": false
    };
    //    return of(result);

    const model: {
      newVersionNum: number;
      versionDesc: string;
    } = {
      newVersionNum: copyModel.versionNum,
      versionDesc: copyModel.formName,
    };

    const myUrl =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/tac-tad/versions/' +
      tacTadSourceVersionId;
    return this.http.post<TacTadVersion>(myUrl, model);
  }

  /**
   * Returns the details for the tac-tad version
   * @param studyId The studyId of the version
   * @param versionId The versionid for the details
   */
  public getTacTadVersionDetails(
    studyId: number,
    versionId: number,
  ): Observable<TacTadVersionDetail[]> {
    // api/v1/studies/{studyId}/tac-tad/version-details/{studyTacTadVersionId}
    return this.http.get<Array<TacTadVersionDetail>>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/tac-tad/version-details/' +
      versionId,
    );
  }

  /**
   * Returns the studySchemaEvents for the tac-tad version
   * @param studyId The studyId of the version
   */
  public getTacTadVersionSchemaEvents(
    studyId: number,
  ): Observable<StudySchemaEvent[]> {
    // api/v1/studies/{studyId}/tac-tad/version-details/study-schema-events
    return this.http.get<Array<StudySchemaEvent>>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/tac-tad/version-details/study-schema-events',
    );
  }

  // Tac-Tad variable lookup services
  /**
   * fetch the study form version based on the studyschemaeventId.
   *
   * @param  number studyId - studyId
   * @param  number studySchemaEventId
   * @returns Observable
   */
  public getStudyFormVersionBasedOnEvent(
    studyId: number,
    studySchemaEventId: number,
  ): Observable<StudyFormForLookup[]> {
    // http://localhost:8080/reg-backend-service/api/v1/studies/14929/study-forms/schema-events/55208
    return this.http.get<Array<StudyFormForLookup>>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/study-forms/schema-events/' +
      studySchemaEventId,
    );
  }
  /**
   * fetch the study form variables based on the study form version
   *
   * @param  number studyId - studyId associated to the form
   * @param  number studyFormId - studyFormId for which the variables are pulled
   * @returns Observable
   */
  public getStudyFormVariables(
    studyId: number,
    studyFormId: number,
  ): Observable<StudyFormVariable[]> {
    return this.http.get<Array<StudyFormVariable>>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/study-forms/' +
      studyFormId +
      '/variables',
    );
  }

  /**
   * @param  number studyId - studyId
   * @param  TacTadVersionDetail tacTadVersionDetail - TacTadVersionDetail to be saved in the database.
   * @returns Observable - Returns TacTadVersionDetail Object which the latest tupleVersion.
   */
  public addTacTadVersionDetail(
    studyId: number,
    tacTadVersionDetail: TacTadVersionDetail,
  ): Observable<TacTadVersionDetail> {
    // /studies/{studyId}/tac-tad/version-details
    // Adds the TAC-TAD detail to a version for a study
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/tac-tad/version-details';
    return this.http.post<TacTadVersionDetail>(myUrl, tacTadVersionDetail);
  }

  /**
   * Updates the TacTadVersionDetail object
   * @param studyId The studyId for this code
   * @param record The TacTadVersionDetail to update
   */
  public updateTacTadVersionDetail(
    studyId: number,
    record: TacTadVersionDetail,
  ): Observable<TacTadVersionDetail> {
    // console.log("Update PersonType called=" );
    // record.tupleVersion =  Math.floor(Math.random() * 1500) + 1;
    // return of(record);
    // /studies/{studyId}/tac-tad/version-details/{treatmentAssignmentCodeStudySchemaEventId}
    // Update the TAC-TAD detail to a version for a study
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/tac-tad/version-details/' +
      record.treatmentAssignmentCodeStudySchemaEventId;
    return this.http.put<TacTadVersionDetail>(url, record);
  }

  /**
   * Delete the TacTadVersionDetail from the db
   * @param studyId The studyId of this code
   * @param detailId The TacTadVersionDetail to be deleted
   * @param tupleVersion The tuple Version of the data
   */
  public deleteTacTadVersionDetail(
    studyId,
    detailId,
    tupleVersion,
  ): Observable<any> {
    // console.log("TupleVersion for delete =", tupleVersion);
    // return of({ "success:": true });
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/tac-tad/version-details/' +
      detailId;
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /*********** SGC *************************** */

  /**
   * Returns the SGCversions for a study
   * @param studyId The study id
   */
  public getSgcVersions(studyId: number): Observable<SgcVersion[]> {
    return this.http.get<Array<SgcVersion>>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/sgc/versions',
    );
    // let result: TacTadVersion[] = [{"versionNum":1,"versionDesc":"Version 1","activeDate":1549324800000,"inactiveDate":1551312000000,"treatmentAssignmentCodeDeriveVersionStudyId":2},{"versionNum":2,"versionDesc":"Version 2","activeDate":1551312000000,"inactiveDate":null,"treatmentAssignmentCodeDeriveVersionStudyId":4}];
    // return of(result);
  }

  /**
   * @param  number studyId - studyId
   * @param  SgcVersion record - SgcVersion to be saved in the database.
   * @returns Observable - Returns sgcVersion Object which the latest tupleVersion.
   */
  public addSgcVersion(
    studyId: number,
    record: SgcVersion,
  ): Observable<SgcVersion> {
    // /studies/{studyId}/sgc/versions
    // Adds the Sgc version for a study
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/sgc/versions';
    return this.http.post<SgcVersion>(myUrl, record);
  }

  /**
   * Updates the SgcVersion object
   * @param studyId The studyId for this code
   * @param record The SgcVersion to update
   */
  public updateSgcVersion(
    studyId: number,
    record: SgcVersion,
  ): Observable<SgcVersion> {
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/sgc/versions/' +
      record.sgcDeriveVersionStudyId;
    return this.http.put<SgcVersion>(url, record);
  }

  /**
   * Delete the SgcVersion from the db
   * @param studyId The studyId of this code
   * @param versionId The SgcVersion to be deleted
   * @param tupleVersion The tuple Version of the data
   */
  public deleteSgcVersion(
    studyId: number,
    versionId: number,
    tupleVersion: number,
  ): Observable<any> {
    // console.log("TupleVersion for delete =", tupleVersion);
    // return of({ "success:": true });
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/sgc/versions/' +
      versionId;
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /**
   * Copies the SGC version for a study to a new version
   * @param studyId The studyIf of the sgcVersion
   * @param sgcSourceVersionId The Id of the source SgcVersion
   * @param copyModel The model holding the new version information
   */
  public copySgcVersion(
    studyId: number,
    tacTadSourceVersionId: number,
    copyModel: FormCopyModel,
  ): Observable<SgcVersion> {
    // studies/{studyId}/tac-tad/versions/sgcDeriveVersionStudyId
    const result: SgcVersion = {
      sgcDeriveVersionStudyId: 140,
      versionNum: copyModel.versionNum,
      versionDesc:
        copyModel.formName + '(copied from version ' + tacTadSourceVersionId,
      activeDate: null,
      inactiveDate: null,
      activeDateModified: false,
      lastModifiedDate: 1552077860212,
      lastModifiedUser: 'M083328',
      tupleVersion: 1,
      // "activeDateModified": false
    };
    //    return of(result);

    const model: {
      newVersionNum: number;
      versionDesc: string;
    } = {
      newVersionNum: copyModel.versionNum,
      versionDesc: copyModel.formName,
    };

    const myUrl =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/sgc/versions/' +
      tacTadSourceVersionId;
    return this.http.post<SgcVersion>(myUrl, model);
  }

  /**
   * Returns the details for the sgc version
   * @param studyId The studyId of the version
   * @param versionId The versionid for the details
   */
  public getSgcVersionDetails(
    studyId: number,
    versionId: number,
  ): Observable<SgcVersionDetail[]> {
    // api/v1/studies/{studyId}/sgc/version-details/{studySgcVersionId}
    return this.http.get<Array<SgcVersionDetail>>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/sgc/version-details/' +
      versionId,
    );
  }

  /**
   * @param number studyId - studyId
   * @param  SgcVersionDetail sgcVersionDetail - SgcVersionDetail to be saved in the database.
   * @returns Observable - Returns SgcVersionDetail Object which the latest tupleVersion.
   */
  public addSgcVersionDetail(
    studyId: number,
    record: SgcVersionDetail,
  ): Observable<SgcVersionDetail> {
    // /studies/{studyId}/sgc/version-details
    // Adds the sgc detail to a version for a study
    const myUrl =
      this.serviceUrlPrefix + 'studies/' + studyId + '/sgc/version-details';
    return this.http.post<SgcVersionDetail>(myUrl, record);
  }

  /**
   * Updates the SgcVersionDetail object
   * @param studyId The studyId for this code
   * @param record The SgcVersionDetail to update
   */
  public updateSgcVersionDetail(
    studyId: number,
    record: SgcVersionDetail,
  ): Observable<SgcVersionDetail> {
    // record.tupleVersion =  Math.floor(Math.random() * 1500) + 1;
    // return of(record);
    // /studies/{studyId}/tac-tad/version-details/{sgcStudySchemaEventId}
    // Update the Sgc detail to a version for a study
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/sgc/version-details/' +
      record.sgcStudySchemaEventId;
    return this.http.put<SgcVersionDetail>(url, record);
  }

  /**
   * Delete the SgcVersionDetail from the db
   * @param studyId The studyId of this code
   * @param detailId The SgcVersionDetail to be deleted
   * @param tupleVersion The tuple Version of the data
   */
  public deleteSgcVersionDetail(
    studyId: number,
    detailId: number,
    tupleVersion: number,
  ): Observable<any> {
    // console.log("TupleVersion for delete =", tupleVersion);
    // return of({ "success:": true });
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/sgc/version-details/' +
      detailId;
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /************SGC ************************************ */

  /******** Tac Tad Setup functionality ************************* */

  /******** Study Status functionality - start ************************* */

  /**
   * Updates the study status
   * @param studyId The studyId for this siteLevel
   * @param StudyStatusModel The new StudyStatus to update to
   */
  public updateStudyStatus(
    studyId: number,
    record: StudyStatusModel,
  ): Observable<StudyStatusModel> {
    const url = this.serviceUrlPrefix + 'studies/' + studyId + '/status';
    return this.http.put<StudyStatusModel>(url, record);
  }

  /**
   ** Updates the study
   * @param studyId
   * @param record
   */
  public updateStudy(record: StudyRecord): Observable<StudyRecord> {
    const url = this.serviceUrlPrefix + 'studies';
    return this.http.put<StudyRecord>(url, record);
  }

  /******** Study Status functionality - end ************************* */

  /***************** Regulatory ACCRU Contracts - Start  *************************************/

  /**
   * Gets the ACCRU Contracts from the service
   * @param studyId The studyId
   */
  public getStudyAccruContracts(
    studyId: number,
  ): Observable<StudyContractAccru[]> {
    return this.http.get<StudyContractAccru[]>(
      this.serviceUrlPrefix + 'studies/' + studyId + '/regulatory/accru',
    );
  }

  // POST
  // /studies/{studyId}/regulatory/accru
  // Add study accru regulatory

  /**
   * Adds a new ACCRU Contract from the service
   * @param studyId The studyId
   * @param record the new contract to add
   */
  public addStudyAccruContract(
    studyId: number,
    record: StudyContractAccru,
  ): Observable<StudyContractAccru> {
    const url: string =
      this.serviceUrlPrefix + 'studies/' + studyId + '/regulatory/accru';
    return this.http.post<StudyContractAccru>(url, record);
  }
  // PUT
  // /studies/{studyId}/regulatory/accru
  // Update study accru regulatory
  /**
   * Updates a  ACCRU Contract from the service
   * @param studyId The studyId
   * @param record the new contract to add
   */
  public updateStudyAccruContract(
    studyId: number,
    record: StudyContractAccru,
  ): Observable<StudyContractAccru> {
    const url: string =
      this.serviceUrlPrefix + 'studies/' + studyId + '/regulatory/accru';
    return this.http.put<StudyContractAccru>(url, record);
  }

  // DELETE
  // /studies/{studyId}/regulatory/accru/{studyRegulatoryMemberShipId}
  // Deletes study accru regulatory
  /**
   * Delete the StudyAccrualContract from the db
   * @param studyId The studyId of this code
   * @param membershipId The TacTadVersionDetail to be deleted
   * @param tupleVersion The tuple Version of the data
   */
  public deleteStudyAccruContract(
    studyId: number,
    membershipId: number,
    tupleVersion: number,
  ): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());

    const url: string =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/regulatory/accru/' +
      membershipId;
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /**
   * Returns the list of membership Sites for the Accru top level org
   */
  getACCRUMemberOrgs(): Observable<OrgNetwork[]> {
    // http://localhost:8080/reg-backend-service/api/v1/studies/14929/regulatory/accru/memberships
    return this.http.get<Array<OrgNetwork>>(
      this.serviceUrlPrefix + 'studies/0/regulatory/accru/memberships',
    );
  }

  /**
   * Returns the contract Id for the selected Membership site
   * @param studyId The studyId
   * @param relationshipPk The relationshipPk of the membership site
   */

  getAccruMemberContractType(
    studyId: number,
    relationshipPk: number,
  ): Observable<StudyContractAccru> {
    // http://localhost:8080/reg-backend-service/api/v1/studies/14929/regulatory/accru/memberships/{relationshipPk}/contracts
    const url: string =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/regulatory/accru/memberships/' +
      relationshipPk +
      '/contracts';
    return this.http.get<StudyContractAccru>(url);
  }

  /**
   * Returns the list of Member statuses for display in the dropdown
   */
  getRegulatoryMemberStatus(): Observable<Code[]> {
    // configuration/regulatory-member-status
    return this.http.get<Array<Code>>(
      this.serviceUrlPrefix + 'configuration/regulatory-member-status',
    );
  }

  /**
   * Returns the list of Contract Types for the Contracts tab
   */
  getRegulatoryContractTypes(): Observable<Code[]> {
    return this.http.get<Array<Code>>(
      this.serviceUrlPrefix + 'configuration/regulatory-contract-types',
    );
  }

  /***************** Regulatory ACCRU Contracts - END  *************************************/

  /***************** Regulatory Certifications - Start  *************************************/

  /**
   * Gets the Regulatory Certifications from the service
   * @param studyId The studyId
   */
  public getStudyRegulatoryCertifications(
    studyId: number,
  ): Observable<StudyRegulatoryCertification[]> {
    return this.http.get<StudyRegulatoryCertification[]>(
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/regulatory/certifications',
    );
  }

  /**
   * Inserts a new Certification Row into the database
   * @param studyId The studyId
   * @param record The new row to insert
   */
  public insertStudyRegulatoryCertification(
    studyId: number,
    record: StudyRegulatoryCertification,
  ): Observable<StudyRegulatoryCertification> {
    const header = new HttpHeaders().set('content-type', 'application/json');
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/regulatory/certifications';
    return this.http.post<StudyRegulatoryCertification>(url, record, {
      headers: header,
    });
  }

  /**
   * Deletes the certification from the database
   * @param studyId The STudyID
   * @param recordId Primary Key
   * @param tupleVersion The tupleVersion of the row
   */
  public deleteStudyRegulatoryCertification(
    studyId: number,
    recordId: number,
    tupleVersion: number,
  ): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const url =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/regulatory/certifications/' +
      recordId;
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /***************** Regulatory Certifications - End  *************************************/
  /***************** Selection Criteria - Start  *************************************/

  /**
   * Gets the Selection criteria from the service
   * @param studyId The studyId
   */
  public getAncillarySelectionCriteria(
    studyId: number,
  ): Observable<StudySelectionCriteria> {
    return this.http.get<StudySelectionCriteria>(
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/selection-criteria',
    );
  }

  /**
   * Inserts a new Selection Criteria Row into the database
   * @param studyId The studyId
   * @param record The new row to insert
   */
  public insertAncillarySelectionCriteria(
    studyId: number,
    record: StudySelectionCriteria,
  ): Observable<StudySelectionCriteria> {
    const header = new HttpHeaders().set('content-type', 'application/json');
    const url =
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/selection-criteria';
    return this.http.post<StudySelectionCriteria>(url, record, {
      headers: header,
    });
  }

  /**
   * Updates Selection Criteria Row into the database
   * @param studyId The studyId
   * @param record The new row to update
   */
  public updateAncillarySelectionCriteria(
    studyId: number,
    record: StudySelectionCriteria,
  ): Observable<StudySelectionCriteria> {
    const url =
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/selection-criteria/' +
      record.ancillaryQualificationCriteriaId;
    return this.http.put<StudySelectionCriteria>(url, record);
  }

  /**
   * Deletes the Selection Criteria from the database
   * @param studyId The STudyID
   * @param recordId Primary Key
   * @param tupleVersion The tupleVersion of the row
   */
  public deleteAncillarySelectionCriteria(
    studyId: number,
    recordId: number,
    tupleVersion: number,
  ): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const url =
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/selection-criteria/' +
      recordId;
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /**
   * Gets the Lookup codes for criteria types from the service
   * @param studyId The studyId
   */
  public getCriteriaTypeCodes(): Observable<Code[]> {
    const results: Code[] = [
      { id: 401101, code: 'Arm', name: 'Arm' },
      { id: 401112, code: 'Institution', name: 'Institution' },
      { id: 401123, code: 'Grouping Factor', name: 'Grouping Factor' },
      { id: 401124, code: 'First # patients', name: 'First # patients' },
    ];
    // return of(results);
    return this.http.get<Code[]>(
      this.serviceUrlPrefix + 'configuration/criteria-types',
    );
  }

  /**
   * Gets the Lookup codes for inclusion from the service
   * @param studyId The studyId
   */
  public getCriteriaInclusionCodes(): Observable<Code[]> {
    return this.http.get<Code[]>(
      this.serviceUrlPrefix + 'configuration/inclusion-types',
    );
  }

  /**
   * Gets the Ancillary Arms from the service
   * @param studyId The studyId
   */
  public getAncillaryArmCodes(studyId: number): Observable<Code[]> {
    return this.http.get<Code[]>(
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/selection-criteria/arms',
    );
  }

  /**
   * Gets the Ancillary Grouping Factors from the service
   * @param studyId The studyId
   */
  public getAncillaryGroupingFactorCodes(
    studyId: number,
  ): Observable<VariableValidValue[]> {
    return this.http.get<VariableValidValue[]>(
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/selection-criteria/grouping-factors',
    );
  }

  /**
   * Gets the Ancillary Arms from the service
   * @param studyId The studyId
   */
  public getAncillaryInstitutions(studyId: number): Observable<Institution[]> {
    return this.http.get<Institution[]>(
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/selection-criteria/institutions',
    );
  }

  /**
   * Gets operators used in Ancillary Qualification Criteria
   */
  public getAncillaryOperators(): Observable<
    AncillaryQualificationCriteriaType[]
  > {
    return this.http.get<AncillaryQualificationCriteriaType[]>(
      `${this.serviceUrlPrefix}configuration/criteria-types/operators`,
    );
  }

  /***************** Selection Certifications - End  *************************************/

  /***************** Funding Sources - Start  *************************************/

  /**
   * Gets the Funding Sources from the service
   * @param studyId The studyId
   */
  public getAncillaryFundingSources(
    studyId: number,
  ): Observable<StudyFundingSource[]> {
    /// ancillary-studies/{studyId}/selection-criteria
    const results: StudyFundingSource[] = [
      {
        fundingSourceId: 2,
        fundingType: {
          id: 1,
          code: 'drug',
          name: 'drug',
        },
        fundingSource: 'Test Source ADD/update',
        comment: 'Test Comment ADD/update',
        startDate: null,
        endDate: null,
        lastModifiedDate: new Date('2019-06-07'),
        lastModifiedUser: '125',
        tupleVersion: 3,
      },
    ];
    // return of(results)
    const url: string =
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/funding-sources';
    return this.http.get<StudyFundingSource[]>(url);
  }

  /**
   * Inserts a new Funding Source Row into the database
   * @param studyId The studyId
   * @param record The new row to insert
   */
  public insertAncillaryFundingSource(
    studyId: number,
    record: StudySelectionCriteria,
  ): Observable<StudySelectionCriteria> {
    const header = new HttpHeaders().set('content-type', 'application/json');
    const url =
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/funding-sources';
    return this.http.post<StudySelectionCriteria>(url, record, {
      headers: header,
    });
  }
  /**
   * Updates Funding Source Row into the database
   * @param studyId The studyId
   * @param record The new row to update
   */
  public updateAncillaryFundingSource(
    studyId: number,
    record: StudySelectionCriteria,
  ): Observable<StudySelectionCriteria> {
    const url =
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/funding-sources';
    return this.http.put<StudySelectionCriteria>(url, record);
  }

  /**
   * Deletes the Funding Source from the database
   * @param studyId The STudyID
   * @param recordId Primary Key
   * @param tupleVersion The tupleVersion of the row
   */
  public deleteAncillaryFundingSource(
    studyId: number,
    recordId: number,
    tupleVersion: number,
  ): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', tupleVersion.toString());
    const url =
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/funding-sources/' +
      recordId;
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /**
   * Gets the Lookup codes for FundingSource types from the service
   * @param studyId The studyId
   */
  public getAncillaryFundingSourceTypeCodes(): Observable<Code[]> {
    const results: Code[] = [
      { id: 4101, code: 'Translational', name: 'Translational' },
    ];
    //  return of(results);
    const url: string =
      this.serviceUrlPrefix + 'configuration/ancillary/fund-types';
    return this.http.get<Code[]>(url);
  }

  /***************** Funding Sources - End  *************************************/

  /******** Study Dosage ********************** */

  public getStudyDosageStatusCodes(studyId: number) {
    return this.http.get<Array<Code>>(
      this.serviceUrlPrefix + 'configuration/dosage-status-list',
    );
    // let statuses: Code[] = [
    //     {"id": 1, "name": "Active", "code": "act"},
    //     {"id": 2, "name": "Inactive", "code": "inact"}
    // ];
    // return of(statuses);
  }

  public getStudyDosages(studyId: number): Observable<StudyDosage[]> {
    // let refs: StudyDosage[] = [
    // {
    //     "dosageId": 26997,
    //     "studyId": 9500,
    //     "dosageLevel": "1",
    //     "dosageStatus": {
    //         "id": 1,
    //         "name": "Active",
    //         "code": "act"
    //     },
    //     // "dosageDesc": " 5 mg Apixibana ;lksdjf ;lakjdf ;laks jdf;laj s;loiwueorwe jrwoieuroiwue oiuweor uowieu rqejkr hjkwehrk iquyeiury iuqwejkh rqiwuery",
    //     "dosageDesc": "12345678901234567891123456789212345678931234567894123456789512345678961234567897123456789812345678991234567890" +
    //     "12345678901234567891123456789212345678931234567894123456789512345678961234567897123456789812345678991234567890" +
    //     "12345678901234567891123456789212345678931234567894123456789512345678961234567897123456789812345678991234567890",
    //     "lastModifiedDate": new Date(1543259313950),
    //     "lastModifiedUserName": "m0000000",
    //     "tupleVersion": 1
    // },
    // {
    //     "dosageId": 26987,
    //     "studyId": 9500,
    //     "dosageLevel": "2",
    //     "dosageStatus": {
    //         "id": 2,
    //         "name": "Inactive",
    //         "code": "inact"
    //     },
    //     "dosageDesc": " 15 mg oxycontin",
    //     "lastModifiedDate": new Date(1543259313950),
    //     "lastModifiedUserName": "266",
    //     "tupleVersion": 2
    // },
    // ];
    // return of(refs);
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/dosages';
    return this.http.get<StudyDosage[]>(myUrl);
  }

  public updateStudyDosage(
    studyId: number,
    record: StudyDosage,
  ): Observable<StudyDosage> {
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/dosages';
    return this.http.put<StudyDosage>(myUrl, record);
  }

  public insertStudyDosage(
    studyId: number,
    record: StudyDosage,
  ): Observable<StudyDosage> {
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/dosages';
    return this.http.post<StudyDosage>(myUrl, record);
  }

  /**
   *
   * @param studyId - ID of the study being acted upon
   * @param record - Study Dosage record being acted upon
   */
  public deleteStudyDosage(
    studyId: number,
    record: StudyDosage,
  ): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', record.tupleVersion.toString());
    const myUrl =
      this.serviceUrlPrefix +
      'studies/' +
      studyId +
      '/dosages/' +
      record.dosageId;
    return this.http.delete(myUrl, { headers: header, responseType: 'text' });
  }

  /******** Study Dosage - end ********************** */

  /***************** Ancillary Study Team - Start  *************************************/

  /**
   * Gets the Study Team from the service
   * @param studyId The studyId
   */
  public getAncillaryStudyTeam(studyId: number): Observable<StudyTeam[]> {
    // ancillary-studies/{studyId}/study-team
    // const results: StudyTeam[] = [
    //     {
    //         'studyTeamId': 2,
    //         'person': {
    //             'firstName': 'Amanda',
    //             'lastName': 'Smith',
    //             'email': 'myName@gmail.com',
    //             'pk': 123456
    //         },
    //         'role': {'roleCode': 'role_stat'},
    //         'startDate': new Date('1/29/2020'),
    //         'endDate': null,
    //         'studyId': 12345,
    //         'modifiedTs': new Date('6/7/2019'),
    //         'modifiedByID': '125',
    //         'tupleVersion': 3
    //     },
    //     {
    //         'studyTeamId': 3,
    //         'person': {
    //             'firstName': 'Tom',
    //             'lastName': 'Jones',
    //             'email': 'tj@yahoo.com',
    //             'pk': 987654
    //         },
    //         'role': {'roleCode': 'role_stat'},
    //         'startDate': new Date('2/21/2020'),
    //         'endDate': null,
    //         'studyId': 12345,
    //         'modifiedTs': new Date('5/1/2019'),
    //         'modifiedByID': '125',
    //         'tupleVersion': 3
    //     }
    // ]
    // return of(results);
    const url: string =
      this.serviceUrlPrefix + 'ancillary-studies/' + studyId + '/study-team';
    return this.http.get<StudyTeam[]>(url);
  }

  /**
   * Inserts a new study team Row into the database
   * @param studyId The studyId
   * @param record The new row to insert
   */
  public insertAncillaryStudyTeam(
    studyId: number,
    record: StudyTeam,
  ): Observable<StudyTeam> {
    const header = new HttpHeaders().set('content-type', 'application/json');
    const url =
      this.serviceUrlPrefix + 'ancillary-studies/' + studyId + '/study-team';
    return this.http.post<StudyTeam>(url, record, { headers: header });
  }
  /**
   * Updates Study Team Row into the database
   * @param studyId The studyId
   * @param record The new row to update
   */
  public updateAncillaryStudyTeam(
    studyId: number,
    record: StudyTeam,
  ): Observable<StudyTeam> {
    const url =
      this.serviceUrlPrefix + 'ancillary-studies/' + studyId + '/study-team';
    return this.http.put<StudyTeam>(url, record);
  }

  /**
   * Deletes the study team from the database
   * @param studyId The STudyID
   * @param recordId Primary Key
   * @param tupleVersion The tupleVersion of the row
   */
  public deleteAncillaryStudyTeam(
    studyId: number,
    row: StudyTeam,
  ): Observable<any> {
    //recordId: number, tupleVersion: number): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('If-Match', row.tupleVersion.toString());
    const url =
      this.serviceUrlPrefix +
      'ancillary-studies/' +
      studyId +
      '/study-team/' +
      row.studyTeamId; //recordId;
    return this.http.delete(url, { headers: header, responseType: 'text' });
  }

  /**
   * Gets the Lookup codes for study team study roles from the service
   */
  public getAncillaryStudyTeamRoles(): Observable<StudyTeamRole[]> {
    // const results: StudyTeamRole[] =
    // [{ 'roleCode': 'role_stat', 'roleDescription': 'Statistician' },
    // { 'roleCode': 'role_lead', 'roleDescription': 'Leader' }];
    // return of(results);
    const url: string = this.serviceUrlPrefix + 'studies/team-roles';
    return this.http.get<StudyTeamRole[]>(url);
  }

  /***************** Ancillary Study Team - End  *************************************/

  /***************** Person Selection Modal - start  *************************************/
  /**
   * Searches for the person based on the criteria provided
   * @param firstName The fragment of firstName to search
   * @param lastName The fragment of last name to search
   * @param email The email to search
   */
  public getPersonForCriteria(
    searchCriteria: SearchCriteriaPerson,
  ): Observable<Person[]> {
    let qryParams = '';
    if (
      searchCriteria.firstName &&
      searchCriteria.firstName.trim().length > 0
    ) {
      qryParams += 'firstName=' + searchCriteria.firstName;
    }
    if (searchCriteria.lastName && searchCriteria.lastName.trim().length > 0) {
      if (qryParams.length > 0) {
        qryParams += '&';
      }
      qryParams += 'lastName=' + searchCriteria.lastName;
    }
    if (searchCriteria.email && searchCriteria.email.trim().length > 0) {
      if (qryParams.length > 0) {
        qryParams += '&';
      }
      qryParams += 'email=' + searchCriteria.email;
    }
    // https://int.reg-backend.mayo.edu/api/v1/users?firstName=test&lastName=t
    const url = 'users?' + qryParams;
    return this.http.get<Person[]>(`${this.config.apiEndpoint}` + url);
  }

  /***************** Person Selection Modal - end  *************************************/

  /**
   * Returns the list of studySlotGroups
   * @param studyId The studyId whose studySiteLevels are to be retrieved
   */
  public getStudySlotGroups(studyId: number): Observable<StudySlotGroup> {
    /// studies/{studyId}/reservation
    const url = 'studies/' + studyId + '/reservations/groups';
    return this.http.get<StudySlotGroup>(this.serviceUrlPrefix + url);
  }
  /**
   * Returns the list of study attribute codes
   */
  public getStudyAttributesCode(): Observable<Code[]> {
    const url = 'configuration/study-attribute-codes';
    return this.http.get<Code[]>(this.serviceUrlPrefix + url);
  }


  /**
   * Add studyAuthToken
   * @param studyId 
   * @param studyAuthToken 
   * @returns StudyAuthToken
   */
  public addStudyAuthToken(
    studyId: number,
    studyAuthToken: StudyAuthToken,
  ): Observable<StudyAuthToken> {
    const url: string =
      this.serviceUrlPrefix + 'studies/' + studyId + '/auth-token';
    return this.http.post<StudyAuthToken>(url, studyAuthToken);
  }


  /**
   * Update StudyAuthToken
   * @param studyId 
   * @param studyAuthToken 
   * @returns 
   */
  public updateStudyAuthToken(
    studyId: number,
    studyAuthToken: StudyAuthToken,
  ): Observable<StudyAuthToken> {
    const url: string =
      this.serviceUrlPrefix + 'studies/' + studyId + '/auth-token';
    return this.http.put<StudyAuthToken>(url, studyAuthToken);
  }

  /**
   * Delete StudyAuthToken
   * @param studyId 
   * @param studyAuthToken 
   * @returns 
   */
  deleteStudyAuthToken(studyId: number, studyAuthToken: StudyAuthToken): Observable<StudyAuthToken> {
    return this.http.delete<StudyAuthToken>(`${this.serviceUrlPrefix}studies/${studyId}/auth-token`, { body: studyAuthToken });
  }


   /**
   * This will return the REDCap study details for passed in token.
   * 
   * @param studyId 
   * @param token 
   * @returns 
   */
   getRedcapStudyDetails(studyId: number, token: string): Observable<RedcapStudy> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('token', token);
    return this.http.get<RedcapStudy>(`${this.serviceUrlPrefix}studies/${studyId}/redcap/token-info`, { headers: header, responseType: 'json'});
  }

  /**
   * This will return the REDCap token for study.
   * 
   * @param studyId 
   * @param token 
   * @returns 
   */
  getRedcapInfo(studyId: number): Observable<StudyAuthToken> {
    return this.http.get<StudyAuthToken>(`${this.serviceUrlPrefix}studies/${studyId}/redcap/info`);
  }


   

}
