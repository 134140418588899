<query-builder [formControl]="queryControl" [config]="queryConfiguration" [allowRuleset]="allowRuleset"
    [allowCollapse]="allowCollapse" [compareWith]="compareFn">
    <ng-container *queryInput="let rule; let field=field; let options=options; type: 'category'; let onChange=onChange">
        <div class="q-control-size">
            <select [(ngModel)]="rule.value" (ngModelChange)="onChange()" [compareWith]="getCompareFunction(field)"
                class="q-input-control">
                <option *ngFor="let opt of options" [ngValue]="opt.value">
                    {{ opt.name }}
                </option>
            </select>
        </div>
        <span *ngIf="getDisplayError(rule, queryControl, 'category')?.length > 0" class="q-input-control error-message">
            {{getDisplayError(rule, queryControl, 'category')}}
        </span>
    </ng-container>
    <ng-container
        *queryInput="let rule; let field=field; let options=options; type: 'multiselect'; let onChange=onChange">
        <div class="q-control-size">
            <select [(ngModel)]="rule.value" (ngModelChange)="onChange()" [compareWith]="getCompareFunction(field)"
                class="q-input-control" multiple>
                <option *ngFor="let opt of options" [ngValue]="opt.value">
                    {{ opt.name }}
                </option>
            </select>
            <span *ngIf="getDisplayError(rule, queryControl, 'multiselect')" class="q-input-control error-message">
                {{getDisplayError(rule, queryControl, 'multiselect')}}
            </span>
        </div>
    </ng-container>
    <ng-container *queryInput="let rule; let field=field; let options=options; type: 'number'; let onChange=onChange">
        <div class="q-control-size">
            <input class="q-input-control" [(ngModel)]="rule.value" type="number" (ngModelChange)="onChange()">
            <span *ngIf="getDisplayError(rule, queryControl, 'number')" class="q-input-control error-message">
                {{getDisplayError(rule, queryControl, 'number')}}
            </span>
        </div>
    </ng-container>
    <ng-container *queryInput="let rule; let field=field; let options=options; type: 'null'; let onChange=onChange">
        <span *ngIf="getDisplayError(rule, queryControl, 'null')" class="q-input-control error-message">
            {{getDisplayError(rule, queryControl, 'null')}}
        </span>
    </ng-container>
</query-builder>
<br>
<div id="queryTranslationDiv" *ngIf="displayQueryTranslation">
    <span class="query-translation-heading">Query Translation</span>
    <div [innerHTML]="getQueryTranslation(true) | safeHtml"></div>
</div>