import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import * as activityConst from 'app/common/model/activity-name-constants'


@Directive({
  selector: '[mcCanNavigate]'
})

/**
 * Directive on the html element evaluates if the activity name passed in
 * has minimum access so it can be displayed or hidden
 */
export class CanNavigateDirective implements OnInit {

  @Input('mcCanNavigate')activityName: string;

  /**
   * Input to allow granularity of access type
   */
  @Input() accessType = '';

    /**
   * Input to identify if tile/button should be hidden if not authorized
   */
  @Input() displayType = '';

    /**
   * Input to identify if studyId associated with activity
   */
  @Input() studyId: number ;


  private el: HTMLElement;

  private elementRef: ElementRef ;

  private visibleDisplay: string;

  private disableClassName = 'element-disabled';

  private hideClassName = 'hide-content';

  constructor(el: ElementRef, private userService: UserService) {
    this.elementRef = el;
    this.el = el.nativeElement;
    if (this.el && this.el.style) {
      this.visibleDisplay = this.el.style.display;
    }
  }

  ngOnInit() {
    // console.log(" in can-navigate "+ this.activityName +" studyId=" + this.studyId);
    let allow = true;
    if (this.activityName) {
      // if this is a valid property on the constants, use that or pass the string directory
      const actName: string = activityConst[this.activityName] ? activityConst[this.activityName] : this.activityName;

      // if the studyid not identified, then studyid doesn't matter and set to null
      if (!this.studyId) { this.studyId = null; }
      allow = this.userService.hasMinimumAccessByStudy(actName, this.studyId);

      if (this.accessType && this.accessType.trim().length > 0) {
        allow = false;
        if (this.accessType.toLowerCase() == 'update') {
          // console.log("Checking for UPDATE Access")
          allow = this.userService.hasModifyAccess(actName);
        }
        if (this.accessType.toLowerCase() == 'create') {
          // console.log("Checking for create Access")
          allow = this.userService.hasCreateAccessByStudy(actName, this.studyId);
        }
      }
      //  console.log("can Navigate?", actName, " => ", allow)
    }
    // if user not allowed and type hide, hide content, display: none
     if (this.el && this.el.style && !allow && this.displayType == 'hide' ) {
       this.el.style.display =  'none';

    // if user not allowed and type hide-keep(better for half tiles), hide content,  visibility:hidden
     } else if (this.el && this.el.style && !allow && this.displayType == 'hide-keep' ) {
      this.el.style.visibility =  'hidden';

    // otherwise if user not allowed and displayType not set, disable
    } else if (this.elementRef && this.elementRef.nativeElement && !allow) {
      this.elementRef.nativeElement.classList.add(this.disableClassName);
    }

    // If the user is allowed, leave the display as is
  }

}
