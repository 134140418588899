import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import { Code } from 'app/common/model/code';
import { HeaderBar } from 'app/common/model/header-bar';
import { StudyCreateService } from 'app/common/services/study-create.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'mc-study-create',
  templateUrl: './study-create.component.html',
  styleUrls: ['./study-create.component.scss']
})
export class StudyCreateComponent implements OnInit, OnDestroy {

  studyTypes: Array<Code>;


  subscriptions: Subscription[] = [];

  /**
   * model for the header bar
   */
  headerModel: HeaderBar = new HeaderBar('Building Study', '', false, '', false);

  selectedStudyType: Code = null;

  receiveNewSelectedStudyType($event) {
    this.selectedStudyType = $event;
  }

  constructor(
    private studyCreateService: StudyCreateService,
    @Inject(APP_CONFIG) private config: AppConfig,
   ) { }

  ngOnInit() {

    if (this.config.version == 'v2') {
      this.subscriptions.push(this.studyCreateService.getStudyTypesForV2()
      .subscribe(results => this.studyTypes = results));
    } else {
      this.subscriptions.push(this.studyCreateService.getStudyTypes()
      .subscribe(results => this.studyTypes = results));
    }


  }

  /**
   * True if the selected studyType is Protocol
   */
  isStudyTypeProtocol(): boolean {
    if (this.selectedStudyType) {
      return this.selectedStudyType.code.toLowerCase() == 'protocol';
    }
    return false;
  }

    /**
   * True if the selected studyType is Ancillary
   */
  isStudyTypeAncillary(): boolean {
    if (this.selectedStudyType) {
      return this.selectedStudyType.code.toLowerCase() == 'ancillary';
    }
    return false;
  }

  isStudyMultiSubjType(): boolean {
    if (this.selectedStudyType && !this.isStudyTypeProtocol() && !this.isStudyTypeAncillary()) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subs => {
        subs.unsubscribe();
      }

    )
  }

  checkForMatchingStudyTemplate() {
    console.log('study-create.component checkForMatchingStudyTemplate');
    // By design, the researchEntity is blanked out when the study type changes, so just have to reset the applyStudyTemplate to false
    // and hide the question

  }

}
