<div class="modal" [ngClass]="{'is-active': showModal}">
    <div class="modal-background"></div>
    <div class="modal-card">

        <section class="modal-card-body">
            <div>
                <span>****UNBLINDED INFORMATION****</span>
            </div>
            <div class="pad-section">
                <div *ngIf="shouldShowProtocolNum()">
                    <div>
                        <div class="columns">
                            <div class=" column is-3">
                                <div class="field is-option-div">
                                    <label class="label is-size-7">
                                        Study Number:
                                    </label>
                                </div>
                            </div>
                            <div class="column">
                                <div class="is-read-only-label">
                                    <span>{{unblindedInfo.protocolNum}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="shouldShowSubjectNum()">
                    <div class="columns">
                        <div class=" column is-3">
                            <div class="field is-option-div">
                                <label class="label is-size-7">
                                    Subject ID:
                                </label>
                            </div>
                        </div>
                        <div class="column">
                            <div class="is-read-only-label">
                                <span>{{unblindedInfo.subjectNum}}</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="shouldShowDrug()">
                    <div class="columns">
                        <div class=" column is-3">
                            <div class="field is-option-div">
                                <label class="label is-size-7">
                                    Drug:
                                </label>
                            </div>
                        </div>
                        <div class="column">
                            <div class="is-read-only-label">
                                <span>{{unblindedInfo.drug}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="modal-card-foot">
            <div class="is-pulled-right footer-button ">
                <button class="button is-primary is-radiusless" [disabled]="!hasDrug()"
                [cdkCopyToClipboard]="copyUnblindedInfo()" >
                Copy</button>
                <button class="button is-radiusless" (click)="closeModal()">
                Cancel</button>
            </div>
        </footer>
</div>
</div>