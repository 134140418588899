import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { EditBarActions } from 'app/common/model/edit-bar-actions';
import { HeaderBar } from 'app/common/model/header-bar';
import { StudyTemplate } from 'app/common/model/study-template';
import { StudyTemplateService } from 'app/common/services/study-template/study-template.service';
import { Observable, Subscription, forkJoin, EMPTY, of } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BaseComponent } from 'app/common/base/base-component';
import * as _ from 'lodash';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { catchError, tap } from 'rxjs/operators';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { UserService } from 'app/common/services/user.service';

@Component({
  selector: 'mc-study-template',
  templateUrl: './study-template.component.html',
  styleUrls: ['./study-template.component.scss']
})
export class StudyTemplateComponent extends BaseComponent implements OnInit {


  /**
  * The edit bar model for the edit-bar
  */
  editBarActions: EditBarActions;

  /**
* model for the header bar
*/
  headerModel: HeaderBar;

  /**
 * Subscription array to hold all the subscribes
 */
  subscriptions: Subscription[] = [];

  data: StudyTemplate[];

  /**
   * Page variable used during paging
   */
  public page = 1;
  /**
   * Used to determine the items on a page
   */
  public itemsPerPage = 10;
  /**
   * maximum page to be displayed
   */
  public maxSize = 5;

  /**
   * Length of the array containing the viewModels
   */
  public length = 0;

  /**
* variable to hold the viewModels sorted and filtered as needed for the ui
*/
  public rows: Array<StudyTemplate> = [];

  /**
 * boolean to determine if the filter row should be displayed
 */
  public showFilterRow: boolean = false;

  // study templates table columns
  public columns: Array<any> = [
    {
      title: 'Title', className: ['first20'],
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Title' },
      name: 'templateTitle', sort: ''
    },
    {
      title: "Research Entity", name: 'researchEntity.name', className: ['first20'],
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Research Entity' }
    },
    {
      title: 'Study Type', className: ['first20'],
      display: true,
      name: 'studyType.name', sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Study Type', sort: '' }
    },
    {
      title: 'Status', name: 'templateStatus.name', className: ['last60'],
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Status' }
    },
  ];

  public selectedIds: number[] = [];
  selectedInfoRowId = 0;
  deletedIds: number[] = [];
  originalStudyTemplates: StudyTemplate[] = [];
  studyTemplates: StudyTemplate[] = [];


  /**
   * Configuration for the table displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['templateTable']
  };

  constructor(
    private viewContainer: ViewContainerRef,
    studySetupService: StudySetupService,
    private studyTemplateService: StudyTemplateService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.headerModel = new HeaderBar('Study Templates', '', false, '', true);
    if (!this.userService.hasModifyAccess("study-template")) {
      this.headerModel.disableButtons = true;
      this.editBarActions = new EditBarActions(true, false, true, true, true);
    }
    else {
      this.editBarActions = new EditBarActions(false, false, false, false, false);
    }

    //Get study templates
    this.subscriptions.push(this.studyTemplateService.getStudyTemplates()
      .subscribe(results => {
        this.data = results;
        this.studyTemplates = _.cloneDeep(this.data);
        this.cloneData(false);
        this.onChangeTable(this.config);
      }));

  }

  /**
   * Clones the data from / to original data
   * @param rollback True if the original data needs to be restored
   */
  cloneData(rollback: boolean = false) {
    // this is to rollback when cancel is clicked.
    if (rollback) {
      this.deletedIds = [];
      this.studyTemplates = _.cloneDeep(this.originalStudyTemplates);
      this.data = _.cloneDeep(this.originalStudyTemplates);
      this.length = this.data.length;
      this.onChangeTable(this.config);
    } else {
      this.originalStudyTemplates = _.cloneDeep(this.studyTemplates);
    }
  }

  /**
  * Returns true if there are pending changes on page
  */
  doChangesExist() {
    const isModified: boolean = !(_.isEqual(this.studyTemplates, this.originalStudyTemplates));
    return isModified;
  }


  /**
  * Destroy implementation - closes all the subscriptions
  */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    );
  }

  /********* BEGIN: Pending Changes warning************** */
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.doChangesExist()) {
      return true;
    }
    const modalArg = new ModalDialogArg('modal-warn', 'Cancel');
    const result = this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg);
    return result.asObservable().pipe(tap(x => {
      return of(x);
    }));
  }
  /********* END : Pending Changes warning************** */

  cellClick(data: StudyTemplate, columnName: string): any {
    var selectedId: number = this.getIdIndex(data.studyTemplateId);
    if (selectedId > -1) {
      this.selectedIds.splice(selectedId, 1);
    } else {
      this.selectedIds.push(data.studyTemplateId);
    }
    this.selectedInfoRowId = 0;
  }

  isRowSelected(row) {
    return this.getIdIndex(row.studyTemplateId) > -1;
  }

  getIdIndex(id) {
    let idx = -1;
    if (this.selectedIds.length > 0) {
      idx = (this.selectedIds.findIndex(x => x == id));
    }
    return idx;
  }

  processInfoClick() {
    if (this.selectedIds.length > 0) {
      if (this.selectedInfoRowId == this.selectedIds[this.selectedIds.length - 1]) {
        this.selectedInfoRowId = 0;
      } else {
        this.selectedInfoRowId = this.selectedIds[this.selectedIds.length - 1];
      }
    } else {
      this.selectedInfoRowId = 0;
    }
  }

  isInfoSelected(row: StudyTemplate) {
    return row.studyTemplateId == this.selectedInfoRowId;
  }

  getInfoMessage(row) {
    let message = '';
    if (this.isInfoSelected(row)) {
      message = this.getInfoMessageString(row.modifiedById, row.modifiedTs);
    }
    return message;
  }

  /**
   * Parses and returns the message for the info click
   * @param userPk The User Primary Key
   * @param modifiedTs The Date Time for the modified record
   */
  getInfoMessageString(userPk: string, modifiedTs: any): string {
    let message = '';
    message = 'Last updated';
    let lastModifiedDate = '';
    const lastUserName = this.getUserNameFromPrimaryKey(userPk);

    message += ' by ' + lastUserName;
    if (modifiedTs) {
      lastModifiedDate = ' on ' + moment(new Date(modifiedTs)).format('MM/DD/YYYY HH:mm:ss');
    }
    message += lastModifiedDate;
    return message;
  }

  /**
   * Gets the user name from the service using the Primary Key
   * @param userPk The userPk string
   */
  getUserNameFromPrimaryKey(userPk: string) {
    let username = userPk; // 'unknown';
    if (userPk && userPk.trim().length > 0) {
      // force to be numeric
      const userPkNum: any = +userPk;
      if (!isNaN(userPkNum)) {
        this.usrService.getPersonForId(userPkNum)
          .subscribe(result => {
            username = result.lastName + ', ' + result.firstName;
          }, (error) => {
            username = userPk + '(name not found)';
          }, () => {
            // console.log("Complete subscription ")
          });
      }
    }
    return username;
  }


  /**
 * TODO: Need to implememt this method
 * This will be executed when action on the edit bar is performed.
 *
 * @param  {string} selectedAction - editbar action
 */
  selectedEditAction(selectedAction: string) {
    if (selectedAction == 'info') {
      this.processInfoClick();
    } else if (selectedAction == 'add') {
      this.navigateToStudyTemplateSetup(null, 0);
    } else if (selectedAction == 'delete') {
      this.deleteSelections();
    } else if (selectedAction == 'filter') {
      this.showFilterRow = !this.showFilterRow;
    } else if (selectedAction == 'edit') {
      this.navigateToStudyTemplateSetup(null, this.selectedIds[0]);
    }
  }

  /**
  * Delete the selected
  */
  deleteSelections(): void {
    if (this.selectedIds.length == 0) {
      return;
    }
    this.selectedIds.forEach(eachId => {
      const idx = this.studyTemplates.findIndex(eachStudyTemplate => eachStudyTemplate.studyTemplateId == eachId);
      this.studyTemplates.splice(idx, 1);
      this.data.splice(idx, 1);
      this.deletedIds.push(eachId);
    });
    this.selectedIds = [];
    this.onChangeTable(this.config);
  }

  navigateToStudyTemplateSetup($event: Event, studyTemplateId: number): void {
    if ($event) {
      $event.stopPropagation();
    }
    let qryParams = {};
    // const studyTemplateId: number = studyTemplate ? studyTemplate.studyTemplateId : 0;
    const navUrl: string = './study-template-setup/' + studyTemplateId;
    this.router.navigate([navUrl],
      {
        relativeTo: this.route,
        queryParams: qryParams,
        queryParamsHandling: 'merge'
      });
  }

  /**
  * Saves the deletions
  */
  saveChanges() {
    if (!this.doChangesExist()) {
      return;
    }

    const observables: Observable<any>[] = [];
    let hasError = false;

    if (this.deletedIds.length > 0) {
      for (const eachId of this.deletedIds) {
        const match = this.originalStudyTemplates.find(eachStudyTemplate => eachStudyTemplate.studyTemplateId == eachId);
        observables.push(this.studyTemplateService.deleteStudyTemplate(eachId, match.tupleVersion)
          .pipe(catchError(
            error => {
              throw error;
            }))
        );
      }
    }

    this.subscriptions.push(forkJoin(observables)
      .subscribe(() => {
      }, (error) => {
        hasError = true;
      },
        () => {
          if (hasError) {
            return;
          }
          this.studyTemplates = this.data;
          this.deletedIds = [];
          this.selectedIds = [];
          this.cloneData();
          const modalArg = new ModalDialogArg('custom-save', 'Save');
          this.subscriptions.push(this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
            .subscribe(actionResult => {
              if (!actionResult) {
                // user wants to navigate to the home
                this.router.navigate(['home']);
              }
              else {
                //do nothing
              }
            }));
        }
      ));

  }

  /**
   *  Cancel button pressed
   */
  cancelChanges() {
    if (!this.doChangesExist()) {
      return true;
    }

    const modalArg = new ModalDialogArg('modal-warn', 'Cancel');
    this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
      .subscribe(actionResult => {
        if (actionResult) {
          this.cloneData(true);
        }
        else {
        }
      });
  }


  /**
  * Main method to handle filtering, sorting of the table
  * @param config Configuration of the datatable
  * @param page The current page being displayed
  */
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    let column: any = null;
    this.rows = [];
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.data, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;
    if (sortedData) {
      this.length = sortedData.length;
    }
  }


  /**
 * Applies the data filter as needed to the rows
 * @param data The data being displayed on the ui
 * @param config the configuration of the table
 */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {

          if (item[column.name] != null) {
            return (item[column.name] + "").toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          let property = column.name.split(".");

          if (property.length == 2 && item[property[0]][property[1]] != null) {
            return (item[property[0]][property[1]] + "").toLowerCase().match(column.filtering.filterString.toLowerCase());
          }

          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        let property = column.name.split(".");
        if (property.length == 2) {
          if (item[property[0]][property[1]] != null && item[property[0]][property[1]] != undefined
            && (item[property[0]][property[1]] + "").toString().match(this.config.filtering.filterString)) {
            flag = true;
          }
        } else {
          if (item[column.name] != null && item[column.name] != undefined
            && (item[column.name] + "").toString().match(this.config.filtering.filterString)) {
            flag = true;
          }
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  /**
   * Sorts the data in the table based on the column and sort order
   * @param data The data currently being displayed on the table
   * @param config The associated configuration for the data being displayed
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    let property = columnName.split(".");

    if (property.length == 2) {
      // simple sorting
      return data.sort((previous: any, current: any) => {
        const previousVal = previous[property[0]][property[1]] ? (previous[property[0]][property[1]] + "").toLowerCase() : '';
        const currentVal = current[property[0]][property[1]] ? (current[property[0]][property[1]] + "").toLowerCase() : '';

        if (previousVal > currentVal) {
          return sort === 'desc' ? -1 : 1;
        } else if (previousVal < currentVal) {
          return sort === 'asc' ? -1 : 1;
        }
        return 0;
      });
    } else {
      // simple sorting
      return data.sort((previous: any, current: any) => {
        const previousVal = previous[columnName] ? (previous[columnName] + "").toLowerCase() : '';
        const currentVal = current[columnName] ? (current[columnName] + "").toLowerCase() : '';

        if (previousVal > currentVal) {
          return sort === 'desc' ? -1 : 1;
        } else if (previousVal < currentVal) {
          return sort === 'asc' ? -1 : 1;
        }
        return 0;
      });
    }


  }


}
