import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mc-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {

  reason: string;

  constructor(private router: Router) {
      if (this.router.getCurrentNavigation() &&
          this.router.getCurrentNavigation().extras &&
          this.router.getCurrentNavigation().extras.state &&
          this.router.getCurrentNavigation().extras.state.reason) {
        this.reason = this.router.getCurrentNavigation().extras.state.reason;
      }
  }

  ngOnInit() {
  }

}
