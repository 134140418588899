import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mc-daterange-picker-wrapper',
  templateUrl: './daterange-picker-wrapper.component.html',
  styleUrls: ['./daterange-picker-wrapper.component.scss']
})
export class DaterangePickerWrapperComponent implements OnInit {

  @Input() inputStartDate: any;
  @Input() inputEndDate: any;

    /**
   * the event passing the start and end date selected 
   */
  @Output() startDateSelected = new EventEmitter<any>();
  @Output() endDateSelected = new EventEmitter<any>();

  modelStartDate: any = null;
  modelEndDate: any = null;

  
  constructor() {

  }

  ngOnInit() {
    this.modelStartDate=this.inputStartDate;
    this.modelEndDate=this.inputEndDate;
  }

  /**
   * Handles the event fired from the UI when a date is selected
   * @param event The date that was selected
   */
  public onStartDateSelected(event): void {
    this.startDateSelected.emit(this.modelStartDate);
  }

  /**
   * Handles the event fired from the UI when a date is selected
   * @param event The date that was selected
   */
  public onEndDateSelected(event): void {
    this.endDateSelected.emit(this.modelEndDate);
  }

}
