import { ModalDialogArg } from 'app/common/model/modal-dialog-args';

export class ToastMessage {
    /**
     * unique identifier for this message
     */
    key: string;
    /**
     * The content to be displayed in the toast
     */
    content: string;
    /**
     * style / class to determine the color and class to apply when displaying the toast
     */
    style: string;
    /**
     * true if the message waits for user to close with the delete button
     * false if the message should be cleared in 5 seconds
     */
    sticky = false;
    /**
     * If populated and passed, allows the message to be displayed in a moda window
     * instead of a toast
     */
    modalArg: ModalDialogArg;

    /**
     *
     * @param key : unique identifier to locate this message
     * @param content : content that should be displayed
     * @param style : class such as info succes custom-save etc. to style and color the message
     * @param isSticky : if a toast message if it should wait for user to dismiss it or is removed in 5 seconds
     * @param modalDialogArg : object to hold modal information for the modal display
     */
    constructor(key, content, style: string = 'info', isSticky: boolean = false,
        modalDialogArg: ModalDialogArg = null, ) {
        this.key = key;
        this.content = content;
        this.style = style || 'info';
        this.sticky = isSticky;
        this.modalArg = modalDialogArg;
    }
}

export class ToastPosition {
    top: number;
    left: number;
    constructor(top, left) {
        this.top = top;
        this.left = left;
    }

}
