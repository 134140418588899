import { NotificationCode } from './notification-code';

export class NotificationAccrualDetail {

    constructor(
        public notificationAccrualDetailId: number | null,
        public accrualType: NotificationCode| null,
        public notificationByLevel: NotificationCode| null,
        public includeByCharacteristic: number| null,
        public patientCountMethod: NotificationCode| null,
        public accrualPointType: NotificationCode| null,
        public accrualPoint: string| null
      ) {}
}
