import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/common/base/base-component';
import { HeaderBarComponent } from 'app/common/header-bar/header-bar.component';
import * as globalConst from 'app/common/model/app-constants';
import { Code } from 'app/common/model/code';
import { EditBarActions } from 'app/common/model/edit-bar-actions';
import { ErrorMessage } from 'app/common/model/error-message';
import { EventArg } from 'app/common/model/event-arg';
import { HeaderBar } from 'app/common/model/header-bar';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { PatientApproval } from 'app/common/model/patient-approval';
import { PatientApprovalModel } from 'app/common/model/patient-approval-model';
import { PatientService } from 'app/common/services/patient/patient.service';
import { StudySetupService } from 'app/common/services/study-setup.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { EMPTY, Observable, Subscription, forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';


@Component({
  selector: 'mc-patient-approval',
  templateUrl: './patient-approval.component.html',
  styleUrls: ['./patient-approval.component.scss']
})
export class PatientApprovalComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
     * model for the header bar
     */
  headerModel: HeaderBar;

  subscriptions: Subscription[] = [];

  /**
   * var to show hide left nav
   */
  showLeftNav = true;

  private currentUrl: any;

  private eventArg: EventArg = new EventArg('', '');

  studyId: number;

  studyNumber = '';


  /**
  * Variable to hold the caseDetailId
  */
  caseDetailId = 0;

  patientId = '';

  caseEventId = 0;

  approvalSchemaEventId = 0;

  /**
  * The composite id on the row that is currently selected for displaying the Info
  */
  selectedInfoRowId = -1;


  approvalModel: PatientApprovalModel = null;

  data: PatientApproval[] = [];

  originalData: PatientApproval[] = [];


  approvalStatusList: Code[] = [];

  /**
 * selectedIds Used by edit-bar
 */
  selectedIds: number[] = [];

  isEditMode = false;

  actionSetting: EditBarActions =
    {
      isAddDisabled: true,
      isEditDisabled: false,
      isInfoDisabled: false,
      isFilterDisabled: true,
      isDeleteDisabled: true,
      isEditBarDisabled: false
    };

  navBackPageEnum = 0;
  trackingNum: string;
  selectedCaseEventId: number;

  propertyId = 'approvalId';



  /**
   * The id of the row being currently edited
   */
  currentEditRowId = 0;

  /**
 * The current selected item if it's just one
 */
  private currentSelectedItem: PatientApproval = null;

  /**
   * Reference to the child header component
   */
  @ViewChild(HeaderBarComponent)
  private headerBar: HeaderBarComponent;

  constructor(studySetupService: StudySetupService,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private viewContainer: ViewContainerRef,) {
    super();
    this.subscriptions.push(
      studySetupService.changeEmitted$
        .subscribe(eventInfo => { this.parseEventInfo(eventInfo); })
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params['navBack']) {
          this.navBackPageEnum = +params['navBack'];
        }
        if (params['trackingNum']) {
          this.trackingNum = params['trackingNum'];
        }
        if (params['selectedCaseEventId']) {
          this.selectedCaseEventId = params['selectedCaseEventId'];
        }
      }));
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.studyId = params['studyId'];
      this.caseDetailId = params['caseDetailId'];
      this.patientId = params['patientId'];
      this.caseEventId = params['caseEventId'];
      this.approvalSchemaEventId = params['approvalSchemaEventId'];
      this.getApprovalStatusList();
      this.getApprovalData();
      this.subscriptions.push(this.studySetupService.getStudy(this.studyId)
        .subscribe(results => {
          this.studyNumber = results.studyNumber;
          // initialize the model for the header
          this.headerModel = {
            caption: 'Manage Approval',
            captionValue: '',
            showDescription: true,
            description: "Study Id: " + this.studyNumber + " | " + "Subject Id: " + this.patientId,
            showButtons: true,
            canNavigate: false,
            navigateUrl: '',
            disableButtons: false
          };
        }));
    }));

  }


  ngAfterViewInit() {
    this.headerBar.initHeader();
  }

  /**
   * Gets the Approval Data from the service
   */
  getApprovalData() {
    this.subscriptions.push(this.patientService.getPatientApprovalModel(this.caseEventId)
      .subscribe(result => {
        this.approvalModel = result;
        this.data = this.approvalModel.approvals;
        this.actionSetting.isEditDisabled = this.approvalModel.isRegistered;

        this.cloneData(false);
      }));
  }

  /**
   * Returns the list of approval statuses
   */
  getApprovalStatusList() {
    this.subscriptions.push(this.patientService.getApprovalStatusList()
      .subscribe(result => {
        this.approvalStatusList = result;
      }));

  }



  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    );
  }


  /**
   * Parses the event info to determine what event was fired
   * @param eventInfo Event info for details of the event
   */
  parseEventInfo(eventArg: EventArg) {
    if (eventArg.eventName == globalConst.EVENT_SAVE_ACTION) {
      this.saveChanges();
    } else if (eventArg.eventName == globalConst.EVENT_CANCEL_ACTION) {
      // if there are changes...
      this.cancelChanges();
    }
  }



  /**
 * Compare the Ids on the select dropdown
 * @param  {Code} a
 * @param  {Code} b
 * @returns boolean
 */
  compareFnStatusId(a: Code, b: Code): boolean {
    if (a || b) {
      return a && b && a.id == b.id;
    } else {
      return true;
    }
  }




  /**
   * Handler row clicked on the ui to select or deselect
   * @param row The object bound to the row
   */
  rowSelectedClick(row: PatientApproval): any {
    this.selectedInfoRowId = 0;
    this.currentEditRowId = 0;
    this.currentSelectedItem = null;

    const selectedId: number = this.getIdIndex(row[this.propertyId]);
    if (selectedId > -1) {
      this.selectedIds.splice(selectedId, 1);
    } else {
      this.selectedIds = [];
      this.selectedIds.push(row[this.propertyId]);
    }
    if (this.selectedIds.length == 1) {
      this.currentSelectedItem = row;
    }
  }


  /**
   * Returns the index of the id in the selectedIds
   * @param id the id to evaluate
   */
  getIdIndex(id: number) {
    let idx = -1;
    if (this.selectedIds.length > 0) {
      idx = (this.selectedIds.findIndex(x => x == id));
    }
    return idx;
  }

  /**
   * True if the row is selected
   * @param row The  object to evaluate
   */
  isRowSelected(row: PatientApproval): boolean {
    const key = row.approvalId;
    const selectedIdx: number = this.selectedIds.findIndex(item => item == key);
    return selectedIdx > -1;

  }


  /**
   * True if this row is selected for Edit
   * @param row The object bound to the row
   */
  isSelectedForEdit(row: PatientApproval): boolean {
    return this.currentEditRowId == row[this.propertyId];
  }

  isStatusPending(row: PatientApproval): boolean {
    return row.approvalStatus && row.approvalStatus.code == 'PND';
  }

  noCommentsForPending(row: PatientApproval) {
    if (row.approvalStatus && row.approvalStatus.code == 'PND') {
      row.comments = null;
    }
  }

  /**
   * Handler for the action clicked on the edit bar
   * @param selectedAction the selected action from the editbar
   */
  selectedEditAction(selectedAction: string) {
    if (selectedAction == 'info') {
      this.processInfoClick();

    } else if (selectedAction == 'edit') {
      if (this.currentEditRowId == 0) {
        // put the row in edit model
        this.currentEditRowId = this.selectedIds[0];
      } else {
        this.currentEditRowId = 0;
      }
    }

  }
  /**
   * Processes the click on the Info icon on the edit bar
   */
  private processInfoClick() {
    if (this.selectedIds.length > 0) {
      if (this.selectedInfoRowId == this.selectedIds[this.selectedIds.length - 1]) {
        this.selectedInfoRowId = -1;
      } else {
        this.selectedInfoRowId = this.selectedIds[this.selectedIds.length - 1];
      }
    } else {
      this.selectedInfoRowId = -1;
    }
  }


  /**
   * True if the row has been selected for showing the info
   * @param row The  row being evaluated
   */
  isInfoSelected(row: PatientApproval) {
    return this.selectedInfoRowId == (row.approvalId);
  }

  /**
 * Called when a row is selected and the info button is pushed
 *
 * @param row the row of the table to be operated on
 */
  getInfoMessage(row: PatientApproval) {
    let message = '';
    if (this.isInfoSelected(row)) {
      const lastUserName = this.getUserNameFromPrimaryKey(row.modifiedById);
      const lastModifiedDate = moment(row.modifiedDate).format('MM/DD/YYYY HH:mm:ss');
      message = 'Last updated by ' + lastUserName + ' on ' + lastModifiedDate +
        '\n'; // + row.eventName;
    }
    return message;
  }




  /**
     * Navigate back to Subject Landing Page
     */
  navigateBack() {
    const path: string = '/patient/' + this.studyId + '/' + this.patientId + '/' + this.caseDetailId;
    this.router.navigate([path], {
      queryParams: {
        'navBack': this.navBackPageEnum,
        'trackingNum': this.trackingNum,
        'selectedCaseEventId': this.selectedCaseEventId
      }
    });
  }


  /********* BEGIN - SAVE / CANCEL ************** */


  /**
   * Clones the data to track the changes
   * @param isRollback True if the data is to be reverted to its original state
   */
  cloneData(isRollback: boolean): void {
    if (!isRollback) {
      this.originalData = _.map(this.data, _.cloneDeep);
    } else {
      this.data = _.map(this.originalData, _.cloneDeep);
    }
  }


  /**
   * True if the original and current lists are different
   */
  doChangesExist(): boolean {
    const isModified: boolean = !(_.isEqual(this.data, this.originalData)); // !this.isArrayEqual(this.data, this.studyReferences);
    return isModified;
  }

  /**
   * Returns the changed list of approvals
   *
   */
  getDataChanges(): Array<PatientApproval> {
    const isModifiedData = _.differenceWith(this.data, this.originalData, _.isEqual);
    return isModifiedData;
  }

  /**
   * Saves the changes
   */
  saveChanges() {
    // this list contains all the inserts and the updates
    // If data is invalid exit or no changes, exit
    if (!this.doChangesExist()) {
      return;
    }
    const modifiedData: PatientApproval[] = this.getDataChanges();
    let hasError = false;

    const observables: Observable<any>[] = [];
    const errorMessages: ErrorMessage[] = [];
    if (modifiedData.length > 0) {
      for (const eachItem of modifiedData) {
        const eachSave = this.saveEachApproval(eachItem, errorMessages);
        observables.push(forkJoin(eachSave));
      }
    }
    // set the current data to the updated data
    this.subscriptions.push(forkJoin(observables)
      .subscribe(
        () => {
          if (errorMessages.length > 0) {
            this.groupErrors(errorMessages);
            hasError = true;
          }
        }, (error) => {
          hasError = true;
        },
        () => {
          if (hasError) {
            return;
          }
          this.cloneData(false);

          let variableData = '';
          if (this.studyNumber.length > 0) {
            variableData = 'studyNumber:' + this.studyNumber;
          }
          const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
          this.subscriptions.push(this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
            .subscribe(actionResult => {
              if (!actionResult) {
                // user wants to navigate to the PAtient Landing page
                this.navigateBack();
              }
            }));
        }
      ));
  }


  /**
   * Evaluates update or insert and Saves each approval
   * @param eachItem Each modified or new approval
   * @param errorMessages Error messages array to be populated for errors
   */
  saveEachApproval(eachItem: PatientApproval, errorMessages: ErrorMessage[]): Observable<any>[] {
    const observables: Observable<any>[] = [];
    //  eachItem is the object to be submitted for update
    // if it has a approvalId it's a PUT
    // if status is "pending" it's a DELETE
    if (eachItem.approvalId && eachItem.approvalStatus.code == 'PND') {
      observables.push(this.patientService.deletePatientApproval(this.caseEventId, eachItem.approvalId, eachItem.tupleVersion)
        .pipe(tap(x => {
          const exist = this.data.findIndex(v => v.approvalId == eachItem.approvalId);
          this.data[exist].approvalId = null;
        }))
        .pipe(catchError(
          error => {
            if (!this.checkValidationErrorInResponse(eachItem.approvalId, error, errorMessages)) {
              throw error;
            } else {
              return of(EMPTY);
            }
          }))
      );
    } else
      if (eachItem.approvalId) {
        // call update
        observables.push(this.patientService.updatePatientApproval
          (this.caseEventId, eachItem.approvalId, eachItem)
          .pipe(tap(x => {
            const exist = this.data.findIndex(v => v.approvalId == eachItem.approvalId);
            this.data[exist] = x;
          }))
          .pipe(catchError(
            error => {
              if (!this.checkValidationErrorInResponse(eachItem.approvalId, error, errorMessages)) {
                throw error;
              } else {
                return of(EMPTY);
              }
            }))
        );

      } else {
        const newClone = _.cloneDeep(eachItem);
        // Call Insert:
        const irt = this.patientService.addPatientApproval(this.caseEventId, eachItem);
        observables.push(irt
          .pipe(tap(x => {
            const newItemIdx = this.data.findIndex(v => v.approvalId == newClone.approvalId);
            this.data[newItemIdx] = x;
          }))
          .pipe(catchError(
            error => {
              if (!this.checkValidationErrorInResponse(newClone.approvalId, error, errorMessages)) {
                throw error;
              } else {
                return of(EMPTY);
              }
            }))
        );
      }
    return observables;
  }


  /**
   * Prompts the user for confirmation and reverts the data to its original state
  */
  cancelChanges() {
    // if there were no changes ignore
    if (this.doChangesExist()) {
      const modalArg = new ModalDialogArg('modal-warn', 'Cancel', null);
      this.subscriptions.push(
        this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
          .subscribe(result => {
            if (result) {
              this.cloneData(true);
              this.clearErrorMessages();
            }
          })
      );
    }

  }


  /********* Pending Changes warning************** */
  /**
   * Implemenation of the canDeactivate that will be called when we navigate away
   *
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.doChangesExist()) {
      return true;
    }
    const variableData: string = 'studyNumber:' + this.studyId;
    const modalArg = new ModalDialogArg('modal-warn', 'Cancel', variableData);
    const result = this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg);
    return result.asObservable().pipe(tap(x => {
      if (x) {
        this.cloneData(false);
        this.clearErrorMessages();
      }
      return of(x);
    }));
  }
  /*********  Pending Changes warning************** */


  /********* END - SAVE / CANCEL ************** */







}
