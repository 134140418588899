export class MsalConfig {
  constructor(
    public issuer: string,
    public tenantId: string,
    public clientId: string,
    public authority: string,
    public knownAuthorities: string[],
    public redirectUri: string,
    public postLogoutRedirectUri: string,
    public cacheLocation: string,
    public scope: string,
    public protectedResourceMap: Map<string, string[]>
  ) { }
}

export class RuntimeConfig {
  constructor(
    public version: string, // v1, v2
    public production: boolean,
    public envName: string,
    public serviceUrl: string,
    public serviceUrlv2: string,
    public logoutUrl: string,
    public msalConfig: MsalConfig
  ) { }
};
