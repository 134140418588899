import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[mcClearInput]'
})
export class ClearInputDirective {

  @Input() defaultColor: string;

  @Input('mcClearInput') highlightColor = '';

  xElement: any = null;

  constructor(private el: ElementRef) { }

  @HostListener('mouseenter') onMouseEnter() {
    this.addClearIcon();
  }
  @HostListener('keydown') onKeyDown() {
    this.addClearIcon();
  }
 @HostListener('keyup') onKeyUp() {
    this.addClearIcon();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.removeClear();
  }

  // @HostListener('document:click', ['$event']) clickedOutside($event){
  //   // here you can hide your menu
  //  // console.log($event);
  // }


  clearText() {
    console.log('clear text called');
    this.el.nativeElement.value = '';
  }
  private addClearIcon() {
    if (this.el.nativeElement.value != undefined &&
      this.el.nativeElement.value != null &&
      (this.el.nativeElement.value as string).length > 0) {
      // "<i class="icon-remove-sign"></i> icon-remove-sign"
      // this.el.nativeElement.classList.add('clearable');
      // $(this)[tog(this.offsetWidth-18 < e.clientX-this.getBoundingClientRect().left)]('onX');
      if (this.xElement == null) {
        // this.el.nativeElement.nextSibling != undefined &&
        // this.el.nativeElement.nextSibling.classList != undefined) {
        // if (!this.el.nativeElement.nextSibling.classList.contains('clearIcon')) {
          const rect: ClientRect = this.el.nativeElement.getBoundingClientRect();
          const positionStyle = ('left:' + (rect.left - 22) + 'px;top:3px');
          const tmp = '<span ng-click=\"clearText()\" class="clearIcon" style=" ' + positionStyle + '">x</span>';
          this.el.nativeElement.insertAdjacentHTML('afterend', tmp);
          this.xElement = this.el.nativeElement.nextSibling;
        } else {
          this.el.nativeElement.nextSibling.classList.remove('clearIcon-hide');
        }

    } else {
      this.removeClear();
    }
  }

  private removeClear() {
    if (this.el.nativeElement.nextSibling.className == 'clearIcon') {
      this.el.nativeElement.nextSibling.classList.add('clearIcon-hide');
    }
  }

}


/*
import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[mcClearInput]'
})
export class ClearInputDirective {

  constructor(private el: ElementRef) {
  //  console.log(el);
  //  console.log('INSIDE');
    el.nativeElement.style.backgroundColor = 'yellow';
 }

   @Input() defaultColor: string;

   @Input('mcClearInput') highlightColor: string;

   @HostListener('mouseenter') onMouseEnter() {
    this.highlight(this.highlightColor || this.defaultColor || 'red');
   }

   @HostListener('mouseleave') onMouseLeave() {
     this.highlight(null);
   }

   private highlight(color: string) {
     this.el.nativeElement.style.backgroundColor = color;
   }

}
*/



