<section class="section" [ngClass]="{'is-hidden': studiesFound}">
    <div class="columns">
        <div class="column">
            <h2 class="has-text-weight-bold">
                No studies found.
            </h2>
        </div>
    </div>
</section>

<div class="search-result" [ngClass]="{'is-hidden': !studiesFound}">

    <div class=" form-table-container">
        <table class="table is-fullwidth is-striped" ngClass="{{config.className || ''}}" role="grid">
            <thead>
                <tr role="row" class="is-selected">
                    <ng-container *ngFor="let column of columns">
                        <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}" (sortChanged)="onChangeTable($event)"
                            ngClass="{{column.className || ''}}">
                            <span> {{column.title}}</span>
                            <span *ngIf="config && column.sort" class="sort  is-text-centered">
                                <span *ngIf="column.sort === 'desc'">
                                    <i class="fas fa-sort-down"></i>
                                </span>
                                <span *ngIf="column.sort === 'asc'">
                                    <i class="fas fa-sort-up"></i>
                                </span>
                            </span>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of rows">
                    <td>
                        <a (click)="goToStudyLanding(row)">
                            <span class="is-size-6 has-text-weight-bold ">
                                {{row.studyNumber}}
                            </span>
                        </a>                    
                    </td>
                    <td>
                        <span> {{row.status.studyStatusDesc}}</span>
                    </td>
                    <td>
                        <span> {{row.studyType}}</span>
                    </td>
                    <td>
                        <span> {{row.acronym}}</span>
                    </td>
                    <td>
                        <span> {{row.longDescription}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>