import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PatientErrorDisplayArg } from '../model/patient-error-display-arg';
import { UiLayoutElement } from '../model/ui-layout-element';
import { ErrorNavigationService } from '../services/error-navigation.service';

@Component({
  selector: 'mc-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent implements OnInit, OnDestroy {

  /**
   * The input for building the component and UI
   */
  @Input() errorDisplayArg: PatientErrorDisplayArg;

  /**
   * tells whether comments are filled in the textarea on the form checklist.
   */
  isCommentFilled:boolean = false;

  /**
   * Indicator to show / hide the error message callout
   */
  showErrorCallout = true;
  subscriptions: Subscription[] = [];


  @ViewChild('errorContainer') containerDiv: ElementRef

  constructor(
    private errorNavigationService: ErrorNavigationService
  ) {
    this.subscriptions.push(this.errorNavigationService.uiElementClicked$
      .subscribe(uiElement => {
        this.handleNavClicked(uiElement);
      }))
  }

  ngOnInit() {
    console.log('what is going on?')
    if (this.errorDisplayArg) {
      if (this.errorDisplayArg.errorCommentModel &&
        this.errorDisplayArg.errorCommentModel.caseFormVariableComment == null) {
          this.errorDisplayArg.errorCommentModel.caseFormVariableComment = {
            caseFormVariableCommentId: -1,
            studyFormVariableId: this.errorDisplayArg.errorCommentModel.formVariableId,
            commentText: '',
            isUpdated: false,
            overrideFlag: false,
            tupleVersion: 0
        }
      }
    }
    // console.log("Display Arg ", this.errorDisplayArg);
    // keep minimized if override + comment error
    if (this.shouldDisplayOverride()) {
      this.showHideErrorMessage(false);
    }

  }

  /**
   * This will change the color of the comments sections.
   * 
   * @param comment - comments entered on the textarea
   */
  onErrorModify($event){
    const comment = $event.target.value;
    if(comment !== "" && comment != null){
      this.errorDisplayArg.isCorrectionTile = false;
    }else{
      this.errorDisplayArg.isCorrectionTile = true;
    }
  }

  /**
  * Destroy implementation - closes all the subscriptions
  */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }

  /**
   * Handles the click on the navigation
   * @param uiElement the Ui Element that was navigated to
   */
  handleNavClicked(uiElement: ElementRef): void {
    if (this.shouldDisplayOverride()) {
      const isMyNavElement: boolean =
        (uiElement == this.errorDisplayArg.currentError.node.element.nativeElement);
      this.showHideErrorMessage(isMyNavElement);
    }
  }


  /**
   * Allows showing / hiding of the callout
   * @param show true if the callout should be displayed
   */
  showHideErrorMessage(show: boolean) {
    this.showErrorCallout = show;
  }

  /**
   * Navigates to the previous or next element
   * @param $event The event that was raised when this method was called
   * @param uiElement The element to navigate to
   */
  navigateTo($event: Event, uiElement: UiLayoutElement) {
    if ($event) {
      $event.stopPropagation();
    }
    if (uiElement && uiElement.node && uiElement.node.element &&
      uiElement.node.element.nativeElement) {
      this.errorNavigationService.setUiElementClicked(uiElement.node.element.nativeElement);
      //  console.log("UI Element to navigate to", uiElement.node.element.nativeElement)
      uiElement.node.element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  /**
   * Navigates to the ui element designated at the finish html
   */
  goToFinish() {
    if (this.errorDisplayArg.finishElement) {
      this.errorNavigationService.setUiElementClicked(this.errorDisplayArg.finishElement.nativeElement);
      this.errorDisplayArg.finishElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // this.selectedContentDiv.nativeElement.scrollTop += 30;
    }
  }


  doNavigate(uiElement: ElementRef): void {
    uiElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  /**
   * True if the comment capture section should be displayed for override
   */
  shouldDisplayOverride(): boolean {
    if (this.errorDisplayArg && this.errorDisplayArg.errorCommentModel) {
      return (this.errorDisplayArg.errorCommentModel != undefined &&
        this.errorDisplayArg.errorCommentModel != null);
    }
    return false;
  }


}
