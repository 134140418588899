import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable, OnDestroy } from '@angular/core';
import * as globalConst from 'app/common/model/activity-name-constants';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, publishReplay, refCount, tap } from 'rxjs/operators';
import { AppConfigService } from '../../app-config/app-config.service';
import { AppUser } from '../model/app-user';
import { Person } from '../model/person';
import { UserAccess } from '../model/user-access';
import { ValidUser } from '../model/valid-user';
import { BaseService } from './base-service';



@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService implements OnDestroy {

  subscriptions: Subscription[] = [];
  public RESET_USER_ID = 'ORIGINAL';
  /**
   * Get the Authenticated User
   * @returns Observable of user object
   */
  public authenticatedUserEntity: AppUser = null;
  /**
   * Get the Current User
   * @returns Observable of user object
   */
  public appUserEntity: AppUser = null;
  /**
    * groups committee dropdowns for the org
    */
  groupedPersons: Map<number, Person> = new Map<number, Person>();
  groupedPersonObservables: Map<number, Observable<any>> = new Map<number, Observable<any>>();
  errorPersonPkList: number[] = [];

  private validUser: ValidUser = new ValidUser(false, 'test');
  private tempAppUserObservable: Observable<any>;

  private readonly USER_ACCESS_LIST: string = 'userAccessList';

  /**
   * Get the Current User's activities
   * https://dev.ccauth.mayo.edu/api/v1/users/{userid}/activities
   * @returns Observable of user-access object
   */
  private _userAccessList: UserAccess[] = null;
  public get userAccessList() {
    if (!this._userAccessList && localStorage.getItem(this.USER_ACCESS_LIST)) {
      this._userAccessList = JSON.parse(localStorage.getItem(this.USER_ACCESS_LIST)) as UserAccess[];
    }

    return this._userAccessList;
  }
  public set userAccessList(list: UserAccess[]) {
    this._userAccessList = list;
    localStorage.setItem(this.USER_ACCESS_LIST, JSON.stringify(list));
  }
  private tempUserAccessListObservable: Observable<any[]>;



  constructor(private http: HttpClient,
    cfr: ComponentFactoryResolver,
    @Inject(DOCUMENT) private document: Document) {
    super(cfr);
  }

  /**
  * Destroy implementation - closes all the subscriptions
  */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    );
  }

  /**
     * Initializes the configuration by reading from the conf.json
     */
  public initializeUserData(): Promise<void> {
    return this.executeInitialize();
  }


  /**
   * Retrieves the Current appUser from the service
   */
  getAppUser(): Observable<AppUser> {
    if (this.appUserEntity) {
      // return Observable.of(this.appUserEntity)
      return of(this.appUserEntity);
    } else if (this.tempAppUserObservable) {
      return this.tempAppUserObservable;
    } else {
      this.tempAppUserObservable =
        this.http.get<AppUser>(`${AppConfigService.runConfig.serviceUrl}users`)
          .pipe(tap(result => {
            this.tempAppUserObservable = null;
            this.appUserEntity = result;
            return of(this.appUserEntity);
          })
            , catchError(err => {
              // Error encountered getting the user.
              // Return null to allow app.component.ts to route to error page
              return of(null);
            }))
          .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
          .pipe(refCount())
        ;
      return this.tempAppUserObservable;
    }
  }


  /**
     * True if the user has access to the activity
     * Usage example:
     * userService.hasAccess("activity-to-check", "update")
     * or
     * userService.hasAccess("activity-to-check")
     *
     * @param activityName The name of the activity to evaluate
     * @param accessType The access type to check
     */
  hasAccess(activityName: string, accessType: string = ''): boolean {
    if (activityName) {
      let evaluated = false;
      let allow = false;
      if (accessType && accessType.trim().length > 0) {
        if (accessType.toLowerCase() == 'update') {
          allow = this.hasModifyAccess(activityName);
          evaluated = true;
        }
      }
      if (!evaluated) {
        allow = this.hasMinimumAccess(activityName);
      }
      return allow;
    } else {
      return false;
    }
  }

  /**
   * Returns true if the user has any access (activity defined not specific to study)
   * @param activityName The name of the activity to evaluate
   */
  public hasMinimumAccess(activityName: string): boolean {
    // specify studyId = null indicating activity is not specific to one study but covers all studies
    return this.hasMinimumAccessByStudy(activityName, null);
  }

  /**
   * Returns true if the user has any access (activity specific to study)
   * @param activityName The name of the activity to evaluate
   */
  public hasMinimumAccessByStudy(activityName: string, studyId: number): boolean {
    const permissions = this.getUserPermissionsForActivity(activityName, studyId);
    if (!permissions) { return false; }

    for (const permission of permissions) {
      if (permission['accessType'] && permission['accessType'] != globalConst.ACCESS_LEVEL_NONE) {
        return true;
      }
    };

    return false;

  }

  /**
   * This is to check for read access only for the passed in activity.
   * 
   * @param  {string} activityName
   * @param  {number} studyId
   * @returns boolean
   */
  public isUserHasOnlyReadAccess(activityName: string, studyId: number): boolean {
    let permissions = this.getUserPermissionsForActivity(activityName, studyId);
    if (!permissions) return false;

    // Honor the higher of found permissions.  For example, if user is in two groups, one of which has READ and one of which has UPDATE, 
    // then honor the UPDATE
    let viewFound = false;
    let updateFound = false;
    for (let permission of permissions) {
      if (permission["accessType"] && permission["accessType"] == globalConst.ACCESS_LEVEL_UPDATE) {
        updateFound = true;
      }
      if (permission["accessType"] && permission["accessType"] == globalConst.ACCESS_LEVEL_VIEW) {
        viewFound = true;
      }
    };

    if (viewFound && !updateFound) {
      return true;
    }
    return false;
  }

  /**
   * Returns true if the user has read or update access for ALL studies (activity defined not specific to study)
   * @param activityName The name of the activity to evaluate
   */
  public hasReadAccess(activityName: string): boolean {
    // specify studyId = null indicating activity is not specific to one study but covers all studies
    return this.hasReadAccessByStudy(activityName, null);
  }

  /**
   * Returns true if the user has read or update access
   * @param activityName The name of the activity to evaluate
   */
  public hasReadAccessByStudy(activityName: string, studyId: number): boolean {
    const permissions = this.getUserPermissionsForActivity(activityName, studyId);
    if (!permissions) { return false; }

    for (const permission of permissions) {
      if (permission['accessType'] == globalConst.ACCESS_LEVEL_VIEW || permission['accessType'] == globalConst.ACCESS_LEVEL_UPDATE) {
        return true;
      }
    };

    return false;
  }


  /**
  * Returns true if the user has update access for ALL studies (activity defined not specific to study)
  * @param activityName The name of the activity to evaluate
  */
  public hasModifyAccess(activityName: string): boolean {
    // specify studyId = null indicating activity is not specific to one study but covers all studies
    return this.hasModifyAccessByStudy(activityName, null);
  }

  /**
   * Returns true if the user has update access
   * @param activityName The name of the activity to evaluate
   */
  public hasModifyAccessByStudy(activityName: string, studyId: number): boolean {
    return this.hasAccessByStudyAndLevel(activityName, studyId, globalConst.ACCESS_LEVEL_UPDATE);
  }

  /**
  * Returns true if the user has create access for ALL studies (activity defined not specific to study)
  * @param activityName The name of the activity to evaluate
  */
  public hasCreateAccess(activityName: string): boolean {
    // specify studyId = null indicating activity is not specific to one study but covers all studies
    return this.hasCreateAccessByStudy(activityName, null);
  }

  /**
* Returns true if the user has create access
* @param activityName The name of the activity to evaluate
*/
  public hasCreateAccessByStudy(activityName: string, studyId: number): boolean {
    return this.hasAccessByStudyAndLevel(activityName, studyId, globalConst.ACCESS_LEVEL_CREATE);
  }

  /**
   * Checks if the current user has access to the given activity name for all studies
   * @param activityName Activity Name to check
   * @returns if user has access to the given activity without checking study
   */
  hasDeleteAccess(activityName: string): boolean {
    return this.hasDeleteAccessByStudy(activityName, null);
  }

  /**
   * Checks if the current user has access to the given activity name for the given study
   * @param activityName Name of the activity to evaluate
   * @param studyId ID of the study that user is trying to access
   * @returns If the user has access to the given activity and study
   */
  hasDeleteAccessByStudy(activityName: string, studyId: number): boolean {
    return this.hasAccessByStudyAndLevel(activityName, studyId, globalConst.ACCESS_LEVEL_DELETE);
  }

  /**
   * Returns the Person entity for the user's primary key
   * @param userPk The user Primary key
   */
  getPersonForId(userPk: number): Observable<Person> {
    if (this.groupedPersons.get(userPk)) {
      return of(this.groupedPersons.get(userPk));
    } else {
      if (this.errorPersonPkList.indexOf(userPk) > -1) {
        return of({ 'pk': null, 'ctepId': null, 'firstName': '', 'lastName': '' + userPk, 'email': null, 'fax': null, 'phone': null, 'personTypeId': null });
      } else {
        return this.getPersonUser(userPk);
      }
    }
  }

  /*Returns the patient landing page access
  */
  public getLandingPageAccess(caseDetailId: number): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.getPatientlandingPageAccess(caseDetailId).subscribe(validUserStatus => {
        observer.next(validUserStatus.valid);
      });
    });
  }

  /**
   * Logs a user out of the application
   */
  public logout() {
    // Redirect to logout page
    this.document.location.href = `${AppConfigService.runConfig.logoutUrl}`;
  }

  /**
   * Retrieves the configuration to be used to connect to the services
   */
  private initializeServiceConfig(): Promise<any> {
    return of(AppConfigService.runConfig).toPromise();
  }


  /**
   * Retrieves the user information from the services
   */
  private initializeGetUser(): Promise<any> {
    return this.http.get<AppUser>(`${AppConfigService.runConfig.serviceUrl}user`)
      .toPromise()
      .then(userAccessResult => {
        this.appUserEntity = userAccessResult;
        this.authenticatedUserEntity = userAccessResult;
        return userAccessResult;
      })
      .catch(err => {
        // Error encountered getting the user.
        // Return null to allow app.component.ts to route to error page
        return null;
      });
  }

  /**
   * Retrieves the user access (activities) for this user / role
   */
  private initializeUserActivity(): Promise<any> {
    if (this.appUserEntity == null || this.appUserEntity.userPk == null) {
      // There is no User to lookup, so just resolve the promise
      return Promise.resolve();
    } else {
      return this.http.get<string[]>(`${AppConfigService.runConfig.serviceUrl}user/` + this.appUserEntity.userPk + '/activities')
        .toPromise()
        .then(result => {
          this.parseUserActivities(result);
          return result;
        });
    }
  }

  /**
   * main function to handle the chaining of all the calls/
   */
  private executeInitialize(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.initializeServiceConfig().then(() => {
        this.initializeGetUser().then((user: any) => {
          if (!user) {
            reject();
          } else {
            this.initializeUserActivity()
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          }
        });
      });
    });
    return promise;
  }

  /**
  * Returns the UserAccess (permission level) for the activity
  * note: an activity in the list with a null studyid indicates authorized to all studies for this activity
  * @param activityName The name of the activity to be evaluated
  */
  private getUserPermissionsForActivity(activityName: string, studyId: number): UserAccess[] {   // | Observable<UserAccess[]>
    if (this.userAccessList && this.userAccessList.length > 0) {
      const matches = this.userAccessList.filter(eachEntry => eachEntry.activityName == activityName && (eachEntry.studyId == studyId || eachEntry.studyId == null));
      if (matches) {
        return matches;
      }
    }
  }

  /**
   *
   */
  private getUserAccessList(userPk: number): Observable<UserAccess[]> {
    if (this.userAccessList) {
      return of(this.userAccessList);
    }

    if (this.tempUserAccessListObservable) {
      return this.tempUserAccessListObservable;
    }

    this.tempUserAccessListObservable =
      this.http.get<string[]>(`${AppConfigService.runConfig.serviceUrl}user/` + userPk.toString() + '/activities')
        .pipe(tap(result => {

          this.tempUserAccessListObservable = null;
          this.parseUserActivities(result);
          return of(this.userAccessList);
        })
          , catchError(err => {
            // Error encountered getting the user.
            // Return null to allow app.component.ts to route to error page
            return of(null);
          }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount())
      ;
    return this.tempUserAccessListObservable;
  }

  public clearUserAccessList(): void {
    if (this.userAccessList) {
      this.userAccessList = null;
      localStorage.removeItem(this.USER_ACCESS_LIST);
    }
  }

  /**
   * Parse the list of strings returned from activities service into list of objects with activityName, accessType, studyId
   * an activity string is of the form "study:view:1234" - that is, activityName:accessType:studyId
   * an activity string of the form "study:view" indicates authorization applies to all studies
   */
  private parseUserActivities(activities: string[]) {
    if (activities.length > 0) {
      this.userAccessList = [];
      activities.forEach(activity => {
        if (activity && activity.indexOf(':') > 0) {
          const userAccessObj: UserAccess = { 'activityName': '', 'accessType': '', 'studyId': null };
          const parts: Array<string> = activity.split(':');
          if (parts) {
            if (parts.length >= 1) {
              userAccessObj.activityName = parts[0];
            }
            if (parts.length >= 2) {
              userAccessObj.accessType = parts[1];
            }
            if (parts.length == 3) {
              // + converts string to number, if for some reason null will be assigned 0
              userAccessObj.studyId = + parts[2];
            }
            this.userAccessList.push(userAccessObj);
          }
        }

      });

      localStorage.setItem(this.USER_ACCESS_LIST, JSON.stringify(this.userAccessList));
    }
  }

  /**
   * Gets the person id and pushes it into the GroupedPerson array
   */
  private getPersonUser(userPk: number): Observable<Person> {
    if (this.groupedPersons.get(userPk)) {
      const match = this.groupedPersons.get(userPk);
      return of(match);
    } else if (this.groupedPersonObservables.get(userPk)) {
      return this.groupedPersonObservables.get(userPk);
    } else {
      const tempPersonObservable =
        this.http.get<Person>(`${AppConfigService.runConfig.serviceUrl}users` + '/' + userPk)
          .pipe(tap(result => {
            this.groupedPersonObservables.delete(userPk);
            this.groupedPersons.set(userPk, result);
            return of(this.groupedPersons.get(userPk));
          })
            , catchError(err => {
              if (this.errorPersonPkList.indexOf(userPk) == -1) {
                this.errorPersonPkList.push(userPk);
              }
              throw err;
            }))
          .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
          .pipe(refCount());
      this.groupedPersonObservables.set(userPk, tempPersonObservable);
      return this.groupedPersonObservables.get(userPk);
    }
  }

  /* Returns the patient landing page access
  * @param caseDetailId to search location and roles
  */
  private getPatientlandingPageAccess(caseDetailId: number): Observable<ValidUser> {
    return this.http.get<ValidUser>(`${AppConfigService.runConfig.serviceUrl}patients/network-role-check/case-details/` + caseDetailId);
  }

  /**
   * Checks if the current user has access to the given activity name and level for the given study
   * @param activityName Name of the activity to evaluate
   * @param studyId ID of the study user is trying to access
   * @param accessLevel Level of access the user needs of the given activity
   * @returns if the user has access to the given activity, study, and activity level
   */
  private hasAccessByStudyAndLevel(activityName: string, studyId: number, accessLevel: string): boolean {
    const permissions = this.getUserPermissionsForActivity(activityName, studyId);
    if (!permissions) { return false; }

    for (const permission of permissions) {
      if (permission.accessType === accessLevel) {
        return true;
      }
    };
    return false;
  }
}
