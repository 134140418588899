import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EmailModalComponent } from 'app/common/modal/email-modal/email-modal.component';
import * as globalConst from 'app/common/model/app-constants';
import { HeaderBar } from 'app/common/model/header-bar';
import { HomePageDisplay } from 'app/common/model/home-page-display';
import { NotificationTileDetail } from 'app/common/model/notification-tile-detail';
import { PreviewPanelModel } from 'app/common/model/preview-panel-model';
import { Subscription } from 'rxjs';
import { SearchCriteria } from '../common/model/search-criteria';
import { PreviewComponent } from '../common/preview/preview.component';
import { UserService } from '../common/services/user.service';





@Component({
  selector: 'mc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  userName = '';

  previewExpanded = false;

  // previewArg: {displayValue : boolean  } = {"displayValue" : false};
  // previewPanelHeightArg: {heightValue : boolean  } = {"heightValue" : true};
  protocolSearchCriteria: SearchCriteria = null;
  tileType: string;

  formTitle = 'Home';
  displayUnderConstruction = false;
  displayRecentNotification = false;
  displayRecentPatient = false;
  displaySearchResults = false;
  previewTitle = 'Preview Pane';
  contentTypeIrbApprovals = 'irbApprovals';
  contentTypeRecentStudies = 'recentStudies';
  contentTypeRecentPatients = 'recentPatients';
  contentType = 'construction';

  /**
   * Reference to the child modal component for displaying notification email
   */
  @ViewChild(EmailModalComponent) private modalComponent: EmailModalComponent;

  @ViewChild(PreviewComponent) private previewComponent: PreviewComponent;


  /**
   * Model to drive the orientation and horizontal/vertical states of the preview window
   */
  previewModelHome: PreviewPanelModel = new PreviewPanelModel();
  homeLandingPageEnum: number = globalConst.LANDING_PAGE_ENUM_HOME;

  /**
   * model for the header bar
   */
  headerModel: HeaderBar = new HeaderBar('Home', '', false, '', false);



  subscriptions: Subscription[] = [];

  isProtocolSearchQuery = true;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.userService.getAppUser()
      .subscribe(user => {
        this.userName = user.userId;
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    );
  }


  /**
   * This will assign the preview Title based on type of tile
   * and toggle it when clicked.
   *
   * @param  {HomePageDisplay} displayType - type of tile
   */
  tileClicked(displayType: HomePageDisplay) {
    this.tileType = displayType.displayType;
    if (this.tileType == globalConst.DISPLAY_RECENT_STUDIES) {
      this.previewTitle = 'Recent Studies';
      this.contentType = 'construction';
      this.displayUnderConstruction = true;
      this.displayRecentNotification = false;
      this.displayRecentPatient = false;
      this.displaySearchResults = false;
    }
    if (this.tileType == globalConst.DISPLAY_AVAILABLE_STUDIES) {
      this.previewTitle = 'Available Studies';
      this.contentType = 'available';
      this.displayUnderConstruction = true; // display format is same, so use this indicator
      this.displayRecentNotification = false;
      this.displayRecentPatient = false;
      this.displaySearchResults = false;
    }

    if (this.tileType == globalConst.DISPLAY_RECENT_NOTIFICATIONS) {
      this.previewTitle = 'Recent Notifications';
      this.displayRecentNotification = true;
      this.displayUnderConstruction = false;
      this.displayRecentPatient = false;
      this.displaySearchResults = false;
    }
    if (this.tileType == globalConst.DISPLAY_RECENT_PATIENTS) {
      this.previewTitle = 'In Progress Registrations';
      this.displayRecentNotification = false;
      this.displayUnderConstruction = false;
      this.displayRecentPatient = true;
      this.displaySearchResults = false;
    }
    this.previewModelHome.isCollapsed = false;
    console.log('tileClickd', this.contentType);

  }

  /**
   * This will display the returned results in the preview pane
   * and also assign the preview pane name with the search results.
   *
   * @param  {} $event - capture emitted event here
   */
  public doSearch($event) {
    this.protocolSearchCriteria = $event;
    this.previewTitle = ' Search Results';
    if (this.protocolSearchCriteria) {
      let pref = 'Study ';
      if (this.protocolSearchCriteria.searchType == globalConst.SEARCH_CRITERIA_TYPE_PATIENT) {
        pref = 'Patient ';
      }
      if (this.protocolSearchCriteria.searchType == globalConst.SEARCH_CRITERIA_TYPE_SUBJECT) {
        pref = 'Subject ';
      }
      if (this.protocolSearchCriteria.searchType == globalConst.SEARCH_CRITERIA_TYPE_TRACKING_NUMBER) {
        pref = 'Tracking Number ';
      }
      this.previewTitle = pref + this.previewTitle;
    }
    this.displayUnderConstruction = false;
    this.displayRecentNotification = false;
    this.displayRecentPatient = false;
    this.displaySearchResults = true;
    this.previewModelHome.isCollapsed = (this.protocolSearchCriteria == null);
  }

  /**
   * Returns the search to the home page
   */
  returnToSearch() {
    this.previewModelHome.isCollapsed = true;
  }


  /**
   * event captured from the preview pane selection and calling modal component with the selected id to populate data.
   * @param  {NotificationTileDetail} selection
   */
  onEmailDetailSelected(selection: NotificationTileDetail) {
    this.modalComponent.showModal = true;
    this.modalComponent.getEmail(selection.emailLogId);
  }

  /** custom handling of the preview for the home page********************* */
  /**
     * True if the orientation is vertical and the state is collapsed
     */
  isCollapsedVertical(): boolean {
    return this.previewModelHome.isCollapsed && this.previewModelHome.isVertical;
  }



  /**
   * True if the orientation is horizontal and is collapsed
   */
  isCollapsedHorizontal(): boolean {
    return (this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }


  /**
   * True if the orientation is vertical and the state is Expanded
   */
  isExpandedVertical(): boolean {
    return (!this.previewModelHome.isCollapsed && this.previewModelHome.isVertical);
  }



  /**
   * True if the orientation is horizontal and is expanded
   */
  isExpandedHorizontal(): boolean {
    return (!this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }

  /** custom handling of the preview for the home page********************* */

}
