<div class="patient-preview">
  <div *ngIf="currentRegModel()">

    <div class="columns">
      <div class="column is-6">
        <h3>Registration Details</h3>
        <table class="table is-fullwidth has-no-bottom-padding">
          <tbody>
            <tr *ngIf="currentRegModel().openTrackingNum">
              <td class="display-label">Open Tracking Id:</td>
              <td>{{currentRegModel().openTrackingNum }}</td>
            </tr>
            <tr *ngIf="!currentRegModel().openTrackingNum">
              <td class="display-label">Tracking Id:</td>
              <td>{{currentRegModel().trackingNum }}</td>
            </tr>
            <tr *ngIf="currentRegModel().assignedPatientId">
              <td class="display-label">Subject Id:</td>
              <td>{{currentRegModel().assignedPatientId }}</td>
            </tr>
            <tr>
              <td class="display-label">Assigned Arm:</td>
              <td>{{currentRegModel().assignedArm }}</td>
            </tr>
            <ng-container *ngIf="doseLevelVars.length > 0">
              <ng-container *ngFor="let eachVar of doseLevelVars ; let rowIdx = index">
                <tr>
                  <td class="display-label">Dose Level</td>
                  <td>{{eachVar.nameValue}}</td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="shouldDisplayTacTad()">
              <tr>
                <td class="display-label">TAC:</td>
                <td>{{currentRegModel().tacTad.code}}</td>
              </tr>
            </ng-container>
            <ng-container *ngIf="shouldDisplayTacTad()">
              <tr>
                <td class="display-label">TAD:</td>
                <td>{{currentRegModel().tacTad.name}}</td>
              </tr>
            </ng-container>
            <ng-container *ngIf="shouldDisplaySgc()">
              <tr>
                <td class="display-label">SGC:</td>
                <td>{{currentRegModel().sgc.code}}</td>
              </tr>
            </ng-container>
            <ng-container *ngIf="shouldDisplayAssignedKits()">
              <tr>
                <td class="display-label">Assigned Kits:</td>
                <td>{{currentRegModel().doubleBlindResponse }} </td>
              </tr>
            </ng-container>
            <tr>
              <td class="display-label">Enrollment Date:</td>
              <td> {{currentRegModel().enrollmentDate | date:'medium' }} </td>
            </tr>
            <ng-container *ngIf="currentRegModel().participatingRegSites">
              <h3>Participation – Registration</h3>
              <tr *ngFor="let item of currentRegModel().participatingRegSites; let i=index">
                <td class="display-label">
                  <span>
                    {{item.name}}
                  </span>
                </td>
                <td>
                  <span>
                    {{item.nameValue}}
                  </span>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="currentRegModel().participatingRegPersons">

              <tr *ngFor="let item of currentRegModel().participatingRegPersons; let i=index">
                <td class="display-label">
                  <span>
                    {{item.name}}
                  </span>
                </td>
                <td>
                  {{item.nameValue}}
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="currentRegModel().participatingSites.length>0">
              <h3>Participation – Current</h3>
              <tr *ngFor="let item of currentRegModel().participatingSites; let i=index">
                <td class="display-label">
                  <span>
                    {{item.name}}
                  </span>
                </td>
                <td>
                  <span>
                    {{item.nameValue}}
                  </span>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="currentRegModel().participatingPersons">

              <tr *ngFor="let item of currentRegModel().participatingPersons; let i=index">
                <td class="display-label">
                  <span>
                    {{item.name}}
                  </span>
                </td>
                <td>
                  {{item.nameValue}}
                </td>
              </tr>
            </ng-container>
            <tr>
              <td colspan="2"></td>
            </tr>
          </tbody>

        </table>
        <h3>Subject Details</h3>
        <table class="table is-fullwidth has-no-bottom-padding">
          <tbody>
            <tr>
              <td class="display-label">
                <span>Name</span>
              </td>
              <td class="">
                <span *ngIf="shouldDisplayLastName()">
                  {{currentRegModel().lastName}}
                </span>
                <span *ngIf="shouldDisplayFirstName()">
                  {{currentRegModel().firstName}}
                </span>
                <span *ngIf="shouldDisplayMiddleName()">
                  {{currentRegModel().middleName}}
                </span>
              </td>
            </tr>

            <tr *ngIf="shouldDisplayGender()">
              <td class="display-label">
                <span>Gender</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().gender}}
                </span>
              </td>
            </tr>

            <tr *ngIf="shouldDisplayRace()">
              <td class="display-label">
                <span>Race</span>
              </td>
              <td class="" *ngFor="let item of currentRegModel().races; let i=index">
                <span>
                  {{item}}
                </span>

              </td>
            </tr>

            <tr *ngIf="shouldDisplayEthnicity()">
              <td class="display-label">
                <span>Ethnicity</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().ethnicity}}
                </span>
              </td>
            </tr>
            <tr *ngIf="shouldDisplayDOB()">
              <td class="display-label">
                <span>Date of Birth</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().dateOfBirthString}}
                </span>
              </td>
            </tr>
            <tr *ngIf="shouldDisplayZipCode()">
              <td class="display-label">
                <span>Zip</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().zipCode}}
                </span>
              </td>
            </tr>
            <tr *ngIf="shouldDisplayCountry()">
              <td class="display-label">
                <span>Country</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().country}}
                </span>
              </td>
            </tr>

            <tr *ngIf="shouldDisplayMedDra()">
              <td class="display-label">
                <span>MED Dra</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().medDra}}
                </span>
              </td>
            </tr>
            <tr *ngIf="shouldDisplayPaymentMethod()">
              <td class="display-label">
                <span>Method of Payment</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().methodOfPayment}}
                </span>
              </td>
            </tr>
            <tr *ngIf="shouldDisplayOtherSubjectId()">
              <td class="display-label">
                <span>Other Subject ID</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().otherSubjectId}}
                </span>
              </td>
            </tr>

            <tr *ngIf="shouldDisplayLinkedTo()">
              <td class="display-label">
                <span>Linked To</span>
              </td>
              <td class="">
                <span *ngFor="let item of currentRegModel().linkedSubjectIds ; let isLast=last">
                  {{item}}{{isLast ? '' : ', '}}
                </span>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="column is-6">
        <h3>Study Details</h3>
        <table class="table is-fullwidth has-no-bottom-padding">
          <tbody>
            <tr>
              <td class="display-label">
                <span>Study Number</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().studyNumber}}
                </span>
              </td>
            </tr>
            <tr>
              <td class="display-label">
                <span>Long Description</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().studyDescription}}
                </span>
              </td>
            </tr>
            <tr>
              <td class="display-label">
                <span>Schema Step</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().currentEventName}}
                </span>
              </td>
            </tr>
            <tr>
              <td class="display-label">
                <span>Version</span>
              </td>
              <td class="">
                <span>
                  {{currentRegModel().versionNum}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="shouldDisplayEnrolledAncillaries()">
          <h3>Ancillary Registration(s)</h3>
          <table class="table is-fullwidth has-no-bottom-padding">
            <tbody>
              <tr *ngFor="let row of this.regPreview.enrolledAncillaries">

                <td class="display-label">
                  <span>
                    {{row.study.studyNumber}}:
                  </span>
                </td>
                <td>
                  <span>
                    {{row.study.longDescription}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- shouldDisplayEnrolledAncillaries -->

    </div>
  </div>
  <div *ngIf="currentRegModel() && currentRegModel().patientForms">
    <!-- {{currentRegModel().formResponses | json}} -->

    <div *ngFor="let eachForm of currentRegModel().patientForms ; let formIdx = index">
      <h3>
        {{eachForm.formName}}
      </h3>
      <table class="table is-striped is-fullwidth form-table ">
        <tbody>
          <ng-container *ngFor="let row of eachForm.formVariables ; let rowIdx = index">
            <tr>
              <td class="has-left-padding">
                <div class="columns">
                  <div class="column is-7  ">
                    <div class="question-pad ql-editor" *ngIf="row && row.name" [innerHTML]="row.name| safeHtml">
                    </div>
                  </div>
                  <div class="column is-5 control">
                    <div>
                      {{row.nameValue}}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>


    </div>


  </div>