<div class="columns" *ngIf="searchCriteria">
    <div class="column">
      <section class="section" *ngIf="noResultsFound">
        <div class="container">
          <div class="subtitle has-text-centered">
            <h2 class="has-text-weight-bold">
              No results found. Please check your search criteria.
            </h2>
          </div>
        </div>
      </section>
  
      <div class="search-result" *ngIf="results && results.length > 0">
        <div class="form-table-container">
          <table class="table is-fullwidth is-striped" ngClass="{{config.className || ''}}" role="grid">
            <thead>
            <tr class="is-selected" role="row">
              <ng-container *ngFor="let column of columns">
                <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}" (sortChanged)="onChangeTable($event)"
                    ngClass="{{column.className || ''}}">
                  <span> {{column.title}}</span>
                  <span *ngIf="config && column.sort" class="sort is-text-centered">
                    <span *ngIf="column.sort === 'desc'">
                      <i class="fas fa-sort-down"></i>
                    </span>
                    <span *ngIf="column.sort === 'asc'">
                      <i class="fas fa-sort-up"></i>
                    </span>
                  </span>
                </th>
              </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr >
                <td *ngFor="let column of columns; let i=index">
                  <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" class="form-control"
                      (tableChanged)="onChangeTable(config)" />
                </td>
            </tr>
            <tr *ngFor="let row of rows">
                <td>
                    <a (click)="goToPatientLanding($event, row)">
                          <span class="has-text-weight-bold">
                              {{row.patient.trackingNum}}
                          </span>
                    </a>
                  </td>
              <td>
                <a (click)="goToPatientLanding($event, row)">
                      <span class="has-text-weight-bold">
                          {{row.patient.patientId}}
                      </span>
                </a>
              </td>
  
              <td>
                <span>{{row.patient.lastName}} {{row.patient.firstName}} {{row.patient.middleName}}</span>
              </td>
              <td>
                  <span>{{row.patient.dateOfBirthString}}</span>
                </td>
              <td>
                <span> {{row.event.schemaEventDisplayName}} </span>
              </td>
              <td>
                <span>{{row.dataEntryPerson.lastName}} {{row.dataEntryPerson.firstName}}</span>
              </td>
           
            </tr>
  
            </tbody>
  
          </table>
        </div>
      </div>
    </div>
  
  </div>
  
