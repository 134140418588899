import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { Observable, of, Subscription } from 'rxjs';
import {  map, startWith } from 'rxjs/operators';
import { Organization } from '../model/organization';


@Component({
  selector: 'mc-organization-auto-complete',
  templateUrl: './org-auto-complete.component.html',
  styleUrls: ['./org-auto-complete.component.scss']
})
export class OrganizationAutoCompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() currentOrganizationName = '';
  @Input() isReadonly = false;
  @Input() showEditModeOnly = true;
  @Input() controlClass = '';
  @Input() cTepId = '';
  /**
   * True if the component is used for displaying org Name
   * False if it's used to display the CTEPID
   *  */
  @Input() isInputOrgName:boolean = true;
  @Output() organizationSelected = new EventEmitter<Organization>();

  public selectedOrgRow: any;

  showModal = false;
  showEditMode = false;
  searchClicked = false;
  cTepIdForModal = ''; // this.cTepId;
  orgNameForModal = '';

  modalItemSelected = false;

  formControl = new FormControl('');
  organizationOptions: Observable<Organization[]>;

  public organizationList: Organization[];
  subscriptions: Subscription[] = [];

  /**
   * Property name used for display.
   * Have put it here since it's likely going to change
   */
  private propertyOrgDisplay = 'abrev';


  constructor(private studySetupService: StudySetupService) {
  }

  ngOnInit() {
    
    //Set org if already exists
    this.formControl.setValue(this.currentOrganizationName)
    this.showEditMode = this.showEditModeOnly;
    this.getOrganizations();
  }
  ngAfterViewInit() {
    this.setModalValues();
  }

  /**
   * Get organizations
   */
  getOrganizations() {
    this.subscriptions.push(this.studySetupService.getOrganizations()
      .subscribe(orgs => {
        this.organizationList = orgs;

        this.organizationOptions = this.formControl.valueChanges.pipe(
          startWith<string | Organization>(''),
          map(value => typeof value === 'string' ? value : (this.isInputOrgName  ? value.abrev :value.ctepId)),
          map(name => name && name.trim()!=='' ? this.filter(name) : [] )
        );

     }));
  }


  /**
   * Display orr or ctepId
   * @param org 
   * @returns 
   */
  displayFn(org: any): string { 
    if(typeof org === 'string'){
     return org
    }
    return    this.isInputOrgName  ? org.abrev : org.ctepId  ;
  }


  setModalValues() {
    setTimeout(() => {
      this.orgNameForModal = this.currentOrganizationName;
      this.cTepIdForModal = this.cTepId;
    }, 50);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions.length = 0;
  }

  /**
   * Returns the value of the Placeholder displayed on the input
   */
  getPlaceHolderValue(): string {
    if (this.isInputOrgName) {
      return 'Organization Name';
    } else {
      return 'CTEP ID';
    }
  }

  onSearch(query: string) {
    this.searchClicked = true;
  }

  handleSelectSuggestion( event: MatOptionSelectionChange ) {
    if(event && event.isUserInput){
      this.selectSuggestion(event?.source?.value);
    }
  }

  selectSuggestion(suggestion: Organization) {
    let name = '';
    if (suggestion == null) {
      suggestion = { abrev: '', ctepId: '', name: '', orgNumber: '', status: '', type: '' };
    }
    if (suggestion) {
      this.currentOrganizationName = suggestion[this.isInputOrgName ? this.propertyOrgDisplay : 'ctepId'];
      this.cTepId = suggestion.ctepId;
      name = suggestion.name;
    } else {
      this.currentOrganizationName = '';
      this.cTepId = '';
    }
    this.setModalValues();
    this.organizationSelected.emit(suggestion);
    this.onSearch(name);
  }

  selectedOrganizationRow(row: Organization) {
    this.selectedOrgRow = row;
  }

  organizationSearchModal(show, $event, selected) {
    this.showModal = show;
    this.searchClicked = true;
    if (!show && selected) {
      this.selectSuggestion(this.selectedOrgRow);
      this.searchClicked = false;
    }
    $event.preventDefault();

  }

  modalItemSelectedEvent(isSelected: boolean) {
    this.modalItemSelected = isSelected;
  }

  /**
   * Filter orgs from organizationList
   * @param name 
   * @returns 
   */
  private filter(name: string): Organization[] {
    const filterValue = name.toLowerCase();
    return this.organizationList.filter(option => this.isInputOrgName ? option.abrev?.toLowerCase().indexOf(filterValue) === 0 : option?.ctepId?.toLowerCase().indexOf(filterValue)===0 );
  }

}
