import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChildren } from '@angular/core';
import { ComponentInputModel } from 'app/common/model/component-input-model';
import { FormsDashboardService } from 'app/common/services/forms/forms-dashboard.service';
import { Subscription } from 'rxjs';
import { LibraryVariable } from '../model/library-variable';


@Component({
  selector: 'mc-variable-lookup',
  templateUrl: './variable-lookup.component.html',
  styleUrls: ['./variable-lookup.component.scss']
})
export class VariableLookupComponent implements OnInit, OnChanges, OnDestroy {

  /**
  * The input parameter that displays the modal
  */
  @Input() inputModel: ComponentInputModel;

  @Input() libraryVariables: Array<LibraryVariable> = [];

  @Input() selectedLibraryVaribleId = 0;
  /**
   * The event emitter to tranfer the selected variables on the form
   */
  @Output() variableSelected = new EventEmitter<LibraryVariable>();



  /**
   * The variables selected for display on the host
   */
  selectedVariables: LibraryVariable[] = [];

  selectedVariableId = 0;


  subscription: Subscription[] = [];

  @ViewChildren('varRow', {read: ElementRef}) viewContentDivs: QueryList<any>;
  private selectedContentDiv: any = null;

  /************* START - Table filtering functionality******************** */
  public showFilterRow: Boolean = true;
  public rows: Array<any> = [];
  public page = 1;
  public itemsPerPage = 5;
  public maxSize = 5;
  public numPages = 2;
  public length = 0;


  /**
   * The static array of columns that define the columns displayed on the table
   */
  public columns: Array<any> = [
    // {
    //   title: 'None', className: [],
    //   display: false,
    //   filtering: false,
    //   name: 'select', sort: ''
    // },
    {
      title: 'Variable #', className: ['office-header', 'text-success'],
      display: true,
      filtering: { filterString: '', placeholder: 'Filter Variable # ' },
      name: 'variableId', sort: ''
    },
    {
      title: 'Alternate Name', name: 'variableAlternateName',
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter Alternate Name' }
    },
    {
      title: 'Data Type', name: 'variableDataTypeName',
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter Data Type' }
    },
    {
      title: 'Description', name: 'variableDesc',
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter Description' }
    }
  ];

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['is-striped', 'is-bordered']
  };

  constructor(private renderer: Renderer2,
    private formsDashboardService: FormsDashboardService) {

  }


  ngOnInit() {
    this.length = this.libraryVariables.length;
  }


  ngOnDestroy() {
    this.subscription.forEach(subx => {
      subx.unsubscribe();
    })
    this.subscription = [];
  }

  /**
   * Handler for the OnChanges interface
   * @param changes The changes that were made after initiliazation
   */
  ngOnChanges(changes: SimpleChanges) {
  //  console.log("Changes made to this => ", changes)
    if (changes.libraryVariables && changes.libraryVariables.currentValue) {
    //  console.log("Calling on change table")
      this.libraryVariables = changes.libraryVariables.currentValue;
      this.onChangeTable(this.config);
      this.selectAndNavigate();
    }
    if (changes.selectedLibraryVaribleId && changes.selectedLibraryVaribleId.currentValue) {
      this.selectedVariableId = changes.selectedLibraryVaribleId.currentValue;
    }

    this.selectAndNavigate();
  }

  /**
   * Helper method to locate the row if an library variable was previously selected
   */
  selectVariableRow() {
    if (this.selectedLibraryVaribleId && this.selectedLibraryVaribleId > 0) {
      if (this.viewContentDivs && this.viewContentDivs.length > 0) {
        this.viewContentDivs.forEach((eachItem, itemIdx) => {
          const elem = this.renderer.selectRootElement(eachItem).nativeElement;
          if (elem.id && elem.id == this.selectedLibraryVaribleId + '') {
            this.selectedContentDiv = elem;
          }
        });
      }
    }
  }


  /**
   * Selects the selected library row and navigates to the selected row
   */
  selectAndNavigate(): void {
    this.selectVariableRow();
  //  console.log("Check for content Div", this.selectedVariableId, this.selectedContentDiv)
    if (this.selectedContentDiv) {
   //   console.log("Navigation to .....");
      this.selectedContentDiv.scrollIntoView(false, { behavior: 'smooth', block: 'center' });
      // this.selectedContentDiv.scrollTop += 300;
    }
  }
  /**
   * The handler for the selection of a row on the ui
   * @param variable The variable being selected or unselected
   */
  handleSelection(variable: LibraryVariable) {
    this.selectedVariables = [];
    if (this.selectedVariableId === variable.variableId) {
      this.selectedVariableId = 0;
    } else {
      this.selectedVariableId = variable.variableId ;
      this.selectedVariables.push(variable);
    }
    // let existing = this.selectedVariables.findIndex(eachVariable => eachVariable.variableId == variable.variableId);
    // //if it exists - remove it
    // if (existing > -1) {
    //   this.selectedVariables.splice(existing, 1)
    //   this.selectedVariableIds.splice(existing,1);
    // } else {
    //   //otherwise add id
    //   this.selectedVariables.push(variable);
    //   this.selectedVariableIds.push(variable.variableId)
    // }
  }

  /**
   * Checks if the variable is selected
   * @param variable the variable being evaluated
   */
  isRowSelected(variable: LibraryVariable): boolean {
    //    let existingId = this.selectedVariables.findIndex(eachVariable => eachVariable.variableId == variable.variableId);
    // let existingId = this.selectedVariableIds.findIndex(eachId => eachId == variable.variableId);
    // return existingId > -1;
    return (this.selectedVariableId === variable.variableId);
  }

  /**
   * Emits the selections for the host
  */
  handleSelections() {
    this.variableSelected.emit(this.selectedVariables[0]);
  }

  /**
   *
   * @param show false if the modal should be closed
   * @param  $event event being emitted
   * @param selected If the selections should be passed on to the host
   */
  closeModal(show, $event, selected) {
    if (selected == true) {
      this.handleSelections();
    }
    if ($event) {
      $event.preventDefault();
    }
    this.inputModel.showModal = false;
    this.selectedVariables = [];
  }


  /**
   * Handler for the change sort
   * @param data the data to be sorted
   * @param config The configuration of the table being rendered
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
   * Function filters the data for display on the UI
   * @param data The data on the current page
   * @param config The configuration for the table columns etc.
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;
    if (!config.filtering) {
      return filteredData;
    }
    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name]) {
            const colValue = item[column.name] + '';
            return colValue.toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });
    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }
    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name] != null && item[column.name] != undefined
          && item[column.name].toString().match(this.config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;
    return filteredData;
  }

  /**
   * Handler for the changeTable event which is fired any time the table needs to be rebuilt
   * @param config The configuration controlling the table
   * @param page The current page we're on
   */
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    this.itemsPerPage = +page.itemsPerPage;
    let column: any = null;
    this.rows = [];
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.libraryVariables, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
//    this.rows = sortedData ;// page && config.paging ? this.changePage(page, sortedData) : sortedData;
    this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
    if (sortedData) {
      this.length = sortedData.length;
    }
  }

  /**
   *
   * @param page
   * @param data
   */
  public changePage(page: any, data: Array<any> = this.libraryVariables): Array<any> {
    const start = (page.page - 1) * page.itemsPerPage;
    const end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    this.page = page.page;
    return data.slice(start, end);
  }


  /**
   * True if paging is configured and if there are adequate
   * items for paging
   */
  shouldShowPagination(): boolean {
    return (this.config.paging && this.libraryVariables && this.libraryVariables.length > 0 && this.numPages > 1);
  }


  /************* END - Table filtering functionality******************** */







}
