import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'mc-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() modalBody: string;
  showModal: boolean = false;
  @Output() isConfirmedEvent = new EventEmitter<boolean>();

   /**
  * This is the Subject (Queue) holding the boolean of the selected user response
  */
   actionSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
  ) { }

  ngOnInit(): void {
  }


  /**
   * return if user pressed yes (true) or no (false)
   */
   setIsConfirmed(isConfirmed:boolean) {
    this.isConfirmedEvent.emit(isConfirmed);
    this.showModal = false;
  }

  
 
}


