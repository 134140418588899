import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StudyFormForLookup } from 'app/common/model/study-form-for-lookup';
import { StudyFormVariable } from 'app/common/model/study-form-variable';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { forEach } from 'lodash-es';




@Component({
  selector: 'mc-variable-modal',
  templateUrl: './variable-modal.component.html',
  styleUrls: ['./variable-modal.component.scss']
})
export class VariableModalComponent implements OnInit {

  /**
   * The currently selected item being edited
   */
  @Input() studySchemaEventId = 0;
  @Input() studyId: number;
  @Input() showModal: boolean;
  @Input() allowMultiples: boolean = false;

  @Output() selectedVariableEvent = new EventEmitter<Array<StudyFormVariable>>();

  /*** *********Begin Modal lookup for Variables *****************/



  formVersions: StudyFormForLookup[] = [];

  variableList: StudyFormVariable[] = [];

  selectedStudyForm: StudyFormForLookup = null;

  selectedFormVariable: Array<StudyFormVariable> = [];

  /************* End Modal lookup for Variables *********************/




  public showFilterRow: Boolean = true;
  public rows: Array<StudyFormVariable> = [];
  public page = 1;
  public itemsPerPage = 10;
  public maxSize = 5;
  public numPages = 1;
  public length = 0;

  // public variableName: string | null,
  // public variableClass: string | null,
  // public validValue: string | null,
  // public studyFormVariableValueId?: number | null,






  /**
   * The columns to be displayed on the Details table
   */
  public columns: Array<any> = [

    {
      title: 'Select', className: [],
      display: true,
      filtering: false,
      name: 'select', sort: false
    },
    {
      title: 'Variable Alternate Name', name: 'variableAlternateName',
      sort: '', className: ['has-variable-name-header'],
      filtering: { filterString: '', placeholder: 'Alternate Name' },
      display: true
    },
    {
      title: 'Valid Value', name: 'validValue',
      sort: '', className: ['has-variable-value-header'],
      filtering: { filterString: '', placeholder: 'Value' },
      display: true
    },
    {
      title: 'Variable Class', name: 'variableClass',
      display: true, className: ['has-variable-class-header'],
      filtering: { filterString: '', placeholder: 'Class' },
      sort: ''
    }

  ];


  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: []
  };

  /**
   * The event emitter to tranfer the selected variables on the form
   */
  // @Output() variableSelected = new EventEmitter<LibraryVariable>();




  constructor(private studySetupService: StudySetupService ) {

  }

  ngOnInit() {
  }


  /**
   * Handles the opening of the modal
   * @param row The FormVersions object
   */
  findVariable(): void {
    this.getFormVersions();
  }


  /**
   * Gets the FormVersions for the selected SchemaEvents
   */
  getFormVersions() {
    this.formVersions = [];
    if (this.studySchemaEventId > 0) {
      this.studySetupService.getStudyFormVersionBasedOnEvent(this.studyId, this.studySchemaEventId)
        .subscribe(results => {
          this.formVersions = results;
        }),
        err => { },
        () => {
          // console.log("Complete subscription Remaining Subscription")
        }
      }
  }


  /**
   * Handler for the formSelection change to retrieve the variables
   */
  formSelectionChanged(): void {
    this.getVariables();
  }

  /**
   * Retrieves the variables for the selected Form
   */
  getVariables() {
    this.variableList = [];
    if (this.selectedStudyForm && this.selectedStudyForm.studyFormId > 0) {
      this.studySetupService.getStudyFormVariables(this.studyId, this.selectedStudyForm.studyFormId)
        .subscribe(results => {
          this.variableList = results;
          this.onChangeTable(this.config)
        }),
        err => { },
        () => {
          // console.log("Complete subscription Remaining Subscription")
        }
    }
  }


  /**
   * Selects or deselects the studyFormVariable
   * @param row The selected row
   */
  rowVariableSelectedClick(row: StudyFormVariable): void {
    let spliceIdx: number = -1;
    if (this.selectedFormVariable) {
     spliceIdx = this.selectedFormVariable.findIndex(v => v.variableAlternateName == row.variableAlternateName && v.validValue == row.validValue);
    }
    if (spliceIdx > -1) {
      this.selectedFormVariable.splice(spliceIdx, 1);
    }
    else {
      if (!this.allowMultiples) {
        if (this.selectedFormVariable.length < 1) {
          this.selectedFormVariable.push(row);
        }
      }
      else {
        this.selectedFormVariable.push(row);
      }
    }
  }

  /**
   * True if the row being evaluated is the selected variable
   * @param row The selected row to evaluate
   */
  isVariableRowSelected(row: StudyFormVariable): boolean {
    if (row && this.selectedFormVariable) {
      return this.selectedFormVariable.findIndex(v => v.variableAlternateName == row.variableAlternateName && v.validValue == row.validValue) > -1;
    }
    return false;
  }

  /**
   * Sets the selected variable to the row
   */
  selectFormVariable() {
    // emit the selection
    this.selectedVariableEvent.emit(this.selectedFormVariable);
    this.closeModal();
  }

  /**
   * Closes the modal
   */
  closeModal() {
    this.showModal = false;
    this.selectedStudyForm = null;
    this.selectedFormVariable = [];
    this.variableList = [];
  }


  /**
   * Sorts the data in the table
   * @param data The data to sort
   * @param config The config to use
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }
    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }
    if (!columnName) {
      return data;
    }
    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousValue: any;
      let currentValue: any;

      previousValue = previous[columnName];
      currentValue = current[columnName];

      if (previousValue && currentValue) {
        if (previousValue > currentValue) {
          return sort === 'desc' ? -1 : 1;
        } else if (previousValue < currentValue) {
          return sort === 'asc' ? -1 : 1;
        }
      }
      return 0;
    });
  }

  /**
   * Filters the data being displayed
   * @param data The data to filter
   * @param config The configuration to use
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;
    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name] != null) {
            return ((item[column.name]) + '').toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          return ''.match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }
    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        const value = item[column.name];

        if (value !== undefined && value != null && value.toString().match(this.config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  /**
   * Handler when the sort, page, or filter options changes on the table
   * @param config the configuration to use
   * @param page The page to display
   */
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    let column: any = null;
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.variableList, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
    if (sortedData) {
      this.length = sortedData.length;
    }
  }

  /**
   * handler for the change page event
   * @param page The page to display
   * @param data The data to choose from
   */
  public changePage(page: any, data: Array<any> = this.variableList): Array<any> {
    const start = (page.page - 1) * page.itemsPerPage;
    const end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }



}
