<div *ngIf="messages.length > 0" class=" columns is-centered wrapper  toast-wrapper " [ngStyle]="positionStyle()">
  <div class="column" >  
    <aside *ngFor="let message of messages  ">
      <div class="tile is-ancestor  has-text-centered">
        <div class="notification tile is-child is-12 is-outlined " [ngClass]="{'is-info':     message.style=='info',
                       'is-danger':   message.style=='danger',
                        'is-warning':   message.style=='warning',
                       'is-success':  message.style=='success'}">
          <button class="delete is-radiusless" (click)="dismiss(message.key)"></button>

          <span [innerHTML]="message.content"></span>

        </div>
      </div>
    </aside>
  </div>
</div>