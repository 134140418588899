<mc-header-bar [headerModel]="headerModel"></mc-header-bar>

<div class="form-page notification-search container is-fluid">
    <div class=""
        [ngClass]="{ 'switchToHorizontal': previewModelHome.isVertical,'is-vertical-open': previewModelHome.isCollapsed  }">
        <div id="mainContainer" [ngClass]="{'switchVertical columns': previewModelHome.isVertical }">
            <div [ngClass]="{ 'column is-main-section-vertical': previewModelHome.isVertical,
               'is-6' : isExpandedVertical(), 'is-11' : isCollapsedVertical() }" class="main-section" id="mainSection">

                <br>
                <div class="container">
                    <label class="label is-medium" style="color:#3399FF">Enter Search Criteria</label>
                </div><br><br>
                <div class="container">

                      <!-- Notification Name -->
                      <div class="columns">
                        <div class="column is-2">
                            <span class="is-small">Recipient Email :</span>
                        </div>
                        <div class="column">
                            <div class="field  is-grouped">
                                <p class="control" class="no-right-margin">
                                    <input placeholder="Recipient Email Address" id="txt-recipient" name="recipient"
                                        class="form-control input is-normal"
                                        [(ngModel)]="notificationSearchCriteria.recipient">
                                </p>
                            </div>
                        </div>
                    </div>
                    
                   <!-- Study Number -->
                    <div class="columns">
                        <div class="column is-2 container">
                            <span class="is-small">Study Number :</span>
                        </div>
                        <div class="column">
                            <div class="field is-grouped ">
                                <p class="control  " class="no-right-margin">
                                    <input placeholder="Study Number" id="txt-study-number" name="studyNumber"
                                        class="form-control input is-normal"
                                        [(ngModel)]="notificationSearchCriteria.sponsorProtocolNumber">
                                </p>
                            </div>
                        </div>
                    </div>

                     <!-- Subject ID -->
                    <div class="columns">
                        <div class="column is-2">
                            <span class="is-small">Subject ID :</span>
                        </div>
                        <div class="column">
                            <div class="field is-grouped ">
                                <p class="control  " class="no-right-margin">
                                    <input placeholder="Subject ID" id="txt-study-number" name="studyNumber"
                                        class="form-control input is-normal"
                                        [(ngModel)]="notificationSearchCriteria.subjectId">
                                </p>
                            </div>
                        </div>
                    </div>

                     <!-- Notification Type -->
                    <div class="columns">
                        <div class="column is-2">
                            <span class="is-normal">Notification Type :</span>
                        </div>
                        <div class=" column field">
                            <div class="control input-width">
                                <div class="select is-normal">
                                    <select [(ngModel)]="notificationSearchCriteria.notificationType" >
                                        <option [ngValue]="null" selected>-- Select --</option>
                                        <option *ngFor="let notificationType of notificationTypes"
                                            [ngValue]="notificationType">
                                            {{geNotificaitonType(notificationType)}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Notification Name -->
                    <div class="columns">
                        <div class="column is-2">
                            <span class="is-small">Notification Name :</span>
                        </div>
                        <div class="column">
                            <div class="field">
                                <p class="control" class="no-right-margin">
                                    <input placeholder="Notification Name" id="txt-study-number" name="studyNumber"
                                        class="form-control input is-normal notificaiton-name"
                                        [(ngModel)]="notificationSearchCriteria.notificationName">
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    
                    <!-- Date range  -->

                    <div class="columns">
                        <div class="column is-2">
                            <span class="is-small">Date Range :</span>
                        </div>
                        <div class="column">
                            <div class="field is-grouped ">
                                    <p class="control  " class="no-right-margin">
                                        <mc-daterange-picker-wrapper [inputStartDate]="notificationSearchCriteria.dateRange.startDate"
                                            [inputEndDate]="notificationSearchCriteria.dateRange.endDate"
                                            (startDateSelected)="setStartDate($event)"
                                            (endDateSelected)="setEndDate($event)">
                                        </mc-daterange-picker-wrapper>
                                    </p>
                                </div>
                        </div>
                    </div> 

                    <br>
                   
                    <div class="columns">
                        <div class="column is-2">

                        </div>
                        <div class="column">
                            <div class="field">
                                <button class="button is-light" (click)="clear()">
                                    <span class="icon">
                                        <i class="fas fa-eraser"></i>
                                    </span>
                                    <span>Clear</span>
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button class="button is-primary" (click)="searchNotifications()"  [disabled]="isSearchDisable()">
                                    <span class="icon">
                                        <i class="fas fa-search"></i>
                                    </span>
                                    <span>Search</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <br>

                </div>
            </div>
            <div class="preview-section" id="previewSection" [ngClass]="{ 'togglePerviewOverlay' : isExpandedHorizontal(),                
            'is-6 preview-vertical-expanded' : isExpandedVertical(),
             'is-1' : isCollapsedVertical(),
            'column ': previewModelHome.isVertical }">
                <mc-preview [formTitle]="" [formCaption]="previewTitle" [previewModel]="previewModelHome"
                    #previewSection>
                    <div preview-data>
                        <mc-notification-tile-results  [isNotificationSearch]="true" (emailDetailSelected) = "onEmailDetailSelected($event)" >
                        </mc-notification-tile-results>
                    </div>

                </mc-preview>
            </div>
        </div>

        <div>
            <mc-email-modal></mc-email-modal> 
          </div>

    </div>