import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'mc-date-picker-wrapper',
  templateUrl: './date-picker-wrapper.component.html',
  styleUrls: ['./date-picker-wrapper.component.scss']
})
export class DatePickerWrapperComponent implements OnInit {

  @Input() inputDate: any;
  @Input() isDisabled: boolean = false;

    /**
   * the event passing the selected date
   */
  @Output() dateSelected = new EventEmitter<any>();

  modelDate: any = null;

  constructor() {

  }

  ngOnInit() {
    if (this.inputDate) {     
      //this.modelDate = new Date(moment.utc(this.inputDate).format("MM/DD/YYYY HH:mm:ss.SSS"))
      this.modelDate = new Date(moment.utc(this.inputDate).format("MM/DD/YYYY"))
    }
  }

  /**
    * Handler for the onChanges event
    * @param changes The changes on the view
    */
  ngOnChanges(changes: any): void {
    if (changes && changes.inputDate && !changes.inputDate.firstChange) {
      if (changes.inputDate.currentValue != changes.inputDate.previousValue) {
        // If a date field has been blanked out, it is set to the string 'Invalid date'.  Treat those as null
        if (changes.inputDate.currentValue && changes.inputDate.currentValue !== 'Invalid date') {
          this.modelDate = new Date(moment.utc(changes.inputDate.currentValue).format('MM/DD/YYYY'));
        } else {
          this.modelDate = null;
        }
      }
    }

  }

  /**
   * Sets the date input by the user
   */
  setManualDate(): void {
    // if it's an invalid date entered manually, set it to null
    // and notify
    if (!moment.isDate(this.modelDate)) {
      this.modelDate = null;
    }
    this.onDateSelected(this.modelDate);
  }



  /**
   * Handles the event fired from the UI when a date is selected
   * @param event The date that was selected
   */
  public onDateSelected(event): void {
    this.fireDateUpdatedEvent(this.modelDate)
  }


  /**
   * Fires the event to inform the host and return the selected date
   * @param newDate Selected date from the datepicker
   */
  fireDateUpdatedEvent(newDate: any) {
    this.dateSelected.emit(newDate);
  }

}


