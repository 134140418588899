import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DemographicSettingsModel } from 'app/common/model/demographic-settings';
import { Organization } from 'app/common/model/organization';
import { PatientDuplicateRequest, PatientDuplicateResponse } from 'app/common/model/patient-duplicate';
import { PatientRegError } from 'app/common/model/patient-reg-error';
import { PatientRegistration } from 'app/common/model/patient-registration';
import { Person } from 'app/common/model/person';
import { PtraxParticipant } from 'app/common/model/ptrax-participant';
import { StudySchemaEventDisplayInfo } from 'app/common/model/StudySchemaEventDisplayInfo';
import { Observable, Subject, of } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../../../app-config/app-config.module';
import { PatientRegistrationComponent } from '../../../patient-registration/patient-registration.component';
import { Form } from '../../model/form';
import { UiLayoutElement } from '../../model/ui-layout-element';
import { BasePatientService } from './base-patient-service';
import { TreatmentAssignment } from 'app/common/model/treatment-assignment';
import { formatDate } from '@angular/common';
import { SubgroupCode } from 'app/common/model/subgroup-code';
import { SubjectReservation } from 'app/common/model/subject-reservation';




@Injectable()
export class NewPatientService extends BasePatientService {

  serviceUrlPrefix: string; //

  http: HttpClient;
  config: AppConfig;
  localId: string;

  /**
   * Private observable subject to handle the Patient Validation errors
   */
  private regErrorSource = new Subject<PatientRegError[]>();

  /**
   * Stream through which a user can subscribe to the validation errors
   */
  public regValidationErrors$ = this.regErrorSource.asObservable();


  /**
     * Private observable subject to handle the Patient uiLayoutElements
     */
  private uiLayoutElementsSource = new Subject<UiLayoutElement[]>();

  /**
   * Stream through which a user can subscribe to the uiLayoutElements
   */
  public uiLayoutElements$ = this.uiLayoutElementsSource.asObservable();





  /**
     * variable for instance of PatientRegistration object being edited / created
     */
  private _currentPatientRegModel: PatientRegistration;



  /**
     * variable for instance of source unedited PatientRegistration object
     */
  private _originalPatientRegModel: PatientRegistration;


  /**
     * Instance of the Parent PatientRegistration object being edited / created
     */
  private _currentParentComponent: PatientRegistrationComponent;


  private _subjectReservation: SubjectReservation;


  constructor(http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig,
    @Inject(LOCALE_ID) localId: string,
    componentFactoryResolver: ComponentFactoryResolver) {
    super(http, config, componentFactoryResolver);

    this.config = config;
    this.http = http;
    this.serviceUrlPrefix = config.apiEndpoint;
    this.localId = localId;
  }

  /**
   * Allows validation errors to be submitted to the queue for
   * subscribers to retrieve
   * @param patientRegErrors Array of validation errors that are submitted to the queue
   */
  public setRegValidationErrors(patientRegErrors: PatientRegError[]) {
    this.regErrorSource.next(patientRegErrors);
  }

  /**
   * Allows UILayout Elements to be submitted to the queue for
   * subscribers to retrieve
   * @param uiLayoutElements Array of uiLayoutElements that are submitted to the queue
   */
  public setUiLayoutElements(uiLayoutElements: UiLayoutElement[]) {
    this.uiLayoutElementsSource.next(uiLayoutElements);
  }

  /**
   * returns the instance of the PatientRegistration model being edited / created
   */
  public get currentPatientRegModel(): PatientRegistration {
    return this._currentPatientRegModel;
  }

  /**
   * sets the instance of the current PatientRegistration model being edited / created
   */
  public set currentPatientRegModel(patientRegModel: PatientRegistration) {
    this._currentPatientRegModel = patientRegModel;
  }

  public set currentReservationModel(reservation: SubjectReservation) {
    this._subjectReservation = reservation;
  }

  public get currentReservationModel(): SubjectReservation {
    return this._subjectReservation;
  }

  /**
   * returns the instance of the source unedited PatientRegistration model
   */
  public get originalPatientRegModel(): PatientRegistration {
    return this._originalPatientRegModel;
  }

  /**
   * sets the instance of the source unedited  PatientRegistration model
   */
  public set originalPatientRegModel(patientRegModel: PatientRegistration) {
    this._originalPatientRegModel = patientRegModel;
  }

  /**
   * returns the instance of the PatientRegistration parent component
   */
  public get currentParentComponent(): PatientRegistrationComponent {
    return this._currentParentComponent;
  }

  /**
   * sets the instance of the current Parent Component
   */
  public set currentParentComponent(parentComponent: PatientRegistrationComponent) {
    this._currentParentComponent = parentComponent;
  }


  // -  /studies/{studyId}/demography-settings
  /**
   * Gets the DemographySetting model from the service
   * @param studyId The study Id for the settings
   */
  public getDemographySettingsModel(studyId: number): Observable<DemographicSettingsModel> {
    const url = 'studies/' + studyId + '/demography-settings';
    return this.http.get<DemographicSettingsModel>
      (`${this.config.apiEndpoint}` + url);
  }

  /**
     * Gets the organizations for the relationshipPk and siteCode from the service
     * @param studyId The studyId for which the data is to be retrieved
     * @param relationshipPk The relationshipPd
     * @param siteCode The site Code
     */
  public getSiteOrgs(studyId: number, relationshipPk: number = 0, siteCode: string = '', networkFlag: boolean = true) // JTB THIS
    : Observable<Organization[]> {
    if (networkFlag) {
      // studies/{studyId}/participation/sites/{relationshipPk}/{siteCode}
      let url = 'studies/' + studyId + '/participation/sites';
      let isRelationshipSet = false;
      if ((relationshipPk && relationshipPk > 0)) {
        url += '?' + 'relationshipPk=' + relationshipPk;
        isRelationshipSet = true;
      }
      if (siteCode && siteCode.trim().length > 0) {
        if (!isRelationshipSet) {
          url += '?';
        } else {
          url += '&';
        }
        url += 'siteCode=' + siteCode.toUpperCase();
      }
      return this.http.get<Organization[]>
        (`${this.config.apiEndpoint}` + url);

      // const dummyNonStd: Organization[] = [
      //   {
      //     "ctepId": "ALLIANCE",
      //     "orgNumber": "37688",
      //     "abrev": "Alliance",
      //     "name": "Alliance",
      //     "type": "structure",
      //     "status": "active",
      //     "relationshipPk": 10541,
      //     "studyNetworkId": null,
      //     "siteLevelId": 1,
      //     "caseOrganizationId": 625017,
      //     "mayoSite": null
      //   },
      //   {
      //     ctepId: "NRG",
      //     orgNumber: "966",
      //     abrev: "NRG",
      //     name: "NRG",
      //     type: "structure",
      //     status: "active",
      //     relationshipPk: null,
      //     studyNetworkId: null,
      //     siteLevelId: 1,
      //     caseOrganizationId: 554605,
      //     mayoSite: null,
      //     nonStdOrgId: 966,
      //   },
      //   {
      //     ctepId: "MN008",
      //     orgNumber: "2707",
      //     abrev: "Abbott-Northwestern Hospital",
      //     name: null,
      //     type: null,
      //     status: null,
      //     relationshipPk: null,
      //     studyNetworkId: null,
      //     siteLevelId: 3,
      //     caseOrganizationId: 554606,
      //     mayoSite: null,
      //     nonStdOrgId: 2707,
      //   },
      // ];
      // return of(dummyNonStd);  
    } else {
      console.log('getSiteOrgs nonNetwork');
      // use separate endpoint to get the direct roster treating locations
      const url = 'studies/' + studyId + '/participation/direct';
      return this.http.get<Organization[]>
        (`${this.config.apiEndpoint}` + url);
    }
  }

  /**
   * Retrieves the persons for the study and the relationshipPk
   * @param studyId The studyId
   * @param relationshipPk The relationship Pk for which the persons are to be retrieved
   */
  public getPersonsForRelationship(studyId: number, relationshipPk: number, personType?: string)
    : Observable<Person[]> {
    //   studies/{studyId}/participation/persons/{relationshipPk}
    let url = 'studies/' + studyId + '/participation/persons/' + relationshipPk;
    if (personType) {
      url = 'studies/' + studyId + '/participation/persons/' + relationshipPk + '?personType=' + personType;
    }

    const persons: Person[] = [
      {

        'ctepId': null,
        'firstName': 'Barbara',
        'lastName': 'Dobie',
        'email': null,
        'fax': null,
        'phone': null,
        'personTypeId': 2,
        'pk': 183,
        'personRoleStatus': 'Inactive'
      },
      {

        'ctepId': null,
        'firstName': 'Colin',
        'lastName': 'Pfannkuch',
        'email': null,
        'fax': null,
        'phone': null,
        'personTypeId': 1,
        'pk': 182,
        'personRoleStatus': 'Active'
      },
      {

        'ctepId': null,
        'firstName': 'Raj',
        'lastName': 'Kommawar',
        'email': null,
        'fax': null,
        'phone': null,
        'personTypeId': 1,
        'pk': 320,
        'personRoleStatus': 'Active'
      }
    ];
    // return of(persons);

    return this.http.get<Person[]>
      (`${this.config.apiEndpoint}` + url);
  }

  /**
   * This will return person on the direct participant form based on the logged in user.
   *
   * @param studyId
   * @param studyNetworkId
   */
  public getPersonsForDirectParticipant(studyId: number, studyNetworkId: number, personType?: string): Observable<Person[]> {
    let url = 'studies/' + studyId + '/participation/direct/' + studyNetworkId + '/persons';
    if (personType) {
      url = 'studies/' + studyId + '/participation/direct/' + studyNetworkId + '/persons?personType=' + personType;
    }
    return this.http.get<Person[]>
      (`${this.config.apiEndpoint}` + url);
  }


  /**
   * Returns the Ptrax Participant for the study and mayoClinic #
   * @param studyId The study Id
   * @param mayoClinicNumber The mcn to lookup
   */
  public getPtraxParticipant(studyId: number, mayoClinicNumber: string): Observable<PtraxParticipant> {
    /// studies/19643/ptrax-participant?mayo-clinic-number=123
    const url = 'studies/' + studyId + '/ptrax-participant?mayo-clinic-number=' + mayoClinicNumber;
    return this.http.get<PtraxParticipant>
      (`${this.config.apiEndpoint}` + url);
  }




  // /**
  //  * Validate the patient registration
  //  * @param record The patient Registration object to validate
  //  */
  // public validateRegulatoryCheck(record: PatientRegistration): Observable<PatientRegistration> {
  //   //http://localhost:8080/reg-backend-service/api/v1/patients/registration/regulatory-check
  //   return this.http.post<PatientRegistration>(this.serviceUrlPrefix + 'patients/registration/regulatory-check', record);
  // }


  /**
   * Calls the service to return the tracking Id for the newly validated patient registration object
   * @param record The PatientRegistration object
   */
  public getTrackingId(record: PatientRegistration, doUpdate: boolean = false, sectionName: string = '\''): Observable<PatientRegistration> {
    // http://localhost:8080/reg-backend-service/api/v1/patients/registration/tracking
    return this.http.post<PatientRegistration>
      (this.serviceUrlPrefix + 'patients/registration/tracking', record);
  }



  /**
   * Calls the service to update and return the tracking Id for the validated patient registration object
   * @param record The PatientRegistration object
   */
  public updateTrackingId(record: PatientRegistration, doUpdate: boolean = false, sectionName: string = ''): Observable<PatientRegistration> {
    // http://localhost:8080/reg-backend-service/api/v1/patients/registration/tracking
    const updateParamName = 'correctionsOn';
    let url: string = this.serviceUrlPrefix + 'patients/registration/tracking';
    if (doUpdate && sectionName) {
      url += '?' + updateParamName + '=' + sectionName;
    }

    return this.http.put<PatientRegistration>
      (url, record);
  }



  /**
     * Gets the Form array from the service
     * @param studyId The study Id for the forms
     * @param studySchemaEventId The StudySchemaEvent to which the form is associated, in the case of a registration
     * @param caseEventId The caseEventId to which the form is associated, in the case of corrections
     * @param isCorrectionsOn On means doing eligibility corrections, off means doing a registration
     */
  public getFormData(studyId: number, keyEventMappingId: number, caseDetailId: number, isCorrectionsOn: boolean): Observable<Form[]> {
    let url: string;
    if (!isCorrectionsOn || caseDetailId <= 0) {
      url = 'studies/' + studyId + '/enrollment-event/' + keyEventMappingId + '/forms';
    } else {
      url = 'studies/' + studyId + '/forms/case-detail/' + caseDetailId + '/enrollment-event/' + keyEventMappingId;
    }
    return this.http.get<Form[]>
      (`${this.config.apiEndpoint}` + url);
  }


  /**
     * Calls the service save the changes to the Patient registration object
     * @param record The PatientRegistration object
     */
  public saveUpdates(record: PatientRegistration, correctionsOn: boolean = false): Observable<PatientRegistration> {
    // http://localhost:8080/reg-backend-service/api/v1/patients/registration/save
    console.log('Save updates', record);
    let url: string = this.serviceUrlPrefix + 'patients/registration/save';
    // corrections on indicates we are on the eligibility corrections tile
    if (correctionsOn) {
      url += '?correctionsOn=yes';
    }

    return this.http.put<PatientRegistration>(url, record);

    // return this.http.put<PatientRegistration>
    //    (this.serviceUrlPrefix + 'patients/registration', record);

  }

  /**
   * update the additional options
   * @param record The patient Registration object to update
   */
  public updateAdditionalOptions(record: PatientRegistration): Observable<PatientRegistration> {
    // http://localhost:8080/reg-backend-service/api/v1/patients/registration/additional-options

    const url: string = this.serviceUrlPrefix + 'patients/registration/additional-options';
    return this.http.put<PatientRegistration>(url, record);

    // return this.http.put<PatientRegistration>(this.serviceUrlPrefix + 'patients/validate', record);
  }


  /**
   * Validate the patient registration
   * @param record The patient Registration object to validate
   */
  public validatePreRegistration(record: PatientRegistration, correctionsOn: boolean = false): Observable<PatientRegistration> {
    // http://localhost:8080/reg-backend-service/api/v1/patients/registration/validate

    let url: string = this.serviceUrlPrefix + 'patients/registration/validate';
    // corrections on indicates we are on the eligibility corrections tile
    if (correctionsOn) {
      url += '?correctionsOn=yes';
    }
    return this.http.put<PatientRegistration>(url, record);

    // return this.http.put<PatientRegistration>(this.serviceUrlPrefix + 'patients/validate', record);
  }

  /**
   * Register  the patient registration
   * @param record The patient Registration object to validate
   */
  public registerPatient(record: PatientRegistration): Observable<PatientRegistration> {
    // http://localhost:8080/reg-backend-service/api/v1/patients/registration/register
    return this.http.put<PatientRegistration>(this.serviceUrlPrefix + 'patients/registration/register', record);
  }


  /**
   * Returns the PatientRegistration Object of the registered patient
   * @param caseDetailId The caseDetailId of the registered patient
   */
  public getPatientRegData(caseDetailId: number, caseEventId: number): Observable<PatientRegistration> {
    // https://dev.reg-backend.mayo.edu/api/v1/patients/registration/{caseDetailId}
    return this.http.get<PatientRegistration>(this.serviceUrlPrefix + 'patients/registration/' + caseDetailId + '/' + caseEventId);
  }




  /**
   * Returns the duplicate matches for the search criteria provided
   * @param studyId The studyId for the patient
   * @param request The search request object with the search info
   */
  public searchDuplicates(studyId: number, request: PatientDuplicateRequest): Observable<PatientDuplicateResponse[]> {
    // https://dev.reg-backend.mayo.edu/api/v1/studies/19643/patients/search-duplicates
    //  let results: PatientDuplicateResponse[] =
    //  [
    //   {
    //       "patientId": null,
    //       "trackingNum": 1000006282,
    //       "studyId": null,
    //       "studyNumber": null,
    //       "caseDetailId": null,
    //       "firstName": "Alice",
    //       "middleName": "Jean",
    //       "lastName": "Smith",
    //       "dateOfBirth": new Date("1992-07-24"),
    //       "genderDesc": "Female",
    //       "raceDescList": null
    //   },
    //   {
    //       "patientId": "ex211717",
    //       "trackingNum": null,
    //       "studyId": null,
    //       "studyNumber": null,
    //       "caseDetailId": null,
    //       "firstName": "Alice",
    //       "middleName": "Jean",
    //       "lastName": "Smith",
    //       "dateOfBirth": new Date("1992-07-24"),
    //       "genderDesc": "Female",
    //       "raceDescList": null
    //   },
    //   {
    //       "patientId": "ex211718",
    //       "trackingNum": null,
    //       "studyId": null,
    //       "studyNumber": null,
    //       "caseDetailId": null,
    //       "firstName": "Alice",
    //       "middleName": "Jean",
    //       "lastName": "Smith",
    //       "dateOfBirth": new Date("1992-07-24"),
    //       "genderDesc": "Female",
    //       "raceDescList": null
    //   },
    //   {
    //       "patientId": "ex211719",
    //       "trackingNum": null,
    //       "studyId": null,
    //       "studyNumber": null,
    //       "caseDetailId": null,
    //       "firstName": "Alice",
    //       "middleName": "Jean",
    //       "lastName": "Smith",
    //       "dateOfBirth": new Date("1992-07-24"),
    //       "genderDesc": "Female",
    //       "raceDescList": null
    //   },
    //   {
    //       "patientId": "ex211720",
    //       "trackingNum": null,
    //       "studyId": null,
    //       "studyNumber": null,
    //       "caseDetailId": null,
    //       "firstName": "Alice",
    //       "middleName": "Jean",
    //       "lastName": "Smith",
    //       "dateOfBirth": new Date("1992-07-24"),
    //       "genderDesc": "Female",
    //       "raceDescList": null
    //   }
    // ]
    // return of(results);
    return this.http.post<PatientDuplicateResponse[]>(this.serviceUrlPrefix +
      'studies/' + studyId + '/patients/search-duplicates', request);
  }


  /**
   * Returns the list of all interventions (study schema event labels) that immediately follow the event being registered to
   * @param studyId The studyId for which the patient is registering
   */
  public getManualArmAssignmentOptions(studyId: number, caseEventId: number): Observable<StudySchemaEventDisplayInfo[]> {
    return this.http.get<Array<StudySchemaEventDisplayInfo>>(this.serviceUrlPrefix +
      'studies/' + studyId + '/manual-arm-assignment/' + caseEventId);
  }

  /**
 * Returns the list of all study tac tads for the version associated with the registration date  
 * @param studyId The studyId for which the patient is registering
 * @param registrationDate
 */
  public getManualTacTadOptions(studyId: number, registrationDate: Date): Observable<TreatmentAssignment[]> {
    const registrationDateString = formatDate(registrationDate, 'MM-dd-yyyy', this.localId);
    return this.http.get<Array<TreatmentAssignment>>(this.serviceUrlPrefix +
      'studies/' + studyId + '/tac-tad/version-details?versionDate=' + registrationDateString);
  }


  /**
 * Returns the list of all study subgroup codes for the version associated with the registration date  
 * @param studyId The studyId for which the patient is registering
 * @param registrationDate
 */
  public getManualSgcOptions(studyId: number, registrationDate: Date): Observable<SubgroupCode[]> {
    const registrationDateString = formatDate(registrationDate, 'MM-dd-yyyy', this.localId);
    return this.http.get<Array<SubgroupCode>>(this.serviceUrlPrefix +
      'studies/' + studyId + '/sgc/version-details?versionDate=' + registrationDateString);
  }


  /**
   * This will return site information for a non-standard site based on ctep id
   *
   * @param ctepId
   */
  public getNonStdSiteInformation(ctepId: string): Observable<Organization> {
    // let result: Organization = {
    //   "ctepId":"ND028",
    //   "orgNumber":"37722",
    //   "abrev":"Altru ND",
    //   "name":"Altru ND",
    //   "type":"treat_mbrship",
    //   "status":"active",
    //   "relationshipPk":12669,
    //   "studyNetworkId":null,
    //   "siteLevelId":3,
    //   "caseOrganizationId":624321,
    //   "mayoSite":null
    // };
    // return of(result);
    const url = 'organizations/ctepId/' + ctepId;
    return this.http.get<Organization>
      (`${this.config.apiEndpoint}` + url);
  }

  /**
   * This will return staff information for a non-standard person based on ctep id
   *
   * @param ctepId
   */
  public getNonStdStaffInformation(ctepId: string): Observable<Person> {
    // let result: Person = {
    //   "ctepId":"12345",
    //   "id": 123,
    //   "firstName":"John",
    //   "lastName":"Doe",
    //   "email":"jdoe@gmail.com",
    //   "fullName":"John Allen Doe",
    //   "personTypeId": 1,
    //   "caseEventStaffId": 1
    // };
    // return of(result);
    const url = 'persons/ctepId/' + ctepId;
    return this.http.get<Person>
      (`${this.config.apiEndpoint}` + url);
  }


  /**
   * Validate the subject reservation
   * @param subjectReservation 
   * @param studyId 
   * @param bypass  
   * @returns 
   */
  public validatePatientReservation(subjectReservation: SubjectReservation, studyId: number, bypass: boolean): Observable<{}> {

    const url: string = this.serviceUrlPrefix + 'studies/' + studyId + "/patients/reservations/validate?bypass=" + bypass;
    return this.http.put<{}>(url, subjectReservation);

  }

  /**
   * Create subject reservation
   * @param subjectReservation 
   * @param studyId 
   * @returns 
   */
  public createPatientReservation(subjectReservation: SubjectReservation, studyId: number): Observable<SubjectReservation> {
    const url: string = this.serviceUrlPrefix + 'studies/' + studyId + "/patients/reservations";
    return this.http.post<SubjectReservation>(url, subjectReservation);

  }

  /**
   * Update existing subject reservation
   */
  public updatePatientReservation(subjectReservation: SubjectReservation, studyId: number): Observable<SubjectReservation> {
    const url: string = this.serviceUrlPrefix + 'studies/' + studyId + "/patients/reservations";
    return this.http.put<SubjectReservation>(url, subjectReservation);

  }

  public getPatientReservation(reservationId: number, studyId: number): Observable<SubjectReservation> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/patients/reservations/${reservationId}`;
    return this.http.get<SubjectReservation>(url);

  }


}
