import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { EventArg } from 'app/common/model/event-arg';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as globalConst from 'app/common/model/app-constants';
import { NotificationDashboardService } from '../../common/services/notification/notification-dashboard.service';

import { Subscription } from 'rxjs';
import { HeaderBar } from 'app/common/model/header-bar';
import { HeaderBarComponent } from 'app/common/header-bar/header-bar.component';

@Component({
  selector: 'mc-group-notification',
  templateUrl: './group-notification.component.html',
  styleUrls: ['./group-notification.component.scss']
})
export class GroupNotificationComponent implements OnInit, AfterViewInit,  OnDestroy {

  eventArg: EventArg = new EventArg('', '');
  showModal = false;

  subscriptions: Subscription[] = [];


  /**
   * model for the header bar
   */
  headerModel: HeaderBar ;

/**
 * Reference to the child header component
 */
@ViewChild(HeaderBarComponent)
private headerBar: HeaderBarComponent;

  constructor(
    private studySetupService: StudySetupService,
    private notificationService: NotificationDashboardService,
    private route: ActivatedRoute,
    private router: Router) {
     this.subscriptions.push( studySetupService.changeEmitted$.subscribe(
        eventInfo => {
          this.parseEventInfo(eventInfo);
        }));
     }

  ngOnInit() {
    this.headerModel = new HeaderBar('Group Notifications', '', false, '', true);
  }

  ngAfterViewInit() {
    this.headerBar.initHeader();
  }


   /**
 * Destroy implementation - closes all the subscriptions
 */
ngOnDestroy() {
  this.subscriptions.forEach(
    x => {
      x.unsubscribe();
      x.closed;
    }
  )
}


  /**
   * Parses the event info to determine what event was fired
   * @param eventInfo Event info for details of the event
   */
  parseEventInfo(eventInfo: EventArg) {
    if (eventInfo.eventName == globalConst.EVENT_DATA_VALID) {

    } else if (eventInfo.eventName == globalConst.EVENT_DATA_UPDATED) {

    } else if (eventInfo.eventName == globalConst.EVENT_WARN_UNSAVED_CHANGES) {
//      this.cancelChanges();
    } else if (eventInfo.eventName == globalConst.EVENT_NAVIGATE) {
  //    this.doNavigation(eventInfo);
    }
  }



  /**
   * Click handler for the save button
   */
  saveChanges() {
    this.eventArg.eventName = globalConst.EVENT_SAVE_ACTION;
    this.eventArg.eventValue = 'true';
    this.studySetupService.emitChange(this.eventArg);
  }

  /**
   * click handler for the cancel button
   */
  cancelChanges() {
    this.raiseCancelEvent();

  }


  /**
   * Raises the cancel event for the components
   */
  raiseCancelEvent() {
    this.eventArg.eventName = globalConst.EVENT_CANCEL_ACTION;
    this.eventArg.eventValue = 'true';
    this.studySetupService.emitChange(this.eventArg);
  }


}
