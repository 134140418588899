import { Component, OnInit, Input } from '@angular/core';
import { UnblindedInfo } from 'app/common/model/unblinded-info';
import {ClipboardModule, Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'mc-unblinded-modal',
  templateUrl: './unblinded-modal.component.html',
  styleUrls: ['./unblinded-modal.component.scss']
})
export class UnblindedModalComponent implements OnInit {

  /**
   * The currently selected case event 
   */
  @Input() unblindedInfo: UnblindedInfo;
  @Input() showModal: boolean;


  constructor(
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    console.log("unblinded modal ngOnInit");
  }

  shouldShowProtocolNum(): boolean {
    return !(this.unblindedInfo===undefined || this.unblindedInfo.protocolNum === undefined);
  }

  shouldShowSubjectNum(): boolean {
    return !(this.unblindedInfo===undefined || this.unblindedInfo.subjectNum === undefined);
  }
  shouldShowDrug(): boolean {
    return !(this.unblindedInfo===undefined || this.unblindedInfo.drug === undefined);
  }

  hasDrug(): boolean {
    if (this.unblindedInfo && this.unblindedInfo.drug && this.unblindedInfo.drug.trim().length > 0) {
      return true;
    }
    return false;
  }

  /**
   * Build up string to copy to clipboard
   */
  copyUnblindedInfo() {
    let copyInfo: string = '';
    if (this.unblindedInfo) {
      copyInfo = copyInfo + "****UNBLINDED INFORMATION****\n\n";
      if (this.unblindedInfo.protocolNum) {
        copyInfo = copyInfo + "Study Number: ";
        copyInfo = copyInfo + this.unblindedInfo.protocolNum + "\n";
      }
      if (this.unblindedInfo.subjectNum) {
        copyInfo = copyInfo + "Subject Id: ";
        copyInfo = copyInfo + this.unblindedInfo.subjectNum + "\n";
      }
      if (this.unblindedInfo.drug) {
        copyInfo = copyInfo + "Drug: ";
        copyInfo = copyInfo + this.unblindedInfo.drug + "\n";
      }
    }
    return copyInfo;
  }

  /**
   * Closes the modal
   */
  closeModal() {
    this.showModal = false;
  }

}
