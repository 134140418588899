import { PossibleValuesHierarchy } from "./possible-values-hierarchy";

export class Gender {
  constructor(
    public studyId: number | null,
    public studyTemplateId: number | null,
    public studyGenderClassificationId: number | null,
    public genderClassificationId: number | null,
    public required: boolean | null,
    public tupleVersion: number | null,
    public possibleValues?: PossibleValuesHierarchy[] | null
) { }
}
