<!-- <p>reservation-confirmation works!</p> -->
<div class="patient-preview"> 
    <div *ngIf=isReservationExist()>
  
      <div class="columns">
        <div class="column is-6">
          <h3>Reservation Details</h3>
          <table class="table is-fullwidth has-no-bottom-padding">
            <tbody>
              <tr>
                <td class="display-label">Reservation ID:</td>
                <td *ngIf="subjectReservation.subjectReservationId">{{subjectReservation.subjectReservationId }}</td>
              </tr>
              <tr>
                <td class="display-label">Slot Group Description:</td>
                <td *ngIf="subjectReservation.studyReservationSlotGroup && subjectReservation.studyReservationSlotGroup.groupDescription">{{subjectReservation.studyReservationSlotGroup.groupDescription}}</td>
              </tr>
              <tr>
                <td class="display-label">Expiration Date:</td>
                <td *ngIf="subjectReservation.expirationDate"> {{getDateDataFormatted(subjectReservation.expirationDate)}} </td>
              </tr>
              <tr>
                <td class="display-label">Site Contact:</td>
                <td *ngIf="subjectReservation.contactStaff">{{getContactStaffName()}}</td>
              </tr>
              <ng-container>
                <h3>Participation – Reservation</h3>
                <tr *ngFor="let item of subjectReservation.organizations; let i=index">
                  <td class="display-label">
                    <span>
                      {{getSiteLevelName(item)}}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{item.name}}
                    </span>
                  </td>
                </tr>
              </ng-container>
  
          </table>
          <h3>Subject Details</h3>
          <table class="table is-fullwidth has-no-bottom-padding">
            <tbody>
              <tr>
                <td class="display-label">Name</td>
                <td *ngIf="subjectReservation.patientInfo">{{getPatientName()}}</td>
              </tr>
              <tr>
                <td class="display-label">Date of Birth:</td>
                <td *ngIf="subjectReservation.patientInfo.dateOfBirthString">{{subjectReservation.patientInfo.dateOfBirthString}}</td>
              </tr>
  
  
            </tbody>
          </table>
        </div>
        <div class="column is-6">
          <h3>Study Details</h3>
          <table class="table is-fullwidth has-no-bottom-padding">
            <tbody>
              <tr>
                <td class="display-label">
                  <span>Study Number</span>
                </td>
                <td *ngIf="studyNumber" class="">
                  <span>
                    {{studyNumber}}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="display-label">
                  <span>Long Description</span>
                </td>
                <td *ngIf="studyDesc" class="">
                  <span>
                    {{studyDesc}}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="display-label">
                  <span>Schema Step</span>
                </td>
                <td class="">
                  <span>
                    {{subjectReservation.schemaStep}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
  
  
      </div>
    </div>

  
    </div>