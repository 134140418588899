export class OrgNetwork {
    constructor(
        public ctepId: string,
        public orgPk: number | null,
        public networkPk: number | null,
        public name: string,
        public status: string,
        public type: string| null
    ) { }
}
