import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StudySetupService } from '../services/study-setup.service';


@Component({
  selector: 'mc-error-validation-display-row',
  templateUrl: './error-validation-display-row.component.html',
  styleUrls: ['./error-validation-display-row.component.scss']
})
export class ErrorValidationDisplayRowComponent implements OnInit, OnDestroy {


  @Input() idToEvaluate: any;

  private messages: Array<string> = [];

  isErrorHidden = true;
  subscriptions: Subscription[] = [];

  constructor(private studySetupService: StudySetupService) {
    this.subscriptions.push(this.studySetupService.changeErrorMessagesEmitted$.subscribe(
      msgs => {
        this.messages = [];

        this.isErrorHidden = true;
        if (msgs.length > 0) {
          this.messages =  (msgs.filter(item => item.id === this.idToEvaluate)).map(each => each.message);
        }
      }));
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }

  /**
   * Evalutes if the event has a validation error
   */
  hasError(): boolean {
    const containsError: boolean = this.messages.length > 0;
    return containsError;
  }


  /**
   * Displays the error message for the event
   * @param $event The event object
   * @param idToEvaluate The id to evaluate
   */
  showError($event: Event) {
    if ($event) {
      $event.stopPropagation();
    }
    this.isErrorHidden = false;
  }


  /**
   * Hides the error message for the event
   */
  hideError() {
    this.isErrorHidden = true;
  }

  /**
   * True if the error should be displayed for this row
   */
  shouldDisplayError(): boolean {
    return !this.isErrorHidden;
  }


  /**
   * Returns the messages for this id
   */
  getErrors(): string[] {
    return this.messages;
  }


}
