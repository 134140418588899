import { Component, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HeaderBarComponent } from 'app/common/header-bar/header-bar.component';
import * as globalConst from 'app/common/model/app-constants';
import { EventArg } from 'app/common/model/event-arg';
import { HeaderBar } from 'app/common/model/header-bar';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { PatientEnrollmentCorrection } from 'app/common/model/patient-enrollment-correction';
import { PatientService } from 'app/common/services/patient/patient.service';
import { StudySetupService } from 'app/common/services/study-setup.service';
import * as _ from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';



@Component({
  selector: 'mc-enrollment-corrections',
  templateUrl: './enrollment-corrections.component.html',
  styleUrls: ['./enrollment-corrections.component.scss']
})
export class EnrollmentCorrectionsComponent implements OnInit {

  /** model for the header bar */
  headerModel: HeaderBar;

  /** Variable to hold the caseDetailId */
  caseDetailId = 0;
  studyId: number;
  patientId = '';
  navBackPageEnum = 0;
  trackingNum: string;
  selectedCaseEventId: number;

  /** List of patient enrollments */
  patientEnrollmentList: PatientEnrollmentCorrection[] = [];

  /** Reference to the child header component */
  @ViewChild(HeaderBarComponent)
  private headerBar: HeaderBarComponent;

  private subscriptions: Subscription[] = [];
  private currentUrl: any;
  private eventArg: EventArg = new EventArg('', '');

  constructor(
    private studySetupService: StudySetupService,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private viewContainer: ViewContainerRef) {

    // need to ensure we react only when the navigation ends
    this.subscriptions.push(router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((url: any) => {
        if (url.url) {
          this.currentUrl = url;
        }
      }));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params['navBack']) {
          this.navBackPageEnum = +params['navBack'];
        }
        if (params['trackingNum']) {
          this.trackingNum = params['trackingNum'];
        }
        if (params['selectedCaseEventId']) {
          this.selectedCaseEventId = params['selectedCaseEventId'];
        }
      }));
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.studyId = params['studyId'];
      this.caseDetailId = params['caseDetailId'];
      this.patientId = params['patientId'];
      let patientTitle = "";
      if (this.patientId !== 'NULL') {
        patientTitle = " &emsp;  Subject ID: " + this.patientId;
      }
      if (this.trackingNum) {
        patientTitle = patientTitle + " &emsp;   Tracking Number: " + this.trackingNum;
      }
      this.subscriptions.push(this.studySetupService.getStudy(this.studyId)
        .subscribe(results => {
          // initialize the model for the header
          this.headerModel = {
            caption: 'Enrollment Corrections',
            captionValue: '',
            showDescription: true,
            description: "StudyId: " + results.studyNumber + patientTitle,
            showButtons: false,
            canNavigate: false,
            navigateUrl: '',
            disableButtons: true
          };
        }));
      this.getPatientEnrollmentEvents();


    }));
  }

  /**
 * Destroy implementation - closes all the subscriptions
 */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    );
  }

  /**
   * Gets the list of the enrollment events for this patient/study
   */
  getPatientEnrollmentEvents() {

    this.subscriptions.push(this.patientService.getPatientEnrollmentEvents(this.caseDetailId)
      .subscribe(results => {
        // we want the protocol first and then the anciallaries sorted by name
        this.patientEnrollmentList = _.orderBy(results, ['studyTypeCode', 'studyNumber'], ['desc', 'asc']);
      }
      ));
  }


  enrollmentAction(row: PatientEnrollmentCorrection) {
    if (row.studyTypeCode === 'ancillary' && row.actionName === 'Register' && row.actionEnabled) {
      this.registerToAncillary(row);
    } else if (row.actionName === 'Delete' && row.actionEnabled) {
      this.deleteEnrollment(row);
    }
  }

  deleteAll() {
    const enrollmentInfo = this.patientEnrollmentList[0];
    const studyNumber = enrollmentInfo.studyNumber;
    this.deleteConfirmed(enrollmentInfo, true).subscribe(isConfirmed => {
      if (isConfirmed) {
        this.patientService.deleteEntirePatient(enrollmentInfo.caseDetailId)
          .subscribe(() => {
          }, (responseError) => {
            this.displayErrorModal(responseError);
          }, () => {
            console.log("Subject deleted");
            //display success modal and if no enrollment events left navigate off page
            this.displaySuccessModal("Subject removed from study \n" + studyNumber).subscribe(result => {
              console.log("Subject deleted... return to study or home landing page");
              //patient doesn't exist... navigate off page  
              this.patientDeletedNavigateBack();
            });
          });
      }
    });
  }

  registerToAncillary(enrollmentInfo: PatientEnrollmentCorrection) {
    const parentCaseEventId = enrollmentInfo.parentCaseEventId; // caseEventId for related parent case event record
    const ancillaryStudyId = enrollmentInfo.studyId;
    const ancillaryStudyNumber = enrollmentInfo.studyNumber;
    this.patientService.registerPatientToAncillary(parentCaseEventId, ancillaryStudyId)
      .subscribe(() => {
      }, (responseError) => {
        this.displayErrorModal(responseError);
      }, () => {
        console.log("Subject registered");
        //re-get enrollment information for this patient
        this.getPatientEnrollmentEvents();
        this.displaySuccessModal("Subject registered to study " + ancillaryStudyNumber).subscribe(() => { });
      });
  }



  /**
   * backend will determine if  can delete entire patient or just current event or delete ancillary
   * @param enrollmentInfo
   */
  deleteEnrollment(enrollmentInfo: PatientEnrollmentCorrection) {
    const studyNumber = enrollmentInfo.studyNumber;
    this.deleteConfirmed(enrollmentInfo, false).subscribe(isConfirmed => {
      if (isConfirmed) {
        this.patientService.deleteEnrollment(enrollmentInfo)
          .subscribe(() => {
          }, (responseError) => {
            this.displayErrorModal(responseError);
          }, () => {
            console.log("Subject deleted");
            //tracking number no longer needed
            this.trackingNum = null;
            //re-get enrollment information for this patient
            this.getPatientEnrollmentEvents();
            //display success modal and if no enrollment events left navigate off page
            this.displaySuccessModal(`Subject removed from ${enrollmentInfo.eventName} event on study ${studyNumber}`).subscribe(result => {
              if (this.patientEnrollmentList.length === 0) {
                console.log("subject deleted... return to study or home landing page");
                //patient doesn't exist... navigate off page  
                this.patientDeletedNavigateBack();
              }
            });
          });
      }
    });

  }


  /**
   * confirm user really wants to delete
   * @param enrollmentInfo
   * @param deleteAll -- user hit delete all button
   */
  deleteConfirmed(enrollmentInfo: PatientEnrollmentCorrection, deleteAll: boolean): Observable<boolean> {
    if (enrollmentInfo.studyTypeCode === 'protocol') {
      let variableData: string = "genericMessage:This will also delete the subject from any associated Ancillary studies on this event";
      if (deleteAll) {
        //if they hit the delete all button, display slightly different button
        variableData = "genericMessage:This will remove all associations of subject to this study.";
      }

      const modalArg: ModalDialogArg =
      {
        contentType: "GenericYesNo",
        dialogType: "modal-warn",
        displayMessage: `Delete ${deleteAll ? 'Subject' : 'Event'}?`,
        displayName: "yes-no",
        variableData: variableData,
      };

      const result = this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg);
      return result.asObservable().pipe(tap(x => of(x)));
    }

    return of(true);
  }

  /**
   * display error modal
   * @param responseError
   */
  displayErrorModal(responseError) {
    // 400 messages need to be handled here... others will be caught in the standard handler
    // there really are no validation errors (400) that we expect
    if ((responseError && responseError.error && responseError.error.code === 400)) {
      const msg = (responseError && responseError.error && responseError.error.message) ? responseError.error.message : responseError.message;
      const variableData: string = 'error:' + msg;
      // let msg = (error.message) ? error.message : error.error.message;
      const modalArg: ModalDialogArg = {
        contentType: 'GeneralException',
        dialogType: 'modal-error',
        displayMessage: '',
        displayName: '',
        variableData: variableData
      };
      //  variableData.genericMessage
      this.studySetupService.showModalDialog(this.viewContainer, modalArg)
        .subscribe(actionResult => {
        });
    }
  }

  /**
   * Display success message.   if patient totally delete, navigate from page
   * @param msg
   * @param isDone
   */
  displaySuccessModal(msg: string): Observable<any> {
    const modalArg: ModalDialogArg = {
      contentType: 'GeneralSaveSuccess',
      dialogType: 'modal-warn',
      displayMessage: msg,
      displayName: '',
      variableData: 'continueLabel:OK'
    };
    const result = this.studySetupService.showModalDialog(this.viewContainer, modalArg);
    return result.asObservable().pipe(tap(x => of(x)));
  }

  /**
   * Navigate back to the patient landing
   */
  navigateBack() {
    const path: string = '/patient/' + this.studyId + '/' + this.patientId + '/' + this.caseDetailId;
    this.router.navigate([path], {
      queryParams: {
        'navBack': this.navBackPageEnum,
        'trackingNum': this.trackingNum,
        'selectedCaseEventId': this.selectedCaseEventId
      }
    });
  }

  /**
   * Patient has been totally deleted.   We want to navigate back to eitehr home page or study landing
   */
  patientDeletedNavigateBack(): void {
    if (this.navBackPageEnum === globalConst.LANDING_PAGE_ENUM_STUDY) {
      // navigate to the study landing
      this.router.navigate(['/studies', this.studyId], { relativeTo: this.route });
      return;
    }
    // default it to navigate to home page
    this.router.navigate(['/home']);
  }

  // reference for angular 9
  saveChanges() {

  }

  cancelChanges() {

  }


}
