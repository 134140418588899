import { Code } from "./code";
import { Organization } from "./organization";
import { PatientInfo } from "./patient-info";
import { Person } from "./person";
import { StudyReservationSlotGroup } from "./study-reservation-slot-group";
import { StudySchemaEventReservationGroup } from "./study-schema-event-reservation-group";

export class SubjectReservation  {
  constructor(
    public subjectReservationId: number | null,
    public reservedBy: Person | null,
    public organizations: Organization[] | null,
    public patientInfo: PatientInfo | null,
    public contactStaff: Person | null,
    public studyReservationSlotGroup :StudyReservationSlotGroup,
    public caseEventId: number | null,
    public dateReserved:Date,
    public reservationStatus: Code,
    public reservationStatusDate: Date | null,
    public expirationDate : Date | null,
    public schemaStep :string | null,
    public tupleVersion:number | null,
    public subjectRegistrationId?: string,
    public renewalFlag?: boolean,
    public removeFlag?: boolean,
    public reservationExpired?:boolean,
    public dateEmailSent?: Date ,
    public studySchemaEventId?: number,
) { }
}
