import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from 'app/app-config/app-config.module';
import { HeaderBarComponent } from 'app/common/header-bar/header-bar.component';
import * as ACTIVITY_NAMES from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import { HeaderBar } from 'app/common/model/header-bar';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { PreviewPanelModel } from 'app/common/model/preview-panel-model';
import { SimulationEnrollmentEvent } from 'app/common/model/simulation/simulation-enrollment-event';
import { StudyAccrualCount } from 'app/common/model/study-accrual-count';
import { StudyAttribute } from 'app/common/model/study-attribute';
// Models
import { StudyRecord } from 'app/common/model/study-record';
import { StudySchema } from 'app/common/model/study-schema';
import { StudySchemaEvent } from 'app/common/model/study-schema-event';
import { StudySlotGroup } from 'app/common/model/study-slot-group';
import { StudyValidateRegistrationInterfaceResponse } from 'app/common/model/study-validate-registration-interface-response';
import { SchemaDashboardService } from 'app/common/services/schema/schema-dashboard.service';
import { SimulationInteractionService } from 'app/common/services/simulation/simulation-interaction.service';
import { StudySimulationService } from 'app/common/services/simulation/study-simulation.service';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { StudySignOffService } from 'app/common/services/study-sign-off.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { EMPTY, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, first, tap } from 'rxjs/operators';
import { SearchCriteria } from '../common/model/search-criteria';
import { SearchInfo } from '../common/model/search-info';
import { StudySubscription } from '../common/model/study-subscription';
import { SearchService } from '../common/services/search.service';
// Services
import { StudyServiceBase } from '../common/services/study-service-base.service';
import { UserService } from '../common/services/user.service';
import { SchemaManagementService } from 'app/common/services/schema/schema-management.service';





@Component({
  selector: 'mc-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.scss']
})
export class StudyComponent implements OnInit, AfterViewInit, OnDestroy {

  public studyRecord: StudyRecord;
  public accrualSetting: StudyAccrualCount = { currentAccrual: 0, totalAccrual: 0, expectedAccrualMonth: 0, accrualButtonEnable: false };
  public studyId: number;
  public activePatientsCount: number;
  public caseEventCount: number;
  public ancillaryStudyCount = 0;
  public validateRegistrationInterfaceResponse: StudyValidateRegistrationInterfaceResponse;
  public studyAttributes: StudyAttribute[];
  public events: SimulationEnrollmentEvent[];
  public schemas: StudySchema[];
  public enableSimulation: boolean;


  initObservablesSubscription: Subscription;
  subscriptions: Subscription[] = [];

  /**
   * Object holding the subscription info
   */
  subscriptionInfo: StudySubscription;

  /**
   * Object holding the original subscription info
   */
  originalSubscriptionInfo: StudySubscription;

  /**
   * Search Criteria object to execute patient search on this page
   */
  patientSearchCriteria: SearchCriteria = {
    searchType: globalConst.SEARCH_CRITERIA_TYPE_PATIENT,
    searchTerm: '',

  };

  initialSchema:StudySchema = new StudySchema(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null ,null);
  currentSchema: StudySchema; 

  /**
   *Reservation studySchemaEventId and Status 
   *
   */
  reservationEvent: StudySchemaEvent;


  studyLandingPageEnum: number = globalConst.LANDING_PAGE_ENUM_STUDY;

  // /**
  //  * Holds the list of active patient retrieved from the service
  //  */
  // activePatients: StudyPatientSearchResult[] = [];

  /**
   * model for the header bar
   */
  headerModel: HeaderBar;
  /**
   * Reference to the child header component
   */
  @ViewChild(HeaderBarComponent)
  private headerBar: HeaderBarComponent;


  SEARCH_TYPE_NONE = 0;
  SEARCH_TYPE_PATIENT = 1;
  SEARCH_TYPE_CONTACTS = 2;
  SEARCH_TYPE_ACCRUED_PATIENT = 3;
  SEARCH_TYPE_ACTIVE_PATIENT = 4;
  SEARCH_TYPE_ANCILLARY = 5;
  SEARCH_TYPE_DOSAGE = 6;
  SEARCH_TYPE_SLOT_RESERVATIONS = 7;
  SEARCH_TYPE_SLOT_AVAILABILITY = 8;
  formTitle = '';

  downloadDocType: number = 0;
  isPdfDoc: boolean = true;

  SEARCH_DISPLAY = 0;

  public isNotifications = false;

  public reservationSlotGroups: StudySlotGroup;

  studySignOffStatus: string = null;
  currentSchemaEffectiveDate: Date = null;

  /** custom handling of the preview page********************* */

  /**
   * Model to drive the orientation and horizontal/vertical states of the preview window
   */
  previewModelHome: PreviewPanelModel = new PreviewPanelModel();

  previewTitle = 'Results';

  constructor(
    private studyService: StudyServiceBase,
    private searchService: SearchService,
    private studySetupService: StudySetupService,
    private studySimulationService: StudySimulationService,
    private simulationInteractionService: SimulationInteractionService,
    private userService: UserService,
    private schemaDashboardService: SchemaDashboardService,
    private viewContainer: ViewContainerRef,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig,
    private route: ActivatedRoute,
    private studySignOffService: StudySignOffService,
    private schemaManagementService: SchemaManagementService) {
    this.subscriptions.push(
      router.events.pipe(filter(event =>
        event instanceof NavigationStart))
        .subscribe((result: any) => {
          const previousQuery: SearchInfo = this.searchService.getSearchQuery(this.studyLandingPageEnum);
          if (previousQuery && previousQuery.searchCriteria) {
            previousQuery.searchCriteria.isPreviewPanelCollapsed = this.previewModelHome.isCollapsed;
          }
        }));

  }

  /**
   * used, when navigating back to study landing page where we need to show preview pane with results.
   * 
   */
  ngAfterViewInit(): void {

    setTimeout(() => {
      this.subscriptions.push(
        this.route.queryParams.subscribe(qParam => {
          const action = qParam.action;
          if (action === 'slot_reservations') {
            this.manageSlotReservations();
          }
        })
      );
    });
  }


  /**
   * Init method implementation for the component
   */
  ngOnInit() {

    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.studyId = params.studyId;
        if (this.studyId > 0) {
          this.patientSearchCriteria.studyId = this.studyId;
          this.initializeTileData();
        }

        this.subscriptions.push(
          this.studyService.getStudy(this.studyId)
            .subscribe(results => {
              // console.log("results", results);
              this.studyRecord = results;
              // initialize the model for the header
              this.headerModel = new HeaderBar('Study Landing: ', results.studyNumber, true, results.longDescription, false);
              this.checkForSearchResults();

            })
        );
        this.subscriptions.push(
          this.studyService.validateStudyAttributeRegistrationInterface(this.studyId)
            .subscribe(result => {
              this.validateRegistrationInterfaceResponse = result;
            })
        );
        this.subscriptions.push(
          this.studySetupService.getStudyAttributes(this.studyId)
            .subscribe(result => {
              this.studyAttributes = result;
            })
        );

        // determine if user authorize to simulation activities and study has dynamic allocation that can be simulated
        this.subscriptions.push(
          this.studySimulationService.checkSimulation(this.studyId)
            .subscribe(result => {
              this.enableSimulation = result == "true";
            })
        );

        // find reservation event and status
        this.subscriptions.push(
          this.studyService.getReservationEventStatus(this.studyId)
            .subscribe(result => {
              this.reservationEvent = result;
            })
        );

        // Get available Slot Groups
        this.subscriptions.push(
          this.studySetupService.getStudySlotGroups(this.studyId)
            .subscribe(result => {
              this.reservationSlotGroups = result;
            })
        );

         // Get the schema versions
         this.subscriptions.push(
          this.schemaManagementService.getSchemaVersions(this.studyId)
            .subscribe(result => {
              this.schemas = result;
              if (this.schemas?.length > 0) {
      
                // Use Array.prototype.reduce to find the schema with the latest effective date
                // Filter out schemas with effective dates <= current date
                // Use reduce with an initial value to handle the case of an empty array
                this.currentSchema = this.schemas
                  .filter(x => x?.effectiveDate < new Date())
                  .reduce((max, current) => (max && max?.effectiveDate > current?.effectiveDate ? max : current), this.initialSchema);
              }
            })
        );

        if (!this.isNotificationTileDisabled()) {
          this.subscriptions.push(this.studyService.getSubscriptionInfo(this.studyId)
            .pipe(
              first(),
              tap({
                next: results => {
                  this.subscriptionInfo = results;
                  this.cloneSubscription();
                },
                complete: () => {
                  // no nothing
                },
              }),
              catchError((err) =>
                // to ensure that absence of subscription does not throw exception
                EMPTY
              )
            ).subscribe());
        }

        if (!this.isStudySignOffTileHidden()) {
          this.subscriptions.push(this.studySignOffService.getStudySignOffStatus(this.studyId)
            .subscribe(result => {
              this.studySignOffStatus = result;
            }));
        }
      })
    );
  }

  getRegisterTileButtonText(): string {
    if (this.config.version == 'v2') {
      return 'Register Subject';
    } else {
      return 'Register Patient';
    }
  }

  getAccrualsButtonText(): string {
    if (this.config.version == 'v2') {
      return 'View My Subjects';
    } else {
      return 'View My Patients';
    }
  }

  getActiveListButtonText(): string {
    if (this.config.version == 'v2') {
      return 'View Active Subjects';
    } else {
      return 'View Active Patients';
    }
  }

  getCaseEventsText(): string {
    if (this.config.version == 'v2') {
      return 'subjects';
    } else {
      return 'patients';
    }
  }


  /**
   * Used to display the date on which the status for the study was set, for the status tile
   */
  getDate() {
    if (this.studyRecord && this.studyRecord.currentMsrStudyStatus && this.studyRecord.currentMsrStudyStatus.statusValidFromDate) {
      return 'as of ' + moment(this.studyRecord.currentMsrStudyStatus.statusValidFromDate).format('MM/DD/YYYY');
    } else {
      const today = new Date;
      return 'as of ' + moment(today).format('MM/DD/YYYY');
    }
  }


  /**
   * True if the user has subscribed to the study
   */
  isSubscribedToStudy(): boolean {
    let isSubscribed = false;
    if (this.subscriptionInfo) {
      isSubscribed = this.subscriptionInfo.subscribe;

    }
    return isSubscribed;
  }


  /**
   * Updates the current subscription state
   */
  updateSubscription() {
    if (!this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_NOTIFICATION_SUBSCRIBE, this.studyId)) {
      return;
    } else {
      this.subscriptionInfo.subscribe = !this.subscriptionInfo.subscribe;
      this.studyService.updateSubscription(this.studyId, this.subscriptionInfo)
        .subscribe(results => {
          this.subscriptionInfo = results;
          this.cloneSubscription();
        });
    }
  }



  /**
   * Clones the Data
   * @param revert true if the data should be reverted to its original values
   */
  cloneSubscription(revert: boolean = false) {
    if (revert) {
      this.subscriptionInfo = _.cloneDeep(this.originalSubscriptionInfo);

    } else {
      this.originalSubscriptionInfo = _.cloneDeep(this.subscriptionInfo);
    }
  }

  /**Navigates to the study setup section */
  editStudy() {
    this.router.navigate(['/admin/studies/' + this.studyId + '/setup/protocol-info']);
  }

  /**
   * Navigates to Patient Registration
   * Study must be open for accrual
   * Study's Registration Interface study attribute must be set to either Research Registration App or Registration Office Only
   */
  registerPatient() {
    //Check for reservation schema event on the study with status "OPEN" (and no slot groups in enroll status)
    if (this.checkSlotReservationOpenStatus() && !this.isEnrollReservationGroupExists()) {
      this.showRegistrationNotAvailable('Register subject via the Reservation Tile');
    } else {
      if (!this.isStudyOpenForAccrual()) {
        this.showRegistrationNotAvailable('This study is not currently open to accrual. Contact the Registration office.');
      } else if (!this.validateRegistrationInterfaceResponse.valid) {
        this.showRegistrationNotAvailable(this.validateRegistrationInterfaceResponse.message);
      } else {
        const caseDetailId = 0;
        const caseEventId = 0;
        const patientId = '0';
        const url: string = '/studies/' + this.studyId + '/registration/' + patientId + '/' + caseDetailId + '/' + caseEventId;
        const qParams = this.getPatientRegQueryParams();
        this.router.navigate([url], {
          queryParams: qParams
        });
      }
    }
  }

  /**
   * This will check for reservation schema event OPEN status on the schema.  
   * @returns true - if status is Open, else false
   * 
   */
  checkSlotReservationOpenStatus(): boolean {
    let isStatusOpen = false;
    if (this.reservationEvent?.studySchemaEventAttributeList.length > 0) {
      this.reservationEvent.studySchemaEventAttributeList.forEach(attribute => {
        if (attribute.attributeCode === 'ReservationStatus' && attribute.attributeTextValue === 'Open') {
          isStatusOpen = true;
        }
      });
    }
    return isStatusOpen;
  }

  //return true if any of the reservation groups have a status of Enroll
  isEnrollReservationGroupExists(): boolean {
    if (this.reservationEvent?.studySchemaEventReservationGroupList?.length > 0) {
      for (const group of this.reservationEvent.studySchemaEventReservationGroupList) {
        if (group.reservationGroupStatus?.code === 'Enroll') {
          return true;
        }
      };
    }
    return false;
  }

  /**
   * This will check for reservation schema event DEACTIVATED status on the schema.
   * @returns true - if status is Deactivated, else false
   * 
   */
  checkSlotReservationDeactivatedStatus(): boolean {
    let isStatusDeactivated = false;
    if (this.reservationEvent?.studySchemaEventAttributeList.length > 0) {
      this.reservationEvent.studySchemaEventAttributeList.forEach(attribute => {
        if (attribute.attributeCode === 'ReservationStatus' && attribute.attributeTextValue === 'Deactivated') {
          return isStatusDeactivated = true;
        }
      });
    }
    return isStatusDeactivated;
  }

  /**
    * Registration office can register patients when study is in Accruing, Accrual Closed, Temporarily Closed
    * others with the Patient Registration activity can register patients when study in Accruing status
    */
  isStudyOpenForAccrual() {
    // make sure status information is set
    if (this.studyRecord == null || this.studyRecord.status == null || this.studyRecord.currentMsrStudyStatus == null) {
      return false;
    }

    // the study must be in Ready status
    if (this.studyRecord.status.studyStatusDesc === 'Ready') {
      // Registration Office
      if (this.userService.hasCreateAccess(ACTIVITY_NAMES.ACTIVITY_PATIENT_REGISTRATION) ||
        this.userService.hasCreateAccess(ACTIVITY_NAMES.ACTIVITY_SLOT_RESERVATION)) {
        if (['Accruing', 'Accrual Closed', 'Temporarily Closed'].indexOf(this.studyRecord.currentMsrStudyStatus.statusDescription) > -1) {
          return true;
        }

        // Other person allowed to register patients
      } else if (this.userService.hasCreateAccessByStudy(ACTIVITY_NAMES.ACTIVITY_PATIENT_REGISTRATION, this.studyId) ||
        this.userService.hasCreateAccessByStudy(ACTIVITY_NAMES.ACTIVITY_SLOT_RESERVATION, this.studyId)) {
        if (this.studyRecord.currentMsrStudyStatus.statusDescription === 'Accruing') {
          return true;
        }
      }
    }
    return false;
  }


  // showStudyNotOpenToAccrual(): Observable<boolean> | boolean {
  showRegistrationNotAvailable(message: string): Observable<boolean> | boolean {
    const modalArg = new ModalDialogArg('modal-warn', 'CannotNavigate', null, null, message);
    const result = this.studyService.showModalDialog(this.viewContainer, modalArg);
    return result.asObservable().pipe(tap(x => of(x)));
  }

  isStatusTileDisabled() {
    return !(this.userService.hasCreateAccess(ACTIVITY_NAMES.ACTIVITY_PATIENT_REGISTRATION) || this.userService.hasCreateAccessByStudy(ACTIVITY_NAMES.ACTIVITY_PATIENT_REGISTRATION, this.studyId)) || !this.studyRecord;
  }

  isNotificationTileDisabled(): boolean {
    this.isNotifications = !(this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_NOTIFICATION_SUBSCRIBE, this.studyId));
    return this.isNotifications;

  }

  isDosageTileDisabled(): boolean {
    const dosageDisabled = true;
    if (this.studyAttributes) {
      const dosageAttribute = this.studyAttributes.find(y => y.attributeCode == 'Dosage');
      if (dosageAttribute) {
        return !dosageAttribute.selected;
      }
    }
    return dosageDisabled;
  }

  isStudySignOffTileHidden(): boolean {
    return !this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_STUDY_SIGN_OFF, this.studyId);
  }

  isStudySignOffDisabled(): boolean {
    const attribute = this.studyAttributes?.filter(
      sa => sa.attributeCode === 'Study Sign Off' && sa.selected);
    if (attribute?.length === 1) {
      return false;
    }
    return true;
  }

  /**
   * Navigate to the Double Blind setup pages
   */
  manageDoubleBlind() {
    // If the user has update access to the bottle assignments, assume they are a pharmacist and route directly there
    if (this.userService.hasModifyAccess(ACTIVITY_NAMES.ACTIVITY_DOUBLE_BLIND_KIT_ASSIGNMENTS)) {
      this.router.navigate(['/admin/studies/' + this.studyId + '/setup/double-blind-setup/kit-assignments']);
    } else {
      this.router.navigate(['/admin/studies/' + this.studyId + '/setup/double-blind-setup/supply-and-exceptions']);
    }
  }

  /**
  * Navigate to the CEvent Landing page
  */
  manageCaseEventProblems() {
    if (this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_STUDY_CASE_EVENT, this.studyId)) {
      this.router.navigate(['/studies/' + this.studyId + '/case-event']);
    }
  }


  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    if (this.initObservablesSubscription != null) {
      this.initObservablesSubscription.unsubscribe();
    }
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    );
  }

  /**Navigates to the study sign off page */
  goToStudySignOff() {
    this.router.navigate(['/admin/studies/' + this.studyId + '/sign-off']);
  }

  /**
   * Checks if the landing page has search results that were displayed previously
   * and restores it
   */
  checkForSearchResults() {
    const previousQuery: SearchInfo = this.searchService.getSearchQuery(this.studyLandingPageEnum);
    if (previousQuery) {
      if (previousQuery.searchCriteria && previousQuery.searchCriteria.studyId) {
        if (previousQuery.searchCriteria.studyId !== this.studyId) {
          // if this is a query for a different study, don't execute any searches
          this.searchService.setSearchQuery(this.studyLandingPageEnum, null);
          return;
        }
      }
      if (previousQuery.searchCriteria && previousQuery.searchCriteria.isPreviewPanelCollapsed != undefined) {
        this.previewModelHome.isCollapsed = previousQuery.searchCriteria.isPreviewPanelCollapsed;
      }
      if (previousQuery.searchTypeEnum == globalConst.SEARCH_TYPE_ENUM_ACCRUED_PATIENTS_FOR_STUDY) {
        setTimeout(() => {
          const criteria: SearchCriteria = {
            searchType: globalConst.SEARCH_CRITERIA_TYPE_ACCRUED_PATIENT,
          };
          this.initiateSearch(this.SEARCH_TYPE_ACCRUED_PATIENT, globalConst.SEARCH_TYPE_ENUM_ACCRUED_PATIENTS_FOR_STUDY,
            'Accruals Results', criteria, false, globalConst.ACCRUAL_PATIENT);
        });
      } else if (previousQuery.searchTypeEnum == globalConst.SEARCH_TYPE_ENUM_ACTIVE_PATIENTS_FOR_STUDY) {
        setTimeout(() => {
          const criteria: SearchCriteria = {
            searchType: globalConst.SEARCH_CRITERIA_TYPE_ACTIVE_PATIENT,
          };
          this.initiateSearch(this.SEARCH_TYPE_ACTIVE_PATIENT, globalConst.SEARCH_TYPE_ENUM_ACTIVE_PATIENTS_FOR_STUDY,
            'Active Results', criteria, true, 0);
        });

      } else if (previousQuery.searchTypeEnum == globalConst.SEARCH_TYPE_ENUM_ACTIVE_CONTACTS_FOR_STUDY) {
        const criteria: SearchCriteria = {
          searchType: globalConst.SEARCH_CRITERIA_TYPE_CONTACTS,
        };
        this.initiateSearch(this.SEARCH_TYPE_CONTACTS, globalConst.SEARCH_TYPE_ENUM_ACTIVE_CONTACTS_FOR_STUDY,
          'Study Contacts', criteria, true, 0);
      } else if (previousQuery.searchTypeEnum == globalConst.SEARCH_TYPE_ENUM_ANCILLARY_STUDIES_FOR_STUDY) {
        const criteria: SearchCriteria = {
          searchType: globalConst.SEARCH_CRITERIA_TYPE_ANCILLARY,
        };
        this.initiateSearch(this.SEARCH_TYPE_ANCILLARY, globalConst.SEARCH_TYPE_ENUM_ANCILLARY_STUDIES_FOR_STUDY,
          'Ancillary Studies', criteria, true, 0);
      }

    }
  }


  /**
   * Collapses the preview panel
   */
  collapseResultPanel($event) {
    this.previewModelHome.isCollapsed = true;
  }

  /**
   * True if the Search type is what is current
   * @param searchType The type of search being conducted
   */
  shouldHideResults(searchType: number): boolean {
    return this.SEARCH_DISPLAY != searchType;
    // if(this.SEARCH_DISPLAY == searchType){
    //   if(this.SEARCH_DISPLAY ==this.SEARCH_TYPE_ACCRUED_PATIENT){
    //     this.downloadDocType=globalConst.ACCRUAL_PATIENT;
    //     this.isPdfDoc=false;
    //   }else{
    //     this.isPdfDoc=true;
    //     this.downloadDocType=0;
    //   }
    //   return false;
    // }
    // return true;
  }

  /**
   * HAndles the click on the button on the Accruals tile
   */
  viewAccrualPatients(): void {
    const criteria: SearchCriteria = {
      searchType: globalConst.SEARCH_CRITERIA_TYPE_ACCRUED_PATIENT,
    };
    this.initiateSearch(this.SEARCH_TYPE_ACCRUED_PATIENT, globalConst.SEARCH_TYPE_ENUM_ACCRUED_PATIENTS_FOR_STUDY,
      'Accruals Results', criteria, false, globalConst.ACCRUAL_PATIENT);
    this.previewModelHome.isCollapsed = false;
  }

  /**
   * Handles the click button to view Active Patients
   */
  viewActivePatients(): void {
    const criteria: SearchCriteria = {
      searchType: globalConst.SEARCH_CRITERIA_TYPE_ACTIVE_PATIENT,
    };

    let title = 'Active Subjects';
    if (this.config.version == 'v1') {
      title = 'Active Patients';
    }
    this.initiateSearch(this.SEARCH_TYPE_ACTIVE_PATIENT,
      globalConst.SEARCH_TYPE_ENUM_ACTIVE_PATIENTS_FOR_STUDY,
      title, criteria, true, 0);
    this.previewModelHome.isCollapsed = false;
  }



  /**
   * Handles the click on the View Contacts button
   */
  viewStudyContacts() {
    const criteria: SearchCriteria = {
      searchType: globalConst.SEARCH_CRITERIA_TYPE_CONTACTS,
    };
    this.initiateSearch(this.SEARCH_TYPE_CONTACTS, globalConst.SEARCH_TYPE_ENUM_ACTIVE_CONTACTS_FOR_STUDY,
      'Study Contacts', criteria, true, 0);
    this.previewModelHome.isCollapsed = false;
  }

  /**
   * Handles the click on the View Ancillary Studies button
   */
  viewAncillaryStudies() {
    const criteria: SearchCriteria = {
      searchType: globalConst.SEARCH_CRITERIA_TYPE_ANCILLARY,
    };
    this.initiateSearch(this.SEARCH_TYPE_ANCILLARY, globalConst.SEARCH_TYPE_ENUM_ANCILLARY_STUDIES_FOR_STUDY,
      'Ancillary Studies', criteria, true, 0);
    this.previewModelHome.isCollapsed = false;
  }

  /**
   * Handles the click on the View Dosage button
   */
  viewDosage() {
    const criteria: SearchCriteria = {
      searchType: globalConst.SEARCH_CRITERIA_TYPE_DOSAGE,
    };
    this.initiateSearch(this.SEARCH_TYPE_DOSAGE, globalConst.SEARCH_TYPE_ENUM_DOSAGE_FOR_STUDY,
      'Dosage', criteria, false, 0);
    this.previewModelHome.isCollapsed = false;
  }

  /**
 * HAndles theclick on the Manage button on the Slot Reservation Tile
 */
  manageSlotReservations(): void {
    const criteria: SearchCriteria = {
      searchType: globalConst.SEARCH_CRITERIA_TYPE_SLOT_RESERVATIONS,
    };
    this.initiateSearch(this.SEARCH_TYPE_SLOT_RESERVATIONS, globalConst.SEARCH_TYPE_ENUM_SLOT_RESERVATIONS,
      'Study Reservations', criteria, false, 0);
    this.previewModelHome.isCollapsed = false;
  }

  /**
  * Handles the click on the View Slot Availablitlity on the Slot Reservation Tile
  */
  viewSlotGroupAvailability(): void {
    const criteria: SearchCriteria = {
      searchType: globalConst.SEARCH_CRITERIA_TYPE_SLOT_AVAILABILITY,
    };
    this.initiateSearch(this.SEARCH_TYPE_SLOT_AVAILABILITY, globalConst.SEARCH_TYPE_ENUM_SLOT_AVAILABILITY,
      'Slot Group Availability', criteria, false, 0);
    this.previewModelHome.isCollapsed = false;
  }

  navigateToSimulation() {
    this.router.navigate(['/admin/studies/' + this.studyId + '/randomization-simulation/run-simulations']);
  }

  isSimulationTileDisabled(): boolean {
    return !(this.enableSimulation);
  }

  createReservation() {
    if (!this.isStudyOpenForAccrual()) {
      this.showRegistrationNotAvailable('This study is not currently open to accrual.  Contact the Registration Office.');
    } else if (!this.reservationSlotGroups || this.reservationSlotGroups.studyReservationSlotGroups.length === 0) {
      this.showRegistrationNotAvailable('There are no slots currently available. For questions, contact the Registration Office.');
    } else if (!this.validateRegistrationInterfaceResponse.valid) {
      const unableToReserveMessage = "Unable to reserve slot using the Research Registration Application. Contact the Registration Office with questions.";
      if (this.validateRegistrationInterfaceResponse.message.includes("Unable to register patient")) {
        this.validateRegistrationInterfaceResponse.message = unableToReserveMessage;
      }
      const regOfficeReserveMessage = 'Contact the Registration Office to reserve slot on study.';
      if (this.validateRegistrationInterfaceResponse.message.includes('Contact the Registration Office to register')) {
        this.validateRegistrationInterfaceResponse.message = regOfficeReserveMessage;
      }
      this.showRegistrationNotAvailable(this.validateRegistrationInterfaceResponse.message);
    }
    else if (this.checkSlotReservationDeactivatedStatus()) {
      // if the Study Reservation event is Deactivated, display message: ‘Slot Reservations are no longer needed’
      this.showRegistrationNotAvailable('Slot Reservations are no longer needed.');
    } else {
      const reservationId: number = 0;
      this.router.navigate(['/studies/' + this.studyId + '/patients/reservation/' + reservationId]);
    }
  }

  /**
   * Handler to navigate back to the home page
   */
  navigateBack(): void {
    this.router.navigate(['/home']);
  }

  /**
     * True if the orientation is vertical and the state is collapsed
     */
  isCollapsedVertical(): boolean {
    return this.previewModelHome.isCollapsed && this.previewModelHome.isVertical;
  }



  /**
   * True if the orientation is horizontal and is collapsed
   */
  isCollapsedHorizontal(): boolean {
    return (this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }


  /**
   * True if the orientation is vertical and the state is Expanded
   */
  isExpandedVertical(): boolean {
    return (!this.previewModelHome.isCollapsed && this.previewModelHome.isVertical);
  }



  /**
   * True if the orientation is horizontal and is expanded
   */
  isExpandedHorizontal(): boolean {
    return (!this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }

  /** custom handling of the preview for the  page********************* */

  saveChanges() {
    // reference  for angular 9 upgrade
  }

  cancelChanges() {
    // reference  for angular 9 upgrade
  }

  /**
   * Get current validated schema version number
   * @returns 
   */
  getCurrentValidatedSchemaVersionNumber(): string { 
      if (this.currentSchema && this.currentSchema.studySchemaVersionNum) {
        return `Schema V${this.currentSchema.studySchemaVersionNum}`;
      }
    return ""; 
  } 

  /**
   * Get the Query String For Patient Registration
   */
  private getPatientRegQueryParams(): any {
    const queryParams = {
      'action': globalConst.QS_PARAM_ACTION_REGISTER
    };
    return queryParams;
  }

  /**
   * Initializes all the data needed for the tiles
   */
  private initializeTileData(): void {
    // call the different service to fetch the data on the tiles
    this.getAccrualCount();
    this.getActivePatientsCount();
    this.getCaseEventCount();
    this.getAncillaryStudyCount();
  }

  /**
  * Gets the Case Problem Events
  */
  private getCaseEventCount(): void {
    if (this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_STUDY_CASE_EVENT, this.studyId)) {
      this.subscriptions.push(
        this.studyService.getCaseProblemCount(this.studyId)
          .subscribe(result => {
            this.caseEventCount = result.count;
          })
      );
    }
  }

  /**
   * Gets the accrual Count from the service
   */
  private getAccrualCount(): void {
    if (this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_STUDY_PATIENT_ACCRUAL, this.studyId)) {
      this.subscriptions.push(
        this.studyService.getAccrualsForAccrualEvent(this.studyId)
          .subscribe(result => {
            this.accrualSetting = result;
          })
      );
    }
  }
  /**
   * Fetch the active patients count
   */
  private getActivePatientsCount() {
    if (this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_STUDY_PATIENT_ACTIVE, this.studyId)) {
      this.subscriptions.push(
        this.studyService.getActivePatientCount(this.studyId)
          .subscribe(result => {
            this.activePatientsCount = result.count;
          }));

      // this.searchService.getActivePatients(this.studyId)
      // .subscribe(res => {
      //   this.activePatients = res;
      //   this.activePatientsCount = this.activePatients.length;
      // }));
    }
  }

  /**
   * Gets the Count of Ancillary studies for the current study
   */
  private getAncillaryStudyCount() {
    if (this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_STUDY_ANCILLARIES, this.studyId)) {
      this.subscriptions.push(
        this.studyService.getAncillaryStudyCount(this.studyId)
          .subscribe(result => {
            this.ancillaryStudyCount = result.count;
          })
      );
    }
  }

  /**
   *
   * @param searchDisplayType The search type for the page
   * @param searchTypeEnum The search Type global enum
   * @param previewTitle The preview title to display on the ui
   * @param searchCriteria The search criteria object
   */
  private initiateSearch(searchDisplayType: number, searchTypeEnum: number,
    previewTitle: string, searchCriteria: SearchCriteria, isPdfDoc: boolean, downloadDocType: number): void {
    this.SEARCH_DISPLAY = searchDisplayType;
    searchCriteria.studyId = this.studyId;

    const searchInfo: SearchInfo = {
      searchCriteria: searchCriteria,
      searchTypeEnum: searchTypeEnum
    };
    this.searchService.setSearchQuery(this.studyLandingPageEnum, searchInfo);
    this.searchService.setSearchCriteria(searchCriteria);
    this.previewTitle = previewTitle;
    this.isPdfDoc = isPdfDoc;
    this.downloadDocType = downloadDocType;
  }


}
