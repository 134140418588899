import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchService } from 'app/common/services/search.service';
import { ActivatedRoute, Router } from '@angular/router';


import * as _ from 'lodash';
import * as globalConst from 'app/common/model/app-constants';
import { SearchCriteria } from 'app/common/model/search-criteria';
import { SearchInfo } from 'app/common/model/search-info';
import { StudyDosage } from 'app/common/model/study-dosage';


@Component({
  selector: 'mc-active-dosage',
  templateUrl: './active-dosage.component.html',
  styleUrls: ['./active-dosage.component.scss']
})
export class ActiveDosageComponent implements OnInit, OnDestroy {

  searchCriteria: SearchCriteria = null;
  subscriptions: Subscription[] = [];

  @Input() hostLandingPageEnum = 0;
  @Output() returnToSearchEvent = new EventEmitter();


  studyId = 0;

  results: StudyDosage[];
  rows: StudyDosage[];
  noResultsFound = false;

  /**
   * The search criteria that identifies the type of search
   */
  currentSearchCriteria: SearchInfo = null;

  showFilterRow = false;

  public columns: Array<any> = [
    {
      title: 'Dose Level', className: ['table-column-width'],
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Dose Level' },
      name: 'dosageLevel', sort: false
    },
    {
      title: 'Description', className: ['table-column-width'],
      display: true,
      name: 'dosageDesc', sort: false,
      // filtering: { filterString: '', placeholder: 'Filter by Description', sort: '' }
    },
    {
      title: 'Status', className: ['table-column-width'],
      name: 'dosageStatus.name',
      display: true,
      sort: false,
      // filtering: { filterString: '', placeholder: 'Filter by Status' }
    }
  ];

  /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: false,
    className: ['is-striped', 'is-bordered']
  };

  constructor(private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params['studyId']) {
          this.studyId = params['studyId'];
        }
      }));
      this.subscriptions.push(this.searchService.searchQuery$.subscribe(
        criteria => {
          this.currentSearchCriteria = this.searchService.getSearchQuery(this.hostLandingPageEnum)//  this.searchService.currentSearchQuery;
          this.searchCriteria = null;
          if (criteria && criteria.searchType == globalConst.SEARCH_CRITERIA_TYPE_DOSAGE) {
            this.searchCriteria = criteria;
            this.getActiveDosage();
          } else {
            this.results = null;
          }
        }
      ))
  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }

  /**
   * Retrieves the active dosage for this study
   */
  getActiveDosage(): void {
    if (this.currentSearchCriteria) {
      this.subscriptions.push(
        this.searchService.getDosages(this.studyId)
          .subscribe(res => {
            this.results = res.filter(y => y.dosageStatus.name == 'Active');
            this.noResultsFound = (this.results.length == 0);
            this.onChangeTable(this.config)
          }));
    }
  }

  returnToSearch() {
    this.fireEvent();
  }

  fireEvent() {
    this.returnToSearchEvent.emit();
  }

  /**
 * Sorts the data in the table based on the column and sort order
 * @param data The data currently being displayed on the table
 * @param config The associated configuration for the data being displayed
 */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      const previousVal = previous[columnName] ? previous[columnName].toLowerCase() : '';
      const currentVal = current[columnName] ? current[columnName].toLowerCase() : '';

      if (previousVal > currentVal) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousVal < currentVal) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
  * Main method to handle filtering, sorting of the table
  * @param config Configuration of the datatable
  * @param page The current page being displayed
  */
  public onChangeTable(config: any): any {
    let column: any = null;
    this.rows = [];
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.results, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;

  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name] != null) {
            if (column.name == 'firstName') {
              const fName: string = item[column.name] ? item[column.name].toLowerCase() : '';
              const lName: string = item['lastName'] ? item['lastName'].toLowerCase() : ''
              const fullName: string = fName + lName;
              return fullName.match(column.filtering.filterString.toLowerCase());
            }
            return item[column.name].toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name] != null && item[column.name] != undefined
          && item[column.name].toString().match(this.config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;
    return filteredData;
  }

}
