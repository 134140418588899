import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../../../app-config/app-config.module';
import { PatientEnrollmentEvent } from '../../model/patient-enrollment-event';
import { BaseService } from '../base-service';
import { StudySchemaEvent } from 'app/common/model/study-schema-event';



export class BasePatientService extends BaseService {
  http: HttpClient;
  config: AppConfig;
  serviceUrlPrefix: string;


  constructor(http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);

    this.config = config;
    this.http = http;
    this.serviceUrlPrefix = config.apiEndpoint;
  }



  /**
   * Returns the current enrollment event for the patient on a particular study
   * @param caseDetailId The caseDetailId
   */
  getCurrentEnrollmentEvent(caseDetailId: number): Observable<PatientEnrollmentEvent> {
    return this.http.get<PatientEnrollmentEvent>
      (this.serviceUrlPrefix + 'patients/enrollment-event/current/' + caseDetailId);
  }


  /**
   * Returns the first enrollment event for the patient on a particular study
   * @param studyId The study
   */
  getFirstEnrollmentEvent(studyId: number): Observable<PatientEnrollmentEvent> {
    return this.http.get<PatientEnrollmentEvent>
    (this.serviceUrlPrefix + 'studies/' + studyId + '/enrollment-event/first');
  }

  /**
   * Returns the Next enrollment event for the patient on a particular study
   * @param caseDetailId The caseDetailId
   */
  getNextEnrollmentEvent(caseDetailId: number): Observable<PatientEnrollmentEvent> {
    return this.http.get<PatientEnrollmentEvent>
    (this.serviceUrlPrefix + 'patients/enrollment-event/next/' + caseDetailId );
  }



  /**
   * Returns the enrollment events for the patient on a particular study
   * @param caseDetailId The caseDetailId
   */
  getEnrollmentEvents(caseDetailId: number): Observable<PatientEnrollmentEvent[]> {
    return this.http.get<PatientEnrollmentEvent[]>
    (this.serviceUrlPrefix + 'patients/enrollment-event/' + caseDetailId );
  }

  /**
   * Return already registered enrolled events from previous vesions.
   * @param caseDetailId 
   * @returns 
   */
  getAlreadyRegisteredEnrolledEvents(caseDetailId: number): Observable<StudySchemaEvent[]> {
    return this.http.get<StudySchemaEvent[]>
    (this.serviceUrlPrefix + 'patients/enrollment-event/' + caseDetailId +"/already-registered");
  }

  checkApprovedForRegistration(caseDetailId: number, studySchemaEventId: number): Observable<string> {
    return this.http.get(this.serviceUrlPrefix + 'patients/' + caseDetailId + '/validate-approvals/' + studySchemaEventId, {responseType: 'text'} );
  }

}
