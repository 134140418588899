import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdminRoutingModule } from "app/admin/admin-routing.module";
import { AdminTilesModule } from "app/home/admin-tiles/admin-tiles.module";
import { PreviewFormModule } from "../common/preview-form/preview-form.module";
import { McTableModule } from "./../common/directives/table/mc-table.module";
import { SharedModule } from "./../common/shared.module";
import { AdminComponent } from "./admin/admin.component";
import { NotificaitionSearchComponent } from "./notificaition-search/notificaition-search.component";
import { StudyCreateModule } from "./study-create/study-create.module";
import { StudyTemplateComponent } from "./study-template/study-template.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    McTableModule,
    AdminRoutingModule,
    PreviewFormModule,
    StudyCreateModule,
    AdminTilesModule,
  ],
  declarations: [
    AdminComponent,
    NotificaitionSearchComponent,
    StudyTemplateComponent,
  ],
  exports: [StudyCreateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
