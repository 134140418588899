import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import { StudyCcdr } from 'app/common/model-v2/study-ccdr';
import { StudyCreateCcdr } from 'app/common/model-v2/study-create-ccdr';
import { StudRecordCcdr } from 'app/common/model-v2/study-record-ccdr';
import { Code } from 'app/common/model/code';
import { ListOptionModel } from 'app/common/model/list-option';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { ExampleModel } from 'app/common/model/study-create-example-model';
import { StudyReferenceNumSection } from 'app/common/model/study-create-reference-num-section';
import { SectionLabelOptionModel } from 'app/common/model/study-create-section-label-option-model';
import { SectionModel } from 'app/common/model/study-create-section-model';
import { StudyTemplateMatch } from 'app/common/model/study-template-match';
import { StudyCreateService } from 'app/common/services/study-create.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-type-multi-subject',
  templateUrl: './type-multi-subject.component.html',
  styleUrls: ['./type-multi-subject.component.scss']
})
export class TypeMultiSubjectComponent implements OnInit, OnDestroy {

  // To hold all the subscription, and destroy them at later point of time.
  subscriptions: Subscription[] = [];

  // researchEntity dropdown values
  researchEntityTypes: Code[];

  // System Genereated value(Yes/No)
  refList: ListOptionModel[];

  /**
   * Holds the list of study section definitions retrieved based on the selected data center.
   * Page is dynamically built based on the sections with a type of "List", using the sectionLabel
   * and the codeDesc in the list of options for each section.
   */
  studySections: SectionModel[];
  /**
   * A filtered list of studySections, containing only those of type List, to be displayed on the UI
   */
  listStudySections: SectionModel[];
  /**
   * An array to bind the "assign reference number" section selections which are dynamically built.
   * This is needed to keep the bindings separate from each other.
   */
  sectionSelectionModels: SectionLabelOptionModel [] = [];

  selectedSysGeneratedRefNum: string;

  model: StudyCreateCcdr;

  applyStudyTemplate: boolean = false;

  relatedStudies: StudyCcdr;
  createdStudyId: number;

  exampleReference: string;
  referenceNumSections: Array<StudyReferenceNumSection>;

  isSponsorProtocolAlreadyGenerated: boolean;

  isSubjectExistForRelatedStudies: boolean;

  public _selectedStudyType: Code;

  showStudyTemplateQuestion: boolean = false;

  @Output() resetSelectedStudyType = new EventEmitter<Code>();


  /**
   * The velos study response returned from the call to Velos
   */
  velosData: StudRecordCcdr;

  // @Input() studyTypeId : number;

  /**
   * This is to find the change detection when input is changed so we can assign some default values.
   */
  @Input()
  public set selectedStudyType(selectedStudyType: Code) {
    this._selectedStudyType = selectedStudyType;
    this.multiSubjTypeChange();
  }

  /**
   * calls the emitter so that the parent component can reset the 'Study Type' field back to null (-- Select --)
   */
  onReset() {
    this.resetSelectedStudyType.emit(null);
  }


  constructor(
    private viewContainer: ViewContainerRef,
    private studyCreateService: StudyCreateService,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig,
    ) {}


    ngOnDestroy() {
      this.subscriptions.forEach(
        subs => {
          subs.unsubscribe();
          subs.closed;
        }
      )
    }

  ngOnInit() {
      this.initData();
      this.subscriptions.push(this.studyCreateService.getSystemGeneratedRef()
      .subscribe(results => this.refList = results));

      this.subscriptions.push(this.studyCreateService.getResearchEntityTypes()
      .subscribe(results => this.researchEntityTypes = results));
  }

  /**
   * when multiSubjType change then initialize values.
   */
  multiSubjTypeChange() {
    this.initData();
  }

 /**
   * Initializes the model for the UI to bind to
   */
  initData() {
    this.model = this.studyCreateService.initializeBlankStudyCcdr(this._selectedStudyType.id);
    this.studySections = null;
    this.listStudySections = null;
    this.applyStudyTemplate = false;
    this.showStudyTemplateQuestion = false;
    this.relatedStudies = {
      'studyNumber': '',
      'regSponsorProtocolNumber': '',
      'relatedStudies': null,
      'subjectType': ''
    };

  }

  isSelectedSubjectTypeMatchInVelosForStudyNumber(subjectType: string) {
    if (this._selectedStudyType.code == subjectType) {
      return true;
    }
    return false;
  }

  isSelectedStudyTypeFoundInRelatedStudies(relatedStudy: StudyCcdr): boolean {

    if (relatedStudy.relatedStudies != undefined && relatedStudy.relatedStudies.length > 0) {
      relatedStudy.relatedStudies.forEach(related => {
        if (related.sponsorProtocolId != undefined) {
          const splitProtocol = related.sponsorProtocolId.split('-');
          const subjectType = splitProtocol[1];
          if (this._selectedStudyType.code == subjectType) {
            this.isSubjectExistForRelatedStudies = true;
          }
        }
      });
    }


   return this.isSubjectExistForRelatedStudies;

  }

 /**
   * This will check if the questions is empty or not
   * true, if list of question available else false
   */
  isStudySectionAvailable(): boolean {
    return this.listStudySections != null && this.listStudySections.length > 0;
  }

   /**
   * This will return true if study section is empty or selected system generated refNum is No
   *
   */
  isStudySectionEmpty(): boolean {
    return this.listStudySections.length == 0 || this.selectedSysGeneratedRefNum == 'No';
  }

  /**
   * true, if selected sys generated ref num is Yes
   */
  isSysGeneratedRefNumSelected(): boolean {
    return this.selectedSysGeneratedRefNum != 'No';
  }


   /**
   * This will fetch data(short desc and long desc) from Velos Api
   */
  getVelosData() {
    const studyNumberUpper = this.model.studyNumber.toUpperCase().trim();
    this.subscriptions.push(this.studyCreateService.getVelosStudyCcdrDetails(studyNumberUpper)
      .subscribe(results => {
        this.velosData = results;

        // This will check if the selected subject type for MSR exist in velos. if not, throw an error.
        if (!this.isSelectedSubjectTypeMatchInVelosForStudyNumber(this.velosData.relatedStudyInfo.subjectType)) {
          let replacementText = this.velosData.relatedStudyInfo.subjectType;
          if (replacementText == null) {
            replacementText = '';
          } else {
            replacementText = ' (' + replacementText + ')';
          }
          const modalArg = new ModalDialogArg('danger', 'Custom', '', '', 'Subject type' + replacementText + ' for ' + this.model.studyNumber + ' does not match this study type.');
          this.subscriptions.push(
          this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
            .subscribe(actionResult => {
              this.initData();
            }));
          } else if (this.isSelectedStudyTypeFoundInRelatedStudies(this.velosData.relatedStudyInfo)) {
            this.relatedStudies = this.velosData.relatedStudyInfo ;
            const modalArg = new ModalDialogArg('danger', 'Custom', '', '', 'Subject type (' + this._selectedStudyType.code + ') for ' + this.model.studyNumber + ' already exists in related studies.');
            this.subscriptions.push(
            this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
              .subscribe(actionResult => {
                this.initData();
              }));
          } else {
            this.model.multipleSubjectType = true;
            this.model.studyTypeCode = this._selectedStudyType.code;
            this.model.longDescription = this.velosData.longDescription;
            this.model.shortDescription = this.velosData.shortDescription;
            this.model.acronym = this.velosData.acronym;
            this.model.dataCenterId = this.velosData.dataCenterId;
            this.model.dataCenter = this.velosData.dataCenter;
            this.model.sponsor = this.velosData.sponsor;
            this.model.sponsorId = this.velosData.sponsorId;
            this.model.randomCenter = this.velosData.randomCenter;
            this.model.randomCenterId = this.velosData.randomCenterId;
            let oldResearchEntityId = this.model.researchEntityId;
            this.model.researchEntityId = this.velosData.researchEntityId;
            if (oldResearchEntityId != this.model.researchEntityId) {
              this.showStudyTemplateQuestion = false;
              this.applyStudyTemplate = false;
            }
            const variableData: string = 'studyNumber:' + this.model.studyNumber;
            this.relatedStudies = this.velosData.relatedStudyInfo ;
              const modalArg = new ModalDialogArg('custom-save', 'VelosStudy', variableData);
              this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
                .subscribe(actionResult => {
                  if (this.velosData.relatedStudyInfo.regSponsorProtocolNumber != undefined) {
                        this.isSponsorProtocolAlreadyGenerated = true;
                        this.model.referenceNumber = this.velosData.relatedStudyInfo.regSponsorProtocolNumber;
                        this.selectedSysGeneratedRefNum = 'Yes';
                  } else {
                         // code to call protocol generator
                         this.onDataCenterUpdate();
                  }
                  if (this.velosData.researchEntityId != null && this.velosData.researchEntityId > 0) {
                    this.checkForMatchingStudyTemplate();
                  }
                });
          }

      }, error => {
        if (error && error.error && error.error.code && error.error.code == 400) {
          const modalArg = new ModalDialogArg('modal-warn', 'Custom', error.error.message);
          this.subscriptions.push(
          this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
            .subscribe(actionResult => {
              this.initData();
            }));
        } else {
        const modalArg = new ModalDialogArg('modal-warn', 'VelosStudy', null);
        this.subscriptions.push(
        this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
          .subscribe(actionResult => {
            this.initData();
          }));
        }
      })
    );
}

/**
   * This will get the list of questions and call the example endpoint.
   */
  onDataCenterUpdate() {
    this.model.assignmentGroupId = 0;
    this.model.referenceNumSections = null; // starting over with different data center
    this.exampleReference = '';
    this.subscriptions.push(this.studyCreateService.getListOfQuestions(this.model.dataCenterId, this.model.studyTypeId)
      .subscribe(results => {
        this.model.assignmentGroupId = results.assignmentGroupId;
        this.studySections = results.sections;
        this.filterListStudySections();
        if (this.model.assignmentGroupId > 0) {
          this.getProtocolNumberExample(this.model.assignmentGroupId , this.model.studyTypeId,  []);
        }
        this.selectedSysGeneratedRefNum = 'Yes';

      }, error => {
        console.log('Error getting list of study questions! ', error);
      })
    );


}

/**
   * Filter out any non-List type sections from the list of studySections for this data center
   */
  filterListStudySections() {
    this.listStudySections = [];
    this.studySections.forEach(section => {
      if (section.sectionType.toUpperCase().trim() == 'LIST') {
         this.listStudySections.push(section);
      }
    });
  }

  /**
   * Generate example for protocol number based on the information selected
   * @param groupId - protocol numbering assignment group
   * @param selections - values selected
   */
  getProtocolNumberExample(groupId, refTypeId, selections) {
    this.subscriptions.push(this.studyCreateService.getProtocolNumberExample(groupId, refTypeId, null, selections)
    .subscribe(results => {
      this.exampleReference = results.protocolNumber;
    }, error => {
      console.log('unable to create example refernce;')
    }));
  }

/**
   * Map the selected value (bound in sectionLabelTemp) to the correct attribute in the study model
   */
  onSelectChange(section: SectionModel, index: number) {
    let existingRns = false;
    const selections = [];
    this.exampleReference = '';
    if (this.model.referenceNumSections != null) {
      this.model.referenceNumSections.forEach(rns => {
        if (rns.sequenceNumSectionId == section.sequenceNumSectionId) {
          existingRns = true;
          rns.codeDesc = this.sectionSelectionModels[index].codeDesc;
          rns.sectionDetailId = this.sectionSelectionModels[index].sectionDetailId;

        }
      });
    } else {
      this.model.referenceNumSections = [];
    }

    if (!existingRns) {
      const rns = new StudyReferenceNumSection(section.sequenceNumSectionId,
        this.sectionSelectionModels[index].sectionDetailId,
        section.sectionLabel,
        this.sectionSelectionModels[index].codeDesc);
      this.model.referenceNumSections.push(rns);

    }

    if (this.model.referenceNumSections != null) {
      this.model.referenceNumSections.forEach(rns => {
        const example: ExampleModel = { 'sequenceNumSectionId': null, 'sectionDetailId': null };
        example.sequenceNumSectionId = rns.sequenceNumSectionId;
        example.sectionDetailId = rns.sectionDetailId;
        selections.push(example);
      }
      );
      this.getProtocolNumberExample(this.model.assignmentGroupId, this.model.studyTypeId, selections)
    }
  }


  createNewStudy() {
    // call v2 endpoint
    this.subscriptions.push(this.studyCreateService.createStudy_v2(this.model)
      .subscribe(results1 => {
        this.createdStudyId = results1.studyId;

        // Now that the study is created, see if we should apply a study configuration template
        if (this.applyStudyTemplate == true) {
          this.subscriptions.push(this.studyCreateService.applyStudyTemplate(this.createdStudyId)
          .subscribe(templateResults => {
            // success - show modal dialog as before
            let templateMsg = " Applied ";
            const variableData: string = 'studyNumber:' + results1.protocolNumber + ' Created' + '|studyTemplate:' + templateMsg;
            // StudySaveConfirm
            const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
            this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
              .subscribe(actionResult1 => {
                if (actionResult1) {
                  this.router.navigate(['/admin/studies/' + this.createdStudyId + '/setup/protocol-info']);
                } else {
                  this.router.navigate(['/home']);
                }
              });
            }, error => {
            if (error && error.error && error.error.code && error.error.code == 400) {
              let templateMsg = " Apply Failed: " + error.error.message;
              const variableData: string = 'studyNumber:' + results1.protocolNumber + ' Created' + '|studyTemplate:' + templateMsg;
              const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
              this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
                .subscribe(actionResult2 => {
                  if (actionResult2) {
                    this.router.navigate(['/admin/studies/' + this.createdStudyId + '/setup/protocol-info']);
                  } else {
                    this.router.navigate(['/home']);
                  }
                  });
            } else {
              let templateMsg = " Apply Failed";
              const variableData: string = 'studyNumber:' + results1.protocolNumber + ' Created' + '|studyTemplate:' + templateMsg;
              const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
              this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
                .subscribe(actionResult3 => {
                  if (actionResult3) {
                    this.router.navigate(['/admin/studies/' + this.createdStudyId + '/setup/protocol-info']);
                  } else {
                    this.router.navigate(['/home']);
                  }
                });
              }
          })
        );
        }
        else {
          //no template to apply, we are done

          const variableData: string = 'studyNumber:' + results1.protocolNumber;
          // StudySaveConfirm
          const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
          this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
            .subscribe(actionResult4 => {
              if (actionResult4) {
                this.router.navigate(['/admin/studies/' + this.createdStudyId + '/setup/protocol-info']);
              } else {
                this.router.navigate(['/home']);
              }
            });
          }


        
      }, error => {
        if (error && error.error && error.error.code && error.error.code == 400) {
          const modalArg = new ModalDialogArg('modal-warn', 'Custom', error.error.message);
          this.subscriptions.push(
            this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
              .subscribe(actionResult => {
                this.initData();
              }));
        } else {
          const modalArg = new ModalDialogArg('modal-warn', 'CreateFailed', null);
          this.subscriptions.push(
            this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
              .subscribe(actionResult => {
                // do nothing
              }));
        }
      })
    );

  }


 /**
   * handler for the cancel button. Resets all the information
   */
  cancelChanges(): void {
    const modalArg = new ModalDialogArg('modal-warn', 'Cancel', null);
    this.subscriptions.push(
      this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
        .subscribe(result => {
          if (result) {
            this.initData();
            this.onReset();
          }
        })
    )


  }

  checkForMatchingStudyTemplate() {
    if (this.model.studyTypeId >0 && this.model.researchEntityId > 0) {
      this.subscriptions.push(
        this.studyCreateService.getMatchingStudyTemplate(this.model.studyTypeId, this.model.researchEntityId)
        .subscribe(result => {
          if (result) {
            let templateMatch: StudyTemplateMatch = result;
            if (templateMatch.matchFound == true) {
              this.showStudyTemplateQuestion = true;
              this.applyStudyTemplate = true; // default to yes
            }
            else {
              this.showStudyTemplateQuestion = false;
              this.applyStudyTemplate = false;
            }
          }
            else { // no result
              this.showStudyTemplateQuestion = false;
              this.applyStudyTemplate = false;
            }
        })
      )
    }
    else {
      this.showStudyTemplateQuestion = false;
      this.applyStudyTemplate = false;
    }
  }

}
