import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppConfigModule } from 'app/app-config/app-config.module';
import { SharedModule } from '../shared.module';
import { DateComponent } from './date/date.component';
import { DropDownComponent } from './drop-down/drop-down.component';
import { InputCheckBoxComponent } from './input-check-box/input-check-box.component';
import { InputTextComponent } from './input-text/input-text.component';
import { PreviewFormComponent } from './preview-form.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { TimeComponent } from './time/time.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        AppConfigModule
    ],
    declarations: [
        PreviewFormComponent,
        InputCheckBoxComponent,
        InputTextComponent, RadioButtonComponent, DateComponent, DropDownComponent, TimeComponent
    ],
    exports: [
        PreviewFormComponent,
        InputCheckBoxComponent,
        InputTextComponent
    ],
    providers: []
})
export class PreviewFormModule { }
