import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ACTIVITY_NOTIFICATION_SETUP } from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import { EventArg } from 'app/common/model/event-arg';
import { GroupNotificationEvent } from 'app/common/model/group-notification-event';
import { NotificationApprovalType } from 'app/common/model/notification-approval-type';
import { NotificationCode } from 'app/common/model/notification-code';
import { SchemaEventAttributeOption } from 'app/common/model/schema-event-attribute-option';
import { StudyNotification } from 'app/common/model/study-notification';
import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { UserService } from 'app/common/services/user.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationAccrualDetail } from '../../../common/model/notification-accrual-detail';
import { StudySchemaEvent } from '../../../common/model/study-schema-event';
import { SchemaDashboardService } from '../../../common/services/schema/schema-dashboard.service';
import { StudySetupService } from '../../../common/services/study-setup.service';
import { ConfigurationBaseComponent } from '../configuration-base/configuration-base.component';

@Component({
  selector: 'mc-group-configuration',
  templateUrl: './group-configuration.component.html',
  styleUrls: ['./group-configuration.component.scss'],
})
export class GroupConfigurationComponent   extends ConfigurationBaseComponent implements OnInit, OnDestroy{
  /**
   * The studyd for this notification
   */
  studyId = 0;

  // The list of notification types for the dropdown
  public notificationTypesList: NotificationCode[] = [];

  // The list of notification levels for the dropdown
  public accrualPointsList: NotificationCode[] = [];

  // The list of group notification events for the dropdown
  public groupEventList: GroupNotificationEvent[] = [];

  // The list of notification accrual types for the dropdown
  public accrualTypesList: NotificationCode[] = [];

  public approvalTypesList: NotificationCode[] = [];

  public subscriptions: Subscription[] = [];

  public accrualTypeSelection = '';

  /**
   * Determines if the user selected a group notification from the study page
   */
  //isGroupNav: boolean = this.notificationService.isNavigatedGroupNotification ;
  //Need to get the value dynamically
  isGroupNav: boolean = false;

  //Determines  whether user can do editable or not
  isEditable: boolean = false;

  /**
   * Array to hold the values of selected events
   */
  selectedEventList: Array<boolean> = new Array<boolean>();

  /**
   * Array to hold the values of selected events
   */
  selectedAuditEventList: Array<boolean> = new Array<boolean>();

  /**
   * Array to hold the values of selected Level
   */
  selectedAuditLevelList: Array<boolean> = new Array<boolean>();

  /**
   * Constants
   */
  nTypeConfirmation = 'Confirmation';
  nTypeAccrual = 'Accrual';
  nTypeApproval = 'Approval';
  nTypeAudit = 'Audit';
  nTypeIntervention = 'Intervention';
  nTypeStatus = 'Status';
  nTypeSchema = 'Schema';
  nTypeNonSchema = 'NonSchema';
  nTypeRandomizationCheck = 'Randomization Check';
  nTypeAccrualLimit = 'Accrual Limit';

  /**
   * Selected notification Type
   */
  selectedNotificationType = '';

  dataInitialized = false;

  // regEvents: Array<GroupNotificationEvent> = null;

  /**
   * Variable used to display Events on the UI
   */
  displayEvents: Array<GroupNotificationEvent> = [];

  /**
   * Variable used to display Audit Events on the UI
   */
  displayAuditEvents: Array<GroupNotificationEvent> = [];

  /**
   * Variable used to display Audit Levels on the UI
   */
  displayAuditLevels: Array<NotificationCode> = [];

  /**
   * Variable for holding schemaEvents with type of screening + enrollmentFlag = true
   */
  enrollmentOnlyList: GroupNotificationEvent[] = [];

  /**
   * Variable for holding all  schema (with enrollmentFlag) + nonSchema Events
   */
  enrollmentAndNonSchemaEventList: GroupNotificationEvent[] = [];

  constructor(
    protected notificationService: NotificationDashboardService,
    protected schemaService: SchemaDashboardService,
    protected studySetupService: StudySetupService,
    protected userService: UserService,
  ) {
    super(notificationService, schemaService, studySetupService, userService);
    this.subscriptions.push(
      studySetupService.changeEmitted$.subscribe(eventInfo => {
        this.parseEventInfo(eventInfo);
      }),
    );
  }

  ngOnInit() {
    if (this.userService.hasModifyAccess(ACTIVITY_NOTIFICATION_SETUP)) {
      this.isEditable = true;
    } else {
      this.isEditable = false;
    }

    this.subscriptions.push(
      this.notificationService.getAccrualPoints().subscribe(results => {
        this.accrualPointsList = results as NotificationCode[];
      }),
    );
    if (this.currentNotification()) {
      this.dataInitialized = true;
      this.initializeNotificationData();
    }
  }

  /**
   * This will be called when the notification has been retrieved.
   * * @param  {EventArg} eventArg
   *
   */
  parseEventInfo(eventArg: EventArg) {
    if (
      eventArg.eventName === globalConst.EVENT_DATA_UPDATED ||
      eventArg.eventName === globalConst.EVENT_DATA_RESET
    ) {
      if (!this.dataInitialized) {
        this.initializeNotificationData();
      }
    }
  }

  /**
   * Method to initialize the notification data dependant on the notification object
   */
  initializeNotificationData() {
    this.isGroupNav = this.notificationService.isNavigatedGroupNotification;
    const level = 'Group';
    const observables: Observable<any>[] = [];

    observables.push(
      this.notificationService.getNotificationTypes(level).pipe(
        tap(results => {
          this.notificationTypesList = results as NotificationCode[];
          if (this.currentNotification().notificationType) {
            this.selectedNotificationType =
              this.currentNotification().notificationType.value;
          }
        }),
      ),
    );
    observables.push(
      this.notificationService.getAccrualTypes().pipe(
        tap(results => {
          this.accrualTypesList = results as NotificationCode[];
          if (
            this.currentNotification().notificationAccrualDetail &&
            this.currentNotification().notificationAccrualDetail.accrualType
          ) {
            this.accrualTypeSelected();
          }
        }),
      ),
    );
    observables.push(
      this.schemaService.getSchemaEvents(this.studyId).pipe(
        tap(result => {
          if (
            result &&
            result.studySchemaEvents &&
            result.studySchemaEvents.length > 0
          ) {
            result.studySchemaEvents.forEach(eachEvent => {
              const event = {} as GroupNotificationEvent;
              event.codeId = eachEvent.schemaEventId;
              event.name = eachEvent.schemaEventName;
              event.type = this.nTypeSchema;
              if (
                eachEvent.schemaEventEnrollmentEventFlag ||
                eachEvent.schemaEventId === 6
              ) {
                // Screening
                this.enrollmentOnlyList.push(event);
              }
            });
          }
        }),
      ),
    );

    observables.push(
      this.notificationService.getAccrualEventTypes().pipe(
        tap(results => {
          for (const eventType of results) {
            const event = {} as GroupNotificationEvent;
            event.codeId = eventType.id;
            event.name = eventType.value;
            event.type = this.nTypeNonSchema;
            this.enrollmentAndNonSchemaEventList.push(event);
          }
        }),
      ),
    );

    observables.push(
      this.notificationService.getAuditEventTypes().pipe(
        tap(results => {
          for (const eventType of results) {
            const event = {} as GroupNotificationEvent;
            event.codeId = eventType.id;
            event.name = eventType.value;
            event.type = this.nTypeNonSchema;
            this.displayAuditEvents.push(event);
          }
        }),
      ),
    );
    observables.push(
      this.notificationService.getApprovalTypes().pipe(
        tap(results => {
          this.approvalTypesList = results;
          if (this.currentNotification().notificationType) {
            this.selectedNotificationType =
              this.currentNotification().notificationType.value;
          }
        }),
      ),
    );

    observables.push(
      this.notificationService.getAuditLevels().pipe(
        tap(results => {
          this.displayAuditLevels = results as NotificationCode[];
        }),
      ),
    );

    // once all the data has been retrieved set the display
    // property and clone
    this.subscriptions.push(
      forkJoin(observables).subscribe({
        complete: () => {
          this.selectedEventList = [];
          this.groupEventList = [];
          // set the display Events
          this.setDisplayEvents();
          // evaluate which ones need to be selected

          this.assignCurrentNotificationEvents();
        }}
      ),
    );
  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }

  /**
   * Compare objects, used in html for matching coded lists
   */
  compareFn(a, b) {
    return a && b && a.id == b.id;
  }

  /**
   * True if a valid selection for notification type is made
   */
  isValidNotificationSelected(): boolean {
    return (
      this.currentNotification() &&
      this.currentNotification().notificationType != null
    );
  }

  /**
   * True if the selected notification type is Accrual
   */
  isTypeAccrual(): boolean {
    return (
      this.selectedNotificationType === this.nTypeAccrual ||
      this.isTypeAccrualLimit()
    );
  }

  /**
   * True if the selected notification type is Randomization Check
   */
  isTypeRandomizationCheck(): boolean {
    return this.selectedNotificationType === this.nTypeRandomizationCheck;
  }

  /**
   * True if the selected notification type is Confirmation
   */
  isTypeConfirmation(): boolean {
    return this.selectedNotificationType === this.nTypeConfirmation;
  }

  /**
   * True if the selected notification type is Approval
   */
  isTypeApproval(): boolean {
    return this.selectedNotificationType === this.nTypeApproval;
  }

  /**
   * True if the selected notification type is Audit
   */
  isTypeAudit(): boolean {
    return this.selectedNotificationType === this.nTypeAudit;
  }

  /**
   * True if the selected notification type is Intervention
   */
  isTypeIntervention(): boolean {
    return this.selectedNotificationType === this.nTypeIntervention;
  }

  /**
   * True if the selected notification type is Status
   */
  isTypeStatus(): boolean {
    return this.selectedNotificationType === this.nTypeStatus;
  }
  /**
   * True if the selected notification type is Accrual Limit
   */
  isTypeAccrualLimit(): boolean {
    return this.selectedNotificationType === this.nTypeAccrualLimit;
  }

  /**
   * True if Schema events should be displayed
   */
  shouldShowEvents(): boolean {
    let shouldShow = false;
    if (this.isTypeAccrual() || this.isTypeConfirmation()) {
      shouldShow = true;
    }
    return shouldShow;
  }

  /**
   * True if AccrualType is Intervention and ARM events should be displayed
   */
  isAccrualTypeIntervention(): boolean {
    let shouldShow = false;
    if (
      this.isTypeAccrual() &&
      this.accrualTypeSelection &&
      this.accrualTypeSelection === 'Intervention'
    ) {
      shouldShow = true;
    }
    return shouldShow;
  }

  /**
   * Sets the displayEvents variable for all the correct events
   */
  setDisplayEvents() {
    if (this.isTypeAccrual()) {
      this.displayEvents = this.getRegTypeEvents();
    } else if (this.isTypeConfirmation()) {
      this.displayEvents = this.enrollmentOnlyList;
    }
  }

  /**
   * Assigns the selected events
   */
  assignCurrentNotificationEvents() {
    const currentEventList: GroupNotificationEvent[] =
      this.currentNotification().groupNotificationEventList;
    if (currentEventList && currentEventList.length > 0) {
      this.displayEvents.forEach((evt, idx) => {
        const match = currentEventList.find(
          each => evt.codeId == each.codeId && evt.type == each.type,
        );
        if (match != null) {
          this.selectedEventList[idx] = true;
        }
      });
    }
  }

  /**
   * returns the array of StudyschemaEvents with schemaEventEnrollmentEventFlag==true, plus any SCREENING type events
   */
  getRegTypeEvents(): Array<GroupNotificationEvent> {
    const list1 = this.enrollmentOnlyList;
    const list2 = this.enrollmentAndNonSchemaEventList;
    return list1.concat(list2);
  }

  /**
   * Returns the display  name
   * @param evt The event object
   */
  getEventDisplayName(evt: StudySchemaEvent) {
    return evt.schemaEventDisplayName;
  }

  /**
   * Returns the selected option for the attribute
   * @param evt The event being evaluated
   * @param attributeName The attribute to locate
   */
  getSelectedAttributeOption(
    evt: StudySchemaEvent,
    attributeName: string,
  ): SchemaEventAttributeOption {
    const selection = evt.studySchemaEventAttributeList.find(
      eachAttribute =>
        eachAttribute.attributeCode.toLowerCase() ==
        attributeName.toLowerCase(),
    );
    const singleSelected = selection.attributeOptions.find(
      eachOption => eachOption.selected == true,
    );
    return singleSelected;
  }

  /**
   * Handler for the notification type select box selection changed event
   */
  notificationTypeChanged() {
    this.currentNotification().notificationAccrualDetail = null;
    this.selectedNotificationType = '';
    this.displayEvents = [];
    this.selectedEventList = [];
    if (this.currentNotification().notificationType != null) {
      this.currentNotification().notificationAccrualDetail =
        new NotificationAccrualDetail(
          null,
          null,
          null,
          null,
          new NotificationCode(1, 'Person', null),
          null,
          null,
        );

      this.selectedNotificationType =
        this.currentNotification().notificationType.value;
      if (this.shouldShowEvents()) {
        this.setDisplayEvents();
      }
    }
    if (this.isTypeAudit) {
      this.currentNotification().groupNotificationEventList[0] =
        new GroupNotificationEvent(-1, null, null, null);
      this.currentNotification().notificationAccrualDetail.notificationByLevel =
        new NotificationCode(null, null, null);
    }
  }

  /** Handler for the accrual Type selection changed
   *
   */
  accrualTypeSelected() {
    this.accrualTypeSelection = '';
    setTimeout(() => {
      if (this.currentNotification().notificationAccrualDetail.accrualType) {
        this.accrualTypeSelection =
          this.currentNotification().notificationAccrualDetail.accrualType.value;
      }
    });
  }

  /**
   *
   * The handler when the check box is clicked
   */
  eventSelectionClicked() {
    setTimeout(() => {
      this.currentNotification().groupNotificationEventList = [];
      for (let n = 0; n < this.displayEvents.length; n++) {
        if (this.selectedEventList[n] && this.selectedEventList[n] == true) {
          const evt = this.displayEvents[n];
          this.currentNotification().groupNotificationEventList.push(
            new GroupNotificationEvent(-1, evt.codeId, evt.name, evt.type),
          );
        }
      }
    });
  }

  /** Handler for the audit level selection changed
   *
   */
  auditLevelSelectionClicked(lvl: NotificationCode) {
    this.currentNotification().notificationAccrualDetail.notificationByLevel =
      lvl;
  }

  /** Handler for the audit event selection changed
   *
   */
  auditEventSelectionClicked(evt: GroupNotificationEvent) {
    this.currentNotification().groupNotificationEventList[0] =
      new GroupNotificationEvent(-1, evt.codeId, evt.name, evt.type);
  }

  /********* Pending Changes warning************** */
  /**
   * Implemenation of the canDeactivate that will be called when we navigate away
   *
   */
  canDeactivate(): Observable<boolean> | boolean {
    return this.notificationService.notificationSetup.canDeactivate();
  }
  /*********  Pending Changes warning************** */

  /**
   * Disables editing if isGroupNav or user don't have write access
   */
  isEditingDisabled(): boolean {
    return this.isGroupNav || !this.isEditable;
  }
}
