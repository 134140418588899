export class HeaderBar {
    constructor(
        public caption: string ,
        public captionValue: string,
        public showDescription: boolean = false,
        public description: string,
        public showButtons: boolean = true,
        public canNavigate: boolean = false ,
        public navigateUrl: string = '',
        public disableButtons?: boolean

    ) { }
}
