<div class="nav-return-bar" >
  <!-- [ngClass]="{'is-hidden': !shouldShowNavBar()}">             -->
  <ul>
    <li class="has-link ">
      <a (click)="navigateBack()" *ngIf="!isCodeDetailPage()">
        <span class="icon">
          <i class="fas fa-arrow-left"></i>
        </span>
        <span *ngIf="!isBackToSearchResults()  " class="link-text">
          Back to Study Landing  
        </span>
        <span *ngIf="isBackToSearchResults() " class="link-text">
          Back to Search Results
        </span>
      </a>
      <a routerLink="./{{currentDetailLink.parentLink}}" *ngIf="isCodeDetailPage()">
        <span class="icon">
          <i class="fas fa-arrow-left"></i>
        </span>
        <span class="link-text">
          Back to {{currentDetailLink.name}}
        </span>
      </a>
      </li>
      <ng-container *ngFor="let sectionItem of getHorizontalSectionItems(); let i = index">
        <li *ngIf="isNavItemVisible(sectionItem)" [mcCanNavigate]="sectionItem.activityName" [studyId]="studyId">
          <a [ngClass]="{'is-nav-active': isLinkActive(sectionItem) } " [routerLinkActive]="['is-active']"
            class=" sectionLink" (click)="navTo($event, sectionItem)" routerLink="./{{sectionItem.path}}">
            <span>
              {{getNavItemDisplay(sectionItem)}}
            </span>
          </a>
        </li>
      </ng-container>
  </ul>
 </div>