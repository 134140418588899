<div class="notification-type">
  <div>
    <h3>Configure Notification</h3>
  </div>

  <div class="main-content" *ngIf="currentNotification()">
    <div class="columns">
      <div class="column is-2">
        <span>Notification Type</span>
      </div>

      <div class="column is-3 field">
        <div class="control">
          <div class="select">
            <select [(ngModel)]="currentNotification().notificationType" [compareWith]="this.compareFn"
              (change)="notificationTypeChanged()" [attr.disabled]="isNotificationTypeNotify() ? '' : null"
              [disabled]="!this.isEditable">
              <option *ngIf="!isNotificationTypeNotify()" value=null selected>-- Select --</option>
              <option *ngIf="isNotificationTypeNotify()" selected [ngValue]="currentNotification().notificationType">
                {{currentNotification().notificationType.value }}
              </option>
              <option *ngFor="let nType of notificationTypesList" [ngValue]="nType">
                {{nType.value}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
    <div *ngIf="isValidNotificationSelected()">
      <!---- ACCRUAL Type  -->
      <div class="columns" *ngIf="isTypeAccrual()">
        <div class="column is-2">
          <span> Accrual Type</span>
        </div>
        <div class="column field">
          <div class="control">
            <div class="select">
              <select [(ngModel)]="currentNotification().notificationAccrualDetail.accrualType"
                [compareWith]="this.compareFn" (change)="accrualTypeSelected()" [disabled]="!this.isEditable">
                <option value=null selected>-- Select --</option>
                <option *ngFor="let nType of accrualTypesList" [ngValue]="nType">
                  {{nType.value}}
                </option>
              </select>
            </div>
          </div>

        </div>
        <div class="column"></div>
      </div>
      <!---- ACCRUAL Type  -->

      <!---   EVENTS -->
      <div class="columns" *ngIf="shouldShowEvents()">
        <div class="column is-2">
          <span>Schema Event</span>
        </div>

        <div class="column is-3 field">
          <div>
            <span *ngIf="displayEvents.length===0">No qualifying events currently defined</span>
            <ul>
              <li *ngFor="let evt of displayEvents; let rowIdx = index">
                <label class="checkbox">
                  <input type="checkbox" name="regEvt-{{rowIdx}}" (click)="eventSelectionClicked()"
                    [(ngModel)]="selectedEventList[rowIdx]" [disabled]="!this.isEditable">
                  {{getEventDisplayName(evt)}} - {{evt.keyEventMappingId}}
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="column"></div>
      </div>
      <!--- EVENTS -->

      <!--- Notify at  - APPROVAL TYPE -->
      <div class="columns" *ngIf="isTypeApproval()">
        <div class="column is-2 ">
          <span>Notify At</span>
        </div>
        <div class="column field">
          <ul>
            <li *ngFor="let apprType of approvalTypesList">
              <label class="checkbox">
                <input type="checkbox" [ngModel]="isSelected(apprType)"
                  (ngModelChange)="toggleSelection($event, apprType)" [disabled]="!this.isEditable">
                {{apprType.desc}}
              </label>
            </li>
          </ul>
        </div>
      </div>
      <!--- Notify at  - APPROVAL TYPE -->

      <!--  Grouping Factor   -->

      <div class="columns" *ngIf="shouldShowGroupingFactors()">
        <div class="column is-2">
          <span>Grouping Factor</span>
        </div>

        <div class="column is-8 field">
          <div>
            <span *ngIf="groupingFactors.length===0">No qualifying grouping factors currently defined</span>
            <ul>
              <li *ngFor="let item of groupingFactors; let rowIdx = index">
                <label class="checkbox">
                  <input type="checkbox" name="grp-factor-{{rowIdx}}" (click)="gfSelectionClicked()"
                    [(ngModel)]="selectedGroupingFactors[rowIdx]" [disabled]="!this.isEditable">
                  {{getGroupingFactorDisplay(item)}}
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="column"></div>
      </div>
      <!--  Grouping Factor   -->


      <!-- Variable valid values -->
      <div class="columns" *ngIf="shouldShowValidValues()">
        <div class="column is-2">
          <span>Variable valid values</span>
        </div>

        <div class="column is-8 field">
          <div class="valid-value-selection">
            <span *ngIf="formsValidValueList.length===0">No qualifying forms valid value currently defined</span>
            <ul>
              <li *ngFor="let item of formsValidValueList; let rowIdx = index">
                <label class="checkbox">
                  <input type="checkbox" name="valid-value-{{rowIdx}}" (click)="vvSelectionClicked()"
                    [(ngModel)]="selectedValidValues[rowIdx]" [disabled]="!this.isEditable">
                  {{getValidValueDisplay(item)}}
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="column"></div>
      </div>

      <!-- End of Variable valid values -->

      <!--  Slot Groups   -->

      <div class="columns" *ngIf="shouldShowSlotGroups()">
        <div class="column is-2">
          <span>Slot Group</span>
        </div>

        <div class="column is-8 field">
          <div>
            <span *ngIf="slotGroups.length===0">No slot groups currently defined</span>
            <ul>
              <li *ngFor="let item of slotGroups; let rowIdx = index">
                <label class="checkbox">
                  <input type="checkbox" name="grp-factor-{{rowIdx}}" (click)="sGSelectionClicked()"
                    [(ngModel)]="selectedSlotGroups[rowIdx]" [disabled]="!this.isEditable">
                  {{item.groupDescription}}
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="column"></div>
      </div>
      <!--  End of Slot Groups   -->



      <div class="columns is-horizontal" *ngIf="isTypeAccrual()">

        <div class="column is-2 ">
          <span>Notify At</span>
        </div>

        <div class="column is-2 field">
          <div class="control">
            <div class="select">
              <select [(ngModel)]="currentNotification().notificationAccrualDetail.accrualPointType"
                [compareWith]="this.compareFn" [disabled]="!this.isEditable">
                <option value=null selected>-- Select --</option>
                <option *ngFor="let nType of accrualPointsList" [ngValue]="nType">
                  {{nType.value}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column"></div>

        <div class="column is-8"
          *ngIf="currentNotification().notificationAccrualDetail.accrualPointType && currentNotification().notificationAccrualDetail.accrualPointType.value === 'Every (x) Patients'">
          <div class="columns">
            <span class="column is-2">For Every:</span>
            <div class="column ">
              <div class="field is-horizontal">
                <div class="control ">
                  <input class="form-control input" placeholder="number" id="for-every" name="forEvery"
                    [(ngModel)]="currentNotification().notificationAccrualDetail.accrualPoint"
                    [disabled]="!this.isEditable">
                </div>&nbsp;&nbsp;
                <span>Patients</span>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-8"
          *ngIf="currentNotification().notificationAccrualDetail.accrualPointType && currentNotification().notificationAccrualDetail.accrualPointType.value === 'a (%) of Patients'">
          <div class="columns">
            <div class="column">
              <div class="field is-horizontal">
                <div class="control ">
                  <input class="form-control input" placeholder="number" id="percent" name="percent"
                    [(ngModel)]="currentNotification().notificationAccrualDetail.accrualPoint"
                    [disabled]="!this.isEditable">
                </div>&nbsp;&nbsp;
                <span>%</span>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-8"
          *ngIf="currentNotification().notificationAccrualDetail.accrualPointType && currentNotification().notificationAccrualDetail.accrualPointType.value === '(xth) Patient'">
          <div class="columns">
            <div class="column is-6">
              <div class="field is-horizontal">
                <div class="control ">
                  <input class="form-control input" placeholder="number" id="xth-patient" name="xthPatient"
                    [(ngModel)]="currentNotification().notificationAccrualDetail.accrualPoint"
                    [disabled]="!this.isEditable">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



    </div>


    <div class="columns">
      <div class="  column is-2">
        <div class=" field is-horizontal ">
          <span>Notes</span>
        </div>
      </div>
      <div class="column is-7">
        <div class="field is-horizontal ">
          <div class="field-body text-field-padding ">
            <div class="field">
              <div class="control ">
                <textarea placeholder="Notes" name="notes" id="body" class="textarea width-100-percent" rows="6"
                  [(ngModel)]="currentNotification().notes" [attr.disabled]="isNotificationTypeNotify() ? '' : null"
                  [disabled]="!this.isEditable"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>