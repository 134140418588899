import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';

import { environment } from '../../environments/environment';
import { AppConfigLoaderService } from './app-config-loader.service';
import { MsalConfig } from './runtime-config';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');



export class AppConfig {
  version: string;
  production: boolean;
  envName: string;
  apiEndpoint: string;
  v2_apiEndpoint: string;
  logoutUrl: string;
  msalConfig: MsalConfig;
}


export const APP_DI_CONFIG: AppConfig = {
  version: environment.version,
  production: environment.production,
  envName: environment.envName,
  apiEndpoint: environment.serviceUrl,
  v2_apiEndpoint: environment.serviceUrlv2,
  logoutUrl: environment.logoutUrl,
  msalConfig: environment.msalConfig
};



@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: APP_CONFIG, useClass: AppConfigLoaderService }
  ],
  declarations: []
})
export class AppConfigModule { }
