
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import * as ACTIVITY_NAMES from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import { Subscription } from 'rxjs';
import { StudyPatientSearchResult } from '../../common/model/study-patient-search-result';
import { SearchService } from '../../common/services/search.service';
import { UserService } from '../../common/services/user.service';
import { SearchCriteria } from '../model/search-criteria';
import { SearchInfo } from '../model/search-info';

@Component({
  selector: 'mc-active-patient-search-results',
  templateUrl: './active-patient-search-results.component.html',
  styleUrls: ['./active-patient-search-results.component.scss']
})
export class ActivePatientSearchResultsComponent implements OnInit, OnDestroy {

  searchCriteria: SearchCriteria = null;
  subscriptions: Subscription[] = [];

  @Input() hostLandingPageEnum = 0;


  @Output() returnToSearchEvent = new EventEmitter();


  studyId = 0;


  rows: StudyPatientSearchResult[];
  noResultsFound = false;
  isActiveListTitle = false;

  results: StudyPatientSearchResult[] = null;
  /**
   * The search criteria that identifies the type of patient search
   */
  currentSearchCriteria: SearchInfo = null;

  public columns: Array<any> = [
    {
      title: 'Tracking Id', name: 'trackingId',
      sort: '',
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Tracking Id' }
    },
    {
      title: 'Subject Id', name: 'patientId',
      sort: '',
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Subject Id' }
    },
    {
      title: 'Initials', name: 'initials',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Initals' }
    },
    {
      title: 'Date of Birth', name: 'dateOfBirthString',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Date of Birth' }
    },
    {
      title: 'Enrollment Event', name: 'eventName',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Event' }
    },
    {
      title: 'Data Entry Person', name: 'dataEntryPerson',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Data Entry Person' }
    }
  ];

  /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering:  { filterString: '' },
    className: ['is-striped', 'is-bordered']
  };

  constructor(private searchService: SearchService,
    private userService: UserService,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private router: Router) {

  }


  ngOnInit() {
    if (this.appConfig.version === 'v1') {
      this.columns[1].title = 'Patient Id';
      this.columns[1].filtering.placeholder = 'Filter by Patient Id';
    }
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params?.studyId) {
          this.studyId = params?.studyId;
        }
      }));



    this.subscriptions.push(this.searchService.searchQuery$.subscribe(
      criteria => {
        this.currentSearchCriteria = this.searchService.getSearchQuery(this.hostLandingPageEnum)//  this.searchService.currentSearchQuery;
        this.searchCriteria = null;
        if (criteria && criteria.searchType === globalConst.SEARCH_CRITERIA_TYPE_ACTIVE_PATIENT) {
          console.log('onInit here');
          this.searchCriteria = criteria;
          this.isActiveListTitle = true;
          this.getActivePatients();
        } else {
          this.results = null;
        }
      }
    ))
  }



  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    )
  }









  /**
   * Navigates to the patient landing page
   * @param $event
   * @param patientResult
   */
  goToPatientLanding($event: Event, patientResult: StudyPatientSearchResult) {
    if (this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_PATIENT_LANDING, this.studyId)) {
      if ($event) {
        $event.stopPropagation();
      }
     const path: string = '/patient/' + patientResult.patient.studyId + '/' + patientResult.patient.patientId + '/' + patientResult.patient.caseDetailId;
     this.router.navigate([path], {
     queryParams: {
        'navBack': this.hostLandingPageEnum,
        'trackingNum' : patientResult.patient.trackingNum
      }
      });
    } else {
     console.log ('not authorize to patient landing page for this study')
    }
  }
  /**
   * @param  PatientSearchResult row - patient record
   * @returns boolean - evaluate whether patientId or TraackingId
   */
  isActivePatient(row: StudyPatientSearchResult): boolean {
    return (row.patient.patientId == null && row.patient.trackingNum != null);
  }

  returnToSearch() {
    this.fireEvent();
  }

  fireEvent() {
    this.returnToSearchEvent.emit();
  }

  /**
 * Sorts the data in the table based on the column and sort order
 * @param data The data currently being displayed on the table
 * @param config The associated configuration for the data being displayed
 */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (const column of columns) {
      if (column.sort !== '' && column.sort !== false) {
        columnName = column.name;
        sort = column.sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousVal: any;
      let currentVal: any;

      // If the following columns are used, then need to get different values for previous and current
      if (columnName === 'trackingId') {
        previousVal = previous?.patient?.trackingNum ? previous?.patient?.trackingNum.toLowerCase() : '';
        currentVal = current?.patient?.trackingNum ? current?.patient?.trackingNum.toLowerCase() : '';
      } else if (columnName === 'patientId') {
        previousVal = previous?.patient?.patientId ? previous?.patient?.patientId.toLowerCase() : '';
        currentVal = current?.patient?.patientId ? current?.patient?.patientId.toLowerCase() : '';
      } else if (columnName === 'initials') { // get first and last name
        previousVal = (previous?.patient?.firstName ? previous?.patient?.firstName.toLowerCase() : '') +
          (previous?.patient?.lastName ? previous?.patient?.lastName.toLowerCase() : '');
        currentVal = (current?.patient?.firstName ? current?.patient?.firstName.toLowerCase() : '') +
          (current?.patient?.lastName ? current?.patient?.lastName.toLowerCase() : '');
      } else if (columnName === 'dateOfBirthString') { // date values
        const dateA: string = previous?.patient?.dateOfBirthString || '';
        const dateB: string = current?.patient?.dateOfBirthString || '';
        previousVal = this.searchService.parseDateString(dateA);
        currentVal = this.searchService.parseDateString(dateB);
      } else if (columnName === 'eventName') {
        previousVal = previous?.event?.schemaEventDisplayName ? previous?.event?.schemaEventDisplayName.toLowerCase() : '';
        currentVal = current?.event?.schemaEventDisplayName ? current?.event?.schemaEventDisplayName.toLowerCase() : '';
     } else if (columnName === 'dataEntryPerson') { // get data entry person first and last name
        previousVal = (previous?.dataEntryPerson?.firstName ? previous?.dataEntryPerson?.firstName.toLowerCase() : '') +
          (previous?.dataEntryPerson?.lastName ? previous?.dataEntryPerson?.lastName.toLowerCase() : '');
        currentVal = (current?.dataEntryPerson?.firstName ? current?.dataEntryPerson?.firstName.toLowerCase() : '') +
          (current?.dataEntryPerson?.lastName ? current?.dataEntryPerson?.lastName.toLowerCase() : '');
      } else { // simple values
        previousVal = previous[columnName] ? previous[columnName].toLowerCase() : '';
        currentVal = current[columnName] ? current[columnName].toLowerCase() : '';
      }

      if (previousVal > currentVal) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousVal < currentVal) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
  * Main method to handle filtering, sorting of the table
  * @param config Configuration of the datatable
  * @param page The current page being displayed
  */
  public onChangeTable(config: any): any {
    let column: any = null;
    this.rows = [];
    if (config.title !== undefined && config.name !== undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.results, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;

  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data === undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (column.name === 'trackingId' && item?.patient && item?.patient?.trackingNum) {
            return item?.patient?.trackingNum.toLowerCase().match(column.filtering.filterString.toLowerCase());
          } else if (column.name === 'patientId' && item?.patient && item?.patient?.patientId) {
            return item?.patient?.patientId.toLowerCase().match(column.filtering.filterString.toLowerCase());
          } else  if (column.name === 'initials' && item?.patient && item?.patient?.firstName && item?.patient?.lastName) {
            const initials = item?.patient?.lastName + item?.patient?.firstName;
            return initials.toLowerCase().match(column.filtering.filterString.toLowerCase());
          } else  if (column.name === 'dateOfBirthString' && item?.patient && item?.patient?.dateOfBirthString ) {
            const dateOfBirthString = item?.patient?.dateOfBirthString;
            return dateOfBirthString.match(column.filtering.filterString);
          } else  if (column.name === 'eventName' && item?.event && item?.event?.schemaEventDisplayName) {
            return item?.event?.schemaEventDisplayName.toLowerCase().match(column.filtering.filterString.toLowerCase());
         } else  if (column.name === 'dataEntryPerson' && item?.dataEntryPerson && item?.dataEntryPerson?.firstName && item?.dataEntryPerson?.lastName) {
            const name = item?.dataEntryPerson?.firstName + item?.dataEntryPerson?.lastName;
            return name.toLowerCase().match(column.filtering.filterString.toLowerCase());
          }

          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });



    return filteredData;
  }

  /**
   * Calls the service to fetch the accrued patients for the study
   */
  private getActivePatients() {
    console.log('in gtActivePatients', this.results);
    if (this.results && this.results !== null && this.results.length > 0) {
      this.doPostRetrieval(this.results);
    } else {
      this.subscriptions.push(
        this.searchService.getActivePatients(this.studyId)
          .subscribe(res => {
            this.doPostRetrieval(res);
          }));
    }
  }

    /**
   * Process the results to display them in a grid
   * @param res The studyPatientSearchResult array
   */
    private doPostRetrieval(res: StudyPatientSearchResult[]) {
      this.results = res.filter(x => x.patient.studyId > 0 && x.patient.caseDetailId > 0);
      this.noResultsFound = (this.results.length === 0);
      this.onChangeTable(this.config);
    }
}


