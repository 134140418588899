import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CalendarDate } from 'app/common/model/calendar-date';
import { CalendarOptions } from 'app/common/model/calendar-options';
import * as moment from 'moment';



@Component({
  selector: 'mc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, AfterViewInit {



  _options: CalendarOptions ;


  /**
   * boolean to toggle the display
   */
  @Input() isOpen = true;






  /**
   * Variable used to drive the month displayed on the ui
   */
  currentMonth: moment.Moment;
  /**
   * variable to determine if the date selected on the ui is today
   */
  today: moment.Moment;

  /**
   * Array built for creating the entire month dates for display
   */
  dates: Array<CalendarDate>;

  /**
   * The html div element holding the calendar dates
   */
  calendarContainerElement: any;

  /**
   * The style object for setting the top and left properties for the container div
   */
  calendarPositionStyle: any = {};

  defaultOptions: CalendarOptions = {
    startDate: new Date(),
    dataFormat: 'yyyy/mm/dd',
    closeOnSelect: true,
    parentComponent: null,
    // callback function
    onSelect: null,
    onOpen: null,
    onClose: null
  };

  /**
   * The host element (input box) where the directive for
   * popping open the calendar control is hosted
   */
  hostElement: ElementRef;


  startDate: FormControl;
  get options(): CalendarOptions {
    return this._options;
  }

  /**
    * Calendar options passed in
    */
  @Input('options')
  set options(value: CalendarOptions) {
    this._options = value;
    setTimeout(() => {
      console.log('STart Date => ', this.options.startDate);
      this.startDate = new FormControl(this.options.startDate);
    });
  }

  //
  constructor(private element: ElementRef,
    private renderer: Renderer2) { }

  /**
   * The html reference to the calendar container
   */
//  @ViewChild('calendarContainer') calendarContainer: ElementRef;

  ngOnInit() {
    if (this.options == null) {
      this.options = this.defaultOptions;
    }

    // this.getDateInfo();

  }

  /**
   * Initialize the html element and retrive the style after the calendar ui has been initialized
   */
  ngAfterViewInit() {
    // this.calendarContainerElement = this.renderer.selectRootElement(this.calendarContainer);
    // if (this.hostElement && this.hostElement.nativeElement) {
    //   this.calendarPositionStyle = this.getPositionStyle();
    // }

  }

/**
 * Set the host component
 * @param hostElement The hostElement on which the directive is set
 */
  setHostElement(hostElement: ElementRef) {
    this.hostElement = hostElement;
  }

  /**
   * Handles the Click for the selected date on the ui
   * @param date the selected date on the ui
   */
  dateSelected(date: CalendarDate) {
    if (this.isCurrentMonth(date)) {
      this.today = moment(this.currentMonth.year() + '-' + (this.currentMonth.month() + 1) + '-' + date.date, 'YYYY-MM-DD');
    } else {
      // if the selected date is not the current month
      this.today = moment(this.currentMonth.year() + '-' + (date.month + 1) + '-' + date.date, 'YYYY-MM-DD');
    }
    if (this.options.onSelect) {
      this.options.onSelect(this.today.toDate(), this.options.parentComponent);
    }
    if (this.options.closeOnSelect) {
      this.closeDatePicker();
    }
  }

  /**
   * Closes this ui and raises the onClose event if configured
   */
  closeDatePicker() {
    this.isOpen = false;
    if (this.options.onClose) {
      this.options.onClose(this.options.parentComponent);
    }
  }

  /**
   * Sets the date for display on the datepicker ui
   */
  getDateInfo(): void {
    this.currentMonth = moment();
    this.today = moment();
    let initialDate: moment.Moment = moment();
    if (this.options.startDate) {
      initialDate = moment(this.options.startDate);
      this.currentMonth = moment(this.options.startDate);
      this.today = moment(this.options.startDate);
    }
    this.dates = this.getDaysOfCalendar(initialDate);
  }

  /**
     * Checks if the date passed in is the same month as current month
     *
     * @param date {date}
     */
  isCurrentMonth(date: CalendarDate): boolean {
    return (this.currentMonth.month() === date.month);
  }

  /**
   * Checks if a date integer is equal to today's date integer in the month
   *
   * @param date {date}  - The Calendar date object to be evaluted
   **/
  isToday(date: CalendarDate): boolean {
    return (this.today.date() === date.date && this.isCurrentMonth(date)); // date.month === this.today.month()) ;
  }

  /**
   * Calculates previous month's days to generate the calendar for
   *
   * @param date {moment} - The month moment to generate the date for
   **/
  getPreviousMonth(date): void {
    const month = moment(date).subtract(1, 'M');
    this.currentMonth = month;
    this.dates = this.getDaysOfCalendar(month);
  }

  /**
   * Calculates next month's days to generate the calendar for
   *
   * @param date {moment} - The month moment to generate the date for
   **/
  getNextMonth(date ): void {
    const month = moment(date).add(1, 'M');
    this.currentMonth = month;
    this.dates = this.getDaysOfCalendar(month);
  }

  /**
   * Calculates the first day of the calendar (since it's probably in the previous month) and the last day of the calendar (since it's probably in the next month)
   *
   * @param now {Moment}  - A date to generate a calendar around
   */
  private getDaysOfCalendar(now: moment.Moment): Array<CalendarDate> {
    const numberOfDaysInMonth = now.daysInMonth();  // Get the number of days in this month
    const dayOfWeek = now.startOf('month').day(); // Get day of week of 1st of the month
    const lastDayOfWeek = now.endOf('month').day(); // Get day of week of last day of the month
    const previousMonthLastDay = moment(now).subtract(1, 'M').endOf('month'); // Get the last day in the previous month
    const nextMonthFirstDay = moment(now).add(1, 'M').startOf('month');  // Get the first day in the next month

    const calendarDates: Array<CalendarDate> = [];

    const startDate = moment(previousMonthLastDay).startOf('week').date();
    const endDate = moment(nextMonthFirstDay).endOf('week').date();

    // Get the previous month's days
    for (let i = startDate; i <= previousMonthLastDay.date(); i++) {
      const currentDate = {
        date: i,
        month: previousMonthLastDay.month()
      }
      calendarDates.push(currentDate);
    }

    // Get the current month's days
    for (let i = now.startOf('month').date(); i <= now.endOf('month').date(); i++) {
      const currentDate = {
        date: i,
        month: now.month()
      }
      calendarDates.push(currentDate);
    }

    // Get the next month's days
    for (let i = nextMonthFirstDay.date(); i <= endDate; i++) {
      const currentDate = {
        date: i,
        month: nextMonthFirstDay.month()
      }
      calendarDates.push(currentDate);
    }
    return calendarDates;
  }

  /**
   * Returns the style for the left and top position for the calendar block
   * If the width falls over the edge to the right, it will pull it to the left
   * if the bottom makes below the window, it will pull it to the top
   */
  getPositionStyle() {

    const calElement = this.calendarContainerElement.nativeElement;
    const clientRect = calElement.getBoundingClientRect();

    let left = clientRect.left + window.pageXOffset;
    let top = clientRect.top + window.pageYOffset;
    const totalWidth: number = window.innerWidth;
    const totalHeight: number = window.innerHeight;

    const tpWidth: number = clientRect.width;
    const tpHeight: number = clientRect.height;
    const hostElementBounds: DOMRect = this.hostElement.nativeElement.getBoundingClientRect();

    if ((left + tpWidth) > totalWidth) {
      left = ((hostElementBounds.right - (tpWidth))); //  (totalWidth - (tpWidth + 240));
    }
    if (top + tpHeight > totalHeight) {
     // console.log("top", top, "clientRect", clientRect, "hostElvemBound", hostElementBounds)
      top = (tpHeight - hostElementBounds.bottom) + (hostElementBounds.top - hostElementBounds.height); // (totalHeight - (tpHeight - 150));
     // console.log("From Top", totalHeight, "tpHeight", tpHeight, hostElementBounds)
    }
    return { left: left + 'px' };
    // return { left: left + "px", top: top + "px" };
  }


}
