import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import * as globalConst from 'app/common/model/app-constants';
import { Subscription } from 'rxjs';
import { PatientSearchResult } from '../../common/model/patient-search-result';
import { SearchService } from '../../common/services/search.service';
import { SearchCriteria } from '../model/search-criteria';
import { SearchInfo } from '../model/search-info';



@Component({
  selector: 'mc-patient-search-results',
  templateUrl: './patient-search-results.component.html',
  styleUrls: ['./patient-search-results.component.scss']
})
export class PatientSearchResultsComponent implements OnInit, OnDestroy {

  searchCriteria: SearchCriteria = null;
  subscriptions: Subscription[] = [];

  @Input() hostLandingPageEnum = 0;
  @Output() returnToSearchEvent = new EventEmitter();


  studyId = 0;

  results: PatientSearchResult[];
  rows: PatientSearchResult[];
  noResultsFound = false;
  version: string;
  isPatientSubject: boolean;

  /**
   * The search criteria that identifies the type of patient search
   */
  currentSearchCriteria: SearchInfo = null;
  
  isSearchBasedOnTrackingNum: boolean = false;

  public columns: Array<any> = [
    {
      title: 'Study Number', name: 'studyNumber',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Patient Id', name: 'patientId',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Tracking Id', name: 'trackingNum',
      sort: '',
      display:  false,
      filtering: false
    },
    {
      title: 'Name', name: 'name',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Gender', name: 'genderDesc',
      sort: '',
      display: false,
      filtering: false
    },
    {
      title: 'Race', name: 'race',
      sort: '',
      display: false,
      filtering: false
    },
    {
      title: 'Date of Birth', name: 'dateOfBirthString',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Organization Name', name: 'organizationName',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Current Enrollment Event Name', name: 'currentEnrollmentEventName',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Intervention Name', name: 'interventionName',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Registration Date', name: 'registrationDate',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Registrar', name: 'registrar',
      sort: '',
      display: true,
      filtering: false
    }
  ];


  /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: false,
    className: ['is-striped', 'is-bordered']
  };

  constructor(private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(APP_CONFIG) appConfig: AppConfig) {
      this.version = appConfig.version;
  }


  ngOnInit() {

    this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params?.studyId) {
          this.studyId = params?.studyId;
        }
      }));



    this.subscriptions.push(this.searchService.searchQuery$.subscribe(
      criteria => {
        this.currentSearchCriteria = this.searchService.getSearchQuery(this.hostLandingPageEnum)//  this.searchService.currentSearchQuery;
        this.searchCriteria = null;
        if (criteria && criteria.searchType) {
          this.searchCriteria = criteria;

          if(this.searchCriteria.searchType === globalConst.SEARCH_CRITERIA_TYPE_TRACKING_NUMBER) {
            const index = this.columns.findIndex(col => col.name === 'currentEnrollmentEventName');
            this.columns[index].title = 'Event Name';
          }else{
            const index = this.columns.findIndex(col => col.name === 'currentEnrollmentEventName');
            this.columns[index].title = 'Current Enrollment Event Name';
          }

          this.getPatientSearchResults();
        } else {
          this.results = null;
        }
      })
      );

    if (this.version === 'v2') {
      this.isPatientSubject = true;
      const index = this.columns.findIndex(col => col.name === 'patientId');
      this.columns[index].title = 'Subject Id';
    }

  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    )
  }

  /**
   * Returns the results of the patient Search
   */
  getPatientSearchResults(): void {
    if (this.currentSearchCriteria) {
      if (this.currentSearchCriteria.searchTypeEnum === globalConst.SEARCH_TYPE_ENUM_TEXT) {
        if(this.currentSearchCriteria.searchCriteria.searchType === globalConst.SEARCH_CRITERIA_TYPE_TRACKING_NUMBER) {
          this.getPatientsForCriteria(null, this.searchCriteria.searchTerm);
        }else if(this.currentSearchCriteria.searchCriteria.searchType === globalConst.SEARCH_CRITERIA_TYPE_PATIENT ||
           this.currentSearchCriteria.searchCriteria.searchType === globalConst.SEARCH_CRITERIA_TYPE_SUBJECT) {
          this.getPatientsForCriteria(this.searchCriteria.searchTerm, null);
        }
      }
    }
  }






  /**
   * Navigates to the patient landing page
   * @param $event
   * @param patientResult
   */
  goToPatientLanding($event: Event, patientResult: PatientSearchResult) {
    if ($event) {
      $event.stopPropagation();
    }
    const path: string = '/patient/' + patientResult.studyId + '/' + patientResult.patientId + '/' + patientResult.caseDetailId;
    this.router.navigate([path], {
      queryParams: {
        'navBack': this.hostLandingPageEnum,
        'trackingNum' : patientResult.trackingNum
      }
    });
  }

  /**
   *
   * @param row
   * @returns boolean - evaluate studyTypeCode (ancillary studies are not a link)
   */
  isPatientLink(row: PatientSearchResult): boolean {
    if (row.studyTypeCode == null || row.studyTypeCode === 'Ancillary') {
      return false;
    }
    return true;
  }
  /**
   * @param  PatientSearchResult row - patient record
   * @returns boolean - evaluate whether patientId or TraackingId
   */
  isActivePatient(row: PatientSearchResult): boolean {
    return (row.patientId == null && row.trackingNum != null);
  }

  isTrackingNumExist(row: PatientSearchResult){
    return row.trackingNum != null;
  }

  returnToSearch() {
    this.fireEvent();
  }

  fireEvent() {
    this.returnToSearchEvent.emit();
  }

  /**
 * Sorts the data in the table based on the column and sort order
 * @param data The data currently being displayed on the table
 * @param config The associated configuration for the data being displayed
 */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (const column of columns ) {
      if (column.sort !== '' && column.sort !== false) {
        columnName = column.name;
        sort = column.sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousVal: any;
      let currentVal: any;

      // If the following columns are used, then need to get different values for previous and current
      if (columnName === 'name') { // get first and last name
        previousVal = (previous?.firstName ? previous?.firstName.toLowerCase() : '') +
          (previous?.lastName ? previous?.lastName.toLowerCase() : '');
        currentVal = (current?.firstName ? current?.firstName.toLowerCase() : '') +
          (current?.lastName ? current?.lastName.toLowerCase() : '');
      } else if (columnName === 'race') { // array of values
        previousVal = previous?.raceDescList ? previous?.raceDescList.join().toLowerCase() : '';
        currentVal = current?.raceDescList ? current?.raceDescList.join().toLowerCase() : '';
      } else if (columnName === 'dateOfBirthString') { // date values
        const dateA: string = previous[columnName]|| '';
        const dateB: string = current[columnName] || '';
        previousVal = this.searchService.parseDateString(dateA);
        currentVal = this.searchService.parseDateString(dateB) ;
      } else if (columnName === 'registrationDate') { // date values
        previousVal =  previous[columnName] || '';
        currentVal = current[columnName] || '';
      }else { // simple values
        previousVal = previous[columnName] ? previous[columnName].toLowerCase() : '';
        currentVal = current[columnName] ? current[columnName].toLowerCase() : '';
      }

      if (previousVal > currentVal) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousVal < currentVal) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
  * Main method to handle filtering, sorting of the table
  * @param config Configuration of the datatable
  * @param page The current page being displayed
  */
  public onChangeTable(config: any): any {
    let column: any = null;
    this.rows = [];
    if (config.title !== undefined && config.name !== undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.results, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;

  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data === undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name] != null) {
            return item[column.name].toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name] != null && item[column.name] !== undefined
          && item[column.name].toString().match(this.config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

   /**
   * Calls the service to return the patients matching the search criteria
   */
   private getPatientsForCriteria(patientId: string, trackingNumber: string) {

    // Below is to display labels on preview pane, based on patientId or trackingNumber
    if(trackingNumber) {
        this.columns.forEach(col => {
          if(col.name === 'patientId' || col.name === 'trackingNum'){
            col.display = true;
          }
        });
    }

    if(patientId){
      this.columns.forEach(col => {
        if(col.name === 'patientId'){
          col.display = true;
        }
        if(col.name === 'trackingNum'){
          col.display = false;
        }
      });
    }


     this.subscriptions.push( this.searchService.getPatient(patientId, trackingNumber)
    .subscribe(res => {
      this.results = res;
           //check for patientId and trackingNumber
           this.results.forEach(result => {
            if(trackingNumber){
               this.isSearchBasedOnTrackingNum = true;
            }else{
              this.isSearchBasedOnTrackingNum = false;
            }
          })
      this.noResultsFound = (this.results.length === 0);
      // this.onChangeTable(this.config);
    }));
  }
}
