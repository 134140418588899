<mc-header-bar [headerModel]="headerModel" (saveEvent)="saveChanges()" (cancelEvent)="cancelChanges()"></mc-header-bar>

<div>
<div class=" builder-content "
  [ngClass]="{ 'switchToHorizontal': previewModelHome.isVertical,'is-vertical-open': previewModelHome.isCollapsed  }">
  <div id="mainContainer" [ngClass]="{'switchVertical columns': previewModelHome.isVertical }">
    <div class="has-builder-header-content" [ngClass]="{ 'column is-main-section-vertical': previewModelHome.isVertical,
         'is-6' : isExpandedVertical(), 'is-11' : isCollapsedVertical() }" class="main-section" id="mainSection">
<!-- <div class="main-section" id="mainSection"> -->

  <div class="header-panel">
    <div class=" has-header">
      <div class="nav-return-bar">
        <ul>
          <li class="has-link ">
            <a (click)="navigateBack()">
              <span class="icon">
                <i class="fas fa-arrow-left"></i>
              </span>
              <span class="link-text">Back to Subject Landing</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="  form-page columns is-marginless">
    <div class="column  is-12 ">
      <div class="main-postprocessing">
        <h3 class="patient-postprocessing-title">Post Processing Event: {{eventName}}</h3>
        <table class="table is-fullwidth is-borderless is-striped postprocessing-table" role="grid ">
          <tbody>
            <ng-container *ngFor="let row of caseIntegrationStatusList; let idx=index ">
              <tr>
                <td>{{row.targetSystem}} </td>
                <td>{{row.transactionResult}} </td>
                <td class="min">
                  <button *ngIf="isResendAllowed(row)" class="button is-small is-primary " (click)="postProcessingAction(row, 'resend', idx)" [disabled]="isResendDisabled(row)">
                  Resend Enrollment
                  </button> 
                  
                  <button *ngIf="isUpdateAllowed(row)" class="button is-small is-primary " (click)="postProcessingAction(row, 'update', idx)" [disabled]="isUpdateDisabled(row)">
                  Update
                  </button>
                </td>
                <td>
                  <button class="button is-small is-primary " (click)="postProcessingAction(row, 'history', idx)">
                    History
                  </button>
                </td> 
                <td *ngIf="row?.ravePatientInfo?.siteMismatch" class="site-mis-match-msg">
                    {{row?.ravePatientInfo?.siteMismatchMsg}}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
<div class="preview-section" id="previewSection" [ngClass]="{ 'togglePerviewOverlay' : isExpandedHorizontal(),                
'is-6 preview-vertical-expanded' : isExpandedVertical(),
 'is-1' : isCollapsedVertical(),
'column ': previewModelHome.isVertical , 'isCollapsedHorizontal' : isCollapsedHorizontal()}">
<mc-preview [formTitle]="" [formCaption]="previewTitle" [previewModel]="previewModelHome" #previewSection>
  <div preview-data>
    <div class="columns" *ngIf="historyTarget">
      <div class="column">
        <section class="section" *ngIf="!postProcessingHistoryRecords || postProcessingHistoryRecords.length === 0">
          <div class="container">
            <div class="subtitle has-text-centered">
              <h2 class="has-text-weight-bold">
                No History Found
              </h2>
            </div>
          </div>
        </section>
        <mc-post-processing-history-preview *ngIf="isRavePreview()"
          [postProcessingHistoryRecords]="postProcessingHistoryRecords"></mc-post-processing-history-preview>
        <mc-post-processing-history-preview-pb *ngIf="isPmbV2OrBioMsPreview()"
          [postProcessingHistoryRecords]="postProcessingHistoryRecords"></mc-post-processing-history-preview-pb>
        <mc-post-processing-history-preview-pm *ngIf="isPMBorMcKessonPreview()"
          [postProcessingHistoryRecords]="postProcessingHistoryRecords"></mc-post-processing-history-preview-pm>
      </div>
    </div>
  </div>
</mc-preview>
</div>
</div>


</div></div>