import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { ExampleModel } from 'app/common/model/study-create-example-model';
import { PngModel } from 'app/common/model/study-create-png-model';
import { BaseService } from 'app/common/services/base-service';
import { Observable, of } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../../app-config/app-config.module';
import { StudyCreateCcdr } from '../model-v2/study-create-ccdr';
import { AncillaryStudy } from '../model/ancillary-study';
import { AncillaryStudyDisplay } from '../model/ancillary-study-display';
import { Code } from '../model/code';
import { ListOptionModel } from '../model/list-option';
import { Organization } from '../model/organization';
// Models
import { StudyCreate } from '../model/study-create';
import { StudyTemplateMatch } from '../model/study-template-match';



@Injectable()
export class StudyCreateService extends BaseService {


  // Gets url-pattern from config file.
  serviceUrlPrefix = this.config.apiEndpoint;
  serviceUrlPrefix_v2 = this.config.v2_apiEndpoint;


  constructor(private http: HttpClient,
    componentFactoryResolver: ComponentFactoryResolver,
    @Inject(APP_CONFIG) private config: AppConfig) {
    super(componentFactoryResolver);
  }



  /**
   * Returns a blank instance of a studyCreate model
   * @param studyTypeId The blank study model for the studyType Id
   */
  initializeBlankStudy(studyTypeId: number, studyNumber: string): StudyCreate {
    const blankStudy: StudyCreate = {
      'studyTypeId': studyTypeId,
      'studyNumber': studyNumber,
      'dataCenterId': 0,
      'dataCenter': '',
      'randomCenterId': 0,
      'randomCenter': '',
      'sponsor': '',
      'sponsorId': 0,
      'researchEntityId': 0,
      'assignmentGroupId': 0,
      'acronym': '',
      'shortDescription': '',
      'longDescription': '',
      'referenceNumSections': null,
      'referenceNumber': ''
      // "ancillaryStudy": {
      //   parentDataCenterId: 0,
      //   parentProtocolNumber: "",
      //   parentStudyId: 0,
      //   relatedStudySchemaEventId: 0
      // }
    };
    return blankStudy;
  }

  initializeBlankAncillaryStudy(studyTypeId: number): AncillaryStudy {
    const blankStudy: AncillaryStudy = {
      ancillaryStudyDetailId: 12,
      isBioms: false,
      parentProtocolNumber: '',
      parentStudyId: 0,
      parentDataCenterId: 0,
      // relatedStudySchemaEvent: {code:"", id:1,name:""},
      relatedStudySchemaEventId: 0,
      relatedKeyEventMappingId: 0,
      relatedStudySchemaEventLabel: '',
      studyRelationId: 1,
      studyRelationTypeId: 1,
      studyRelationTupleVersion: 1,
      study: {
        studyId: 0,
        'studyNumber': '',
        studyTypeId: studyTypeId,
        'dataCenterId': 0,
        'dataCenter': '',
        'randomCenterId': 0,
        'randomCenter': '',
        'sponsor': '',
        'sponsorId': 0,
        'researchEntityName': '',
        'shortDescription': '',
        'longDescription': '',
        'referenceNumber': '',
        'currentMsrStudyStatus': null
      },
      studyAccrual: null,
      patientEnrollment: {code: '', id: 1, name: ''},
      consentVariables: [{studyFormVariableValueId: 1, validValue: 'Answer',
      variableClass: 'grouping', variableName: 'var-1'}],
      consentVariableCondition : {code: 'ALL', id: 1, name: 'ALL'},
    };
    return blankStudy;
  }


  /**
   * Returns a blank instance of a studyCreate model for CCDR
   * @param studyTypeId The blank study model for the studyType Id
   */
  initializeBlankStudyCcdr(studyTypeId: number): StudyCreateCcdr {
    const blankStudy: StudyCreateCcdr = {
      'studyTypeId': studyTypeId,
      'studyTypeCode': '',
      'studyNumber': '',
      'dataCenterId': 0,
      'dataCenter': '',
      'randomCenterId': 0,
      'randomCenter': '',
      'sponsor': '',
      'sponsorId': 0,
      'researchEntityId': 0,
      'assignmentGroupId': 0,
      'acronym': '',
      'shortDescription': '',
      'longDescription': '',
      'referenceNumSections': null,
      'referenceNumber': '',
      'multipleSubjectType': true
    };
    return blankStudy;
  }

  /**
   * Create a new study
   * @return The new study id
   */
  createStudy(study: StudyCreate): Observable<any> {
    return this.http.post<any>(this.serviceUrlPrefix + 'studies/', study);
  }
 /**
   * Create a new ancillary study
   * @return The new study id
   */
  createAncillaryStudy(study: AncillaryStudy): Observable<any> {
    return this.http.post<any>(this.serviceUrlPrefix + 'ancillary-studies', study);
  }
  /**
   * Get the valid study domains
   */
  getDomains(): Observable<Array<ListOptionModel>> {
    return of([new ListOptionModel('What is this', '1')]);
  }

  getSystemGeneratedRef(): Observable<Array<ListOptionModel>> {
    return of([new ListOptionModel('Yes', 'Yes'), new ListOptionModel('No', 'No')]);
  }

  /**
   * Get the valid study types
   */
  getStudyTypes(): Observable<Array<Code>> {
    return this.http.get<Array<Code>>( this.serviceUrlPrefix + 'configuration/study-type-codes' );
  }
  /**
   * @returns Observable List of Research Entity
   */
  getResearchEntityTypes(): Observable<Array<Code>> {
    return this.http.get<Array<Code>>( this.serviceUrlPrefix + 'configuration/research-entity-codes' );
  }

  /**
   * Get the valid data center organizations
   */
  getDataCenters(): Observable<Array<Organization>> {
    return this.http.get<Array<Organization>>( this.serviceUrlPrefix + 'organizations/datacenters' );
  }

  /**
   * Retrives all the necessary details for the ancillary study
   *
   * @param  {string} protocolNumber
   * @returns Observable
   */
  getAncillaryStudyDetails(protocolNumber: string): Observable<AncillaryStudyDisplay> {
    return this.http.get<AncillaryStudyDisplay>( this.serviceUrlPrefix + 'studies/ancillary/' + protocolNumber );
  }


  /**
   * Get List of Questions
   * @param {number} dataCenterId - the  Data Center organization id
   * @return SectionModel[] - array of SectionModel objects containing the sections to be prompted
   *  for assigning a reference number to the study
   */
  getListOfQuestions(dataCenterId: number, referenceTypeId: number): Observable<PngModel> {
    if (this.config.version == 'v2') {
      const myUrl = this.serviceUrlPrefix_v2 + 'organizations/' + dataCenterId + '/types/' + referenceTypeId + '/study-questions';
      return this.http.get<PngModel>(myUrl);
    }
    const myUrl = this.serviceUrlPrefix + 'organizations/' + dataCenterId + '/types/' + referenceTypeId + '/study-questions';
    return this.http.get<PngModel>(myUrl);
  }


  /**
  * Get the study details like short desc and long desc from Velos Api
   * @param  {number} studyNumber 
   * @param isToSyncTitle - This will be true when using to sync title
   */
  getVelosDetails(studyNumber: string, isToSyncTitle : boolean): Observable<any> {
    // 2017-100633-S
    let myUrl = this.serviceUrlPrefix + 'msr/studies/' + studyNumber;
    if(isToSyncTitle){
        myUrl = myUrl + "?isToSyncTitle="+isToSyncTitle;
    }
    return this.http.get<any>( myUrl);
  }

  /**
   * Get example protocol number
   * @param {number} groupId - the AssignmentGroupID of the selected Data Center
   *         {number} refTypeId - the reference Type id
   *        {ExampleModel} model - sequenceNumSectionId, sectionDetailId
   * @return example protocol number
   */
  getProtocolNumberExample(groupId: number, refTypeId: number, parentProtocol: string , model: ExampleModel[]): Observable<any> {
    // eslint-disable-next-line max-len
    if (this.config.version == 'v2') {
      const myUrl = this.serviceUrlPrefix_v2 + 'groups/' + groupId + '/types/' + refTypeId + '/generate-study-number/example?protocol=' + parentProtocol;
      return this.http.post<any>(myUrl, model);
    }
    const myUrl = this.serviceUrlPrefix + 'groups/' + groupId + '/types/' + refTypeId + '/generate-study-number/example?protocol=' + parentProtocol;
    return this.http.post<any>(myUrl, model);
  }

  // -----------Version v2--------------------

  /**
   * Get the valid study types based on V2
   */
  getStudyTypesForV2(): Observable<Array<Code>> {
    return this.http.get<Array<Code>>( this.serviceUrlPrefix_v2 + 'configuration/study-type-codes' );
  }


  getVelosStudyCcdrDetails(studyNumber: string): Observable<any> {
    // 2017-100633-S
    return this.http.get<any>(this.serviceUrlPrefix_v2 + 'msr/studies/' + studyNumber );

   // return this.http.get<any>('http://r5104225:8080/reg-backend-service/api/v2/msr/studies/2020-107176-S');

    //  let studyTest = {
    //   "studyTypeId": 3,
    //   "studyNumber": "",
    //   "dataCenterId": 37688,
    //   "dataCenter": "Alliance",
    //   "randomCenterId": 0,
    //   "randomCenter": "Alliance",
    //   "sponsor": "",
    //   "sponsorId": 0,
    //   "researchEntityId": 2,
    //   "assignmentGroupId": 0,
    //   "acronym": "",
    //   "shortDescription": "Val's Sponsor record for Multiple Subject Testing. 3Aug2020",
    //   "longDescription": "Val's Sponsor record for Multiple Subject Testing. 3Aug2020",
    //   "referenceNumSections": null,
    //   "referenceNumber": "",
    //     "relatedStudyInfo": {
    //         "studyNumber": "2020-107112-S",
    //         "subjectType": "Patient",
    //         "relatedStudies": [{
    //           "relatedStudyNumber": "2020-107111-H01"
    //         },
    //         {
    //           "relatedStudyNumber": "2020-107111-H02"
    //         }

    //         ]
    //     }

    // };

  // return of(studyTest);

  }

  /**
   * This will create a study for the selected subject type.
   *
   * @param study - Request payload to create a study for CCDR
   */
  createStudy_v2(study: StudyCreate): Observable<any> {
    return this.http.post<any>(this.serviceUrlPrefix_v2 + 'studies/', study);
  }


  /**
  * Get the study details like short desc and long desc from Velos Api
  * @param  {number} studyNumber 
  * @param isToSyncTitle - This will be true when using to sync title
  */
  getMatchingStudyTemplate(studyTypeId: number, researchEntityId: number): Observable<StudyTemplateMatch> {
    let myUrl = this.serviceUrlPrefix + 'study-templates/match?research-entity=' + researchEntityId + '&study-type=' + studyTypeId;
    return this.http.get<any>(myUrl);
  }

  
  /**
   * Apply a study template to a study
   * @return success/failure and message
   */
  applyStudyTemplate(studyId: number): Observable<any> {
    return this.http.put<any>(this.serviceUrlPrefix + 'studies/' + studyId + '/study-template', null);
  }


    /**
   * Retrieves the possible values for consentVariableCondition
   *
   * @returns Observable
   */
    getAncillaryConsentVariableConditions(): Observable<Array<Code>> {
      return this.http.get<Array<Code>>( this.serviceUrlPrefix + 'configuration/consent-variable-condition' );
      // return of([{"id": 1, "code": "ALL", "name": "All"}, {"id": 2, "code": "OR", "name": "Or"}]);
    }
    
  
}
