<div [ngClass]="{'organization-width': !showEditMode }">
  <div tabindex="0" class="" [ngClass]="{'is-hidden': showEditMode}">
    <div class="control">
      <span *ngIf="currentOrganizationName === ''">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span *ngIf="currentOrganizationName !== ''" name="spnLabel">{{currentOrganizationName}}</span>
    </div>
  </div>


  <div tabindex="-1">
    <div class="field is-grouped clearfix no-bottom-margin "
      [ngClass]="{'is-hidden': !showEditMode, 'organization-width': !showEditMode }">

      <p class="control  " class="no-right-margin" [ngClass]="controlClass">
        <input type="text" [formControl]="formControl" [matAutocomplete]="auto" placeholder="{{getPlaceHolderValue()}}"
          id="organizationSearch" class="form-control input is-small organization-search-input"
          name="organizationSearch" matInput [value]="currentOrganizationName">

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" [panelWidth]="200" autoActiveFirstOption>
          <mat-option *ngFor="let option of organizationOptions | async" [value]="option"
            (onSelectionChange)="handleSelectSuggestion($event)">
            <div class="org-item">
              <span>
                {{ option.ctepId }}
              </span>
              <span [ngClass]="{ 'has-padding': !option.ctepId}">
                |
              </span>
              <span>
                {{ option.abrev}}
              </span>
            </div>

          </mat-option>
        </mat-autocomplete>
      </p>

      <button type="button find-button  " class="button is-custom-save is-small is-radiusless"
        (click)="organizationSearchModal(true,$event, false)">
        <span class=" is-small">
          find
        </span>
      </button>
    </div>

    <div class="modal" [ngClass]="{'is-active': showModal}">
      <div class="modal-background"></div>
      <div class="modal-card org-modal" [mcDraggable]="true">
        <header class="modal-card-head">
          <p class="modal-card-title">Organization Search
          </p>
          <button class="delete" aria-label="close" (click)="organizationSearchModal(false,$event,false)">

          </button>
        </header>
        <div class="modal-card-body ignore-draggable no-top-padding">
          <mc-organization-search [cTepId]="cTepIdForModal" [orgName]="orgNameForModal" [modalVisible]="showModal"
            (itemSelected)="modalItemSelectedEvent($event)" (selectedOrg)="selectedOrganizationRow($event)">
          </mc-organization-search>
        </div>
        <footer class="modal-card-foot ignore-draggable">
          <div class="is-pulled-right footer-button ">
            <button class="button is-primary is-radiusless" [disabled]="!modalItemSelected"
              (click)="organizationSearchModal(false,$event,true)">
              Select</button>
            <button class="button is-radiusless" (click)="organizationSearchModal(false,$event,false)">
              Cancel</button>
          </div>
        </footer>
      </div>
    </div>


  </div>

</div>