/**
 * Class to hold exception message with its appropriate pointer
 * to the source of the exception
*/
export class ErrorMessage {
    constructor(
        public id: any | null,
        public message: string = '',
        public childId?: any | null
    ) { }
}
