import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/common/base/base-component';
import { HeaderBarComponent } from 'app/common/header-bar/header-bar.component';
import { ACTIVITY_STUDY_CASE_EVENT } from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import { CaseProblem } from 'app/common/model/case-problem';
import { EditBarActions } from 'app/common/model/edit-bar-actions';
import { ErrorMessage } from 'app/common/model/error-message';
import { EventArg } from 'app/common/model/event-arg';
import { HeaderBar } from 'app/common/model/header-bar';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { Problem } from 'app/common/model/problem';
import { StudyPatientSearchResult } from 'app/common/model/study-patient-search-result';
import { StudyRecord } from 'app/common/model/study-record';
import { StudyServiceBase } from 'app/common/services/study-service-base.service';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { UserService } from 'app/common/services/user.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { EMPTY, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'mc-case-event',
  templateUrl: './case-event.component.html',
  styleUrls: ['./case-event.component.scss'],
})
export class CaseEventComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  subscriptions: Subscription[] = [];

  /**
   * model for the header bar
   */
  headerModel: HeaderBar;

  selectedPatient: StudyPatientSearchResult = null;

  /**
   * selectedIds Used by edit-bar
   */
  selectedIds: number[] = [];

  actionSetting: EditBarActions = {
    isAddDisabled: false,
    isDeleteDisabled: false,
    isEditDisabled: false,
    isFilterDisabled: false,
    isInfoDisabled: false,
    isEditBarDisabled: false,
  };

  studyId: number;
  studyNumber = '';
  studyRecord: StudyRecord;

  /**
   * Reference to the child header component
   */
  @ViewChild(HeaderBarComponent)
  private headerBar: HeaderBarComponent;

  /**
   * Variable to determine if the Edit button was clicked
   */
  isModeReadonly = true;

  selectedInfoRowId = 0;

  /**
   * The id of the row being currently edited
   */
  currentEditRowId = 0;

  /**
   * The current selected item if it's just one
   */
  private currentSelectedItem: CaseProblem = null;

  data: CaseProblem[] = [];

  originalData: CaseProblem[] = [];

  deletedRecords: CaseProblem[] = [];

  propertyId = 'caseProblemId';

  /**
   * number to decrement and set as the id for a new row
   */
  newRowId = 0;

  problems: Problem[] = [];
  /*********  Pending Changes warning************** */

  /********* END - SAVE / CANCEL ************** */

  public showFilterRow: Boolean = false;
  public rows: Array<CaseProblem> = [];
  public page = 1;
  public itemsPerPage = 10;
  public maxSize = 5;
  public numPages = 1;
  public length = 0;

  public columns: Array<any> = [
    {
      title: 'PID',
      name: 'patientId',
      display: true,
      className: ['table-column-width-20'],
      filtering: { filterString: '', placeholder: 'Filter by PID' },
      sort: '',
    },
    {
      title: 'Initials',
      name: 'initials',
      display: true,
      className: ['table-column-width-10'],
      filtering: { filterString: '', placeholder: 'Filter by Initials' },
      sort: '',
    },
    {
      title: 'CEvent',
      name: 'problem',
      className: ['table-column-width-10'],
      filtering: { filterString: '', placeholder: 'Filter by CEvent' },
      display: true,
      sort: '',
    },
    {
      title: 'Event Date',
      name: 'problemDate',
      className: ['table-column-width-10'],
      filtering: { filterString: '', placeholder: 'Filter by Event Date' },
      display: true,
      sort: '',
    },
    {
      title: 'Comment',
      name: 'comment',
      className: ['table-column-width-50'],
      filtering: { filterString: '', placeholder: 'Filter by Comment' },
      display: true,
      sort: '',
    },
  ];

  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: [],
  };

  constructor(
    studySetupService: StudySetupService,
    private studyServiceBase: StudyServiceBase,
    private userService: UserService,
    private viewContainer: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
  ) {
    super();
    this.subscriptions.push(
      studySetupService.changeEmitted$.subscribe(eventInfo => {
        this.parseEventInfo(eventInfo);
      }),
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.studyId = params['studyId'];
        if (this.studyId > 0) {
          //This is to make the actionSetting disabled if the user is not having write access
          if (
            !this.userService.hasModifyAccessByStudy(
              ACTIVITY_STUDY_CASE_EVENT,
              this.studyId,
            )
          ) {
            this.actionSetting.isAddDisabled = true;
            this.actionSetting.isEditDisabled = true;
            this.actionSetting.isDeleteDisabled = true;
          }
          this.subscriptions.push(
            this.studyServiceBase
              .getCaseProblems(this.studyId)
              .subscribe(results => {
                this.data = results;
                this.cloneData(false);
                this.onChangeTable(this.config);
              }),
          );
          this.subscriptions.push(
            this.studyServiceBase.getProblems().subscribe(results => {
              this.problems = results;
            }),
          );
          this.subscriptions.push(
            this.studySetupService.getStudy(this.studyId).subscribe(results => {
              // initialize the model for the header
              this.studySetupService.studyRecord = results;
              this.studyNumber = results.studyNumber;
              this.headerModel = {
                caption: 'CEvent Landing Page',
                captionValue: '',
                showDescription: true,
                description: 'StudyId: ' + results.studyNumber,
                showButtons: true,
                canNavigate: false,
                navigateUrl: '',
                disableButtons: false,
              };
            }),
          );
        }
      }),
    );
  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
      x.closed;
    });
  }

  ngAfterViewInit() {
    this.headerBar.initHeader();
    //this.studyNumber = this.studySetupService.studyRecord?.studyNumber;
  }

  /**
   * This will Evaluate whether SAVE or CANCEL to be called.
   * @param  {EventArg} eventArg
   */
  parseEventInfo(eventArg: EventArg) {
    if (eventArg.eventName == globalConst.EVENT_SAVE_ACTION) {
      this.saveChanges();
    } else if (eventArg.eventName == globalConst.EVENT_CANCEL_ACTION) {
      // if there are changes...
      this.cancelChanges();
    }
  }

  /**
   * Clones the data to track the changes
   * @param isRollback True if the data is to be reverted to its original state
   */
  cloneData(isRollback: boolean): void {
    if (!isRollback) {
      this.originalData = _.map(this.data, _.cloneDeep);
    } else {
      this.data = _.map(this.originalData, _.cloneDeep);
    }
  }

  /**
   * Handler for the action clicked on the edit bar
   * @param selectedAction the selected action from the editbar
   */
  selectedEditAction(selectedAction) {
    if (selectedAction == 'info') {
      this.processInfoClick();
    } else if (selectedAction == 'add') {
      this.addNewItem();
      this.actionSetting.isAddDisabled = false;
    } else if (selectedAction == 'delete') {
      this.deleteSelectedRows();
    } else if (selectedAction == 'filter') {
      this.showFilterRow = !this.showFilterRow;
    } else if (selectedAction == 'edit') {
      if (this.currentEditRowId == 0) {
        // put the row in edit model
        this.currentEditRowId = this.selectedIds[0];
      } else {
        this.currentEditRowId = 0;
      }
      this.actionSetting.isAddDisabled = false;
    }
  }

  /**
   * Deletes the selected Rows
   */
  deleteSelectedRows(): void {
    if (this.selectedIds.length == 0) {
      return;
    }
    this.selectedIds.forEach(eachId => {
      let idx: number = this.data.findIndex(
        item => item[this.propertyId] == eachId,
      );
      // Can only delete row with no active date || activeDate > today
      const today = new Date().valueOf();
      const row = this.data[idx];
      if (idx > -1) {
        const deletions = this.data.splice(idx, 1);
        this.deletedRecords.push(deletions[0]);
      }
      idx = this.rows.findIndex(item => item[this.propertyId] == eachId);
      if (idx > -1) {
        this.rows.splice(idx, 1);
      }
    });
    this.selectedIds = [];
    this.actionSetting.isAddDisabled = this.data.length > 0;
  }

  /**
   * True if the Edit button is Enabled
   */
  isEditEnabled(): boolean {
    let isEnabled = false;
    if (this.currentSelectedItem) {
      // only enable if it's existing data
      isEnabled = this.currentSelectedItem[this.propertyId] > 0;
    }
    return isEnabled;
  }

  /**
   * Adds a new item to the list on the UI
   */
  addNewItem(): void {
    this.newRowId--;
    const newCaseProblem: CaseProblem = {
      caseProblemId: this.newRowId,
      patient: {
        patientId: '',
        caseDetailId: 0,
        firstName: '',
        lastName: '',
        middleName: '',
      },
      problemDate: null,
      problem: null,
      comment: null,
      lastModifiedDate: null,
      lastModifiedById: null,
      tupleVersion: -1,
    };
    this.data.unshift(newCaseProblem);
    this.rows.unshift(newCaseProblem);
    this.selectedIds = [];
    this.rowSelectedClick(newCaseProblem);
    this.selectedEditAction('edit');
  }

  /**
   * Processes the click on the Info icon on the edit bar
   */
  private processInfoClick() {
    if (this.selectedIds.length > 0) {
      if (
        this.selectedInfoRowId == this.selectedIds[this.selectedIds.length - 1]
      ) {
        this.selectedInfoRowId = 0;
      } else {
        this.selectedInfoRowId = this.selectedIds[this.selectedIds.length - 1];
      }
    } else {
      this.selectedInfoRowId = 0;
    }
  }

  /**
   * True if the row has been selected for showing the info
   * @param row The CaseProblem row being evaluated
   */
  isInfoSelected(row: CaseProblem) {
    return row[this.propertyId] == this.selectedInfoRowId;
  }

  /**
   * Called when a row is selected and the info button is pushed
   *
   * @param row the row of the table to be operated on
   */
  getInfoMessage(row: CaseProblem) {
    let message = '';
    if (this.isInfoSelected(row)) {
      if (row[this.propertyId] < 0) {
        message = 'Case Event Problem not saved';
      } else if (row.lastModifiedById && row.lastModifiedDate) {
        const lastUserName = this.getUserName(row.lastModifiedById);
        const lastModifiedDate = moment(row.lastModifiedDate).format(
          'MM/DD/YYYY HH:mm:ss',
        );
        message =
          '\nLast updated by ' + lastUserName + ' on ' + lastModifiedDate;
      } else {
        message = 'unknown';
      }
    }
    return message;
  }

  getUserName(userPk) {
    let username = 'unknown';
    if (userPk && userPk.length > 0) {
      // force to be numeric
      const userPkNum: any = +userPk;
      if (!isNaN(userPkNum)) {
        this.userService.getPersonForId(userPkNum).subscribe(result => {
          username = result.lastName + ', ' + result.firstName;
        }),
          err => {
            username = userPk + '(name not found)';
          },
          () => {
            // console.log("Complete subscription ")
          };
      }
    }
    return username;
  }

  /**
   * Handler for the selecting new row
   * @param row The CaseProblem object bound to the row
   * @param columnName The column name
   */
  rowSelectedClick(row: CaseProblem): any {
    this.selectedInfoRowId = 0;
    this.currentEditRowId = 0;
    this.currentSelectedItem = null;
    this.selectedIds = [];

    const selectedId: number = this.getIdIndex(row[this.propertyId]);
    if (selectedId > -1) {
      this.selectedIds.splice(selectedId, 1);
    } else {
      this.selectedIds.push(row[this.propertyId]);
    }
    if (this.selectedIds.length == 1) {
      this.currentSelectedItem = row;
    }
  }

  /**
   * True if the row is selected for edit
   * @param row The PersonResponsibility object bound to the row
   */
  isReadOnly(row: CaseProblem) {
    return this.currentEditRowId != row[this.propertyId];
  }

  cellClick(data: CaseProblem, columnName: string): any {
    const selectedId: number = this.getIdIndex(data[this.propertyId]);
    if (selectedId > -1) {
      this.selectedIds.splice(selectedId, 1);
      this.currentEditRowId = 0;
    } else {
      this.selectedIds.push(data[this.propertyId]);
    }
    this.selectedInfoRowId = 0;
  }

  /**
   * Sets the problem date from the date picker
   * @param $selectedDate The selected Date from the datepicker
   * @param row The caseProblem object to the updated
   */
  onCellUpdated($selectedDate: Date, row: CaseProblem) {
    if ($selectedDate == null) {
      row.problemDate = undefined;
    } else {
      row.problemDate = $selectedDate;
    }
  }

  /**
   * True if the row is selected
   * @param row The CaseProblem object bound to the row
   */
  isRowSelected(row: CaseProblem): boolean {
    return this.getIdIndex(row[this.propertyId]) > -1;
  }

  /**
   * True if this row is selected for Edit
   * @param row The CaseProblem object bound to the row
   */
  isSelectedForEdit(row: CaseProblem): boolean {
    return this.currentEditRowId == row[this.propertyId];
  }

  /**
   * True if this row is selected for Edit and is NOT a newly created row
   * @param row The CaseProblem object bound to the row
   */
  isSelectedForEditOnly(row: CaseProblem): boolean {
    return this.currentEditRowId == row[this.propertyId]; // && this.currentEditRowId > 0;
  }

  /**
   * Returns the index of the id in the selectedIds
   * @param id the id to evaluate
   */
  getIdIndex(id) {
    let idx = -1;
    if (this.selectedIds.length > 0) {
      idx = this.selectedIds.findIndex(x => x == id);
    }
    return idx;
  }

  /**
   * True if the original and current lists are different
   */
  doChangesExist(): boolean {
    const isModified: boolean = !_.isEqual(this.data, this.originalData);
    return isModified;
  }

  /**
   * Returns the changed list of CaseProblems
   */
  getDataChanges(): Array<CaseProblem> {
    const isModifiedData = _.differenceWith(
      this.data,
      this.originalData,
      _.isEqual,
    );
    return isModifiedData;
  }

  /**
   * Saves the changes
   */
  saveChanges() {
    // this list contains all the inserts and the updates
    // If data is invalid exit or no changes, exit
    this.clearErrorMessages();
    if (!this.doChangesExist()) {
      return;
    }
    const modifiedData: CaseProblem[] = this.getDataChanges();
    let hasError = false;

    const observables: Observable<any>[] = [];
    const errorMessages: ErrorMessage[] = [];

    // we have to call the delete individually
    if (this.deletedRecords.length > 0) {
      for (const deletedRow of this.deletedRecords) {
        observables.push(
          this.studyServiceBase
            .deleteCaseProblem(
              this.studyId,
              deletedRow[this.propertyId],
              deletedRow.tupleVersion,
            )
            .pipe(
              tap(x => {
                console.log('Deleted=> ', deletedRow[this.propertyId]);
              }),
            )
            .pipe(
              catchError(error => {
                errorMessages.push(error);
                throw error;
              }),
            ),
        );
      }
    }
    if (modifiedData.length > 0) {
      for (const eachItem of modifiedData) {
        const eachSave = this.saveEachCode(eachItem, errorMessages);
        observables.push(forkJoin(eachSave));
      }
    }
    // set the current data to the updated data
    this.subscriptions.push(
      forkJoin(observables).subscribe(
        () => {
          if (errorMessages.length > 0) {
            this.groupErrors(errorMessages);
            hasError = true;
          }
        },
        error => {
          hasError = true;
        },
        () => {
          if (hasError) {
            return;
          }
          this.cloneData(false);
          this.onChangeTable(this.config);
          this.selectedIds = [];
          this.deletedRecords = [];
          this.currentEditRowId = 0;

          this.selectedInfoRowId = 0;
          let variableData = '';
          if (this.studyNumber.length > 0) {
            variableData = 'studyNumber:' + this.studyNumber;
          }
          const modalArg = new ModalDialogArg(
            'custom-save',
            'Save',
            variableData,
          );
          this.subscriptions.push(
            this.studySetupService
              .showPendingChangesDialog(this.viewContainer, modalArg)
              .subscribe(actionResult => {
                if (!actionResult) {
                  // user wants to navigate to the Study Landing page
                  this.router.navigate(['studies', this.studyId]);
                } else {
                  // refresh the list before continuing
                  this.subscriptions.push(
                    this.studySetupService
                      .getCaseProblems(this.studyId)
                      .subscribe(results => {
                        this.data = results;
                        this.cloneData(false);
                        this.onChangeTable(this.config);
                        this.clearErrorMessages();
                      }),
                  );
                }
              }),
          );
        },
      ),
    );
  }

  /**
   * Evaluates update or insert and Saves each code
   * @param eachItem Each modified or new CaseProblem
   * @param errorMessages Error messages array to be populated for errors
   */
  saveEachCode(
    eachItem: CaseProblem,
    errorMessages: ErrorMessage[],
  ): Observable<any>[] {
    const observables: Observable<any>[] = [];
    //  eachItem is the object to be submitted for update
    if (eachItem[this.propertyId] < 0) {
      const newClone = _.cloneDeep(eachItem);
      // Call Insert:
      const irt = this.studyServiceBase.addCaseProblem(this.studyId, eachItem);
      observables.push(
        irt
          .pipe(
            tap(x => {
              const newItemIdx = this.data.findIndex(
                v => v[this.propertyId] == newClone[this.propertyId],
              );
              this.data[newItemIdx] = x;
            }),
          )
          .pipe(
            catchError(error => {
              if (
                !this.checkValidationErrorInResponse(
                  newClone[this.propertyId],
                  error,
                  errorMessages,
                )
              ) {
                throw error;
              } else {
                return of(EMPTY);
              }
            }),
          ),
      );
    } else {
      // call update
      observables.push(
        this.studyServiceBase
          .updateCaseProblem(this.studyId, eachItem)
          .pipe(
            tap(x => {
              const exist = this.data.findIndex(
                v => v[this.propertyId] == eachItem[this.propertyId],
              );
              this.data[exist] = x;
            }),
          )
          .pipe(
            catchError(error => {
              if (
                !this.checkValidationErrorInResponse(
                  eachItem[this.propertyId],
                  error,
                  errorMessages,
                )
              ) {
                throw error;
              } else {
                return of(EMPTY);
              }
            }),
          ),
      );
    }
    return observables;
  }

  /**
   * Prompts the user for confirmation and reverts the data to its original state
   */
  cancelChanges() {
    // if there were no changes ignore
    if (this.doChangesExist()) {
      const modalArg = new ModalDialogArg('modal-warn', 'Cancel', null);
      this.subscriptions.push(
        this.studySetupService
          .showPendingChangesDialog(this.viewContainer, modalArg)
          .subscribe(result => {
            if (result) {
              this.selectedIds = [];
              this.currentEditRowId = 0;
              this.cloneData(true);
              this.onChangeTable(this.config);
              this.actionSetting.isAddDisabled = false;
              this.clearErrorMessages();
            }
          }),
      );
    }
  }

  /********* Pending Changes warning************** */
  /**
   * Implemenation of the canDeactivate that will be called when we navigate away
   *
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.doChangesExist()) {
      return true;
    }
    const variableData: string = 'studyNumber:' + this.studyId;
    const modalArg = new ModalDialogArg('modal-warn', 'Cancel', variableData);
    const result = this.studySetupService.showPendingChangesDialog(
      this.viewContainer,
      modalArg,
    );
    return result.asObservable().pipe(
      tap(x => {
        if (x) {
          this.cloneData(false);
        }
        return of(x);
      }),
    );
  }

  /**
   * Sorts the data in the table
   * @param data The data to sort
   * @param config The config to use
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }
    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }
    if (!columnName) {
      return data;
    }
    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousValue: any;
      let currentValue: any;
      if (columnName === 'patientId') {
        previousValue = previous['patient']['patientId']
          ? previous['patient']['patientId']
          : '';
        currentValue = current['patient']['patientId']
          ? current['patient']['patientId']
          : '';
      } else if (columnName === 'initials') {
        // get first and last name
        previousValue =
          (previous['patient']['firstName']
            ? previous['patient']['firstName'].toLowerCase()
            : '') +
          (previous['patient']['lastName']
            ? previous['patient']['lastName'].toLowerCase()
            : '');
        currentValue =
          (current['patient']['firstName']
            ? current['patient']['firstName'].toLowerCase()
            : '') +
          (current['patient']['lastName']
            ? current['patient']['lastName'].toLowerCase()
            : '');
      } else if (columnName === 'problem') {
        previousValue = previous[columnName]['problemCode']
          ? previous[columnName]['problemCode'].toLowerCase()
          : '';
        currentValue = current[columnName]['problemCode']
          ? current[columnName]['problemCode'].toLowerCase()
          : '';
      } else if (columnName === 'problemDate') {
        previousValue = previous[columnName]
          ? previous[columnName]
          : new Date(0);
        currentValue = current[columnName] ? current[columnName] : new Date(0);
      } else {
        previousValue = previous[columnName]
          ? previous[columnName].toLowerCase()
          : '';
        currentValue = current[columnName]
          ? current[columnName].toLowerCase()
          : '';
      }

      if (previousValue > currentValue) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousValue < currentValue || previousValue == null) {
        return sort === 'asc' ? -1 : 1;
      }

      return 0;
    });
  }

  /**
   * Filters the data being displayed
   * @param data The data to filter
   * @param config The configuration to use
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }

    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (
            column.name === 'patientId' &&
            item['patient'] &&
            item['patient']['patientId']
          ) {
            return item['patient']['patientId']
              .toLowerCase()
              .match(column.filtering.filterString.toLowerCase());
          } else if (
            column.name === 'initials' &&
            item['patient'] &&
            item['patient']['firstName'] &&
            item['patient']['lastName']
          ) {
            const initials =
              item['patient']['firstName'] + item['patient']['lastName'];
            return initials
              .toLowerCase()
              .match(column.filtering.filterString.toLowerCase());
          } else if (
            column.name === 'problem' &&
            item['problem'] &&
            item['problem']['problemCode']
          ) {
            return item['problem']['problemCode']
              .toLowerCase()
              .match(column.filtering.filterString.toLowerCase());
          } else if (column.name === 'problemDate' && item['problemDate']) {
            const problemDateString = moment(item['problemDate']).format(
              'MM/DD/YYYY',
            );
            return problemDateString.match(column.filtering.filterString);
          } else if (column.name === 'comment' && item['comment']) {
            return item['comment']
              .toLowerCase()
              .match(column.filtering.filterString.toLowerCase());
          }
          return ''
            .toLowerCase()
            .match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    return filteredData;
  }

  /**
   * Handler when the sort, page, or filter options changes on the table
   * @param config the configuration to use
   * @param page The page to display
   */
  public onChangeTable(
    config: any,
    page: any = { page: this.page, itemsPerPage: this.itemsPerPage },
  ): any {
    let column: any = null;
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.data, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows =
      page && config.paging ? this.changePage(page, sortedData) : sortedData;
    if (sortedData) {
      this.length = sortedData.length;
    }
  }

  /**
   * handler for the change page event
   * @param page The page to display
   * @param data The data to choose from
   */
  public changePage(page: any, data: Array<any> = this.data): Array<any> {
    const start = (page.page - 1) * page.itemsPerPage;
    const end =
      page.itemsPerPage > -1 ? start + page.itemsPerPage : data.length;
    return data.slice(start, end);
  }

  /**
   * Compare objects, used in html for matching coded lists
   */
  compareProblemFn(a, b) {
    if (a || b) {
      return a && b && a.problemId == b.problemId;
    } else {
      return true;
    }
  }

  /**
   * Handler to navigate back to the home page
   */
  navigateBack(): void {
    if (!this.doChangesExist()) {
      this.router.navigate(['studies', this.studyId]);
    }
    // otherwise display dialog
    const modalArg = new ModalDialogArg('modal-warn', 'Cancel', null);
    this.subscriptions.push(
      this.studySetupService
        .showPendingChangesDialog(this.viewContainer, modalArg)
        .subscribe(actionResult => {
          if (actionResult) {
            // user wants to navigate to the Study Landing page
            this.router.navigate(['studies', this.studyId]);
          }
        }),
    );
  }

  /**
   *
   * @param $event The selected Patient from the modal
   */
  onSelectPatient($event: StudyPatientSearchResult, row: CaseProblem): void {
    this.selectedPatient = $event;
    row.patient.patientId = this.selectedPatient.patient.patientId;
    row.patient.firstName = this.selectedPatient.patient.firstName;
    row.patient.lastName = this.selectedPatient.patient.lastName;
    row.patient.caseDetailId = this.selectedPatient.patient.caseDetailId;
  }
}
