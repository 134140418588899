import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { OrgNetwork } from '../model/org-network';
import { StudySetupService } from '../services/study-setup.service';




@Component({
  selector: 'mc-network-organization-search',
  templateUrl: './network-organization-search.component.html',
  styleUrls: ['./network-organization-search.component.scss']
})
export class NetworkOrganizationSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() currentOrganization: OrgNetwork = null;
  @Input() isReadonly = false;
  @Input() showEditModeOnly = true;
  @Input() controlClass = '';
  @Input() cTepId = '';
  /**
   * Used to determine if the Level 1 member sites of Accru Org are to be retrieved
   */
  @Input() isAccruMembersOnly = false;
  @Output() organizationSelected = new EventEmitter<OrgNetwork>();

  public selectedOrgRow: OrgNetwork;

  showModal = false;
  showEditMode = false;

  cTepIdForModal: string = this.cTepId;
  orgNameForModal = '';

  searchHasFocus = false;

  modalItemSelected = false;


  searchElement: any = null;

  subscriptions: Subscription[] = [];

  currentOrganizationName = '';

  @ViewChild('organizationSearch', { read: ViewContainerRef, static: true }) orgnSearch;


  /**
   * variable to determine how many chars needed enable the find button
   */
  minimumCharCount = 3;


  public page = 1;
  public itemsPerPage = 10;
  public length = 0;
  public selectedOrgNum: number;
  public organizationList: OrgNetwork[];
  public showFilterRow: Boolean = true;
  public selectedOrgName = '';

  public rows: Array<any> = [];
  public columns: Array<any> = [
    {
      title: 'CTEP ID',
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by CTEP ID' },
      name: 'ctepId', sort: '',
      className: 'has-fixed-cell-width'
    },
    {
      title: 'Org#',
      display: true,
      name: 'networkPK', sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Org Number', sort: '' },
      className: 'has-fixed-cell-width'
    },
    {
      title: 'Name', name: 'name',
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by name' },
      className: 'has-name-content'
    },
    {
      title: 'Status', name: 'status',
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by status' },
      classname: ''
    }
  ];

  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['is-striped', 'is-bordered']
  };


  private data: Array<OrgNetwork>;

  @ViewChildren('orgRow', { read: ElementRef }) viewContentDivs: QueryList<any>;


  private selectedContentDiv: any = null;

  constructor(private element: ElementRef,
    private renderer: Renderer2,
    private studySetupService: StudySetupService
  ) {

  }

  ngOnInit() {
    this.showEditMode = this.showEditModeOnly;
    this.searchElement = this.renderer.selectRootElement(this.orgnSearch); // ('#organizationSearch');
    this.minimumCharCount = (this.isAccruMembersOnly) ? -1 : this.minimumCharCount;
  }
  ngAfterViewInit() {
    this.searchElement.value = '';
    if (this.currentOrganization) {
      this.searchElement.value = this.currentOrganization.name;
      this.selectedOrganizationRow(this.currentOrganization);
    }
    this.setModalValues();
  }

  setModalValues() {
    setTimeout(() => {
      if (this.currentOrganization) {
        this.orgNameForModal = this.currentOrganization.name;
      }
      this.cTepIdForModal = this.cTepId;
    }, 50);
  }

  /**
    * Destroy implementation - closes all the subscriptions
    */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    );
  }

  /**
   * Returns the current organization name if it exists
   */
  getCurrentOrganizationName() {
    if (this.currentOrganization && this.currentOrganization.networkPk > 0) {
      return this.currentOrganization.name;
    }
    if (this.selectedOrgRow) {
      return this.selectedOrgRow.name;
    }
    return '';
  }

  removeModalOrgFilter() {
    this.orgNameForModal = '';
  }

  removeModalCtepIdFilter() {
    this.cTepIdForModal = '';
  }

  onQueryChange(query: string) {

  }

  /**
   * True if the number of chars in the input box meet the criteria to enable it
   */
  shouldEnableSearch(): boolean {
    let shouldEnable = false;
    if (this.searchElement && this.searchElement.element &&
      this.searchElement.element.nativeElement &&
      this.searchElement.element.nativeElement.value) {
      shouldEnable = this.searchElement.element.nativeElement.value.trim().length >= this.minimumCharCount;
    }
    return shouldEnable;
  }




  // selectSuggestion(suggestion: NetworkOrganization) {
  //   this.currentOrganizationName = suggestion.name;
  //   this.cTepId = suggestion.ctepId;
  //   this.setModalValues();
  //   this.organizationSelected.emit(suggestion);
  //   this.onSearch(suggestion.name);
  // }

  selectedOrganizationRow(row: OrgNetwork) {
    this.selectedOrgRow = row;
  }

  organizationSearchModal($event: Event, show: boolean, selected: boolean = false) {
    if ($event) {
      $event.stopPropagation();
    }
    this.showModal = show;
    if (show) {
      const qry: string = this.searchElement.element.nativeElement.value.trim();
      if ((this.currentOrganization && this.currentOrganization.name != qry) ||
        (this.selectedOrgRow && this.selectedOrgRow.name != qry)) {
        this.selectedOrganizationRow(null);
        this.selectedOrgNum = 0;
        this.modalItemSelectedEvent(false);
      }

      // call the search and retrieve results
      let obs: Observable<any>;
      if (this.isAccruMembersOnly) {
        obs = this.getAccruMembers();
      } else {
        obs = this.getNetworkOrgs(qry);
      }
      this.subscriptions.push(
        obs.subscribe(results => {
          this.data = results;
          this.length = this.data.length;
          this.onChangeTable(this.config);
        }));
      // this.handleSelectSuggestion(this.selectedOrgRow);
      // this.searchClicked = false;
    }
    if (selected) {
      this.showModal = false;
      // if an org was selected
      if (this.selectedOrgRow) {
        this.organizationSelected.emit(this.selectedOrgRow);
      }
    }
  }

  /**
   *
   * @param qry The filter to use for querying for network Orgs
   */
  getNetworkOrgs(qry: string): Observable<any> {
    return this.studySetupService.getNetworkOrganizations(qry);
  }


  getAccruMembers(): Observable<any> {
    return this.studySetupService.getACCRUMemberOrgs();
  }

  modalItemSelectedEvent(isSelected: boolean) {
    this.modalItemSelected = isSelected;
  }

  /**
   * Helper method to locate the row if an org was previously selected
   */
  selectNavRow() {
    if (this.selectedOrgName && this.selectedOrgNum > 0) {
      if (this.viewContentDivs && this.viewContentDivs.length > 0) {
        this.viewContentDivs.forEach((eachItem, itemIdx) => {
          const elem = this.renderer.selectRootElement(eachItem).nativeElement;
          if (elem.id && elem.id == this.selectedOrgNum + '') {
            this.selectedContentDiv = elem;
          }
        });
      }
    }
  }




  /**
   * Sorts the data based on the confi
   * @param data Data to be sorted
   * @param config configuration used to sort
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
   * Common method called when any sort / filter changes on the ui
   * @param config configuration for filter / sorting
   * @param page The page to which to navigate
   */
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    let column: any = null;
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.data, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    setTimeout(() => {
      this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
      if (sortedData) {
        this.length = sortedData.length;
      }
    }, 100);

  }

  /**
   * Filters the data based on config and filter string
   * @param data data to filter
   * @param config config to use
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;
    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name]) {
            if (column.name === 'networkPK') {
              return item[column.name].toString().match(column.filtering.filterString.toLowerCase());
            } else {
              return item[column.name].toLowerCase().match(column.filtering.filterString.toLowerCase());
            }
          } else {
            return item;
          }

        });
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }
    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = true;
      this.columns.forEach((column: any) => {
        if (!flag) {
          return;
        }
        if (item[column.name]) {
          if (column.name == 'ctepId') {
            const itemVal: string = item[column.name].toLowerCase();
            const filterStr: string = column.filtering.filterString.toLowerCase();
            if (itemVal.length > 0 && !itemVal.match(filterStr)) {
              flag = false;
            }
          } else if (!item[column.name].toString().match(this.config.filtering.filterString)) {
            flag = false;
          }
        } else {
          if (column.name == 'ctepId') {
            if (column.filtering.filterString.length > 0) {
              flag = false;
            }
          }
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  /**
   *
   * @param page
   * @param data
   */
  public changePage(page: any, data: Array<any> = this.data): Array<any> {
    const start = (page.page - 1) * page.itemsPerPage;
    const end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  /**
   * CAlled when a organization is selected
   * @param row The selected organization
   */
  public selectOrganization(row: OrgNetwork) {
    // this.itemSelected.emit(true);
    // this.selectedOrg.emit(row);
    if (this.selectedOrgNum == row.networkPk) {
      this.selectedOrganizationRow(null);
      this.selectedOrgNum = 0;
      this.modalItemSelectedEvent(false);
    } else {
      this.selectedOrganizationRow(row);
      this.selectedOrgNum = +row.networkPk;
      this.modalItemSelectedEvent(true);
    }
  }


  /**
   * True if the organization is selected on the ui
   * @param org The organization evaluate
   */
  isOrganizationSelected(org: OrgNetwork): boolean {
    return org && org.networkPk && this.selectedOrgNum == +org.networkPk;
  }





}
