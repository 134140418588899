import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupNotificationRoutingModule } from 'app/admin/group-notification/group-notification-routing.module';
import { NotificationSetupModule } from 'app/admin/notification-setup/notification-setup.module';
import { McTableModule } from 'app/common/directives/table/mc-table.module';
import { SharedModule } from 'app/common/shared.module';
import { GroupNotificationComponent } from './group-notification.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule, ReactiveFormsModule,
    McTableModule,
    GroupNotificationRoutingModule,
    NotificationSetupModule
  ],
  declarations: [GroupNotificationComponent,
   // NotificationComponent
  ]
})
export class GroupNotificationModule { }
