import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { InfoModalComponent } from 'app/common/modal/info-modal/info-modal.component';
import * as globalConst from 'app/common/model/app-constants';
import { ApplicationHistory } from 'app/common/model/application-history';
import { HomePageDisplay } from 'app/common/model/home-page-display';
import { ReportsService } from 'app/common/services/reports.service';
import { UserService } from 'app/common/services/user.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ReportModalComponent } from '../report-modal/report-modal.component';


type Report = {
  title: string;
  name: string;
  type: string;
};

@Component({
  selector: 'mc-report-tiles',
  templateUrl: './report-tiles.component.html',
  styleUrls: ['./report-tiles.component.scss'],
})
export class ReportTilesComponent implements OnInit, OnDestroy {
  @Output() displayTypeSelectedEvent = new EventEmitter();

  /**
   * Reference to the child ReportModal component
   */
  @ViewChild(ReportModalComponent)
  reportModalComponent: ReportModalComponent;

  /**
   * Reference to the child modal component
   */
  @ViewChild(InfoModalComponent)
  infoModalComponent: InfoModalComponent;

  reportTitle: string = 'Enter Study';
  isAttributeTile = false;
  modalTitle: string;
  modalBody: string;

  public studyAccrualTiles: boolean;
  public isRandomizationTileAuthorized: boolean;
  public isDataDictionaryTileAuthorized: boolean;
  public isSubjectRegistrationDataExtractTileAuthorized: boolean;
  public isDifferenceReportTileAuthorized: boolean;
  public isFullStudyUnblindTileAuthorized: boolean;
  public isAccrualBySiteTileAuthorized: boolean;
  public isSchemaReportAuthorized: boolean;

  reportLandingPageEnum: number = globalConst.LANDING_PAGE_ENUM_REPORT;

  public applicationHistoryList: ApplicationHistory[];
  public noInfoMsg: ApplicationHistory = {
    contentText: 'No information found.',
    applicationHistoryId: 0,
    source: 'N/A',
    contentType: 'OPENING',
    startDate: new Date(),
    modifiedById: 'N/A',
    modifiedTs: new Date(),
  };
  public errorMsg: ApplicationHistory = {
    contentText: 'Unable to retrieve information.',
    applicationHistoryId: 0,
    source: 'N/A',
    contentType: 'OPENING',
    startDate: new Date(),
    modifiedById: 'N/A',
    modifiedTs: new Date(),
  };

  public subscriptions: Subscription[] = [];

  reportList: Report[] = [
    {
      'title': 'Attribute Report',
      'name': 'Generate Report',
      'type': 'attributeReport'
    },
    {
      'title': 'Study Network Report',
      'name': 'Generate Report',
      'type': 'studyNetworkReport'
    }
  ];

  constructor(
    // private router: Router,
    private userService: UserService,
    private reportService: ReportsService,
  ) { }

  /**
   * determines if the supplied activity is allowed
   * @param activityName
   */
  isActivityAllowed(activityName: string): boolean {
    let allow = true;
    if (activityName) {
      allow = this.userService.hasMinimumAccessByStudy(activityName, null);
    }

    return allow;
  }

  ngOnInit() {
    // Check tile is displayed authorization, decided if you are authorized to reports page you can see all the tiles
    //authorization will be checked after you enter the study and press submit/download

    const isReportAllowed = this.isActivityAllowed('report');

    this.studyAccrualTiles = isReportAllowed;
    this.isRandomizationTileAuthorized = isReportAllowed;
    this.isDataDictionaryTileAuthorized = isReportAllowed;
    this.isSubjectRegistrationDataExtractTileAuthorized = isReportAllowed;
    this.isDifferenceReportTileAuthorized = isReportAllowed;
    this.isFullStudyUnblindTileAuthorized = this.isActivityAllowed('full-study-unblind-report');
    this.isAccrualBySiteTileAuthorized = isReportAllowed;
    this.isSchemaReportAuthorized = isReportAllowed;

  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    });
  }

  openModalForm(): void {
    this.reportTitle = 'Enter Study';
    if (this.reportModalComponent) {
      this.reportModalComponent.showModal = true;
      this.reportModalComponent.showDownloadButton = false;
      this.reportModalComponent.showGenerateButton = true;
      this.reportModalComponent.showGenerateButton2 = false;
    }
  }

  /**
   * This will fire event based on title selected.
   *
   * @param   tileType - title selected
   */
  tileClicked(tileType: string) {
    this.fireEvent(tileType);
  }
  /**
   * This will capture Report type and emit it to the Report component
   * @param  selectedItem - tile selected
   */
  fireEvent(selectedItem: string) {
    const pageDisplay: HomePageDisplay = new HomePageDisplay(
      globalConst.DISPLAY_STUDY_ACCRUAL_RPT,
      selectedItem,
    );
    this.displayTypeSelectedEvent.emit(pageDisplay);
  }
  /**
   * This will update the modal to show with the download button.
   *
   * @param  selectedTile - pass the selected tile name
   */
  openDa(selectedTile: string) {
    this.reportTitle = 'Enter Study';
    if (this.reportModalComponent) {
      this.reportModalComponent.showModal = true;
      this.reportModalComponent.showDownloadButton = true;
      this.reportModalComponent.showGenerateButton = false;
      this.reportModalComponent.showGenerateButton2 = false;
      this.reportModalComponent.reportName = selectedTile;
      // if (selectedTile == 'summary') {
      //   this.exceptionsModalComponent.isLogSummarySelected = true;
      // } else {
      //   this.exceptionsModalComponent.isLogSummarySelected = false;
      // }
    }
  }

  /**
   * This will update the modal to show generate button and display error on modal (new)
   *
   * @param  selectedTile - pass the selected tile name
   */
  openModal(selectedTile: string) {
    if (!this.reportModalComponent) return;

    if (selectedTile === 'attributeReport') {
      this.reportTitle = 'Enter Attribute Parameters';
      this.reportModalComponent.isAttributeTile = !this.isAttributeTile;
    }
    else if (selectedTile === "schemaReport") {
      this.reportTitle = 'Enter Schema Parameters';
      this.subscriptions.push(
        this.reportService.getSchemaEvents().subscribe(result => {
          this.reportModalComponent.schemaEvents = result;
        })
      );
    }
    else if (selectedTile === 'studyNetworkReport') {
      this.reportTitle = 'Enter Study Network Parameters';

      if (!this.reportModalComponent.participatingOrgs || this.reportModalComponent.participatingOrgs.length === 0) {   // Prevents backend call every time modal is opened after first time
        this.subscriptions.push(
          this.reportService.getParticipatingOrgs().subscribe(result => {
            this.reportModalComponent.participatingOrgs = result;
          })
        );
      }

      if (!this.reportModalComponent.participatingOrgStatuses || this.reportModalComponent.participatingOrgStatuses.length === 0) {   // Prevents backend call every time modal is opened after first time
        this.subscriptions.push(
          this.reportService.getParticipatingOrgStatuses().subscribe(result => {
            this.reportModalComponent.participatingOrgStatuses = result;
          })
        );
      }
    }
    else {
      this.reportTitle = 'Enter Study';
      this.reportModalComponent.isAttributeTile = false;
    }

    // Open the report modal
    this.reportModalComponent.showModal = true;
    this.reportModalComponent.showDownloadButton = false;
    this.reportModalComponent.showGenerateButton = false;
    this.reportModalComponent.showGenerateButton2 = true;
    this.reportModalComponent.reportName = selectedTile;

  }

  getHelp(source: string) {

    // get the info to be displayed for this source
    this.applicationHistoryList = [];
    this.subscriptions.push(
      this.reportService.getApplicationHistory(source).subscribe(
        response => {
          this.applicationHistoryList = response as ApplicationHistory[];
          this.showHelp(source);
        },
        error => {
          // error in retrieval
          this.applicationHistoryList.push(this.errorMsg);
          this.showHelp(source);
        },
      ),
    );
  }

  showHelp(source: string) {
    if (this.applicationHistoryList.length === 0) {
      this.applicationHistoryList.push(this.noInfoMsg);
    }

    //Set modal title
    if (source === 'RandomizationLog') {
      this.modalTitle = 'Randomization Logs';
    } else if (source === 'RandomizationSummary') {
      this.modalTitle = 'Randomization Summary';
    }

    //Set  modal body
    this.modalBody = '';
    this.applicationHistoryList.forEach(x => {
      if (x.contentType === 'OPENING') {
        this.modalBody =
          this.modalBody + '<div><p>' + x.contentText + '</p></div><br>';
      }
    });

    const historyList = this.applicationHistoryList.filter(
      h => h.contentType && h.contentType === 'HISTORY',
    );
    historyList.sort((a, b) =>
      a.startDate < b.startDate ? 1 : b.startDate < a.startDate ? -1 : 0,
    );

    if (historyList.length > 0) {
      this.modalBody =
        this.modalBody +
        "<div class='contains-label'>" +
        "<label class='label'>History: </label>" +
        '</div>';

      historyList.forEach(x => {
        let sDate = '02/20/2020'; // default to application go-live date

        if (x.startDate != null) {
          sDate = moment(x.startDate).format('MM/DD/YYYY');
        }
        this.modalBody =
          this.modalBody +
          "<div class='columns'>" +
          "<div class='column is-one-fifth'>" +
          sDate +
          '</div>' +
          "<div class='column'>" +
          x.contentText +
          '</div>' +
          '</div>';
      });
    }

    this.applicationHistoryList.forEach(x => {
      if (x.contentType === 'CLOSING') {
        this.modalBody =
          this.modalBody + '<div><p>' + x.contentText + '</p></div><br>';
      }
    });

    this.infoModalComponent.showModal = true;
  }
}
