import { Directive, EventEmitter, Input, Output, HostListener } from '@angular/core';

@Directive({  selector: '[mcTableSorting]'})
export class McTableSortingDirective {

  @Input() public mcTableSorting: any;
  @Input() public column: any;

  @Output() public sortChanged: EventEmitter<any> = new EventEmitter();
  @Input()
  public get config(): any {
    return this.mcTableSorting;
  }


  public set config(value: any) {
    this.mcTableSorting = value;
  }

  @HostListener('click', ['$event'])
  public onToggleSort(event: any): void {
    if (event) {
      event.preventDefault();
    }
    if (this.mcTableSorting && this.column && this.column.sort !== false) {
      switch (this.column.sort) {

        case 'asc':
          this.column.sort = 'desc';
          break;
        case 'desc':
          this.column.sort = 'asc';
          break;
        default:
          this.column.sort = 'asc';
          break;
      }

      this.sortChanged.emit(this.column);
    }
  }

}
