import { Component, Input, Output, EventEmitter, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';


import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'mc-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss']
})
export class SideBarNav implements OnInit {

  @Input() sectionLinkItems;
  @Output() ScrollSectionEvent = new EventEmitter();

  constructor( @Inject(DOCUMENT) private document: any, ) { }

  ngOnInit() {
  }

  sideBarNavScrollSection(linkID) {
    this.ScrollSectionEvent.emit(linkID);
  }
}
