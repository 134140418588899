import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { UserService } from './user.service';


@Injectable()
export class ServiceHttpInterceptor implements HttpInterceptor {
    pendingRequest = 0;
    /**
     * Variable to define which codes will display the global error
     * Some non-200 codes are used by the backend to return status
     */
    handledErrorCodes: number[] = [0, 404, 401, 500];

    constructor(private toast: ToastService,
        private userService: UserService,
        private router: Router
    ) {
    }

    /**
     * The implementation of the intercept interface. In normal situations it passes the response right through
     * In the event of an error, catches the exception and raises a message
     *
     * @param req :The request object
     * @param next : The httpHandler that should then handle this response
     * @returns: An Observable Event for the handler to process
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('keepalive.html') !== -1) {
            // Don't block on keepalive URL call
            return next.handle(req);
        }

        // URL Validation to prevent untrusted calls
        if (!this.isURLValid(req.urlWithParams)) {
            return throwError(`Call failed to invalid URL: ${req.urlWithParams}`);
        }

        this.pendingRequest++;
        this.toast.setPendingHttpCount(this.pendingRequest);

        let reqHeaders = null;
        if (this.userService.authenticatedUserEntity && (this.userService.authenticatedUserEntity.userPk != this.userService.appUserEntity.userPk)) {
            let header = new HttpHeaders().set('emulated-userid', this.userService.appUserEntity.toString());
            const existingkeys = req.headers.keys();
            if (existingkeys && existingkeys.length > 0) {
                existingkeys.forEach(eachKey => {
                    const val = req.headers.get(eachKey);
                    header = header.set(eachKey, val);
                });
            }
            reqHeaders = header;
            return this.nextHandle(req, reqHeaders, next);
        }

        reqHeaders = req.headers;
        return this.nextHandle(req, reqHeaders, next);
    }

    isURLValid(url: string): boolean {
        // https://stackoverflow.com/a/1547940 - with some modifications
        const VALID_CHARACTERS_REGEX = /[a-zA-Z0-9\s\.\/\?\~:\-\_\;\\#\[\]\@\!\$&\'\|\(\)\*\+\,\;\=]+$/;
        // https://stackoverflow.com/a/42619368 - with some modifications
        const URL_REGEX: RegExp = /^(https?:\/\/)?(www.)?((localhost:\d+\/)|(\/?\.?[a-zA-Z0-9\s\~:\-\_\;\\#\[\]\@\!\$&\'\|\(\)\*\+\,\;\=]+\/?\??))*$/;
        const EXCEPTIONS: string[] = ['conf.json'];
        const ALLOWED_URLS: string[] = ['mayo.edu', 'localhost:'];

        if (
            (!VALID_CHARACTERS_REGEX.test(url) // Make sure characters are valid, otherwise we could get catastrophic backtracking 
                || !URL_REGEX.test(url)
                || (!ALLOWED_URLS.find(au => url.includes(au))
                    && !url.startsWith('/') // indicates it's a relative path
                )
            )
            && !EXCEPTIONS.includes(url)) {
            return false;
        }
        return true;
    }


    nextHandle(req: HttpRequest<any>, reqHeaders: HttpHeaders, next: HttpHandler): Observable<HttpEvent<any>> {
        const reqClone = req.clone({ headers: reqHeaders });
        // TODO: remove this block after emulatedUserId request header can be
        // received at the auth server
        //  if(req.url.indexOf('auth') > -1){
        //      reqClone = req.clone();
        //  }
        return next.handle(reqClone)
            .pipe(tap(event => {
                if (event instanceof HttpResponse) {
                    // do nothing for now
                    if (this.pendingRequest > 0) {
                        this.pendingRequest--;
                        this.toast.setPendingHttpCount(this.pendingRequest);
                    }
                }
            }))
            .pipe(catchError(err => {
                // if (this.pendingRequest > 0) {
                //     this.pendingRequest--;
                //     this.toast.setPendingHttpCount(this.pendingRequest);
                // }
                let countReduced = false;
                //    console.log(">> ===========================> " + (err instanceof HttpErrorResponse ));
                if (err instanceof HttpErrorResponse) {
                    let msg = '';

                    if (err.status == 0 || err.status == 404) {
                        msg += 'Oops! Something went wrong.';
                    } else if (err.status == 500) {
                        if (err.error && err.error.message) {
                            const realMsg: string = err.error.message;
                            msg += realMsg.substring(realMsg.indexOf(':') + 1, realMsg.length);
                        } else {
                            msg += err.statusText;
                        }
                    } else if (err.status === 401) {
                        msg += 'User is not authorized to some function on this page';
                    } else if (err.status === 403) {
                        // Route to authentication error page with message
                        const reason = { id: '1', reason: err.error };
                        this.router.navigateByUrl('/not-authorized', { state: reason });
                    }

                    // We want to display errors only if certain error statuses are
                    // returned by the server. Other cases, we ignore the error
                    if (this.handledErrorCodes.includes(err.status)) {
                        //   msg +=  err.status + ': ' + err.statusText;
                        // This variable allows the message to be displayed on the modal
                        const variableData: string = 'error:' + msg;
                        const modalArg = new ModalDialogArg('modal-warn', 'GeneralException', variableData);
                        // By passing the modalArg, we ensure that the message is displayed
                        // in a modal popup instead of a toast
                        this.toast.sendMessage(msg, 'danger', null, true, modalArg);
                        // set the pending Count to 0 so the ui is not blocked
                        this.pendingRequest = 0;
                        this.toast.setPendingHttpCount(this.pendingRequest);
                        countReduced = true;
                    }
                }
                if (!countReduced) {
                    if (this.pendingRequest > 0) {
                        this.pendingRequest--;
                        this.toast.setPendingHttpCount(this.pendingRequest);
                    }
                }
                //  console.log("About to Throw Observable Exception ?")
                return throwError(err);
            }));
    }
}

