import { PossibleValuesHierarchy } from "./possible-values-hierarchy";

export class Race {
  constructor(
    public studyId: number | null,
    public studyTemplateId: number | null,
    public studyRaceClassificationId: number | null,
    public raceCodeClassificationId: number | null,
    public required: boolean | null,
    public tupleVersion: number | null,
    public possibleValues?: PossibleValuesHierarchy[] | null
  ) { }
}
