import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { EditBarComponent } from 'app/admin/edit-bar/edit-bar.component';
import { NotificationComponent } from 'app/admin/study-setup/notification/notification.component';
import { AccruedPatientSearchResultsComponent } from 'app/common/accrued-patient-search-results/accrued-patient-search-results.component';
import { CanNavigateDirective } from 'app/common/directives/can-navigate.directive';
import { QuillModule } from 'ngx-quill';
import { StudyHorizontalNavComponent } from '../admin/study-horizontal-nav/study-horizontal-nav.component';
import { ActivePatientSearchResultsComponent } from './active-patient-search-results/active-patient-search-results.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';
import { DatePickerWrapperComponent } from './date-picker-wrapper/date-picker-wrapper.component';
// import { ClearInputDirective } from './directives/clear-input.directive';
import { ClearInputDirective } from './directives/clear-input.directive';
import { DatePickerComponent } from './directives/date-picker/date-picker.component';
import { DatePickerDirective } from './directives/date-picker/date-picker.directive';
import { DraggableDirective } from './directives/draggable.directive';
// Common Components
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxAngularQueryBuilderModule } from 'ngx-angular-query-builder';
import { SearchInputComponent } from './controls/search-input/search-input.component';
import { DaterangePickerWrapperComponent } from './daterange-picker-wrapper/daterange-picker-wrapper.component';
import { DatetimePickerWrapperComponent } from './datetime-picker-wrapper/datetime-picker-wrapper.component';
import { ScrollerDirective } from './directives/scroller/scroller.directive';
import { McTableModule } from './directives/table/mc-table.module';
import { ErrorDisplayComponent } from './error-display/error-display.component';
import { ErrorValidationDisplayRowComponent } from './error-validation-display-row/error-validation-display-row.component';
import { ErrorValidationDisplayComponent } from './error-validation-display/error-validation-display.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { ConfirmationModalComponent } from './modal/confirmation-modal/confirmation-modal.component';
import { EmailModalComponent } from './modal/email-modal/email-modal.component';
import { InfoModalComponent } from './modal/info-modal/info-modal.component';
import { PersonSelectionModalComponent } from './modal/person-selection-modal/person-selection-modal.component';
import { UnblindedModalComponent } from './modal/unblinded-modal/unblinded-modal.component';
import { VariableModalComponent } from './modal/variable-modal/variable-modal.component';
import { NetworkOrganizationSearchComponent } from './network-organization-search/network-organization-search.component';
import { OrganizationAutoCompleteComponent } from './org-auto-complete/org-auto-complete.component';
import { OrganizationSearchComponent } from './organization-search/organization-search.component';
import { PatientParticipationInformationComponent } from './patient-participation-information/patient-participation-information.component';
import { PatientSearchByStudyComponent } from './patient-search-by-study/patient-search-by-study.component';
import { PatientSearchResultsComponent } from './patient-search-results/patient-search-results.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeHtmlModule } from './pipes/safe-html.pipe.module';
import { PreviewComponent } from './preview/preview.component';
import { PullOutComponent } from './pull-out/pull-out.component';
import { QueryBuilderWrapperComponent } from './query-builder-wrapper/query-builder-wrapper.component';
import { SideBarNav } from './sidebar-nav/sidebar-nav.component';
import { CommonDemographicSettingsComponent } from './study-configuration/common-demographic-settings/common-demographic-settings.component';
import { SiteAndPersonComponent } from './study-configuration/site-and-person/site-and-person.component';
import { StudyAttributesComponent } from './study-configuration/study-attributes/study-attributes.component';
import { TablePagingComponent } from './table-paging/table-paging.component';
import { VariableLookupComponent } from './variable-lookup/variable-lookup.component';







@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    McTableModule,
    RouterModule,
    QuillModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatInputModule,
    // BrowserAnimationsModule
    ClipboardModule,
    NgxAngularQueryBuilderModule,
    NgMultiSelectDropDownModule,
    SafeHtmlModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule
  ],
  declarations: [
    ClearInputDirective,
    ScrollerDirective,
    SideBarNav,
    PreviewComponent,
    OrganizationSearchComponent,
    OrganizationAutoCompleteComponent,
    DatePickerDirective,
    ConfirmActionComponent,
    DraggableDirective,
    DatePickerComponent,
    EditBarComponent,
    VariableModalComponent,
    VariableLookupComponent,
    NotificationComponent,
    HeaderBarComponent,
    BreadcrumbComponent,
    CanNavigateDirective,
    ErrorDisplayComponent,
    StudyHorizontalNavComponent,
    NetworkOrganizationSearchComponent,
    PatientSearchResultsComponent,
    AccruedPatientSearchResultsComponent,
    PullOutComponent,
    PatientParticipationInformationComponent,
    ErrorValidationDisplayComponent,
    ErrorValidationDisplayRowComponent,
    ActivePatientSearchResultsComponent,
    DatePickerWrapperComponent,
    PatientSearchByStudyComponent,
    TablePagingComponent,
    EmailModalComponent,
    PersonSelectionModalComponent,
    DatetimePickerWrapperComponent,
    UnblindedModalComponent,
    QueryBuilderWrapperComponent,
    DaterangePickerWrapperComponent,
    InfoModalComponent,
    SiteAndPersonComponent,
    StudyAttributesComponent,
    CommonDemographicSettingsComponent,
    SearchInputComponent,
    ConfirmationModalComponent,
  ],
  exports: [
    SideBarNav,
    ClearInputDirective,
    ScrollerDirective,
    PreviewComponent,
    DatePickerDirective,
    CanNavigateDirective,
    OrganizationAutoCompleteComponent,
    OrganizationSearchComponent,
    EditBarComponent,
    ConfirmActionComponent,
    VariableLookupComponent,
    VariableModalComponent,
    NotificationComponent,
    HeaderBarComponent, BreadcrumbComponent,
    ErrorDisplayComponent,
    SafeHtmlPipe,
    StudyHorizontalNavComponent,
    NetworkOrganizationSearchComponent,
    PatientSearchResultsComponent,
    AccruedPatientSearchResultsComponent,
    ActivePatientSearchResultsComponent,
    PatientSearchByStudyComponent,
    PullOutComponent,
    PatientParticipationInformationComponent,
    ErrorValidationDisplayComponent, ErrorValidationDisplayRowComponent,
    MatDatepickerModule, MatNativeDateModule,
    MatPaginatorModule,
    DatePickerWrapperComponent,
    TablePagingComponent,
    EmailModalComponent,
    PersonSelectionModalComponent,
    DatetimePickerWrapperComponent,
    UnblindedModalComponent,
    DaterangePickerWrapperComponent,
    QueryBuilderWrapperComponent,
    InfoModalComponent,
    ConfirmationModalComponent,
    SiteAndPersonComponent,
    StudyAttributesComponent,
    CommonDemographicSettingsComponent,
    SearchInputComponent,
    MatCheckboxModule,
    MatIconModule
  ],
  providers: [],
  // entryComponents: [ConfirmActionComponent, DatePickerComponent, ErrorDisplayComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
