import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { Breadcrumb } from 'app/common/model/breadcrumb';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'mc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  MAX_CRUMBS = 10 ;
  MAX_CRUMBS_TO_DISPLAY = 2;

  breadcrumbs: Breadcrumb[] = [];
  currentCrumbs: Breadcrumb[] = [];
  displayBreadCrumbs: Breadcrumb[] = [];


  constructor(private route: ActivatedRoute,
    private router: Router,
    private titleService: Title) {
    this.subscriptions.push(router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((evt: any) => {
        const root: ActivatedRoute = this.route.root;
        this.currentCrumbs = this.getBreadcrumbs(root);
        if (this.isHomeBreadcrumb(this.currentCrumbs[0])) {
          this.breadcrumbs = [];
          this.displayBreadCrumbs = [];
          this.setTitle('Registration - Home' );
        } else {
          //  let lastCrumb: Breadcrumb = this.currentCrumbs[0];
          const addCrumb: Breadcrumb = this.getCrumbToAdd();
          if (addCrumb) {
            addCrumb.id = evt.id;
            addCrumb.url = evt.url;
            this.breadcrumbs.push(addCrumb);
            // don't keep more than the max specified
            if (this.breadcrumbs.length > this.MAX_CRUMBS) {
              this.breadcrumbs.splice(0, 1);
            }
          }
          this.setHomeBreadcrumb();
          this.displayBreadCrumbs = _.clone(this.breadcrumbs);
          if (this.breadcrumbs.length > this.MAX_CRUMBS_TO_DISPLAY) {
            const removeCount: number = ((this.breadcrumbs.length - this.MAX_CRUMBS_TO_DISPLAY) - 1);
            this.displayBreadCrumbs.splice(0, removeCount);
          }
          const totalItems: number = this.displayBreadCrumbs.length;
          this.displayBreadCrumbs.splice(totalItems - 1, 1);

        }

      }));
  }

  /**
   * Determines if a breadcrumb from the currentCrumb list can be added to the list
   */
  getCrumbToAdd(): Breadcrumb {
    let firstCurrent = this.currentCrumbs[0];
    // if this is not the home crumb (we don't want to add the home crumb at the end)
    if (this.isHomeBreadcrumb(firstCurrent)) {
      firstCurrent = null;
    } else {
      if (this.breadcrumbs.length > 0) {
        // get the first crumb
        const lastCrumb = this.breadcrumbs[this.breadcrumbs.length - 1];

        // and it's not the same as the last crumb - we can add it to the list

        if (!(firstCurrent.label && lastCrumb &&
          firstCurrent.label.toLowerCase() != lastCrumb.label.toLowerCase())) {
          firstCurrent = null;
        }
      }
    }
    return firstCurrent;
  }

  /**
   * Sets the home breadcrumb at the start of the breadcrumbs
   */
  setHomeBreadcrumb() {
    if (this.breadcrumbs.length > 0) {
      // check if there's a home crumb in the mix
      const ixArray: Array<number> = [];
      this.breadcrumbs.forEach((crumb, ix) => {
        if (this.isHomeBreadcrumb(crumb)) {
          ixArray.push(ix);
        }
      })
      if (ixArray.length > 0) {
        ixArray.forEach(idx => {
          this.breadcrumbs.splice(idx, 1);
        })
      }
    }
    this.breadcrumbs.unshift(this.getHomeCrumb());
  }

  /**
   * True if the crumb is home crumb
   * @param crumbToCheck the crumb to evaluate
   */
  isHomeBreadcrumb(crumbToCheck: Breadcrumb): boolean {
    return (crumbToCheck && crumbToCheck.label && crumbToCheck.label.toLowerCase() == 'home');
  }

  /**
   * Builds the home breadcrumb
   */
  getHomeCrumb(): Breadcrumb {
    const homeCrumb: Breadcrumb = {
      id: 0,
      url: '/home',
      params: {},
      label: 'Home'
    };
    return homeCrumb;
  }

  ngOnInit() {
  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }

  /**
   * Sets the title of the page
   * @param newTitle The new Title to set
   */
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }


  /**
   *
   * @param  $event The event raised by the ui
   */
  nonNavLink($event: Event) {
    if ($event) {
      $event.stopPropagation();
    }
  }


  /**
   *
   * @param  $event The event raised by the ui
   * @param breadcrumb The breadcrumb to process
   * @param rowIdx The rowIndex of the crumb
   */
  navigateTo($event: Event, breadcrumb: Breadcrumb, rowIdx: number) {
    if ($event) {
      $event.stopPropagation();
    }
    const startIndex: number = this.breadcrumbs.findIndex(eachCrumb => eachCrumb.id == breadcrumb.id);
    const idxToRemove: number[] = [];
    for (let n = startIndex; n < this.breadcrumbs.length; n++) {
      idxToRemove.push(n);
    }

    idxToRemove.reverse().forEach(each => {
      this.breadcrumbs.splice(each, 1);
    })

    this.router.navigate([breadcrumb.url]);

  }

  /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param {ActivateRoute} route
   * @param {string} url
   * @param {IBreadcrumb[]} breadcrumbs
   */
  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {

      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // add breadcrumb
      const breadcrumb: Breadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url
      };
      breadcrumbs.push(breadcrumb);

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
