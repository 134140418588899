import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../../app-config/app-config.module';

@Injectable()
export class AccrualSettingsService {

    /**
     *  Gets url-pattern from config file.
     */
    serviceUrlPrefix = this.config.apiEndpoint;

    constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {}

}
