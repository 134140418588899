<div class="control-wrapper">
    <div class="input-wrapper">
        <input #input type="text" class="form-control search-input search-control" [placeholder]="placeholder"
            [formControl]="inputControl" [matAutocomplete]="auto" (input)="handleInputChanged()"
            (focus)="handleOnFocus()">
            <mat-icon class="input-icon" >search</mat-icon>
            
    </div>
    <mat-autocomplete #auto="matAutocomplete" class="search-input" aria-label="autocomplete options"
        (optionSelected)="handleSelectionChanged($event.option.value)" [displayWith]="displayOptionSelection">
        <mat-option *ngFor="let opt of filteredOptions" [value]="opt">
            {{opt.name}}
        </mat-option>
    </mat-autocomplete>
    <mat-chip-list #chipList aria-label="chip selection" [tabIndex]="-1">
        <mat-chip *ngFor="let chip of selectedOptions" (removed)="removeChip(chip)" class="chip">
            {{chip.name}}
            <button matChipRemove tabindex="-1">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
    </mat-chip-list>
</div>
