import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ACTIVITY_NOTIFICATION, ACTIVITY_NOTIFICATION_SETUP } from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import { EventArg } from 'app/common/model/event-arg';
import { NotificationCode } from 'app/common/model/notification-code';
import { ResearchEntity } from 'app/common/model/research-entity';
import { StudyNotification } from 'app/common/model/study-notification';
import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { StudyCreateService } from 'app/common/services/study-create.service';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { UserService } from 'app/common/services/user.service';
import { Observable, Subscription } from 'rxjs';



@Component({
  selector: 'mc-notification-info',
  templateUrl: './notification-info.component.html',
  styleUrls: ['./notification-info.component.scss']
})
export class NotificationInfoComponent implements OnInit, OnDestroy {

  // The list of notification statuses for the dropdown
  public statusTypesList: NotificationCode[] = [];

  // list of research entities
  public groupsList: ResearchEntity[] = [];

  private subscriptions: Subscription[] = [];

  private studyId = 0;

  /**
 * Determines if the user selected a group notification from the study page
 */
  // isGroupNav: boolean = this.notificationService.isNavigatedGroupNotification ;
  // Need to get the value dynamically
  isGroupNav = false;

  //Determines  whether user can do editable or not
  isEditable: boolean = false;


  constructor(
    private viewContainer: ViewContainerRef,
    private notificationService: NotificationDashboardService,
    private studySetupService: StudySetupService,
    private studyCreateService: StudyCreateService,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.subscriptions.push(studySetupService.changeEmitted$.subscribe(
      eventInfo => {
        this.parseEventInfo(eventInfo);
      }));
  }

  // The notification model being created or edited
  public currentNotification(): StudyNotification {
    return this.notificationService.currentNotification;
  }

  /**
   * Variable to determine if the form we're working on is new
   */
  isExistingForm(): boolean {
    let isExist = false;
    if (this.currentNotification()) {
      isExist = this.currentNotification().notificationId > 0;
    }
    return isExist;
  }

  /**
   * Variable to determine if study or group notification
   */
  isStudyNotification(): boolean {
    return this.studyId != 0;
  }

  ngOnInit() {
    this.isGroupNav = this.notificationService.isNavigatedGroupNotification;
    this.subscriptions.push(this.notificationService.getStatusTypes()
      .subscribe(results => {
        this.statusTypesList = results as NotificationCode[];
        this.statusTypesRetrieved();
      })
    );

    // Get the studyId
    this.route.parent.parent.parent.params.subscribe(params => {
      if (params['studyId']) {
        this.studyId = params['studyId'];
      }
    });

    if (!this.isGroupNav) {
      if (this.studyId) { //Study notification
        if (this.userService.hasModifyAccessByStudy(ACTIVITY_NOTIFICATION, this.studyId)) {
          this.isEditable = true;
        } else {
          this.isEditable = false;
        }
      } else {// Group notification
        if (this.userService.hasModifyAccess(ACTIVITY_NOTIFICATION_SETUP)) {
          this.isEditable = true;
        } else {
          this.isEditable = false;
        }
      }
    }

    //get list of research entities
    //TODO: for now use our list of organizations for research entity (so orgid's match)... this will change when research entity is better defined
    // this.subscriptions.push(this.studySetupService.getOrganizations().subscribe(results => {
    //   for (let result of results) {
    //     if (["ACCRU", "Alliance", "Spore", "CPN", "Mayo Clinic"].includes(result.abrev)) {
    //       let entity = {} as ResearchEntity;
    //       entity.id = +result.orgNumber; //string to integer
    //       entity.name = result.abrev;
    //       this.groupsList.push(entity);
    //     }
    //   }

    // })

    // );

    this.subscriptions.push(this.studyCreateService.getResearchEntityTypes()
      .subscribe(results => {
        for (const result of results) {
          const entity = {} as ResearchEntity;
          entity.id = result.id;
          entity.name = result.name;
          this.groupsList.push(entity);
        }
      }));

  }

  /**
  * Destroy implementation - closes all the subscriptions
  */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    );
  }

  /**
   * This will be called when the notification has been reset.
   * @param  {EventArg} eventArg
   */
  parseEventInfo(eventArg: EventArg) {
    if (eventArg.eventName == globalConst.EVENT_DATA_RESET) {
      this.statusTypesRetrieved();
    }
    if (eventArg.eventName == globalConst.EVENT_DATA_UPDATED) {
      this.isGroupNav = this.notificationService.isNavigatedGroupNotification;
    }
  }

  /**
   * Returns True if the current notification is of type "notify"
  */
  isNotificationTypeNotify(): boolean {
    let isNotify = false;
    if (this.currentNotification()) {
      isNotify = this.notificationService.isNotificationTypeNotify(this.currentNotification());
    }
    return isNotify;
  }

  /**
   * Compare objects, used in html for matching coded lists
   */
  compareFn(a, b) {
    return a && b && a.id == b.id;
  }

  /**
  * When status type service is completed, let a new notification default to status of Inactive
  */
  statusTypesRetrieved() {
    if (this.currentNotification() && this.currentNotification().status == null) {
      this.currentNotification().status = this.statusTypesList.find(s => s.value == 'Inactive');
    }
  }


  /********* Pending Changes warning************** */
  /**
   * Implemenation of the canDeactivate that will be called when we navigate away
   *
   */
  canDeactivate(): Observable<boolean> | boolean {
    return this.notificationService.notificationSetup.canDeactivate();
  }
  /*********  Pending Changes warning************** */




}
