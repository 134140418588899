import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mc-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  showModal: boolean;


  constructor(
  ) { }

  ngOnInit(): void {
  }

    /**
   * Closes the modal
   */
     closeModal() {
      this.showModal = false;
    }
  
}
