import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Organization } from 'app/common/model/organization';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'mc-organization-search',
  templateUrl: './organization-search.component.html',
  styleUrls: ['./organization-search.component.scss']
})
export class OrganizationSearchComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() cTepId = '';
  @Input() orgName: string ;
  @Output() selectedOrg: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * IS the component being display in the modal view or hidden
   */
  _modalVisible: boolean;

  public page = 1;
  public itemsPerPage = 5;
  public numPages = 2;
  public length = 0;
  public selectedOrgNum: number;
  public organizationList: Organization[];
  public showFilterRow: Boolean = true;

/**
   * Property name used for display.
   * Have put it here since it's likely going to change
   */
  private propertyOrgDisplay = 'abrev';



  public rows: Array<any> = [];
  public columns: Array<any> = [
    {
      title: 'CTEP ID',
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by CTEP ID' },
      name: 'ctepId', sort: '',
      className: 'ctep-width'
    },
    {
      title: 'Org#',
      display: true,
      name: 'orgNumber', sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Org Number' },
      className: 'org-width'
    },

    {
      title: 'Abrev', name: 'abrev',
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by abbreviation' },
      className: 'abbrev-width'
    },
    {
      title: 'Name', name: 'name',
      display: true,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by name' },
      className: 'name-width'
    },
    {
      title: 'Type', name: 'type',
      display: false,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by type' }
    },
    {
      title: 'Status', name: 'status',
      display: false,
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by status' }
    }
  ];

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['is-striped', 'is-bordered']
  };


  public data: Array<any> = [];

  orgSubscription: Subscription  ;



  @ViewChildren('orgRow', {read: ElementRef}) viewContentDivs: QueryList<any>;


  private selectedContentDiv: any = null;
  get modalVisible(): boolean {
    return this._modalVisible;
  }

  @Input('modalVisible')
  set modalVisible(value: boolean) {
    this._modalVisible = value;
    if (this._modalVisible) {
      this.selectNavRow();
      if (this.selectedContentDiv) {
        this.selectedContentDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // this.selectedContentDiv.scrollTop += 300;
      }
    } else {
      if (this.columns.filter) {
        this.columns.forEach((column: any) => {
          if (column.filtering) {
            column.filtering.filterString = '';
          }
        });
      }
    }
  }

  constructor(private renderer: Renderer2,
    private studySetupService: StudySetupService) {

  }

  ngOnInit() {
    this.orgSubscription = this.studySetupService.getOrganizations()
      .subscribe(results => {
        this.organizationList = results;
       // this.filterForInput() ;
      }
      );
  }

  ngAfterViewInit() {
    // this.filterForInput() ;
  // this.selectNavRow();
  }
  ngOnChanges(changes: any) {
     this.filterForInput() ;
  }



  /**
   * Initialization code that prepares the ui with the data
   */
  filterForInput() {
    const filterList = this.organizationList;
    if (filterList) {
      if (this.orgName) {
        const selection: Organization = this.organizationList.find(eachOrg => eachOrg.abrev && eachOrg[this.propertyOrgDisplay] == this.orgName.trim());
        if (selection && selection.orgNumber) {
          this.selectOrganization(selection);
        }
      }
      this.data = filterList;
      this.length = this.data.length;
    }
    this.onChangeTable(this.config);
  }

  /**
   * Helper method to locate the row if an org was previously selected
   */
  selectNavRow() {
    if (this.orgName && this.selectedOrgNum > 0) {
      if (this.viewContentDivs && this.viewContentDivs.length > 0) {
        this.viewContentDivs.forEach((eachItem, itemIdx) => {
          const elem = this.renderer.selectRootElement(eachItem).nativeElement;
          if (elem.id && elem.id == this.selectedOrgNum + '') {
            this.selectedContentDiv = elem;
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.orgSubscription.unsubscribe();
  }


  /**
   * Sorts the data based on the confi
   * @param data Data to be sorted
   * @param config configuration used to sort
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
   * Common method called when any sort / filter changes on the ui
   * @param config configuration for filter / sorting
   * @param page The page to which to navigate
   */
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    this.itemsPerPage = +page.itemsPerPage;
    let column: any = null;
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.data, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    // setTimeout(() => {
      this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
      if (sortedData) {
        this.length = sortedData.length;
      }
    // }, 100);

  }

  /**
   * Filters the data based on config and filter string
   * @param data data to filter
   * @param config config to use
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;
    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name]) {
            const itemVal: string = item[column.name].toLowerCase();
            const filterStr: string = column.filtering.filterString.toLowerCase();
            if (filterStr.length > 0) {
              return itemVal.match(filterStr);
            } else {
              return item;
            }
          } else {
              if (column.filtering.filterString.length > 0) {
                return null;
              } else {
                return item;
              }
            }
        });
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }
    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name]) {
          if (item[column.name].toString().match(this.config.filtering.filterString)) {
            flag = true;
          }
        } else {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  /**
   *
   * @param page
   * @param data
   */
  public changePage(page: any, data: Array<any> = this.data): Array<any> {
    const start = (page.page - 1) * page.itemsPerPage;
    const end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    this.page = page.page;
    return data.slice(start, end);
  }

  /**
   * True if paging is configured and if there are adequate
   * items for paging
   */
  shouldShowPagination(): boolean {
    return (this.config.paging && this.data && this.data.length > 0 && this.numPages > 1);
  }

  /**
   * CAlled when a organization is selected
   * @param row The selected organization
   */
  public selectOrganization(row: Organization) {
    this.itemSelected.emit(true);
    this.selectedOrg.emit(row);
    this.selectedOrgNum = +row.orgNumber;
  }


  /**
   * True if the organization is selected on the ui
   * @param org The organization evaluate
   */
  isOrganizationSelected(org: Organization): boolean {
    return org && org.orgNumber && this.selectedOrgNum == +org.orgNumber;
  }

}
