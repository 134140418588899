import { Component  } from '@angular/core';
import { NotificationCode } from 'app/common/model/notification-code';
import { StudyNotification } from 'app/common/model/study-notification';
import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { UserService } from 'app/common/services/user.service';
import { SchemaDashboardService } from '../../../common/services/schema/schema-dashboard.service';
import { StudySetupService } from '../../../common/services/study-setup.service';
import { NotificationApprovalType } from 'app/common/model/notification-approval-type';

@Component({
  selector: 'mc-configuration-base',
  template: `
  <p>
    Notificaiton configuration-base works!
  </p>
`,
styles: [
]
})
export class ConfigurationBaseComponent  {

  constructor(
    protected notificationService: NotificationDashboardService,
    protected schemaService: SchemaDashboardService,
    protected studySetupService: StudySetupService,
    protected userService: UserService) {
  }


  /**
 * The notification model being created or edited
 */
  public currentNotification(): StudyNotification {
    return this.notificationService.currentNotification;
  }


  isSelected(event: NotificationCode): boolean {
    return this.currentNotification().notificationApprovalTypeList.some(
      at => at.approvalType.id === event.id,
    );
  }


  toggleSelection(checked: boolean, event: NotificationCode): void {
    const isSelected = this.isSelected(event);

    if (checked && !isSelected) {
      this.currentNotification().notificationApprovalTypeList.push(
        new NotificationApprovalType(-1, event),
      );
    } else if (!checked && isSelected) {
      const approvalIndex =
        this.currentNotification().notificationApprovalTypeList.findIndex(
          at => at.approvalType.id === event.id,
        );
      if (approvalIndex !== -1) {
        this.currentNotification().notificationApprovalTypeList.splice(
          approvalIndex,
          1,
        );
      }
    }
  }

}
