import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EmailModalComponent } from 'app/common/modal/email-modal/email-modal.component';
import * as globalConst from 'app/common/model/app-constants';
import { HeaderBar } from 'app/common/model/header-bar';
import { HomePageDisplay } from 'app/common/model/home-page-display';
import { NotificationTileDetail } from 'app/common/model/notification-tile-detail';
import { PreviewPanelModel } from 'app/common/model/preview-panel-model';
import { Subscription } from 'rxjs';
import { SearchCriteria } from '../common/model/search-criteria';
import { PreviewComponent } from '../common/preview/preview.component';
import { UserService } from '../common/services/user.service';



@Component({
  selector: 'mc-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.scss']
})

export class ReportDashboardComponent implements OnInit, OnDestroy {

  userName = '';

  previewExpanded = false;
  displayStudyAccrualReport = false;

  protocolSearchCriteria: SearchCriteria = null;
  tileType: string;

  formTitle = 'Reports';
  displayStudyAccReport = false;

  previewTitle = 'Preview Pane';
  contentTypeReport = 'report';
  contentType = 'report';
  param: string;


  /**
   * Reference to the child modal component for displaying notification email
   */
  @ViewChild(EmailModalComponent)  private modalComponent: EmailModalComponent;

  @ViewChild(PreviewComponent) private previewComponent: PreviewComponent;


  /**
   * Model to drive the orientation and horizontal/vertical states of the preview window
   */

  previewModelHome: PreviewPanelModel = new PreviewPanelModel();
  reportLandingPageEnum: number = globalConst.LANDING_PAGE_ENUM_REPORT;

  /**
   * model for the header bar
   */
  headerModel: HeaderBar = new HeaderBar('Reports', '', false, '', false);



  subscriptions: Subscription[] = [];

  isProtocolSearchQuery = true;

  constructor(
        private userService: UserService) {

      }

  ngOnInit() {
     this.subscriptions.push(this.userService.getAppUser()
      .subscribe(user => {
        this.userName = user.userId;
      }));


  }


  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }


  /**
   * This will assign the preview Title based on type of tile
   * and toggle it when clicked.
   *
   * @param  {HomePageDisplay} displayType - type of tile
   */
  tileClicked(displayType: HomePageDisplay) {
    this.tileType = displayType.displayType;


    if (this.tileType == globalConst.DISPLAY_STUDY_ACCRUAL_RPT) {
      this.previewTitle = 'Study Accrual Report' ;
      this.contentType = 'reportStudyAccrual';
      this.param = displayType.displayArg;
      this.displayStudyAccReport = true;
      this.previewModelHome.isCollapsed = false;

    }

    this.previewModelHome.isCollapsed = false;


  }

  /**
   * Returns the search to the Report page
   */
  returnToSearch() {
    this.previewModelHome.isCollapsed = true;
  }


  /**
   * event captured from the preview pane selection and calling modal component with the selected id to populate data.
   * @param  {NotificationTileDetail} selection
   */
  onEmailDetailSelected(selection: NotificationTileDetail) {
    this.modalComponent.showModal = true;
    this.modalComponent.getEmail(selection.emailLogId);
  }
/** custom handling of the preview for the home page********************* */
/**
   * True if the orientation is vertical and the state is collapsed
   */
  isCollapsedVertical(): boolean {
    return this.previewModelHome.isCollapsed && this.previewModelHome.isVertical;
  }

  /**
   * True if the orientation is horizontal and is collapsed
   */
  isCollapsedHorizontal(): boolean {
    return (this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }


  /**
   * True if the orientation is vertical and the state is Expanded
   */
  isExpandedVertical(): boolean {
    return (!this.previewModelHome.isCollapsed && this.previewModelHome.isVertical);
  }



  /**
   * True if the orientation is horizontal and is expanded
   */
  isExpandedHorizontal(): boolean {
    return (!this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }

/** custom handling of the preview for the home page********************* */

// reference to angular 9. could remove it later point
doSearch() {

}

}
