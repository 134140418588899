<div *ngIf="inputForm" class=" ">
  <h3>{{inputForm.formName}}</h3>
  <table class="table is-striped is-fullwidth form-table ">
    <tbody>
      <tr *ngFor="let row of inputForm.formVariableList ; let rowIdx = index">
        <td class="has-left-padding">
          <div class="columns">
            <div class="column is-7  ">
                <div class="question-pad ql-editor" *ngIf="row && row.questionText" [innerHTML]="row.questionText.questionText|safeHtml">
              </div>
            </div>
            <div class="column is-5 control">
              <div *ngIf="isCheckBox(row)">
                <mc-input-check-box [formVariable]="row"></mc-input-check-box>
              </div>
              <div *ngIf="isInputText(row)">
                <mc-input-text [formVariable]="row"></mc-input-text>
              </div>
              <div *ngIf="isMultiChoice(row)">
                <mc-radio-button [formVariable]="row"></mc-radio-button>
              </div>
              <div *ngIf="isDate(row)">
                <mc-date [formVariable]="row"></mc-date>
              </div>
              <div *ngIf="isTime(row)">
                <mc-time [formVariable]="row"></mc-time>
              </div>
              <div *ngIf="isDropdown(row)">
                <mc-drop-down [formVariable]="row"></mc-drop-down>
              </div>
            </div>
          </div>
        </td>
        <!--  <td>
          <div class=""> -->

        <!-- </div>
        </td> -->
      </tr>
    </tbody>
  </table>
</div>