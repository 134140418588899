import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'res-table-paging',
  templateUrl: './table-paging.component.html',
  styleUrls: ['./table-paging.component.scss']
})
export class TablePagingComponent implements OnInit, AfterViewInit {

  // @Input() currentPage: number = 1;



  _currentPage: number;

  _itemsPerPage = 0;



  @Input() totalItems = 0;
  // @Input() itemsPerPage: number = 0;
  @Input() numPages = 0;
  @Output() pageChanged: EventEmitter<any> = new EventEmitter();

  config: any = {
    paging: true
  }




  /****** Material Paginator ************* */



  @ViewChild(MatPaginator)
  private paginatorComponent: MatPaginator;


  currentPageSize: number =  this._itemsPerPage;

  pageSize = this._itemsPerPage;
  pageSizeOptions: number[] = [5, 10, 25];

  // MatPaginator Output
  pageEvent: PageEvent;


  /****** Material Paginator ************* */







  /**
   * OPtions to be displayed in the dropdown for number of rows to be displayed
   */
  itemsPerPageOptions: number[] = [5, 10, 15, 20];

  pageIndexing: number[] = [];

  pagesCountInPageset = 3;

  pageSet: number[] = [];
  get currentPage(): number {
    return this._currentPage;
  }

  /**
    * Current Page value
    */
  @Input()
  set currentPage(value: number) {
    // console.log("The current page is being set to ", value);
    setTimeout(() => {
      this._currentPage = value;
      // this.selectPage(null, value);
    })
  }
  get itemsPerPage(): number {
    return this._itemsPerPage;
  }

  /**
    * Items Per Page value
    */
  @Input()
  set itemsPerPage(value: number) {
    setTimeout(() => {
      if (this._itemsPerPage != value) {
        this.currentPageSize = value;
      }
      this._itemsPerPage = value;
    })
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  tablePageChanged($event: PageEvent): void {

    this.pageEvent = $event;
    this._itemsPerPage = this.pageEvent.pageSize;
    if (this.currentPageSize != $event.pageSize) {
      this.currentPageSize = $event.pageSize;
      this.paginatorComponent.firstPage();
      this.currentPage = 1;
      this.selectPage(null, 1);
    } else {
      this.currentPage = this.pageEvent.pageIndex + 1;
      this.selectPage(null, this.pageEvent.pageIndex + 1);
    }

  }




  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.paginatorComponent) {
      // this._itemsPerPage = this.paginatorComponent.pageSize;
      this.currentPageSize = this.paginatorComponent.pageSize;
    }
  }

  /**
   * Returns the paging count
   */
  getPagingCount(): number[] {
    return Array(this.numPages).fill(0).map((x, i) => i + 1);
  }

  /**
   * handler for the items per page counter modification
   */
  rowCountChanged(): void {
    this.goToFirst();
  }


  /**
   * HAndles the click on the paging
   * @param $event
   * @param i
   */
  selectPage($event: Event, i: number): void {
    if ($event) {
      $event.stopPropagation();
    }
    this.setPagingSets(i)
    this.pageChanged.emit({ page: i, itemsPerPage: this.itemsPerPage })
  }


  /**
   * Generates the set of page links for navigation on pages
   * @param pageNumber The current page number being navigated to
   */
  setPagingSets(pageNumber: number): void {
    const idx: number = this.pageSet.indexOf(pageNumber);
    // if it's close to the last page
    if (pageNumber >= (this.numPages - this.pagesCountInPageset)) {
      this.pageSet = Array(this.pagesCountInPageset).fill(0).map((x, i) => i + this.numPages - this.pagesCountInPageset)
    } else if (idx == -1) {
      this.pageSet = Array(this.pagesCountInPageset).fill(0).map((x, i) => i + pageNumber); // + 1);
    } else {
      this.pageSet = Array(this.pagesCountInPageset).fill(0).map((x, i) => i + pageNumber - 1);
    }
  }

  /**
   * True if the current page is the first page
   */
  isFirstPage(): boolean {
    return this.currentPage == 1;
  }

  /**
   * True if the current page is the last page
   */
  isLastPage(): boolean {
    return this.currentPage == this.numPages;
  }

  /**
   * Go to the next page
   */
  goToNext(): void {
    if (!this.isLastPage()) {
      this.selectPage(null, this.currentPage + 1);
    }
  }

  /**
   * Go to the Previous page
   */
  goToPrevious(): void {
    if (!this.isFirstPage()) {
      this.selectPage(null, this.currentPage - 1)
    }
  }


  /**
   * handler to navigate to the first page
   */
  goToFirst(): void {
    this.selectPage(null, 1);
  }


  /**
   * Handler to navigate to the last page
   */
  goToLast(): void {
    this.selectPage(null, this.numPages);
  }


  /**
   * Retrieves the next set of pages to display

   * @param isNextSet True if we want to pull the next set of pages
   * false if it's the previous set
   */
  goToSet(isNextSet: boolean): void {
    const countItemsInSet: number = this.pageSet.length;
    if (isNextSet) {
      if (countItemsInSet == 0) {
        const pageToGo: number = this.currentPage + this.pagesCountInPageset
        this.selectPage(null, pageToGo)
      } else {
        const lastPageInSet: number = this.pageSet[countItemsInSet - 1];
        this.selectPage(null, lastPageInSet + 1);
      }
    } else {
      const firstPageInSet: number = this.pageSet[0];
      this.selectPage(null, firstPageInSet - 1);
    }
  }

  /**
   * True if the Pagination links should be visible
   */
  shouldShowPagination(): boolean {
    const shouldShow: boolean = this.numPages > 1;
    return shouldShow;
  }

  /**
   * True if the page number to evaluate is either the first or last page
   * @param pageNumber The pageNumber to evaluate
   */
  isFirstOrLastPage(pageNumber: number): boolean {
    const display: boolean = (pageNumber == 1 || pageNumber == this.numPages);
    return display;
  }


  /**
   * True if the page number should be displayed
   * @param pageNumber The page number being evaluated
   */
  shouldDisplayPageNumber(pageNumber: number): boolean {
    // don't display if it's the first or last page
    if (this.isFirstOrLastPage(pageNumber)) {
      return false;
    }
    // if it's not the first or last page
    if (this.pageSet.length == 0) {
      const maxItemsToDisplay: number = this.pagesCountInPageset;
      return (pageNumber <= maxItemsToDisplay);
    } else {
      return (this.pageSet.indexOf(pageNumber) > -1);
    }
  }




  /**
   * True if the previous set or next set ellipses are to be displayed
   * @param isNextPageSet True if its the ellipses to the right of the pages
   * false if it's the one to the left
   */
  shouldDisplayEllipses(isNextPageSet: boolean): boolean {
    let shouldDisplay = false;
    const countItemsInSet: number = this.pageSet.length;

    if (isNextPageSet) {
      if (this.pageSet.length == 0) {
        shouldDisplay = (this.currentPage + this.pagesCountInPageset) < this.numPages;
      } else {
        const lastPageInSet: number = this.pageSet[countItemsInSet - 1];
        shouldDisplay = (lastPageInSet + 1 < this.numPages)
      }
    } else {
      if (this.pageSet.length == 0) {
        shouldDisplay = (this.currentPage > this.pagesCountInPageset + 1);
      } else {
        const firstPageInSet = this.pageSet[0];
        shouldDisplay = (firstPageInSet > 2);
      }
    }
    return shouldDisplay;
  }

}
