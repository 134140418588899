<mc-header-bar [headerModel]="headerModel" (saveEvent)="saveChanges()" (cancelEvent)="cancelChanges()"></mc-header-bar>



<div class="main-section" id="mainSection">

  <div class="header-panel">
    <div class=" has-header">
      <div class="nav-return-bar">
        <ul>
          <li class="has-link ">
            <a (click)="navigateBack()">
              <span class="icon">
                <i class="fas fa-arrow-left"></i>
              </span>
              <span class="link-text">Back to Subject Landing</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
 
  <div class="  form-page columns is-marginless">
    <div class="column  is-12 ">
      <div class="main-enrollment">
        <h3 class="patient-enrollment-title">Subject Enrollment</h3>
        <table class="table is-fullwidth is-borderless is-striped enrollment-table" role="grid ">
          <thead>
            <tr>
              <th>Study Id </th>
              <th>Event</th>
              <th><button class="button is-small is-primary is-enrollment" 
                (click)="deleteAll()">Delete All</button></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of patientEnrollmentList; let idx=index ">
              <tr>
                <td>{{row.studyNumber}} </td>
                <td>{{row.eventName}} </td>
                <td> <button class="button is-small is-primary is-enrollment" [disabled]="!row.actionEnabled" 
                  (click)="enrollmentAction(row)">
                  {{row.actionName}}
                  </button></td> 
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>



</div>