import { Injectable, Inject, ComponentFactoryResolver } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
// import { catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from 'app/app-config/app-config.module';
import { Demographic } from 'app/common/model/demographic';
import { BaseService } from 'app/common/services/base-service';
import { StandardOptionModel, NameSettingOptionModel } from 'app/common/model/standard-option-model';
import { Ethnicity } from 'app/common/model/ethnicity';
import { Gender } from 'app/common/model/gender';
import { Race } from 'app/common/model/race';
import { PaymentMethod } from 'app/common/model/payment-method';

import { Country } from 'app/common/model/country';
import { MedDraOptionModel } from '../../model/medDra-option-model';
import { PossibleValuesHierarchy } from '../../model/possible-values-hierarchy';
import { StudyMedDRAClassification } from '../../model/demographic-settings';

@Injectable()
export class DemographyService extends BaseService {

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }


  /**
   * Gets the Gender Standards from the service
   */
  public getGenderStandards(): Observable<Array<StandardOptionModel>> {
    return this.http.get<Array<StandardOptionModel>>
      (`${this.config.apiEndpoint}demographics/gender/standards`);
  }

  /**
     * Gets the Race Standards from the service
     */
  public getRaceStandards(): Observable<Array<StandardOptionModel>> {
    // Endpoint: http://localhost:8080/reg-backend-service/api/v1/demographics/standards

    return this.http.get<Array<StandardOptionModel>>
      (`${this.config.apiEndpoint}demographics/race/standards`);
  }

  /**
     * Gets the Ethnicity Standards from the service
     */
  public getEthnicityStandards(): Observable<Array<StandardOptionModel>> {
    return this.http.get<Array<StandardOptionModel>>
      (`${this.config.apiEndpoint}demographics/ethnicity/standards`);
  }

  /**
     * Gets the Method of Payment Standards from the service
     */
  public getMethodPaymentStandards(): Observable<Array<StandardOptionModel>> {
    return this.http.get<Array<StandardOptionModel>>
      (`${this.config.apiEndpoint}demographics/payment-method/standards`);
  }

  /**
   * Gets the Country standards
   */
  public getCountryStandards(): Observable<Array<StandardOptionModel>> {
    return this.http.get<Array<StandardOptionModel>>
      (`${this.config.apiEndpoint}demographics/country/standards`);
  }



  /**
   * Gets the First Name options
   */
  public getFirstNameOptions(): Observable<Array<NameSettingOptionModel>> {
    return this.http.get<Array<NameSettingOptionModel>>
      (`${this.config.apiEndpoint}demographics/first-name/options`);
  }

  /**
   * Gets the LastName Options
   */
  public getLastNameOptions(): Observable<Array<NameSettingOptionModel>> {
    return this.http.get<Array<NameSettingOptionModel>>
      (`${this.config.apiEndpoint}demographics/last-name/options`);
  }


  /**
   * Gets the MiddleName Options
   */
  public getMiddleNameOptions(): Observable<Array<NameSettingOptionModel>> {
    return this.http.get<Array<NameSettingOptionModel>>
      (`${this.config.apiEndpoint}demographics/middle-name/options`);

  }

  /**
   * Gets the DateOfBirth Options
   */
  public getDobOptions(): Observable<Array<NameSettingOptionModel>> {
    return this.http.get<Array<NameSettingOptionModel>>
      (`${this.config.apiEndpoint}demographics/date-of-birth/options`);
  }


  /**
   * Gets the PostalCode Options
   */
  public getPostalCodeOptions(): Observable<Array<NameSettingOptionModel>> {
    const options: NameSettingOptionModel[] = [];
    return this.http.get<Array<NameSettingOptionModel>>
      (`${this.config.apiEndpoint}demographics/postal-code/options`);
  }

  /**
   * Gets the MedDRA Options
   */
  public getMedDRAOptions(): Observable<Array<MedDraOptionModel>> {
    return this.http.get<Array<MedDraOptionModel>>
      (`${this.config.apiEndpoint}demographics/meddra/options`);
  }

  /**
 * Gets the MedDRA Disease codes
 */
  public getMedDRADiseaseCodes(versionId: number): Observable<Array<PossibleValuesHierarchy>> {
    return this.http.get<Array<PossibleValuesHierarchy>>
      (`${this.config.apiEndpoint}demographics/meddra/disease-codes/${versionId}`);
  }

  /**
   * Gets the OtherSubjectId Options
   */
  public getOtherSubjectIdOptions(): Observable<Array<NameSettingOptionModel>> {
    const options: NameSettingOptionModel[] = [];
    return this.http.get<Array<NameSettingOptionModel>>
      (`${this.config.apiEndpoint}demographics/other-subject-id/options`);
  }

  /**
     * Gets the Duplicate Patient setting handling Options
     */
  public getDuplicatePatientOptions(): Observable<Array<NameSettingOptionModel>> {

    const options: NameSettingOptionModel[] = [];
    // TODO: Update this call when the endpoint is ready
    return this.http.get<Array<NameSettingOptionModel>>
      (`${this.config.apiEndpoint}demographics/multiple-registrations/options`);
  }



  /****** Service method for options and standards***************************** */

  /****** Demographics DTO*/

  /**
   * Gets the First Name setting for the Study id. If none exists, returns a blank object
   * @param : study Id
   * @return : Returns the First name object
   */
  public getFirstName(studyId: number): Observable<Demographic> {
    // return of(this.firstName);
    return this.http.get<Demographic>
      (`${this.config.apiEndpoint}studies/${studyId}/demographics/first-name`);
  }

  /**
   * Adds the First Name setting to the db
   * @param studyId The study Id whose setting we want to create
   * @param firstName The First Name setting property
   */
  addFirstName(firstName: Demographic): Observable<Demographic> {
    return this.http.post<Demographic>(`${this.config.apiEndpoint}studies/${firstName.studyId}/demographics/first-name`, firstName);
  }

  /**
   * Updates the FirstName setting property
   * @param studyId The study Id whose setting is being updated
   * @param firstName The demography object that represents the setting
   */
  public updateFirstName(firstName: Demographic): Observable<Demographic> {
    // record already exists - PUT
    return this.http.put<Demographic>
      (`${this.config.apiEndpoint}studies/${firstName.studyId}/demographics/first-name`, firstName);
  }


  /**
   * Gets the Middle Name setting for the Study id. If none exists, returns a blank object
   * @param : study Id
   * @return : Returns the Middle name object
   */
  public getMiddleName(studyId: number): Observable<Demographic> {
    return this.http.get<Demographic>(`${this.config.apiEndpoint}studies/${studyId}/demographics/middle-name`);
  }

  /**
   * Adds a middle name setting
   * @param middleName - The middle name object to create
   */
  addMiddleName(middleName: Demographic): Observable<Demographic> {
    return this.http.post<Demographic>(`${this.config.apiEndpoint}studies/${middleName.studyId}/demographics/middle-name`, middleName);
  }

  /**
   * Update a middle name setting
   * @param middleName - The middle name object to create
   */
  updateMiddleName(middleName: Demographic): Observable<Demographic> {
    return this.http.put<Demographic>(`${this.config.apiEndpoint}studies/${middleName.studyId}/demographics/middle-name`, middleName);
  }


  /**
   * Gets the Last Name setting for the Study id. If none exists, returns a blank object
   * @param : study Id
   * @return : Returns the Last name object
   */
  public getLastName(studyId: number): Observable<Demographic> {
    return this.http.get<Demographic>(`${this.config.apiEndpoint}studies/${studyId}/demographics/last-name`);
  }

  /**
   * Adds a last name setting
   * @param lastName - The last name object to create
   */
  addLastName(lastName: Demographic): Observable<Demographic> {
    return this.http.post<Demographic>(`${this.config.apiEndpoint}studies/${lastName.studyId}/demographics/last-name`, lastName);
  }

  /**
   * Update a last name setting
   * @param lastName - The last name object to create
   */
  updateLastName(lastName: Demographic): Observable<Demographic> {
    return this.http.put<Demographic>(`${this.config.apiEndpoint}studies/${lastName.studyId}/demographics/last-name`, lastName);
  }

  /**
     * Gets the Gender setting for the Study id. If none exists, returns a blank object
     * @param : study Id
     * @return : Returns the Gender object
     */
  public getGender(studyId: number): Observable<Gender> {
    return this.http.get<Gender>(`${this.config.apiEndpoint}studies/${studyId}/demographics/gender`);
  }


  /**
  * Adds a gender setting
  * @param gender - The gender object to create
  */
  addGender(gender: Gender): Observable<Gender> {
    return this.http.post<Gender>(`${this.config.apiEndpoint}studies/${gender.studyId}/demographics/gender`, gender);
  }

  /**
   * Update gender setting
   * @param gender - The gender object to create
   */
  updateGender(gender: Gender): Observable<Gender> {
    return this.http.put<Gender>(`${this.config.apiEndpoint}studies/${gender.studyId}/demographics/gender`, gender);
  }

  /**
   * Gets the race setting for the Study id. If none exists, returns a blank object
   * @param : study Id
   * @return : Returns the race object
   */
  public getRace(studyId: number): Observable<Race> {
    return this.http.get<Race>(`${this.config.apiEndpoint}studies/${studyId}/demographics/race`);
  }

  /**
   * Adds a race setting
   * @param race - The race object to create
   */
  addRace(race: Race): Observable<Race> {
    return this.http.post<Race>(`${this.config.apiEndpoint}studies/${race.studyId}/demographics/race`, race);
  }

  /**
   * Adds a race setting
   * @param race - The race object to create
   */
  updateRace(race: Race): Observable<Race> {
    return this.http.put<Race>(`${this.config.apiEndpoint}studies/${race.studyId}/demographics/race`, race);
  }

  /**
   * Gets the ethnicity setting for the Study id. If none exists, returns a blank object
   * @param : study Id
   * @return : Returns the ethnicity object
   */
  public getEthnicity(studyId: number): Observable<Ethnicity> {
    return this.http.get<Ethnicity>(`${this.config.apiEndpoint}studies/${studyId}/demographics/ethnicity`);
  }

  /**
   * Adds an ethnicity setting
   * @param ethnicity - The ethnicity object to create
   */
  addEthnicity(ethnicity: Ethnicity): Observable<Ethnicity> {
    return this.http.post<Ethnicity>(`${this.config.apiEndpoint}studies/${ethnicity.studyId}/demographics/ethnicity`, ethnicity);
  }

  /**
   * Update an ethnicity setting
   * @param ethnicity - The ethnicity object to create
   */
  updateEthnicity(ethnicity: Ethnicity): Observable<Ethnicity> {
    return this.http.put<Ethnicity>(`${this.config.apiEndpoint}studies/${ethnicity.studyId}/demographics/ethnicity`, ethnicity);
  }

  /**
   * Gets the date of birth setting for the Study id. If none exists, returns a blank object
   * @param : study Id
   * @return : Returns the date of birth object
   */
  public getDateOfBirth(studyId: number): Observable<Demographic> {
    return this.http.get<Demographic>(`${this.config.apiEndpoint}studies/${studyId}/demographics/date-of-birth`);
  }

  /**
   * Adds a date of birth setting
   * @param studyId - studyId
   * @param dateOfBirth - The date of birth object to create
   */
  addDateOfBirth(dateOfBirth: Demographic): Observable<Demographic> {
    return this.http.post<Demographic>(`${this.config.apiEndpoint}studies/${dateOfBirth.studyId}/demographics/date-of-birth`, dateOfBirth);
  }

  /**
   * Updates a date of birth setting
   * @param studyId - studyId
   * @param dateOfBirth - The date of birth object to create
   */
  updateDateOfBirth(dateOfBirth: Demographic): Observable<Demographic> {
    return this.http.put<Demographic>
      (`${this.config.apiEndpoint}studies/${dateOfBirth.studyId}/demographics/date-of-birth`, dateOfBirth);
  }

  /**
   * Gets the country setting for the Study id. If none exists, returns a blank object
   * @param : study Id
   * @return : Returns the date of birth object
   */
  public getCountry(studyId: number): Observable<Country> {
    return this.http.get<Country>(`${this.config.apiEndpoint}studies/${studyId}/demographics/country`);
  }

  /**
   * Adds a country setting
   * @param country - The country object to create
   */
  addCountry(country: Country): Observable<Country> {
    return this.http.post<Country>(`${this.config.apiEndpoint}studies/${country.studyId}/demographics/country`, country);
  }

  /**
   * Update a country setting
   * @param country - The country object to create
   */
  updateCountry(country: Country): Observable<Country> {
    return this.http.put<Country>(`${this.config.apiEndpoint}studies/${country.studyId}/demographics/country`, country);
  }

  /**
   * Gets the postal code setting for the Study id. If none exists, returns a blank object
   * @param : study Id
   * @return : Returns the postal code object
   */
  public getPostalCode(studyId: number): Observable<Demographic> {
    return this.http.get<Demographic>(`${this.config.apiEndpoint}studies/${studyId}/demographics/postal-code`);
  }

  /**
   * Adds a postal code setting
   * @param postalCode - The country object to create
   */
  addPostalCode(postalCode: Demographic): Observable<Demographic> {
    return this.http.post<Demographic>(`${this.config.apiEndpoint}studies/${postalCode.studyId}/demographics/postal-code`, postalCode);
  }

  /**
   * Updates a postal code setting
   * @param postalCode - The country object to create
   */
  updatePostalCode(postalCode: Demographic): Observable<Demographic> {
    return this.http.put<Demographic>(`${this.config.apiEndpoint}studies/${postalCode.studyId}/demographics/postal-code`, postalCode);
  }

  /**
   * Gets the medDRA code setting for the Study id. If none exists, returns a blank object
   * @param : study Id
   * @return : Returns the meddra code object
   */
  public getMedDRA(studyId: number): Observable<StudyMedDRAClassification> {
    return this.http.get<StudyMedDRAClassification>(`${this.config.apiEndpoint}studies/${studyId}/demographics/meddra`);
  }


  /**
   * Adds a medDRA setting
   * @param medDRA - The medDRA object to create
   */
  addMedDRA(medDRA: StudyMedDRAClassification): Observable<StudyMedDRAClassification> {
    return this.http.post<StudyMedDRAClassification>(`${this.config.apiEndpoint}studies/${medDRA.studyId}/demographics/meddra`, medDRA);
  }

  /**
   * Update a medDRA setting
   * @param medDRA - The medDRA object to update
  */
  updateMedDRA(medDRA: StudyMedDRAClassification): Observable<StudyMedDRAClassification> {
    return this.http.put<StudyMedDRAClassification>(`${this.config.apiEndpoint}studies/${medDRA.studyId}/demographics/meddra`, medDRA);
  }

  /**
   * Gets the Payment Method setting for the Study id.
   * @return : Returns the PaymentMethod object
   */
  public getPaymentMethod(studyId: number): Observable<PaymentMethod> {
    return this.http.get<PaymentMethod>(`${this.config.apiEndpoint}studies/${studyId}/demographics/payment-method`);
  }

  /**
   * Adds a payment method setting
   * @param paymentMethod - The payment method object to create
   */
  addPaymentMethod(paymentMethod: PaymentMethod): Observable<PaymentMethod> {
    return this.http.post<PaymentMethod>(`${this.config.apiEndpoint}studies/${paymentMethod.studyId}/demographics/payment-method`, paymentMethod);
  }

  /**
   * Update a payment method setting
   * @param paymentMethod - The payment method object to update
   */
  updatePaymentMethod(paymentMethod: PaymentMethod): Observable<PaymentMethod> {
    return this.http.put<PaymentMethod>(`${this.config.apiEndpoint}studies/${paymentMethod.studyId}/demographics/payment-method`, paymentMethod);
  }

  /**
   * Gets the Subject Number setting for the Study id.
   * @param : study Id
   * @return : Returns the OtherSubjectId object
   */
  public getOtherSubjectId(studyId: number): Observable<Demographic> {
    return this.http.get<Demographic>(`${this.config.apiEndpoint}studies/${studyId}/demographics/other-subject-id`);
  }

  /**
   * Adds a subject number setting
   * @param otherSubjectId - The subject number object to create
   */
  addOtherSubjectId(otherSubjectId: Demographic): Observable<Demographic> {
    return this.http.post<Demographic>(`${this.config.apiEndpoint}studies/${otherSubjectId.studyId}/demographics/other-subject-id`, otherSubjectId);
  }

  /**
   * Update a subject number setting
   * @param otherSubjectId - The subject number object to update
  */
  updateOtherSubjectId(otherSubjectId: Demographic): Observable<Demographic> {
    return this.http.put<Demographic>(`${this.config.apiEndpoint}studies/${otherSubjectId.studyId}/demographics/other-subject-id`, otherSubjectId);
  }




  /**
   * Gets the Duplicate Patient Handling setting for the Study id.
   * @param : study Id
   * @return : Returns the Duplicate Patient setting object
   */
  public getDuplicatePatient(studyId: number): Observable<Demographic> {
    return this.http.get<Demographic>(`${this.config.apiEndpoint}studies/${studyId}/demographics/multiple-registrations`);
  }

  /**
   * Adds a duplicate patient setting
   * @param duplicatePatient - The duplicate patient object to create
   */
  addDuplicatePatient(duplicatePatient: Demographic): Observable<Demographic> {
    return this.http.post<Demographic>(`${this.config.apiEndpoint}studies/${duplicatePatient.studyId}/demographics/multiple-registrations`, duplicatePatient);
  }

  /**
   * Update a duplicate patient setting
   * @param duplicatePatient - The duplicate patient object to update
  */
  updateDuplicatePatient(duplicatePatient: Demographic): Observable<Demographic> {
    return this.http.put<Demographic>(`${this.config.apiEndpoint}studies/${duplicatePatient.studyId}/demographics/multiple-registrations`, duplicatePatient);
  }



  // We should discuss as a team - https://angular.io/tutorial/toh-pt6#error-handling
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   */
  // private handleError(): {
  //   return (error: any): Observable<T> => {
  //     // Do something with this error
  //     console.error('Oops something happened!');
  //
  //   }
  // }
}
