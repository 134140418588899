import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as globalConst from 'app/common/model/app-constants';
import { EventArg } from 'app/common/model/event-arg';
import { StudyPersonType } from 'app/common/model/study-person-type';
import { StudySiteLevel } from 'app/common/model/study-site-level';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { UserService } from 'app/common/services/user.service';
import * as _ from 'lodash';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as ACTIVITY_NAMES from '../../common/model/activity-name-constants';
import { Organization } from '../../common/model/organization';
import { PatientRegistration } from '../../common/model/patient-registration';
import { UiLayoutElement } from '../../common/model/ui-layout-element';
import { NewPatientService } from '../../common/services/patient/new-patient.service';
import { Person } from '../model/person';





@Component({
  selector: 'mc-patient-participation-information',
  templateUrl: './patient-participation-information.component.html',
  styleUrls: ['./patient-participation-information.component.scss']
})
export class PatientParticipationInformationComponent implements OnInit, OnDestroy {

  @Input() SECTION_ID = '';
  @Input() studyId = 0;
  @Input() patientId = '';
  @Input() caseDetailId = 0;

  @Input() isPatientTransfer = false;

  @Output() treatmentLocationChanged = new EventEmitter<Organization>();

  @Output() tilesDisable = new EventEmitter<any>();

  subscriptions: Subscription[] = [];



  ctepTL: string;
  nonStdTL: Organization;
  isNonStdTLReadOnly: boolean = false;
  initialTLCaseOrgId: number;
  initialTLCaseOrgTypeId: number;

  ctepStaff = new Array("", "", "", "", "", "", "", "", ""); // Initialize to avoid typescript errors.  Will be adjust after we have participatingPersons
  nonStdStaff: Person[];
  //ctepStaff: string[] = [];

  /**
   * The list of studyPerson Types data
   */
  studyPersonTypes: StudyPersonType[] = [];

  siteLevelList: StudySiteLevel[] = [];

  lookupSiteLevelOrgList: Array<Organization[]> = [];

  SITE_CODE_CREDIT_GROUP = 'CGP';
  SITE_CODE_MEMBERSHIP = 'MBR';
  SITE_CODE_TREATING_LOCATION = 'TLC';

  /**
   * Array holding the reference to the ui elements
   */
  uiLayoutList: UiLayoutElement[] = [];


  nullOrganization: Organization = { 'ctepId': '', 'orgNumber': '', 'abrev': '', 'name': 'Select..', 'type': '', 'status': 'active', 'relationshipPk': null };

  nullPersonType: Person = { 'ctepId': null, 'firstName': '..', 'lastName': 'Select ', 'email': null, 'fax': null, 'phone': null, 'personTypeId': 0, 'pk': 0, 'personRoleStatus': 'Inactive' };
  creditGroupOrgList: Organization[] = [];



  membershipOrgList: Organization[] = [];

  treatingLocationOrgList: Organization[] = [];

  personsList: Person[] = [];

  sitesValidationProperty = 'participatingSites';
  personValidationProperty = 'participatingPersons';
  separator = '.';

  /**
   * This will hold the caseOrgIds for all the sites.
   */
  private caseOrganizationIds: number[] = [];

  /**
   * Holds the list of persons in the Lookup
   */
  personLookups: Map<number, Array<Person>> = new Map<number, Array<Person>>();
  /**
     * This will hold the caseEventStaffId for all the persons.
     */
  private caseEventStaffIds: number[] = [];




  blankPerson: Person = { firstName: '', lastName: '', pk: null, personTypeId: 0, personRoleStatus: '' };


  @ViewChildren('participatingPersons', { read: ViewContainerRef }) viewContentPersonDivs: QueryList<ViewContainerRef>;
  @ViewChildren('siteInfo', { read: ViewContainerRef }) viewContentSiteDivs: QueryList<ViewContainerRef>;

  constructor(private studySetupService: StudySetupService,
    private patientService: NewPatientService,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {

    this.subscriptions.push(this.studySetupService.changeEmitted$.subscribe(
      eventInfo => {
        this.parseEventInfo(eventInfo);
      }));

    this.subscriptions.push(this.patientService.regValidationErrors$.subscribe(
      validationErrors => {
        // this.processValidationErrors(validationErrors);
      }));

    console.log('2.0 - Patient Participation - component initialized');
    // this.subscriptions.push(
    //   this.route.parent.params.subscribe(params => {
    //     this.studyId = params['studyId'];
    //     this.caseDetailId = params['caseDetailId'];
    //     this.patientId = params['patientId'];
    //   }));

  }


  /**
 * Destroy implementation - closes all the subscriptions
 */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    );
  }


  /**
* Parses the event arg object to detemine action to take
* @param eventArg Event Arg to be parsed
*/
  parseEventInfo(eventArg: EventArg) {
    if (eventArg.eventName == globalConst.EVENT_DATA_UPDATED) {
      console.log('2 - Patient Parti Participation - HAndling Data Updated Event');
      this.initializeLookupData();
    }
    if (eventArg.eventName == globalConst.EVENT_SAVE_ACTION) {
      // this.saveChanges();
    } else if (eventArg.eventName == globalConst.EVENT_CANCEL_ACTION) {
      // this.cancelChanges();
    } else if (eventArg.eventName == globalConst.EVENT_DATA_RESET) {
      this.processReset(eventArg);
    }
  }



  /**
   * CAll the services to fetch the data for display on the ui
   */
  initializeLookupData() {
    const observables: Observable<any>[] = [];
    observables.push(this.getStudySiteLevels());
    observables.push(this.getPersonTypes());

    // once all the data has been retrieved set the display
    // property and clone
    this.subscriptions.push(
      forkJoin(observables)
        .subscribe(() => {
          //To improve performance we are not loading sites and persons drop downs if sites and persons data is readonly
          if (!this.isSiteDataReadonly() || !this.isPersonDataReadonly()) {
            const secondSet: Observable<any>[] = [];
            secondSet.push(this.getTopLevelLookupOrgs());

            this.subscriptions.push(
              forkJoin(secondSet)
                .subscribe(() => {
                  console.log('Second forkJoin initialized.');
                }, (err) => { },
                  () => {
                    console.log('2.3 - Patient PArt Participation - All Lookup Data Retrieved');
                    setTimeout(() => {
                      console.log('2.4 - Patient PArt  Participation - Initialize data for Patient');
                      this.initializeData();
                      this.performViewInit();
                    }, 100);
                  })
            );
          }
        }, (err) => { console.log(err); },
          () => {

            // console.log("2.3 - Patient PArt Participation - All Lookup Data Retrieved")
            // setTimeout(() => {
            //   console.log("2.4 - Patient PArt  Participation - Initialize data for Patient")
            //   this.initializeData();
            //   this.performViewInit();
            // }, 100);

          }
        ));
  }

  /**
   * Returns the list of studySiteLevels from the service
   */
  getStudySiteLevels(): Observable<any> {

    const obs =
      this.studySetupService.getSiteLevels(this.studyId)
        .pipe(tap(results => {
          console.log('2.1 - Participation - retrieved Site Level Data');
          this.siteLevelList = results.filter(eachSite => eachSite.required);
        })
        );

    return obs;
  }



  /**
     * Sets up the data for the person types section of the page
     */
  getPersonTypes(): Observable<any> {
    const obs =
      this.studySetupService.getStudyPersonTypes(this.studyId)
        .pipe(tap(results => {
          console.log('2.2 - Participation - retrieved Person Type data');
          this.studyPersonTypes = results.filter(eachPerson => (eachPerson.required != null));

          this.studyPersonTypes.forEach(eachPType => {
            // Create the lookup map for each person Type
            this.personLookups.set(eachPType.label.id, []);
            if (eachPType.studyId == null) {
              eachPType.studyId = this.studyId;
            }
            if (eachPType.modifiedById == null) {
              eachPType.modifiedById = '';
            }
          });
          console.log('Retrieved the person types');
          // this.cloneData(false, false);
        })
        );
    return obs;
  }


  /**
   * Initialize the data for the object is no data exists
   */
  initializeData() {
    if (this.currentRegModel()) {
      console.log('2.5 - Participation - Current Reg Model Exists setting correct site and persons');

      const sites = this.currentRegModel().participatingSites;
      if (!sites || sites.length == 0) {
        this.currentRegModel().participatingSites = new Array(this.siteLevelList.length);

        this.siteLevelList.forEach((siteLevel, ix) => {
          this.currentRegModel().participatingSites[ix] = null;
          // this.currentRegModel().participatingSites[ix] = this.getNullOrgForSiteLevel(siteLevel.siteLevel);
        });

      } else {
        // if sites already assigned find them and set the dropdowns


        //***************** code to handle non-standard sites and staff ************************* */
        // EXCEPTION:  	If Alliance credit group and treating location non-standard, allow this to display and to be updated but do no allow a new treating location to be assigned to Alliance unless its on the eresearch alliance roster.    
        const cgSite: StudySiteLevel = this.getCreditGroupSite();
        const tlSite: StudySiteLevel = this.getTreatingLocation();
        if (this.currentRegModel()) {
          const sites = this.currentRegModel().participatingSites;
          if (sites.length > 0) {
            const cgIdx = sites.findIndex(eachS => eachS && cgSite && eachS.siteLevelId == cgSite.siteLevel.id);
            if (cgIdx > -1) { // have a credit group


              if (this.currentRegModel().participatingSites[cgIdx].ctepId == 'ALLIANCE' &&
                this.currentRegModel().participatingSites[cgIdx].nonStdOrgId &&
                this.currentRegModel().participatingSites[cgIdx].nonStdOrgId > 0) { // credit group is non-standard Alliance
                const tlIdx = sites.findIndex(eachS => eachS && eachS.siteLevelId == tlSite.siteLevel.id);
                if (tlIdx > -1) {
                  if (this.currentRegModel().participatingSites[tlIdx].nonStdOrgId &&
                    this.currentRegModel().participatingSites[tlIdx].nonStdOrgId > 0) {
                    this.isNonStdTLReadOnly = true; // can't change TL unless they change the CG to standard
                    this.currentRegModel().participatingSites[cgIdx].name = 'Alliance (non-standard)'; // display it as non-standard
                    const nonStdAlliance = _.clone(this.currentRegModel().participatingSites[cgIdx]);
                    this.creditGroupOrgList.push(nonStdAlliance); // add it to the dropdown so it will display
                  }
                }
              }

              // Add caseOrganizationTypeId from existing creditGroup to selected CGP
              if (this.isNonStdCreditGroup()) {
                const site = sites.find(site => site.siteLevelCode == 'CGP');
                this.creditGroupOrgList.forEach(creditGrp => {
                  creditGrp.caseOrganizationTypeId = site.caseOrganizationTypeId;
                });
              }
            }
          }
        }
        //***************** end code to handle non-standard sites and staff ************************* */


        let memberObs: Observable<any>;
        let hasTreatingLocation = false;
        let treatingLocationIdx = -1;

        let siteLevel: StudySiteLevel;
        let selectedOrg: Organization = null;
        sites.forEach((site, idx) => {
          // selectedOrg = null;
          const studySiteLevel = this.siteLevelList.find(studySiteLevel => site && studySiteLevel.siteLevel.id == site.siteLevelId);
          if (studySiteLevel && studySiteLevel.siteLevel) {
            // we already got the group list, but based on previous selections find the memberships or treating location list
            // TODO: we really only need to do this if it not read only
            if (studySiteLevel.siteLevel.code == this.SITE_CODE_MEMBERSHIP) {
              selectedOrg = null;
              siteLevel = this.getMembershipSite();
              selectedOrg = this.getSelectedParentOrg(idx, siteLevel, 'creditGroupOrgList');
              // check that the selected Org doesn't have the same siteLevelId as the current one
              // this can happen when we don't have a matching credit group
              if (selectedOrg && selectedOrg.siteLevelId !== siteLevel.siteLevel.id) {
                this.membershipOrgList = [];
                this.setCaseOrganizationId(idx, 'creditGroupOrgList');
                if (selectedOrg.relationshipPk && selectedOrg.relationshipPk > 0) {
                  memberObs = this.getOrgListObservable(selectedOrg.relationshipPk,
                    siteLevel, 'membershipOrgList');
                }
              } else {
                this.setCaseOrganizationId(1, 'membershipOrgList');
              }
            } else if (studySiteLevel.siteLevel.code == this.SITE_CODE_TREATING_LOCATION) {
              hasTreatingLocation = true;
              treatingLocationIdx = idx;
              if ((memberObs) || (selectedOrg)) {
                this.treatingLocationOrgList = [];
              }
            }
          }
        });

        if (hasTreatingLocation) {
          // this.treatingLocationOrgList = [];
          siteLevel = this.getTreatingLocation();
        }

        if (memberObs) {
          this.subscriptions.push(
            memberObs
              .subscribe(() => {
                selectedOrg = this.getSelectedParentOrg(treatingLocationIdx, siteLevel, 'membershipOrgList');
                this.populateTreatingLocationSiteList(selectedOrg, siteLevel);
              }, (err) => { },
                () => {
                })
          );
        } else {
          // There was no top level Credit Group
          if (selectedOrg) {
            this.populateTreatingLocationSiteList(selectedOrg, siteLevel);
          } else {
            // There were no top level Credit Groups or Membership sites
            this.populatePersonsForTreatingLocation();
          }

        }
        console.log('participatingSites ', this.currentRegModel().participatingSites);
      }

      if (this.currentRegModel().participatingPersons.length == 0) {
        // TODO: Revisit once the person lookup is created
        this.currentRegModel().participatingPersons = new Array(this.studyPersonTypes.length);
        this.studyPersonTypes.forEach((personType, ix) => {
          this.currentRegModel().participatingPersons[ix] = this.getNullPersonForStudyPerson(personType.label.id); // null;
          // this.currentRegModel().participatingPersons[ix] = this.getNullPersonForStudyPerson(personType);

        });
      }

      // Initialize non-standard ctepTL
      const studySiteLevel = this.getTreatingLocation();
      const filteredOrgs = this.currentRegModel().participatingSites.filter(site => site && site.siteLevelId && site.siteLevelId == studySiteLevel.siteLevel.id);
      if (filteredOrgs && filteredOrgs.length > 0 && filteredOrgs[0]) {
        this.ctepTL = filteredOrgs[0].ctepId;
        if (this.isNonStdCreditGroup()) {
          this.initialTLCaseOrgId = filteredOrgs[0].caseOrganizationId;
          this.initialTLCaseOrgTypeId = filteredOrgs[0].caseOrganizationTypeId;
        }
      }

      // Initialize non-standard staff 
      this.nonStdStaff = _.clone(this.currentRegModel().participatingPersons);
      this.ctepStaff = new Array(this.currentRegModel().participatingPersons.length);
      if (this.nonStdStaff.length == 0) {
        this.studyPersonTypes.forEach((type, ix) => {
          this.ctepStaff[ix] = '';
        });
      }
      else {
        // TODO - binding of incoming ctep ids not working
        this.nonStdStaff.forEach((staff, ix) => {
          const item = this.studyPersonTypes.find(pType => pType.label.id == staff.personTypeId);
          const index = this.studyPersonTypes.findIndex(pType => pType.label.id == item.label.id);
          if (item && staff.ctepId) {
            this.ctepStaff[index] = staff.ctepId;
          }
          else if (item) {
            this.ctepStaff[index] = '';
          }
        });
      }
      // update the original model in the service
      const cloned = _.cloneDeep(this.patientService.currentPatientRegModel);
      this.patientService.originalPatientRegModel = cloned;
    }
  }


  /***
   *
   * Populates the Treating location and the dependant persons
   */
  private populateTreatingLocationSiteList(selectedOrg: Organization, siteLevel: StudySiteLevel) {
    if (selectedOrg && selectedOrg.relationshipPk && selectedOrg.relationshipPk > 0) {
      this.getOrgListObservable(selectedOrg.relationshipPk,
        siteLevel, 'treatingLocationOrgList')
        .subscribe(() => {
          // let sites = this.currentRegModel().participatingSites;
          // let index: number = sites.findIndex(eachOrg => eachOrg.siteLevelId == siteLevel.siteLevel.id)
          // this.setCaseOrganizationId(index + 1, "treatingLocationOrgList");
        }, (err) => { },
          () => {
            this.populatePersonsForTreatingLocation();
            // let site = this.getTreatingLocation();
            // let org = this.currentRegModel().participatingSites.find(item => item.siteLevelId == site.siteLevel.id);
            // //If the Org does not have the relationshipPk set
            // if (org != undefined && org.relationshipPk != undefined && org.relationshipPk == 0 && this.membershipOrgList.length > 0) {
            //   org = this.membershipOrgList.find(eachItem => eachItem.orgNumber === org.orgNumber);
            // }
            // if (org && org.relationshipPk) {
            //   this.getPersonsFromService(org.relationshipPk)
            // }
          });
    }
  }

  /**
   * Populate the Persons for a Treating Location
   */
  private populatePersonsForTreatingLocation() {
    const site = this.getTreatingLocation();
    let org = this.currentRegModel().participatingSites.find(item => item && item.siteLevelId == site.siteLevel.id);
    if (site.networkFlag) {

      // If the Org does not have the relationshipPk set
      if (org != undefined && org.relationshipPk != undefined && org.relationshipPk == 0 && this.membershipOrgList.length > 0) {
        org = this.membershipOrgList.find(eachItem => eachItem && eachItem.orgNumber === org.orgNumber);
      }
      if (org && org.relationshipPk) {
        this.getPersonsFromService(org.relationshipPk, true);
      }

    } else {
      this.getPersonsFromService(org.studyNetworkId, false);
    }

  }

  /**
   * Returns a blank person to start off
   * @param personType the person type
   */
  getNullPersonForStudyPerson(personType: number): Person {
    const p: Person = _.clone(this.blankPerson);
    p.personTypeId = personType;
    return p;
  }

  /**
 * Returns a blank person with personType and existing CaseEventStaffId to start off
 * @param personType the person type
 */
  setNullPersonForStudyPerson(personType: number, caseEventStaffId: number): Person {
    const p: Person = _.clone(this.blankPerson);
    p.personTypeId = personType;
    p.caseEventStaffId = caseEventStaffId;
    return p;
  }


  /**
   * Returns the SiteLevel for Credit Group
   */
  getCreditGroupSite(): StudySiteLevel {
    return this.getSiteByCode(this.SITE_CODE_CREDIT_GROUP);
  }

  /**
   * Returns the SiteLevel for Membership
   */
  getMembershipSite(): StudySiteLevel {
    return this.getSiteByCode(this.SITE_CODE_MEMBERSHIP);
  }
  /**
   * Returns the SiteLevel for Treating Location
   */
  getTreatingLocation(): StudySiteLevel {
    return this.getSiteByCode(this.SITE_CODE_TREATING_LOCATION);
  }


  /**
   * Retrieves the exact site based on the siteLevel Code
   * @param code The code to locate the site
   */
  private getSiteByCode(code: string): StudySiteLevel {
    if (this.siteLevelList && this.siteLevelList.length > 0) {
      return this.siteLevelList.find(item => item.siteLevel && item.siteLevel.code.toLowerCase() == code.toLowerCase());
    }
    return null;
  }


  /**
   * True if the siteLevel code is the code being evaluated
   * @param item The siteLevel to evaluate
   * @param siteLevelCode The code to check
   */
  isSiteLevel(item: StudySiteLevel, siteLevelCode: string): boolean {
    //   console.log("Checking.. ",siteLevelCode, item, item.siteLevel.code == siteLevelCode)
    return item.siteLevel.code == siteLevelCode;
  }


  /**
     * handles the data refresh when the tracking id is retrieved and the underlying
     * patient reg object is updated
     * TODO: Do we need to do anything here?
     * @param eventArg The event arg associated with this event
     */
  processReset(eventArg: EventArg) {
    if (eventArg && eventArg.eventObject == globalConst.EVENT_DATA_REFRESH) {
      console.log('2.7 - Participation - Reset being processed');
      this.caseDetailId = this.patientService.currentParentComponent.caseDetailId;
      // clear all the persons dropdowns
      Array.from(this.personLookups.keys()).forEach(
        eachId => {
          this.personLookups.set(eachId, []);
        });
      this.personsList = [];
      this.initializeData();
    }
  }

  performViewInit() {
    console.log('2.6 - Participation - AFTER View init called ');
    if (this.viewContentSiteDivs && this.viewContentSiteDivs.length > 0) {
      this.viewContentSiteDivs.forEach((eachItem, itemIdx) => {
        const node: ViewContainerRef = this.renderer.selectRootElement(eachItem);
        this.uiLayoutList.push(this.getUILayoutElement(itemIdx, node, true));
      });
    }
    if (this.viewContentPersonDivs && this.viewContentPersonDivs.length > 0) {
      this.viewContentPersonDivs.forEach((eachPersonDiv, itemIdx) => {
        const node: ViewContainerRef = this.renderer.selectRootElement(eachPersonDiv);
        this.uiLayoutList.push(this.getUILayoutElement(itemIdx, node, false));
      });
    }
    this.patientService.setUiLayoutElements(this.uiLayoutList);
  }



  /**
     * The PatientRegistration model being created or edited
     */
  public currentRegModel(): PatientRegistration {
    return this.patientService.currentPatientRegModel;
  }


  /**
   *
   * @param idx The index of the layout element
   * @param viewRef The view container
   * @param isSiteList True if the collection is a site or it's the person list
   */
  getUILayoutElement(idx: number, viewRef: ViewContainerRef, isSiteList: boolean): UiLayoutElement {
    let uiElement: UiLayoutElement = { sectionId: this.SECTION_ID, node: null, errorMessages: [], propertyName: '' };
    if (isSiteList) {
      // find the site at the index
      const matches = this.siteLevelList.filter(eachItem => eachItem.required);
      uiElement = {
        sectionId: this.SECTION_ID,
        node: viewRef,
        propertyName:
          this.sitesValidationProperty + this.separator + matches[idx].siteLevel.id,
        errorMessages: []
      };
    } else {
      // find the site at the index
      const matches = this.studyPersonTypes.filter(eachItem => eachItem.required != null);
      uiElement = {
        sectionId: this.SECTION_ID,
        node: viewRef,
        propertyName:
          this.personValidationProperty + this.separator + matches[idx].label.id,
        errorMessages: []
      };
    }
    return uiElement;
  }


  /**
   * Handles the selection of an Org for CreditGroup
   * TODO: do we need to do anything here?  - clear out the staffCtep, maybe?
   * @param index The index of the site
   */
  creditGroupSelected(index: number) {
    this.clearDependencies(0);
    this.getMembershipOrgs(index);
    this.isNonStdTLReadOnly = false;
  }


  /**
   * Handles the Selection of an org in the Membership dropdown
   * @param index The index of the site in the sites array
   */
  membershipSelected(index: number) {
    this.clearDependencies(1);
    this.getTreatingLocationOrgs();
  }

  /**
   * Handles the Selection of an org in the Treatment Location dropdown
   */
  treatingLocationSelected(indx: number = 2) {
    this.clearDependencies(2);
    // console.log("Participation ", this.currentRegModel().participatingSites,
    //   this.currentRegModel().participatingSites[2])
    const site = this.getTreatingLocation();
    let org = this.currentRegModel().participatingSites.find(item => item && item.siteLevelId == site.siteLevel.id);

    // if we started with a non-standard TLC and switched to standard, need to set the caseOrganizationId so is updated instead of inserted
    if (this.initialTLCaseOrgId && this.initialTLCaseOrgId > 0) {
      const idx = this.currentRegModel().participatingSites.findIndex(eachS => eachS && eachS.siteLevelId == site.siteLevel.id);
      this.currentRegModel().participatingSites[idx].caseOrganizationId = this.initialTLCaseOrgId;
      this.currentRegModel().participatingSites[idx].caseOrganizationTypeId = this.initialTLCaseOrgTypeId;
    }

    if (site.networkFlag) {
      // If the Org does not have the relationshipPk set
      if (org != undefined && org.relationshipPk != undefined && org.relationshipPk == 0 && this.membershipOrgList.length > 0) {
        org = this.membershipOrgList.find(eachItem => eachItem && eachItem.orgNumber === org.orgNumber);
      }

      if (org && org.relationshipPk) {
        this.getPersonsFromService(org.relationshipPk, true);
      }
    } else {
      this.getPersonsFromService(org.studyNetworkId, false);
    }

    // RAise the event for treatment Location Changed
    this.treatmentLocationChanged.emit(org);
    //  }, 10);



  }


  /**
   * Clears the dependencies for the changed site
   * @param siteIndex The current site being changed
   */
  clearDependencies(siteIndex: number) {
    // if there are child sites..
    //check if it is non-stadard site
    let isNonStandardSite = false;
    let isExistingSiteNonStd = false;
    if (this.isNonStdCreditGroup()) {
      isNonStandardSite = true;
    }
    const sites = this.currentRegModel().participatingSites;

    const existingSite = sites.find(site => site != null && site.nonStdOrgId > 0);
    if (existingSite) {
      isExistingSiteNonStd = true;
    }
    const personsCount: number = this.currentRegModel().participatingPersons.length;
    if (siteIndex == 0 && sites.length > 1) {
      if (this.isPatientTransfer) {
        this.currentRegModel().participatingSites[1] = null;
        this.currentRegModel().participatingSites[2] = null;
      }
      else if (isNonStandardSite) {
        const mbrSite = sites.find(site => site && site.siteLevelCode == 'MBR');
        const mbrIndex = sites.findIndex(site => site && site.siteLevelCode == 'MBR');
        const studySiteLevel = this.getMembershipSite();
        if (mbrSite) {
          this.currentRegModel().participatingSites[mbrIndex] = this.nullOrganization;
          this.currentRegModel().participatingSites[mbrIndex].isNonStandardSite = true;
          this.currentRegModel().participatingSites[mbrIndex].caseOrganizationId = mbrSite.caseOrganizationId;
          this.currentRegModel().participatingSites[mbrIndex].caseOrganizationTypeId = mbrSite.caseOrganizationTypeId;
          this.currentRegModel().participatingSites[mbrIndex].siteLevelCode = studySiteLevel.siteLevel.code;
          this.currentRegModel().participatingSites[mbrIndex].siteLevelId = studySiteLevel.siteLevel.id;
        }

        //find existing treating location site and clear, but leave caseOrganizationId and type
        const tlcSite = sites.find(site => site && site.siteLevelCode == 'TLC');
        const tlcIndex = sites.findIndex(site => site && site.siteLevelCode == 'TLC');
        // this.currentRegModel().participatingSites[2] = this.nullOrganization;
        // this.currentRegModel().participatingSites[2].caseOrganizationId = tlcSite.caseOrganizationId;
        // this.currentRegModel().participatingSites[2].caseOrganizationTypeId = tlcSite.caseOrganizationTypeId;
        if (tlcSite) {
          this.currentRegModel().participatingSites[tlcIndex].name = '';
          this.currentRegModel().participatingSites[tlcIndex].ctepId = '';
          this.currentRegModel().participatingSites[tlcIndex].abrev = '';
          this.currentRegModel().participatingSites[tlcIndex].relationshipPk = 0;
          this.currentRegModel().participatingSites[tlcIndex].caseOrganizationTypeId = null;
        }
      } else if (isExistingSiteNonStd) {
        // happens when site changes from non-standard to standard site, we have to add in a membership site
        this.currentRegModel().participatingSites[1] = null;
        //manually add slot for membership (if there is a CGP then you have to have MBR from a study config standpoint)
        this.currentRegModel().participatingSites.push(null);
      } else {
        this.currentRegModel().participatingSites[1] = null;
        this.currentRegModel().participatingSites[2] = null;
      }


      // clear non-standard structure
      this.ctepTL = '';
    }
    if (siteIndex == 1 && sites.length > 2) {
      this.currentRegModel().participatingSites[2] = null;
    }
    if (personsCount > 0) {
      // clear the selected persons
      for (let index = 0; index < personsCount; index++) {
        const existingPerson: Person = this.currentRegModel().participatingPersons[index];
        let pTypeId = 0;
        let pCaseEventStaffId = 0;
        if (existingPerson) {
          pTypeId = existingPerson.personTypeId;
          pCaseEventStaffId = existingPerson.caseEventStaffId;
        }
        this.currentRegModel().participatingPersons[index] = this.setNullPersonForStudyPerson(pTypeId, pCaseEventStaffId); // null;
        this.nonStdStaff[index] = this.setNullPersonForStudyPerson(pTypeId, 0);
        this.ctepStaff[index] = '';
      }
      // clear the lookup array values
      Array.from(this.personLookups.keys()).forEach(
        eachId => {
          this.personLookups.set(eachId, []);
        });
    }
    this.personsList = []; // Clears persons list when site is changed/cleared.
  }


  /**
   * Populates the lookup for the Top level Lookup Orgs
   */
  getTopLevelLookupOrgs(): Observable<any> {
    let siteLevel: StudySiteLevel = this.getCreditGroupSite();
    let targetCollectionName = 'creditGroupOrgList';
    if (siteLevel == undefined) {
      // The CreditGroup has not been configured
      siteLevel = this.getMembershipSite();
      if (siteLevel) {
        // the next site - Membership is configured
        targetCollectionName = 'membershipOrgList';
      } else {
        // We just have the Treating location
        siteLevel = this.getTreatingLocation();
        if (siteLevel) {
          targetCollectionName = 'treatingLocationOrgList';
        }
      }
    }
    let obs: Observable<any>;
    if (siteLevel) {
      obs = this.getOrgListObservable(0, siteLevel, targetCollectionName);
      console.log('Returning TargetCollection ', targetCollectionName);
    }
    return obs;
  }





  /**
  * Gets the Membership Org list for the selected CreditGroup
  * @param index The index for the particiation site on the Reg object
  */
  getMembershipOrgs(index: number) {
    this.membershipOrgList = [];
    this.treatingLocationOrgList = [];

    const siteLevel: StudySiteLevel = this.getMembershipSite();

    let selectedOrg: Organization = null;
    selectedOrg = this.getSelectedParentOrg(index, siteLevel, 'creditGroupOrgList');
    if (selectedOrg && selectedOrg.relationshipPk && selectedOrg.relationshipPk > 0) {
      const obsList: Observable<any>[] = [];
      obsList.push(
        this.getOrgListObservable(selectedOrg.relationshipPk,
          siteLevel, 'membershipOrgList'));

      this.subscriptions.push(
        forkJoin(obsList)
          .subscribe(() => {

          }, (err) => { },
            () => {
              console.log('Membership Org List retrieved');
            })
      );
    }

  }


  /**
   * Gets the orgList for the selected Site
   * @param relationshipPk
   * @param siteLevel
   * @param targetCollectionName
   */
  private getOrgListObservable(relationshipPk: number, siteLevel: StudySiteLevel, targetCollectionName: string): Observable<any> {
    let sitecode: string = siteLevel.siteLevel.code;
    if (sitecode.toUpperCase() == 'CGP') {
      // if it's Credit Group, we will pass in 0 for relationshipPk and blank for Sitecode
      relationshipPk = 0;
      sitecode = '';
    }
    const obs: Observable<any> =
      this.patientService.getSiteOrgs(this.studyId,
        relationshipPk, sitecode, siteLevel.networkFlag)
        .pipe(tap(results => {
          if (sitecode == '') {
            sitecode = 'CGP';
          }
          const index: number = this.siteLevelList.findIndex(eachSite => eachSite.siteLevel.code == sitecode);
          if (results && results.length > 0) {
            results.forEach((item: Organization) => {
              item.siteLevelId = siteLevel.siteLevel.id;
              item.name = this.getOrganizationName(item);
            });
            this[targetCollectionName] = results;
            if (results.length > 0 && index > -1) {
              this.setCaseOrganizationId(index + 1, targetCollectionName);
            }
          }
        })
        );
    return obs;
  }

  /**
   * If ctepId exists concatonate the ctep id onto the name
   * if it is the registration office, include site relationship type and status
   * @param org
   */
  private getOrganizationName(org: Organization) {
    let theName = org.name;
    if (org.ctepId != '' && org.ctepId != null) {
      theName = theName + ' (' + org.ctepId + ')';
    }

    // if the registration office, show site relationship type and status
    if (this.userService.hasCreateAccess(ACTIVITY_NAMES.ACTIVITY_PATIENT_REGISTRATION)) {
      theName = theName + ' - ' + org.type + ' - ' + org.status;
    }
    return theName;
  }





  /**
   * Retrieves the list of lookup orgs for Treating locations based on the membership selected
   * @param index The index of the Membersihp org in the array
   */
  getTreatingLocationOrgs() {
    this.treatingLocationOrgList = [];
    const siteLevel: StudySiteLevel = this.getTreatingLocation();

    const index: number = this.siteLevelList.findIndex(x => x && x.required &&
      x.siteLevel.id == siteLevel.siteLevel.id);
    let selectedOrg: Organization = null;
    selectedOrg = this.getSelectedParentOrg(index, siteLevel, 'membershipOrgList');
    if (selectedOrg && selectedOrg.relationshipPk && selectedOrg.relationshipPk > 0) {
      const obsList: Observable<any>[] = [];
      obsList.push(
        this.getOrgListObservable(selectedOrg.relationshipPk,
          siteLevel, 'treatingLocationOrgList'));

      this.subscriptions.push(
        forkJoin(obsList)
          .subscribe((

          ) => {

          }, (err) => { },
            () => {
              console.log('Treating location Org List retrieved');
            })
      );

    }
  }


  /**
   * Returns the parent Org for this site
   * @param index The index of the participatingSites
   * @param siteLevel The siteLevel object
   * @param orgListName The name of the list that is set
   */
  private getSelectedParentOrg(index: number, siteLevel: StudySiteLevel, orgListName: string): Organization {
    let selectedOrg: Organization = null;
    if (siteLevel) {
      if (index > 0) {
        // find the siteLevelId for the siteLevel
        const siteLevelId: number = (this.currentRegModel().participatingSites[index - 1].siteLevelId);
        if (siteLevelId && siteLevelId > 0) {
          const parentRelationshipPk = this.currentRegModel().participatingSites[index - 1].relationshipPk;
          selectedOrg = this[orgListName].find(item => item && item.relationshipPk == parentRelationshipPk);
          // this.setCaseOrganizationId(index, orgListName);
          // selectedOrg =  this.membershipOrgList.find(item => item.orgNumber == membershipOrgNumber)
        }
      } else {
        selectedOrg = this.currentRegModel().participatingSites[index];
      }
    }
    return selectedOrg;
  }

  /**
   * Sets the caseOrganizationId for all the orgs if it's set for the saved on
   * @param index The index of the site of the participating Sites
   * @param orgListName The list of the organizations that are the lookup
   */
  setCaseOrganizationId(index: number, orgListName: string) {
    const selectedOrganization: Organization = this.currentRegModel().participatingSites[index - 1];
    let caseOrgId: number;
    if (selectedOrganization && selectedOrganization.caseOrganizationId && selectedOrganization.caseOrganizationId > 0) {
      caseOrgId = selectedOrganization.caseOrganizationId;
    } else if (this.caseOrganizationIds.length >= index) {
      // in case we blew away the child collections for existing patients
      // and we need to reset the caseOrgIds
      caseOrgId = this.caseOrganizationIds[index - 1];
    }
    if (caseOrgId > 0) {
      this[orgListName].forEach(eachOrg => {
        eachOrg.caseOrganizationId = caseOrgId;
      });
      // if the caseOrgId has not been set, set it
      this.caseOrganizationIds[index - 1] = caseOrgId;
    }
  }




  /**
   * True if Site Data is readonly
   */
  isSiteDataReadonly(): boolean {
    const parentComponent = this.patientService.currentParentComponent;
    let isReadonly = false;
    // Site Data is editable if:
    // - User is working with the First Event
    // - User has specifically asked to editing
    if (parentComponent && !parentComponent.isProcessingFirstEvent()) {
      isReadonly = !parentComponent.isSectionBeingEdited(this.SECTION_ID);
    }

    // OR if the registration button was pressed and registration completed successfully,
    // this section should be read only
    if (this.currentRegModel().isRegistrationCompleted) {
      isReadonly = true;
    }
    return isReadonly;
    // //if this is a first event or the host page is patientTransfer
    // const parentComponent = this.patientService.currentParentComponent;
    // if ((parentComponent != null && parentComponent.isFirstEvent()) || this.isPatientTransfer) {
    //   return false;
    // }
    // if (this.caseDetailId > 0) {
    //   return this.isRegDataReadonly();
    // }
    // return false;
  }


  /**
   * True if the Person information should be in Readonly mode
   */
  isPersonDataReadonly(): boolean {
    const parentComponent = this.patientService.currentParentComponent;
    let isReadonly = false;
    // Person Data is editable if:
    // - User is working with any event in any mode
    // - User has specifically asked to editing
    if (parentComponent) {
      isReadonly =
        parentComponent.isUpdateSectionMode() &&
        !parentComponent.isSectionBeingEdited(this.SECTION_ID);
    }

    // OR if the registration button was pressed and registration completed successfully,
    // this section should be read only
    if (this.currentRegModel().isRegistrationCompleted) {
      isReadonly = true;
    }
    return isReadonly;

  }





  /**
   * Returns the name of the matching organization for the site
   * @param rowIdx The siteIndex being evaluated
   */
  getSiteName(siteLevel: StudySiteLevel, rowIdx: number): string {
    let siteName = '';
    if (this.currentRegModel()) {
      const sites = this.currentRegModel().participatingSites;
      // const site = sites[rowIdx];
      const site = sites.filter(eachSite => eachSite && eachSite.siteLevelId && siteLevel && siteLevel.siteLevel && eachSite.siteLevelId == siteLevel.siteLevel.id);

      if (site.length = 1) {
        // siteName = site[1].name;
        if (site[0].name) {
          siteName = site[0].name;
        }
        else if (site[0].ctepId) {
          siteName = site[0].ctepId;
        }

      }
    }
    return siteName;
  }


  /**
   * Returns the orgList for the siteLevel Code
   * @param siteCode The SiteCode to evaluate
   */
  getOrgListBySiteCode(siteCode: string): Organization[] {
    if (siteCode == this.SITE_CODE_CREDIT_GROUP) {
      return this.creditGroupOrgList;
    }
    if (siteCode == this.SITE_CODE_MEMBERSHIP) {
      return this.membershipOrgList;
    }
    if (siteCode == this.SITE_CODE_TREATING_LOCATION) {
      return this.treatingLocationOrgList;
    }
    return [];
  }
  /**
     * Compare objects, used in html for matching coded lists
     */
  compareFnCg(a, b) {
    if (a || b) {
      return a && b && a.orgNumber == b.orgNumber;
    } else {
      return true;
    }
  }

  compareFnNonStd(a, b) {
    if (a || b) {
      return a && b && a.nonStdOrgId == b.nonStdOrgId;
    } else {
      return true;
    }
  }


  compareFnMember(a, b) {
    if (a || b) {
      return a && b && a.orgNumber == b.orgNumber && a.relationshipPk == b.relationshipPk;
    } else {
      return true;
    }
  }
  compareFnTl(a, b) {
    if (a || b) {
      return a && b && a.orgNumber == b.orgNumber;
    } else {
      return true;
    }
  }

  /**
       * Compare objects, used in html for matching coded lists
       */
  comparePersonFn(a: Person, b: Person) {
    if (a || b) {
      return a && b && a.pk == b.pk;
    } else {
      return true;
    }
  }

  /***** Begin Person ******* */

  /**
   * Returns the Person that matches the list of persontypesbeing evaluated
   * @param item The personType to evaluate
   * @param personToCheck The person to evalutes
   */
  getRegPersonForPersonType(item: StudyPersonType, personToCheck: Person): Person {
    const person: Person = null; // this.getNullPersonForStudyPerson(item.label.id);
    if (this.currentRegModel()) {
      const existingPersons: Person[] = this.currentRegModel().participatingPersons;
      if (existingPersons) {
        const existingPerson: Person = existingPersons.find(each => each && each.personTypeId == item.label.id);
        if (existingPerson &&
          (personToCheck.pk > 0 && personToCheck.pk == existingPerson.pk) ||
          (personToCheck.nonStdStaffId > 0 && personToCheck.nonStdStaffId == existingPerson.nonStdStaffId)) {
          return personToCheck;
        }
      }
    }
    return person;
  }
  /**
   * This is to check the person firstname, lastname, fullname
   * so it won't display person with empty value.
   * @param person
   * @returns boolean
   */
  checkForFirstAndLastname(person: Person): boolean {
    let isPersonTypeValid = false;
    if (person && ((person.firstName && person.lastName) || person.fullName)) {
      isPersonTypeValid = true;
    }
    return isPersonTypeValid;
  }

  /**
  * Sets the Person on the PatientREg Model for the personType being evaluated
  * @param item The personType to evaluate
  */
  setRegPersonForPersonType($event: any, item: StudyPersonType): void {
    // Check what person the user has selected
    // Find the target person (in patientReg.participatingPersons) this needs to go to
    // if the target exists - replace it with the selection
    // if does not exist - push it to the collection
    if ($event && $event.target && $event.target.value) {
      const id: number = +$event.target.value;
      // find the index of this selection
      const lookup: Person[] = this.getPersonsForType(item);
      let match: Person = this.getNullPersonForStudyPerson(item.label.id);
      if (lookup) {
        const p: Person = lookup.find(eachP => eachP.pk == id);
        if (p) {
          match = p;
        }
      }
      if (this.currentRegModel()) {
        const persons = this.currentRegModel().participatingPersons;
        if (persons.length > 0) {
          const idx = persons.findIndex(eachP => eachP && eachP.personTypeId == item.label.id);
          if (idx > -1) {
            const caseStafffEventId: number = this.currentRegModel().participatingPersons[idx].caseEventStaffId;
            if (caseStafffEventId) {
              match.caseEventStaffId = caseStafffEventId;
            }
            this.currentRegModel().participatingPersons[idx] = match;
          } else {

            //This is a case where person already delted and now try to select a new one, so caseEventStaffID needs to be empty or null.
            match.caseEventStaffId = null;
            this.currentRegModel().participatingPersons.push(match);
          }
        }
      }
    }
    console.log(this.currentRegModel());
  }

  /**
   * return the binding value for the passed in person - nonStdStaffId for non-standard, pk for standard
   * @param singlePerson 
   */
  getSinglePersonValue(singlePerson) {
    return singlePerson.nonStdStaffId ? singlePerson.nonStdStaffId : singlePerson.pk;
  }

  /**
   * Returns the list of person for this relationshipId
   * @param relationshipId The relationshipId to evaluate
   */
  getPersonsFromService(relationshipId: number, isNetwork: boolean): void {

    // let fetchPersons$: Observable<Person[]> = this.patientService.getPersonsForRelationship(this.studyId, relationshipId);

    if (isNetwork) {
      this.subscriptions.push(
        this.patientService.getPersonsForRelationship(this.studyId, relationshipId)
          .subscribe((results) => {
            this.personsList = results;
            this.personsList.forEach((eachPerson: Person, idx: number) => {
              const typeId: number = eachPerson.personTypeId;
              if (this.personLookups.has(typeId)) {
                this.setPersonStaffId(eachPerson);
                this.personLookups.get(typeId).push(eachPerson);
              }
            });
            // Push the selected person for each type if they aren't already there (e.g. from OPEN)
            // this.addSelectedPersonsToPersonsList();
            // NOTE for non-standard persons enhancement: this existing code handles the documented exception:
            // EXCEPTION:  If previously saved staff was non-standard but the treating location is not non-standard..The normal drop downs using
            // the roster will be displayed in addition to the non-standard person marked as non-standard.    No other non-standard person can be selected.
            this.addSelectedPersonsToPersonsList();

            Array.from(this.personLookups.values()).forEach(
              personList => {
                console.log(personList);
                personList.sort(function (a, b) {
                  return (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : 0);
                });
              });
          }, (err) => { },
            () => {

            })
      );
    } else {
      this.subscriptions.push(
        this.patientService.getPersonsForDirectParticipant(this.studyId, relationshipId)
          .subscribe((results) => {
            this.personsList = results;
            this.personsList.forEach((eachPerson: Person, idx: number) => {
              const typeId: number = eachPerson.personTypeId;
              if (this.personLookups.has(typeId)) {
                this.setPersonStaffId(eachPerson);
                this.personLookups.get(typeId).push(eachPerson);
              }
            });

            // Push the selected person for each type if they aren't already there (e.g. from OPEN)
            this.addSelectedPersonsToPersonsList();

            Array.from(this.personLookups.values()).forEach(
              personList => {
                console.log(personList);
                personList.sort(function (a, b) {
                  return (a.fullName > b.fullName) ? 1 : ((b.fullName > a.fullName) ? -1 : 0);
                });
              });
          }, (err) => { },
            () => {

            })
      );
    }
  }

  /**
   * Method to push the selected person for each type if they aren't already there (e.g. from OPEN)
   * TODO: do we need to do something here?
   */
  addSelectedPersonsToPersonsList() {
    const existingPersons: Person[] = this.currentRegModel().participatingPersons;
    existingPersons.forEach((eachPerson: Person) => {
      let needToAdd = true;
      const typeId: number = eachPerson.personTypeId;
      if (this.personLookups.has(typeId)) {
        // Loop through that type id to see if the person is already there
        const personsByType = this.personLookups.get(typeId);
        personsByType.forEach((eachPersonByType: Person) => {
          if (eachPersonByType.pk == eachPerson.pk) {
            // ||  eachPerson.pk == 0 && eachPerson.nonStandardStaffId == 0) {  // pk gets set to 0 on reset of selected TL
            needToAdd = false;
          }
        });
        if (needToAdd) {
          if ((eachPerson.pk == null || eachPerson.pk == undefined) &&
            eachPerson.ctepId != null &&
            this.userService.hasCreateAccess(ACTIVITY_NAMES.ACTIVITY_PATIENT_REGISTRATION)) {
            eachPerson.personRoleStatus = 'Non-Rostered';
            this.setPersonStaffId(eachPerson);
            this.personLookups.get(typeId).push(eachPerson);
          }
          //  this.setPersonStaffId(eachPerson);
          //   this.personLookups.get(typeId).push(eachPerson);
        }
      }
    });
  }


  /**
   * Method to populate the caseEventStaffId on the person object if it's existing data
   * @param item The Person object on which to set the caseEventStaff Id
   */
  setPersonStaffId(item: Person): void {
    const persons: Person[] = this.currentRegModel().participatingPersons;
    let eventStaffId = 0;
    if (persons.length > 0) {
      const existingIdx: number = persons.findIndex(eachPerson => eachPerson && eachPerson.personTypeId &&
        eachPerson.personTypeId == item.personTypeId);
      if (existingIdx > -1) {
        const existingSelection: Person = persons[existingIdx];
        if (existingSelection && existingSelection.caseEventStaffId && existingSelection.caseEventStaffId > 0) {
          eventStaffId = existingSelection.caseEventStaffId;
          this.caseEventStaffIds[existingIdx] = eventStaffId;
        }
      } else {
        // check if we had existing staffIds set
        if (this.caseEventStaffIds.length > 0) {
          const idx: number = this.studyPersonTypes.findIndex(pt => pt && pt.label && pt.label.id == item.personTypeId);
          if (idx > -1) {
            eventStaffId = this.caseEventStaffIds[idx];
          }
        }
      }
    }
    if (eventStaffId > 0) {
      item.caseEventStaffId = eventStaffId;
    }
  }

  /**
   * Returns the list of persons matching the code for the StudyPerson Type
   * @param item The studyPerson Type to evaluate
   */
  getPersonsForType(item: StudyPersonType): Person[] {
    const key: number = item.label.id;
    //console.log(this.personLookups.get(key));
    let isValidPerson = false;
    this.personLookups.get(key)
      .forEach(person => {
        if (person && ((person.firstName && person.lastName) || person.fullName)) {
          isValidPerson = true;
        }
      });
    if (isValidPerson) {
      return this.personLookups.get(key);
    }
    return null;

  }

  /**
   * Returns person lastName, firstName to drop downs
   * if the registration office, show  person lastName, firstName and person status
   *
   * @param singlePerson
   */
  getPersonData(singlePerson: Person): string {
    // if the registration office, show person status also
    if (singlePerson) {
      if (this.userService.hasCreateAccess(ACTIVITY_NAMES.ACTIVITY_PATIENT_REGISTRATION) && singlePerson.personRoleStatus != null && singlePerson.personRoleStatus != '') {
        if (singlePerson.fullName == null) {
          return singlePerson.lastName + ', ' + singlePerson.firstName + ' - ' + singlePerson.personRoleStatus;
        } else {
          return singlePerson.fullName + singlePerson.personRoleStatus;
        }
      }
      if (singlePerson.fullName == null) {
        return singlePerson.lastName + ', ' + singlePerson.firstName;
      } else {
        return singlePerson.fullName;
      }
    } else { return ''; }
  }

  /*********End Person */

  // Returns site list with alphabetical order
  sortOrgList(orgList: Organization[]): Organization[] {
    return orgList.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
  }

  /**
   * returns true if the selected credit group is non-standard
   */
  isNonStdCreditGroup() {
    const cg: StudySiteLevel = this.getCreditGroupSite();
    const selectedCG = this.currentRegModel().participatingSites.filter(site => site && site.siteLevelId && cg && cg.siteLevel && site.siteLevelId == cg.siteLevel.id);
    if (selectedCG.length > 0) {
      if (selectedCG[0].nonStdOrgId && selectedCG[0].nonStdOrgId > 0) {
        return true;
      }
    }
    return false;
  }

  getNonStdSiteInformation(siteIndex: number) {
    if (this.ctepTL && this.ctepTL.length > 0) {
      this.ctepTL = this.ctepTL.toLocaleUpperCase();
      this.subscriptions.push(
        this.patientService.getNonStdSiteInformation(this.ctepTL)
          .subscribe((results) => {

            const tlStudySiteLevel: StudySiteLevel = this.getTreatingLocation();
            if (this.currentRegModel()) {
              const sites = this.currentRegModel().participatingSites;
              if (sites.length > 0) {
                const idx = sites.findIndex(eachS => eachS && eachS.siteLevelId == tlStudySiteLevel.siteLevel.id);
                if (idx > -1) {
                  results.siteLevelId = this.currentRegModel().participatingSites[idx].siteLevelId;
                  results.siteLevelCode = this.currentRegModel().participatingSites[idx].siteLevelCode;
                  results.caseOrganizationId = this.currentRegModel().participatingSites[idx].caseOrganizationId;
                  results.caseOrganizationTypeId = this.currentRegModel().participatingSites[idx].caseOrganizationTypeId;
                  this.nonStdTL = results;
                  this.currentRegModel().participatingSites[idx] = results;

                }
                else {
                  results.siteLevelId = tlStudySiteLevel.siteLevel.id;
                  results.siteLevelCode = tlStudySiteLevel.siteLevel.code;
                  results.caseOrganizationId = -1;
                  this.nonStdTL = results;
                  this.currentRegModel().participatingSites[2] = results;
                }
              }
            }
          }, (err) => { console.log("getNonStdSiteInformation err"); },
            () => {
              console.log("getNonStdSiteInformation else");
            })
      );
    }
    else {
      const tlStudySiteLevel: StudySiteLevel = this.getTreatingLocation();
      if (this.currentRegModel()) {
        const sites = this.currentRegModel().participatingSites;
        if (sites.length > 0) {
          const idx = sites.findIndex(eachS => eachS && eachS.siteLevelId == tlStudySiteLevel.siteLevel.id);
          if (idx > -1) {
            //this.currentRegModel().participatingSites[idx] = null;
            // Keep the caseOrganizationId, clear out the other important stuff
            this.currentRegModel().participatingSites[idx].name = '';
            this.currentRegModel().participatingSites[idx].ctepId = '';
            this.currentRegModel().participatingSites[idx].abrev = '';
            this.currentRegModel().participatingSites[idx].relationshipPk = 0;
            this.currentRegModel().participatingSites[idx].caseOrganizationTypeId = null;
          }
        }
      }
    }

    //Disable other tiles other than regulatory check 
    this.patientService.currentParentComponent.disableTiles();

    // make sure the regulatory check button is required
    // const eventArg: EventArg = new EventArg(globalConst.PATIENT_DATA_UPDATED, 'true');
    // this.studySetupService.emitChange(eventArg);

  }

  getNonStdTLName() {
    const studySiteLevel = this.getTreatingLocation();
    const filteredOrgs = this.currentRegModel().participatingSites.filter(site => site && site.siteLevelId && site.siteLevelId == studySiteLevel.siteLevel.id);
    if (filteredOrgs && filteredOrgs.length > 0) {
      if (filteredOrgs[0].abrev) {
        return filteredOrgs[0].abrev; // abrev or name?
      }
      else if (filteredOrgs[0].name) {
        return filteredOrgs[0].name;
      }
    }
    return '';
  }

  getNonStdStaffInformation(item: StudyPersonType, staffIndex: number) {
    if (this.ctepStaff && this.ctepStaff[staffIndex] != null && this.ctepStaff[staffIndex].length > 0) {
      this.ctepStaff[staffIndex] = this.ctepStaff[staffIndex].toLocaleUpperCase();
      this.subscriptions.push(
        this.patientService.getNonStdStaffInformation(this.ctepStaff[staffIndex])
          .subscribe((results) => {
            if (this.currentRegModel()) {
              const persons = this.currentRegModel().participatingPersons;
              if (persons.length > 0) {
                const idx = persons.findIndex(eachP => eachP && eachP.personTypeId == item.label.id);
                if (idx > -1) {
                  results.personTypeId = this.currentRegModel().participatingPersons[idx].personTypeId;
                  this.setPersonStaffId(results);
                  this.nonStdStaff[idx] = results;
                  this.currentRegModel().participatingPersons[idx] = results;
                } else {
                  results.personTypeId = item.label.id;
                  //this.setPersonStaffId(results);
                  results.caseEventStaffId = null;
                  this.currentRegModel().participatingPersons.push(results);
                  this.nonStdStaff[staffIndex] = results;
                }
              }
            }
          }, (err) => { console.log("getNonStdStaffInformation err"); },
            () => {
              console.log("getNonStdStaffInformation else");
            })
      );
    }
    else {
      if (this.currentRegModel()) {
        const persons = this.currentRegModel().participatingPersons;
        if (persons.length > 0) {
          const idx = persons.findIndex(eachP => eachP && eachP.personTypeId == item.label.id);
          if (idx > -1) {
            const personType = this.currentRegModel().participatingPersons[idx].personTypeId;
            const caseEventStaffId = this.currentRegModel().participatingPersons[idx].caseEventStaffId;
            this.currentRegModel().participatingPersons[idx] = this.blankPerson;
            this.currentRegModel().participatingPersons[idx].personTypeId = personType;
            this.currentRegModel().participatingPersons[idx].caseEventStaffId = caseEventStaffId;
            this.nonStdStaff[staffIndex] = this.blankPerson;
            this.nonStdStaff[staffIndex].personTypeId = personType;
          }
        }
      }
    }
    //Disable other tiles other than regulatory check 
    this.patientService.currentParentComponent.disableTiles();
  }

  getNonStdStaffName(item: StudyPersonType, staffIndex: number) {
    console.log('getNonStdStaffName', this.currentRegModel());
    let staffInfo: string = '';
    const filteredPersons = this.currentRegModel().participatingPersons.filter(p => p && p.personTypeId && p.personTypeId == item.label.id); //item.studyPersonTypeId
    if (filteredPersons && filteredPersons.length > 0) {
      if (filteredPersons[0].fullName) {
        staffInfo = filteredPersons[0].fullName;
      } else {
        if (filteredPersons[0].lastName) {
          staffInfo = filteredPersons[0].lastName + " ";
        }
        if (filteredPersons[0].firstName) {
          staffInfo = staffInfo + filteredPersons[0].firstName;
        }
      }
      if (filteredPersons[0].email) {
        staffInfo = staffInfo + " - " + filteredPersons[0].email;
      }
      return staffInfo;
    }
    return '';

  }


}

