

<p>
  This implementation is deprecated, please update to date-picker-wrapper.
</p>
 

<div  *ngIf="false">
<div #calendarContainer name="calendarContainer"
[ngStyle]="calendarPositionStyle"
   class="container is-fluid  calendar-top-container" *ngIf="isOpen">
  <div class="calendar is-small">
    <div class="calendar-nav">
      <div class="calendar-nav-left">
        <button class="button is-link" (click)="getPreviousMonth(currentMonth)">
          <i class="fas fa-chevron-left"></i>
        </button>
      </div>
      <div>{{ currentMonth }}</div>
      <div class="calendar-nav-right">
        <button class="button is-link" (click)="getNextMonth(currentMonth)">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <div class="calendar-container">
      <div class="calendar-header">
        <div class="calendar-date">Sun</div>
        <div class="calendar-date">Mon</div>
        <div class="calendar-date">Tue</div>
        <div class="calendar-date">Wed</div>
        <div class="calendar-date">Thu</div>
        <div class="calendar-date">Fri</div>
        <div class="calendar-date">Sat</div>
      </div>

      <div class="calendar-body">

        <div class="calendar-date" *ngFor="let date of dates" >
          <button  
             class="date-item" 
             [ngClass]="{'is-today': isToday(date), 'is-current-month': isCurrentMonth(date)}"
          (click)="dateSelected(date)"
          >{{ date.date }}</button>
        </div>

      </div>
    </div>
  </div><!-- /.calendar -->
</div><!-- /.container -->
</div>