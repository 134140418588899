import { NotificationCode } from './notification-code';
import { Individual } from 'app/common/model/notification-person';

export class NotificationDistribution {

    constructor(
        public notificationDistributionId: number,
        public notificationDistributionType: NotificationCode,
        public role: NotificationCode | null,
        public individual: Individual | null ,
        public sharedEmail: NotificationCode | null,
        public personType: NotificationCode | null,
      ) {}
}
