import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StudyServiceBase } from 'app/common/services/study-service-base.service';
import { PatientTileResult } from 'app/common/model/patient-tile-result';
import { PatientSearchResult } from 'app/common/model/patient-search-result';
import * as globalConst from 'app/common/model/app-constants';
import { SearchService } from 'app/common/services/search.service';

@Component({
  selector: 'mc-patient-tile-results',
  templateUrl: './patient-tile-results.component.html',
  styleUrls: ['./patient-tile-results.component.scss']
})
export class PatientTileResultsComponent implements OnInit {
  public patientResults: PatientTileResult[];
  public patientsFound: boolean;
  rows: PatientTileResult[];

    public columns: Array<any> = [
      {
        title: 'Study Number', name: 'studyNumber',
        sort: true,
        display: true,
        filtering: false
      },
      {
        title: 'Tracking Number',
        name: 'trackingNum',
        sort: true,
        display: true,
        filtering: false
      },
      {
        title: 'Patient Id',
        name: 'patientId',
        sort: true,
        display: true,
        filtering: false
      },
      {
        title: 'Name', name: 'name',
        sort: true,
        display: true,
        filtering: false
      },
       {
        title: 'DOB', name: 'dateOfBirthString',
        sort: true,
        display: true,
        filtering: false
      },
      {
        title: 'Site', name: 'siteName',
        sort: true,
        display: true,
        filtering: false
      },
      {
        title: 'Event', name: 'eventName',
        sort: true,
        display: true,
        filtering: false
      },
      {
        title: 'Registrar', name: 'registrarName',
        sort: true,
        display: true,
        filtering: false
      }
    ];


    /**
     * The configuration for the table being displayed
     */
    public config: any = {
      paging: false,
      sorting: { columns: this.columns },
      filtering: false,
      className: ['is-striped', 'is-bordered']
    };

  constructor(private router: Router,
    private studyBaseService: StudyServiceBase,
    private searchService: SearchService) { }


     /**
 * Sorts the data in the table based on the column and sort order
 * @param data The data currently being displayed on the table
 * @param config The associated configuration for the data being displayed
 */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (const column of columns ) {
      if (column.sort !== '' && column.sort !== false) {
        columnName = column.name;
        sort = column.sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousVal: any;
      let currentVal: any;

      // If the following columns are used, then need to get different values for previous and current
      if (columnName === 'studyNumber') {
        previousVal = previous?.patient?.studyNumber ? previous?.patient?.studyNumber.toLowerCase() : '';
        currentVal = current?.patient?.studyNumber ? current?.patient?.studyNumber.toLowerCase() : '';
      } else if (columnName === 'trackingNum') {
        previousVal = previous?.patient?.trackingNum ? previous?.patient?.trackingNum.toLowerCase() : '';
        currentVal = current?.patient?.trackingNum ? current?.patient?.trackingNum.toLowerCase() : '';
      } else if (columnName === 'patientId') {
        previousVal = previous?.patient?.patientId ? previous?.patient?.patientId.toLowerCase() : '';
        currentVal = current?.patient?.patientId ? current?.patient?.patientId.toLowerCase() : '';
      } else if (columnName === 'name') { // get first and last name
        previousVal = (previous?.patient?.firstName ? previous?.patient?.firstName.toLowerCase() : '') +
          (previous?.patient?.lastName ? previous?.patient?.lastName.toLowerCase() : '');
        currentVal = (current?.patient?.firstName ? current?.patient?.firstName.toLowerCase() : '') +
          (current?.patient?.lastName ? current?.patient?.lastName.toLowerCase() : '');
      } else if (columnName === 'dateOfBirthString') { // date values
        const dateA: string = previous?.patient[columnName]|| '';
        const dateB: string = current?.patient[columnName] || '';
        previousVal = this.searchService.parseDateString(dateA);
        currentVal = this.searchService.parseDateString(dateB) ;

      } else if (columnName === 'siteName') {
        previousVal = previous?.treatingLocation?.name ? previous?.treatingLocation?.name.toLowerCase() : '';
        currentVal = current?.treatingLocation?.name ? current?.treatingLocation?.name.toLowerCase() : '';
      } else if (columnName === 'eventName') {
        previousVal = previous?.event?.schemaEventDisplayName ? previous?.event?.schemaEventDisplayName.toLowerCase() : '';
        currentVal = current?.event?.schemaEventDisplayName ? current?.event?.schemaEventDisplayName.toLowerCase() : '';
     } else if (columnName === 'registrarName') { // get data entry person first and last name
        previousVal = (previous?.registrar?.firstName ? previous?.registrar?.firstName.toLowerCase() : '') +
          (previous?.registrar?.lastName ? previous?.registrar?.lastName.toLowerCase() : '');
        currentVal = (current?.registrar?.firstName ? current?.registrar?.firstName.toLowerCase() : '') +
          (current?.registrar?.lastName ? current?.registrar?.lastName.toLowerCase() : '');
      } else { // simple values
        previousVal = previous[columnName] ? previous[columnName].toLowerCase() : '';
        currentVal = current[columnName] ? current[columnName].toLowerCase() : '';
      }

      if (previousVal > currentVal) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousVal < currentVal) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

    /**
   * Main method to handle filtering, sorting of the table
   * @param config Configuration of the datatable
   * @param page The current page being displayed
   */
  public onChangeTable(config: any): any {
    let column: any = null;
    this.rows = [];
    if (config.title !== undefined && config.name !== undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.patientResults, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;

  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data === undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name] != null) {
            return item[column.name].toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name] != null && item[column.name] !== undefined
          && item[column.name].toString().match(this.config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  /**
   * fetch all the patient registrations still in process
   */
  ngOnInit() {
    this.studyBaseService.getActivePatientsForAllStudies()
    .subscribe(results => {
      if (results.length > 0) {
        this.patientsFound = true;
        this.patientResults = results;
      } else {
        this.patientsFound = false;
      }
      this.onChangeTable(this.config);
      console.log('Patients found', this.patientsFound);
      })
  }


  /**
  * This will take you to the patientLanding page
   *
   * @param  patient
   */
  goToPatientLanding(patient: PatientSearchResult) {
   const path: string = '/patient/' + patient.studyId + '/' + patient.patientId + '/' + patient.caseDetailId;
   this.router.navigate([path], {
     queryParams: {
       'navBack': globalConst.LANDING_PAGE_ENUM_HOME,
       'trackingNum' : patient.trackingNum
      }
    });
  }
}
