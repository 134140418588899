import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from 'app/app-config/app-config.module';
import { Code } from 'app/common/model/code';
import { FormNavRule } from 'app/common/model/form-nav-rule';
import { FormVariable } from 'app/common/model/form-variable';
import { TabbedDataRuleError } from 'app/common/model/tabbed-data-rule-error';
import { VariableClass } from 'app/common/model/variable-class';
import * as jexl from 'jexl';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { Form } from '../../model/form';
import { BaseService } from '../base-service';
import { Helper } from '../helper.service';

@Injectable()
export class RulesService extends BaseService {

  http: HttpClient;
  config: AppConfig;
  serviceUrlPrefix: string;


  /**
   * the navigation rules used on the form object
   */
  //private navRules: NavRuleValidator [] = [];

  helper: Helper;
  SPECIAL_NAV_TARGET_END_OF_SECTION = 'ENDOFSECTION';
  SPECIAL_NAV_TARGET_CODE = 'SPECIAL';
  VALIDATION_ERROR_RULE_TYPE = 'Navigation';

  constructor(http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
    this.config = config;
    this.http = http;
    this.serviceUrlPrefix = config.apiEndpoint;
    this.helper = new Helper();
  }



  // validateDependencyRules

  /**
  * Sends the Form to the service for validation
  * @param studyId The Study id
  * @param formId The FormId
  * @param record The form record to validate
  */
  public validateDependencyRules(studyId: number, formId: number, record: Form): Observable<any> {
    /// studies/17807/forms/14144/validateRules
    const url = this.serviceUrlPrefix + 'studies/' + studyId + '/forms/' + formId + '/validateRules';
    return this.http.post(url, record, { responseType: 'text' });
  }


  /**
     *
     * Gets the list of form Navigation target types
     * @returns Observable list of codes
     */
  public getFormNavigationTargetTypes(): Observable<Code[]> {
    const url = 'configuration/nav-rule/target-types';
    return this.http.get<Code[]>
      (`${this.config.apiEndpoint}` + url);
  }


  /**
    *
    * Gets the list of form  Navigation Actions
    * @returns Observable list of codes
    */
  public getFormNavigationActions(): Observable<Code[]> {
    const url = 'configuration/nav-rule/actions';
    return this.http.get<Code[]>
      (`${this.config.apiEndpoint}` + url);
  }

  /**
 *
 * Gets the list of form  Navigation Target type Special
 * @returns Observable list of codes
 */
  public getFormNavigationTargetTypesSpecial(): Observable<Code[]> {
    const url = 'configuration/nav-rule/target-special';
    return this.http.get<Code[]>
      (`${this.config.apiEndpoint}` + url);
  }

  /**
   * Validates all navigation rules on all variables in the given form
   * @param form Form who's variables' navigation rules need to be validated
   * @returns List of all navigation rule errors found
   */
  public validateFormNavigationRules(form: Form, variableClasses: VariableClass[]): Observable<TabbedDataRuleError[]> {
    let navigationErrors: TabbedDataRuleError[] = [];
    form?.formVariableList.forEach(formVariable => {
      navigationErrors = navigationErrors.concat(this.validateVariableNavigationRules(formVariable, form));
    });

    return of(navigationErrors);
  }

  /**
   * Validates each navigation rule on the given form variable
   * @param formVariable Variable who's navigation rules need to be validated
   * @param form Form where the given form variable exists
   * @returns List of errors based on evaluating each navigation rule
   */
  public validateVariableNavigationRules(
    formVariable: FormVariable, form: Form
  ): TabbedDataRuleError[] {
    const navigationErrors: TabbedDataRuleError[] = [];

    if (formVariable.formVariableNavRule?.navRules?.length > 0) {

      formVariable.formVariableNavRule.navRules.forEach(navigationRule => {
        const basicSelections = this.validateNavRule_BasicSelections(navigationRule, formVariable);
        if (basicSelections !== null) {
          navigationErrors.push(basicSelections);
        }
        else {
          const gotoSelections = this.validateNavRule_GoToSelections(navigationRule, formVariable);
          if (gotoSelections !== null) {
            navigationErrors.push(gotoSelections);
          }
          else {
            const canBeParsed = this.validateNavRule_CanBeParsed(navigationRule, formVariable);
            if (canBeParsed !== null) {
              navigationErrors.push(canBeParsed);
            }
            else {
              const isBoolean = this.validateNavRule_IsBoolean(navigationRule, formVariable);
              if (isBoolean !== null) {
                navigationErrors.push(isBoolean);
              }

              const ruleVariables = this.validateNavRule_RuleVariablesPrecedeTriggerVariable(navigationRule, formVariable, form);
              if (ruleVariables !== null) {
                navigationErrors.push(ruleVariables);
              }
            }
          }
        }
      });

      const sameRules = this.validateNavRule_SameRuleSeparateTargets(formVariable);
      if (sameRules !== null) {
        navigationErrors.push(...sameRules);
      }
    }
    return navigationErrors;
  }

  /******************** Navigation Rule Helpers ********************/
  /**
   * Creates a standard error and rule type for the given values
   * @param formVariableId ID of the variable where the error is connected
   * @param navigationId ID of the navigation rule that caused the error
   * @param message Message that should be displayed for the error
   * @returns Error populated with the given values
   */
  private createNavigationError(formVariableId: number, navigationId: number, message: string): TabbedDataRuleError {
    return new TabbedDataRuleError(formVariableId, navigationId, null, message, this.VALIDATION_ERROR_RULE_TYPE);
  }

  /**
   * Finds the form section where the navigation rule's form variable exists
   * @param navRule Current navigation rule being evaluated
   * @param form Form that contains the navigation rule
   * @param searchStartIndex Index where the navigation rule's form variable exists in the the form.formVariableList
   * @param sectionStack Stack of sections to know in which section the form variable exists.
   *                    NOTE: Updates the passed in variable with sections found (out/ref parameter)
   * @returns Section where the form variable exists
   */
  public getCurrentVariableSection(navRule: FormNavRule, form: Form, searchStartIndex: number, sectionStack: FormVariable[]): FormVariable {
    let formVariableSection: FormVariable = null;
    // Handle special target types
    if (navRule.targetType.code === this.SPECIAL_NAV_TARGET_CODE) {
      // Note: nothing special needed for 'End of Form' since the target won't be found
      // Handle 'End of Section'
      if (navRule.target.navigationFormTargetSpecial.code === this.SPECIAL_NAV_TARGET_END_OF_SECTION) {
        const tempSectionStack: FormVariable[] = [];
        // Find the section the variable is in, so it knows when to stop
        for (let index = 0; index < searchStartIndex; index++) {
          const fv = form.formVariableList[index];
          if (fv.formSectionId > 0) {
            const sectionIndex = tempSectionStack.findIndex(tss => tss.formSectionId === fv.formSectionId);
            if (sectionIndex === -1) {
              tempSectionStack.push(fv);
            }
            else {
              tempSectionStack.pop();
            }
          }
        }
        if (tempSectionStack.length > 0) {
          formVariableSection = tempSectionStack.pop();
          sectionStack.push(formVariableSection);
        }
      }
    }
    return formVariableSection;
  }

  /******************** Individual Navigation Rule Validations ********************/
  /**
   * Validates the Navigation Rule's rule text can be evaluated
   * @param navRule Navigation rule to be validated
   * @param formVariable Form Variable that contains the navigation rule
   * @returns Error if the jexl framework cannot evaluate the rule text
   */
  public validateNavRule_CanBeParsed(navRule: FormNavRule, formVariable: FormVariable): TabbedDataRuleError {
    try {
      jexl.evalSync(navRule.navigationRuleText, {});
    } catch (error) {
      return this.createNavigationError(formVariable.formVariableId, navRule.navigationId, 'Rule contains syntax parsing error.');
    }
    return null;
  }

  /**
   * Validates the Navigation Rule's rule text evaluates to a boolean result
   * @param navRule Navigation rule to be validated
   * @param formVariable Form Variable that contains the navigation rule
   * @returns Error if the jexl framework evaluates the navigation rule to not be a boolean
   */
  public validateNavRule_IsBoolean(navRule: FormNavRule, formVariable: FormVariable): TabbedDataRuleError {
    const jexlResult = jexl.evalSync(navRule.navigationRuleText, {});
    if (typeof jexlResult === 'boolean') {
      return null;
    }
    return this.createNavigationError(formVariable.formVariableId, navRule.navigationId, 'Rule does not evaluate to true/false.');
  }

  /**
   * Validates that the navigation rule's Action and Rule Text have values
   * @param navRule Navigation rule to be validated
   * @param formVariable Form variable that contains the navigation rule
   * @returns Error if Action or Rule text aren't populated, otherwise null
   */
  public validateNavRule_BasicSelections(navRule: FormNavRule, formVariable: FormVariable): TabbedDataRuleError {
    if (!navRule.action
      || navRule.action.code === null
      || !this.helper.variableHasValue(navRule.navigationRuleText)) {
      return this.createNavigationError(formVariable.formVariableId, navRule.navigationId, 'Rule and Action must both be populated.');
    }
    return null;
  }

  /**
   * Validates the Navigation rules' Target Type and Target have values when the action is 'GoTo'
   * @param navRule Navigation rule to be validated
   * @param formVariable Form variable that contains the navigation rule
   * @returns Error if Target Type or Target aren't populated, otherwise null
   */
  public validateNavRule_GoToSelections(navRule: FormNavRule, formVariable: FormVariable): TabbedDataRuleError {
    if (navRule.action.code === 'GOTO'
      && (
        !navRule.targetType
        || !navRule.targetType.code
        || !navRule.target
        || (
          !navRule.target.formVariableId
          && !navRule.target.navigationFormTargetSpecial?.code)
      )
    ) {
      return this.createNavigationError(formVariable.formVariableId, navRule.navigationId, 'For GoTo navigation, Target Type and Target must both be populated.');
    }
    return null;
  }

  /**
   * Validates the navigation rule text only uses variables that come before the given form variable
   * @param navRule Navigation rule to be validated
   * @param formVariable Form variable that contains the navigation rule
   * @param form Form that contains the form variable
   * @returns Error if any variables used in the navigation rule text come after the given form variable
   */
  public validateNavRule_RuleVariablesPrecedeTriggerVariable(
    navRule: FormNavRule, formVariable: FormVariable, form: Form
  ): TabbedDataRuleError {
    const regExp = new RegExp(/\$\d+/g);

    const variablesOutOfBounds: Set<string> = new Set();
    let regMatch: RegExpExecArray;

    do {
      regMatch = regExp.exec(navRule.navigationRuleText);
      if (regMatch) {
        regMatch.forEach(match => {
          const variableID = parseInt(match.slice(1));
          if (variableID !== formVariable.variableId) {
            const formVariableMatch = form.formVariableList.find(fv => fv.variableId === variableID);
            if (formVariableMatch && formVariableMatch.sequenceNum > formVariable.sequenceNum) {
              variablesOutOfBounds.add(match);
            }
            else if (!formVariableMatch) {
              variablesOutOfBounds.add(match);
            }
          }
          else if (navRule.action.code === 'HIDE') {
            variablesOutOfBounds.add(match);
          }
        });
      }
    } while (regMatch);

    if (variablesOutOfBounds.size > 0) {
      const arrayOfVariables = variablesOutOfBounds.values();
      let message = 'The following variables are not defined before the selected variable';
      if (navRule.action.code !== 'HIDE') {
        message += ' and are not the selected variable';
      }
      message += ': ';
      for (let index = 0; index < variablesOutOfBounds.size; index++) {
        const voob = arrayOfVariables.next().value;
        message += voob + (index === variablesOutOfBounds.size - 1 ? '' : ', ');
      }
      return this.createNavigationError(formVariable.formVariableId, navRule.navigationId, message);
    }

    return null;
  }



  /**
   * Validates the navigation rules within the form variable don't have duplicate rules
   * @param formVariable Form Variable who's navigation rules' text should be validated
   * @returns Errors for any GoTo rules with matching rule text
   */
  public validateNavRule_SameRuleSeparateTargets(formVariable: FormVariable): TabbedDataRuleError[] {
    if (formVariable.formVariableNavRule?.navRules?.length > 0) {
      const ruleTexts: FormNavRule[] = [];
      formVariable.formVariableNavRule.navRules.forEach(nr => {
        if (nr.action.code === 'GOTO') {
          const clone = _.cloneDeep(nr);
          clone.navigationRuleText = clone.navigationRuleText.split(' ').join('');
          ruleTexts.push(clone);
        }
      });

      const rulesWithDuplicates: FormNavRule[] = [];
      for (let outerIndex = 0; outerIndex < ruleTexts.length - 1; outerIndex++) {
        const currentRule = ruleTexts[outerIndex];
        for (let innerIndex = outerIndex + 1; innerIndex < ruleTexts.length; innerIndex++) {
          const followingRule = ruleTexts[innerIndex];
          let dupeFound = false;
          if (currentRule.navigationRuleText === followingRule.navigationRuleText) {
            if (_.isEqual(currentRule.targetType, followingRule.targetType) && currentRule.targetType.code === 'SECTION') {
              if (!_.isEqual(
                // Will allow the two targets to be equal if either is set to the section start or end variable
                _.omit(currentRule.target, ['variableDesc', 'formVariableId', '__proto__']),
                _.omit(followingRule.target, ['variableDesc', 'formVariableId', '__proto__']))
              ) {
                dupeFound = true;
              }
            }
            else if (!_.isEqual(currentRule.targetType, followingRule.targetType)
              // Omit prototype to avoid false negatives in isEqual()
              || !_.isEqual(_.omit(currentRule.target, ['__proto__']), _.omit(followingRule.target, ['__proto__']))
            ) {
              dupeFound = true;
            }
            if (dupeFound) {
              if (!rulesWithDuplicates.includes(currentRule)) {
                rulesWithDuplicates.push(currentRule);
              }
              if (!rulesWithDuplicates.includes(followingRule)) {
                rulesWithDuplicates.push(followingRule);
              }
            }
          }
        }
      }

      if (rulesWithDuplicates.length > 0) {
        const errors: TabbedDataRuleError[] = [];

        rulesWithDuplicates.forEach(rwd => {
          errors.push(this.createNavigationError(formVariable.formVariableId, rwd.navigationId, 'The same GoTo rule cannot target more than one variable.'));
        });
        return errors;
      }
    }
    return null;
  }

}
