import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoModalComponent } from 'app/common/modal/info-modal/info-modal.component';
import { ACTIVITY_NOTIFICATION, ACTIVITY_NOTIFICATION_SETUP } from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import { EventArg } from 'app/common/model/event-arg';
import { NotificationWildcard } from 'app/common/model/notification-wildcard';
import { StudyNotification } from 'app/common/model/study-notification';
import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { UserService } from 'app/common/services/user.service';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'mc-message-text',
  templateUrl: './message-text.component.html',
  styleUrls: ['./message-text.component.scss']
})
export class MessageTextComponent implements OnInit, AfterViewInit, OnDestroy {



  isDisplayOn = false;
  /**
   * variable to determine if the subject element has the focus
   */
  subjectFocused = false;

  /**
   * variable to determine if the body element has the focus
   */
  bodyFocused = false;

  subscriptions: Subscription[] = [];

  /**
   * The list of wildcards to display for inserting into the message
   */
  wildcards: NotificationWildcard[];
  wildcardsToDisplay: NotificationWildcard[];
  wildcardCategories: String[];
  messageText: string = "This is Test message";

  private studyId: number = 0;

  constructor(private viewContainer: ViewContainerRef,
    private router: Router,
    private studySetupService: StudySetupService,
    private notificationService: NotificationDashboardService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private userService: UserService) {
    this.subscriptions.push(studySetupService.changeEmitted$.subscribe(
      eventInfo => {
        this.parseEventInfo(eventInfo);
      }));
  }

  /**
   * The editorInstance
   */
  editorInstance: any;

  @ViewChild('subject', { read: ViewContainerRef }) viewChildSubject;

  editorElem: any;


  /**
   * Variable for determining the location of the cursor
   */
  cursorPosition = -1;

  /**
   * Determines if the user selected a group notification from the study page
   */
  isGroupNav = false;

  /**
   * variable for notification object
   */
  currentNotification(): StudyNotification {
    return this.notificationService.currentNotification;
  }

  //Determines  whether user can do editable or not
  isEditable: boolean =false;

  modalTitle:string ;
  modalBody:string ;
  
/**
 * Reference to the child modal component
 */
 @ViewChild(InfoModalComponent)
  infoModalComponent: InfoModalComponent;
  /**
   * This will be called when the notification has been retrieved.
   * @param  {EventArg} eventArg
   */
  parseEventInfo(eventArg: EventArg) {
    if (eventArg.eventName == globalConst.EVENT_DATA_UPDATED) {
      this.isGroupNav = this.notificationService.isNavigatedGroupNotification;
    }
  }


  ngOnInit() {
    this.isGroupNav = this.notificationService.isNavigatedGroupNotification;
    // Get the studyId
    this.route.parent.parent.parent.params.subscribe(params => {
      if (params['studyId']) {
        this.studyId = params['studyId'];
      }
    });


    if(!this.isGroupNav){

      if(this.studyId){ //Study notification
        if(this.userService.hasModifyAccessByStudy(ACTIVITY_NOTIFICATION, this.studyId)){
          this.isEditable=true;
        }else{
          this.isEditable=false;
        }
      }else{// Group notification
        if(this.userService.hasModifyAccess(ACTIVITY_NOTIFICATION_SETUP)){
          this.isEditable=true;
        }else{
          this.isEditable=false;
        }
      }
    }

    this.subscriptions.push(this.notificationService.getNotificationWildcards()
      .subscribe(results => {
        this.wildcards = results as NotificationWildcard[];
        this.wildcardCategories = _.map(_.uniqBy(this.wildcards, 'notificationWildcardCategoryName'), 'notificationWildcardCategoryName');
      })
    );
  }

  /**
  * Destroy implementation - closes all the subscriptions
  */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    )
  }

  /**
   * Return the input for the subject element
   */
  subjectElement(): any {
    if (this.currentNotification()) {
      return this.renderer.selectRootElement(this.viewChildSubject);
    }
    return null;
  }

  /** Returns the Body / editor element*/
  bodyElement(): any {
    if (this.currentNotification() && this.editorInstance) {
      return this.renderer.selectRootElement('.ql-editor');
    }
    return null;
  }


  ngAfterViewInit() {
    this.setFocusSubjectElement();
  }



  /**
   * Sets the focus on the subject element
   */
  setFocusSubjectElement() {
    setTimeout(() => {
      if (this.subjectElement()) {
        this.subjectElement().element.nativeElement.focus();
        this.subjectFocused = true;
      }
    }, 10);
  }

  /**
   * sets the focus on the bodyElement
   */
  setFocusBodyElement() {
    // setTimeout(() => {
    if (this.currentNotification()) {
      this.placeCaretAtEnd(this.editorElem);
      this.bodyFocused = true;
    }
    // }, 10);
  }

  /**
   * HAndles the focus / blur events for the elements on the ui
   * @param elementName the name of the element in or out of focus
   * @param isFocused true if element is in focus,
   */
  inputFocused(elementName: string, isFocused: boolean) {
    // we want to delay a second to allow for the click on the variable icons
    // to insert the correct variable.
    setTimeout(() => {
      this.subjectFocused = (elementName == 'subject' && isFocused);
      this.bodyFocused = (elementName == 'body' && isFocused);
    }, 1000);

  }

  /**
   * True if any of the input elements has the focus AND isGroupNav is false
  */
  isAnyElementFocused(): boolean {
    return (!this.isGroupNav) && (this.bodyFocused || this.subjectFocused);
  }

  /**
   * Get the wildcards to display in cascaded dropdown based on selected category
   * @param category the category selected
   */
  getWildcardsByCategory(category: String) {
    this.wildcardsToDisplay = _.filter(this.wildcards, ['notificationWildcardCategoryName', category]);

    // track the cursor/focus for eventual wildcard placement
    if (this.subjectFocused) {
      // this.currentNotification().subject = this.currentNotification().subject.concat(variableItem.notificationWildcard);
      setTimeout(() => {
        this.setFocusSubjectElement();
      });

    } else if (this.bodyFocused) {
      this.editorInstance.setSelection(this.cursorPosition);
      this.editorInstance.focus();
    }

  }

  /**
   * track the cursor/focus for eventual wildcard placement
   */
  buttonClicked() {
    this.isDisplayOn = !this.isDisplayOn;
    if (this.subjectFocused) {
      setTimeout(() => {
        this.setFocusSubjectElement();
      });

    } else if (this.bodyFocused) {
      this.editorInstance.setSelection(this.cursorPosition);
      this.editorInstance.focus();
    }
  }

  /**
   * Insert the variable in the element in focus
   * @param variable the variable to insert
   */
  insertVariable($event: Event,  variableItem: NotificationWildcard ) { // VariableInsertItem ) {

    if ($event) {
    //  $event.stopPropagation();
    }
    if (this.isEditingDisabled()) {
      return;
    }
    if (!this.isAnyElementFocused()) {
      return false;
    }

    if (this.subjectFocused) {
      this.currentNotification().subject = this.currentNotification().subject.concat(variableItem.notificationWildcard);
      setTimeout(() => {
        this.setFocusSubjectElement();
      });

    } else if (this.bodyFocused) {
      let position = 0; // this.currentNotification().messageText.lastIndexOf("</");// this.cursorPosition;//
      if (this.cursorPosition > -1) {
        position = this.cursorPosition;
      }
      this.editorInstance.insertText(position, variableItem.notificationWildcard)
      this.currentNotification().messageText =this.editorInstance.root.innerHTML;
      setTimeout(() => {
        const newPosition: number = position + variableItem.notificationWildcard.length + 1;
        this.editorInstance.setSelection(newPosition);
        this.editorInstance.focus();
      });
    }
  }

  /**
   * Handler for the editorCreated event
   * @param editorInstance The editor instance
   */
  editorCreated(editorInstance) {
    this.editorInstance = editorInstance;
    //this.editorElem = this.renderer.selectRootElement('.ql-editor');
  }


  /**
   * Places Focus at the end of the selection on the element
   * @param el The element on which the focus needs to be set
   */
  placeCaretAtEnd(el) {
    setTimeout(function () {
      el.focus();
      if (typeof window.getSelection != 'undefined'
        && typeof document.createRange != 'undefined') {
        const range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body['createTextRange'] != 'undefined') {
        const textRange = window['document']['body']['createTextRange'];
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    }, 0);
  }

  /**
   * Handler for the selection changed event
   * @param args The args for the selection changed event on the editor
   */
  editorSelectionChanged(args) {
    if (args) {
      if (args.range) {
        if (args.range.length == 0) {
          this.cursorPosition = args.range.index;
        }
        this.inputFocused('body', true)
      }
    }
  }

  /**
   * This method is called when content is changed on quil editor (Message Text - BODY)
   */
  editorContentChanged(event){
    // Maintaining cursor position when content changed by the user
    if(event?.source === 'user'){
      if(event?.delta?.ops?.[0].retain){
            if(event?.delta?.ops?.[1]?.insert){
            this.cursorPosition=event?.delta?.ops?.[0].retain + event?.delta?.ops?.[1].insert?.length
             this.editorInstance.setSelection(this.cursorPosition);
            }

            if(event?.delta?.ops?.[1]?.delete){
              this.cursorPosition=event?.delta?.ops?.[0].retain - event?.delta?.ops?.[1]?.delete
              this.editorInstance.setSelection(this.cursorPosition);
             }
             this.editorInstance.focus();
      } 

    }
  }

  contentChanged(args) {
    // this code tracks cursor position while typing, but causes strange behavior in quill editor
    // the insertText() sometimes inserts CR/LF in front of non-first wildcards
    // Without this code, must mouse-click where you want the wildcard to be placed
    if (args) {
      if (args.text) {
        if (args.text.length > 0) {
          this.cursorPosition = args.text.length;
          this.editorInstance.setSelection(this.cursorPosition);
          this.editorInstance.focus();
            }
        this.inputFocused('body', true)
      } else {
        this.inputFocused('body', false)
      }
    }
  }


  /********* Pending Changes warning************** */
  /**
   * Implemenation of the canDeactivate that will be called when we navigate away
   *
   */
  canDeactivate(): Observable<boolean> | boolean {
    return this.notificationService.notificationSetup.canDeactivate();
  }
  /*********  Pending Changes warning************** */


  /**
   * Returns True if the current notification is of type "notify"
  */
  isNotificationTypeNotify(): boolean {
    let isNotify = false;
    if (this.currentNotification()) {
      isNotify = this.notificationService.isNotificationTypeNotify(this.currentNotification());
    }
    return isNotify;
  }


  /**
   * Disables editing if it's a notify type notification or isGroupNam
   */
  isEditingDisabled(){
    return this.isNotificationTypeNotify() || this.isGroupNav || !this.isEditable;
  }


  moreInfo(wildCard: NotificationWildcard){
    //Set modal title
    this.modalTitle=wildCard.notificationWildcardDesc;

    //Set  modal body
    this.modalBody = "<div>";
    if (wildCard.additionalInfo) {
      this.modalBody = this.modalBody
        + "<div class='columns'>"
        + "<div class='column is-3 contains-label'>"
        + "<label class='label'>  Additional Info: </label>"
        + "</div>"
        + "<div class='column'>"
        + wildCard.additionalInfo
        + "</div>"
        + "</div>";
    }

    if (wildCard.wildCardTableLocation) {
      this.modalBody = this.modalBody
        + "<div class='columns'>"
        + "<div class='column is-3 contains-label'>"
        + "<label class='label'>  Database/Pulled from: </label>"
        + "</div>"
        + "<div class='column'>"
        + wildCard.wildCardTableLocation
        + "</div>"
        + "</div>"
    }

    if (wildCard.wildCardTableLocation) {
      this.modalBody = this.modalBody
        + "<div class='columns'>"
        + "<div class='column is-3 contains-label'>"
        + "<label class='label'>  Example: </label>"
        + "</div>"
        + "<div class='column'>"
        + wildCard.example
        + "</div>"
        + "</div>";
    }
    this.modalBody = this.modalBody + "</div>";

    this.infoModalComponent.showModal=true;
  }


}
