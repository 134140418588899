<div class="modal person-modal" [ngClass]="{'is-active': showModal}">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Person Selection</p>
        </header>
        <section class="modal-card-body">
            Provide one or more of the following criteria
            <div class="has-search-criteria">
                <div class="field is-horizontal is-grouped">
                    <div class="field-label  ">
                        <label class="">First&nbsp;Name: </label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <input class="input is-small " type="text" [(ngModel)]="searchCriteriaPerson.firstName">
                            </div>
                        </div>
                    </div>
                    <div class="field-label  ">
                        <label class=" ">&nbsp;&nbsp;Last&nbsp;Name: </label>
                    </div>
                    <div class="field-body">
                        <div class="field  has-addons">
                            <div class="control">
                                <input class="input is-small " type="text" [(ngModel)]="searchCriteriaPerson.lastName">
                            </div>
                            <p class="control ">
                                <button class="button is-primary is-small" [disabled]="!isValidSearchCriteria()"
                                    (click)="findPersonMatches()">
                                    Search
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="persons.length > 0">
                <div class="has-fixed-table-header form-table-container">
                    <table class="is-scroll-table header table is-striped is-fullwidth " role="grid">
                        <thead>
                            <tr>
                                <ng-container *ngFor="let column of personColumns">
                                    <th *ngIf="column.display" class="table-header " [mcTableSorting]="personConfig"
                                        [column]="column" title="{{column.title}}" (sortChanged)="onChangePersonTable($event)"
                                        ngClass="{{column.className || ''}}">
                                        <span> {{column.title}}</span>
                                        <span *ngIf="personRows.length > 0 && personConfig && column.sort != undefined"
                                            class="sort  is-text-centered">
                                            <span *ngIf="column.sort === ''">
                                                <i class="fas fa-sort"></i>
                                            </span>
                                            <span *ngIf="column.sort === 'desc'">
                                                <i class="fas fa-sort-down"></i>
                                            </span>
                                            <span *ngIf="column.sort === 'asc'">
                                                <i class="fas fa-sort-up"></i>
                                            </span>
                                        </span>
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="showPersonFilterRow">
                                <ng-container *ngFor="let column of personColumns">
                                    <td *ngIf="column.display">
                                        <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
                                            [mcTableFiltering]="column.filtering" class="form-control filter-width"
                                            (tableChanged)="onChangePersonTable(personConfig)" />
                                    </td>
                                </ng-container>
                            </tr>
                            <tr *ngFor="let row of personRows" (click)="selectPerson(row)" [ngClass]="{'is-selected': isPersonSelected(row)}"
                                id="{{row.pk}}">
                                <td class="has-fixed-cell-width">
                                    {{row.firstName}}
                                </td>
                                <td class="has-fixed-cell-width">
                                    {{row.lastName}}
                                </td>
                                <td>
                                    {{row.email}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button is-primary is-radiusless" [disabled]="selectedPerson==null" (click)="setSelectedPerson()">Select</button>
            <button class="button is-radiusless" (click)="cancelSelectedPerson()">Cancel</button>
        </footer>
    </div>
</div>