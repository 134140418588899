<div class="modal" [ngClass]="{'is-active': showModal}">
  <div class="modal-background"></div>
  <div class="modal-card ">
    <header class="modal-card-head">
      <p class="modal-card-title modal-card-title-font">
        {{title}}
        <button class="delete is-pulled-right" aria-label="close" type="button" (click)="closeModal()">
        </button>
      </p>
    </header>

    <div class="modal-card-body">
      <div *ngIf="this.errorMessage" class="error-div">
        <span class="error">{{this.errorMessage || "&nbsp;"}} </span>
      </div>
      <div *ngIf="isAttributeTile">
        <div class="field">
          <div class="field-label is-normal has-text-left">
            <label>Study Attributes - Required </label>
          </div>
          <div class="control">
            <mc-search-input [options]="studyAttributes" placeholder="Study Attributes - Required  "
              (selectedOptionsChange)="handleAttributeSelection($event)"></mc-search-input>
          </div>
        </div>

        <div class="field">
          <div class="field-label is-normal has-text-left">
            <label>Research Entity - Optional </label>
          </div>
          <div class="control">
            <div class="select is-fullwidth" (click)="ignoreClick($event)">
              <select [(ngModel)]="studyAttributeRequest.researchEntity">
                <option [ngValue]="null" selected>-- Select --</option>
                <option *ngFor="let item of researchEntity" [ngValue]="item">
                  {{item.name}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <div class="field-label is-normal has-text-left">
            <label>Study Status - Optional </label>
          </div>
          <div class="control">
            <div class="select is-fullwidth " (click)="ignoreClick($event)">
              <select [(ngModel)]="studyAttributeRequest.studyStatus">
                <option [ngValue]="null" selected>-- Select --</option>
                <option *ngFor="let item of studyStatuses" [ngValue]="item">
                  {{item.name}}
                </option>
              </select>
            </div>
          </div>
        </div>


      </div>

      <div *ngIf="isSchemaReport()">
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="control">
                <div class="field-label is-normal has-text-left">
                  <label class="">Registration Events - Required</label>
                </div>
                <mc-search-input placeholder="Click here to select or type" [options]="schemaEvents"
                  (selectedOptionsChange)="handleCriteriaChange(schemaReportCriteria.schemaEvents, $event, 'schemaEvents')"></mc-search-input>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="control">
                <div class="container">
                  <div class="field-label is-normal has-text-left">
                    <label class="">Intervention - Optional</label>
                  </div>
                  <div class="select" (click)="ignoreClick($event)">
                    <select [(ngModel)]="interventionSelected" (change)="interventionChange()">
                      <option [ngValue]="null">-- Select --</option>
                      <option *ngFor="let item of interventionOptions" [ngValue]="item">
                        {{item.name}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="field-label is-normal has-text-left">
                <label>Research Entities - Optional </label>
              </div>
              <mc-search-input placeholder="Click here to select or type" [options]="researchEntity"
                (selectedOptionsChange)="handleCriteriaChange(schemaReportCriteria.researchEntities, $event, 'researchEntities')"></mc-search-input>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="field-label is-normal has-text-left">
                <label>Study Statuses - Optional </label>
              </div>
              <mc-search-input placeholder="Click here to select or type" [options]="studyStatuses"
                (selectedOptionsChange)="handleCriteriaChange(schemaReportCriteria.studyStatuses, $event,  'studyStatuses')"></mc-search-input>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="control">
                <div class="container">
                  <mat-checkbox color="primary" (change)="attributeCheckChange($event,'StratFactorBlinded' )">Blinded
                    Strat Factor? -
                    Optional</mat-checkbox><br>
                  <mat-checkbox color="primary" (change)="attributeCheckChange($event,'specialsf' )">Arm Balancing
                    Stratification Factor? -
                    Optional​</mat-checkbox><br>
                  <mat-checkbox color="primary" (change)="attributeCheckChange($event,'GfAssignmentForm' )"> Use the
                    Intervention assignment form to
                    assign treatment? - Optional​</mat-checkbox>
                  <div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isStudy() && !isAttributeTile" class="field is-horizontal">
        <div class="field-label is-normal has-text-left">
          <label class="">Study: </label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input is-expanded " type="text" name="protocolID" id="protocolID" [(ngModel)]="txtStudyID"
                (ngModelChange)="checkSourceProtocolProvided()">
            </div>
          </div>
        </div>

        <div *ngIf="isDifferenceReport()" class="field">
          <div class="control">
            <button class="button is-primary is-radiusless" (click)="getFormInfo()"
              [disabled]="isFindButtonDisabled()">Find Forms</button>
          </div>
        </div>
      </div>

      <div *ngIf="isDifferenceReport()">
        <div>
          <div class="field is-grouped">
            <label class="">Source (Old): &nbsp;&nbsp;</label>

            <div class="control">
              <div class="select is-small " (click)="ignoreClick($event)">
                <select [(ngModel)]="oldForm" (change)="getNewFormDropdownValues()" [compareWith]="this.compareFn">
                  <option [ngValue]="null">-- Select --</option>
                  <option *ngFor="let item of forms" [ngValue]="item">
                    Form # {{item.studyFormNum}} - {{item.formName}} - Version {{item.versionNum}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p>&nbsp;</p>
        </div>

        <div>
          <div class="field is-grouped">
            <label class="">Target (New): &nbsp;</label>

            <div class="control">
              <div class="select is-small " (click)="ignoreClick($event)">
                <select [(ngModel)]="newForm" [compareWith]="this.compareFn">
                  <option [ngValue]="null">-- Select --</option>
                  <option *ngFor="let item of filteredForms" [ngValue]="item">
                    Form # {{item.studyFormNum}} - {{item.formName}} - Version {{item.versionNum}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p>&nbsp;</p>
        </div>

      </div>

      <div *ngIf="isStudyNetworkReport()" class="study-network-report">
        <div class="row">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <div class="field-label is-normal has-text-left">
                  <label>Research Entities - Required </label>
                  <a class="is-pulled-right" href="javascript:" (click)="toggleInfoTooltip('researchEntities')">
                    <i class="fas fa-info"></i>
                  </a>
                </div>
                <mc-search-input placeholder="Click here to select or type" [options]="researchEntity"
                  (selectedOptionsChange)="handleCriteriaChange(studyNetworkReportCriteria.researchEntities, $event, 'researchEntities')"></mc-search-input>
              </div>
            </div>
          </div>

          <div class="tooltip" *ngIf="studyNetworkReportCriteria.researchEntitiesTooltipOpen">
            <p>Let's you select multiple research entities saving each selection as a chip. An OR will be used between
              each research entity selected when pulling the data.​</p>
            <a href="javascript:" (click)="toggleInfoTooltip('researchEntities')"><strong>X</strong></a>
          </div>
        </div>

        <div class="row">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <div class="field-label is-normal has-text-left">
                  <label>Study Status(es) - Optional </label>
                  <a class="is-pulled-right" href="javascript:" (click)="toggleInfoTooltip('studyStatuses')">
                    <i class="fas fa-info"></i>
                  </a>
                </div>
                <mc-search-input placeholder="Click here to select or type" [options]="studyStatuses"
                  (selectedOptionsChange)="handleCriteriaChange(studyNetworkReportCriteria.studyStatuses, $event,  'studyStatuses')"></mc-search-input>
              </div>
            </div>
          </div>

          <div class="tooltip" *ngIf="studyNetworkReportCriteria.studyStatusesTooltipOpen">
            <p>Let's you select multiple study statuses saving each selection as a chip. An OR will be used between each
              study status selected when pulling the data.</p>
            <a href="javascript:" class="is-pulled-right"
              (click)="toggleInfoTooltip('studyStatuses')"><strong>X</strong></a>
          </div>
        </div>

        <div class="row">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <div class="field-label is-normal has-text-left">
                  <label>Participating Organizations - Optional </label>
                  <a class="is-pulled-right" href="javascript:" (click)="toggleInfoTooltip('participatingOrgs')">
                    <i class="fas fa-info"></i>
                  </a>
                </div>
                <mc-search-input placeholder="Click here to select or type" [options]="participatingOrgs"
                  (selectedOptionsChange)="handleCriteriaChange(studyNetworkReportCriteria.participatingOrgs, $event,  'participatingOrgs')"></mc-search-input>
              </div>
            </div>
          </div>

          <div class="tooltip" *ngIf="studyNetworkReportCriteria.participatingOrgsTooltipOpen">
            <p>Let's you select multiple Participating Organizations saving each selection as a chip. An AND will be
              used between each Participating Organization selected when pulling the data.</p>
            <a href="javascript:" class="is-pulled-right"
              (click)="toggleInfoTooltip('participatingOrgs')"><strong>X</strong></a>
          </div>
        </div>

        <div class="row">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <div class="field-label is-normal has-text-left">
                  <label>Participating Org Status(es) - Optional </label>
                  <a class="is-pulled-right" href="javascript:" (click)="toggleInfoTooltip('participatingOrgStatuses')">
                    <i class="fas fa-info"></i>
                  </a>
                </div>
                <mc-search-input placeholder="Click here to select or type" [options]="participatingOrgStatuses"
                  (selectedOptionsChange)="handleCriteriaChange(studyNetworkReportCriteria.participatingOrgStatuses, $event,  'participatingOrgStatuses')"></mc-search-input>
              </div>
            </div>
          </div>

          <div class="tooltip" *ngIf="studyNetworkReportCriteria.participatingOrgStatusesTooltipOpen">
            <p>Let's you select multiple Participating Organizations statuses saving each selection as a chip. An OR
              will be used between each Participating Organizations status selected when pulling the data.</p>
            <a href="javascript:" class="is-pulled-right"
              (click)="toggleInfoTooltip('participatingOrgStatuses')"><strong>X</strong></a>
          </div>
        </div>

        <div>
          <p>&nbsp;</p>
        </div>
      </div>


      <footer class="modal-card-foot ">
        <div class=" has-footer-buttons ">
          <div *ngIf="showGenerateButton">
            <button class="button is-primary is-radiusless" [disabled]="noSelections()" type="submit"
              (click)="generateReport()">
              Generate </button>
            <button class="button is-radiusless" type="button" (click)="closeModal()">
              Cancel</button>
          </div>
          <div *ngIf="showDownloadButton">
            <button class="button is-primary is-radiusless" [disabled]="noSelections()" type="submit"
              (click)="download()">
              Download </button>
            <button class="button is-radiusless" type="button" (click)="closeModal()">
              Cancel</button>
          </div>
          <div *ngIf="showGenerateButton2">
            <button class="button is-primary is-radiusless" [disabled]="noSelections()" type="submit"
              (click)="generateReport2()">
              Generate </button>
            <button class="button is-radiusless" type="button" (click)="closeModal()">
              Cancel</button>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>