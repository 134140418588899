import { AfterViewInit, EventEmitter } from '@angular/core';
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'app/common/base/base-component';
import { Code } from 'app/common/model/code';
import { StudyPersonType } from 'app/common/model/study-person-type';
import { StudySiteLevel } from 'app/common/model/study-site-level';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { UserService } from 'app/common/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-shared-site-and-person',
  templateUrl: './site-and-person.component.html',
  styleUrls: ['./site-and-person.component.scss']
})
export class SiteAndPersonComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() siteLevelCodes: Code[];
  @Input() networkSiteLevelList: StudySiteLevel[];
  @Input() nonNetworkSiteLevelList: StudySiteLevel[];
  @Input() studyPersonTypes: StudyPersonType[];
  @Input() isNetworkParticipation: boolean = false;
  @Input() isNonNetworkParticipation: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() hasErrors: boolean = false;

  @Output() isNetworkParticipationChange = new EventEmitter<boolean>();
  @Output() isNonNetworkParticipationChange = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];

  /**
   * Names of properties on this component to avoid working with strings
   */
  private propertyNetworkList = 'networkSiteLevelList';
  private propertyNonNetworkList = 'nonNetworkSiteLevelList';

  constructor(
    studySetupService: StudySetupService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.siteLevelCodes || this.siteLevelCodes.length === 0) {
      this.getSiteLevelCodes();
    }
  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    );
  }

  ///// Get Data
  getSiteLevelCodes(): void {
    this.subscriptions.push(
      this.studySetupService.getSiteLevelCodes()
        .subscribe(results => {
          this.siteLevelCodes = results;
        })
    );
  }

  ///// Event Handlers
  /**
   * Verify if all the Network options are set to No.
   * If so - then uncheck the Network checkbox
   */
  noClicked(): void {
    let atLeastOneChecked = false;
    setTimeout(() => {
      this.networkSiteLevelList.forEach(eachItem => {
        if (!atLeastOneChecked && eachItem.required) {
          atLeastOneChecked = true;
        }
      });
      if (!atLeastOneChecked) {
        this.isNetworkParticipation = false;
      }
    }, 15);
  }

  /**
   * resets the required flags if the checkbox is deselected
   * @param $event the event that was raised
   * @param isNetwork true if it's the network checkbox, false if it's the non-network checkbox
   */
  networkOptionSelected($event: Event, isNetwork: boolean): void {
    if ($event) {
      $event.stopPropagation();
    }

    let listProperty, uncheckListProperty, f;
    if (!!isNetwork) { // Network participation
      listProperty = this.propertyNetworkList;
      uncheckListProperty = this.propertyNonNetworkList;
      this.isNonNetworkParticipation = false;
    } else { // Direct participation
      listProperty = this.propertyNonNetworkList;
      uncheckListProperty = this.propertyNetworkList;
      this.isNetworkParticipation = false;
    }

    f = () => {
      this[uncheckListProperty].forEach((item) => {
        item.required = false;
      });
      this[listProperty].forEach((item) => {
        if (this.isTreatingLocationSiteLevel(item)) {
          item.required = true;
        } else {
          item.required = false;
        }
      });
    };

    setTimeout(f);
    this.isNetworkParticipationChange.emit(this.isNetworkParticipation);
    this.isNonNetworkParticipationChange.emit(this.isNonNetworkParticipation);
  }

  ///// UI
  /**
   *  Returns true if the site level item a 'Treating Location'
   */
  isTreatingLocationSiteLevel(item): boolean {
    return (!!item.siteLevel && 'TLC' === item.siteLevel.code);
  }
}
