import { Directive, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[mcScroller]'
})
export class ScrollerDirective implements OnInit {
  // array of spied Elements positions
  @Input() spiedElements: any;
  timer: any;

  ngOnInit() {
  }

  @HostListener('scroll', ['$event']) scrolling($event: Event) {
    // Get the index based on the spiedElements = array of positions = this.spiedElements
    // Current position = $event.srcElement.scrollTop;
    // Use cleartimeout to not invoke the calls till a scroll pause
    console.log(this.spiedElements);
    // console.log(Array.isArray(this.spiedElements) + '---- typeof = ' + typeof(this.spiedElements));
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let spiedPositions = this.spiedElements;
      if (typeof (this.spiedElements) == 'string') {
        spiedPositions = this.spiedElements.split(',');
      }
      const currentPosition = 0; // $event..srcElement..scrollTop;

      let index = spiedPositions.findIndex((currElement) => {
        return currentPosition < currElement;
      })
      if (index >= 1) { index--; }
      if (index == -1) { index = spiedPositions.length - 1; }
      console.log('index : ' + index);

      // this.removeAllSectionLinkActive();
      // this.setSectionLinkActive(index);
    }, 100);

  }

  @HostListener('click', ['$event']) clicking($event: Event) {
    // console.log('clicking...', $event);
    // let element = $event.srcElement;
    // let parentID = element.closest('.form-section').id;
    // console.log(parentID)

  }

  constructor() { }

}
