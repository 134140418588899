import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Organization } from 'app/common/model/organization';
import { StudySiteLevel } from 'app/common/model/study-site-level';
import { SubjectReservation } from 'app/common/model/subject-reservation';
import * as moment from 'moment';

@Component({
  selector: 'mc-reservation-confirmation',
  templateUrl: './reservation-confirmation.component.html',
  styleUrls: ['./reservation-confirmation.component.scss']
})
export class ReservationConfirmationComponent implements OnInit {

  @Input()  subjectReservation: SubjectReservation;
  @Input()  siteLevelList: StudySiteLevel[];
  @Input()  studyNumber: String;
  @Input()  studyDesc: String;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  isReservationExist() {
    if (this.subjectReservation != null) {
      return true;}
    else {
      return false;
    }
  }

  getContactStaffName() {
    if (this.subjectReservation && this.subjectReservation.contactStaff) {
      if(this.subjectReservation.contactStaff.fullName){
        return this.subjectReservation.contactStaff.fullName;
      }
      return this.subjectReservation.contactStaff.firstName + " " + this.subjectReservation.contactStaff.lastName;
    }
  }

  getPatientName() {
    if (this.subjectReservation && this.subjectReservation.patientInfo) {
      var name = this.subjectReservation.patientInfo.lastName + " " + this.subjectReservation.patientInfo.firstName;
      if (this.subjectReservation.patientInfo.middleName != null && this.subjectReservation.patientInfo.middleName.length > 0) {
        name = name + " " + this.subjectReservation.patientInfo.middleName;
      }
      return name;
    }
  }

  getSiteLevelName(item: Organization) {
    let siteLevel = this.siteLevelList.filter(x => x.siteLevel.code == item.siteLevelCode);
    return siteLevel[0].siteLevel.name;
  }

    getDateDataFormatted(dob: Date): string {
      return moment(dob).format('MM/DD/YYYY'); 
  
    }
  
}
