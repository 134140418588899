import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import * as ACTIVITY_NAMES from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { SearchService } from '../../common/services/search.service';
import { UserService } from '../../common/services/user.service';
import { SearchCriteria } from '../../common/model/search-criteria';
import { SearchInfo } from '../../common/model/search-info';
import { SlotReservationPreviewRecord } from 'app/common/model/slot-reservation-preview-record';

@Component({
  selector: 'mc-slot-reservations',
  templateUrl: './slot-reservations.component.html',
  styleUrls: ['./slot-reservations.component.scss']
})
export class SlotReservationsComponent implements OnInit, OnDestroy {

  searchCriteria: SearchCriteria = null;
  subscriptions: Subscription[] = [];

  @Input() hostLandingPageEnum = 0;
  @Output() returnToSearchEvent = new EventEmitter();


  studyId = 0;

  results: SlotReservationPreviewRecord[];
  rows: SlotReservationPreviewRecord[];
  noResultsFound = false;
  isActiveListTitle = false;

  /**
   * The search criteria that identifies the type of patient search
   */
  currentSearchCriteria: SearchInfo = null;
  showFilterRow = true;

  public columns: Array<any> = [
    {
      title: 'Reservation Id', name: 'studyReservationId',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Id' }
    },
    {
      title: 'Current State', name: 'currentState',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by State' }
    },
    {
      title: 'Initials', name: 'initials',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Initals' }
    },
    {
      title: 'Slot Group Description', name: 'slotGroupDescription',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Description' }
    },
    {
      title: 'State Date', name: 'currentStateDate',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Date' }
    },
    {
      title: 'Expiration Date', name: 'expirationDate',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Date' }
    },
    {
      title: 'Treating Location', name: 'treatingLocation',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Treating Location' }
    },
    {
      title: 'Site Contact', name: 'siteContact',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Contact' }
    }
  ];

  /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering:  { filterString: '' },
    className: ['is-striped', 'is-bordered']
  };

  constructor(private searchService: SearchService,
    private userService: UserService,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private router: Router) {

  }


  ngOnInit() {
      this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params['studyId']) {
          this.studyId = params['studyId'];
        }
      }));



    this.subscriptions.push(this.searchService.searchQuery$.subscribe(
      criteria => {
        this.currentSearchCriteria = this.searchService.getSearchQuery(this.hostLandingPageEnum)//  this.searchService.currentSearchQuery;
        this.searchCriteria = null;
        if (criteria && criteria.searchType == globalConst.SEARCH_CRITERIA_TYPE_SLOT_RESERVATIONS) {
          this.searchCriteria = criteria;
          this.isActiveListTitle = false;
          this.getSlotReservations();
        } else {
          this.results = null;
        }
      }
    ))
  }



  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }



  /**
   * Calls the service to fetch the slot reservations for the study
   */
  private getSlotReservations() {
    this.subscriptions.push(
      this.searchService.getSlotReservations(this.studyId)
        .subscribe(res => {
          this.results = res;
           this.noResultsFound = (this.results.length == 0);
          this.onChangeTable(this.config);
        }));
  }


  /**
   * Navigates to the Manage slot reservation page
   * @param $event
   * @param patientResult
   */
  goToManageSlotReservation($event: Event, slotReservation: SlotReservationPreviewRecord) {
   
    if (this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_SLOT_RESERVATION, this.studyId)) {
      if ($event) {
        $event.stopPropagation();
      }
      const path = `studies/${this.studyId}/patients/reservation/${slotReservation.studyReservationId}`;
      this.router.navigate([path], {
      queryParams: {
        'navBack': this.hostLandingPageEnum
      }
     });
    } else {
      console.log ('not authorize to manage reservation page for this study')
     }
  }
 

 

  returnToSearch() {
    this.fireEvent();
  }

  fireEvent() {
    this.returnToSearchEvent.emit();
  }

  /**
 * Sorts the data in the table based on the column and sort order
 * @param data The data currently being displayed on the table
 * @param config The associated configuration for the data being displayed
 */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousVal: any;
      let currentVal: any;

      // If the following columns are used, then need to get different values for previous and current
      if (columnName === 'currentStateDate') { // date values
        previousVal = previous[columnName] ? new Date(previous[columnName]) : new Date(0);
        currentVal = current[columnName] ? new Date(current[columnName]) : new Date(0);
      } else  if (columnName === 'expirationDate') { // date values
        previousVal = previous[columnName] ? new Date(previous[columnName]) : new Date(0);
        currentVal = current[columnName] ? new Date(current[columnName]) : new Date(0);
      } else if (columnName === 'studyReservationId') { // integer values
        previousVal = previous[columnName] ;
        currentVal = current[columnName] ;
      } else { // simple values
        previousVal = previous[columnName] ? previous[columnName].toLowerCase() : '';
        currentVal = current[columnName] ? current[columnName].toLowerCase() : '';
      }

      if (previousVal > currentVal) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousVal < currentVal) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
  * Main method to handle filtering, sorting of the table
  * @param config Configuration of the datatable
  * @param page The current page being displayed
  */
  public onChangeTable(config: any): any {
    let column: any = null;
    this.rows = [];
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.results, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;

  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (column.name === 'currentStateDate' && item['currentStateDate'] ) {
            const regDateString = moment(item['currentStateDate']).format('MM/DD/YYYY');
            return regDateString.match(column.filtering.filterString);
          } else    if (column.name === 'expirationDate' && item['expirationDate'] ) {
            const expDateString = moment(item['expirationDate']).format('MM/DD/YYYY');
            return expDateString.match(column.filtering.filterString);
          } else {
            if (item[column.name] != null) {
              return ((item[column.name]) + '').toLowerCase().match(column.filtering.filterString.toLowerCase());
            }
          }

          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });



    return filteredData;
  }
}
