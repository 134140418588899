<div class="study-header-bar" *ngIf="headerModel" #topHeaderDiv>
  <div class="columns">
    <div class="column is-narrow no-left-padding">
      <h1 *ngIf="headerModel.caption">
        {{headerModel.caption}} 
        <span *ngIf="headerModel.captionValue">
          <span *ngIf="!headerModel.canNavigate">
            {{headerModel.captionValue}}
          </span>
          <a *ngIf="headerModel.canNavigate" (click)="navigate()">
            <span>{{headerModel.captionValue}}</span>
          </a>
        </span>      
      </h1>
    </div>

    <div class="column is-no-right-padding is-clipped">
      <h1 class="header-description" #headerDescription          [innerHTML]="headerModel.description" > 
      </h1>
    </div>

    <div *ngIf="isTextOverflowing()" class="column is-narrow is-no-left-padding">
      <div class="tool-tip-position">
        <span class="icon is-white"  (click)="toggleTooltipDisplay($event)">
          <i class="fas fa-info-circle"></i>
        </span>
        <div *ngIf="toolTipShow" class="has-desc tool-tip">
          <a class="close-button" (click)="toggleTooltipDisplay($event)">
            <span class="icon">
              <i class="fas fa-window-close"></i>
            </span>
          </a>
          {{headerModel.description}}
        </div>
        <div *ngIf="toolTipShow" class="arrow-up" 
          (click)="toggleTooltipDisplay($event)"></div>
      </div>
    </div>
    <div class="column is-narrow is-save"   *ngIf="headerModel.showButtons"
     [ngClass]="{'has-content-disabled' : areButtonsDisabled() }" >
      <button class="button is-small is-custom-save is-fixed-size is-radiusless" type="submit"
      [attr.disabled]="areButtonsDisabled() ? '' : null" 
      (click)="saveChanges($event)">Save</button>
      <button class="button is-small is-cancel is-fixed-size is-radiusless"
        [attr.disabled]="areButtonsDisabled()? '' : null" 
       (click)="cancelChanges($event);">Cancel</button>
    </div>
  </div>
</div><!-- /.study-header-bar --> 
<mc-error-validation-display></mc-error-validation-display>
