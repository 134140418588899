import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/common/shared.module';
import { FormsModule } from '@angular/forms';
import { TypeProtocolComponent } from './type-protocol/type-protocol.component';
import { TypeAncillaryComponent } from './type-ancillary/type-ancillary.component';
import { StudyCreateComponent } from './study-create.component';
import { TypeMultiSubjectComponent } from './type-multi-subject/type-multi-subject.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  declarations: [
    StudyCreateComponent,
    TypeProtocolComponent,
    TypeAncillaryComponent,
    TypeMultiSubjectComponent
  ],
  exports: [
    StudyCreateComponent,

  ]
})
export class StudyCreateModule { }
