<div class="notification-type">
  <div class="config-header">
    <h3>Configure Group Notification</h3>
  </div>

  <div class="main-content" *ngIf="currentNotification()">
    <div class="columns">
      <div class="column is-3">
        <span>Notification Type</span>
      </div>

      <div class="column is-5 field">
        <div class="control">
          <div class="select">
            <select [(ngModel)]="currentNotification().notificationType" [compareWith]="this.compareFn" 
            (change)="notificationTypeChanged()" [disabled] ="isEditingDisabled()">
              <option value=null selected>-- Select --</option>
              <option *ngFor="let nType of notificationTypesList" [ngValue]="nType">
                {{nType.value}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
    <div *ngIf="isValidNotificationSelected()">

      <!---   EVENTS -->
      <div class="columns" *ngIf="shouldShowEvents()">
        <div class="column is-3">
          <span>Schema Event</span>
        </div>


        <div class="column is-5 field" *ngIf="shouldShowEvents()">
          <!--     *ngIf="isTypeConfirmation()"> -->
          <ul>
            <li *ngFor="let evt of displayEvents; let rowIdx = index">
              <label class="checkbox" >
                <input type="checkbox" name="regEvt-{{rowIdx}}" 
                (click)="eventSelectionClicked()" [(ngModel)]="selectedEventList[rowIdx]" [disabled] ="isEditingDisabled()"> 
                  {{evt.name}}
              </label>
            </li>
          </ul>
        </div>
        <div class="column"></div>
      </div>
      <!--- EVENTS -->



      <div class="columns is-horizontal" *ngIf="isTypeApproval()">

        <div class="column is-3 ">
          <span>Notify At</span>
        </div>

        <div class="column is-4 field">
          <ul>
            <li *ngFor="let apprType of approvalTypesList">
              <label class="checkbox" >
                <input type="checkbox"  
                [ngModel]="isSelected(apprType)" 
                (ngModelChange)="toggleSelection($event, apprType)" [disabled] ="isEditingDisabled()"> 
                  {{apprType.desc}}
              </label>
            </li>
          </ul>
        </div>

      </div>
      
      
        <div class="columns is-horizontal" *ngIf="isTypeAccrual() || isTypeRandomizationCheck()">

        <div class="column is-3 ">
          <span>Notify At</span>
        </div>

        <div class="column is-4 field">
          <div class="control">
            <div class="select">
              <select [(ngModel)]="currentNotification().notificationAccrualDetail.accrualPointType" [compareWith]="this.compareFn" [disabled] ="isEditingDisabled()">
                <option value=null selected>-- Select --</option>
                <option *ngFor="let nType of accrualPointsList" [ngValue]="nType">
                  {{nType.value}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column"></div>

        <div class="column is-8" *ngIf="currentNotification().notificationAccrualDetail.accrualPointType && currentNotification().notificationAccrualDetail.accrualPointType.value === 'Every (x) Patients'">
          <div class="columns">
            <span class="column is-3">For Every:</span>
            <div class="column ">
              <div class="field is-horizontal">
                <div class="control ">
                  <input class="form-control input" placeholder="number" id="for-every" name="forEvery" [(ngModel)]="currentNotification().notificationAccrualDetail.accrualPoint" [disabled] ="isEditingDisabled()">
                </div>&nbsp;&nbsp;
                <span>Patients</span>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-8" *ngIf="currentNotification().notificationAccrualDetail.accrualPointType && currentNotification().notificationAccrualDetail.accrualPointType.value === 'a (%) of Patients'">
          <div class="columns">
            <div class="column">
              <div class="field is-horizontal">
                <div class="control ">
                  <input class="form-control input" placeholder="number" id="percent" name="percent" [(ngModel)]="currentNotification().notificationAccrualDetail.accrualPoint" [disabled] ="isEditingDisabled()">
                </div>&nbsp;&nbsp;
                <span>%</span>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-8" *ngIf="currentNotification().notificationAccrualDetail.accrualPointType && currentNotification().notificationAccrualDetail.accrualPointType.value === '(xth) Patient'">
          <div class="columns">
            <div class="column is-4">
              <div class="field is-horizontal">
                <div class="control ">
                  <input class="form-control input" placeholder="number" id="xth-patient" name="xthPatient" [(ngModel)]="currentNotification().notificationAccrualDetail.accrualPoint" [disabled] ="isEditingDisabled()">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!---- audit notification -------->
    <div class="columns is-horizontal" *ngIf="isTypeAudit()">
      <div class="column is-3">
        <span>Schema Event</span>
      </div>

      <!-- 
                [checked]="currentNotification().groupNotificationEventList[0] && currentNotification().groupNotificationEventList[0].codeId == auditEvt.codeId" -->
      <div class="column is-5 field">
        <ul>
          <li *ngFor="let auditEvt of displayAuditEvents" >
            <label class="radio">
              <input type="radio" 
                name="auditEvent" 
                [(ngModel)]="currentNotification().groupNotificationEventList[0].codeId" 
                [value]="auditEvt.codeId" 
                (click)="auditEventSelectionClicked(auditEvt)"
                [disabled]="isEditingDisabled()"> 
                {{auditEvt.name}}  
            </label>
          </li>
        </ul>
      </div>
      <div class="column"></div>

    </div>

    <div class="columns is-horizontal" *ngIf="isTypeAudit()">
      <div class="column is-3">
        <span>By Level</span>
      </div>

      <!--[checked]="currentNotification().notificationAccrualDetail.notificationByLevel  && currentNotification().notificationAccrualDetail.notificationByLevel.id == lvl.id"-->
      <div class="column is-5 field">
        <ul>
          <li *ngFor="let lvl of displayAuditLevels" >
            <label class="radio">
              <input type="radio" 
                     name="auditLevel" 
                     [(ngModel)]="currentNotification().notificationAccrualDetail.notificationByLevel.id" 
                     [value]="lvl.id" 
                     (click)="auditLevelSelectionClicked(lvl)"
                     [disabled]="isEditingDisabled()"> 
                     {{lvl.value}}  
            </label>
          </li>
        </ul>
      </div>
      <div class="column"></div>
    </div>
    <!---- audit notification -------->

    <div class="columns ">
      <div class=" column is-3 ">
        <div class=" field is-horizontal ">
          <span>Notes</span>
        </div>
      </div>
      <div class="column is-7 ">
        <div class="field is-horizontal ">
          <div class="field-body text-field-padding ">
            <div class="field ">
              <div class="control ">
                <textarea placeholder="Notes " name="notes " id="body " class="textarea width-100-percent " rows="6
        " [(ngModel)]="currentNotification().notes " [disabled]="isEditingDisabled()"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>