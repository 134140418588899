import { Component, OnInit, Input } from '@angular/core';
import { FormVariable } from 'app/common/model/form-variable';

@Component({
  selector: 'mc-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

  @Input() formVariable: FormVariable

  constructor() { }

  ngOnInit() {
  }

}
