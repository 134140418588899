<div *ngFor="let item of siteLevelList; let i=index">
  <div *ngIf="item.required" class="columns" #siteInfo>
    <ng-container *ngIf="isSiteLevel(item, SITE_CODE_CREDIT_GROUP) ">
      <div class="field is-option-div" class=" column is-3">
        <label class="label is-size-7">
          {{item.siteLevel.name}}
        </label>
      </div>
      <div class="column ">
        <div *ngIf="isSiteDataReadonly() " class="is-read-only-label">
          <span>
            {{getSiteName(item, i)}}
          </span>
          
        </div>
        <div *ngIf="!isSiteDataReadonly()">

          <div *ngIf="!isNonStdCreditGroup()">
            <div class="select">
              <select [(ngModel)]="currentRegModel().participatingSites[i]"
                (change)="creditGroupSelected(i)" [compareWith]="compareFnCg">
                <option [ngValue]=null selected>Select..</option>
                <option *ngFor="let singleOrg of sortOrgList(creditGroupOrgList)" [ngValue]="singleOrg">
                  {{singleOrg.name}}
                </option>
              </select>
            </div>
        </div>

         <div *ngIf="isNonStdCreditGroup()">
            <div class="select">
              <select [(ngModel)]="currentRegModel().participatingSites[i]"
                (change)="creditGroupSelected(i)" [compareWith]="compareFnNonStd">
                <option [ngValue]=null selected>Select....</option>
                <option *ngFor="let singleOrg of sortOrgList(creditGroupOrgList)" [ngValue]="singleOrg">
                  {{singleOrg.name}}
                </option>
              </select>
            </div>
        </div>


        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isSiteLevel(item, SITE_CODE_MEMBERSHIP) && !isNonStdCreditGroup() ">
      <div class="field is-option-div" class=" column is-3">
        <label class="label is-size-7">
          {{item.siteLevel.name}}
        </label>
      </div>
      <div class="column ">
        <div *ngIf="isSiteDataReadonly() " class="is-read-only-label">
          <span>
            {{getSiteName(item, i)}}
          </span>
        </div>
        <div *ngIf="!isSiteDataReadonly() && !isNonStdCreditGroup()">
          <div class="select">
            <select [(ngModel)]="currentRegModel().participatingSites[i]"
             (change)="membershipSelected(i)" [compareWith]="compareFnMember">
             <option [ngValue]=null selected>Select..</option>
              <option *ngFor="let singleOrg of sortOrgList(membershipOrgList)" [ngValue]="singleOrg">
                <ng-template [ngIf]="singleOrg">
                  {{singleOrg.name}}
                  <!-- -{{singleOrg.caseOrganizationId}} -->
                </ng-template>

              </option>
            </select>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isSiteLevel(item, SITE_CODE_TREATING_LOCATION) ">
      <div class="field is-option-div" class=" column is-3">
          <div *ngIf="isSiteDataReadonly()">
            <div *ngIf="isNonStdCreditGroup()" class="ctep-pad">
            </div>
          </div>
          <label class="label is-size-7">
          {{item.siteLevel.name}}
        </label>
      </div>
      <div class="column ">
        <div *ngIf="isSiteDataReadonly() " class="is-read-only-label">
          <div *ngIf="isNonStdCreditGroup()" class="ctep-pad">
          </div>
          <span>
            {{getSiteName(item, i)}}
          </span>
        </div>
        <div *ngIf="!isSiteDataReadonly()">
          <div *ngIf="!isNonStdCreditGroup()">
            <div class="select">
              <select [(ngModel)]="currentRegModel().participatingSites[i]"
              (change)="treatingLocationSelected(i)" [compareWith]="compareFnTl">
              <option [ngValue]=null selected>Select..</option>
                <option *ngFor="let singleOrg of sortOrgList(treatingLocationOrgList)" [ngValue]="singleOrg">
                <ng-template [ngIf]="singleOrg">
                  {{singleOrg.name}}
                  <!-- -{{singleOrg.caseOrganizationId}} -->
                </ng-template>
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="isNonStdCreditGroup()">
            <div class="ctep-pad">
              <input [(ngModel)]="ctepTL" type="text" placeholder="CTEP ID" [readonly]="isNonStdTLReadOnly" (change)="getNonStdSiteInformation(i)"
                id="ctepTL" class="ctep-input">
              <span class="ctep-name-pad">{{getNonStdTLName()}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div><div *ngFor="let item of studyPersonTypes; let i=index">
  <div *ngIf="item.required != null" class="columns" #participatingPersons>
    <div class=" column is-3">
      <div class="field is-option-div">
        <label class="label is-size-7">
          {{item.label.name}}
        </label>
      </div>
    </div>
    <div class="column">
      <span *ngIf="isPersonDataReadonly()">
        {{getPersonData(currentRegModel().participatingPersons[i])}}
      </span>
      <ng-container *ngIf="!isPersonDataReadonly()">
        <div *ngIf="!isNonStdCreditGroup()">
          <div class="select">
            <select (change)="setRegPersonForPersonType($event, item)" >
              <option [ngValue]=blankPerson selected  value="0" >Select..</option>
              <option *ngFor="let singlePerson of getPersonsForType(item)" [ngValue]="singlePerson"
              [selected]="getRegPersonForPersonType(item, singlePerson)"  value="{{getSinglePersonValue(singlePerson)}}">
                <ng-template [ngIf]="checkForFirstAndLastname(singlePerson)">
                  {{getPersonData(singlePerson)}}
                  <!-- - {{singlePerson.caseEventStaffId}}  -->
                </ng-template>
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="isNonStdCreditGroup()">
          <div class="ctep-staff-pad">
              <input [(ngModel)]="ctepStaff[i]" type="text" placeholder="CTEP ID" (change)="getNonStdStaffInformation(item, i)"
                class="ctep-input">
              <span class="ctep-name-pad">{{getNonStdStaffName(item, i)}}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

