import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../common/shared.module';
import { AdminTilesModule } from './admin-tiles/admin-tiles.module';

import { HomeComponent } from './home.component';
import { SearchComponent } from './search/search.component';
import { ProtocolSearchResultsComponent } from './protocol-search-results/protocol-search-results.component';

import { McTableModule } from 'app/common/directives/table/mc-table.module';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    McTableModule,
    ReactiveFormsModule,
    AdminTilesModule
  ],
  declarations: [
    SearchComponent,
    ProtocolSearchResultsComponent,
    HomeComponent

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomePageModule { }
