import { Component, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { HeaderBarComponent } from 'app/common/header-bar/header-bar.component';
import { CaseIntegrationStatus } from 'app/common/model/case-integration-status';
import { HeaderBar } from 'app/common/model/header-bar';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { PostProcessingHistoryRecord } from 'app/common/model/post-processing-history-record';
import { PreviewPanelModel } from 'app/common/model/preview-panel-model';
import { PatientService } from 'app/common/services/patient/patient.service';
import { StudySetupService } from 'app/common/services/study-setup.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'mc-post-processing',
  templateUrl: './post-processing.component.html',
  styleUrls: ['./post-processing.component.scss'],
})
export class PostProcessingComponent implements OnInit, OnDestroy {
  /**
   * model for the header bar
   */
  headerModel: HeaderBar;


  studyId: number;

  patientId = '';

  eventName = '';

  /**
   * Variable to hold the caseEventId
   */
  caseEventId = 0;
  /**
   * Variable to hold the caseDetailId
   */
  caseDetailId = 0;

  navBackPageEnum = 0;
  trackingNum: string;
  selectedCaseEventId: number;

  /**
   * List of case integration statuses
   */
  caseIntegrationStatusList: CaseIntegrationStatus[] = [];

  postProcessingHistoryRecords: PostProcessingHistoryRecord[];
  historyTarget: string;

  previewModelHome: PreviewPanelModel = new PreviewPanelModel();

 previewTitle = 'History';

    /**
   * Reference to the child header component
   */
    @ViewChild(HeaderBarComponent)
    private headerBar: HeaderBarComponent;

  private subscriptions: Subscription[] = [];

  private currentUrl: any;

  constructor(
    private studySetupService: StudySetupService,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private viewContainer: ViewContainerRef
  ) {
    // need to ensure we react only when the navigation ends
    this.subscriptions.push(
      router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((url: any) => {
          if (url.url) {
            this.currentUrl = url;
          }
        })
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        if (params['navBack']) {
          this.navBackPageEnum = +params['navBack'];
        }
        if (params['trackingNum']) {
          this.trackingNum = params['trackingNum'];
        }
        if (params['selectedCaseEventId']) {
          this.selectedCaseEventId = params['selectedCaseEventId'];
        }
      }))
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.studyId = params['studyId'];
      this.caseDetailId = params['caseDetailId'];
      this.caseEventId = params['caseEventId'];
      this.patientId = params['patientId'];
      this.subscriptions.push(this.studySetupService.getStudy(this.studyId)
        .subscribe(results => {
          //initialize the model for the header
          this.headerModel = {
            caption: "Post Processing",
            captionValue: "",
            showDescription: true,
            description: "StudyId: " + results.studyNumber + " &emsp;  Subject ID: " + this.patientId,
            showButtons: false,
            canNavigate: false,
            navigateUrl: "",
            disableButtons: true
          }
        }));
      this.getPostProcessingStatus();


    }));
  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
      x.closed;
    });
  }

  /**
   * This will show modal dialog with results of the processing action
   * @returns Observable - return Observable
   */
  showResultDialog(result: CaseIntegrationStatus, action: string) {
    // } : Observable<boolean> {
    const actionMessage =
      action.toUpperCase().substr(0, 1) + action.substr(1, action.length - 1);
    let variableData =
      actionMessage +
      ' to ' +
      result.targetSystem +
      ' - ' +
      result.transactionResult +
      '.';
    if (result.failedReasonCode) {
      variableData = variableData.concat(
        ' Reason Code ' + result.failedReasonCode
      );
    }
    if (result.failedMessage) {
      // NOTE:  sample failedMessage format:
      //  "failedMessage": "<Response ReferenceNumber=\"cf686e3c-662e-42e8-ad1b-c9856fd36aba\" InboundODMFileOID=\"Enrollment\" IsTransactionSuccessful = \"0\" ReasonCode=\"RWS00024\" ErrorOriginLocation=\"/ODM/ClinicalData[1]/SubjectData[1]\" SuccessStatistics=\"Rave objects touched: Subjects=0; Folders=0; Forms=0; Fields=0; LogLines=0\" ErrorClientResponseMessage=\"Subject already exists.\"> </Response> ",
      let msg="";
      if(result.failedMessage.indexOf('ErrorClientResponseMessage') >=0){
         msg = result.failedMessage.substring(
          result.failedMessage.indexOf('ErrorClientResponseMessage') + 28,
          result.failedMessage.indexOf('Response>') - 5
        );
      }else{
        msg=result.failedMessage;
      }
      variableData = variableData.concat(' - ' + msg);
    }

    const modalArg = new ModalDialogArg('modal-warn', 'Custom', variableData);
    this.patientService.showResultDialog(this.viewContainer, modalArg);
  }

  /**
   * Gets the list of the post-processing integration statuses for this case event id
   */
  getPostProcessingStatus() {
    this.subscriptions.push(
      this.patientService
        .getPostProcessingStatus(this.caseEventId)
        .subscribe((results) => {
          // we want the protocol first and then the anciallaries sorted by name
          this.caseIntegrationStatusList = _.orderBy(
            results,
            ['targetSystem'],
            ['desc']
          );
          this.eventName = this.caseIntegrationStatusList[0].eventName;
        })
    );
  }

  /**
   * Process a resend to PMB
   */
  resendToPmbV2(row: CaseIntegrationStatus, idx: number, action: string) {
    this.subscriptions.push(
      this.patientService.resendToPmbV2(row).subscribe((results) => {
        this.caseIntegrationStatusList[idx] = results;
        this.showResultDialog(results, action);
      })
    );
  }

  /**
   * Process a resend to RAVE
   */
  resendToRave(row: CaseIntegrationStatus, idx: number, action: string) {
    this.subscriptions.push(
      this.patientService.resendToRave(row).subscribe((results) => {
        this.caseIntegrationStatusList[idx] = results;
        this.showResultDialog(results, action);
      })
    );
  }

  /**
   * Process a update to RAVE
   */
  updateToRave(row: CaseIntegrationStatus, idx: number, action: string) {
    this.subscriptions.push(
      this.patientService.updateToRave(row).subscribe((results) => {
        this.caseIntegrationStatusList[idx] = results;
        this.showResultDialog(results, action);
      })
    );
  }

  /**
   * Process a resend to BioMS
   */
  resendToBioMS(row: CaseIntegrationStatus, idx: number, action: string) {
    this.subscriptions.push(
      this.patientService.resendToBioMS(row).subscribe((results) => {
        this.caseIntegrationStatusList[idx] = results;
        this.showResultDialog(results, action);
      })
    );
  }

  /**
   * Process a update to BioMS
   */
  updateToBioMS(row: CaseIntegrationStatus, idx: number, action: string) {
    this.subscriptions.push(
      this.patientService.updateToBioMS(row).subscribe((results) => {
        this.caseIntegrationStatusList[idx] = results;
        this.showResultDialog(results, action);
      })
    );
  }

  /**
   * Process a resend to McKesson
   */
  resendToMcKesson(row: CaseIntegrationStatus, idx: number, action: string) {
    this.subscriptions.push(
      this.patientService.resendToMcKesson(row).subscribe((results) => {
        this.caseIntegrationStatusList[idx] = results;
        this.showResultDialog(results, action);
      })
    );
  }

    /**
   * Process a resend to Redcap
   */
    sendToRedcap(row: CaseIntegrationStatus, idx: number, action: string, isUpdate: boolean) {
      this.subscriptions.push(
        this.patientService.sendUpdatesToRedcap(row, isUpdate).subscribe((results) => {
          this.caseIntegrationStatusList[idx] = results;
          this.showResultDialog(results, action);
        })
      );
    }

  /**
   * Navigate back to the patient landing
   */
  navigateBack() {
    const path: string =
      '/patient/' +
      this.studyId +
      '/' +
      this.patientId +
      '/' +
      this.caseDetailId;
    this.router.navigate([path], {
      queryParams: {
        navBack: this.navBackPageEnum,
        trackingNum: this.trackingNum,
        selectedCaseEventId: this.selectedCaseEventId
      },
    });
  }

  postProcessingAction(
    row: CaseIntegrationStatus,
    action: string,
    idx: number
  ) {
    if (action === 'history') {
      this.getPostProcessingHistory(row);
    } else {
      this.postProcessingHistoryRecords = [];
      this.historyTarget = "";
      this.previewModelHome.isCollapsed = true;


      if (action === 'resend') {
        if (row.targetSystem.toUpperCase() == 'RAVE') {
          this.resendToRave(row, idx, action);
        }
        if (row.targetSystem.toUpperCase().startsWith('BIOMS')) {
          this.resendToBioMS(row, idx, action);
        }
        if (row.targetSystem.toUpperCase() === 'PMBV2') {
          this.resendToPmbV2(row, idx, action);
        }
        if (row.targetSystem.toUpperCase().startsWith('MCKESSON')) {
          this.resendToMcKesson(row, idx, action);
        }
        if (row.targetSystem.toUpperCase().startsWith('REDCAP')) {
          this.sendToRedcap(row, idx, action, false);
        }
      } else if (action === 'update') {
        if (row.targetSystem.toUpperCase() == 'RAVE') {
          this.updateToRave(row, idx, action);
        }
        if (row.targetSystem.toUpperCase().startsWith('BIOMS')) {
          this.updateToBioMS(row, idx, action);
        }
        if (row.targetSystem.toUpperCase().startsWith('REDCAP')) {
          this.sendToRedcap(row, idx, action, true);
        }
      } else if (action === 'delete') {
      }
    }
  }

  /**
   * Get Post Processing history
   * @param row 
   * @param idx 
   * @param action 
   */
  getPostProcessingHistory(row: CaseIntegrationStatus) {
    this.historyTarget= row.targetSystem;
    this.subscriptions.push(
      this.patientService.getPostProcessingHistory(row).subscribe((results) => {
        this.postProcessingHistoryRecords = results;
        })
    );
    this.previewModelHome.isCollapsed = false;
  }

  isPmbV2OrBioMsPreview():boolean {
    if (this.postProcessingHistoryRecords ) {
      if (this.historyTarget.toLowerCase() ==='pmbv2' || this.historyTarget.toLowerCase().startsWith('bioms') || this.historyTarget.toLowerCase() === 'redcap' ) {
          return true;
      }
    }
    return false;
  }

  isRavePreview():boolean {
    if (this.postProcessingHistoryRecords ) {
      if (this.historyTarget.toLowerCase() ==='rave') {
          return true;
      }
    }
    return false;
  }

  isPMBorMcKessonPreview():boolean {
    if (this.postProcessingHistoryRecords ) {
      if (this.historyTarget.toLowerCase() ==='pmb' || this.historyTarget.toLowerCase() ==='mckesson' ) {
          return true;
      }
    }
    return false;
  }

  isUpdateAllowed(row: CaseIntegrationStatus) {
    if (row.targetSystem != null && row.targetSystem !== 'McKesson' && row.targetSystem !== 'PMB' && row.targetSystem !== 'PMBv2') {
      return true;
    }
    return false;
  }

  isDeleteAllowed(row: CaseIntegrationStatus) {
    // if (row.targetSystem != null && row.targetSystem === 'PMB') {
    //   return true;
    // }
    return false;
  }

  isResendAllowed(row: CaseIntegrationStatus) {
    if (row.targetSystem != null && row.targetSystem !== 'PMB') {
      return true;
    }
    return false;
  }

  /**
   * 
   * @param row is resend button disabled
   * @returns 
   */
  isResendDisabled(row: CaseIntegrationStatus){
    if (row.targetSystem != null && row.targetSystem.toUpperCase() === 'RAVE') {
      if(row.ravePatientInfo?.firstEvent && !row.ravePatientInfo?.patientFound){
            return false;
      }
      return true;
    }
    return false;
  }


  /**
   * 
   * @param row Is update button disabled
   * @returns 
   */
  isUpdateDisabled(row: CaseIntegrationStatus){
    if (row.targetSystem != null && row.targetSystem.toUpperCase() === 'RAVE') {
      if ( row.ravePatientInfo?.patientFound && !row.ravePatientInfo?.siteMismatch) {
        return false;
      }
      return true;
    }
    return false;
  }


  // reference for angular 9

  saveChanges() {}

  cancelChanges() {}


   /**
     * True if the orientation is vertical and the state is collapsed
     */
   isCollapsedVertical(): boolean {
    return this.previewModelHome.isCollapsed && this.previewModelHome.isVertical;
  }


  /**
   * True if the orientation is horizontal and is collapsed
   */
  isCollapsedHorizontal(): boolean {
    return (this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }


  /**
   * True if the orientation is vertical and the state is Expanded
   */
  isExpandedVertical(): boolean {
    return (!this.previewModelHome.isCollapsed && this.previewModelHome.isVertical);
  }

  /**
   * True if the orientation is horizontal and is expanded
   */
  isExpandedHorizontal(): boolean {
    return (!this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }

  /** custom handling of the preview for the  page********************* */

}
