import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../../app-config/app-config.module';
import { Person } from '../model/person';

@Injectable()
export class PersonService {

  serviceUrlPrefix = this.config.apiEndpoint;

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }

  getPersons(): Observable<Array<Person>> {
    // return this.http.get<Array<Person>>( this.serviceUrlPrefix + 'persons' );
      return of([
        {
          id: 1,
          firstName: 'George',
          middleName: '',
          lastName: 'Smith',
          email: 'gs@smith.com'
        },
        {
          id: 4747,
          firstName: 'Sara',
          middleName: 'M',
          lastName: 'Broun',
          email: 'Broun.Sara@mayo.edu'
        },
        {
          id: 8568,
          firstName: 'Dawn',
          middleName: '',
          lastName: 'Dena',
          email: 'Dena.Dawn@mayo.edu'
        }
      ]);

  }

}
