<table class="table is-fullwidth" ngClass="{{config.className || ''}}" role="grid">
  <thead>
    <tr role="row">
      <th *ngFor="let column of columns" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}"
        (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
        <span> {{column.title}}</span>
        <!--      <span *ngIf="config && column.sort != undefined "> {{column.sort.length}}</span>
       
 <pre>{{column | json}}</pre> -->
        <span class="no-sort" *ngIf="config && column.sort != undefined && column.sort.length == 0">
        <i class="fas fa-sort " ></i>        
      </span>


        <i *ngIf="config && column.sort" class="pull-right fa is-size-2" [ngClass]="{'fa-sort-desc': column.sort === 'desc', 'fa-sort-asc': column.sort === 'asc'}"></i>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="showFilterRow">
      <td *ngFor="let column of columns">
        <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" class="form-control"
          (tableChanged)="onChangeTable(config)" />
      </td>
    </tr>
    <tr *ngFor="let row of rows">
      <td (click)="cellClick(row, column.name)" *ngFor="let column of columns" [innerHtml]="getData(row, column.name) | safeHtml">
      </td>
    </tr>
  </tbody>
</table>