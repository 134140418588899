<div class="  form-page columns is-marginless">
    <div class="column  is-12 ">
      <div id="transfer-requests">
        <div class="transfer-edit-bar">
          <div class="columns edit-bar-section">
            <div class="column ">
              <div class="">
                <h3> Transfer Subject History</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="column 2" [ngClass]="{'is-hidden': !patientTransferInputList}">
          <table class="table is-fullwidth is-borderless is-striped " role="grid">
            <tr><td><span>Event Name:</span></td><td><Span>{{patientTransferInputList.eventName}}</Span></td><td><span>initials:</span></td><td>{{patientTransferInputList.initials}}</td></tr>
            <tr><td><span>Site Name:</span></td><td>{{patientTransferInputList.siteName}}</td><td><span>Last Modified Date:</span></td><td>{{patientTransferInputList.modifiedTs | date:'MM/dd/yyyy'}}</td></tr>
            <tr><td><span>Status Date:</span></td><td>{{patientTransferInputList.statusDate | date:'MM/dd/yyyy'}}</td><td></td></tr>
             </table>
        </div>
     
      <div class="main-transfer">
        <h3>Organizations:</h3>
        <ng-container>
          <table class="table is-fullwidth is-borderless is-striped " role="grid">
            <thead>
              <tr>
                <th>Name</th>
                <th>Ctep Id</th>
                <th>Site Level</th>
                <th>Site Seq</th>
              </tr>
            </thead>
            <tbody>
          <tr *ngFor="let item of patientTransferInputList.organizations">
            <td class="display-label">
              <span>
                {{item.name}}
              </span>
            </td>
            <td>
                {{item.ctepId}}
            </td>
            <td>
              {{item.siteLevel}}
          </td>
          <td>
            {{item.siteSeq}}
        </td>
          </tr>
            </tbody>
          </table>
        </ng-container>
        </div>
        
        <div class="main-transfer">
          <h3>Staff:</h3>
          <ng-container>
            <table class="table is-fullwidth is-borderless is-striped " role="grid">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>email Id</th>
                </tr>
              </thead>
              <tbody>
            <tr *ngFor="let item of patientTransferInputList.staff">
              <td class="display-label">
                <span>
                  {{item.type}}
                </span>
              </td>
              <td>
                  {{item.name}}
              </td>
              <td>
                {{item.email}}
            </td>
            </tr>
              </tbody>
            </table>
          </ng-container>
          </div>


      </div>
    </div>
</div>