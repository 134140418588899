import * as  ExcelJS from 'exceljs';

export class TableUtil {
  static exportTableToExcel(tableId: string, name?: string) {
    const sheetName = name || "ExportResult";
    const fileName = sheetName;
    const targetTableElm = document.getElementById(tableId);

    //Create excel book
    const workBook = new ExcelJS.Workbook();

    //Add work sheet to excel book
    const worksheet = workBook.addWorksheet(sheetName);

    const table = document.getElementById(tableId) as HTMLTableElement;
    const rows = table.getElementsByTagName('tr');

    //Loop through the rows and add each row to worksheet
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const cells = row.getElementsByTagName(i === 0 ? 'th' : 'td');
      const excelRow: string[] = [];

      for (let j = 0; j < cells.length; j++) {
        excelRow.push(cells[j].innerText);
      }

      worksheet.addRow(excelRow);

    }

    // Auto-size columns
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
    });

    workBook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    });

  }

}
