import { DatePipe } from '@angular/common';
import { Component, ComponentRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorDisplayComponent } from 'app/common/error-display/error-display.component';
import * as globalConst from 'app/common/model/app-constants';
import { AppUser } from 'app/common/model/app-user';
import { HeaderBar } from 'app/common/model/header-bar';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { Organization } from 'app/common/model/organization';
import { PatientErrorDisplayArg } from 'app/common/model/patient-error-display-arg';
import { PatientInfo } from 'app/common/model/patient-info';
import { PatientRegError } from 'app/common/model/patient-reg-error';
import { Person } from 'app/common/model/person';
import { PreviewPanelModel } from 'app/common/model/preview-panel-model';
import { StudyRecord } from 'app/common/model/study-record';
import { StudyReservationSlotGroup } from 'app/common/model/study-reservation-slot-group';
import { StudySiteLevel } from 'app/common/model/study-site-level';
import { StudySlotGroup } from 'app/common/model/study-slot-group';
import { SubjectReservation } from 'app/common/model/subject-reservation';
import { UiLayoutElement } from 'app/common/model/ui-layout-element';
import { NewPatientService } from 'app/common/services/patient/new-patient.service';
import { StudyServiceBase } from 'app/common/services/study-service-base.service';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { UserService } from 'app/common/services/user.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as ACTIVITY_NAMES from '../../common/model/activity-name-constants';

@Component({
  selector: 'mc-create-reservation',
  templateUrl: './create-reservation.component.html',
  styleUrls: ['./create-reservation.component.scss']
})
export class CreateReservationComponent implements OnInit {

  @ViewChildren('participatingPersons', { read: ViewContainerRef }) viewContentPersonDivs: QueryList<any>;
  @ViewChildren('siteInfo', { read: ViewContainerRef }) viewContentSiteDivs: QueryList<any>;
  @ViewChildren('patientInfo', { read: ViewContainerRef }) viewContentPatientInfoDivs: QueryList<any>;
  @ViewChildren('slotGroup', { read: ViewContainerRef }) viewContentSlotGroupDivs: QueryList<any>;

  @ViewChild('reservationTile') viewContentReservationTileeDiv;
  @ViewChildren('participatingPersons') participatingPersonsViewContent: QueryList<any>;
  @ViewChildren('siteInfo') siteInfoViewContent: QueryList<any>;
  @ViewChildren('patientInfo') patientInfoViewContent: QueryList<any>;
  @ViewChildren('slotGroup') slotGroupViewContent: QueryList<any>;


  studyId: number = null;
  subjectReservationId: number;

  blankPerson: Person = { firstName: '', lastName: '', pk: null, personTypeId: 0, personRoleStatus: '' };
  nullPatientInfo: PatientInfo = new PatientInfo("", "", "", null, null, null, null, null, null, null, null, null);
  subjectReservation: SubjectReservation = new SubjectReservation(0, null, [], this.nullPatientInfo, this.blankPerson, null, null, null, null, null, null, null, 0);
  originalSubjectReservation: SubjectReservation;

  currentUser: AppUser = null;

  ctepTL: string;
  nonStdTL: Organization;
  isNonStdTLReadOnly: boolean = false;
  initialTLCaseOrgId: number;
  initialTLCaseOrgTypeId: number;

  uiLayoutList: UiLayoutElement[] = [];

  siteLevelList: StudySiteLevel[] = [];

  lookupSiteLevelOrgList: Array<Organization[]> = [];

  SITE_CODE_CREDIT_GROUP = 'CGP';
  SITE_CODE_MEMBERSHIP = 'MBR';
  SITE_CODE_TREATING_LOCATION = 'TLC';


  nullOrganization: Organization = { 'ctepId': '', 'orgNumber': '', 'abrev': '', 'name': 'Select..', 'type': '', 'status': 'active', 'relationshipPk': null };

  nullPersonType: Person = { 'ctepId': null, 'firstName': '..', 'lastName': 'Select ', 'email': null, 'fax': null, 'phone': null, 'personTypeId': 0, 'pk': 0, 'personRoleStatus': 'Inactive' };
  creditGroupOrgList: Organization[] = [];

  membershipOrgList: Organization[] = [];

  treatingLocationOrgList: Organization[] = [];

  personsList: Person[] = [];

  sitesValidationProperty = 'participatingSites';
  personValidationProperty = 'participatingPersons';
  patientInfoValidationProperty = 'patientInfo';
  slotGroupValidationProperty = 'slotGroups';
  separator = '.';

  propertySiteContact = "siteContact";
  propertyPiFirstName = 'firstName';
  propertyPiMiddleName = 'middleName';
  propertyPiLastName = 'lastName';
  propertyPiDOB = 'dateOfBirthString';
  propertySlotGroup = "group";

  /**
   * Holds the list of persons in the Lookup
   */
  personLookups: Map<string, Array<Person>> = new Map<string, Array<Person>>();


  siteContacts: Array<Person> = [];

  studySlotGroups: StudySlotGroup;

  public studyRecord: StudyRecord;
  headerModel: HeaderBar;
  studyNumber: string;
  studyDesc: string;
  isReadOnly: boolean;
  isSitesReadOnly: boolean;
  isReservationUpdate: boolean;


  statusError = "error";
  statusCompleted = "Completed";
  tileStatus = null;

  reservationErrors: Array<PatientRegError> = new Array<PatientRegError>();


  tileNameValidate = "Validate";
  tileNameReservation = "Reservation";
  updateTileName = "Update Reservation Information";

  /** begin - custom handling of the preview page********************* */

  /**
   * Model to drive the orientation and horizontal/vertical states of the preview window
   */
  previewModelHome: PreviewPanelModel = new PreviewPanelModel();

  previewTitle = 'Subject Reservation';

  formTitle = 'New Reservation Record';

  // printDocType: number = globalConst.REVIEW_PATIENT; - needed to print PDF
  /** end - custom handling of the preview page********************* */

  /**
 * Variable to hold the error components are they are created for displaying the errors
 */
  errorComponents: ComponentRef<ErrorDisplayComponent>[] = [];


  // Private Field Definitions

  private subscriptions: Subscription[] = [];
  /**
   * This will hold the caseOrgIds for all the sites.
   */
  private caseOrganizationIds: number[] = [];
  private selectedContentDiv: any = null;


  constructor(private studySetupService: StudySetupService,
    private studyService: StudyServiceBase,
    private patientService: NewPatientService,
    private userService: UserService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private viewContainer: ViewContainerRef,
    private datePipe: DatePipe) {
    this.currentUser = userService.appUserEntity;

  }

  ngOnInit(): void {

    this.subscriptions.push(this.route.params.subscribe(params => {
      this.studyId = params?.studyId;
      this.subjectReservationId = params?.subjectReservationId;

      this.subscriptions.push(
        this.studyService.getStudy(this.studyId)
          .subscribe(results => {
            this.studyRecord = results;
            // initialize the model for the header
            if (this.subjectReservationId <= 0) {
              this.headerModel = new HeaderBar('Create a Reservation for study ', results.studyNumber, true, results.longDescription, false);
            } else {
              this.headerModel = new HeaderBar('Update a Reservation for study ', results.studyNumber, true, results.longDescription, false);
            }
            this.studyNumber = results.studyNumber;
            this.studyDesc = results.longDescription;
          })
      );

      if (this.subjectReservationId <= 0) {
        this.isSitesReadOnly = false;
        const reservedBy = new Person(this.currentUser.firstName, this.currentUser.lastName, null, null, null, this.currentUser.ctep, null, null, null, null, this.currentUser.userPk);
        const reservation = new SubjectReservation(this.subjectReservationId, reservedBy, [], this.nullPatientInfo, this.blankPerson, null, null, null, null, null, null, null, 0);
        this.originalSubjectReservation = _.cloneDeep(reservation);
        this.subjectReservation = reservation;
      } else {
        //call backend endpoint for manage reservation
        this.isReservationUpdate = true;
        this.isSitesReadOnly = true;
        this.subscriptions.push(this.patientService.getPatientReservation(this.subjectReservationId, this.studyId)
          .subscribe(res => {
            this.originalSubjectReservation = _.cloneDeep(res);
            this.subjectReservation = res;
            if (this.subjectReservation.reservationStatus.code !== 'RESERVED'
              || !(this.userService.hasCreateAccessByStudy(ACTIVITY_NAMES.ACTIVITY_SLOT_RESERVATION, this.studyId))) {
              this.isReadOnly = true;
            }

            this.treatingLocationSelected();
            this.subjectReservation.contactStaff = this.originalSubjectReservation.contactStaff;
          })
        );
      }
    }));

    this.initializeLookupData();

  }


  /**
 * CAll the services to fetch the data for display on the ui
 */
  initializeLookupData() {
    const observables: Observable<any>[] = [];
    observables.push(this.getStudySiteLevels());
    observables.push(this.getSlotGroups());

    // once all the data has been retrieved set the display
    // property and clone
    this.subscriptions.push(
      forkJoin(observables)
        .subscribe(() => {
          const secondSet: Observable<any>[] = [];
          secondSet.push(this.getTopLevelLookupOrgs());

          this.subscriptions.push(
            forkJoin(secondSet)
              .subscribe(() => {
                console.log('Second forkJoin initialized.');
              }, (err) => { },
                () => {
                  console.log('2.3 - Patient PArt Participation - All Lookup Data Retrieved');
                  setTimeout(() => {
                    console.log('2.4 - Patient PArt  Participation - Initialize data for Patient');
                    this.initializeData();
                    this.performViewInit();
                  }, 100);
                })
          );
        }, (err) => { console.log(err); },
          () => {

          }
        ));
  }

  getPersonName(person: Person): string {
    const personList = this.siteContacts.filter(contact => contact.pk === person.pk);
    const singlePerson = personList[0];
    if (singlePerson) {
      if (singlePerson.personRoleStatus != null && singlePerson.personRoleStatus !== '') {
        if (singlePerson.fullName == null) {
          return singlePerson.lastName + ', ' + singlePerson.firstName + ' - ' + singlePerson.personRoleStatus;
        } else {
          return singlePerson.fullName + singlePerson.personRoleStatus;
        }
      }
      if (singlePerson.fullName == null) {
        return singlePerson.lastName + ', ' + singlePerson.firstName;
      } else {
        return singlePerson.fullName;
      }
    } else { return ''; }
  }


  /**
   * 
   * @returns Get study slot groups
   */
  getSlotGroups() {
    const obs =
      this.studySetupService.getStudySlotGroups(this.studyId)
        .pipe(tap(results => {
          this.studySlotGroups = results;
        })
        );
    return obs;
  }
  /**
* Returns the list of studySiteLevels from the service
*/
  getStudySiteLevels(): Observable<any> {

    const obs =
      this.studySetupService.getSiteLevels(this.studyId)
        .pipe(tap(results => {
          console.log('2.1 - Participation - retrieved Site Level Data');
          this.siteLevelList = results.filter(eachSite => eachSite.required);
        })
        );

    return obs;
  }


  /**
  * Populates the lookup for the Top level Lookup Orgs
  */
  getTopLevelLookupOrgs(): Observable<any> {
    let siteLevel: StudySiteLevel = this.getCreditGroupSite();
    let targetCollectionName = 'creditGroupOrgList';
    if (siteLevel === undefined) {
      // The CreditGroup has not been configured
      siteLevel = this.getMembershipSite();
      if (siteLevel) {
        // the next site - Membership is configured
        targetCollectionName = 'membershipOrgList';
      } else {
        // We just have the Treating location
        siteLevel = this.getTreatingLocation();
        if (siteLevel) {
          targetCollectionName = 'treatingLocationOrgList';
        }
      }
    }
    let obs: Observable<any>;
    if (siteLevel) {
      obs = this.getOrgListObservable(0, siteLevel, targetCollectionName);
      console.log('Returning TargetCollection ', targetCollectionName);
    }
    return obs;
  }


  performViewInit() {

    if (this.viewContentSlotGroupDivs && this.viewContentSlotGroupDivs.length > 0) {
      this.viewContentSlotGroupDivs.forEach((eachItem, itemIdx) => {
        const node: ViewContainerRef = this.renderer.selectRootElement(eachItem);
        const propertyName = this.slotGroupValidationProperty + this.separator + node.element.nativeElement.id;
        this.uiLayoutList.push(this.getUILayoutElement(node, propertyName));
      });
    }

    if (this.viewContentSiteDivs && this.viewContentSiteDivs.length > 0) {
      this.viewContentSiteDivs.forEach((eachItem, itemIdx) => {
        const node: ViewContainerRef = this.renderer.selectRootElement(eachItem);
        const matches = this.siteLevelList.filter(eachSiteLevel => eachSiteLevel.required);
        const propertyName = this.sitesValidationProperty + this.separator + matches[itemIdx].siteLevel.id;
        this.uiLayoutList.push(this.getUILayoutElement(node, propertyName));
      });
    }
    if (this.viewContentPersonDivs && this.viewContentPersonDivs.length > 0) {
      this.viewContentPersonDivs.forEach((eachPersonDiv, itemIdx) => {
        const node: ViewContainerRef = this.renderer.selectRootElement(eachPersonDiv);
        const propertyName = this.personValidationProperty + this.separator + node.element.nativeElement.id;
        this.uiLayoutList.push(this.getUILayoutElement(node, propertyName));
      });
    }

    if (this.viewContentPatientInfoDivs && this.viewContentPatientInfoDivs.length > 0) {
      this.viewContentPatientInfoDivs.forEach((eachItem, itemIdx) => {
        const node: ViewContainerRef = this.renderer.selectRootElement(eachItem);
        const propertyName = this.patientInfoValidationProperty + this.separator + node.element.nativeElement.id;
        this.uiLayoutList.push(this.getUILayoutElement(node, propertyName));
      });
    }
  }




  /**
 * Returns the SiteLevel for Credit Group
 */
  getCreditGroupSite(): StudySiteLevel {
    return this.getSiteByCode(this.SITE_CODE_CREDIT_GROUP);
  }

  /**
   * Returns the SiteLevel for Membership
   */
  getMembershipSite(): StudySiteLevel {
    return this.getSiteByCode(this.SITE_CODE_MEMBERSHIP);
  }
  /**
   * Returns the SiteLevel for Treating Location
   */
  getTreatingLocation(): StudySiteLevel {
    return this.getSiteByCode(this.SITE_CODE_TREATING_LOCATION);
  }


  /**
 * returns true if the selected credit group is non-standard
 */
  isNonStdCreditGroup() {
    const cg: StudySiteLevel = this.getCreditGroupSite();
    const selectedCG = this.subjectReservation.organizations.filter(site => site && site.siteLevelId && cg && cg.siteLevel && site.siteLevelId === cg.siteLevel.id);
    if (selectedCG.length > 0) {
      if (selectedCG[0].nonStdOrgId && selectedCG[0].nonStdOrgId > 0) {
        return true;
      }
    }
    return false;
  }

  // Returns site list with alphabetical order
  sortOrgList(orgList: Organization[]): Organization[] {
    return orgList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
  }


  /**
   * Returns the name of the matching organization for the site
   * 
   * @param rowIdx The siteIndex being evaluated
   */
  getSiteName(siteLevel: StudySiteLevel, rowIdx: number): string {
    let siteName = '';

    const sites = this.subjectReservation.organizations;
    // const site = sites[rowIdx];

    const site = sites.filter(eachSite => eachSite && eachSite.siteLevelId && siteLevel && siteLevel.siteLevel && eachSite.siteLevelId === siteLevel.siteLevel.id);

    if (site.length === 1) {
      // siteName = site[1].name;
      if (site[0]?.name) {
        siteName = site[0]?.name;
      }
      else if (site[0]?.ctepId) {
        siteName = site[0]?.ctepId;
      }

    }

    return siteName;
  }


  /**
 * Handles the selection of an Org for CreditGroup
 * TODO: do we need to do anything here?  - clear out the staffCtep, maybe?
 * 
 * @param index The index of the site
 */
  creditGroupSelected(index: number) {
    this.clearDependencies(0);
    this.getMembershipOrgs(index);
    // this.isNonStdTLReadOnly = false;
  }

  /**
  * Handles the Selection of an org in the Membership dropdown
  *
  * @param index The index of the site in the sites array
  */
  membershipSelected(index: number) {
    this.clearDependencies(1);
    this.getTreatingLocationOrgs();
  }

  /**
  * Gets the Membership Org list for the selected CreditGroup
  * 
  * @param index The index for the particiation site on the Reg object
  */
  getMembershipOrgs(index: number) {
    this.membershipOrgList = [];
    this.treatingLocationOrgList = [];

    const siteLevel: StudySiteLevel = this.getMembershipSite();

    let selectedOrg: Organization = null;
    selectedOrg = this.getSelectedParentOrg(index, siteLevel, 'creditGroupOrgList');
    if (selectedOrg && selectedOrg.relationshipPk && selectedOrg.relationshipPk > 0) {
      const obsList: Observable<any>[] = [];
      obsList.push(
        this.getOrgListObservable(selectedOrg.relationshipPk,
          siteLevel, 'membershipOrgList'));

      this.subscriptions.push(
        forkJoin(obsList)
          .subscribe(() => {

          }, (err) => { },
            () => {
              console.log('Membership Org List retrieved');
            })
      );
    }
  }



  /**
  * True if the siteLevel code is the code being evaluated
  *
  * @param item The siteLevel to evaluate
  * @param siteLevelCode The code to check
  */
  isSiteLevel(item: StudySiteLevel, siteLevelCode: string): boolean {
    //   console.log("Checking.. ",siteLevelCode, item, item.siteLevel.code == siteLevelCode)
    return item.siteLevel.code === siteLevelCode;
  }




  /**
  * Set the date in the correct format
  * 
  * @param selectedDate The date Selected by the popup
  */
  setDOBData(selectedDate) {
    if (selectedDate) {
      this.subjectReservation.patientInfo.dateOfBirthString = this.datePipe.transform(selectedDate, "MM/dd/yyyy");
    }
  }


  showSlotGroup(): boolean {
    if ((this.studySlotGroups && this.studySlotGroups.slotGroupTypeCode === 'None') || this.subjectReservationId > 0) {
      return false;
    }
    return true;
  }

  /**
   * Called when slot selection is changed 
   */
  slotChanged() {
    if (this.subjectReservation.studyReservationSlotGroup.groupStatus.code === "Enroll") {
      const modalArg: ModalDialogArg = {
        dialogType: 'modal-warn',
        contentType: 'Custom',
        variableData: null,
        displayMessage: 'This cohort no longer requires a reservation.  Proceed to registration.',
        displayName: ''
      };
      this.subscriptions.push(
        this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
          .subscribe(result => {
            if (result) {

            }
          })
      );
    }
  }


  /**
   * Initialize the data for the object is no data exists
   */
  initializeData() {
    if (this.subjectReservation) {
      console.log('2.5 - Participation - Current Reg Model Exists setting correct site and persons');

      const sites = this.subjectReservation.organizations;
      if (!sites || sites.length === 0) {
        const siteLength = this.siteLevelList.filter(siteLevel => siteLevel.required === true).length;
        this.subjectReservation.organizations = new Array(siteLength);
        this.siteLevelList.forEach((siteLevel, ix) => {
          if (siteLevel.required) {
            this.subjectReservation.organizations[ix] = null;
          }
        });

      } else {
        // if sites already assigned find them and set the dropdowns

        //***************** code to handle non-standard sites and staff ************************* */
        // EXCEPTION:  	If Alliance credit group and treating location non-standard, allow this to display and to be updated but do no allow a new treating location to be assigned to Alliance unless its on the eresearch alliance roster.    
        const cgSite: StudySiteLevel = this.getCreditGroupSite();
        const tlSite: StudySiteLevel = this.getTreatingLocation();
        if (this.subjectReservation) {
          const resSites = this.subjectReservation.organizations;
          if (resSites.length > 0) {
            const cgIdx = resSites.findIndex(eachS => eachS && cgSite && eachS.siteLevelId === cgSite.siteLevel.id);
            if (cgIdx > -1) { // have a credit group


              if (this.subjectReservation.organizations[cgIdx].ctepId === 'ALLIANCE' &&
                this.subjectReservation.organizations[cgIdx].nonStdOrgId &&
                this.subjectReservation.organizations[cgIdx].nonStdOrgId > 0) { // credit group is non-standard Alliance
                const tlIdx = resSites.findIndex(eachS => eachS && eachS.siteLevelId === tlSite.siteLevel.id);
                if (tlIdx > -1) {
                  if (this.subjectReservation.organizations[tlIdx].nonStdOrgId &&
                    this.subjectReservation.organizations[tlIdx].nonStdOrgId > 0) {
                    this.isNonStdTLReadOnly = true; // can't change TL unless they change the CG to standard
                    this.subjectReservation.organizations[cgIdx].name = 'Alliance (non-standard)'; // display it as non-standard
                    const nonStdAlliance = _.clone(this.subjectReservation.organizations[cgIdx]);
                    this.creditGroupOrgList.push(nonStdAlliance); // add it to the dropdown so it will display
                  }
                }
              }

              // Add caseOrganizationTypeId from existing creditGroup to selected CGP
              if (this.isNonStdCreditGroup()) {
                const site = resSites.find(thisSite => thisSite.siteLevelCode === 'CGP');
                this.creditGroupOrgList.forEach(creditGrp => {
                  creditGrp.caseOrganizationTypeId = site.caseOrganizationTypeId;
                });
              }
            }
          }
        }
        //***************** end code to handle non-standard sites and staff ************************* */


        let memberObs: Observable<any>;
        let hasTreatingLocation = false;
        let treatingLocationIdx = -1;

        let siteLevel: StudySiteLevel;
        let selectedOrg: Organization = null;
        sites.forEach((site, idx) => {
          // selectedOrg = null;
          const thisStudySiteLevel = this.siteLevelList.find(eachStudySiteLevel => site && eachStudySiteLevel.siteLevel.id === site.siteLevelId);
          if (thisStudySiteLevel && thisStudySiteLevel.siteLevel) {
            // we already got the group list, but based on previous selections find the memberships or treating location list
            // TODO: we really only need to do this if it not read only
            if (thisStudySiteLevel.siteLevel.code === this.SITE_CODE_MEMBERSHIP) {
              selectedOrg = null;
              siteLevel = this.getMembershipSite();
              selectedOrg = this.getSelectedParentOrg(idx, siteLevel, 'creditGroupOrgList');
              // check that the selected Org doesn't have the same siteLevelId as the current one
              // this can happen when we don't have a matching credit group
              if (selectedOrg && selectedOrg.siteLevelId !== siteLevel.siteLevel.id) {
                this.membershipOrgList = [];
                if (selectedOrg.relationshipPk && selectedOrg.relationshipPk > 0) {
                  memberObs = this.getOrgListObservable(selectedOrg.relationshipPk,
                    siteLevel, 'membershipOrgList');
                }
              }
            } else if (thisStudySiteLevel.siteLevel.code === this.SITE_CODE_TREATING_LOCATION) {
              hasTreatingLocation = true;
              treatingLocationIdx = idx;
              if ((memberObs) || (selectedOrg)) {
                this.treatingLocationOrgList = [];
              }
            }
          }
        });

        if (hasTreatingLocation) {
          siteLevel = this.getTreatingLocation();
        }

        if (memberObs) {
          this.subscriptions.push(
            memberObs
              .subscribe(() => {
                selectedOrg = this.getSelectedParentOrg(treatingLocationIdx, siteLevel, 'membershipOrgList');
                this.populateTreatingLocationSiteList(selectedOrg, siteLevel);
              }, (err) => { },
                () => {
                })
          );
        } else {
          // There was no top level Credit Group
          if (selectedOrg) {
            this.populateTreatingLocationSiteList(selectedOrg, siteLevel);
          } else {
            // There were no top level Credit Groups or Membership sites
            this.populatePersonsForTreatingLocation();
          }

        }
      }

      // Initialize non-standard ctepTL
      const studySiteLevel = this.getTreatingLocation();
      const filteredOrgs = this.subjectReservation.organizations.filter(site => site && site.siteLevelId && site.siteLevelId === studySiteLevel.siteLevel.id);
      if (filteredOrgs && filteredOrgs.length > 0 && filteredOrgs[0]) {
        this.ctepTL = filteredOrgs[0].ctepId;
        if (this.isNonStdCreditGroup()) {
          this.initialTLCaseOrgId = filteredOrgs[0].caseOrganizationId;
          this.initialTLCaseOrgTypeId = filteredOrgs[0].caseOrganizationTypeId;
        }
      }

      if (this.subjectReservationId <= 0) {
        if (this.studySlotGroups && this.studySlotGroups.slotGroupTypeCode === 'None') {
          if (this.studySlotGroups.studyReservationSlotGroups.length > 0) {
            //By default setting active slot group when slot group format is "None" while creating subject reservation [slot group selection drop down is hidden when slot group format is "None"]
            this.subjectReservation.studyReservationSlotGroup = this.studySlotGroups.studyReservationSlotGroups[0];
          }
        }
      }
    }
  }

  /**
   * Returns a blank person to start off
   */
  getNullPersonForStudyPerson(): Person {
    const p: Person = _.clone(this.blankPerson);
    return p;
  }



  /**
   * 
   * @param viewRef The view container
   * @param property 
   * @returns 
   */
  getUILayoutElement(viewRef: ViewContainerRef, property: string): UiLayoutElement {

    const uiElement = {
      sectionId: '',
      node: viewRef,
      propertyName: property,
      errorMessages: []
    };

    return uiElement;
  }



  /**
   * Handles the Selection of an org in the Treatment Location dropdown
   */
  treatingLocationSelected(indx: number = 2) {
    this.clearDependencies(2);
    const site = this.getTreatingLocation();
    let org = this.subjectReservation.organizations.find(item => item && site?.siteLevel && item.siteLevelId === site.siteLevel.id);

    // if we started with a non-standard TLC and switched to standard, need to set the caseOrganizationId so is updated instead of inserted
    if (this.initialTLCaseOrgId && this.initialTLCaseOrgId > 0) {
      const idx = this.subjectReservation.organizations.findIndex(eachS => eachS && eachS.siteLevelId === site.siteLevel.id);
      this.subjectReservation.organizations[idx].caseOrganizationId = this.initialTLCaseOrgId;
      this.subjectReservation.organizations[idx].caseOrganizationTypeId = this.initialTLCaseOrgTypeId;
    }

    if (site?.networkFlag) {
      // If the Org does not have the relationshipPk set
      if (org !== undefined && org.relationshipPk !== undefined && org.relationshipPk === 0 && this.membershipOrgList.length > 0) {
        org = this.membershipOrgList.find(eachItem => eachItem && eachItem.orgNumber === org.orgNumber);
      }

      if (org && org.relationshipPk) {
        this.getPersonsFromService(org.relationshipPk, true);
      }
    } else {
      if (org && org.studyNetworkId) {
        this.getPersonsFromService(org.studyNetworkId, false);
      }
    }

  }


  /**
   * Clears the dependencies for the changed site
   * 
   * @param siteIndex The current site being changed
   */
  clearDependencies(siteIndex: number) {
    // if there are child sites..
    //check if it is non-stadard site
    let isNonStandardSite = false;
    let isExistingSiteNonStd = false;
    if (this.isNonStdCreditGroup()) {
      isNonStandardSite = true;
    }
    const sites = this.subjectReservation.organizations;

    const existingSite = sites.find(site => site != null && site.nonStdOrgId > 0);
    if (existingSite) {
      isExistingSiteNonStd = true;
    }

    if (siteIndex === 0 && sites.length > 1) {
      if (isNonStandardSite) {
        const mbrSite = sites.find(site => site && site.siteLevelCode === 'MBR');
        const mbrIndex = sites.findIndex(site => site && site.siteLevelCode === 'MBR');
        const studySiteLevel = this.getMembershipSite();
        if (mbrSite) {
          this.subjectReservation.organizations[mbrIndex] = this.nullOrganization;
          this.subjectReservation.organizations[mbrIndex].isNonStandardSite = true;
          this.subjectReservation.organizations[mbrIndex].caseOrganizationId = mbrSite.caseOrganizationId;
          this.subjectReservation.organizations[mbrIndex].caseOrganizationTypeId = mbrSite.caseOrganizationTypeId;
          this.subjectReservation.organizations[mbrIndex].siteLevelCode = studySiteLevel.siteLevel.code;
          this.subjectReservation.organizations[mbrIndex].siteLevelId = studySiteLevel.siteLevel.id;
        }

        //find existing treating location site and clear, but leave caseOrganizationId and type
        const tlcSite = sites.find(site => site && site.siteLevelCode === 'TLC');
        const tlcIndex = sites.findIndex(site => site && site.siteLevelCode === 'TLC');
        if (tlcSite) {
          this.subjectReservation.organizations[tlcIndex].name = '';
          this.subjectReservation.organizations[tlcIndex].ctepId = '';
          this.subjectReservation.organizations[tlcIndex].abrev = '';
          this.subjectReservation.organizations[tlcIndex].relationshipPk = 0;
          this.subjectReservation.organizations[tlcIndex].caseOrganizationTypeId = null;
        }
      } else if (isExistingSiteNonStd) {
        // happens when site changes from non-standard to standard site, we have to add in a membership site
        this.subjectReservation.organizations[1] = null;
        //manually add slot for membership (if there is a CGP then you have to have MBR from a study config standpoint)
        this.subjectReservation.organizations.push(null);
      } else {
        this.subjectReservation.organizations[1] = null;
        this.subjectReservation.organizations[2] = null;
      }


      // clear non-standard structure
      this.ctepTL = '';
    }
    if (siteIndex === 1 && sites.length > 2) {
      this.subjectReservation.organizations[2] = null;
    }
    this.subjectReservation.contactStaff = this.getNullPersonForStudyPerson(); // null;
    this.siteContacts = []; // Clears persons list when site is changed/cleared.
  }




  /**
   * Retrieves the list of lookup orgs for Treating locations based on the membership selected
   * 
   * @param index The index of the Membersihp org in the array
   */
  getTreatingLocationOrgs() {
    this.treatingLocationOrgList = [];
    const siteLevel: StudySiteLevel = this.getTreatingLocation();

    const index: number = this.siteLevelList.findIndex(x => x && x.required &&
      x.siteLevel.id === siteLevel.siteLevel.id);
    let selectedOrg: Organization = null;
    selectedOrg = this.getSelectedParentOrg(index, siteLevel, 'membershipOrgList');
    if (selectedOrg && selectedOrg.relationshipPk && selectedOrg.relationshipPk > 0) {
      const obsList: Observable<any>[] = [];
      obsList.push(
        this.getOrgListObservable(selectedOrg.relationshipPk,
          siteLevel, 'treatingLocationOrgList'));

      this.subscriptions.push(
        forkJoin(obsList)
          .subscribe((

          ) => {

          }, (err) => { },
            () => {
            })
      );

    }
  }


  /**
     * Compare objects, used in html for matching coded lists
     */
  compareFnCg(a, b) {
    if (a || b) {
      return a && b && a.orgNumber === b.orgNumber;
    } else {
      return true;
    }
  }

  compareFnNonStd(a, b) {
    if (a || b) {
      return a && b && a.nonStdOrgId === b.nonStdOrgId;
    } else {
      return true;
    }
  }


  compareFnMember(a, b) {
    if (a || b) {
      return a && b && a.orgNumber === b.orgNumber && a.relationshipPk === b.relationshipPk;
    } else {
      return true;
    }
  }
  compareFnTl(a, b) {
    if (a || b) {
      return a && b && a.orgNumber === b.orgNumber;
    } else {
      return true;
    }
  }

  /**
       * Compare objects, used in html for matching coded lists
       */
  comparePersonFn(a: Person, b: Person) {
    if (a || b) {
      return a && b && a.pk === b.pk;
    } else {
      return true;
    }
  }


  /**
       * Compare objects, used in html for matching coded lists
       */
  compareSlotGroups(a: StudyReservationSlotGroup, b: StudyReservationSlotGroup) {
    if (a || b) {
      return a && b && a.studySchemaEventReservationGroupId === b.studySchemaEventReservationGroupId;
    } else {
      return true;
    }
  }

  /***** Begin Person ******* */

  /**
   * Returns the list of person for this relationshipId
   * 
   * @param relationshipId The relationshipId to evaluate
   */
  getPersonsFromService(relationshipId: number, isNetwork: boolean): void {

    // let fetchPersons$: Observable<Person[]> = this.patientService.getPersonsForRelationship(this.studyId, relationshipId);

    if (isNetwork) {
      this.subscriptions.push(
        this.patientService.getPersonsForRelationship(this.studyId, relationshipId, "SITREG")
          .subscribe((results) => {
            this.siteContacts = results;
            this.setSiteContact();
            this.siteContacts.sort((a, b) => (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : 0));
            //}));
          })
      );
    } else {
      this.subscriptions.push(
        this.patientService.getPersonsForDirectParticipant(this.studyId, relationshipId, "SITREG")
          .subscribe((results) => {
            this.siteContacts = results;
            this.setSiteContact();
            this.siteContacts.sort((a, b) => (a.fullName > b.fullName) ? 1 : ((b.fullName > a.fullName) ? -1 : 0));
          })
      );
    }


  }

  setSiteContact() {
    if (this.subjectReservationId <= 0) {
      //If subjectReservationId <=0 and reservedBy person is found in site contact list then by default the selected person for site contact is reserveBy person.
      const reservedByPersonFound = this.siteContacts.find(x => x.pk === this.subjectReservation.reservedBy.pk);
      if (reservedByPersonFound) {
        this.subjectReservation.contactStaff = reservedByPersonFound;
      }
    }
  }


  /**
   * Returns person lastName, firstName to drop downs
   * if the registration office, show  person lastName, firstName and person status
   *
   * @param singlePerson
   */
  getPersonData(singlePerson: Person): string {
    // if the registration office, show person status also
    if (singlePerson) {
      if (this.userService.hasCreateAccess(ACTIVITY_NAMES.ACTIVITY_PATIENT_REGISTRATION) && singlePerson.personRoleStatus != null && singlePerson.personRoleStatus !== '') {
        if (singlePerson.fullName == null) {
          return singlePerson.lastName + ', ' + singlePerson.firstName + ' - ' + singlePerson.personRoleStatus;
        } else {
          return singlePerson.fullName + singlePerson.personRoleStatus;
        }
      }
      if (singlePerson.fullName == null) {
        return singlePerson.lastName + ', ' + singlePerson.firstName;
      } else {
        return singlePerson.fullName;
      }
    } else { return ''; }
  }

  // /*********End Person */


  getNonStdSiteInformation(siteIndex: number) {
    if (this.ctepTL && this.ctepTL.length > 0) {
      this.ctepTL = this.ctepTL.toLocaleUpperCase();
      this.subscriptions.push(
        this.patientService.getNonStdSiteInformation(this.ctepTL)
          .subscribe((results) => {

            const tlStudySiteLevel: StudySiteLevel = this.getTreatingLocation();
            if (this.subjectReservation) {
              const sites = this.subjectReservation.organizations;
              if (sites.length > 0) {
                const idx = sites.findIndex(eachS => eachS && eachS.siteLevelId === tlStudySiteLevel.siteLevel.id);
                if (idx > -1) {
                  results.siteLevelId = this.subjectReservation.organizations[idx].siteLevelId;
                  results.siteLevelCode = this.subjectReservation.organizations[idx].siteLevelCode;
                  results.caseOrganizationId = this.subjectReservation.organizations[idx].caseOrganizationId;
                  results.caseOrganizationTypeId = this.subjectReservation.organizations[idx].caseOrganizationTypeId;
                  this.nonStdTL = results;
                  this.subjectReservation.organizations[idx] = results;

                }
                else {
                  results.siteLevelId = tlStudySiteLevel.siteLevel.id;
                  results.siteLevelCode = tlStudySiteLevel.siteLevel.code;
                  results.caseOrganizationId = -1;
                  this.nonStdTL = results;
                  this.subjectReservation.organizations[2] = results;
                }
              }
            }
          }, (err) => { },
            () => {
            })
      );
    }
    else {
      const tlStudySiteLevel: StudySiteLevel = this.getTreatingLocation();
      if (this.subjectReservation) {
        const sites = this.subjectReservation.organizations;
        if (sites.length > 0) {
          const idx = sites.findIndex(eachS => eachS && eachS.siteLevelId === tlStudySiteLevel.siteLevel.id);
          if (idx > -1) {
            // Keep the caseOrganizationId, clear out the other important stuff
            this.subjectReservation.organizations[idx].name = '';
            this.subjectReservation.organizations[idx].ctepId = '';
            this.subjectReservation.organizations[idx].abrev = '';
            this.subjectReservation.organizations[idx].relationshipPk = 0;
            this.subjectReservation.organizations[idx].caseOrganizationTypeId = null;
          }
        }
      }
    }

  }

  getNonStdTLName() {
    const studySiteLevel = this.getTreatingLocation();
    const filteredOrgs = this.subjectReservation.organizations.filter(site => site && site.siteLevelId && site.siteLevelId === studySiteLevel.siteLevel.id);
    if (filteredOrgs && filteredOrgs.length > 0) {
      if (filteredOrgs[0].abrev) {
        return filteredOrgs[0].abrev; // abrev or name?
      }
      else if (filteredOrgs[0].name) {
        return filteredOrgs[0].name;
      }
    }
    return '';
  }

  /**
   * Executes when clicks on reserve button
   */
  reserve() {
    this.validate(false);
  }

  validate(bypass: boolean) {
    this.clearErrors();
    this.subscriptions.push(
      this.patientService.validatePatientReservation(this.subjectReservation, this.studyId, bypass)
        .subscribe(results => {

          const modalArg: ModalDialogArg = {
            dialogType: 'modal-warn',
            contentType: 'Custom',
            variableData: null,
            displayMessage: 'Changes Saved',
            displayName: ''
          };

          if (this.subjectReservationId <= 0) {
            this.subscriptions.push(
              this.patientService.createPatientReservation(this.subjectReservation, this.studyId)
                .subscribe(reservationResult => {
                  this.isSitesReadOnly = true;
                  this.subjectReservation = reservationResult;
                  this.subjectReservationId = this.subjectReservation.subjectReservationId;
                  this.originalSubjectReservation = reservationResult;
                  this.tileStatus = this.statusCompleted;
                }, (err: any) => {
                  modalArg.displayMessage = "Failed on saving data";
                  modalArg.dialogType = "modal-error";
                  this.displayChangesSavedDialog(modalArg);
                })
            );
          } else {
            // Update reservation.
            this.subscriptions.push(
              this.patientService.updatePatientReservation(this.subjectReservation, this.studyId)
                .subscribe(reservationResult => {
                  this.subjectReservation = reservationResult;
                  this.isReservationUpdate = true;
                  this.originalSubjectReservation = _.cloneDeep(reservationResult);
                  this.displayChangesSavedDialog(modalArg);
                }, (err: any) => {
                  modalArg.displayMessage = "Failed on updating data";
                  this.displayChangesSavedDialog(modalArg);
                })
            );
          }


        },
          (err: any) => {
            this.reservationErrors = this.doHandleValidationError(err);
            this.processValidationErrors(this.reservationErrors);
            this.tileStatus = this.statusError;
          })
    );
  }

  displayChangesSavedDialog(modalArg: ModalDialogArg) {
    this.subscriptions.push(
      this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
        .subscribe(result => {
          if (result) {
            // show another with remove reserve options
          }
        })
    );
  }

  /**
   * Common exception handler for managing exception handling
   * 
   * @param error the exception caught during the http POST / PUT
   */
  doHandleValidationError(error: any): PatientRegError[] {
    let patientRegErrors: PatientRegError[] = [];

    if (error.status === 400) {
      if (error && error.error && error.error.errors !== undefined && error.error.errors.length > 0) {
        const respErr = error.error.errors; // [{"source":"patientForms.10330","message":"value is required"},{"source":"patientForms.10333","message":"value is required"}];
        patientRegErrors = respErr;
        this.patientService.setRegValidationErrors(patientRegErrors);
        this.processValidationErrors(patientRegErrors);
      }
    }

    return patientRegErrors;
  }

  /**
   * Override errors
   */
  overrideErrors() {
    this.validate(true);
  }


  /**
 * Process the validation by matching the error source to the ui element
 * 
 * @param validationErrors The validation errors generated when checking
 */
  processValidationErrors(validationErrors: PatientRegError[]) {
    // first clear existing errors
    this.clearErrors();
    if (validationErrors && validationErrors.length > 0) {
      validationErrors.forEach(eachError => {
        if (this.uiLayoutList.length > 0) {
          const errIdx = this.uiLayoutList.findIndex(eachEr => eachEr.propertyName === eachError.source);
          if (errIdx > -1) {
            this.uiLayoutList[errIdx].errorMessages.push(eachError.message);
          }
        }
      });
      this.groupAndPushErrors();

    }
  }

  /**
    * Navigate the user to the validation errors
    */
  reviewErrors() {
    if (this.tileStatus === this.statusError) {
      const validUIErrors: UiLayoutElement[] = this.uiLayoutList.filter(eachErr => eachErr.errorMessages.length > 0);

      if (validUIErrors.length > 0) {
        const property = validUIErrors[0].propertyName;
        if (property.includes(this.personValidationProperty)) {
          this.setSelectedContentDiv(this.participatingPersonsViewContent);
        } else if (property.includes(this.sitesValidationProperty)) {
          this.setSelectedContentDiv(this.siteInfoViewContent);
        } else if (property.includes(this.patientInfoValidationProperty)) {
          this.setSelectedContentDiv(this.patientInfoViewContent);
        } else if (property.includes(this.slotGroupValidationProperty)) {
          this.setSelectedContentDiv(this.slotGroupViewContent);
        }

      }

      if (this.selectedContentDiv) {
        this.selectedContentDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

      this.tileStatus = null;
    }
  }

  /**
   * Get tile name
   */
  getTileName() {
    if (this.tileStatus === this.statusError) {
      return this.tileNameValidate;
    } else if (!this.isReservationUpdate) {
      return this.tileNameReservation;
    } else {
      return this.updateTileName;
    }
  }


  /**
 * Gets the title of the Register tile
 */
  getReservationTitle(): string {
    if (this.isValidateStatus(this.statusError)) {
      const validUIErrors: UiLayoutElement[] =
        this.uiLayoutList.filter(
          (eachErr) => eachErr.errorMessages.length > 0
        );
      return validUIErrors.length.toString();
    } else if (this.isValidateStatus(this.statusCompleted)) {
      return this.statusCompleted;
    }
    return " ";
  }


  /**
* Returns the subtitle to be displayed on the register tile
*/
  getReservationSubtitle() {
    if (this.isValidateStatus(this.statusError)) {
      if (this.reservationErrors.length === 1) {
        return "Error Found";
      }
      return "Errors Found";
    }
    if (this.tileStatus === this.statusCompleted) {
      return (
        "Reservation ID: " + this.subjectReservation.subjectReservationId
      );
    }
    return " ";
  }


  /**
   * Cancel button action
   */
  cancel() {

    //Check for any changes applied.
    const isReservationChanged = this.isDataModified();
    if (isReservationChanged) {
      const modalArg: ModalDialogArg = {
        dialogType: 'modal-warn',
        contentType: 'Cancel',
        variableData: null,
        displayMessage: '',
        displayName: ''
      };

      this.subscriptions.push(
        this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
          .subscribe(result => {
            if (result) {
              this.gotoStudyLanding();
            }
          })
      );
    } else {
      // no reservation modified, so take to landing page
      this.gotoStudyLanding();
    }

  }

  /**
   * check for update and cancel button to enable/disble
   */
  isDataModified() {
    if (!this.isReservationUpdate)
      return !(_.isEqual(this.subjectReservation, this.originalSubjectReservation));


    const sDate = this.subjectReservation?.patientInfo?.dateOfBirthString;
    const oDate = this.originalSubjectReservation?.patientInfo?.dateOfBirthString;


    const oFirstName = this.originalSubjectReservation?.patientInfo?.firstName;
    const eFirstName = this.subjectReservation?.patientInfo?.firstName;

    if (oFirstName === eFirstName
      && this.originalSubjectReservation?.patientInfo?.lastName === this.subjectReservation?.patientInfo?.lastName
      && this.originalSubjectReservation?.patientInfo?.middleName === this.subjectReservation?.patientInfo?.middleName
      && this.originalSubjectReservation?.contactStaff?.pk === this.subjectReservation?.contactStaff?.pk
      && sDate === oDate
    ) return false;

    return true;
  }

  /**
   * Go to study landing page
   */
  gotoStudyLanding() {
    this.navigateBackToManageReservation();
  }

  gotoManageReservations() {
    this.navigateBackToManageReservation();
  }

  navigateBackToManageReservation() {
    this.router.navigate(["/studies/" + this.studyId],
      {
        queryParams: {
          'action': globalConst.SEARCH_CRITERIA_TYPE_SLOT_RESERVATIONS
        }
      });
  }


  /**
   * redirect to register subject
   */
  gotoRegisterSubject() {
    const caseDetailId = 0;
    const caseEventId = 0;
    const patientId = '0';
    const url: string = '/studies/' + this.studyId + '/registration/' + patientId + '/' + caseDetailId + '/' + caseEventId;

    this.router.navigate([url], {
      queryParams: {
        'action': globalConst.QS_PARAM_ACTION_REGISTER,
        'reservationNum': this.subjectReservationId
      }
    });

  }

  /**
 * Get if the status is the requested one
 * Statuses are 'Completed' or 'error'
 * 
 * @param status
 */
  public isValidateStatus(status: string): boolean {
    return status === this.tileStatus;
  }

  /**
 * Get if the selected slot has a status of "Enroll"
 * 
 * @param status
 */
  public isEnrollSlot(): boolean {
    return this.subjectReservation?.studyReservationSlotGroup?.groupStatus?.code === "Enroll";
  }

  /**
 * Get if the selected slot has a status of "Enroll"
 * 
 * @param status
 */
  public isReserveButtonDisabled(): boolean {
    return (this.isEnrollSlot() || this.isValidateStatus(this.statusCompleted));
  }

  /**
    * clear Errors being displayed on the UI
    */
  clearErrors(): void {
    // Clear the stack of error that are currently displayed
    this.reservationErrors = [];
    if (this.errorComponents.length > 0) {
      this.errorComponents.forEach((eachError, idx) => {
        eachError.destroy();
      });
      this.errorComponents = [];
    }
    if (this.uiLayoutList.length > 0) {
      this.uiLayoutList.forEach(uiLayout => {
        uiLayout.errorMessages = [];
      });
    }
  }

  /**
 * Groups the errors by their sections
 */
  groupAndPushErrors() {
    const validUIErrors: UiLayoutElement[] = this.uiLayoutList.filter(eachErr => eachErr.errorMessages.length > 0);
    if (validUIErrors.length > 0) {

      validUIErrors.forEach((eachError, errIdx) => {
        const current: UiLayoutElement = eachError;
        const previous: UiLayoutElement = (errIdx === 0 ? null : validUIErrors[errIdx - 1]);
        const next: UiLayoutElement = (errIdx === validUIErrors.length - 1 ? null : validUIErrors[errIdx + 1]);

        let required = true;
        eachError.errorMessages.forEach((errString) => {
          if (errString.indexOf('Comment') >= 0) {
            required = false;
          }
        });

        const errArg: PatientErrorDisplayArg = {
          currentError: current,
          nextError: next,
          required: required,
          previousError: previous, finishElement: this.viewContentReservationTileeDiv
        };

        this.errorComponents.push(this.patientService.showValidationError(current.node, errArg));
      });
    }
  }

  /**
   * 
   * @returns checks current state is RESERVED or not
   */
  isCurrentStateReserved() {
    return this.subjectReservation.reservationStatus?.code?.trim() === 'RESERVED' &&  !this.subjectReservation?.reservationExpired ;
  }

  /**
   * Checks renew button is enabled or not
   * @returns 
   */
  isRenewButtonEnabled() {
    if (this.subjectReservation.dateEmailSent) {
      if (moment(this.subjectReservation.dateEmailSent) <= moment() && !this.subjectReservation?.reservationExpired 
        && this.subjectReservation.reservationStatus?.code?.trim() === 'RESERVED' && !this.isDataModified()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Removes reservation
   */
  removeReservation() {
    //show confirmation modal.
    const modalArg: ModalDialogArg = {
      dialogType: 'modal-warn',
      contentType: 'GenericYesNo',
      variableData: null,
      displayMessage: 'Are you sure you want to Remove this Reservation?',
      displayName: ''
    };

    this.subscriptions.push(
      this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
        .subscribe(result => {
          if (result) {
            this.subjectReservation.removeFlag = true;
            this.updateReservation();
          }
        }));

  }

  /**
   * Used for subject reservation renewal
   */
  renew() {
    this.subjectReservation.renewalFlag = true;
    this.updateReservation();
  }

  updateReservation() {
    this.subscriptions.push(
      this.patientService.updatePatientReservation(this.subjectReservation, this.studyId)
        .subscribe(reservationResult => {
          this.subjectReservation = reservationResult;
          this.isReservationUpdate = true;
          this.originalSubjectReservation = _.cloneDeep(reservationResult);
          if (this.subjectReservation.removeFlag) {
            this.subjectReservation.removeFlag = false;
          }
          if (this.subjectReservation.renewalFlag) {
            this.subjectReservation.renewalFlag = false;
          }

          const modalArg: ModalDialogArg = {
            dialogType: 'modal-warn',
            contentType: 'Custom',
            variableData: null,
            displayMessage: 'The Reservation has been successfully updated',
            displayName: ''
          };

          this.subscriptions.push(
            this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
              .subscribe(result => {
                if (result) {
                  //Nothing to do
                }
              }));
        }, (err: any) => {
        })
    );
  }


  /** begin - custom handling of the preview for the  page********************* */

  isReservationComplete(): boolean {
    return this.tileStatus === this.statusCompleted;
  }

  /**
     * True if the orientation is vertical and the state is collapsed
     */
  isCollapsedVertical(): boolean {
    return this.previewModelHome.isCollapsed && this.previewModelHome.isVertical;
  }



  /**
   * True if the orientation is horizontal and is collapsed
   */
  isCollapsedHorizontal(): boolean {
    return (this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }


  /**
   * True if the orientation is vertical and the state is Expanded
   */
  isExpandedVertical(): boolean {
    return (!this.previewModelHome.isCollapsed && this.previewModelHome.isVertical);
  }



  /**
   * True if the orientation is horizontal and is expanded
   */
  isExpandedHorizontal(): boolean {
    return (!this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }

  /** end - custom handling of the preview for the  page********************* */


  // Private method definitions

  /**
   * Gets the orgList for the selected Site
   * 
   * @param relationshipPk
   * @param siteLevel
   * @param targetCollectionName
   */
  private getOrgListObservable(relationshipPk: number, siteLevel: StudySiteLevel, targetCollectionName: string): Observable<any> {
    let sitecode: string = siteLevel.siteLevel.code;
    if (sitecode.toUpperCase() === 'CGP') {
      // if it's Credit Group, we will pass in 0 for relationshipPk and blank for Sitecode
      relationshipPk = 0;
      sitecode = '';
    }
    const obs: Observable<any> =
      this.patientService.getSiteOrgs(this.studyId,
        relationshipPk, sitecode, siteLevel.networkFlag)
        .pipe(tap(results => {
          if (sitecode === '') {
            sitecode = 'CGP';
          }
          const index: number = this.siteLevelList.findIndex(eachSite => eachSite.siteLevel.code === sitecode);
          if (results && results.length > 0) {
            results.forEach((item: Organization) => {
              item.siteLevelId = siteLevel.siteLevel.id;
              item.name = this.getOrganizationName(item);
            });
            this[targetCollectionName] = results;
          }
        })
        );
    return obs;
  }


  /**
   * If ctepId exists concatonate the ctep id onto the name
   * if it is the registration office, include site relationship type and status
   * 
   * @param org
   */
  private getOrganizationName(org: Organization) {
    let theName = org.name;
    if (org.ctepId !== '' && org.ctepId != null) {
      theName = theName + ' (' + org.ctepId + ')';
    }

    // if the registration office, show site relationship type and status
    if (this.userService.hasCreateAccess(ACTIVITY_NAMES.ACTIVITY_PATIENT_REGISTRATION)) {
      theName = theName + ' - ' + org.type + ' - ' + org.status;
    }
    return theName;
  }

  /**
   * Retrieves the exact site based on the siteLevel Code
   * 
   * @param code The code to locate the site
   */
  private getSiteByCode(code: string): StudySiteLevel {
    if (this.siteLevelList && this.siteLevelList.length > 0) {
      return this.siteLevelList.find(item => item.siteLevel && item.siteLevel.code.toLowerCase() === code.toLowerCase());
    }
    return null;
  }


  /***
   *
   * Populates the Treating location and the dependant persons
   */
  private populateTreatingLocationSiteList(selectedOrg: Organization, siteLevel: StudySiteLevel) {
    if (selectedOrg && selectedOrg.relationshipPk && selectedOrg.relationshipPk > 0) {
      this.getOrgListObservable(selectedOrg.relationshipPk,
        siteLevel, 'treatingLocationOrgList')
        .subscribe(() => {

        }, (err) => { },
          () => {
            this.populatePersonsForTreatingLocation();
          });
    }
  }

  /**
   * Populate the Persons for a Treating Location
   */
  private populatePersonsForTreatingLocation() {
    const site = this.getTreatingLocation();
    let org = this.subjectReservation.organizations.find(item => item && item.siteLevelId === site.siteLevel.id);
    if (site.networkFlag) {

      // If the Org does not have the relationshipPk set
      if (org !== undefined && org.relationshipPk !== undefined && org.relationshipPk === 0 && this.membershipOrgList.length > 0) {
        org = this.membershipOrgList.find(eachItem => eachItem && eachItem.orgNumber === org.orgNumber);
      }
      if (org && org.relationshipPk) {
        this.getPersonsFromService(org.relationshipPk, true);
      }

    } else {
      if (org && org.studyNetworkId) {
        this.getPersonsFromService(org.studyNetworkId, false);
      }
    }

  }

  /**
   * Returns the parent Org for this site
   * 
   * @param index The index of the participatingSites
   * @param siteLevel The siteLevel object
   * @param orgListName The name of the list that is set
   */
  private getSelectedParentOrg(index: number, siteLevel: StudySiteLevel, orgListName: string): Organization {
    let selectedOrg: Organization = null;
    if (siteLevel) {
      if (index > 0) {
        // find the siteLevelId for the siteLevel
        const siteLevelId: number = (this.subjectReservation.organizations[index - 1].siteLevelId);
        if (siteLevelId && siteLevelId > 0) {
          const parentRelationshipPk = this.subjectReservation.organizations[index - 1].relationshipPk;
          selectedOrg = this[orgListName].find(item => item && item.relationshipPk === parentRelationshipPk);
        }
      } else {
        selectedOrg = this.subjectReservation.organizations[index];
      }
    }
    return selectedOrg;
  }


  private setSelectedContentDiv(viewContent: any) {

    if (viewContent && viewContent.length > 0) {
      viewContent.forEach((eachItem, itemIdx) => {
        if (itemIdx === 0) {
          this.selectedContentDiv = this.renderer.selectRootElement(eachItem);
        }
      });
    }

  }



  // end Private method definitions

}
