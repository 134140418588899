<mc-header-bar [headerModel]="headerModel" (saveEvent)="saveChanges()" (cancelEvent)="cancelChanges()"></mc-header-bar>
<div class="form-page">
  <div class="nav-return-bar">
    <ul>
      <li class="has-link ">
        <a (click)="navigateBack()">
          <span class="icon">
            <i class="fas fa-arrow-left"></i>
          </span>
          <span class="link-text">
            Back to Study Landing
          </span>
        </a>
      </li>
    </ul>
  </div>


  <div class="columns cevent-header">
    <div class="column ">
      <h3>Case Event Problems</h3>
    </div>

    <div class="column   edit-bar-section ">
      <mc-edit-bar [selectionCount]="selectedIds.length" [actionSetting]="actionSetting" (selectedActionEvent)="selectedEditAction($event)">
      </mc-edit-bar>
    </div>
  </div>


  <div *ngIf="false">
    <nav class="pagination is-small" role="navigation" aria-label="pagination">
      <a class="pagination-previous">Previous</a>
      <a class="pagination-next">Next page</a>
      <ul class="pagination-list">
        <li>
          <a class="pagination-link" aria-label="Goto page 1">1</a>
        </li>
        <li>
          <span class="pagination-ellipsis">&hellip;</span>
        </li>
        <li>
          <a class="pagination-link" aria-label="Goto page 45">45</a>
        </li>
        <li>
          <a class="pagination-link is-current" aria-label="Page 46" aria-current="page">46</a>
        </li>
        <li>
          <a class="pagination-link" aria-label="Goto page 47">47</a>
        </li>
        <li>
          <span class="pagination-ellipsis">&hellip;</span>
        </li>
        <li>
          <a class="pagination-link" aria-label="Goto page 86">86</a>
        </li>
      </ul>
    </nav>
  </div>

  <div class=" form-table-container  study-setup-content">
    <table class="table is-fullwidth is-borderless is-striped" ngClass="{{config.className || ''}}" role="grid">
      <thead>
        <tr role="row">
          <th class="has-text-centered">
            &nbsp;
          </th>
          <ng-container *ngFor="let column of columns">
            <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}" (sortChanged)="onChangeTable($event)"
              ngClass="{{column.className || ''}}">
              <span> {{column.title}}</span>
              <span *ngIf="config && column.sort" class="sort is-text-centered">
                <span *ngIf="column.sort === ''">
                  <i class="fas fa-sort"></i>
                </span>
                <span *ngIf="column.sort === 'desc'">
                  <i class="fas fa-sort-down"></i>
                </span>
                <span *ngIf="column.sort === 'asc'">
                  <i class="fas fa-sort-up"></i>
                </span>
              </span>
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="showFilterRow">
          <td>&nbsp;</td>
          <td *ngFor="let column of columns">
            <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" class="form-control"
              (tableChanged)="onChangeTable(config)" />
          </td>
        </tr>
        <tr *ngFor="let row of rows;let i=index" (click)="cellClick(row, 'select')" [ngClass]="{'is-selected':isRowSelected(row), 'is-error-row': hasError(row.caseProblemId)}">

          <td class="is-error-cell">
            <div (click)="ignoreClick($event)">
              <mc-error-validation-display-row [idToEvaluate]="row.caseProblemId">
              </mc-error-validation-display-row>
            </div>
          </td>

          <div *ngIf="isReadOnly(row)  then readOnly else editable"></div>
          <ng-template #editable>

              <td  (click)="ignoreClick($event)">
                <div *ngIf="studyId" >
                  <mc-patient-search-by-study [studyId]="studyId" [patientId]="row.patient.patientId" (selectedPatientEvent)="onSelectPatient($event, row)">
            
                  </mc-patient-search-by-study>
                </div>
              </td>
  
              <td>
                <div class="display-message is-display-message-multiline is-display-message-bottom " [ngClass]="{'selected':isInfoSelected(row)}"
                  [attr.data-display-message]="getInfoMessage(row)" (click)="ignoreClick($event)">
                  <span>{{row.patient.firstName}}{{row.patient.lastName}}</span>
                </div>
              </td>
  
              <td>
                <div class="select is-small " (click)="ignoreClick($event)">
                  <select [(ngModel)]="row.problem" [compareWith]="this.compareProblemFn">
                    <option [ngValue]="null">-- Select --</option>
                    <option *ngFor="let problem of problems" [ngValue]="problem" title="{{problem.problemDesc}}">
                      {{problem.problemCode}} 
                    </option>
                  </select>
                </div>
              </td>
  
              <td>
                <div  (click)="ignoreClick($event)">
                  <mc-date-picker-wrapper [inputDate]="row.problemDate" (dateSelected)="onCellUpdated($event, row)">
                  </mc-date-picker-wrapper>
                </div>
              </td>
  
              <td>
                <div  (click)="ignoreClick($event)">
                  <textarea class="textarea comment-column" maxlength=300 rows=3 [(ngModel)]="row.comment" > </textarea>
                </div>
              </td>
  
  
            </ng-template>
          <ng-template #readOnly>
            <td>
              {{row.patient.patientId}}
            </td>
            <td  class="display-message is-display-message-multiline is-display-message-bottom" [ngClass]="{'selected':isInfoSelected(row)}"
                [attr.data-display-message]="getInfoMessage(row)" (click)="ignoreClick($event)">
              <span>{{row.patient.lastName}} {{row.patient.firstName}} {{row.patient.middleName}}</span>
            </td>
            <td (click)="ignoreClick($event)" >
              <span *ngIf="row.problem" class="is-tooltip-info tooltip" [attr.data-tooltip]="row.problem.problemDesc">{{row.problem.problemCode}}</span>
              <span *ngIf="!row.problem" class="has-text-danger">Not Selected</span>
            </td>
            <td (click)="ignoreClick($event)">
              <span>{{row.problemDate | date:'MM/dd/yyyy'}}</span>
            </td>
            <td  (click)="ignoreClick($event)">
              <span>{{row.comment}}</span>
            </td>
          </ng-template>
        </tr>
      </tbody>
    </table>
  </div>
</div>