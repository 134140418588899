import { Country } from './country';
import { Demographic } from './demographic';
import { Ethnicity } from './ethnicity';
import { Gender } from './gender';
import { PaymentMethod } from './payment-method';
import { PossibleValuesHierarchy } from './possible-values-hierarchy';
import { Race } from './race';

export class DemographicSettingsModel {
    constructor(
        public studyId: number | null,
        public studyTemplateId: number | null,
        public studyFirstNameDemographic: Demographic | null,
        public studyMiddleNameDemographic: Demographic | null,
        public studyLastNameDemographic: Demographic | null,
        public studyDOBDemographic: Demographic | null,
        public studyPostalCode: Demographic | null,
        public studyOtherSubjectId: Demographic | null,
        public studyMedDRAClassification: StudyMedDRAClassification | null,
        public studyRaceClassification: Race | null,
        public studyEthnicityClassification: Ethnicity | null,
        public studyGenderClassification: Gender | null,
        public studyPaymentClassification: PaymentMethod | null,
        public studyCountryClassification: Country | null,
        public studyMultipleRegistrations: Demographic | null
    ) { }
}

// export class StudyRaceClassification {
//     constructor(
//         public studyId: number | null,
//         public studyTemplateId: number | null,
//         public studyRaceClassificationId: number | null,
//         public raceCodeClassificationId: number | null,
//         public required: boolean | null,
//         public tupleVersion: number | null,
//         public possibleValues?: PossibleValuesHierarchy[] | null
//     ) { }
// }
// export class StudyEthnicityClassification {
//     constructor(
//         public studyId?: number | null,
//         public studyTemplateId?: number | null,
//         public studyEthnicityClassificationId?: number | null,
//         public ethnicityClassificationId?: number | null,
//         public required?: boolean | null,
//         public tupleVersion?: number | null,
//         public possibleValues?: PossibleValuesHierarchy[] | null
//     ) { }
// }
// export class StudyGenderClassification {
//     constructor(
//         public studyId?: number | null,
//         public studyTemplateId?: number | null,
//         public studyGenderClassificationId?: number | null,
//         public genderClassificationId?: number | null,
//         public required?: boolean | null,
//         public tupleVersion?: number | null,
//         public possibleValues?: PossibleValuesHierarchy[] | null
//     ) { }
// }

// export class StudyPaymentClassification {
//     constructor(
//         public studyId?: number | null,
//         public studyTemplateId?: number | null,
//         public studyPaymentClassificationId?: number | null,
//         public paymentMethodClassificationId?: number | null,
//         public required?: boolean | null,
//         public tupleVersion?: number | null,
//         public possibleValues?: PossibleValuesHierarchy[] | null
//     ) { }
// }

// export class StudyCountryClassification {
//     constructor(
//         public studyId?: number | null,
//         public studyTemplateId?: number | null,
//         public studyCountryClassificationId?: number | null,
//         public countryClassificationId?: number | null,
//         public required?: boolean | null,
//         public tupleVersion?: number | null,
//         public possibleValues?: PossibleValuesHierarchy[] | null
//     ) { }
// }

export class StudyMedDRAClassification {
    constructor(
        public studyId: number | null,
        public studyTemplateId: number | null,
        public studyMedDRAVersionId?: number | null,
        public medDRAVersionId?: number | null,
        public required?: boolean | null,
        public tupleVersion?: number | null,
        public possibleValues?: PossibleValuesHierarchy[] | null
    ) { }
}
