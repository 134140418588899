import { StudyRecord } from './study-record';
import { StudyAccrualCount } from './study-accrual-count';
import { Code } from './code';
import { StudyFormVariable } from './study-form-variable';


export class AncillaryStudy {
    constructor(
        // public parentProtocolNumber: string,
        // public parentStudyId: number | null,
        // public parentDataCenterId: number | null,
        // public relatedStudySchemaEventId: number | null
        public ancillaryStudyDetailId: number,
        public parentProtocolNumber: string,
        public parentStudyId: number | null,
        public studyRelationId: number,
        public studyRelationTypeId: number,
        public studyRelationTupleVersion: number,
        public parentDataCenterId: number | null,
        public relatedStudySchemaEventId: number | null,
        public relatedKeyEventMappingId: number | null,
        public relatedStudySchemaEventLabel: string | null,
        // public relatedStudySchemaEvent: Code | null,
        public study: StudyRecord,
        public studyAccrual: StudyAccrualCount,
        public patientEnrollment: Code | null,
        public consentVariables: StudyFormVariable[] | null,
        public isBioms: boolean ,
        public consentVariableCondition : Code | null




    ) { }
}
