<nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
  <ul>
    <li *ngFor="let row of displayBreadCrumbs; let rowIdx = index">
      <a (click)="navigateTo($event,row, rowIdx)">
        {{row.label}} </a>
    </li>
    <li class="is-active" *ngIf="currentCrumbs && currentCrumbs.length > 0">
      <a (click)="nonNavLink($event)">
        {{currentCrumbs[0].label}}
      </a>
    </li>
  </ul>
</nav>
