import { Injector } from '@angular/core';

export class AppInjector {

  private static injector: Injector;


  /**
   * Sets the Injector
   */
  static setInjector(injector: Injector) {
    AppInjector.injector = injector;
  }

  /**
   * Gets the Injector
   */
  static getInjector(): Injector {
    return AppInjector.injector;
  }

}
