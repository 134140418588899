export class StandardOptionModel {
    standard: number;
    name: string;
    constructor(standardId: number, name: string) {
        this.standard = standardId;
        this.name = name;
    }
}
export class NameSettingOptionModel {
    demographicId: number;
    name: string;
    constructor(demographicId: number, name: string) {
        this.demographicId = demographicId;
        this.name = name;
    }
}
