import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EditBarActions } from 'app/common/model/edit-bar-actions';
import { UpDownModel } from 'app/common/model/up-down-model';
import { EditBarUpDownActions } from 'app/common/model/edit-bar-up-down-actions';

export enum EditBarEventValues {
  ADD = 'add',
  EDIT = 'edit',
  INFO = 'info',
  FILTER = 'filter',
  DELETE = 'delete',
  UP = 'up',
  DOWN = 'down'
}

@Component({
  selector: 'mc-edit-bar',
  templateUrl: './edit-bar.component.html',
  styleUrls: ['./edit-bar.component.scss']
})
export class EditBarComponent implements OnInit {

  @Input() selectionCount: number;

  @Input() isMultiSelectEnable?: boolean;
  /**
   * This variable will handle edit selection count explicitely if needed
   * If not provided, it will use the selectionCount
   */
  @Input() editSelectionCount?: number = null;
  @Input() upDownModel?: EditBarUpDownActions = { isFirstRow: false, isLastRow: false };
  @Input() actionSetting: EditBarActions;
  @Output() selectedActionEvent = new EventEmitter();

  actionSelected = '';

  ADD = 'add';
  EDIT = 'edit';
  INFO = 'info';
  FILTER = 'filter';
  DELETE = 'delete';
  UP = 'up';
  DOWN = 'down';




  constructor() { }

  ngOnInit() {
  }

  fireEvent() {
    this.selectedActionEvent.emit(this.actionSelected);
  }

  actionClicked(action) {
    if (!this.isActionDisabled(action)) {
      this.actionSelected = action;
      this.fireEvent();
    }
  }

  /**
   * Returns true / false to enable / disable the button
   * @param action The action button to evaluate
   */
  isActionDisabled(action) {
    if (this.actionSetting.isEditBarDisabled) {
      return true;
    }
    const len = this.selectionCount;
    let eVal = true;
    switch (action) {
      case this.ADD:
        eVal = (this.actionSetting.isAddDisabled);
        break;
      case this.EDIT:
        if (!this.actionSetting.isEditDisabled) {
          if (this.isMultiSelectEnable) {
            eVal = len <= 0;
          } else if (this.editSelectionCount != null) {
            eVal = this.editSelectionCount !== 1;
          } else {
            eVal = len !== 1;
          }
        } else {
          eVal = true;
        }
        break;
      case this.INFO:
        if (this.actionSetting.isInfoDisabled == null) {
          eVal = false;
        } else if (!this.actionSetting.isInfoDisabled) {
          eVal = len !== 1;
        } else {
          eVal = true;
        }
        break;
      case this.FILTER:
        if (!this.actionSetting.isFilterDisabled) {
          eVal = false;
        } else {
          eVal = true;
        }
        break;
      case this.UP:
        eVal = (this.upDownModel == null || this.upDownModel.isFirstRow || len !== 1);
        break;
      case this.DOWN:
        eVal = (this.upDownModel == null || this.upDownModel.isLastRow || len !== 1);

        break;
      case this.DELETE:
        if (!this.actionSetting.isDeleteDisabled) {
          eVal = len == 0;
        } else {
          eVal = true;
        }
        break;
    }
    return eVal;
  }

  /**
   * Returns true / false to display the button
   * @param action The action button to evaluate
   */
  isActionDisplayed(action) {
    let eVal = true;
    switch (action) {
      case this.UP:
        eVal = (this.actionSetting.isUpDisabled != null);
        break;
      case this.DOWN:
        eVal = (this.actionSetting.isDownDisabled != null);
        break;
      case this.INFO:
        eVal = (this.actionSetting.isInfoDisabled != null);
        break;
      case this.FILTER:
        eVal = (this.actionSetting.isFilterDisabled != null);
        break;

    }
    return eVal;
  }

}
