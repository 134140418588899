import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { McTableModule } from 'app/common/directives/table/mc-table.module';
import { SharedModule } from 'app/common/shared.module';
import { EnrollmentCorrectionsComponent } from './enrollment-corrections/enrollment-corrections.component';
import { PatientApprovalComponent } from './patient-approval/patient-approval.component';
import { PatientRegPreviewComponent } from './patient-reg-preview/patient-reg-preview.component';
import { PatientTransferPreviewComponent } from './patient-transfer-preview/patient-transfer-preview.component';
import { PatientTransferComponent } from './patient-transfer/patient-transfer.component';
import { PatientComponent } from './patient.component';
import { PostProcessingComponent } from './post-processing/post-processing.component';
import { PostProcessingHistoryPreviewComponent } from './post-processing/post-processing-history-preview/post-processing-history-preview.component';
import { PostProcessingHistoryPreviewPBComponent } from './post-processing/post-processing-history-preview-pb/post-processing-history-preview-pb.component';
import { PostProcessingHistoryPreviewPMComponent } from './post-processing/post-processing-history-preview-pm/post-processing-history-preview-pm.component';

@NgModule({

  imports: [
    CommonModule,
    SharedModule,
    FormsModule, ReactiveFormsModule,
    McTableModule,
  ],
  declarations: [
    PatientComponent,
    PatientTransferComponent,
    PatientApprovalComponent,
    EnrollmentCorrectionsComponent,
    PostProcessingComponent,
    PatientRegPreviewComponent,
    PatientTransferPreviewComponent,
    PostProcessingHistoryPreviewComponent,
    PostProcessingHistoryPreviewPBComponent,
    PostProcessingHistoryPreviewPMComponent
  ],
})
export class PatientModule { }
