<mc-header-bar [headerModel]="headerModel">
</mc-header-bar>

<div class="container is-fluid form-page-ct">
  <div class="" [ngClass]="{ 'switchToHorizontal': previewModelHome.isVertical,'is-vertical-open': previewModelHome.isCollapsed  }">
    <!-- <div  id="formSection"> -->
    <div id="mainContainer" [ngClass]="{'switchVertical columns': previewModelHome.isVertical }">
      <div [ngClass]="{ 'column is-main-section-vertical': previewModelHome.isVertical,
       'is-6' : isExpandedVertical(), 'is-11' : isCollapsedVertical() }"
        class="main-section" id="mainSection">

        <div class="search-section form-page" id="main-content">
          <form novalidate>
            <div class="columns is-marginless">
              <div class="column form-content is-12 ">
                <mc-search (searchSelectionEvent)="doSearch($event)">
                </mc-search>
                <mc-admin-tiles (displayTypeSelectedEvent)="tileClicked($event)" [previewModel]="previewModelHome">

                </mc-admin-tiles>
              </div>              
            </div>
          </form>

        </div>
      </div>
      
      <div class="preview-section" id="previewSection"
           [ngClass]="{ 'togglePerviewOverlay' : isExpandedHorizontal(),                
            'is-6 preview-vertical-expanded' : isExpandedVertical(),
             'is-1' : isCollapsedVertical(),
            'column ': previewModelHome.isVertical }">
        <mc-preview [formTitle]="" [formCaption]="previewTitle" [previewModel]="previewModelHome" #previewSection>
          <div preview-data>
            <div [ngClass]="{'is-hidden' : !displaySearchResults}">
              <mc-protocol-search-results (returnToSearchEvent)="returnToSearch()">
              </mc-protocol-search-results>
            
          
              <mc-patient-search-results (returnToSearchEvent)="returnToSearch()"
              [hostLandingPageEnum]="homeLandingPageEnum">
              </mc-patient-search-results>
            </div>
            <mc-studies-construction-tile-results *ngIf="displayUnderConstruction" [contentType]="contentType"> 
            </mc-studies-construction-tile-results>
            <mc-notification-tile-results *ngIf = "displayRecentNotification" (emailDetailSelected) = "onEmailDetailSelected($event)">
            </mc-notification-tile-results>
            <mc-patient-tile-results *ngIf = "displayRecentPatient">
            </mc-patient-tile-results>
          </div>
        </mc-preview>
      </div>
    </div>
     </div>

     
  <div>
    <mc-email-modal></mc-email-modal> 
  </div>


</div>