<div>
  <div class="tile is-ancestor report-tile">

    <div class="tile is-3 is-parent is-vertical" displayType="hide" [ngClass]="{'is-hidden': !studyAccrualTiles}">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar green"></div>
        <p class="title is-6 has-text-weight-bold">Study Accrual</p>
        <div>
          <button type="button" (click)="openModalForm()" class="button is-primary  is-radiusless">
            <span>Generate Report</span>
          </button>
        </div>
      </div>
    </div>

    <div class="tile is-3 is-parent is-vertical" displayType="hide"
      [ngClass]="{'is-hidden': !isRandomizationTileAuthorized}">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar green"></div>
        <p class="title is-6 has-text-weight-bold no-bottom-margin">Randomization Logs</p>
        <span (click)="getHelp('RandomizationLog')">
          <i class="far fa-question-circle"></i>
        </span>
        <div>
          <button type="button" (click)="openDa('log')" class="button is-primary  is-radiusless">
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>

    <div class="tile is-3 is-parent is-vertical" displayType="hide"
      [ngClass]="{'is-hidden': !isRandomizationTileAuthorized}">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar green"></div>
        <p class="title is-6 has-text-weight-bold no-bottom-margin">Randomization Summary</p>
        <span (click)="getHelp('RandomizationSummary')">
          <i class="far fa-question-circle"></i>
        </span>
        <div>
          <button type="button" (click)="openDa('summary')" class="button is-primary  is-radiusless">
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>

    <div class="tile is-3 is-parent is-vertical" displayType="hide"
      [ngClass]="{'is-hidden': !isDataDictionaryTileAuthorized}">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar green"></div>
        <p class="title is-6 has-text-weight-bold">Data Dictionary</p>
        <div>
          <button type="button" (click)="openDa('dataDictionary')" class="button is-primary  is-radiusless">
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>

  </div>

  <div class="tile is-ancestor report-tile">

    <div class="tile is-3 is-parent is-vertical" displayType="hide"
      [ngClass]="{'is-hidden': !isSubjectRegistrationDataExtractTileAuthorized}">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar green"></div>
        <p class="title is-6 has-text-weight-bold">Subject Registration Extract</p>
        <div>
          <button type="button" (click)="openDa('subjectRegistrationDataExtract')"
            class="button is-primary  is-radiusless">
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>

    <div class="tile is-3 is-parent is-vertical" displayType="hide"
      [ngClass]="{'is-hidden': !isDifferenceReportTileAuthorized}">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar green"></div>
        <p class="title is-6 has-text-weight-bold">Difference Report</p>
        <div>
          <button type="button" (click)="openDa('difference')" class="button is-primary  is-radiusless">
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>

    <div class="tile is-3 is-parent is-vertical" displayType="hide"
      [ngClass]="{'is-hidden': !isFullStudyUnblindTileAuthorized}">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar green"></div>
        <p class="title is-6 has-text-weight-bold">Full Study Unblind</p>
        <div>
          <button type="button" (click)="openDa('fullStudyUnblind')" class="button is-primary  is-radiusless">
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>


    <div class="tile is-3 is-parent is-vertical" displayType="hide"
      [ngClass]="{'is-hidden': !isAccrualBySiteTileAuthorized}">
      <div class="tile is-child box is-radiusless is-half-tile">
        <div class="color-bar green"></div>
        <p class="title is-6 has-text-weight-bold">Accrual By Site</p>
        <div>
          <button type="button" (click)="openModal('accrualBySite')" class="button is-primary  is-radiusless">
            <span>Generate Report</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="tile is-ancestor report-tile">

      <div class="tile is-3 is-parent is-vertical" displayType="hide" [ngClass]="{'is-hidden': !isSchemaReportAuthorized}">
        <div class="tile is-child box is-radiusless is-half-tile">
          <div class="color-bar green"></div>
          <p class="title is-6 has-text-weight-bold">Schema Report</p>
          <div>
            <button type="button" (click)="openModal('schemaReport')" class="button is-primary  is-radiusless">
              <span>Generate Report</span>
            </button>
          </div>
        </div>
      </div>

    <ng-container *ngFor="let report of reportList">

      <div class="tile is-3 is-parent is-vertical" displayType="hide"
        [ngClass]="{'is-hidden': !isAccrualBySiteTileAuthorized}">
        <div class="tile is-child box is-radiusless is-half-tile">
          <div class="color-bar green"></div>
          <p class="title is-6 has-text-weight-bold">{{report.title}}</p>
          <div>
            <button type="button" (click)="openModal(report.type)" class="button is-primary  is-radiusless">
              <span>{{report.name}}</span>
            </button>
          </div>
        </div>
      </div>

    </ng-container>
    

    <mc-rpt-modal [title]="reportTitle" (getReportData)="tileClicked($event)">
    </mc-rpt-modal>

    <mc-info-modal [title]="modalTitle" [body]="modalBody">
    </mc-info-modal>

  </div>

</div>