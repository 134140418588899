<div [ngClass]="{'organization-width': !showEditModeOnly}" >
  <div tabindex="0" class="" [ngClass]="{'is-hidden': showEditMode}">
    <div class="control">
      <span *ngIf="currentOrganizationName == ''">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span *ngIf="currentOrganizationName != ''" name="spnLabel">{{currentOrganizationName}}</span>
    </div>
  </div>


  <div tabindex="-1">
    <div class="field is-grouped clearfix "
      [ngClass]="{'is-hidden': !showEditMode, 'organization-width': !showEditModeOnly}">
      <!-- <div class="field"> -->
      <p class="control  " class="no-right-margin" [ngClass]="controlClass" >
        <input placeholder="Organization Name" id="organizationSearch"
          type="search"
            class="form-control input is-small organization-search-input"
          autocomplete="off" [value]="getCurrentOrganizationName()" #organizationSearch name="organizationSearch" (input)="onQueryChange(organizationSearch.value)">
      </p>
      <button type="button find-button " [disabled]="!shouldEnableSearch()? '' : null"
       class="button is-custom-save is-small is-radiusless"
        (click)="organizationSearchModal($event, true)">
        <span class=" is-small">           
          <i class="fas fa-search" aria-hidden="true"></i>
        </span>
      </button>
      <!-- </div> -->

    </div>

    <div class="modal" [ngClass]="{'is-active': showModal}">
      <div class="modal-background"></div>
      <div class="modal-card org-modal" [mcDraggable]="true">
        <header class="modal-card-head is-modal-header">
          <p class="modal-card-title is-modal-title">Organization Search            
          </p>
          <button class="delete" aria-label="close" (click)="organizationSearchModal($event,false)">
          </button>
        </header>
        <div class="modal-card-body ignore-draggable no-top-padding" >
          <section class="">  
            <div class="has-fixed-table-header form-table-container">    
                <table class="is-scroll-table header table is-striped is-fullwidth " ngClass="{{config.className || ''}}" 
                role="grid">
                  <thead>
                    <tr role="row">
                      <ng-container *ngFor="let column of columns">
                        <th *ngIf="column.display" class="table-header " [mcTableSorting]="config" [column]="column" title="{{column.title}}" (sortChanged)="onChangeTable($event)"
                          ngClass="{{column.className || ''}}">
                          <span> {{column.title}}</span>
                          <span *ngIf="rows.length > 0 && config && column.sort != undefined" class="sort  is-text-centered">
                              <span *ngIf="column.sort === ''">
                                <i class="fas fa-sort"></i>
                              </span>
                              <span *ngIf="column.sort === 'desc'">
                                <i class="fas fa-sort-down"></i>
                              </span>
                              <span *ngIf="column.sort === 'asc'">
                                <i class="fas fa-sort-up"></i>
                              </span>
                              </span>
                        </th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="showFilterRow">
                      <ng-container *ngFor="let column of columns" >
                      <td *ngIf="column.display" >
                        <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" 
                          class="form-control filter-width"
                            (tableChanged)="onChangeTable(config)" />
                      </td>
                      </ng-container>
                    </tr>
                    <tr #orgRow *ngFor="let row of rows" (click)="selectOrganization(row)" 
                        [ngClass]="{'is-selected': isOrganizationSelected(row)}"
                          id="{{row.networkPK}}" >
                      <td class="has-fixed-cell-width">
                        {{row.ctepId}}
                      </td>
                      <td class="has-fixed-cell-width">
                        {{row.orgPk}}
                      </td>                           
                      <td class="has-name-content">
                        {{row.name}}
                      </td>
                      <td>
                        {{row.status}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </section>
        </div>
        <footer class="modal-card-foot ignore-draggable">
          <div class="is-pulled-right footer-button ">
            <button class="button is-primary is-radiusless" [disabled]="!modalItemSelected" (click)="organizationSearchModal($event,false, true)">
              Select</button>
            <button class="button is-radiusless" (click)="organizationSearchModal($event,false)">
              Cancel</button>
          </div>
        </footer>
      </div>
    </div>


  </div>

</div>