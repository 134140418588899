<div class="columns" *ngIf="searchCriteria">
  <div class="column">
    <section class="section" *ngIf="noResultsFound">
      <div class="container">
        <div class="subtitle has-text-centered">
          <h2 class="has-text-weight-bold">
            No results found. Please check your search criteria.
          </h2>
        </div>
      </div>
    </section>

    <div class="search-result" *ngIf="results && results.length > 0">
      <div class="form-table-container">
        <table class="table is-fullwidth is-striped" ngClass="{{config.className || ''}}" role="grid">
          <thead>
          <tr class="is-selected" role="row">
            <ng-container *ngFor="let column of columns">
              <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}" (sortChanged)="onChangeTable($event)"
                  ngClass="{{column.className || ''}}">
                <span> {{column.title}}</span>
                <span *ngIf="config && column.sort" class="sort is-text-centered">
                  <span *ngIf="column.sort === 'desc'">
                    <i class="fas fa-sort-down"></i>
                  </span>
                  <span *ngIf="column.sort === 'asc'">
                    <i class="fas fa-sort-up"></i>
                  </span>
                </span>
              </th>
            </ng-container>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of results">
            <td>
              <span>{{row.studyNumber}}</span>
            </td>
              <td *ngIf="isSearchBasedOnTrackingNum">
                <a *ngIf = "isPatientLink(row)" (click)="goToPatientLanding($event, row)">
                  <span class="has-text-weight-bold">
                    <span *ngIf = "!isActivePatient(row)">
                      {{row.patientId}}
                    </span>
                  </span>
                </a>
                <span *ngIf = "!isPatientLink(row)" >
                  <span *ngIf = "!isActivePatient(row)">
                    {{row.patientId}}
                  </span>
                </span>
              </td>
              <td *ngIf="isSearchBasedOnTrackingNum">
                <a *ngIf = "isPatientLink(row)" (click)="goToPatientLanding($event, row)">
                  <span class="has-text-weight-bold">
                    <span *ngIf="isTrackingNumExist(row)">
                      {{row.trackingNum}} 
                    </span>
                  </span>
                </a>
                <span *ngIf = "!isPatientLink(row)" >
                  <span *ngIf="isTrackingNumExist(row)">
                    {{row.trackingNum}} 
                  </span>
                </span>
              </td>

            <td *ngIf="!isSearchBasedOnTrackingNum">
              <a *ngIf = "isPatientLink(row)" (click)="goToPatientLanding($event, row)">
                <span class="has-text-weight-bold">
                  <span *ngIf = "!isActivePatient(row)">
                    {{row.patientId}}
                  </span>
                  <span *ngIf = "isActivePatient(row)">
                    {{row.trackingNum}}
                  </span>

                </span>
              </a>
              <span *ngIf = "!isPatientLink(row)" >
                <span *ngIf = "!isActivePatient(row)">
                  {{row.patientId}}
                </span>
                <span *ngIf = "isActivePatient(row)">
                  {{row.trackingNum}}
                </span>

              </span>
            </td>

            <td>
              <span>{{row.lastName}} {{row.firstName}} {{row.middleName}}</span>
            </td>
            <!-- <td>
                <span *ngIf="row.raceDescList && row.raceDescList.length > 0">
                  <span>{{row.raceDescList.join(", ")}} </span>
                </span>
            </td> -->
            <td>
              <span>{{row.dateOfBirthString }} </span>
            </td>
            <td>
              <span>{{row.organizationName}}</span>
            </td>
            <td>
              <span>{{row.currentEnrollmentEventName}}</span>
            </td>
            <td>
              <span>{{row.interventionName}}</span>
            </td>
            <td>
              <span>{{row.registrationDate | date:'MM/dd/yyyy'}}</span>
            </td>
            <td>
              <span>{{row.registrar}}</span>
            </td>
          </tr>

          </tbody>

        </table>
      </div>
    </div>
  </div>

</div>
