import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Form } from '../model/form';
import { FormVariable } from '../model/form-variable';
import { FormsDashboardService } from 'app/common/services/forms/forms-dashboard.service';

import { VariableResponseType } from 'app/common/model/variable-response-type';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mc-preview-form',
  templateUrl: './preview-form.component.html',
  styleUrls: ['./preview-form.component.scss']
})
export class PreviewFormComponent implements OnInit, OnDestroy {

  @Input() inputForm: Form;

  /**
   * The list of subscriptions on this component
   */
  subscriptions: Subscription[] = [];
  /**
   * list of responseTypes
   */
  responseTypeList: VariableResponseType[] = [];


  /**
   * Response type Checkbox
   */
  rtCodeCheckBox = 'CHECKBOX';
  /**
   * Response type Date
   */
  rtCodeDate = 'DATE';
  /**
   * Response type DropDown
   */
  rtCodeDropDown = 'DROPDOWN';
  /**
   * Response type multi choice (radio button)
   */
  rtCodeMultiChoice = 'MULTICHOICE';
  /**
   * Response type multi choice - grid
   */
  rtCodeMultiChoiceGrid = 'MULTiCHOICEGRID';
  /**
   * Response type text box
   */
  rtCodeText = 'TEXTBOX';
  /**
   * Response type Time
   */
  rtCodeTime = 'TIME';


  constructor(private formsDashboardService: FormsDashboardService) { }

  ngOnInit() {
    this.subscriptions.push(this.formsDashboardService.getVariableResponseTypes()
      .subscribe(results => {
        this.responseTypeList = results as VariableResponseType[];
      })
    );
  }


  /**
  * Destroy implementation - closes all the subscriptions
  */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }

  /**
   * Returns the matching responseType for the formVariableResponseType
   * @param  responseTypeId The responseTypeId
   */
  findMatch(responseTypeId: number): VariableResponseType {
    return this.responseTypeList.find(respType => respType.variableResponseTypeId == responseTypeId);
  }

  /**
   * True if the response type is a checkbox
   * @param variable the variable whose response type will be evaluated.
   *
   */
  isCheckBox(variable: FormVariable): boolean {
    const match = this.findMatch(variable.variableResponseTypeId);
    return match && match.variableResponseTypeCode.toUpperCase() == this.rtCodeCheckBox;
  }

  /**
   * True if the response type is a Input text box
   * @param variable the variable whose response type will be evaluated.
   * NOTE: do not display response field for the fluff class or display type (some legacy forms have the response type type set as text for these)
   *
   */
  isInputText(variable: FormVariable): boolean {
    if (variable.variableClassName == "fluff"  || variable.variableDataTypeName == "display") {
      return false;
    }

    const match = this.findMatch(variable.variableResponseTypeId);
    return match && match.variableResponseTypeCode.toUpperCase() == this.rtCodeText;
  }

  /**
   * True if the response type is a Date field
   * @param variable the variable whose response type will be evaluated.
   *
   */
  isDate(variable: FormVariable): boolean {
    const match = this.findMatch(variable.variableResponseTypeId);
    return match && match.variableResponseTypeCode.toUpperCase() == this.rtCodeDate;
  }

  /**
   * True if the response type is a Time field
   * @param variable the variable whose response type will be evaluated.
   *
   */
  isTime(variable: FormVariable): boolean {
    const match = this.findMatch(variable.variableResponseTypeId);
    return match && match.variableResponseTypeCode.toUpperCase() == this.rtCodeTime;
  }


  /**
     * True if the response type is a Dropdown field
     * @param variable the variable whose response type will be evaluated.
     *
     */
  isDropdown(variable: FormVariable): boolean {
    const match = this.findMatch(variable.variableResponseTypeId);
    return match && match.variableResponseTypeCode.toUpperCase() == this.rtCodeDropDown;
  }


  /**
     * True if the response type is a MultiChoice field
     * @param variable the variable whose response type will be evaluated.
     *
     */
  isMultiChoice(variable: FormVariable): boolean {
    const match = this.findMatch(variable.variableResponseTypeId);
    return match && match.variableResponseTypeCode.toUpperCase() == this.rtCodeMultiChoice;
  }


  /**
     * True if the response type is a MultiChoiceGrid field
     * @param variable the variable whose response type will be evaluated.
     *
     */
  isMultiChoiceGrid(variable: FormVariable): boolean {
    const match = this.findMatch(variable.variableResponseTypeId);
    return match && match.variableResponseTypeCode.toUpperCase() == this.rtCodeMultiChoiceGrid;
  }



}
