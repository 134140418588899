import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, Route, Data } from '@angular/router';
import { UserService } from 'app/common/services/user.service';
import * as activityConst from 'app/common/model/activity-name-constants';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard  {
  constructor(private userService: UserService,
    private router: Router) { }


  /**
   * Interface Implementation called to check if the route can be activated
   * True if the user is authorized to activate the route
   * @param route The route being navigated to
   * @param state The route state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const url: string = state.url;
    const theStudy: any = this.getParam(route, 'studyId');  // route.params.studyId

    let studyId = 0;
    if (theStudy) {
      studyId = +theStudy;
    }

    if (route.data.customPage && route.data.customPage === activityConst.ACTIVITY_PATIENT_LANDING_PAGE) {
      let caseDetailId = 0;
      const theCaseDetailId: any = this.getParam(route, 'caseDetailId'); // route.params.caseDetailId

      if (theCaseDetailId) {
        caseDetailId = +theCaseDetailId;
      }

      if (theCaseDetailId > 0) {
        return this.isAuthorizedToNetwork(caseDetailId);
      }

   }


    if (studyId > 0) {
      return this.isAuthorizedByStudy(url, route.data, studyId);
    } else {
      return this.isAuthorized(url, route.data);
    }
  }

  /**
   * Interface Implementation called when the child route is being activated
   * True if the user is authorized to access the route
   * @param route The route being navigated to
   * @param state The route state
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }

  /**
   * Interface Implementation - returns true if the user is authorized to load this route
   * @param route The route being loaded
   */
  canLoad(route: Route): boolean {
    const url = `/${route.path}`;

    return this.isAuthorized(url, route.data);
  }


  /**
   * Returns true if the user is authorized to access the resource
   * being navigated to
   * @param url the url to evaluated
   * @param data The data component from the route
   */
  private isAuthorized(url: string, data: Data): boolean {

    if (data.activityName && data.activityType == 'create') {
      return this.userService.hasCreateAccess(data.activityName)  ;
    } else if (data.activityName && data.activityType == 'update') {
      return this.userService.hasModifyAccess(data.activityName)  ;
    } else if (data.activityName) {
      return this.userService.hasMinimumAccess(data.activityName)  ;
    }
    return true;
  }

    /**
   * Returns true if the user is authorized to access the resource either via the specific study or accross all studies
   * being navigated to
   * @param url the url to evaluated
   * @param data The data component from the route
   */
  private isAuthorizedByStudy(url: string, data: Data, studyId: number): boolean {

    if (data.activityName && data.activityType === 'create' ) {
      return this.userService.hasCreateAccess(data.activityName) || this.userService.hasCreateAccessByStudy(data.activityName, studyId) ;
    } else if (data.activityName && data.activityType === 'update') {
      return this.userService.hasModifyAccess(data.activityName) || this.userService.hasModifyAccessByStudy(data.activityName, studyId) ;
    } else if (data.activityName ) {
      return this.userService.hasMinimumAccess(data.activityName) || this.userService.hasMinimumAccessByStudy(data.activityName, studyId) ;
    }

    // if no activity name provided return true
    return true;
  }

  /**
   * Returns true if the user is authorized to access patient landing page based on network roles on TLC
   * being navigated to
   * @param caseDetailId to get the roles on network
   */
  private isAuthorizedToNetwork(caseDetailId: number): Observable<boolean> {
    return this.userService.getLandingPageAccess(caseDetailId);
  }

    /**
   *
   * @param route The route to check
   * @param paramName The paramName to look for
   */
  private getParam(route: ActivatedRouteSnapshot, paramName: string): any {
    if (route.params[paramName]) {
      return route.params[paramName];
    }
    if (route.parent) {
      return this.getParam(route.parent,  paramName);
    }
    // if no value or no match, return null
    return null;
  }

}
