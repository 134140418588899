import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import * as activityConst from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import { HomePageDisplay } from 'app/common/model/home-page-display';
import { PreviewPanelModel } from 'app/common/model/preview-panel-model';
import { UserService } from 'app/common/services/user.service';
import { SearchInfo } from '../../common/model/search-info';
import { SearchService } from '../../common/services/search.service';
import { StudyServiceBase } from '../../common/services/study-service-base.service';


@Component({
  selector: 'mc-admin-tiles',
  templateUrl: './admin-tiles.component.html',
  styleUrls: ['./admin-tiles.component.scss']
})
export class AdminTilesComponent implements OnInit {

  @Input() previewModel: PreviewPanelModel;
  @Output() displayTypeSelectedEvent = new EventEmitter();

  homeLandingPageEnum: number = globalConst.LANDING_PAGE_ENUM_HOME;


  public underConstructionCount: number;
  public totalStudies: number;
  public patientCount: number;
  public notificationsCount: number ;
  public version: string;
  public patientOrSubjectTitle: string;

  constructor(private router: Router,
    private studyLandingService: StudyServiceBase,
  private searchService: SearchService,
  private userService: UserService,
  @Inject(APP_CONFIG) config: AppConfig) {
    this.version = config.version;
   }

  ngOnInit() {
    this.underConstructionCount = 0;
    // Only call service to get studies under construction if that tile is displayed
    if (this.isActivityAllowed(activityConst.ACTIVITY_HOME_TILE_STUDY_ADMIN)) {
      this.studyLandingService.getStudiesUnderConstruction()
        .subscribe(results => {
          if (results != null) {
            this.underConstructionCount = results.length;
          }
        })
    }

    this.totalStudies = 0;
    this.studyLandingService.getAvailableStudiesCount()
    .subscribe(results => {
      if (results != null) {
        this.totalStudies = results.count;
      }
    })

    this.processPreviousSearch();


    this.patientCount = 0;

    if (this.isActivityAllowed(activityConst.ACTIVITY_HOME_TILE_PATIENT)) {
    this.studyLandingService.getActivePatientCountForAllStudies()
       .subscribe(results => {
         if (results != null) {
           this.patientCount = results;
         }

       })
      }

      // Based on version display the title on the patient/subject tile
      if (this.version == 'v2') {
        this.patientOrSubjectTitle = 'Subjects';
      } else {
        this.patientOrSubjectTitle = 'Patients';
      }

   // TODO: retrieve pnotification and studies (non-admin)
    this.notificationsCount = 32;
  }

  /**
   * Verify if a previous search was executed using one of the tiles on this page
   */
  private processPreviousSearch(): void {
    const previousQuery: SearchInfo = this.searchService.getSearchQuery(this.homeLandingPageEnum);
    if (previousQuery) {
      if (previousQuery.searchTypeEnum == globalConst.SEARCH_TYPE_ENUM_STUDIES) {

        setTimeout(() => {
          this.searchService.clearSearchQueries();
          this.viewStudiesUnderConstruction();
        });
      } else if (previousQuery.searchTypeEnum == globalConst.SEARCH_TYPE_ENUM_STUDIES_AVAILABLE) {

        setTimeout(() => {
          this.searchService.clearSearchQueries();
          this.viewStudies();
        });
      }// else conditions for the other tiles

    }
  }



  /**
   * This will fire event based on title selected.
   *
   * @param  {string} tileType - title selected
   */
  tileClicked(tileType: string) {
    this.fireEvent(tileType);
  }
  /**
   * This will capture underconstruction and emit it to the home component
   * @param  {string} selectedItem - tile selected
   */
  fireEvent(selectedItem: string) {
    const pageDisplay: HomePageDisplay = new HomePageDisplay(selectedItem);
    this.displayTypeSelectedEvent.emit(pageDisplay);
  }


  /**
   * This will emit underconstruction when clicked on the view studies button.
   */
  viewStudies() {
    const criteria: SearchInfo = {
      searchCriteria: { searchTerm: '', searchType: globalConst.SEARCH_CRITERIA_TYPE_STUDY },
      searchTypeEnum: globalConst.SEARCH_TYPE_ENUM_STUDIES_AVAILABLE
    };
    this.searchService.setSearchQuery(this.homeLandingPageEnum, criteria);
    // this.searchService.currentSearchQuery.searchTypeEnum = globalConst.SEARCH_TYPE_STUDIES;
    this.fireEvent(globalConst.DISPLAY_AVAILABLE_STUDIES);
  }

    /**
   * This will emit underconstruction when clicked on the view studies button.
   */
  viewStudiesUnderConstruction() {
    const criteria: SearchInfo = {
      searchCriteria: { searchTerm: '', searchType: globalConst.SEARCH_CRITERIA_TYPE_STUDY },
      searchTypeEnum: globalConst.SEARCH_TYPE_ENUM_STUDIES
    };
    this.searchService.setSearchQuery(this.homeLandingPageEnum, criteria);
    // this.searchService.currentSearchQuery.searchTypeEnum = globalConst.SEARCH_TYPE_STUDIES;
    this.fireEvent(globalConst.DISPLAY_RECENT_STUDIES);
  }

  /**
   * Handles the view patients click event
   */
  viewPatients() {
      console.log('View Patient clicked')
    this.fireEvent(globalConst.DISPLAY_RECENT_PATIENTS);
  }


  /**
   * handles the create new study click event
   */
  createNewStudy() {
    this.router.navigate(['admin/studies/create']);
  }


/**
   * handles the viewNotifications click event
   */
  viewNotifications() {
    console.log('View Notifications  clicked')
    this.fireEvent(globalConst.DISPLAY_RECENT_NOTIFICATIONS);
  }

  /**
   * handles the search notifications click event
   */
   searchNotificationLanding() {
    this.router.navigate(['admin/notification-search']); 
  }

/**
   * handles the viewReports click event
   */
  viewReports() {
    this.router.navigate(['Reports']);
  }


/**
   * handles the manage notifications  click event
   */
  manageNotifications() {
    this.router.navigate(['admin/group-notification']);
  }

  /**
     * handles the manage Study Templates  click event
     */
   manageStudyTemplates() {
    this.router.navigate(['admin/study-templates']);
  }

/**
   * determines if the supplied activity is allowed
   * @param activityName
   */
  isActivityAllowed(activityName: string): boolean {
    let allow = true;
    if (activityName) {
      allow = this.userService.hasMinimumAccessByStudy(activityName, null);
    }
    return allow;
  }



}
