import {
  AfterViewInit,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { HeaderBarComponent } from "app/common/header-bar/header-bar.component";
import * as activityConst from "app/common/model/activity-name-constants";
import * as globalConst from "app/common/model/app-constants";
import { EditBarActions } from "app/common/model/edit-bar-actions";
import { EventArg } from "app/common/model/event-arg";
import { HeaderBar } from "app/common/model/header-bar";
import { ModalDialogArg } from "app/common/model/modal-dialog-args";
import { Organization } from "app/common/model/organization";
import { PatientTransfer } from "app/common/model/patient-transfer";
import { PatientTransferRequest } from "app/common/model/patient-transfer-request";
import { PreviewPanelModel } from "app/common/model/preview-panel-model";
import { PatientParticipationInformationComponent } from "app/common/patient-participation-information/patient-participation-information.component";
import { PatientService } from "app/common/services/patient/patient.service";
import { StudySetupService } from "app/common/services/study-setup.service";
import { UserService } from "app/common/services/user.service";
import * as _ from "lodash";
import { find } from "lodash";
import * as moment from "moment";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { ErrorDisplayComponent } from "../../common/error-display/error-display.component";
import { PatientErrorDisplayArg } from "../../common/model/patient-error-display-arg";
import { PatientInfo } from "../../common/model/patient-info";
import { PatientRegError } from "../../common/model/patient-reg-error";
import { PatientRegistration } from "../../common/model/patient-registration";
import { Person } from "../../common/model/person";
import { TreatmentAssignment } from "../../common/model/treatment-assignment";
import { UiLayoutElement } from "../../common/model/ui-layout-element";
import { PreviewComponent } from "../../common/preview/preview.component";
import { NewPatientService } from "../../common/services/patient/new-patient.service";

@Component({
  selector: "mc-patient-transfer",
  templateUrl: "./patient-transfer.component.html",
  styleUrls: ["./patient-transfer.component.scss"],
})
export class PatientTransferComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  singleTrackingDtl: any;
  singleTrackingPreview = false;

  /**
   * Variable used to track changes since submission
   */
  submittedReg: PatientRegistration = null;

  /**
   * variable to track if there were errors in the submission
   */
  hasSubmissionErrors = false;

  /**
   * model for the header bar
   */
  headerModel: HeaderBar;

  /**
   * Subscription array to hold each subscription so they can be closed upon destruction
   */
  subscriptions: Subscription[] = [];

  /**
   * var to show hide left nav
   */
  showLeftNav = true;

  private currentUrl: any;

  private eventArg: EventArg = new EventArg("", "");

  studyId: number;

  /**
   * Variable to hold the caseDetailId
   */
  caseDetailId = 0;

  patientId = "";
  patientRegModel: PatientRegistration = null;
  /**
   * The original(last saved) PatientRegistration object
   */
  originalRegModel: PatientRegistration = null;

  /**
   * Array to hold the UI Elements of the subcomponents
   */
  uiLayoutElements: UiLayoutElement[] = [];

  /**
   * Variable to hold the error components are they are created for displaying the errors
   */
  errorComponents: ComponentRef<ErrorDisplayComponent>[] = [];

  /**
   * Errors grouped by their sections
   */
  groupedArray: UiLayoutElement[] = [];

  /**
   * List of patientTransfer history
   */
  patientTransferList: PatientTransfer[] = [];

  private newTranferInitialized = false;

  /**
   * The composite id on the row that is currently selected for displaying the Info
   */
  selectedInfoRowId = "";

  /**
   * selectedIds Used by edit-bar
   */
  selectedIds: string[] = [];

  actionSetting: EditBarActions = {
    isAddDisabled: true,
    isEditDisabled: true,
    isInfoDisabled: false,
    isFilterDisabled: true,
    isDeleteDisabled: true,
    isEditBarDisabled: false,
  };

  isRequestDisplayMode = false;

  navBackPageEnum = 0;
  trackingNum: string;
  selectedCaseEventId: number;

  transferRequest: PatientTransferRequest = {
    participatingSites: [],
    participatingPersons: [],
    comments: "",
    studyId: 0,
  };

  /**
   * Reference to the child header component
   */
  @ViewChild(HeaderBarComponent)
  private headerBar: HeaderBarComponent;

  /**
   * Reference to the child preview component
   */
  @ViewChild(PreviewComponent)
  public previewComponent: PreviewComponent;

  @ViewChild(PatientParticipationInformationComponent)
  private patientInfoComponent: PatientParticipationInformationComponent;

  @ViewChildren("contentDivs") viewContentDivs: QueryList<any>;
  private selectedContentDiv: any = null;

  /***** Handling for Participation sub-component  ***************************** */

  SECTION_ID: string = globalConst.REG_SECTION_PARTICIPATION;

  /***** Handling for Participation sub-component  ***************************** */

  /** custom handling of the preview page********************* */

  /* Model to drive the orientation and horizontal/vertical states of the preview window
   */
  previewModelHome: PreviewPanelModel = new PreviewPanelModel();

  previewTitle: string = "Subject Transfer";

  formTitle: string = "Subject Transfer";

  constructor(
    private studySetupService: StudySetupService,
    private patientService: PatientService,
    private patientRegService: NewPatientService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private viewContainer: ViewContainerRef,
  ) {
    this.subscriptions.push(
      this.patientRegService.uiLayoutElements$.subscribe((uiElements) => {
        this.processUiElements(uiElements);
      }),
    );

    this.subscriptions.push(
      router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((url: any) => {
          if (url.url) {
            this.currentUrl = url;
          }
        }),
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        if (params["navBack"]) {
          this.navBackPageEnum = +params["navBack"];
        }
        if (params["trackingNum"]) {
          this.trackingNum = params["trackingNum"];
        }
        if (params["selectedCaseEventId"]) {
          this.selectedCaseEventId = params["selectedCaseEventId"];
        }
      }),
    );
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.studyId = params["studyId"];
        this.caseDetailId = params["caseDetailId"];
        this.patientId = params["patientId"];
        this.transferRequest.studyId = this.studyId;
        this.subscriptions.push(
          this.studySetupService.getStudy(this.studyId).subscribe((results) => {
            // initialize the model for the header
            this.headerModel = {
              caption: "Transfer Subject",
              captionValue: "",
              showDescription: true,
              description:
                "StudyId: " +
                results.studyNumber +
                " | " +
                "Subject ID: " +
                this.patientId,
              showButtons: false,
              canNavigate: false,
              navigateUrl: "",
              disableButtons: true,
            };
          }),
        );
        this.getPatientTransferHistory();
        // this.initNewTransfer();
        // this.newTranferInitialized = true;
      }),
    );
  }

  ngAfterViewInit() {
    this.headerBar.initHeader();
  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
      x.closed;
    });
  }

  /**
   * Gets the list of transfers for the caseDetailId
   */
  getPatientTransferHistory() {
    this.subscriptions.push(
      this.patientService
        .getPatientTransferHistory(this.caseDetailId)
        .subscribe((results) => {
          this.patientTransferList = results;
        }),
    );
  }

  /**
   * Parses the event info to determine what event was fired
   * @param eventInfo Event info for details of the event
   */
  parseEventInfo(eventInfo: EventArg) {
    // //console.log("parent event " + JSON.stringify(eventInfo));
    if (eventInfo.eventName == globalConst.EVENT_SAVE_ACTION) {
      // this.saveChanges();
    }
  }

  /**
   * Handler row clicked on the ui to select or deselect
   * @param data The patientTransfer object bound to the row
   */
  rowSelectedClick(data: PatientTransfer): any {
    this.selectedInfoRowId = "";
    const key = this.getCompositeKey(data);
    const selectedIdx: number = this.selectedIds.findIndex(
      (item) => item == key,
    );
    if (selectedIdx > -1) {
      this.selectedIds.splice(selectedIdx, 1);
    } else {
      this.selectedIds.push(key);
    }
    this.goToPatientTransferHistory(data);
  }

  /**
   * True if the row is selected
   * @param row The Patient Tranfer object to evaluate
   */
  isRowSelected(row: PatientTransfer): boolean {
    const key = this.getCompositeKey(row);
    const selectedIdx: number = this.selectedIds.findIndex(
      (item) => item == key,
    );
    return selectedIdx > -1;
  }

  /**
   * Handler for the action clicked on the edit bar
   * @param selectedAction the selected action from the editbar
   */
  selectedEditAction(selectedAction) {
    if (selectedAction == "info") {
      this.processInfoClick();
    } else if (selectedAction == "add") {
      //  this.addNewItem();
    } else if (selectedAction == "delete") {
      //  this.deleteSelectedRows();
    } else if (selectedAction == "filter") {
    } else if (selectedAction == "edit") {
      // this.currentEditRowExpanded = false;
      // if (this.currentEditRowId == 0) {
      //   //put the row in edit model
      //   this.currentEditRowId = this.selectedIds[0];
      // } else {
      //   this.currentEditRowId = 0;
      // }
    }
  }

  /**
   * Processes the click on the Info icon on the edit bar
   */
  private processInfoClick() {
    if (this.selectedIds.length > 0) {
      if (
        this.selectedInfoRowId == this.selectedIds[this.selectedIds.length - 1]
      ) {
        this.selectedInfoRowId = "";
      } else {
        this.selectedInfoRowId = this.selectedIds[this.selectedIds.length - 1];
      }
    } else {
      this.selectedInfoRowId = "";
    }
  }

  /**
   * True if the row has been selected for showing the info
   * @param row The PatientTransfer row being evaluated
   */
  isInfoSelected(row: PatientTransfer) {
    return this.selectedInfoRowId == this.getCompositeKey(row);
  }

  /**
   * Called when a row is selected and the info button is pushed
   *
   * @param row the row of the table to be operated on
   */
  getInfoMessage(row: PatientTransfer) {
    let message = "";

    const lastUserName = row.modifiedById;
    const lastModifiedDate = moment(row.modifiedTs).format(
      "MM/DD/YYYY HH:mm:ss",
    );

    message =
      "Last updated by " +
      lastUserName +
      " on " +
      lastModifiedDate +
      "\n" +
      row.eventName;

    return message;
  }

  /**
   * Returns the composite key for the patientTransfer object
   * @param pTransfer The PatientTransfer object to evaluate
   */
  getCompositeKey(pTransfer: PatientTransfer): string {
    let key = "";
    if (pTransfer) {
      key =
        (pTransfer.caseEventId ? pTransfer.caseEventId : "0") +
        "-" +
        pTransfer.caseTransferId;
    }

    return key;
  }

  /**
   * Add the ui Elements as they are created on the subcomponents
   * @param uiElements Array of UI elements generated by each component
   */
  processUiElements(uiElements: UiLayoutElement[]) {
    this.uiLayoutElements = uiElements;
    console.log(
      "Received the UI Layout Element Elements from the patient Part info",
      uiElements,
    );
  }

  /**
   * Starts the new Transfer process
   * @param $event Event raised by the click
   */
  startTransfer(): void {
    this.transferRequest.participatingSites =
      this.currentRegModel().participatingSites;
    this.transferRequest.participatingPersons =
      this.currentRegModel().participatingPersons;
    this.patientService
      .addPatientTransfer(this.caseDetailId, this.transferRequest)
      .subscribe(
        () => {
          this.hasSubmissionErrors = false;
          this.submittedReg = null;
          this.transferRequest.bypass = false;
        },
        (responseError) => {
          if (
            (responseError && responseError.message) ||
            (responseError &&
              responseError.error &&
              responseError.error.message)
          ) {
            if (responseError.status === 400) {
              this.doHandleTransferErrors(responseError);
            } else {
              const msg =
                responseError &&
                responseError.error &&
                responseError.error.message
                  ? responseError.error.message
                  : responseError.message;
              // let msg = (error.message) ? error.message : error.error.message;
              const modalArg: ModalDialogArg = {
                contentType: "Custom",
                dialogType: "modal-warn",
                displayMessage: msg,
                displayName: "",
                variableData: "",
              };
              //  new ModalDialogArg("modal-warn", "Custom", "Save Failed: " +  msg);
              this.studySetupService
                .showModalDialog(this.viewContainer, modalArg)
                .subscribe((actionResult) => {});
            }
            this.hasSubmissionErrors = true;
            this.submittedReg = _.cloneDeep(this.currentRegModel());
          }
        },
        () => {
          const modalArg: ModalDialogArg = {
            contentType: "GeneralSaveSuccess",
            dialogType: "modal-warn",
            displayMessage: "Return to All Transfers ",
            displayName: "",
            variableData: "continueLabel:OK",
          };
          this.subscriptions.push(
            this.studySetupService
              .showModalDialog(this.viewContainer, modalArg)
              .subscribe((actionResult) => {
                this.resetTransferRequest();
                this.getPatientTransferHistory();
                this.showSection("requests");
              }),
          );
        },
      );
  }

  /**
   * HAndles the click for overriding validation errors
   */
  overrideValidation() {
    // update byPass and call the service
    console.log("Override validation ");
    this.transferRequest.bypass = true;
    this.startTransfer();
  }

  // reference for save
  saveChanges() {}

  /**
   * Cancels the new Transfer process
   * @param $event Event raised by the click
   */
  cancelChanges($event: Event): void {
    if ($event) {
      $event.stopPropagation();
    }
    this.resetTransferRequest();
  }

  /**
   * Handles the resetting of the Transfer request to its original state
   */
  resetTransferRequest(): void {
    this.clearErrors();
    this.transferRequest.participatingSites = [];
    this.transferRequest.comments = "";

    this.cloneData();
    // TODO: HACK - Assuming that CREDIT GROUP is the first selector
    this.patientInfoComponent.creditGroupSelected(0);
  }

  doHandleTransferErrors(errorResp) {
    let patientRegErrors: PatientRegError[] = [];
    if (
      errorResp &&
      errorResp.error &&
      errorResp.error.errors != undefined &&
      errorResp.error.errors.length > 0
    ) {
      const respErr = errorResp.error.errors; // [{"source":"patientForms.10330","message":"value is required"},{"source":"patientForms.10333","message":"value is required"}];
      patientRegErrors = respErr;
      this.processValidationErrors(patientRegErrors);
    }
  }

  /**
   * Process the validation by matching the error source to the ui element
   * @param validationErrors The validation errors generated when checking
   */
  private processValidationErrors(validationErrors: PatientRegError[]) {
    // first clear existing errors
    this.clearErrors();
    if (validationErrors && validationErrors.length > 0) {
      validationErrors.forEach((eachError) => {
        if (this.uiLayoutElements.length > 0) {
          const errIdx = this.uiLayoutElements.findIndex(
            (eachEr) => eachEr.propertyName == eachError.source,
          );
          if (errIdx > -1) {
            this.uiLayoutElements[errIdx].errorMessages.push(eachError.message);
          }
        }
      });
      this.groupAndPushErrors();
    }
  }

  /**
   * Clears the Errors currently being displayed on the ui
   */
  private clearErrors() {
    if (this.errorComponents.length > 0) {
      this.errorComponents.forEach((eachError, idx) => {
        eachError.destroy();
      });
      this.errorComponents = [];
    }
    if (this.uiLayoutElements.length > 0) {
      this.uiLayoutElements.forEach((uiLayout) => {
        uiLayout.errorMessages = [];
      });
    }
    this.groupedArray = [];
  }

  /**
   * Groups the errors by their sections
   */
  groupAndPushErrors() {
    const validUIErrors: UiLayoutElement[] = this.uiLayoutElements.filter(
      (eachErr) => eachErr.errorMessages.length > 0,
    );
    if (validUIErrors.length > 0) {
      const grouped = _.mapValues(_.groupBy(validUIErrors, "sectionId"));
      this.groupedArray = this.groupedArray.concat(grouped[this.SECTION_ID]);
      this.groupedArray.forEach((eachError, errIdx) => {
        const current: UiLayoutElement = eachError;
        const previous: UiLayoutElement =
          errIdx == 0 ? null : this.groupedArray[errIdx - 1];
        const next: UiLayoutElement =
          errIdx == this.groupedArray.length - 1
            ? null
            : this.groupedArray[errIdx + 1];

        const componentDiv = this.getSectionElement(0);
        const errArg: PatientErrorDisplayArg = {
          currentError: current,
          nextError: next,
          previousError: previous,
          finishElement: componentDiv,
        };
        this.errorComponents.push(
          this.patientService.showValidationError(current.node, errArg),
        );
      });
      console.log("Grouped Errors ", this.groupedArray);
    }
  }

  /**
   * Returns the contentDiv based on the index
   * @param idx The index of the contentDiv to be located
   */
  private getSectionElement(idx: number) {
    let contentDiv: any = null;
    if (this.viewContentDivs && this.viewContentDivs.length > 0) {
      this.viewContentDivs.forEach((eachItem, itemIdx) => {
        if (itemIdx == idx) {
          contentDiv = this.renderer.selectRootElement(eachItem);
        }
      });
    }
    return contentDiv;
  }

  /**
   * Navigate back to Patient Landing Page
   */
  navigateBack() {
    const path: string =
      "/patient/" +
      this.studyId +
      "/" +
      this.patientId +
      "/" +
      this.caseDetailId;
    this.router.navigate([path], {
      queryParams: {
        navBack: this.navBackPageEnum,
        trackingNum: this.trackingNum,
        selectedCaseEventId: this.selectedCaseEventId,
      },
    });
  }

  /**
   * True if the link should be displayed as active
   */
  public isLinkActive(sectionItem: string): boolean {
    if (sectionItem == "requests") {
      return !this.isRequestDisplayMode;
    }
    // default is false
    return this.isRequestDisplayMode;
  }

  /**
   *true if patient has in process enrollment/registration.
   * if this is the case the user won't be able to transfer the patient
   * Note: operationally, either the in process enrollment must be deleted or the enrollment must be complteded before transfer  take place.
   *
   * @returns {boolean}
   * @memberof PatientTransferComponent
   */
  public isPendingRegistration(): boolean {
    let pendingPatientTransfer = this.patientTransferList.find(
      (x) => x.status == "Registration Pending",
    );
    if (pendingPatientTransfer == null) {
      return false; //no pending registrations
    } else {
      return true; //pending registration
    }
  }

  /**
   * Sets the mode for display
   * @param sectionItem The section to open
   */
  showSection(sectionItem: string): void {
    const newTrfSection = "newTransfer";
    if (sectionItem == newTrfSection && !this.newTranferInitialized) {
      this.initNewTransfer();
      this.newTranferInitialized = true;
      setTimeout(() => {
        this.regDataInitialized();
      }, 10);
    }
    this.isRequestDisplayMode = sectionItem == "newTransfer";
  }

  /**
   * Initializes the Patient Model by either creating a blank one or retrieving
   * the existing one from the db
   */
  initializePatientModel(): Observable<any> {
    // initialize a blank model
    const participatingSites: Organization[] = [];
    const participatingPersons: Person[] = [];
    const patientInfo: PatientInfo = {
      firstName: "",
      countryId: null,
      dateOfBirthString: null,
      ethnicityId: null,
      genderId: null,
      lastName: "",
      subjectReferences: [],
      medDraId: null,
      methodOfPaymentId: null,
      middleName: "",
      raceIds: [],
      zipCode: "",
    };
    const treatmentAssignment: TreatmentAssignment = {
      caseEventTreatmentAssignmentCodeStudyID: null,
      treatmentAssignmentCodeStudyID: null,
      code: "",
      description: "",
    };
    const regModel: PatientRegistration = {
      studyId: this.studyId,
      assignedArm: "",
      assignedPatientId: null,
      caseDetailId: this.caseDetailId,
      participatingPersons: participatingPersons,
      participatingSites: participatingSites,
      patientInfo: patientInfo,
      trackingNumber: null,
      treatmentAssignment: treatmentAssignment,
      subgroupCode: null,
      patientForms: [],
      caseDetailTupleVersion: 0,
      caseEventId: -1,
      caseEventTupleVersion: 0,
      subjectId: null,
      subjectTupleVersion: 0,
      studySchemaEventId: 0,
      linkedSubjectIds: [],
      reservationEnrollGroup: null,
    };
    return of(regModel);
  }

  /**
   * initializes a new Transfer
   */
  initNewTransfer() {
    const observables: Observable<any>[] = [];
    observables.push(
      this.initializePatientModel().pipe(
        tap((result) => {
          if (result.participatingPersons == null) {
            result.participatingPersons = [];
          }
          if (result.participatingSites == null) {
            result.participatingSites = [];
          }
          console.log("1.0 - Main - retrieved patient data");
          this.patientRegModel = result;
          this.patientRegService.currentPatientRegModel = this.patientRegModel;
          this.cloneData(true);
        }),
      ),
    );

    // if (this.patientId && this.caseDetailId > 0) {
    //   //this is not in editmore
    //   if (!this.isEditMode) {
    //     observables.push(this.patientRegService.getNextEnrollmentEvent(this.caseDetailId)
    //       .pipe(tap(result => {
    //         console.log("1.2 - Main - retrieved Next Enrollment Event")
    //         this.enrollmentEvent = result;
    //       })));
    //   } else {
    //     observables.push(this.patientRegService.getCurrentEnrollmentEvent(this.caseDetailId)
    //       .pipe(tap(currentEvent => {
    //         console.log("1.2.A - Main - retrieved CURRENT Enrollment Event")
    //         this.enrollmentEvent = currentEvent;
    //       })));
    //   }
    // } else {
    //   observables.push(this.patientRegService.getFirstEnrollmentEvent(this.studyId)
    //     .pipe(tap(result => {
    //       console.log("1.2 - Main - retrieved Enrollment Event")
    //       this.enrollmentEvent = result;
    //     }))
    //     .pipe(catchError(
    //       error => {
    //         throw error
    //       })));
    // }

    // once all the data has been retrieved forkjoin
    this.subscriptions.push(
      forkJoin(observables).subscribe(
        () => {},
        (err) => {},
        () => {
          // if (this.enrollmentEvent) {
          //   this.patientRegService.currentPatientRegModel.studySchemaEventId = this.enrollmentEvent.studySchemaEventId;
          //   console.log("1.3 - Main - All needed initial data retrieved SchemaEventID ", this.enrollmentEvent.studySchemaEventId)

          // } else {
          //   let modalArg = new ModalDialogArg("modal-warn", "Custom", "Invalid Configuration: An Event for this registration could be not be retrieved.");
          //   this.studySetupService.showModalDialog(this.viewContainer, modalArg)
          //     .subscribe(actionResult => {

          //     });
          // }
          this.regDataInitialized();
        },
      ),
    );
  }

  /**
   *  Clones the original PatientReg to the current or reverse
   * @param isOriginal True if we want to set the original from the current
   */
  cloneData(isOriginal: boolean = false) {
    if (isOriginal) {
      this.patientRegService.originalPatientRegModel = _.cloneDeep(
        this.patientRegService.currentPatientRegModel,
      );
    } else {
      this.patientRegService.currentPatientRegModel = _.cloneDeep(
        this.patientRegService.originalPatientRegModel,
      );
    }
  }

  /**
   * The PatientRegistration model being created or edited
   */
  public currentRegModel(): PatientRegistration {
    return this.patientRegService.currentPatientRegModel;
  }

  /**
   * Emits an event to notify the sub components of patientRegistration initialized
   */
  regDataInitialized() {
    const eventArg: EventArg = new EventArg(
      globalConst.EVENT_DATA_UPDATED,
      "true",
    );
    console.log("1.4 - Main - Emitting Data Updated Event");
    this.studySetupService.emitChange(eventArg);
  }

  /**
   * True if the orientation is vertical and the state is collapsed
   */
  isCollapsedVertical(): boolean {
    return (
      this.previewModelHome.isCollapsed && this.previewModelHome.isVertical
    );
  }

  /**
   * True if the orientation is horizontal and is collapsed
   */
  isCollapsedHorizontal(): boolean {
    return (
      this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical
    );
  }

  /**
   * True if the orientation is vertical and the state is Expanded
   */
  isExpandedVertical(): boolean {
    return (
      !this.previewModelHome.isCollapsed && this.previewModelHome.isVertical
    );
  }

  /**
   * True if the orientation is horizontal and is expanded
   */
  isExpandedHorizontal(): boolean {
    return (
      !this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical
    );
  }

  /** custom handling of the preview for the  page********************* */

  /**
   * This will take you to the patientLanding page
   *
   * @param  {patient}
   */
  goToPatientTransferHistory(patient: PatientTransfer) {
    this.singleTrackingDtl = patient;
    this.singleTrackingPreview = true;
    // this.previewTitle = 'Study Accrual Report' ;
    this.previewModelHome.isCollapsed = false;
  }

  /**
   * True if the user has override validation errors overide access
   */
  hasOverrideAccess(): boolean {
    return this.userService.hasAccess(
      activityConst.ACTIVITY_PATIENT_REG_VALIDATION_OVERRIDE,
      "update",
    );
  }

  /**
   * True if there were submission errors and the model has not been modified since
   * the previous submission
   */
  shouldShowOverride(): boolean {
    const shouldShow: boolean =
      this.hasSubmissionErrors && !this.hasChangedSinceSubmission();
    return shouldShow;
  }

  /**
   * True if the reg model was updated since it was last submitted
   */
  hasChangedSinceSubmission(): boolean {
    let hasChanged = false;
    if (this.submittedReg) {
      // if the model has been updated since the last save
      hasChanged = !_.isEqual(this.currentRegModel(), this.submittedReg);
    }
    return hasChanged;
  }
}
