import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { SearchCriteriaPerson } from 'app/common/model/search-criteria-person';
import { Person } from 'app/common/model/person';
import { Observable, Subscription } from 'rxjs';
import { StudySetupService } from 'app/common/services/study-setup.service';


@Component({
  selector: 'mc-person-selection-modal',
  templateUrl: './person-selection-modal.component.html',
  styleUrls: ['./person-selection-modal.component.scss']
})
export class PersonSelectionModalComponent implements OnInit, OnDestroy {

  searchCriteriaPerson: SearchCriteriaPerson = { firstName: '', lastName: '', email: '', pk: null };
  persons: Person[] = [];

  @Input() showModal: boolean;
  @Output() selectedPersonEvent = new EventEmitter<Person>();
  selectedPerson: Person;

  showPersonFilterRow: boolean = false;

  /**
   * variable to hold the viewModels sorted and filtered as needed for the ui
   */
  public personRows: Array<Person> = [];

  /**
   * Count of rows on the person table
   */
  public personDataLength = 0;

  /**
   * Columns used to render the header and sorting
   */
  public personColumns: Array<any> = [
    {
      title: 'First Name', className: ['has-fixed-cell-width'],
      display: true, type: 'person',
      filtering: { filterString: '', placeholder: 'Filter by First ' },
      name: 'firstName', sort: ''
    },
    {
      title: 'Last Name', className: ['has-fixed-cell-width'],
      display: true, type: 'person',
      name: 'lastName', sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Last', sort: '' }
    },

    {
      title: 'Email', name: 'email',
      display: true, type: 'person',
      sort: '',
      filtering: { filterString: '', placeholder: 'Filter by Email' }
    }
  ];

  /**
   * Configuration for the table displayed
   */
  public personConfig: any = {
    paging: false,
    sorting: { columns: this.personColumns },
    filtering: { filterString: '' },
    className: ['is-striped', 'is-bordered']
  };

  /**
   * Page variable used during paging
   */
  public page = 1;
  /**
   * Used to determine the items on a page
   */
  public itemsPerPage = 10;
  /**
   * maximum page to be displayed
   */
  public maxSize = 5;
  /**
   * counter to hold the number of pages
   */
  public numPages = 1;

  /**
   * Subscription array to hold all the subscribes
   */
  subscriptions: Subscription[] = [];



  constructor(private studySetupService: StudySetupService
    ) { }

  ngOnInit(): void {
  }

  /**
  * Destroy implementation - closes all the subscriptions
  */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }


  /**
   * Main method to handle filtering, sorting of the Person table
   * @param config Configuration of the datatable
   * @param page The current page being displayed
   */
  public onChangePersonTable(config: any,
    page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    let column: any = null;
    this.personRows = [];
    console.log("onChangePersonTable");
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (this.personConfig.filtering) {
      Object.assign(this.personConfig.filtering, config.filtering);
    }
    const columns = this.personColumns;
    if (column != null) {
      columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.personConfig.sorting, config.sorting);
    }
    const filteredData = this.changeFilter(this.persons, this.personConfig);
    const sortedData = this.changeSort(filteredData, this.personConfig);
    this.personRows = sortedData;
    if (sortedData) {
      this.personDataLength = sortedData.length;
    }
  }

  /**
   * Sorts the data in the table based on the column and sort order
   * @param data The data currently being displayed on the table
   * @param config The associated configuration for the data being displayed
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }
    const columns = config.sorting.columns || [];

    columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name]) {
            return  (item[column.name] + '').trim().toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(config.filtering.filterString));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      columns.forEach((column: any) => {
        let propertyName = column.name;
        if (item[propertyName] != null && item[propertyName] != undefined
          && item[propertyName].toString().match(config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  /**
   * True if some value is provided for the searchCriteria
   */
  isValidSearchCriteria(): boolean {
    return this.searchCriteriaPerson.firstName.trim().length > 0 ||
      this.searchCriteriaPerson.lastName.trim().length > 0 ||
      this.searchCriteriaPerson.email.trim().length > 0
  }

  /**
   * Calls the service to return the matches
   */
  findPersonMatches(): void {
    this.persons = [];
    this.subscriptions.push(this.studySetupService.getPersonForCriteria(this.searchCriteriaPerson)
      .subscribe(result => {
        this.persons = result;
        this.onChangePersonTable(this.personConfig)
      }))
  }

  /**
   * Sets the selected person to the row
   */
  setSelectedPerson() {
    this.selectedPersonEvent.emit(this.selectedPerson);
    this.closeModal();
  }

  /**
   * Handles the cancel button click
   */
  cancelSelectedPerson() {
    this.showModal = false;  
    this.persons = [];
    this.searchCriteriaPerson = { email: '', lastName: '', firstName: '', pk: null };
  }

  /**
   * Selects or deselects the person
   * @param row The row displaying the person
   */
  selectPerson(row: Person): void {
    if (this.selectedPerson && this.selectedPerson.pk == row.pk) {
      this.selectedPerson = null;
    } else {
      this.selectedPerson = row;
    }
  }

  /**
   * True if the person is the selected person
   * @param row The person row to evaluate
   */
  isPersonSelected(row: Person): boolean {
    return this.selectedPerson && this.selectedPerson.pk == +row.pk ;
  }

  /**
   * Closes the modal
   */
  closeModal() {
    this.showModal = false;
    this.selectedPerson = null;
    this.persons = [];
    this.searchCriteriaPerson = { firstName: '', lastName: '', email: '', pk: null };
  }

}
