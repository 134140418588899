import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import { SimulationResult } from 'app/common/model/simulation/simulation-result';
import { SimulationStudyData } from 'app/common/model/simulation/simulation-study-data';
import { Observable, of } from 'rxjs';
import { BaseService } from '../base-service';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';

@Injectable({
  providedIn: 'root'
}
)
export class StudySimulationService extends BaseService {
  serviceUrlPrefix = this.appConfig.apiEndpoint;

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(componentFactoryResolver);
  }

  showPendingChangesDialog(viewContainerRef: ViewContainerRef, modalDialogArg: ModalDialogArg = null) {
    return this.showModalDialog(viewContainerRef, modalDialogArg);
  }
  /**
   * This will return the simulation template of type ms excel.
   * 
   * @param  {number} studyId
   * @param  {number} studySchemaEventId
   */
  public getSimulationTemplate(studyId: number, studySchemaEventId: number, simulation): Observable<any> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/events/${studySchemaEventId}/simulations/template`;
    return this.httpClient.post(url, simulation, {
      observe: "response",
      responseType: "arraybuffer",
    });
  }

  /**
   * 
   * @param studyId Generates template and trigger simulation run
   * @param studySchemaEventId 
   * @param simulationInputAndRunInfo 
   * @returns 
   */
  public generateTemplateAndRunSimulation(studyId: number, studySchemaEventId: number, simulationInputAndRunInfo): Observable<any> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/events/${studySchemaEventId}/simulations`;
    return this.httpClient.post(url, simulationInputAndRunInfo, {
      observe: "response"
    });
  }

  /**
   * This will upload the formData(file and json payload) via POST request.
   * 
   * @param  {number} studyId
   * @param  {number} studySchemaEventId
   * @param  {} formData
   * @returns Observable
   */
  public uploadSimulationFile(studyId: number, studySchemaEventId: number, formData): Observable<any> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/events/${studySchemaEventId}/simulations/trigger`;
    return this.httpClient.post(url, formData, {
      observe: "response"
    });
  }
  /**
   * This will return all the necessary details to render the simulation enrollment events.
   * 
   * @param  {number} studyId
   * @returns Observable
   */
  public getSimulationEnrollmentEvents(studyId: number): Observable<SimulationStudyData> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/simulations/study-data`;
    return this.httpClient.get<SimulationStudyData>(url);
  }
  /**
   * This will return simulation results for the study.
   * 
   * @param  {number} studyId
   * @param  {} studySchemaEventId
   * @returns Observable
   */
  public getSimulationResults(studyId: number, studySchemaEventId): Observable<SimulationResult[]> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/events/${studySchemaEventId}/simulations/results`;
    return this.httpClient.get<Array<SimulationResult>>(url);
  }

  public getSimulationLogReports(studyId: number, simulationId: number): Observable<string> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/reports/simulations/${simulationId}/simulation-log`;
    const options = { responseType: 'text' as 'text' };
    return this.httpClient.get(url, options);
  }

  public getSimulationSummaryReports(studyId: number, simulationId: number): Observable<string> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/reports/simulations/${simulationId}/simulation-summary`;
    const options = { responseType: 'text' as 'text' };
    return this.httpClient.get(url, options);
  }

  public getSimulationDetailReport(studyId: number, simulationId: number): Observable<Blob> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/reports/simulations/${simulationId}/simulation-details`;
    const options = { responseType: 'blob' as 'blob' };
    return this.httpClient.get(url, options);
  }

  public getSimulationInputFile(studyId: number, simulationId: number): Observable<Blob> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/reports/simulations/${simulationId}/simulation-input-file`;
    const options = { responseType: 'blob' as 'blob' };
    return this.httpClient.get(url, options);
  }

  public deleteSimulation(studyId: number, simulationId: number): Observable<string> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/simulations/${simulationId}`;
    const options = { responseType: 'text' as 'text' };
    return this.httpClient.delete(url, options);
  }


  public checkSimulation(studyId: number): Observable<string> {
    const url = `${this.serviceUrlPrefix}studies/${studyId}/simulations/check`;
    const options = { responseType: 'text' as 'text' };
    return this.httpClient.get(url, options);
  }
}
