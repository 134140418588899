import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mc-pull-out',
  templateUrl: './pull-out.component.html',
  styleUrls: ['./pull-out.component.scss']
})
export class PullOutComponent implements OnInit {

  @Input() isCollapsed = true;

  constructor() { }

  ngOnInit() {
  }

}
