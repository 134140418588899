import { Component, OnInit, Input } from '@angular/core';
import { PatientTransfer } from 'app/common/model/patient-transfer';

@Component({
  selector: 'mc-patient-transfer-preview',
  templateUrl: './patient-transfer-preview.component.html',
  styleUrls: ['./patient-transfer-preview.component.scss']
})
export class PatientTransferPreviewComponent implements OnInit {
  isRequestDisplayMode = false;

  @Input()  patientTransferInputList: PatientTransfer;

  constructor() { }

  ngOnInit() {

  }

    /**
     * True if the link should be displayed as active
     */
    public isLinkActive(sectionItem: string): boolean {
      if (sectionItem === 'requests') {
        return !this.isRequestDisplayMode;
      }
      // default is false
      return this.isRequestDisplayMode;
    }

}
