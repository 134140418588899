<div class="field is-horizontal is-narrow">  
  <div class="field-body ">
    <div class="field is-grouped">
      <div class="field has-search-criteria">
        <p class="control ">
          <input class="input is-criteria-text is-small" type="text" 
            type="search"
            placeholder="Patient Id" name="searchTerm" 
            [(ngModel)]="searchCriteria" />
        </p>
      </div>
      <div class="has-search-button">
        <button [disabled]="isInvalid()" type="button"
        (click)="searchClick()"
          class="button is-custom-save is-radiusless is-small">
          <span class="icon is-small">
            <i class="fas fa-search" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>




<div class="modal" [ngClass]="{'is-active': showModal}">
  <div class="modal-background"></div>
  <div class="modal-card modal-card-psbs">
      <header class="modal-card-head modal-card-psb">
        <p class="modal-card-title modal-card-psb">Select Patient</p>
      </header>

    <section class="modal-card-body">
    
        <table class="table is-fullwidth  is-borderless is-striped ">
          <thead>
            <tr>
              <ng-container *ngFor="let column of columns">
                <th *ngIf="column.display" class="table-header" 
                [mcTableSorting]="config" [column]="column"
                  title="{{column.title}}" 
                  (sortChanged)="onChangeTable($event)" 
                  ngClass="{{column.className || ''}}">
                  <span class="has-title"> {{column.title}}</span>
                  <span *ngIf="config && rows.length > 0 && column.sort !== undefined" 
                    class="sort is-text-centered has-padding-left-5">
                    <span *ngIf="column.sort === ''">
                      <i class="fas fa-sort"></i>
                    </span>
                    <span *ngIf=" column.sort === 'desc'">
                      <i class="fas fa-sort-down"></i>
                    </span>
                    <span *ngIf="column.sort === 'asc'">
                      <i class="fas fa-sort-up"></i>
                    </span>
                  </span>
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="showFilterRow">
              <td *ngFor="let column of columns;let i=index">
                <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
                  [mcTableFiltering]="column.filtering" 
                  class="form-control is-filter-input"
                   (tableChanged)="onChangeTable(config)" />
              </td>
            </tr>


            <tr *ngFor="let row of rows" 
              [ngClass]="{'is-selected':isSelected(row)}" 
              (click)="rowSelectedClick(row)">
              <td>
                <span class="has-text-weight-bold">
                  <span>
                    {{row.patient.patientId}}
                  </span>
                </span>
              </td>
              <td>
                <span>{{row.patient.lastName}} {{row.patient.firstName}}</span>
              </td>
              <td>
                <span> {{row.patient.genderDesc}} </span>
              </td>
              <td>
                <span>{{row.patient.dateOfBirthString }} </span>
              </td>
              <td>
                <span>{{row.treatingLocation.name}}</span>
              </td>
            </tr>
          </tbody>

        </table>
 
    </section>
    <footer class="modal-card-foot modal-card-psb">
      <div class="is-pulled-right footer-button ">
        <button class="button is-primary is-radiusless" [disabled]="selectedPatient === null " (click)="choosePatient()">
          Select</button>
        <button class="button is-radiusless" (click)="closeModal()">
          Cancel</button>
      </div>
    </footer>
  </div>
</div>