import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StudyRecord } from 'app/common/model/study-record';
import { StudySetupService } from 'app/common/services/study-setup.service';
import * as globalConst from 'app/common/model/app-constants';

@Component({
  selector: 'mc-study-horizontal-nav',
  templateUrl: './study-horizontal-nav.component.html',
  styleUrls: ['./study-horizontal-nav.component.scss']
})
export class StudyHorizontalNavComponent implements OnInit , OnDestroy {

@Input() studyId: number ;
@Input() studyRecord: StudyRecord = null;

@Input() verticalSectionItems: any = [];

formsManagementPath = 'forms-management' ;
schemaManagementPath = 'schema-management' ;
notificationPath = 'notification';
notificationSetupPath = 'notifications';
protocolInfoPath = 'protocol-info';
ancillaryInfoPath = 'ancillary';
doubleBlindPath = 'double-blind-setup';
tacTadPath = 'tac-tad-setup/tac-version-listing';
sgcPath = 'tac-tad-setup/sgc-version-listing';

regulatoryPath = 'regulatory-setup';
attributePath = 'attributes';


prunedTacTadPath = 'tac-version-listing'
prunedSgcPath = 'sgc-version-listing';

  /**
   * The path for the listing of the tac-version-details
   */
  tacDetailPath = 'tac-version-detail';

  /**
   * The path for the listing of the sgc-version-details
   */
  sgcDetailPath = 'sgc-version-detail';


    /**
   * The path for the listing of the schema-dashboard
   */
    schemaDashboardPath = 'schema-dashboard';

  codeSetupParent = 'tac-tad-setup'


  fundingSourcePath = 'funding-source';


  navLinkList: string[] = [this.schemaManagementPath,
  this.formsManagementPath,
  this.notificationPath,
  this.notificationSetupPath,
  this.protocolInfoPath,
  this.ancillaryInfoPath,
  this.doubleBlindPath,
  this.prunedTacTadPath,
  this.prunedSgcPath,
  this.regulatoryPath,
  this.tacDetailPath,
  this.sgcDetailPath,
  this.schemaDashboardPath
  ];

  /**
   * The links to the detail pages
   */
  codeDetailLinks: { name: string, path: string, parentLink: string }[] = [
    { name: 'TAC Landing', path: this.tacDetailPath, parentLink:  this.tacTadPath },
    { name: 'SGC Landing', path: this.sgcDetailPath, parentLink: this.sgcPath },
    { name: 'Schema Management', path: this.schemaDashboardPath, parentLink: this.schemaManagementPath }];

  currentDetailLink: { name: string, path: string, parentLink: string } = null;
  

  /**
   * List of nav items for ancillary study
   */
  ancillaryStudyNavList: string[] = [
    this.ancillaryInfoPath,
    this.attributePath
    // this.notificationSetupPath,
  ];

  currentUrlChunks: string [] = [];

subscriptions: Subscription[] = [];
currentUrl: any;
navBackPageEnum: number;

  /**
  * List of all the horizontally displayed study components
  */
  horizontalSectionItems: { active: string, activityName: string, title: string, icon: string, path: string }[] = [
    { active: '', activityName: 'ACTIVITY_PROTOCOL_INFO', title: 'Protocol Info', icon: 'fa fa-info', path: this.protocolInfoPath },
    { active: '', activityName: 'ACTIVITY_ATTRIBUTES', title: 'Study', icon: 'fas fa-search', path: this.attributePath },
    { active: '', activityName: 'ACTIVITY_SCHEMA_MANAGEMENT', title: 'Schema ', icon: 'fas fa-edit', path: this.schemaManagementPath },
    { active: '', activityName: 'ACTIVITY_FORM_MANAGEMENT', title: 'Forms', icon: 'fas fa-user', path: this.formsManagementPath },
    { active: '', activityName: 'ACTIVITY_NOTIFICATION', title: 'Notifications', icon: 'fas fa-bell', path: this.notificationPath },
    { active: '', activityName: 'ACTIVITY_DOUBLE_BLIND_SETUP', title: 'Double Blind', icon: 'fas fa-bell', path: this.doubleBlindPath },
    { active: '', activityName: 'ACTIVITY_TAC_TAD_SETUP', title: 'TAC/TAD', icon: 'fas fa-bell', path: this.tacTadPath },
     { active: '', activityName: 'ACTIVITY_TAC_TAD_SETUP', title: 'SGC', icon: 'fas fa-bell', path: this.sgcPath },
    { active: '', activityName: 'ACTIVITY_REGULATORY_SETUP', title: 'Regulatory', icon: 'fas fa-bell', path: this.regulatoryPath },
  ];


  constructor(private router: Router,
    private route: ActivatedRoute,
    private studySetupService: StudySetupService) {

    // need to ensure we react only when the navigation ends
    this.subscriptions.push(router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((url: any) => {
        if (url.url) {
          this.currentUrl = url;
          this.currentUrlChunks = this.currentUrl.url.toLowerCase().split('/');
          this.setCodeDetailPage();
        }
      }));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params['navBack']) {
          this.navBackPageEnum =  params['navBack']
        }

    }))
  }

  /**
   * Determines if the current page is a TAC-TAD or SGC Version page or Schema Dashboard
   */
  setCodeDetailPage() {
    this.currentDetailLink = null;
    if (this.currentUrl && this.currentUrl.url) {
      const cUrl: string = this.currentUrl.url.toLowerCase();
      // else check if it's a detail
      const lastFrag: string = cUrl.substr(cUrl.lastIndexOf('/') + 1);
      if (this.navLinkList.indexOf(lastFrag) == -1) {
        const urlToMatch: string = cUrl.substring(cUrl.lastIndexOf(this.codeSetupParent) +
          this.codeSetupParent.length);
        let isDetailPg = false;
        this.codeDetailLinks.forEach(eLink => {
          if (!isDetailPg) {
            isDetailPg = urlToMatch.indexOf(eLink.path) > -1;
            if (isDetailPg) {
              this.currentDetailLink = eLink;
            }
          }
        })
      }
    }
  }

  /**
   * Get horizontalSectionItems based on protocol or ancillary study
   * @returns 
   */
  getHorizontalSectionItems(){
    if (this.studySetupService.isAncillaryStudy())      {
      const sItem = this.horizontalSectionItems.find(eItem => eItem.path === this.protocolInfoPath);
      if(sItem){
        sItem.path = this.ancillaryInfoPath;
      }
    }else {
      const sItem = this.horizontalSectionItems.find(eItem => eItem.path === this.ancillaryInfoPath);
      if(sItem){
        sItem.path = this.protocolInfoPath;
      }
    }
    return this.horizontalSectionItems;

  }

  /**
   * True if the page being displayed is a Detail page (Tac/Tad or SGC)
   */
  isCodeDetailPage(): boolean {
    return this.currentDetailLink != null;
  }



  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    )
  }


  /**
   * True if the link should be displayed as active
   */
  public isLinkActive(sectionItem: any): boolean {
    if (this.currentDetailLink) {
      // if it's a detail page for Tac tad and sgc
      return sectionItem.path.indexOf(this.currentDetailLink.parentLink) > -1;
    }
    // is this the landing page for one of the top nav
    let matchList = this.navLinkList.filter(e => this.currentUrlChunks.includes(e))
    if (matchList.length == 0) {
      // if it's not and we're under a sub nav with verticals,
      matchList = this.verticalSectionItems.filter(e => this.currentUrlChunks.includes(e))
      const verticalMatches = this.verticalSectionItems.map(function (each) { return each.path }).filter(e => this.currentUrlChunks.includes(e));
      // we found a match to indicate it's a sub nav for study, so make Study active
      return verticalMatches.length > 0 && sectionItem.path == this.attributePath
    }
    return matchList.length > 0 && matchList.indexOf(sectionItem.path) > -1;
  }

  /**
   * True if the item is visible on the display
   * Evaluates ancillary study to determine if it can be displayed on an ancillary study
   * @param sectionItem The navItem to evaluate
   */
  isNavItemVisible(sectionItem: any): boolean {
    let isVisible = true;
    if (this.studySetupService.isAncillaryStudy()) {
      isVisible = this.ancillaryStudyNavList.indexOf(sectionItem.path) > -1;
      if (isVisible) {
        if (sectionItem.path == this.attributePath) {
       //   sectionItem.path = this.fundingSourcePath;
        }
      }
    }
    return isVisible;
  }

  getNavItemDisplay(sectionItem: any): string {
    let display: string = sectionItem.title;
//    if (sectionItem.path == this.protocolInfoPath && this.studySetupService.isAncillaryStudy()) {
    if (sectionItem.path == this.ancillaryInfoPath && this.studySetupService.isAncillaryStudy()) {
      display = 'Ancillary Study Info';
    }
    return display;
  }


  /**
  * Handler to navigate back to the home page
  */
  navigateBack(): void {

    if(this.navBackPageEnum == globalConst.LANDING_PAGE_ENUM_HOME){
      this.router.navigate(["/home"]);
    }else{
      let path = '/studies/';
      if (this.studySetupService.isAncillaryStudy() && this.studySetupService.ancillaryStudy) {
        // if (this.studyRecord.ancillaryStudy && this.studyRecord.ancillaryStudy.parentStudyId) {
        path = path + this.studySetupService.ancillaryStudy.parentStudyId;

      } else {
        path = path + this.studyId;
      }

      this.router.navigate([path], {
        queryParams: {
          'navBack': this.navBackPageEnum
        }
      });
    }
  }

  /**
   * 
   * @returns Is back to search results
   */
  isBackToSearchResults(){
    if(this.navBackPageEnum == globalConst.LANDING_PAGE_ENUM_HOME){
      return true;
    }
    return false;
  }




  /**
   * Navigate to the selected Section
   * @param $event The event raised
   * @param sectionItem The seciton Item evaluated
   */
  navTo($event: Event, sectionItem: any): void {
    // If the user has clicked on the Study nav Item and it's an ancillary study , take them to the
    // funding source page
    if (this.studySetupService.isAncillaryStudy()) {
      const navTo: string = this.fundingSourcePath;
      if (sectionItem.path == this.attributePath) {
        $event.stopPropagation();
        const navUrl: string = './' + this.fundingSourcePath;
        this.router.navigate([navUrl],
          {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
          });
      }
    }
  }

}
