import { Code } from "./code";

export class SchemaReportCriteria {
    constructor(
        public schemaEvents: Code[],
        public eventAttributes: Code[],
        public researchEntities: Code[],
        public studyStatuses: Code[]
    ) {}
}
