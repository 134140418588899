<mc-header-bar [headerModel]="headerModel" ></mc-header-bar>

<div class="form-page">
<!-- <div class="variable-library-list"> -->
  <table class="table is-fullwidth is-striped" ngClass="{{config.className || ''}}" role="grid">
    <thead>
      <tr role="row">
        <ng-container *ngFor="let column of columns">
          <th *ngIf="column.display" class="table-header" title="{{column.title}}" ngClass="{{column.className || ''}}">
            <span> {{column.title}}</span>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td *ngFor="let column of columns">
          <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" class="form-control"
            (tableChanged)="onChangeTable(config)" />
        </td>
      </tr>
      <tr *ngFor="let row of rows; let rowIdx = index" (click)="selectVariable(row, rowIdx)" [ngClass]="{'is-selected':rowIdx == selectedRowIndex}">
        <td>
          <span>{{row.variableId}}</span>
        </td>
        <td>
          <span>{{row.variableName}}</span>
        </td>
        <td>
          <span>{{row.variableAlternateName}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="isModeReadOnly  then readOnly else editable"></div>

<ng-template #editable>

  <div class="study-header-bar">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Add Variable to Library</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">

        </div>
      </div>
    </div><!-- /.level -->
  </div><!-- /.study-header-bar -->

  <div class="form-page">

    <div class="columns">

      <div class="column is-5">
        <div class=" field is-horizontal ">
          <label class="is-small text-field-padding"> Variable Name: </label>
          <div class="field-body    ">
            <div class="field">
              <div class="control ">
                <input placeholder="Variable Name" class="input is-small width-100-percent" name="variableName" id="variable-name" [(ngModel)]="libraryVariable.variableName">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-2">

      </div>


      <div class="column is-5">
        <div class="field is-horizontal">
          <label class="is-small text-field-padding">Alternate Name :</label>
          <div class="field-body ">
            <div class="field">
              <div class="control">
                <input placeholder="Alternative Name" class="input is-small width-100-percent" name="alternativeVariableName" id="alternative-variable-name "
                  [(ngModel)]="libraryVariable.variableAlternateName">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="columns">
      <div class="  column is-full">
        <div class=" field is-horizontal ">
          <label class="is-small text-field-padding">Description : </label>
          <div class="field-body ">
            <div class="field">
              <div class="control ">
                <input placeholder="Description" name="desc" id="txt-short-desc" [(ngModel)]="libraryVariable.variableDesc" class="input is-small width-100-percent">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-3">
        <div class=" field is-horizontal ">
          <label class="is-small text-field-padding ">Dictionary Code:</label>
          <div class="field-body ">
            <div class="field ">
              <div class="control ">
                <input placeholder="Code" name="code" id="dict-code" class="input is-small width-100-percent"  [(ngModel)]="libraryVariable.dictionaryCode">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-2">
      </div>

      <div class="column  is-2">
        <div class=" field is-horizontal ">
          <div class="field-body ">
            <label class="is-small text-field-padding"> Version:</label>
            <div class="field ">
              <div class="control ">
                <input placeholder="Version" name="version" id="version" class="input is-small width-100-percent" [(ngModel)]="libraryVariable.dictionaryVersion">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-2">
       </div>

      <div class="column is-3">
        <div class=" field is-horizontal ">
          <div class="field-body ">
            <label class="is-small text-field-padding">Group:</label>
            <div class="field ">
              <div class="control">
                <input placeholder="Group" name="group" id="group" class="input is-small width-100-percent" [(ngModel)]="libraryVariable.dictionaryGroup">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-3">
        <div class=" field is-horizontal">
          <div class="field-body ">
            <label class="is-small text-field-padding">Data Type:</label>
            <div class="control input-width">
              <div class="select is-small">
                <select [(ngModel)]="libraryVariable.variableDataTypeId">
                  <option value="null" selected>-- Select --</option>
                  <option *ngFor="let dataType of dataTypeList" [ngValue]="dataType.variableDataTypeId">
                    {{dataType.variableDataTypeName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
      </div>
      <div class="column is-3">
        <div class=" field is-horizontal ">
          <label class="is-small text-field-padding">External Variable Number :</label>
          <div class="field-body ">
            <div class="field">
              <div class="control ">
                <input placeholder="External Number" class="input is-small width-100-percent" name="externalVariableNumber" id="external-variable-number "
                  [(ngModel)]="libraryVariable.externalVariableNum">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">

      <div class="column is-2">
      </div>

      <div class=" column ">
        <button class="button is-primary is-radiusless" (click)="createNewVariable()">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
          <span>Create New Variable</span>
        </button>
      </div>

    </div>

  </div>
</ng-template>

<ng-template #readOnly>

  <div class="study-header-bar">
    <div class="level">
      <div class="level-left navbar-start form-main-title-header ">
        <div class="level-item header-page-title">
          <div class="form-main-title">
            <div class="is-pulled-left">
              View Existing Variable
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-page">
    <div class="form-content study-form ">
      <div class="form-variable">
        <div class="columns">
          <div class="column is-2">
            <span class="is-small">Variable Name:</span>
          </div>
          <div class="field column is-4">
            <div>
              <span class="is-small">{{libraryVariable.variableName}}</span>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-2">
            <span class="is-small">Alternate Name:</span>
          </div>
          <div class="field column is-4">
            <div>
              <span class="is-small">{{libraryVariable.variableAlternateName}}</span>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-2">
            <span class="is-small">Description:</span>
          </div>
          <div class="field column is-4">
            <div>
              <span class="is-small">{{libraryVariable.variableDesc}}</span>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-2">
            <span class="is-small">Dictionary Code:</span>
          </div>
          <div class="field column is-4">
            <div>
              <span class="is-small">{{libraryVariable.dictionaryCode}}</span>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-2">
            <span class="is-small">Version:</span>
          </div>
          <div class="field column is-4">
            <div>
              <span class="is-small">{{libraryVariable.dictionaryVersion}}</span>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-2">
            <span class="is-small">Group:</span>
          </div>
          <div class="field column is-4">
            <div>
              <span class="is-small">{{libraryVariable.dictionaryGroup}}</span>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-2">
            <span class="is-small">External Variable Number:</span>
          </div>
          <div class="field column is-4">
            <div>
              <span class="is-small">{{libraryVariable.externalVariableNum}}</span>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-2">
            <span class="is-small">Data Type:</span>
          </div>
          <div class="field column is-4">
            <div>
              <span class="is-small">{{libraryVariable.variableDataTypeName}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


</ng-template>
