import { UiLayoutElement } from './ui-layout-element';
import { ElementRef } from '@angular/core';
import { PatientFormVariableResponse } from './patient-form-variable-response';



export class PatientErrorDisplayArg {
    constructor(
         public previousError: UiLayoutElement = null,
         public currentError: UiLayoutElement,
         public nextError: UiLayoutElement = null,
         /**
          * The Element that hosts the component to land upon when finish is clicked
          */
         public finishElement: ElementRef ,

         /**
          * is this really an error, requiring comment (code reused for corrections comments)
          */
         public required?: boolean | null,

         public errorCommentModel?: PatientFormVariableResponse,

         public isCorrectionTile?:boolean
    ){}
}
