import { Component, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import * as globalConst from 'app/common/model/app-constants';
import * as _ from 'lodash';

import { EditBarActions } from 'app/common/model/edit-bar-actions';

import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { StudyNotification } from 'app/common/model/study-notification';
import { EventArg } from 'app/common/model/event-arg';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { Helper } from 'app/common/services/helper.service';
import { ToastService } from 'app/common/services/toast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, forkJoin, EMPTY, of } from 'rxjs';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { GroupNotificationSubscription } from 'app/common/model/group-notification-subscription';
import { catchError, tap } from 'rxjs/operators';
import { UserService } from 'app/common/services/user.service';
import { ACTIVITY_NOTIFICATION, ACTIVITY_NOTIFICATION_SETUP } from 'app/common/model/activity-name-constants';
import * as moment from 'moment';
import { NotificationApprovalType } from 'app/common/model/notification-approval-type';




@Component({
  selector: 'mc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss', '../study-setup.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
/**
  * Page variable used during paging
  */
public page = 1;
/**
 * Used to determine the items on a page
 */
public itemsPerPage = 10;
/**
 * maximum page to be displayed
 */
public maxSize = 5;
/**
 * counter to hold the number of pages
 */
public numPages = 1;
/**
 * Length of the array containing the viewModels
 */
public length = 0;
/**
 * Ids selected when the check box is clicked
 */
public selectedIds: number[] = [];

/**
 * variable to hold the studyNotification ids of the notifications that are deleted
 */
deletedIds: number [] = [];

/**
* Array to track the ids selected for edit
*/
editRowIds: number[] = [];

selectedInfoRowId = 0;


/**
 * boolean to determine if the filter row should be displayed
 */
public showFilterRow: boolean = false;
/**
 * variable to hold the viewModels sorted and filtered as needed for the ui
 */
public rows: Array<StudyNotificationViewModel> = [];
/**
 * variable to hold the column heading - Level for study listing, Group for group listing
 */
public levelHeading = 'Level';
/**
 * Columns used to render the header and sorting
 */
public columns: Array<any> = [
  {
    title: 'None', className: [],
    display: false,
    filtering: false,
    name: 'select', sort: ''
  },
  {
    title: 'Title', className: ['office-header', 'text-success'],
    display: true,
    filtering: { filterString: '', placeholder: 'Filter by Title' },
    name: 'name', sort: ''
  },
   {
    title: this.levelHeading, name: 'notificationLevel',
    display: true,
    sort: '',
    filtering: { filterString: '', placeholder: 'Filter by Level' }
  },
  {
    title: 'Type',
    display: true,
    name: 'notificationType', sort: '',
    filtering: { filterString: '', placeholder: 'Filter by Type', sort: '' }
  },
  {
    title: 'Configuration', name: 'notificationConfiguration',
    display: true,
    sort: '',
    filtering: { filterString: '', placeholder: 'Filter by Configuration' }
  },
  {
    title: 'Recipients', name: 'notificationRecipients',
    display: true,
    sort: '',
    filtering: { filterString: '', placeholder: 'Filter by Recipients' }
  },
  {
    title: 'Subscribed', name: 'subscribed',
    display: true,
    sort: '',
    filtering: false
  }
];

/**
 * Configuration for the table displayed
 */
public config: any = {
  paging: false,
  sorting: { columns: this.columns },
  filtering: { filterString: '' },
  className: [ ]
};

  /**
  * The edit bar model for the edit-bar
  */
 editBarActions: EditBarActions = new EditBarActions(false, false, false, false, false);


 /**
  * The studyId for the notifications
  */
 studyId = 0;

 public areAllRowsSelected = false;

 /**
  * Subscription array to hold all the subscribes
  */
 subscriptions: Subscription[] = [];

 /**
  * variable to hold the notifications data
  */
 notifications: StudyNotification[] = [];

 /**
  * variable to hold the viewModel of the notifications
  */
 vmNotifications: StudyNotificationViewModel[] = [];

/**
  * variable to hold the copy ofviewModel of the notifications
  */
 vmOriginalNotifications: StudyNotificationViewModel[] = [];

 /**
  * variable to check if we are working with Group Notifications
  */
 isGroupNotifications = false;

 /**
  * the notificationId selected for edit
  */
 selectedEditId = -1;


/**
 * variable used for the studyNotification array
 *
 */
private data: Array<any>;



 /**
  * Constants for accrual point values - all lower case
  * TODO:  there is a OneNote issue log for consistent use of codes...this is one place where that would be preferred
  */
 private xthPatient = '(xth) patient';
 private everyXPatients = 'every (x) patients';
 private percentPatients = 'a (%) of patients';

 
  constructor(private viewContainer: ViewContainerRef,
    private router: Router,
    private studySetupService: StudySetupService,
    private notificationDashboardService: NotificationDashboardService,
    private helper: Helper,
    private toast: ToastService,
    private route: ActivatedRoute,
    private userService: UserService) {
      this.subscriptions.push(
        studySetupService.changeEmitted$
          .subscribe(eventInfo => {
            this.parseEventInfo(eventInfo); 
         })
      );
  }


 


  ngOnInit() {

    // Get the studyId
    this.route.parent.params.subscribe(params => {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.studyId = params['studyId'];
      if (this.studyId) {
         //This is to make the editBarActioons disabled if the user has only read access.
          if(!this.userService.hasModifyAccessByStudy(ACTIVITY_NOTIFICATION, this.studyId)){
            this.editBarActions.isAddDisabled = true;
            this.editBarActions.isEditDisabled = true;
            this.editBarActions.isDeleteDisabled = true;
          }
       this.subscriptions.push(this.notificationDashboardService.getStudyNotifications(this.studyId)
          .subscribe(results => {
            this.setNotifications(results);
          })
        );
      } else {
        this.setUIForGroupNotifications();

        this.subscriptions.push(this.notificationDashboardService.getGroupNotifications()
          .subscribe(results => {
            this.setNotifications(results);
          })
        );
      }
    });
  }


  /**
   * Update the UI models for displaying Group notification data
   */
  setUIForGroupNotifications() {
    this.isGroupNotifications = true;
    this.levelHeading = 'Group';
    // update the column title for the 3rd column
    this.columns[2].title = this.levelHeading;
    // Remove the 7th column - Subscribed - does not exist for Group notification
    this.columns.splice(6, 1);
    // Set the edit option to false
    this.editBarActions.isEditDisabled = true;

    //This is to make the editBarActioons disabled if the user has only read access.
    if(!this.userService.hasModifyAccess(ACTIVITY_NOTIFICATION_SETUP)){
      this.editBarActions.isAddDisabled = true;
      this.editBarActions.isDeleteDisabled = true;
    }

  }

  /**
   * Common method to set the notification (group or study) after being retrieved
   * @param notifications the notifications to set on the view
   */
  setNotifications(notifications: StudyNotification[]) {
    this.notifications = notifications;
    this.createViewModels();
    this.cloneData(false);
  }


  /**
   * Create viewModels for the ui
   */
  createViewModels() {
    this.vmNotifications = [];
    this.vmNotifications = this.notifications.map(eachItem => new StudyNotificationViewModel(eachItem, this.isGroupNotifications));
    this.defineConfiguration(this.vmNotifications);
    this.data = this.vmNotifications;
    this.length = this.data.length;
    this.onChangeTable(this.config);
  }


  /**
    * True if the notificationId is selected for edit
    */
   isEditMode(notificationId: number): boolean {
    const isEdit: boolean = this.selectedEditId === notificationId;
    return isEdit;
  }
  /**
    * True if the notificationcan be edited
    */
   canEditSubscription(vm: StudyNotificationViewModel): boolean {
    const isEdit: boolean = vm.notificationLevel !== 'Study' &&
          this.selectedEditId === vm.notificationId;
    return isEdit;
  }




  /**
   * Clones the data from / to original data
   * @param rollback True if the original data needs to be restored
   */
  cloneData(rollback: boolean = false) {
    // this is to rollback when cancel is clicked.
    if (rollback) {
      this.deletedIds = [];
      this.vmNotifications = _.cloneDeep(this.vmOriginalNotifications);
      this.data = this.vmNotifications;
      this.length = this.vmNotifications.length;
      this.onChangeTable(this.config);
    } else {
      this.vmOriginalNotifications = _.cloneDeep(this.vmNotifications);
    }
  }

  /**
   * This will Evaluate whether SAVE or CANCEL to be called.
   * @param  eventArg
   */
  parseEventInfo(eventArg: EventArg) {
    if (eventArg.eventName === globalConst.EVENT_SAVE_ACTION) {
      this.saveChanges();
    } else if (eventArg.eventName === globalConst.EVENT_CANCEL_ACTION) {
      // if there are changes...
      this.cloneData(true);
    }
  }

  /**
   * This will be executed when action on the edit bar is performed.
   *
   * @param selectedAction - editbar action
   */
  selectedEditAction(selectedAction: string) {
    if (selectedAction === 'info') {
      // activate the tooltip
      this.processInfoClick();
    } else if (selectedAction === 'add') {
      this.navigateToNotificationSetup(null, null);
    } else if (selectedAction === 'delete') {
      this.deleteSelections();
    } else if (selectedAction === 'filter') {
      this.showFilterRow = !this.showFilterRow;
    } else if (selectedAction === 'edit') {
      if (this.selectedIds[0] === this.selectedEditId) {
        this.selectedEditId = -1;
      } else {
        this.selectedEditId = this.selectedIds[0];
      }
      // this.navigateToNotificationSetup(this.selectedIds[0]);
    }
  }

  processInfoClick() {
    if (this.selectedIds.length > 0) {
      if (this.selectedInfoRowId === this.selectedIds[this.selectedIds.length - 1]) {
        this.selectedInfoRowId = 0;
      } else {
        this.selectedInfoRowId = this.selectedIds[this.selectedIds.length - 1];
      }
    } else {
      this.selectedInfoRowId = 0;
    }
  }

  isInfoSelected(row) {
    return row.notificationId === this.selectedInfoRowId;
  }

  /**
   * Called when a row is selected and the info button is pushed
   *
   * @param row the row of the table to be operated on
   */
  getInfoMessage(row: StudyNotificationViewModel) {
    let message = '';
    if (this.isInfoSelected(row)) {
      if (row.notificationId == null || row.notificationId < 0) {
        message = 'Study notification not saved';
      } else {
        message = this.getInfoMessageString(row.lastModifiedById, row.lastModifiedTs);
      }
    }
    return message;
  }

    /**
     * Parses and returns the message for the info click
     * @param userPk The User Primary Key
     * @param modifiedTs The Date Time for the modified record
     */
    getInfoMessageString(userPk: string, modifiedTs: any): string {
      let message = '';
      message = 'Last updated';
      let lastModifiedDate = '';
      const lastUserName = this.getUserNameFromPrimaryKey(userPk);

      message += ' by ' + lastUserName;
      if (modifiedTs) {
          lastModifiedDate = ' on ' + moment(new Date(modifiedTs)).format('MM/DD/YYYY HH:mm:ss');
      }
      message += lastModifiedDate;
      return message;
  }

  /**
   * Gets the user name from the service using the Primary Key
   * @param userPk The userPk string
   */
  getUserNameFromPrimaryKey(userPk: string) {
      let username = userPk; // 'unknown';
      if (userPk && userPk.trim().length > 0) {
          // force to be numeric
          const userPkNum: any = +userPk;
          if (!isNaN(userPkNum)) {
              this.userService.getPersonForId(userPkNum)
                  .subscribe(result => {
                      username = result.lastName + ', ' + result.firstName;
                  }, (error) => {
                      username = userPk + '(name not found)';
                  }, () => {
                      // console.log("Complete subscription ")
                  });
          }
      }
      return username;
  }


  /**
   * Returns True if the notification is of type "Notify"
   * @param notification the notification to verify
   */
  isNotificationTypeNotify(notification: StudyNotificationViewModel): boolean {
    let isNotify = false;
    if (notification ) {
      isNotify = notification.notificationType === globalConst.NOTIFY_TYPE_NOTIFY;
    }
    return isNotify;
  }

  /**
   * Delete the selected
  */
  deleteSelections(): void {
    if (this.selectedIds.length === 0) {
      return;
    }
    this.selectedIds.forEach(eachId => {
      const idx = this.vmNotifications.findIndex(eachNotify => eachNotify.notificationId === eachId);
      const selection: StudyNotificationViewModel = this.vmNotifications[idx];
      if (!this.isNotificationTypeNotify(selection)) {
        this.vmNotifications.splice(idx, 1);
        this.deletedIds.push(eachId);
        //      this.notifications.splice(idx, 1);
      }
    })
    this.selectedIds = [];
    this.onChangeTable(this.config);
  }

  /**
   * Sorts the data in the table based on the column and sort order
   * @param data The data currently being displayed on the table
   * @param config The associated configuration for the data being displayed
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;


    for(const column of columns) {
      if (column.sort !== '' && column.sort !== false) {
        columnName = column.name;
        sort = column.sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      const previousVal = previous[columnName] ? previous[columnName].toLowerCase() : '';
      const currentVal = current[columnName] ? current[columnName].toLowerCase() : '';

      if (previousVal > currentVal) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousVal < currentVal) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data === undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name] != null) {
            return item[column.name].toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name] != null && item[column.name] !== undefined
          && item[column.name].toString().match(this.config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  /**
   * Main method to handle filtering, sorting of the table
   * @param config Configuration of the datatable
   * @param page The current page being displayed
   */
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    let column: any = null;
    this.rows = [];
    if (config.title !== undefined && config.name !== undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.data, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;
    if (sortedData) {
      this.length = sortedData.length;
    }
  }

  /**
   * Manages the click of the checkbox on the first column
   * @param data The row being evaluated
   * @param columnName The column name for the row
   */
  cellClick(data: any, columnName: string): any {
    this.selectedInfoRowId = 0;
    this.selectedEditId = -1;
    const selectedId: number = this.selectedIds.indexOf(data.notificationId);
    if (selectedId > -1) {
      this.selectedIds.splice(selectedId, 1);
    } else {
      this.selectedIds.push(data.notificationId);
    }
  }

  /**
   * True if the row has been check for selection
   * @param row the row being evaluated
   */
  isRowSelected(row) {
    if (row) {
      const idx = this.selectedIds.indexOf(row.notificationId);
      return (idx > -1);
    }
    return false;
  }
  /**
   *  define configuration.
   *
   * @param  notificationModel
   */
  defineConfiguration(notificationModel: StudyNotificationViewModel[]) {
    for (const notifi of notificationModel) {
      notifi.notificationConfiguration = "";
      let accrualPoint = '?';
      let accrualPointType="";
         if (notifi.notificationType === 'Approval' && notifi.studyNotification.notificationApprovalTypeList) {
           for (const notificationApprovalType of  notifi.studyNotification.notificationApprovalTypeList) {
              if (notifi.notificationConfiguration !== "") notifi.notificationConfiguration = notifi.notificationConfiguration + ", ";
              notifi.notificationConfiguration = notifi.notificationConfiguration + notificationApprovalType.approvalType.value;
           } 
         } else {
           if (notifi.studyNotification && notifi.studyNotification.notificationAccrualDetail && notifi.studyNotification.notificationAccrualDetail.accrualPoint) {
              accrualPoint = notifi.studyNotification.notificationAccrualDetail.accrualPoint;
           }
           accrualPointType = notifi.studyNotification.notificationAccrualDetail.accrualPointType?.value?.trim().toLowerCase();
           if (accrualPointType === this.everyXPatients) {
             notifi.notificationConfiguration = 'Every ' + accrualPoint + ' Patients';
          } else if (accrualPointType === this.percentPatients) {
             notifi.notificationConfiguration = accrualPoint + '% Accruals';
          } else if (accrualPointType === this.xthPatient) {
             notifi.notificationConfiguration = accrualPoint + ' Patient';
        }
      }
    
   }
  }

  /**
   * Fires the navigate event to get the navigation to the notification setup
   * @param Event The event is any being raised
   * @param notification The notification object for the setup
   */
  navigateToNotificationSetup($event: Event, notification: StudyNotificationViewModel): void {
    if ($event) {
      $event.stopPropagation();
    }
    let qryParams = {};
    let isGroupNotification = false;
    if (!this.isGroupNotifications && notification && notification.notificationLevel) {
      isGroupNotification = notification.notificationLevel.toLowerCase() === 'group'
      qryParams = { isGroupNotification: isGroupNotification }
    }
    console.log('Groupd Nav', qryParams)
    const notifyId: number = notification ? notification.notificationId : 0;
    const navUrl: string = '../notifications/' + notifyId
    this.router.navigate([navUrl],
      {
        relativeTo: this.route,
        queryParams: qryParams,
        queryParamsHandling: 'merge'
      });
  }



  /**
  * Fires the event from the component for the parent component
  * @param evntArg The event arg for the fired event
  */
  fireDataChangeEvent(evntArg: EventArg) {
    this.studySetupService.emitChange(evntArg);
  }

  /** Saves the deletions*/
  saveChanges() {
    if (!this.doChangesExist()) {
      return;
    }
    let variableData = '';
    const modifiedData: StudyNotificationViewModel[] = this.getDataChanges();

    if (!this.isGroupNotifications) {
      variableData = 'studyNumber:' + this.studySetupService.studyRecord.studyNumber;
    }
    const observables: Observable<any>[] = [];
    if (this.isGroupNotifications) {
      observables.push(forkJoin(this.saveGroupNotifications()));
    } else {
      observables.push(forkJoin(this.saveStudyNotifications(modifiedData)));
    }
    if (observables.length > 0) {
      const errorMessages: string[] = [];
      this.subscriptions.push(forkJoin(observables)
        .subscribe(() => {
        }, (err) => { console.log(err) },
          () => {
            this.deletedIds = [];
            const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
            this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
              .subscribe(actionResult => {
                 this.createViewModels();
                 this.cloneData(false);
                if (!actionResult) {
                  // user wants to navigate to the Study Landing page
                  // TODO: Navigate to the Study landing page
                  if (this.isGroupNotifications) {
                    // navigate to the group notifications landing page
                     this.router.navigate(['group-notification']);
                  } else {
                    this.router.navigate(['studies', this.studyId ]);
                  }
                }
              });
          })
      )
    }
  }

  /**
    * Save the Group Notifications changes to ui
    */
  saveStudyNotifications(modifiedItems: StudyNotificationViewModel[]): Observable<any>[] {
    const observables: Observable<any>[] = [];
    const errorMessages: string[] = [];
    if (this.deletedIds.length > 0) {
      this.deletedIds.forEach(eachId => {
        const match = this.notifications.find(eachNotification => eachNotification.notificationId === eachId);
        observables.push(this.notificationDashboardService.deleteStudyNotification(this.studyId, eachId, match.tupleVersion)
          .pipe(tap(x => {
            const idx = this.notifications.findIndex(each => each.notificationId === eachId);
            this.notifications.splice(idx, 1);
          }))
          .pipe(catchError(
            error => {
              console.log('Error caught during delete.');
              errorMessages.push(error);
              return EMPTY;
            }))
        )
      })
    }
    if (modifiedItems.length > 0) {
      modifiedItems.forEach(eachItem => {
        const match = this.notifications.find(eachNotification => eachNotification.notificationId === eachItem.notificationId);
        if (match) {
          if (match.subscribedToGroupNotification == null) {
            match.subscribedToGroupNotification = new GroupNotificationSubscription(eachItem.isSubscribed, null, null);
          } else {
            match.subscribedToGroupNotification.subscribed = eachItem.isSubscribed;
          }
          observables.push(this.notificationDashboardService.updateStudyNotification(this.studyId, match.notificationId, match)
            .pipe(tap(x => {
              const idx = this.notifications.findIndex(each => each.notificationId === eachItem.notificationId);
              this.notifications[idx] = x;
            }))
            .pipe(catchError(
              error => {
                console.log('Error caught during update.');
                errorMessages.push(error);
                return EMPTY;
              }))
          )
        }

      });
    }
    return observables;
  }

  /**
   * Save the Group Notifications changes to ui
   */
  saveGroupNotifications(): Observable<any>[] {
    // TODO: Save Updates to Group Notifications
    const observables: Observable<any>[] = [];
    const errorMessages: string[] = [];
    if (this.deletedIds.length > 0) {
      this.deletedIds.forEach(eachId => {
        const match = this.notifications.find(eachNotification => eachNotification.notificationId === eachId);
        observables.push(this.notificationDashboardService.deleteGroupNotification(eachId, match.tupleVersion)
          .pipe(tap(x => {
            const idx = this.notifications.findIndex(each => each.notificationId === eachId);
            this.notifications.splice(idx, 1);
          }))
          .pipe(catchError(
            error => {
              console.log('Error caught during delete.');
              errorMessages.push(error);
              return EMPTY;
            }))
        )
      })
    }
    return observables;
  }


  /**
   * Returns true if there are pending changes on page
   */
  doChangesExist() {
    const isModified: boolean = !(_.isEqual(this.vmNotifications, this.vmOriginalNotifications));
    return isModified;
    // return this.deletedIds.length > 0;
  }
/**
   * Returns a list of modified form data
   */
  getDataChanges(): Array<StudyNotificationViewModel> {
    const isModifiedData = _.differenceWith(this.vmNotifications, this.vmOriginalNotifications, _.isEqual);
    return isModifiedData;
  }


  /********* BEGIN: Pending Changes warning************** */
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.doChangesExist()) {
      return true;
                }
    const modalArg = new ModalDialogArg('modal-warn', 'Cancel');
    const result = this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg);
    return result.asObservable().pipe(tap(x => {
      if (x) {
        this.cloneData(false);
      }
      return of(x);
      }));
  }

  /********* END : Pending Changes warning************** */

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe(); 
    }
    )
  }




}





/**
 * Flattened UI ViewModel to better allow for sorting and filtering on the table
*/
export class StudyNotificationViewModel {
  public studyNotificationId: number;
  public notificationId: number;
  public name: string;
  public longDesc: string;
  public notificationType: string;
  public notificationLevel: string;
  public notificationConfiguration: string;
  public notificationRecipients: string;
  public studyNotification: StudyNotification;
  public isSubscribed: boolean | null;
  public lastModifiedById: string;
  public lastModifiedTs: Date;

  constructor(studyNotification: StudyNotification, isGroupList: boolean) {
    this.lastModifiedById = studyNotification.modifiedById;
    this.lastModifiedTs = studyNotification.modifiedTs;

    this.studyNotificationId = studyNotification.studyNotificationId;
    this.notificationId = studyNotification.notificationId;
    this.name = studyNotification.name;
    this.longDesc = studyNotification.longDesc;
    if (studyNotification.notificationType) {
      this.notificationType = studyNotification.notificationType.value;
    }
    if (studyNotification.researchEntity == null || studyNotification.researchEntity.id == null) {
      this.notificationLevel = 'Study';
      this.isSubscribed = null;
    } else {/*
      //TODO: hard coded for now until the property is set from the notification object */
      if (studyNotification.subscribedToGroupNotification) {
        this.isSubscribed = studyNotification.subscribedToGroupNotification.subscribed;
      }

      if (!isGroupList) {
        this.notificationLevel = 'Group';
      } else {
        this.notificationLevel = studyNotification.researchEntity.name;
      }
    }

    let distList = '';
    const distArray: Array<string> = [];
    if (studyNotification.notificationDistributionList &&
        studyNotification.notificationDistributionList.length > 0) {
        studyNotification.notificationDistributionList.forEach(eachItem => {
          if (eachItem.individual && eachItem.individual.email) {
            distArray.push(eachItem.individual.email);
          }
          if (eachItem.role && eachItem.role.value) {
            distArray.push(eachItem.role.desc);
          }
          if (eachItem.personType && eachItem.personType.value) {
            distArray.push(eachItem.personType.desc);
          }
          if (eachItem.sharedEmail && eachItem.sharedEmail.value) {
            distArray.push(eachItem.sharedEmail.value);
          }
      })
      distList = distArray.join(',');
    }
    this.notificationRecipients = distList;
    this.studyNotification = studyNotification;
  }
}
