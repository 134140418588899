import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as globalConst from 'app/common/model/app-constants';
import { Subscription } from 'rxjs';
import { SearchCriteria } from '../../common/model/search-criteria';
import { SearchService } from '../../common/services/search.service';
import { StudyRecord } from './../../common/model/study-record';



@Component({
  selector: 'mc-protocol-search-results',
  templateUrl: './protocol-search-results.component.html',
  styleUrls: ['./protocol-search-results.component.scss']
  // ,  providers:[ProtocolSearchService]
})
export class ProtocolSearchResultsComponent implements OnInit,  OnDestroy {

  searchCriteria: SearchCriteria = null;

  subscriptions: Subscription[] = [];


  @Output() returnToSearchEvent = new EventEmitter();

  results: StudyRecord[];
  rows: StudyRecord[];
  noResultsFound = false;


  public columns: Array<any> = [
    {
      title: 'Search By', name: 'referenceNumber',
      sort: '',
      display: true,
      filtering: false
    },
     {
      title: 'Reference Type', name: 'referenceNumber',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Study #', name: 'studyNumber',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Status',
      name: 'status.studyStatusDesc',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Study Type', name: 'studyType',
      sort: '',
      display: true,
      filtering: false
    },
    {
      title: 'Acronym', name: 'acronym', sort: false,
      display: true,
      filtering: false
    },
    {
      title: 'Long Description', name: 'longDescription',
      sort: false,
      display: true,
      filtering: false
    }

  ];

  /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: false,
    className: ['is-striped', 'is-bordered']
  };



  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }

  /**
 * Sorts the data in the table based on the column and sort order
 * @param data The data currently being displayed on the table
 * @param config The associated configuration for the data being displayed
 */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousValue = '';
      let currentValue = '';
      if (columnName === 'status.studyStatusDesc') {
        previousValue = previous['status'] ? previous['status']['studyStatusDesc'].toLowerCase() : '';
        currentValue = current['status'] ? current['status']['studyStatusDesc'].toLowerCase() : '';
      } else {
        previousValue = previous[columnName] ? previous[columnName].toLowerCase() : '';
        currentValue = current[columnName] ? current[columnName].toLowerCase() : '';
      }

      if (previousValue > currentValue) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousValue < currentValue) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
  * Main method to handle filtering, sorting of the table
  * @param config Configuration of the datatable
  * @param page The current page being displayed
  */
  public onChangeTable(config: any): any {
    let column: any = null;
    this.rows = [];
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.results, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;

  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (item[column.name] != null) {
            return item[column.name].toLowerCase().match(column.filtering.filterString.toLowerCase());
          }
          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name] != null && item[column.name] != undefined
          && item[column.name].toString().match(this.config.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }



  constructor(private searchService: SearchService,
    private router: Router) {
  }

  /**
   * Returns the results of the protocol search
   */
  getProtocolSearchResults() {
   this.subscriptions.push( this.searchService.getStudy(this.searchCriteria.searchTerm)
      .subscribe(res => {
        this.results = res;
        this.noResultsFound = (this.results.length == 0);
        this.onChangeTable(this.config);
      }));

  }



  /**
   * Navigate to the study landing page
   * @param study The study record
   */
  goToStudyLanding(study: StudyRecord) {
    if (study.studyType == 'Ancillary') {
      const path: string = 'admin/studies/' + study.studyId + '/setup/ancillary';
      this.router.navigate([path], {
        queryParams: {
          'navBack': globalConst.LANDING_PAGE_ENUM_HOME
        }
      });
    } else {
      this.router.navigate(['/studies', study.studyId]);
    }
  }

  ngOnInit() {
    this.subscriptions.push(this.searchService.searchQuery$.subscribe(
      criteria => {
        this.searchCriteria = null;
        if (criteria && criteria.searchType == globalConst.SEARCH_CRITERIA_TYPE_PROTOCOL) {
          this.searchCriteria = criteria;
          this.getProtocolSearchResults();
        } else {
          this.results = null;
        }
      }
    ))
  }

  // ngOnChanges(changes: any): void {
  //   this.noResultsFound = false;
  //   if (this._sCriteria) {
  //     //   //if(this._sCriteria.searchType == "studyNumber"){
  //     //     this.getProtocolSearchResults();
  //     //  // }

  //   } else {
  //     this.results = null;
  //   }
  // }

  returnToSearch() {
    this.fireEvent();
  }

  fireEvent() {
    this.returnToSearchEvent.emit();
  }


}
