
import { Component, Input, OnInit } from '@angular/core';
import { ReportArmAccrual } from 'app/common/model/report-arm-accrual';
import { ReportGrpAccrualDtl } from 'app/common/model/report-grp-accrual-dtl';
import { ReportStudyAccrual } from '../../../common/model/report-study-accual';
import { ReportsService } from './../../../common/services/reports.service';
import { ReportEnrollmentEventAccrual } from 'app/common/model/report-event-accrual';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { ErrorMessage } from 'app/common/model/error-message';

@Component({
  selector: 'mc-study-accrual-report-view',
  templateUrl: './study-accrual-report-view.component.html',
  styleUrls: ['./study-accrual-report-view.component.scss']
})


export class StudyAccrualReportViewComponent implements OnInit {

  @Input() contentType = ''; // Get Content Type
  @Input() param = ''; // Get STudyID


  public reportStudyAccrualPropResults: ReportStudyAccrual;
  public reportStudyAccrualArm: ReportArmAccrual[];  
  public reportStudyAccrualEnrollmentEvent: ReportEnrollmentEventAccrual[];
  public reportStudyAccrualGrp: ReportGrpAccrualDtl[];
  public errorMessage: String;;

  public studiesReportFound: boolean;
  // rows :  ReportStudyAccrualProp[];

  constructor(
    // private router: Router,
    private reportLandingService: ReportsService) { }


  /**
   * Reset the Report part based on studiesReportFound value
   */
  ngOnInit() {
    this.studiesReportFound = false;
  }

  /**
   * Calling service to get the data based on StudyID as param
   */
  private getStudyResults() {


    if (this.contentType === 'reportStudyAccrual') {
          this.errorMessage = '';
          this.reportLandingService.getStudyAccrualReport(this.param).subscribe(results => {


            // this.studiesReportFound = true;
            this.studiesReportFound = false;


          if (results.studyId > 0) {
            this.reportStudyAccrualPropResults = results;
            this.reportStudyAccrualArm = this.reportStudyAccrualPropResults.armAccruals;
            this.reportStudyAccrualEnrollmentEvent = this.reportStudyAccrualPropResults.enrollmentEventAccruals;
            this.reportStudyAccrualGrp = this.reportStudyAccrualPropResults.groupingAccrualDetails;
            this.studiesReportFound = true;


         } else {
           this.studiesReportFound = false;
         }


        }, 
        error => {
          if (error.status == 400) {
            this.studiesReportFound = false;
            this.errorMessage =  error.error.message; 
          }
		  }
        )
    }
   // this.studiesReportFound=false;
  }

  /**
   * This gets called whenever the input (contentType) changes
   * @param changes
   */
  ngOnChanges(changes: any): void {
    this.getStudyResults();
  }




}
