<!-- <div class="tabs is-boxed is-preview-minimized" *ngIf="isCollapsedHorizontal()">
  <ul>
    <li class="is-active">
      <a class="preview-bg" (click)="togglePreviewHorizontal()">
        <span>
          <i class="fas fa-caret-up"></i>
        </span>
      </a>
    </li>
  </ul>
</div> -->

<!-- *ngIf="!isCollapsedHorizontal()" -->
<div class="preview-block" id="preview-block" [ngClass]="{'is-horizontal-collapsed':isCollapsedHorizontal(),
          'is-vertical-collapsed':this.previewModel.isVertical}">
  <div class="preview-bar" id="preview-bar" [ngClass]="{'hide-content': previewModel.isVertical}"></div>
  <div class="preview-bar-vertical hide-content" id="preview-bar-vertical"
    [ngClass]="{'hide-content': !previewModel.isVertical}">
  </div>
  <div class="preview-content" id="preview-btn" [ngClass]="{'hide-content': previewModel.isVertical}">
    <div *ngIf="isPdf" class="pdf-btn" (click)="downloadPdf()" title="Print PDF">
      <i class="fas fa-file-pdf"></i>
    </div>
    <div *ngIf="!isPdf" class="excel-btn" (click)="downloadExcel()" title="Download excel">
      <i class="fas fa-file-excel"></i>
    </div>
    <div id="switch-structure-btn" (click)="switchToHorizontal($event)" title="Change to Portrait Mode">
      <span class="icon">
        <span *ngIf="!this.previewModel.isVertical">
          <i class="fas fa-arrows-alt-v"></i>
        </span>
      </span>
    </div>
    <div class="preview-button" (click)="togglePreviewHorizontal()">
      <p class="preview-btn-text">
        <span *ngIf="isCollapsedHorizontal()">
          <i class="fas fa-caret-up"></i>
        </span>
        <span *ngIf="isExpandedHorizontal()">
          <i class="fas fa-caret-down"></i>
        </span>
        {{formCaption}}
      </p>
    </div>
  </div>

  <div class="preview-content-vertical" id="preview-content-vertical-btn"
    [ngClass]="{'hide-content': !previewModel.isVertical}">
    <div id="switch-structure-btn-vertical" (click)="switchToVertical()" title="Change to Landscape Mode">
      <span class="icon">
        <span *ngIf="this.previewModel.isVertical">
          <i class="fas fa-arrows-alt-h"></i>
        </span>
      </span>
    </div>
    <div *ngIf="isPdf" class="vertical-pdf pdf-btn" (click)="downloadPdf()" title="Print PDF">
      <i class="fas fa-file-pdf"></i>
    </div>
    <div *ngIf="!isPdf" class="vertical-excel excel-btn" (click)="downloadExcel()" title="Download excel">
      <i class="fas fa-file-excel"></i>
    </div>
    <div class="preview-button-vertical" (click)="togglePreviewVertical()">
      <p class="preview-btn-text ">
        <span *ngIf="isCollapsedVertical()">
          <i class="fas fa-caret-up"></i>
        </span>
        <span *ngIf="isExpandedVertical()">
          <i class="fas fa-caret-down"></i>
        </span>
        {{formCaption}}
      </p>
    </div>
  </div>
</div>

<div class="content-section">
  <!-- [ngClass]="{'hide-content':isCollapsedHorizontal()}"> -->
  <div class="show-preview-content" id="show-preview-div" #showPreviewDiv
    [ngClass]="{'is-collapsed-height':isCollapsedHorizontal()}">
    <div class="preview-list-content" [ngClass]="{'preview-list-content-toggle-height': isCollapsedHorizontal(),
                'preview-vertical' : this.previewModel.isVertical  }" id="preview-list-content">
      <div #content class="show-content " [ngClass]="{'is-hidden': previewModel.isCollapsed }">
        <p class="preview-title">{{formTitle}} </p>
        <!-- add the select attribute to ng-content -->
        <ng-content select="[preview-data]"></ng-content>
      </div>
    </div>
  </div>

</div>