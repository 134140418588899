import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationSetupComponent } from 'app/admin/notification-setup/notification-setup.component';
import { CanDeactivateGuard } from 'app/can-deactivate-guard.service';
import { NotificationInfoComponent } from 'app/admin/notification-setup/notification-info/notification-info.component';
import { RecipientsComponent } from './recipients/recipients.component';
import { MessageTextComponent } from './message-text/message-text.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { GroupConfigurationComponent } from './group-configuration/group-configuration.component';
import { AuthGuard } from 'app/auth-guard.service';


const notificationSetupRoutes: Routes = [
  {
    path: '',
    component: NotificationSetupComponent,
    children: [
      {
        path: '',
        redirectTo: 'notification-info',
        pathMatch: 'full'
      },
      {
        path: 'notification-info',
        component: NotificationInfoComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          breadcrumb: 'Notification Info'
        }
      },
      {
        path: 'configuration',
        component: ConfigurationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          breadcrumb: 'Configurations'
        }
      },
      {
        path: 'message-text', component: MessageTextComponent, canDeactivate: [CanDeactivateGuard],
        data: {
          breadcrumb: 'Message Text'
        }
      },
      {
        path: 'recipients',
        component: RecipientsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          breadcrumb: 'Message Text'
        }
      },
      {
        path: 'group-configuration',
        component: GroupConfigurationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          breadcrumb: 'Group Configuration'
        }
      }

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(notificationSetupRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class NotificationSetupRoutingModule { }
