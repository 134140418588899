import { Directive } from '@angular/core';

@Directive({
  selector: '[trueFormMessages]'
})
export class FormMessagesDirective {

  constructor() { }

}
