import { Component, OnInit, Input, HostListener, ViewChild, ElementRef, Renderer2, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { HeaderBar } from '../model/header-bar';
import { Router } from '@angular/router';


@Component({
  selector: 'mc-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * Input model for displaying the header bar
   */
  @Input() headerModel: HeaderBar;

  /**
   * Event Emitter for the save event
   */
  @Output() saveEvent  = new EventEmitter();

  /**
   * Event emitter for the cancel event
   */
  @Output() cancelEvent = new EventEmitter();


  /**
   * boolean for the show / hide tooltip
   */
  toolTipShow = false;

  /**
 * indicator for the ui to indicate that the text has overflowed
 */
  hasTextOverflowed = false;

  /**
   * The div holding the header description text
   */
  headerElementDiv: any = null;

  /**
   * The reference to the html element holding the header description
   */
  @ViewChild('headerDescription', {read: ElementRef, static: true}) headerHtmlDiv: any;



  isDescriptionOverflowing: boolean = null;

  constructor(private element: ElementRef,
    private renderer: Renderer2,
    private router: Router) { }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  /**
   * initialize the root element if it has not been initialized
   */
  selectRootElement() {
    if (this.headerHtmlDiv != undefined) {
      this.headerElementDiv = this.renderer.selectRootElement(this.headerHtmlDiv);
    }
    if (this.headerElementDiv != undefined && this.headerElementDiv != null) {
      this.headerElementDiv = this.renderer.selectRootElement(this.headerHtmlDiv);
      return this.headerElementDiv;
    }
    return null;
  }




  /**
   * Method called by the parent when the model is populated
   */
  initHeader() {

  }


  /**
 * Destroy implementation - closes all the subscriptions
 */
  ngOnDestroy() {
  }
  /**
     * True if the tooltip is to be displayed
     */
  public isTextOverflowing(): boolean {
    if (this.isDescriptionOverflowing != null) {
      return this.isDescriptionOverflowing;
    }
    if (this.selectRootElement()) {
      setTimeout(() => {
        if (this.headerElementDiv && this.headerElementDiv.nativeElement) {
          this.isDescriptionOverflowing = this.isOverflowed(this.headerElementDiv.nativeElement);
          return this.isDescriptionOverflowing;
        }
      });
    }
    return false;
  }

  /**
   * Navigate to the specified URL
   */
  navigate() {
   // console.log("Navigate => " , this.headerModel.navigateUrl)
    this.router.navigate([this.headerModel.navigateUrl]);
  }


  /**
   * Check if the elements content is larger than the displayed size
   * @param element
   */
  isOverflowed(element): boolean {
    if (element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth) {
      return true;
    } else {
      return false;
    }
  }


  /**
   * True if the model's disableButtons is set to true explicitely
   */
  areButtonsDisabled() {
    if (this.headerModel.disableButtons != undefined) {
      return this.headerModel.disableButtons ;
    }
    return false ;
  }

  /**
   *
   * Handler for the save button click
   */
  saveChanges($event: Event) {
    if (this.headerModel.disableButtons) {
      return;
    }
    this.saveEvent.emit();
  }

  /**
   * Handler for the cancel button click
   */
  cancelChanges($event: Event): void {
    if (this.headerModel.disableButtons) {
      return;
    }
    this.cancelEvent.emit();
  }


  /**
   * Toggle the display of the tooltip
   * @param $event The event being raised
   */
  toggleTooltipDisplay($event: Event): void {
    if ($event) {
      $event.stopPropagation();
    }
    this.toolTipShow = !this.toolTipShow;
  }

}
