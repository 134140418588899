import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from 'app/app-config/app-config.module';
import { UnblindedModalComponent } from 'app/common/modal/unblinded-modal/unblinded-modal.component';
import * as activityConst from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import { PatientDoubleBlindTileData } from 'app/common/model/patient-double-blind-tile-data';
import { PatientRegPreviewParam } from 'app/common/model/patient-reg-preview-param';
import { PatientTransferTileData } from 'app/common/model/patient-transfer-tile-data';
import { StudyRecord } from 'app/common/model/study-record';
import { StudyValidateRegistrationInterfaceResponse } from 'app/common/model/study-validate-registration-interface-response';
import { UnblindedInfo } from 'app/common/model/unblinded-info';
import { DoubleBlindService } from 'app/common/services/double-blind/double-blind.service';
import { StudyServiceBase } from 'app/common/services/study-service-base.service';
import { UserService } from 'app/common/services/user.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HeaderBarComponent } from '../common/header-bar/header-bar.component';
import { HeaderBar } from '../common/model/header-bar';
import { ModalDialogArg } from '../common/model/modal-dialog-args';
import { PatientEnrollmentEvent } from '../common/model/patient-enrollment-event';
import { PreviewPanelModel } from '../common/model/preview-panel-model';
import { SchemaPathBuilder } from '../common/model/schema-path-builder';
import { StudySchema } from '../common/model/study-schema';
import { StudySchemaEvent } from '../common/model/study-schema-event';
import { PatientService } from '../common/services/patient/patient.service';
import { SchemaDashboardService } from '../common/services/schema/schema-dashboard.service';
import { StudySetupService } from '../common/services/study-setup.service';




@Component({
  selector: 'mc-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit, AfterViewInit, OnDestroy {

  subscriptions: Subscription[] = [];

  schemaEvents: any = [];

  patientId: string;
  studyId = 0;
  caseDetailId: number;
  caseEventId = 0;

  schema: StudySchema = null;

  /**
   * The current enrollment event that the patient is currently on
   */
  currentEnrollmentEvent: PatientEnrollmentEvent = null;

  /**
   * The next enrollment event the user can navigate to
   */
  nextEnrollmentEvent: PatientEnrollmentEvent = null;

  /**
   * is current event deactivated?  if so, can't proceed
   */
  isCurrentEventDeactivated = false;

  /**
   * List of enrollment events including past, current, and next
   */
  enrollmentEventList: PatientEnrollmentEvent[] = [];

  previousEnrolledEvents: StudySchemaEvent[] = [];

  /**
   * Selected Enrollment Event
   */
  selectedEnrollmentEvent: PatientEnrollmentEvent = null;


  navBackPageEnum = 0;
  trackingNum: string;
  selectedCaseEventId: number;
  navBackAncillary: number = 0;

  /**
   * model for the header bar
   */
  headerModel: HeaderBar;


  nextEventName = '';


  eligibilityStatus = 'Completed';
  eligibilityStatusDate = '';

  patientStatus = 'Registered';
  patientStatusDate: string = moment(new Date()).format('MM/DD/YYYY');

  transferStatus = 'On Hold';
  transferStatusDate: string = moment(new Date()).format('MM/DD/YYYY');

  approvalStatus = '';


  eventList: any[] = [];

  /**
   * Array of approvalEvents
   */
  approvalEvents: any[] = [];


  doubleBlindTileData: PatientDoubleBlindTileData = {
    ordersAvailableCount: 0,
    doubleBlindConfigured: false
  };


  transferTileData: PatientTransferTileData = {
    'transferStatus': null,
    'transferStatusDate': null
  };

  previewParams: PatientRegPreviewParam = { caseEventId: 0, showDetails: false, studyId: 0 };

  public validateRegistrationInterfaceResponse: StudyValidateRegistrationInterfaceResponse;

  public studyRecord: StudyRecord;

  /**
   * Reference to the child header component
   */
  @ViewChild(HeaderBarComponent)
  private headerBar: HeaderBarComponent;


  /** custom handling of the preview page********************* */

  /**
   * Model to drive the orientation and horizontal/vertical states of the preview window
   */
  previewModelHome: PreviewPanelModel = new PreviewPanelModel();

  previewTitle = 'Review Subject';

  formTitle = 'Review Subject';

  printDocType: number = globalConst.REVIEW_PATIENT;

  unblindedInfo: UnblindedInfo;

  /**
   * Reference to the child modal component
   */
  @ViewChild(UnblindedModalComponent)
  private modalComponent: UnblindedModalComponent;

  constructor(
    private patientService: PatientService,
    private schemaService: SchemaDashboardService,
    private studySetupService: StudySetupService,
    private doubleBlindService: DoubleBlindService,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
    private studyService: StudyServiceBase,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {
  }



  /**
   * Init method implementation for the component
   */
  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params['navBack']) {
          this.navBackPageEnum = +params['navBack'];
        }
        if (params['trackingNum']) {
          this.trackingNum = params['trackingNum'];
        }
        if (params['selectedCaseEventId']) {
          this.selectedCaseEventId = params['selectedCaseEventId'];
        }
        if (params['navBackAncillaryStudyId']) {
          this.navBackAncillary = params['navBackAncillaryStudyId'];
        }
      }));

    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.patientId = params['patientId'];
        this.studyId = +params['studyId'];
        this.caseDetailId = +params['caseDetailId'];
        this.previewParams.studyId = this.studyId;
        if (this.studyId > 0) {
          this.subscriptions.push(this.schemaService.getCurrentValidatedSchema(this.studyId)
            .subscribe(result => {
              this.schema = result;
              if (this.caseDetailId) {
                this.subscriptions.push(
                  this.patientService.getAlreadyRegisteredEnrolledEvents(this.caseDetailId)
                    .subscribe(results => {
                      this.previousEnrolledEvents = results;
                      this.setEnrollmentEvents();
                      this.subscriptions.push(
                        this.patientService.getEnrollmentEvents(this.caseDetailId)
                          .subscribe(results => {
                            this.enrollmentEventList = results;
                            if (this.enrollmentEventList.length > 0) {
                              this.enrollmentEventList.forEach(eachEvt => {
                                // if nextEvent = true, set it as the nextEnrollment Event
                                if (eachEvt.nextEvent) {
                                  this.nextEnrollmentEvent = eachEvt;
                                }
                                if (eachEvt.caseEventId == this.selectedCaseEventId) {
                                  this.selectedEnrollmentEvent = eachEvt;
                                  this.previewParams.caseEventId = this.selectedEnrollmentEvent.caseEventId;
                                }
                                if (eachEvt.currentEvent) {
                                  // if currentEvent = true, set it as the currentEnrollmentEvent
                                  this.currentEnrollmentEvent = eachEvt;
                                  if (this.selectedCaseEventId === undefined) { //} || this.selectedCaseEventId == 0) {  // a new or in-process registration
                                    this.selectedEnrollmentEvent = eachEvt;
                                    this.previewParams.caseEventId = this.selectedEnrollmentEvent.caseEventId;
                                  }
                                  if (this.currentEnrollmentEvent.eventStatusAttribute && this.currentEnrollmentEvent.eventStatusAttribute.startsWith('Deactivated')) {
                                    // if the current event has been deactivated, they are not allowed to continue
                                    this.nextEnrollmentEvent = null;
                                    this.isCurrentEventDeactivated = true;
                                  }
                                }
                                // find the keyEventMappingId
                                const schma = this.schemaEvents.find(item => item.keyEventMappingId == eachEvt.keyEventMappingId);
                                if (schma) {
                                  schma['caseEventId'] = eachEvt.caseEventId;
                                  schma['eventEnrollmentDate'] = eachEvt.eventEnrollmentDate;
                                  schma['studySchemaEventLabel'] = eachEvt.studySchemaEventLabel;
                                }
                              });

                            }
                            if (this.currentEnrollmentEvent == null && this.enrollmentEventList.length <= 1) { // proceed to reg page iff pt has completed some prior enrollment events
                              const modalArg = new ModalDialogArg('modal-warn', 'Custom', 'Invalid Configuration: No more enrollment events available.');
                              this.patientService.showModalDialog(this.viewContainer, modalArg)
                                .subscribe(actionResult => {
                                  this.navigateBack();
                                });
                            }
                            if (this.currentEnrollmentEvent && this.currentEnrollmentEvent.eventStatusAttribute == 'Deactivated' && this.currentEnrollmentEvent.eventEnrollmentDate == null) {
                              // we have an in-progress current event that has been deactivated; patient cannot continue on this event but should proceed to the next open enrollment event
                              // So need to clear out the tracking number
                              this.trackingNum = '';
                            }
                            this.getEventBasedTileData();
                          })
                      );

                    }));
              }
            }));
          this.getDoubleBlindTileData();
          this.getTransferTileData();
        }
        // Get Patient data from service
        // this.subscriptions.push(
        //   this.studyLandingService.getStudyDetails(this.studyId)
        //     .subscribe(results => {
        //      // console.log("results", results);

        // initialize the model for the header
        const navUrl: string = '/studies/' + this.studyId;

        if (this.patientId == 'null' && this.trackingNum && this.trackingNum.length > 0) {
          this.headerModel = new HeaderBar('Subject Landing: Tracking Number ', this.trackingNum + '', true, '', false, true, navUrl);
        } else {
          this.headerModel = new HeaderBar('Subject Landing: Subject Id ', this.patientId + '', true, '', false, true, navUrl);
        }

      })
    );

    this.subscriptions.push(
      // This will return whether the study is configured to CTSU attribute or if it is not ctsu , then it will check for routine reg office, non reg attributes.
      this.studyService.validateStudyAttributeRegistrationInterface(this.studyId)
        .subscribe(result => {
          this.validateRegistrationInterfaceResponse = result;
        })
    );

    //this is used to determine type of study.   the results are later used to determine if double blind button should be enabled.  
    //so we only need if tile is enabled 
    if (this.userService.hasMinimumAccess(activityConst.ACTIVITY_DOUBLE_BLIND_BOTTLE_REQUEST_RESUPPLY)) {
      if (this.config.version == 'v2') {
        this.subscriptions.push(
          this.studyService.getStudyV2(this.studyId)
            .subscribe(result => {
              this.studyRecord = result;
            })
        );
      }
    }

  }

  ngAfterViewInit() {
    this.headerBar.initHeader();
  }


  /**
 * Destroy implementation - closes all the subscriptions
 */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    );
  }


  /**
   * Retrieve the data from the service
   * for handling the Double Blind tile
   */
  getDoubleBlindTileData(): void {
    // TODO: Call the service and retrieve the info to set these properties
    this.subscriptions.push(this.patientService.getDoubleBlindTileStatus(this.studyId, this.caseDetailId)
      .subscribe(result => {
        if (result) {
          this.doubleBlindTileData = result;
        }
      }));
  }

  /**
   * Retrieve the data from the service
   * for handling the Transfer tile
   */
  getTransferTileData(): void {
    this.subscriptions.push(this.patientService.getTransferTileStatus(this.caseDetailId)
      .subscribe(result => {
        if (result) {
          this.transferTileData = result;
        }
      }));
  }

  /**
   * Returns the navigate label
   */
  navbackTitle(): string {
    let title = 'Search Results ';
    if (this.navBackPageEnum > 0) {
      if (this.navBackPageEnum === globalConst.LANDING_PAGE_ENUM_STUDY) {
        title = 'Study Landing';
      }

      if (this.navBackPageEnum === globalConst.LANDING_PAGE_ENUM_ANCILLARY_STUDY_INFO) {
        //If no navBackAncillary then redirect to navigate to study
        if (this.navBackAncillary) {
          title = 'Ancillary Info';
        } else {
          title = 'Study Landing';
        }
      }
    }

    return title;
  }

  /**
   * sets the schema events that have the enrollment flag = true
   */
  setEnrollmentEvents() {
    if (this.schema && this.schema.studySchemaEvents && this.schema.studySchemaEvents.length > 0) {
      setTimeout(() => {
        const sourceData = this.schema.studySchemaEvents;

        const pathBuilderSource: SchemaPathBuilder = {
          sourceData: sourceData,
          arePathNumbersConfigured: this.schemaService.arePathNumbersConfigured(sourceData),
          eventTree: [],
          headerEvents: [],
          sharedEventIdsOnRow: null,
          groupedEventTree: null,
          placeholderEventIds: new Array<Array<number>>()
        };
        this.schemaService.buildSchemaEventTree(pathBuilderSource);
        const grouped: Array<Array<StudySchemaEvent>> = pathBuilderSource.groupedEventTree;
        this.eventList = (_.uniqBy(([].concat.apply([], grouped)), 'studySchemaEventId'))
          .filter(event => event);  // filter out any null or undefined values
        this.schemaEvents = this.eventList.filter(evt => evt && evt['schemaEventEnrollmentEventFlag'] == true);
        //If there are any already registered events in previous schema version then add them to front of the schemaEvents
        if (this.previousEnrolledEvents.length > 0) {
          this.schemaEvents = this.previousEnrolledEvents.concat(this.schemaEvents);
        }
      });
    }
  }

  /**
   * gets the String to display as the enrollment event - label if one is specified, else the event display name
   */
  getSchemaEventNameLabel(item: any): String {
    return (item.studySchemaEventLabel != null && item.studySchemaEventLabel.length > 0 ? item.studySchemaEventLabel : item.schemaEventDisplayName);
  }

  /**
   * True if the event being evaluated is the same as the current selected event
   * @param schemaEvent The schema event to evaluate
   */
  isSelectedEvent(schemaEvent: StudySchemaEvent): boolean {
    let isCurrentSelected = false;
    if (this.selectedEnrollmentEvent) {
      isCurrentSelected = this.selectedEnrollmentEvent.keyEventMappingId == schemaEvent.keyEventMappingId;
    }
    return isCurrentSelected;
  }

  /**
   * Handles the selection of the Event
   * @param $event The Event being raised
   * @param item The schemaEvent that was selected
   */
  selectEvent($event: Event, item: any): void {
    if ($event) {
      $event.stopPropagation();
    }

    if (item.caseEventId && item.caseEventId > 0) {
      this.selectedEnrollmentEvent = this.enrollmentEventList.find(each => each.caseEventId == item.caseEventId);
      this.getEventBasedTileData();
      if (this.previewModelHome.isCollapsed) {
        this.setPreviewParams(0, false);
      } else {
        this.setPreviewParams(item.caseEventId, true);
      }

    } else {
      this.setPreviewParams(0, this.previewModelHome.isCollapsed);
    }
  }

  /**
   * Evaluates if the schemaEvent is after the current event
   * @param schemaEvent The schemaEvent to evaluate
   * @param idx The index of the event in the list
   */
  isInactiveEvent(schemaEvent: StudySchemaEvent, idx: number): boolean {
    let isInactive = false;
    if (this.currentEnrollmentEvent && this.schemaEvents.length > 0) {
      const indx: number = this.schemaEvents.findIndex(eachEvt => eachEvt.keyEventMappingId == this.currentEnrollmentEvent.keyEventMappingId);
      isInactive = idx > indx;
    }
    return isInactive;
  }



  /**
   * Hide the event if event is deactivated and  no patient enrolled  to it.
   * @param item 
   * @param idx 
   */
  isEventHide(item: any, idx: number): boolean {
    let isHide = false;
    if (!item.eventEnrollmentDate) {
      if (item.studySchemaEventAttributeList) {
        let isEventDeactivated = item.studySchemaEventAttributeList.find(each => each.attributeCode === 'Status').attributeOptions.find(eachOption => eachOption.selected === true && eachOption.optionCode === 'Deactivated');
        if (isEventDeactivated) {
          isHide = true;
        }
      }
    }
    return isHide;
  }


  /*   Tile data - TODOs       ***** */


  /**
   * Retrieves the data based on the selected event
   */
  getEventBasedTileData(): void {
    this.getRegStatusFromService();
    this.getEligibilityStatusFromService();
    this.getApprovalStatus();


    // this.doubleBlindStatus;
    // this.doubleBlindStatusDate

  }

  /**
   * Retrieves the Patient Registration Status from the service
   */
  getRegStatusFromService() {
    this.patientStatus = 'N/A';
    this.patientStatusDate = '';
    if (this.selectedEnrollmentEvent && this.selectedEnrollmentEvent.caseEventId > 0) {
      this.patientService.getPatientRegistrationStatus(this.selectedEnrollmentEvent.caseEventId)
        .subscribe(result => {
          if (result) {
            this.patientStatus = result.status;
            this.patientStatusDate = moment(result.statusDate).format('MM/DD/YYYY');
          }
        }),
        err => { },
        () => {
          // console.log("Complete subscription")
        };
    }
  }


  /**
 * Retrieves the Patient Eligibility Status from the service
 */
  getEligibilityStatusFromService() {
    this.eligibilityStatus = 'N/A';
    this.eligibilityStatusDate = '';
    if (this.selectedEnrollmentEvent && this.selectedEnrollmentEvent.caseEventId > 0) {
      this.patientService.getPatientFormStatus(this.selectedEnrollmentEvent.caseEventId)
        .subscribe(result => {
          if (result) {
            this.eligibilityStatus = result.status;
            if (result.statusDate) {
              this.eligibilityStatusDate = moment(result.statusDate).format('MM/DD/YYYY');
            } else { this.eligibilityStatusDate = ''; }
          }
        }),
        err => { },
        () => {
          // console.log("Complete subscription")
        };
    }
  }

  isPatientStatusRegistered(): boolean {
    if (this.patientStatus && this.patientStatus == "Registered") {
      return true;
    }
    return false;
  }

  /**
   * Returns the patient status
   */
  getPatientStatus(): string {
    return this.patientStatus;
  }

  /**
   * Returns the status Date
   */
  getPatientStatusDate(): string {
    return 'as of ' + this.patientStatusDate;
  }




  /**
   * Returns the name of the next registration event to be completed.
   * If the current enrollment event is complete, it returns the next enrollment event.
   * If the current enrollment event is in-process, it returns the current event.
   * Completed enrollment events have a eventEnrollmentDate date, in-process ones, do not.
   */
  getRegistationEventName(): string {
    if (this.currentEnrollmentEvent && this.nextEnrollmentEvent) {
      if (this.currentEnrollmentEvent.eventEnrollmentDate) {
        return this.nextEnrollmentEvent.eventName;
      } else {
        return this.currentEnrollmentEvent.eventName;
      }
    } else if (this.nextEnrollmentEvent == null) {
      if (this.currentEnrollmentEvent && this.currentEnrollmentEvent.eventEnrollmentDate != null) {
        return this.currentEnrollmentEvent.eventName;
      } else {
        return 'Unavailable';
      }
    } else {
      return 'N/A';
    }
  }



  /**
   * Returns the patient Transfer status
   */
  getPatientTransferStatus(): string {
    if (this.transferTileData && this.transferTileData.transferStatus) {
      return this.transferTileData.transferStatus;
    }
    return '';
  }

  /**
   * Returns the qualifications status Date
   */
  getPatientTransferStatusDate(): string {
    if (this.transferTileData && this.transferTileData.transferStatusDate) {
      return 'as of ' + moment(this.transferTileData.transferStatusDate).format('MM/DD/YYYY');
    }
    return '';
  }

  /**
   * Returns true if the patient is invalid for Transfer
   * Used to disable the Transfer button
   */
  invalidForTransfer(): boolean {
    if (!this.userService.hasMinimumAccess(activityConst.ACTIVITY_PATIENT_REGISTRATION_TRANSFER)) {
      return true;
    }
    return (this.patientId == undefined || this.patientId == null || this.patientId == 'null');
  }


  /**
   * Returns the header data for the doubleBlind tile
   */
  getDoubleBlindOrdersAvailable(): number {
    return this.doubleBlindTileData.ordersAvailableCount;
  }

  /**
   * Returns true if double blind is configured for this study
   */
  isDoubleBlindConfigured(): boolean {
    // disable if non-patient subject type
    if (this.studyRecord && this.studyRecord.studyType &&
      (this.studyRecord.studyType.endsWith('Caregivers') ||
        this.studyRecord.studyType.endsWith('Providers') ||
        this.studyRecord.studyType.endsWith('Practice') ||
        this.studyRecord.studyType.endsWith('Other') ||
        this.studyRecord.studyType.endsWith('Other Professional Disciplines'))) {
      return false;
    }
    // Evaluate if the current Event and eventContext are the same
    const isConfigured: boolean = (this.currentEnrollmentEvent == this.selectedEnrollmentEvent);
    return isConfigured && this.doubleBlindTileData.doubleBlindConfigured;
  }


  /**
     * Returns the patient Eligibility status
     */
  getPatientEligibilityStatus(): string {
    return this.eligibilityStatus;
  }

  /**
   * Returns the Patient Document Count   status Date
   */
  getPatientEligibilityStatusDate(): string {
    if (this.eligibilityStatusDate === '') {
      return this.eligibilityStatusDate;
    }
    return 'as of ' + this.eligibilityStatusDate;
  }


  /**
   * True if an approval event exists in the schema and the user has read access
   *
   */
  isApprovalTileDisabled(): boolean {
    if (this.selectedEnrollmentEvent?.nextApprovalStudySchemaEventId) {
      return !this.userService.hasReadAccess(activityConst.ACTIVITY_PATIENT_REGISTRATION_APPROVE);
    }
    return true;
  }

  /**
   * True user doesn't have the specified role
   *
   */
  isEnrollmentTileDisabled(): boolean {
    return !this.userService.hasReadAccess(activityConst.ACTIVITY_PATIENT_ENROLLMENT_CORRECTIONS);
  }




  /**
   * Returns the next approval event for the currentSelected Event
   * if it exists in the schema
   */
  private getNextApprovalEvent(): any {
      if(this.selectedEnrollmentEvent?.nextApprovalStudySchemaEventId){
        return this.selectedEnrollmentEvent.nextApprovalStudySchemaEventId
      }
    return null;
  }


  /**
   * True if the patient has been approved for registering to next event
   */
  isPatientApprovedToContinue(): boolean {
    // TODO: Determine if the user needs approval to proceed
    return true;
  }

  /**
   * Gets the approval status for the patient
   */
  getApprovalStatus() {
          //  Returns the status approval  for the patient

      const nextApprovalEventId: any = this.getNextApprovalEvent();
      console.log(nextApprovalEventId);
      if (nextApprovalEventId) {
        const enrEvent: PatientEnrollmentEvent = this.selectedEnrollmentEvent;
        let caseEventId = 0;
        if (enrEvent) {
          caseEventId = enrEvent.caseEventId;
        }


        this.subscriptions.push(
          this.patientService.getPatientApprovalStatus(caseEventId)
            .subscribe(result => {
              if (result) {
                this.approvalStatus = result.status;
              }
            })
        );
        this.approvalStatus = 'Pending';
      } else {
        this.approvalStatus = 'N/A';
      }
      return this.approvalStatus;
  }
  /**
   * True if the Approval Event is configured an
   */
  isApprovalButtonDisabled(): boolean {
    // TODO: Determine if the study has approval event configured and user
    // needs approval to continue
    const isButtonDisabled: boolean = this.isApprovalTileDisabled() || this.approvalStatus == 'N/A';

    // if (!isButtonDisabled) {
    //   // Button is disabled when:
    //   // - The this.selectedEnrollmentEvent is in Continue mode
    //   // - The Approval event is immediately after this.selectedEnrollmentEvent

    //   if (this.selectedEnrollmentEvent &&
    //     (!(this.selectedEnrollmentEvent.currentEvent && this.selectedEnrollmentEvent.nextEvent))) {
    //     isButtonDisabled = !this.isNextEventApprovalEvent;
    //   } else {
    //     isButtonDisabled = true;
    //   }
    // }
    return isButtonDisabled;
  }




  /*   Tile data        ***** */


  /**
   * Handler to navigate back to the home page
   */
  navigateBack(): void {
    if (this.navBackPageEnum == globalConst.LANDING_PAGE_ENUM_STUDY) {
      this.navigateToStudy();
      return;
    }
    if (this.navBackPageEnum == globalConst.LANDING_PAGE_ENUM_ANCILLARY_STUDY_INFO) {
      //If no navBackAncillary then redirect to navigate to study
      if (this.navBackAncillary) {
        this.navigateToAncillaryStudyInfo();
      } else {
        this.navigateToStudy();
      }
      return;
    }
    // default it to navigate to home page
    this.router.navigate(['/home']);
  }

  /**
   * True if the user can navigate to the next event for registration
   */
  canNavigateToRegisterNextEvent(): boolean {
    const canNav: boolean = (this.nextEnrollmentEvent && this.nextEnrollmentEvent.studySchemaEventId > 0 && !this.isCurrentEventDeactivated);
    //Check if any deleted events don't want to map to next events.
    if (this.schema.deleteMap?.length > 0) {
      const deleteMap = this.schema.deleteMap.find(x => x.deletedKeyEventMappingId === this.currentEnrollmentEvent.keyEventMappingId);
      if (deleteMap && deleteMap.nextKeyEventMappingId === 0) {
        return false;
      }
    }
    return canNav;
  }

  /**
   * Navigate to the Patient Registration page
   */
  navigateForNextEvent(): void {
    if (this.trackingNum && !isNaN(+this.trackingNum)) {
      if (this.isCurrentEventDeactivated) {
        // show can't nav modal
        this.showInvalidActionModal();
      } else {
        // This patient has not yet completed the initial registration
        const url: string = this.getPatientRegURL(false);
        this.router.navigate([url], {
          queryParams: {
            'action': this.getNextActionQueryParam(), // globalConst.QS_PARAM_ACTION_NEXT_EVENT,
            'trackingNum': this.trackingNum
          }
        });
      }
    } else if (this.canNavigateToRegisterNextEvent()) {
      // this.router.navigate([this.getPatientRegURL()]);
      this.registerPatient();
    } else {
      // show can't nav modal
      this.showInvalidActionModal();
    }
  }

  /**
   * Returns the string to determine if this is a continuation of the
   * current event or init of the next Event
   */
  private getNextActionQueryParam(): string {
    // TODO: Assuming that the user will always have the same navigation
    // regardless of which event they are viewing
    let nextParam: string = globalConst.QS_PARAM_ACTION_NEXT_EVENT;
    // locate the event that has currentEvent = true;
    // if the currentEvent=True and nextEvent= true, they will continue
    // otherwise they will go to next patient
    const cEvts: PatientEnrollmentEvent[] = this.enrollmentEventList
      .filter(ev => ev.currentEvent == true && ev.nextEvent === true);
    // if more than one event is returned here - this event is incomplete
    if (cEvts.length > 0) {
      if (cEvts[0].firstEvent) {
        // if this is the firstEvent, continue with Register
        nextParam = globalConst.QS_PARAM_ACTION_CONTINUE_REGISTER;
      } else {
        // otherwise continue with next
        nextParam = globalConst.QS_PARAM_ACTION_CONTINUE_NEXT;
      }
    }
    return nextParam;
  }


  /**
   * Navigates to the Patient Reg and allows users to make changes
   * to the Participations section
   *
    */
  editParticipation(): void {
    if (!this.userService.hasMinimumAccess(activityConst.ACTIVITY_PATIENT_PARTICIPATION_INFO_UPDATE)) {
      return;
    } else {
      const url: string = this.getPatientRegURL(true);
      const qParams = this.getEditQueryParams(globalConst.REG_SECTION_PARTICIPATION);
      this.router.navigate([url], {
        queryParams: qParams
      });
    }
  }

  /**
   * Navigates to the Patient Reg and allows users to make changes
   * to the demography section
   *
    */
  editDemographics(): void {
    if (!this.userService.hasMinimumAccess(activityConst.ACTIVITY_PATIENT_INFO_UPDATE)) {
      return;
    } else {
      const url: string = this.getPatientRegURL(true);
      const qParams = this.getEditQueryParams(globalConst.REG_SECTION_PATIENT_INFO);
      this.router.navigate([url], {
        queryParams: qParams
      });
    }
  }


  /**
 * Navigates to the Patient Reg and allows users to make changes
 * to the forms (eligibility checklist) section
 *
  */
  editEligibility(): void {
    if (!this.userService.hasMinimumAccess(activityConst.ACTIVITY_PATIENT_ELIGIBILITY_UPDATE)) {
      return;
    } else {
      const url: string = this.getPatientRegURL(true);
      const qParams = this.getEditQueryParams(globalConst.REG_SECTION_FORM);
      this.router.navigate([url], {
        queryParams: qParams
      });
    }
  }

  /**
 * Navigates to the Patient Reg and allows users to make changes
 * to the additional options section
 *
  */
  editAdditionalOptions(): void {
    if (!this.userService.hasMinimumAccess(activityConst.ACTIVITY_PATIENT_ADDITIONAL_OPTTIONS)) {
      return;
    } else {
      const url: string = this.getPatientRegURL(true);
      const qParams = this.getEditQueryParams(globalConst.REG_SECTION_ADDITIONAL_OPTIONS);
      this.router.navigate([url], {
        queryParams: qParams
      });
    }
  }

  /**
   * Get the Edit parameters for the selected target to be edited
   * @param editTarget The target to be edited
   */
  private getEditQueryParams(editTarget: string): any {
    const queryParams = {
      'action': globalConst.QS_PARAM_ACTION_EDIT,
      'target': editTarget,
      'navBack': this.navBackPageEnum,
      'selectedCaseEventId': this.selectedCaseEventId
    };
    return queryParams;
  }

  /**
   * Returns the URL with the parameters for navigation to the patient Reg page
   */
  private getPatientRegURL(editMode: boolean): string {
    // let caseEventId = this.selectedEnrollmentEvent.caseEventId;
    // edit/corrections tiles are context sensitive to the selected enrollment event, but the
    // "continue registration" tile should always go to the "next" event
    let caseEventId = 0;
    if (editMode) {
      caseEventId = this.selectedEnrollmentEvent.caseEventId;
    } else if (this.nextEnrollmentEvent) {
      caseEventId = this.nextEnrollmentEvent.caseEventId;
    }
    console.log('caseEventId=' + caseEventId);
    return '/studies/' + this.studyId + '/registration/' + this.patientId + '/' + this.caseDetailId + '/' + caseEventId;
  }


  /**
   * Navigate to the STudy landing page
   */
  navigateToStudy(): void {
    this.router.navigate(['/studies', this.studyId], { relativeTo: this.route });
  }

  /**
   * Navigate to ancillary study info
   */
  navigateToAncillaryStudyInfo() {
    console.log("Inside nav ancillary");
    this.router.navigate(['/admin/studies/' + this.navBackAncillary + "/setup/ancillary"], {
      queryParams: {
        'action': 'ancillaryStudies'
      }
    });
  }

  /**
   *
   * Navigates to the Tranfer Patient page
   */
  transferPatient(): void {
    // let navUrl: string = "/patient/transfer/" + this.studyId + "/" + this.patientId + "/" + this.caseDetailId ;
    // this.router.navigate([navUrl]);
    const path: string = '/patient/transfer/' + this.studyId + '/' + this.patientId + '/' + this.caseDetailId;
    this.router.navigate([path], {
      queryParams: {
        'navBack': this.navBackPageEnum,
        'trackingNum': this.trackingNum,
        'selectedCaseEventId': this.selectedCaseEventId
      }
    });
  }

  /**
   *
   * Navigates to the Registration page
   */
  registerPatient(): void {
    // let navUrl: string = "/patient/transfer/" + this.studyId + "/" + this.patientId + "/" + this.caseDetailId ;
    // this.router.navigate([navUrl]);
    let theCaseEventId = 0;
    if (this.nextEnrollmentEvent && this.nextEnrollmentEvent.caseEventId) {
      theCaseEventId = this.nextEnrollmentEvent.caseEventId;
    }
    const path: string = '/studies/' + this.studyId + '/registration/' + this.patientId + '/' + this.caseDetailId + '/' + theCaseEventId;
    this.router.navigate([path], {
      queryParams: {
        'action': this.getNextActionQueryParam(),
        'navBack': this.navBackPageEnum,
        'trackingNum': this.trackingNum,
        'selectedCaseEventId': this.selectedCaseEventId
      }
    });
  }


  /**
   * HAndles the click on the Review Reg
   */
  reviewRegistration(): void {
    this.setPreviewParams(this.selectedEnrollmentEvent.caseEventId, true);
    this.previewModelHome.isCollapsed = false;
    this.caseEventId = this.selectedEnrollmentEvent.caseEventId;
  }


  /**
   * Sets the Preview Params
   */
  setPreviewParams(caseEventId: number, showDetails: boolean): void {
    this.previewParams.caseEventId = caseEventId;
    this.previewParams.showDetails = showDetails;
    this.patientService.setRegPreviewParams(this.previewParams);
  }


  /**
   * Navigate to the Approval Landing Page
   * @param $event The event raised by the button
   */
  navigateToApproval($event: Event): void {
    if ($event) {
      $event.stopPropagation();
    }
    const nextApprovalEventId: any = this.getNextApprovalEvent();
    if (nextApprovalEventId) {

      const enrEvent: PatientEnrollmentEvent = this.selectedEnrollmentEvent;
      let caseEventId = 0;
      if (enrEvent) {
        caseEventId = enrEvent.caseEventId;
      }

      // patient/approval/:studyId/:patientId/:caseDetailId/:studySchemaEventId
      const path: string = 'approval/' + this.studyId + '/' + this.patientId
        + '/' + this.caseDetailId + '/' + caseEventId + '/' + nextApprovalEventId;
      this.router.navigate([path], {
        queryParams: {
          'navBack': this.navBackPageEnum,
          'trackingNum': this.trackingNum,
          'selectedCaseEventId': this.selectedCaseEventId
        }
      });
    }
  }

  /**
     * Submits the request to get a kit assigned to a patient
     * @param $event The event being raised
     */
  makeDoubleBlindRequest($event: Event): void {
    if ($event) {
      $event.stopPropagation();
    }

    // Don't allow double blind request if double blind not configured or no orders available
    if (!this.isDoubleBlindConfigured() || this.getDoubleBlindOrdersAvailable() === 0)
      return;

    // TODO: call service to submit
    this.subscriptions.push(
      this.doubleBlindService.requestKitForPatient(this.studyId,
        this.patientId)
        .subscribe(results => {
          let kitAssignmentNumber = ' ';
          if (results && results.length > 0) {
            kitAssignmentNumber = results.map(item => item.kitNumber).join(',');
          }
          this.getDoubleBlindTileData();
          const modalArg: ModalDialogArg = {
            contentType: 'Custom',
            dialogType: 'modal-warn',
            displayMessage: 'Assigned Kit Number(s): ' + kitAssignmentNumber,
            displayName: 'Success',
            variableData: ''
          };
          this.subscriptions.push(this.patientService.showModalDialog(this.viewContainer, modalArg)
            .subscribe(actionResult => {
            }));
        }, (responseError) => {
        },
          () => {
          }));

  }

  /**
   * True if an event is completed and the user can correct the completed enrollment
   */
  isCorrectEnrollmentDisabled(): boolean {
    let isButtonDisabled: boolean = true;
    // //if the selected event is not the currentEvent and the next event
    // if (this.selectedEnrollmentEvent) {
    //   isButtonDisabled = ((this.selectedEnrollmentEvent.currentEvent &&
    //     this.selectedEnrollmentEvent.nextEvent));
    // }
    //we want to be able to delete even in process registrations
    isButtonDisabled = false;
    return isButtonDisabled;
  }

  /**
   * True if the event is completed and the user can manage the post processing
   */
  isManagePostProcessingDisabled(): boolean {
    let isButtonDisabled = true;
    // if the selected event is not the currentEvent and next event
    if (this.selectedEnrollmentEvent) {
      isButtonDisabled = ((this.selectedEnrollmentEvent.currentEvent &&
        this.selectedEnrollmentEvent.nextEvent));
    }
    return isButtonDisabled;
  }

  /**
   * True if the user has no access or patient only has tracking id
   *
   */
  isPostProcessingTileDisabled(): boolean {
    return !this.userService.hasModifyAccess(activityConst.ACTIVITY_PATIENT_REG_POST_PROCESSING);
  }

  /**
   * True if the event is pre-reg (no drug assigned) or event is in process
   *TODO Not sure if this is a valid check
   */
  // isUnblindedTileDisabled(): boolean {
  //   let isPreReg = this.selectedEnrollmentEvent && this.selectedEnrollmentEvent.eventCode == 'PreReg';
  //   let isRegComplete: boolean;
  //   //if the selected event is not the currentEvent and the next event
  //   if (this.selectedEnrollmentEvent) {
  //     isRegComplete = ((this.selectedEnrollmentEvent.currentEvent &&
  //       this.selectedEnrollmentEvent.nextEvent));
  //   }

  //   return isPreReg || !isRegComplete;
  // }

  /**
   * True if the user has activity
   *
   */
  shouldSeeUnblindedTile(): boolean {
    return this.userService.hasReadAccess(activityConst.ACTIVITY_PATIENT_SUBJECT_UNBLINDING);
  }


  /**
     * Returns true if the patient is invalid for the activity name passed in
     * Used to disable the tile and check navigation
     */
  invalidForActivity(activityName: string): boolean {
    if (!this.userService.hasMinimumAccess(activityName)) {
      return true;
    }
  }


  /**
   * Invoke the unblinded info endpoint and show modal if successful
   * @param $event The event from the button click
   */
  getUnblindedInfo($event: Event): void {
    let thisCaseEvent: number = 0;
    if (this.selectedEnrollmentEvent && this.selectedEnrollmentEvent.caseEventId) {
      thisCaseEvent = this.selectedEnrollmentEvent.caseEventId;
    }
    if (this.selectedCaseEventId && thisCaseEvent == 0) {
      thisCaseEvent = this.selectedCaseEventId;
    }
    this.subscriptions.push(this.patientService.getUnblindedInfo(thisCaseEvent)
      .subscribe(result => {
        if (result) {
          this.unblindedInfo = result;
          this.modalComponent.showModal = true;
        }
      }));
  }


  /**
   * Navigates to the Enrollment Correction page
   * @param $event the event from the button click
   */
  navigateToEnrollmentCorrections($event): void {
    if ($event) {
      $event.stopPropagation();
    }
    const enrEvent: PatientEnrollmentEvent = this.selectedEnrollmentEvent;
    let caseEventId = 0;
    if (enrEvent) {
      caseEventId = enrEvent.caseEventId;
    }
    const path: string = 'enrollment-corrections/' + this.studyId + '/'
      + this.patientId + '/' + this.caseDetailId + '/' + caseEventId;
    this.router.navigate([path], {
      queryParams: {
        'navBack': this.navBackPageEnum,
        'trackingNum': this.trackingNum,
        'selectedCaseEventId': this.selectedCaseEventId
      }
    });
  }


  /**
   * Navigate to the manage Post Processing landing page
   * @param $event The event from the button click
   */
  managePostProcessing($event: Event): void {
    if ($event) {
      $event.stopPropagation();
    }
    const enrEvent: PatientEnrollmentEvent = this.selectedEnrollmentEvent;
    let caseEventId = 0;
    if (enrEvent) {
      caseEventId = enrEvent.caseEventId;
    }
    const path: string = 'post-processing/' + this.studyId + '/' +
      this.patientId + '/' + this.caseDetailId + '/' + caseEventId;
    this.router.navigate([path], {
      queryParams: {
        'navBack': this.navBackPageEnum,
        'trackingNum': this.trackingNum,
        'selectedCaseEventId': this.selectedCaseEventId
      }
    });
  }

  /**
     * True if the orientation is vertical and the state is collapsed
     */
  isCollapsedVertical(): boolean {
    return this.previewModelHome.isCollapsed && this.previewModelHome.isVertical;
  }



  /**
   * True if the orientation is horizontal and is collapsed
   */
  isCollapsedHorizontal(): boolean {
    return (this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }


  /**
   * True if the orientation is vertical and the state is Expanded
   */
  isExpandedVertical(): boolean {
    return (!this.previewModelHome.isCollapsed && this.previewModelHome.isVertical);
  }



  /**
   * True if the orientation is horizontal and is expanded
   */
  isExpandedHorizontal(): boolean {
    return (!this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }

  /** custom handling of the preview for the  page********************* */




  /********* Invalid Action warning************** */
  showInvalidActionModal(): Observable<boolean> | boolean {
    const modalArg = new ModalDialogArg('modal-warn', 'CannotNavigate', null);
    const result = this.patientService.showModalDialog(this.viewContainer, modalArg);
    return result.asObservable().pipe(tap(x => {
      return of(x);
    }));
  }

  // Reference to save and changel changes.As we are not using it but still compiler forces us to write this

  saveChanges() {

  }

  cancelChanges() {

  }


}
