/********************************************************** */


/**
 * This section lists the possible access levels
 *
 */
export const ACCESS_LEVEL_NONE: string = "none";
export const ACCESS_LEVEL_VIEW: string = "read";
export const ACCESS_LEVEL_UPDATE: string = "update";
export const ACCESS_LEVEL_CREATE: string = "create";
export const ACCESS_LEVEL_DELETE: string = 'delete';

/********************************************************** */

/**
* This section lists all the names of the activities used to identify
* where security is evaluated
*
*/
export const ACTIVITY_HOME = 'home';
export const ACTIVITY_HOME_TILE_STUDY = 'home-tile-study';
export const ACTIVITY_HOME_TILE_STUDY_ADMIN = 'home-tile-study-admin';
export const ACTIVITY_HOME_TILE_PATIENT = 'home-tile-patient';
export const ACTIVITY_PATIENT_REGISTRATION = 'patient-registration';
export const ACTIVITY_PATIENT_REGISTRATION_APPROVE = 'patient-registration-approve';
export const ACTIVITY_PATIENT_REG_REG_DATE = 'patient-reg-reg-date';
export const ACTIVITY_PATIENT_ADDITIONAL_OPTTIONS = 'patient-reg-additional-options';

export const ACTIVITY_PATIENT_LANDING_PAGE = 'patient-landing-page';
export const ACTIVITY_PATIENT_LANDING = 'patient-view';
export const ACTIVITY_PATIENT_REGISTRATION_TRANSFER = 'patient-transfer'; // TODO:
export const ACTIVITY_PATIENT_PARTICIPATION_INFO_UPDATE = 'patient-participation-corrections';
export const ACTIVITY_PATIENT_INFO_UPDATE = 'patient-info-corrections'; // TODO:
export const ACTIVITY_PATIENT_ELIGIBILITY_UPDATE = 'patient-eligibility-corrections';
export const ACTIVITY_PATIENT_ENROLLMENT_CORRECTIONS = 'patient-enrollment-corrections'; // TODO:
export const ACTIVITY_PATIENT_REG_POST_PROCESSING = 'patient-reg-post-processing'; // TODO:
export const ACTIVITY_PATIENT_REG_VALIDATION_OVERRIDE = 'patient-registration-validation-override';
export const ACTIVITY_PATIENT_RESERVATION_VALIDATION_OVERRIDE = 'patient-reservation-validation-override';
export const ACTIVITY_PATIENT_SUBJECT_UNBLINDING = 'subject-unblinding';



export const ACTIVITY_STUDY_LANDING = 'study-view';
export const ACTIVITY_STUDY_CREATE = 'study-create';
export const ACTIVITY_STUDY_SIGN_OFF = 'study-sign-off';
export const ACTIVITY_STUDY_SIGN_OFF_CONFIG = 'study-sign-off-config';
export const ACTIVITY_STUDY_CASE_EVENT = 'case-event';
export const ACTIVITY_STUDY_PATIENT_ACCRUAL = 'study-patient-accrual'; // authorized to accrual tile and preview pane
export const ACTIVITY_STUDY_PATIENT_ACTIVE = 'study-patient-active'; // authorized to active tile and preview pane
export const ACTIVITY_STUDY_PATIENT_ACCRUAL_ARM = 'study-patient-accrual-arm'; // authorized to view the Arm column on the Study Landing Accruals tile preview pane

export const ACTIVITY_VARIABLE_LIBRARY = 'variable-library';
export const ACTIVITY_ADMIN_DASHBOARD = 'admin-dashboard';
export const ACTIVITY_STUDY_SETUP = 'study-setup';
export const ACTIVITY_STUDY_ANCILLARIES = 'study-ancillaries';
export const ACTIVITY_NOTIFICATION_SUBSCRIBE = 'study-notification-subscribe';
export const ACTIVITY_STUDY_SIMULATION = 'study-simulation-tile';

export const ACTIVITY_NOTIFICATION_RECENT = 'notification-recent';
export const ACTIVITY_NOTIFICATION_SETUP = 'notification-setup';
export const ACTIVITY_FORM_BUILDER = 'study-form-builder';
export const ACTIVITY_PROTOCOL_INFO = 'study-protocol-info';
export const ACTIVITY_PERSON_RESPONSIBILITY = 'study-person-responsibility';
export const ACTIVITY_ORGANIZATION_RESPONSIBILITY = 'study-organization-responsibility';
export const ACTIVITY_ATTRIBUTES = 'study-attributes';
export const ACTIVITY_STUDY_REFERENCE = 'study-study-reference';
export const ACTIVITY_SITE_PERSON = 'study-site-and-person';
export const ACTIVITY_IRB_APPROVALS = 'study-irb-approvals';
export const ACTIVITY_DEMOGRAPHY_SETTINGS = 'study-demography-settings';
export const ACTIVITY_DOSAGE = 'study-dosage';
export const ACTIVITY_STUDY_PARTICIPATION = 'study-study-participation';
export const ACTIVITY_STUDY_PSR = 'study-psr';
export const ACTIVITY_SCHEMA_DASHBOARD = 'study-schema-dashboard';
export const ACTIVITY_FORM_MANAGEMENT = 'study-form-management';
export const ACTIVITY_SCHEMA_MANAGEMENT = 'study-schema-management';
export const ACTIVITY_NOTIFICATION = 'study-notification';
export const ACTIVITY_STUDY_TEMPLATE = 'study-template';

export const ACTIVITY_DOUBLE_BLIND_SETUP = 'double-blind-setup';
export const ACTIVITY_DOUBLE_BLIND_DRUG_DEFAULTS = 'double-blind-drug-defaults';
export const ACTIVITY_DOUBLE_BLIND_KIT_DEFINITIONS = 'double-blind-bottle-definitions';
export const ACTIVITY_DOUBLE_BLIND_KIT_ASSIGNMENTS = 'double-blind-bottle-assignments';
export const ACTIVITY_DOUBLE_BLIND_BOTTLE_AVAILABLE_FLAG = 'double-blind-bottle-available-flag';
export const ACTIVITY_DOUBLE_BLIND_MANAGE_KITS = 'double-blind-manage-bottles';
export const ACTIVITY_DOUBLE_BLIND_REORDERS = 'double-blind-reorders';
export const ACTIVITY_DOUBLE_BLIND_RECORDS = 'double-blind-records';
export const ACTIVITY_DOUBLE_BLIND_MANAGE_TILE = 'double-blind-manage-tile';
export const ACTIVITY_DOUBLE_BLIND_BOTTLE_REQUEST_RESUPPLY = 'double-blind-bottles-request-resupply';

export const ACTIVITY_TAC_TAD_SETUP = 'tac-tad-setup';
export const ACTIVITY_TAC_CODE_LISTING = 'tac-code-listing';
export const ACTIVITY_DERIVATION_VERSION_LISTING = 'derivation-version-listing';
export const ACTIVITY_SGC_VERSION_LISTING = 'sgc-version-listing';
export const ACTIVITY_SGC_CODE_LISTING = 'sgc-code-listing';



export const ACTIVITY_REGULATORY_SETUP = 'study-regulatory-setup';
export const ACTIVITY_REGULATORY_ACCRU = 'study-regulatory-accru';
export const ACTIVITY_REGULATORY_CERTIFICATION_SETUP = 'study-regulatory-certification-setup';

export const ACTIVITY_FUNDING_SOURCE = 'study-funding-source';
export const ACTIVITY_SELECTION_CRITERIA = 'study-selection-criteria';
export const ACTIVITY_ANCILLARY_STUDY_TEAM = 'ancillary-study-team';
export const ACTIVITY_REPORTS = 'report';

//Slot Reservation
export const ACTIVITY_SLOT_RESERVATION = 'patient-reservation';


