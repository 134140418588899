export class Demographic {
    constructor(
        public studyId: number | null,
        public studyTemplateId: number | null,
        public studyDemographicId: number | null,
        public demographicId: number | null,
        public required: boolean | null,
        public tupleVersion: number | null,
        public demographicDisplayName?: string | null
    ) {}
}
