/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';
import { Option, Rule, RuleSet } from 'ngx-angular-query-builder';
import { ErrorMessage } from '../model/error-message';
import { FieldMapWrapper, FieldWrapper, QueryBuilderConfigWrapper } from '../model/query-builder-config-wrapper';
import { Helper } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class QueryBuilderService {
  private static readonly _MINIMUM_QUERY_RULES: any = { condition: 'and', rules: [] };
  public static get MINIMUM_QUERY_RULES() {
    return _.cloneDeep(this._MINIMUM_QUERY_RULES);
  }

  private static readonly _DEFAULT_SELECT_OPTION: Option = { name: '-- Select --', value: {} };
  public static get DEFAULT_SELECT_OPTION() {
    return _.cloneDeep(this._DEFAULT_SELECT_OPTION);
  }

  public static get DEFAULT_FIELD_DEFINITION() :  FieldMapWrapper{
    return  {
      '0': {
        type: 'null',
        operators: [QueryBuilderService.DEFAULT_SELECT_OPTION.name],
        name: QueryBuilderService.DEFAULT_SELECT_OPTION.name
      } 
    } as FieldMapWrapper;
  }

  OPENING_GENERAL_BRACKET = '(';
  CLOSING_GENERAL_BRACKET = ')';
  OPENING_SUB_BRACKET = '[';
  CLOSING_SUB_BRACKET = ']';

  expression: string = '';
  helper: Helper;

  constructor() {
    this.helper = new Helper();
  }

  ///// Public functions
  /**
   * Converts the given rules to a logical/algebraic representation including parenthesis
   * @param queryRules Current set of rules to be translated
   * @param queryBuilderConfig Optional current configurations for the Query Builder
   * @param includeFormatting Optional flag to include formatting for the resulting translation
   * @returns String, logical representation of the given rules
   */
  public convertRulesToLogicalRepresentation(
    queryRules: RuleSet,
    queryBuilderConfig?: QueryBuilderConfigWrapper,
    includeFormatting: boolean = false): string {
    let result: string = '';

    if (queryRules?.rules?.length > 0) {
      result = this.translateQueryRule(queryRules, queryBuilderConfig, includeFormatting, true);
    }

    return result;
  }

  /**
   * Evaluates the given RuleSet for basic completeness
   * @param queryRules Current set of rules to be evaluated
   * @param queryBuilderConfig Optional current configurations for the Query Builder
   */
  public isRuleSetValid(queryRules: RuleSet, queryBuilderConfig?: QueryBuilderConfigWrapper): boolean {
    let result = true;

    if (this.helper.variableHasValue(queryRules)) {
      result = result && this.validateRule(queryRules, null, [], queryBuilderConfig);
    }
    else {
      result = false;
    }

    return result;
  }

  /**
   * FormControl validation function to validate the value of the given control
   */
  public getValidateQueryBuilderControlFn() {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors: { [key: string]: any; } = {};
      const errorStore = [];
      if (this.helper.variableHasValue(control.value)) {
        this.validateRule(control.value, null, errorStore, null);
      }

      if (errorStore.length > 0) {
        errors.wrapperRules = errorStore;
      }

      return this.helper.variableHasValue(errors) ? errors : null;
    };
  }

  ///// Private functions
  /**
   * Recursive function to evaluate and translate the given ruleObj into a logical/algebraic representation
   * @param ruleObj Current rule object to be evaluated
   * @param queryBuilderConfig Optional current configurations for the Query Builder
   * @param includeFormatting Flag to include formatting for the resulting translation
   * @param isFirst Indicates if this is the first ruleObj being evaluated; Used for formatting
   * @returns Returns the logical/algebraic representation of the given ruleObj
   */
  private translateQueryRule(
    ruleObj: RuleSet | Rule,
    queryBuilderConfig?: QueryBuilderConfigWrapper,
    includeFormatting: boolean = false,
    isFirst: boolean = false): string {

    if (this.isRuleObjRule(ruleObj)) {
      const rule = ruleObj as Rule;

      if (typeof queryBuilderConfig === 'undefined' || queryBuilderConfig === null) {
        return this.mergeString(rule.field, rule.operator, rule.value, includeFormatting);
      }
      else {
        const configField = queryBuilderConfig.fields[rule.field];
        const value = this.getRuleValue(rule, configField);

        return this.mergeString(configField?.name ?? rule.field, rule.operator, value, includeFormatting);
      }
    }
    else {
      const rule = ruleObj as RuleSet;
      let result = '';

      if (rule.rules.length === 1) { // Excluded unnecessary extra set of parenthesis
        result = this.translateQueryRule(rule.rules[0], queryBuilderConfig, includeFormatting);
      }
      else {
        result = includeFormatting ? this.formatRule(this.OPENING_GENERAL_BRACKET) : this.OPENING_GENERAL_BRACKET;

        for (let index = 0; index < rule.rules.length; index++) {
          const r = rule.rules[index];
          result += this.translateQueryRule(r, queryBuilderConfig, includeFormatting);

          if (index < rule.rules.length - 1) {

            if (includeFormatting) {
              result += this.formatRule(rule.condition?.toUpperCase());
            }
            else {
              result += ' ' + rule.condition?.toUpperCase() + ' ';
            }
          }
        }
        result += includeFormatting ? this.formatRule(this.CLOSING_GENERAL_BRACKET) : this.CLOSING_GENERAL_BRACKET;
      }
      // Final formatting if needed
      if (includeFormatting) {
        // Need to wrap the entire query translation in a ul
        if (isFirst) {
          result = this.formatFirstRuleSet(result);
        }
        // Only add a sub-list if there are more than 1 rule (simplifies results)
        else if (rule.rules.length !== 1) {
          result = this.formatRuleSet(result);
        }
      }
      return result;
    }
  }

  /**
   * Combines the pieces of the 'Rule' object being translated
   * @param field Field property from the 'Rule' object being translated
   * @param operator Operator property from the 'Rule' object being translated
   * @param value Value property from the 'Rule' object being translated
   * @param includeFormatting Flag for if formatting should be included in the resulting translation
   * @returns Final translation of the given parameters from the 'Rule' object being translated
   */
  private mergeString(field: string, operator: string, value: any, includeFormatting: boolean): string {
    if (typeof operator === 'undefined') {
      operator = '';
    }

    if (typeof value === 'undefined') {
      value = '';
    }
    else if (value instanceof Array) {
      // Join the array into a string formatting each element if needed
      let newValue = this.OPENING_SUB_BRACKET;
      for (let index = 0; index < value.length; index++) {
        let element = value[index];
        if (includeFormatting) {
          element = this.formatValue(element);
        }
        newValue += element;
        if (index < value.length - 1) {
          newValue += ', ';
        }
      }
      newValue += this.CLOSING_SUB_BRACKET;

      value = newValue;
    }
    else if (includeFormatting) {
      value = this.formatValue(value);
    }

    // Add formatting if needed
    if (includeFormatting) {
      return this.formatRule(
        this.OPENING_GENERAL_BRACKET
        + this.formatField(field) + ' '
        + operator + ' '
        + value
        + this.CLOSING_GENERAL_BRACKET);
    }
    else {
      return this.OPENING_GENERAL_BRACKET + field + ' ' + operator + ' ' + value + this.CLOSING_GENERAL_BRACKET;
    }
  }

  /**
   * Gets the appropriate rule display value given a QueryBuilder Configuration Field
   */
  private getRuleValue(rule: Rule, configField: FieldWrapper): any {
    if (configField?.options?.length > 0) {
      let matches = [];

      // Determine how to compare the option values and rule values
      let compareFunction = configField.compareFunction;
      if (!compareFunction) {
        // eslint-disable-next-line eqeqeq
        compareFunction = (optValue: any, ruleValue: any): boolean => optValue == ruleValue;
      }

      matches = configField.options.filter((opt: Option): boolean => {
        if (rule.value instanceof Array) {
          let matchFound = false;
          rule.value.forEach(rv => {
            matchFound = compareFunction(opt.value, rv) ? true : matchFound;
          });
          return matchFound;
        }
        else {
          return compareFunction(opt.value, rule.value);
        }
      });

      if (rule.value instanceof Array) {
        return matches.map(m => m.name);
      }
      else if (matches.length === 1) {
        return matches[0].name;
      }
    }

    if (typeof rule.value === 'undefined') {
      return '';
    }
    return rule.value;
  }

  /**
   * Performs validation on the given @param ruleObj and adds any errors to @param errorStore
   * @param ruleObj Rule or RuleSet that is to be validated
   * @param ruleParent Parent of the given @param ruleObj
   * @param errorStore List of current error messages that is added to when errors are found
   * @param queryBuilderConfiguration Current configuration settings for the Query Builder; used for getting custom validators
   * @returns True for a valid rule; False for an invalid rule
   */
  private validateRule(
    ruleObj: RuleSet | Rule,
    ruleParent: RuleSet,
    errorStore: any[],
    queryBuilderConfiguration: QueryBuilderConfigWrapper
  ): boolean {
    let result: boolean = true;
    if (!this.helper.variableHasValue(ruleObj)) {
      result = false;
    }
    else if (this.isRuleObjRule(ruleObj)) {
      const rule: Rule = ruleObj as Rule;
      if (!this.helper.variableHasValue(rule.field)) {
        errorStore.push(
          new ErrorMessage(
            rule,
            'All rules must have a field selected.'
          )
        );
        result = false;
      }
      // Operators are not always required
      // if (!this.helper.variableHasValue(rule.operator)) {
      //   errorStore.push({ 'operator': 'All rules must have an operator selected.' });
      // }
      if (!this.helper.variableHasValue(rule.value)) {
        errorStore.push(
          new ErrorMessage(
            rule,
            'All rules must have a value entered or selected.'
          )
        );
        result = false;
      }
      if (QueryBuilderService.DEFAULT_FIELD_DEFINITION[rule.field]) {
        errorStore.push(
          new ErrorMessage(
            rule,
            'Please select a valid field that is not "' + QueryBuilderService.DEFAULT_FIELD_DEFINITION[rule.field].name + '".'
          )
        );
        result = false;
      }
      if (_.isEqual(rule.value, QueryBuilderService.DEFAULT_SELECT_OPTION.value)
        || _.findIndex(rule.value, rv => _.isEqual(rv, QueryBuilderService.DEFAULT_SELECT_OPTION.value)) > -1) {
        errorStore.push(
          new ErrorMessage(
            rule,
            'Please select a valid value that is not "' + QueryBuilderService.DEFAULT_SELECT_OPTION.name + '".'
          )
        );
        result = false;
      }
      if (this.helper.variableHasValue(rule.field) && this.helper.variableHasValue(rule.value)
        && this.helper.variableHasValue(queryBuilderConfiguration)
        && queryBuilderConfiguration.fields[rule.field]?.validator) {
        const validationResult = queryBuilderConfiguration.fields[rule.field].validator(rule, ruleParent);
        if (validationResult) {
          console.log(validationResult);
          errorStore.push(validationResult);
          console.log(errorStore);
          result = false;
        }
      }
    }
    else {
      const rule: RuleSet = ruleObj as RuleSet
      if (!this.helper.variableHasValue(rule.condition)) {
        errorStore.push(
          new ErrorMessage(
            'condition',
            'All RuleSets must have a condition selected.'
          )
        );
        result = false;
      }
      if (!this.helper.variableHasValue(rule.rules)) {
        errorStore.push(
          new ErrorMessage(
            'empty',
            'Empty RuleSets are not allowed.'
          )
        );
        result = false;
      }
      rule.rules?.forEach(r => {
        if (!this.validateRule(r, rule, errorStore, queryBuilderConfiguration)) {
          result = false;
        }
      });
    }
    return result;
  }

  ///// Helping Functions
  private isRuleObjRule(ruleObj: RuleSet | Rule) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return typeof ruleObj["field"] !== 'undefined' ||
      // eslint-disable-next-line @typescript-eslint/dot-notation
      typeof ruleObj['value'] !== 'undefined';
  }

  ///// Formatting functions
  private formatValue(value: any): string {
    return '<i>' + value + '</i>';
  }

  private formatField(field: any): string {
    return '<b>' + field + '</b>';
  }

  private formatFirstRuleSet(ruleText: any): string {
    return '<ul>' + ruleText + '</ul>';
  }

  private formatRuleSet(ruleText: any): string {
    return '<li><ul>' + ruleText + '</ul></li>';
  }

  private formatRule(ruleText: any): string {
    return '<li>' + ruleText + '</li>';
  }
}
