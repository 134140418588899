import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as globalConst from 'app/common/model/app-constants';
import { PreviewPanelModel } from 'app/common/model/preview-panel-model';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { PDFTitleInfo } from '../model/pdf-title-info';
import { ExportFileService } from '../services/export-file.service';
import { TableUtil } from '../util/table-util';
import { PDFGenerator } from './pdf-generator/pdf-generator';

@Component({
  selector: 'mc-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy {

  @Input() formTitle;
  @Input() formCaption;
  @Input() caseEventId;
  @Input() printDocType = 0;
  @Input() showToggleButton = true;
  @Input() isPdf = true;
  @Input() studyId;
  @Input() enrollmentStudySchemaEventId;
  @Input() pdfTitleInfo: PDFTitleInfo | null;

  _previewModel: PreviewPanelModel;

  previewExpanded = false;
  previewExpandedVertical = false;
  previewExpandedHorizontal = false;
  subscriptions: Subscription[] = [];


  /**
   * TODO: We'll revisit when we will work with Scroll and Printing
   */
  @ViewChild('showPreviewDiv')
  public showPreviewDiv: ElementRef;

  @ViewChild('content')
  public content: ElementRef;

  /**
  * A Model to hold the states of the preview panel
  */
  @Input()
  set previewModel(preModel: PreviewPanelModel) {
    this._previewModel = preModel;
  }

  /**
   * returns the previewmodel
   */
  get previewModel() {
    return this._previewModel;
  }

  constructor(@Inject(DOCUMENT) private document: any,
    private exportFileService: ExportFileService) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    );
  }

  /**
   * This will toggle when clicked on preview button from the horizontal view.
   */
  togglePreviewHorizontal() {
    this.previewModel.isVertical = false;
    this.previewModel.isCollapsed = !this.previewModel.isCollapsed;
  }


  /**
   * This will toggle when clicked on preview button from the vertical view.
   */
  togglePreviewVertical() {
    this.previewModel.isVertical = true;
    this.previewModel.isCollapsed = !this.previewModel.isCollapsed;
    this.previewExpandedVertical = !this.previewExpandedVertical;

  }


  /**
   * This will switch to Vertical view when clicked on the Landscape mode
   */
  switchToVertical() {
    this.previewModel.isVertical = false;

  }
  /**
   * This will switch to Horizontal view when clicked on the Potraite mode
   */
  switchToHorizontal($event: Event) {
    if ($event) {
      $event.stopPropagation();
    }
    this.previewModel.isVertical = true;
    if (this.previewModel.isCollapsed) {
      // this.previewModel.isCollapsed = false;
    }

  }
  setPreviewScroll(linkID) {
    // console.log('called setPreview Scroll - scroll now' + linkID)
    // console.log(document.getElementById(linkID+"_print").offsetTop);
    const offsetTop = document.getElementById(linkID + '_print').offsetTop;
    document.getElementById('preview-list-content').scrollTop = offsetTop - 40;
  }

  /**
   * True if the orientation is vertical and the state is collapsed
   */
  isCollapsedVertical(): boolean {
    return this.previewModel.isCollapsed && this.previewModel.isVertical;
  }



  /**
   * True if the orientation is horizontal and is collapsed
   */
  isCollapsedHorizontal(): boolean {
    return (this.previewModel.isCollapsed && !this.previewModel.isVertical);
  }


  /**
   * True if the orientation is vertical and the state is Expanded
   */
  isExpandedVertical(): boolean {
    return (!this.previewModel.isCollapsed && this.previewModel.isVertical);
  }



  /**
   * True if the orientation is horizontal and is expanded
   */
  isExpandedHorizontal(): boolean {
    return (!this.previewModel.isCollapsed && !this.previewModel.isVertical);
  }

  /**
   * Calls the service to download pdf
   */
  downloadPdf() {
    if (this.printDocType === globalConst.REVIEW_PATIENT) {
      this.getPatientConfirmationPdf();
    }
    else if (this.printDocType === globalConst.SIM_STUDY_PARAMS) {
      this.getSimStudyParameters();
    }
    else {
      this.getPDFForCurrentContent();
    }
  }

  downloadExcel() {
    if (this.printDocType === globalConst.ACCRUAL_PATIENT) {
      //"accrual-subjects-table" -->Table with id defined in accrued-patient-search-results.component.html
      TableUtil.exportTableToExcel("accrual-subjects-table", "AccrualSubjects");
    } else if (this.printDocType === globalConst.ANCILLARY_ACCRUAL_PATIENT) {
      TableUtil.exportTableToExcel("accrual-subjects-table", "AncillaryAccrualSubjects");
    }
  }

  private getPatientConfirmationPdf() {
    if (this.caseEventId > 0) {
      const mediaType = 'application/pdf';
      this.subscriptions.push(this.exportFileService.getPatientConfirmationPdf(this.caseEventId)
        .subscribe(res => {
          const blob = new Blob([res], { type: mediaType });
          saveAs(blob, 'Research Registration Summary.pdf');
        }));
    }
  }

  private getSimStudyParameters() {
    if (this.studyId > 0 && this.enrollmentStudySchemaEventId > 0) {
      const mediaType = 'application/pdf';
      this.subscriptions.push(this.exportFileService.getSimStudyParameters(this.studyId, this.enrollmentStudySchemaEventId)
        .subscribe(res => {
          const blob = new Blob([res], { type: mediaType });
          saveAs(blob, 'Simulation Study Parameters.pdf');
        }));
    }
  }

  private getPDFForCurrentContent(): void {
    const pdfGenerator: PDFGenerator = new PDFGenerator(this.content, this.getPDFTitle(), this.getPDFSubtitle());
    pdfGenerator.generatePDF();
  }

  private getPDFTitle(): string {
    if (this.pdfTitleInfo && this.pdfTitleInfo.title.trim().length > 0) {
      return this.pdfTitleInfo.title.trim();
    }
    else if (this.formCaption && this.formCaption.trim().length > 0) {
      return this.formCaption.trim();
    }
    else if (this.formTitle && this.formTitle.trim().length > 0) {
      return this.formTitle.trim();
    }

    return '';
  }

  private getPDFSubtitle(): string {
    if (this.pdfTitleInfo && this.pdfTitleInfo.subtitle && this.pdfTitleInfo.subtitle.trim().length > 0) {
      return this.pdfTitleInfo.subtitle.trim();
    }

    return '';
  }
}

