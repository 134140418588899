import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminTilesComponent } from './admin-tiles.component';
import { NotificationTileResultsComponent } from './notification-tile-results/notification-tile-results.component';
import { StudiesConstructionTileResultsComponent } from './studies-construction-tile-results/studies-construction-tile-results.component';
import { McTableModule } from '../../common/directives/table/mc-table.module';
import { SharedModule } from '../../common/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientTileResultsComponent } from './patient-tile-results/patient-tile-results.component';


@NgModule({
  imports: [
    CommonModule,
    McTableModule,
    SharedModule, FormsModule, ReactiveFormsModule,

  ],
  declarations: [
    AdminTilesComponent,
    StudiesConstructionTileResultsComponent,
    NotificationTileResultsComponent,
    PatientTileResultsComponent
  ],
  exports: [
    AdminTilesComponent,
    StudiesConstructionTileResultsComponent,
    NotificationTileResultsComponent,
    PatientTileResultsComponent
  ]
})
export class AdminTilesModule { }
