import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config/app-config.module';
import * as globalConst from 'app/common/model/app-constants';
import { Code } from 'app/common/model/code';
import { GroupVariableValue } from 'app/common/model/group-variable-value';
import { ReservationVariableTypeMap } from 'app/common/model/reservation-variable-type-map';
import { SchemaEventAttribute } from 'app/common/model/schema-event-attribute';
import { SchemaEventAttributeOption } from 'app/common/model/schema-event-attribute-option';
import { SchemaEventGfForm } from 'app/common/model/schema-event-gf-form';
import { StudySchemaEvent } from 'app/common/model/study-schema-event';
import { BaseService } from 'app/common/services/base-service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { SchemaPathBuilder } from '../../model/schema-path-builder';
import { StudySchema } from '../../model/study-schema';

@Injectable()
export class SchemaDashboardService extends BaseService {
  public studySchema: StudySchema;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super(componentFactoryResolver);
  }

  /**
   * Gets the Schema  from the service
   */
  public getSchemaEvents(studyId: number): Observable<StudySchema> {
    return this.http.get<StudySchema>(encodeURI(`${this.config.apiEndpoint}studies/` + studyId + `/schema-events`));
  }

    /*
  Gets the studySchema from the service
  @returns StudySchema
  */
  public getStudySchemaByStudySchemaId(studyId: number, studySchemaId: number): Observable<StudySchema> {
    return this.http.get<StudySchema>(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema/${studySchemaId}`));
  }

  /*
  Gets the studySchema from the service
  @returns StudySchema
  */
  public getStudySchema(studyId: number): Observable<StudySchema[]> {
    return this.http.get<StudySchema[]>(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema`));
  }

   /*
  Gets the current validated studySchema with effective date from the service
  @returns StudySchema
  */
  public getCurrentValidatedSchema(studyId: number): Observable<StudySchema> {
    return this.http.get<StudySchema>(encodeURI(`${this.config.v2_apiEndpoint}studies/${studyId}/schema`));
  }

  /**
   *  Get all events from validated schemas, excluding any duplicates.
	 * (Latest validated schema events + (All Validated schema events - Latest validated schema events))
   * @param studyId 
   * @returns 
   */
  public getValidatedSchemaEvents(studyId: number): Observable<StudySchema> {
    return this.http.get<StudySchema>(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema-events/validated`));
  }

  /**
   * Calls the service to add a new schema Event
   * @param studyId - studyId for which new schema event is created
   * @param schemaEvent -studySchema object which needs to be saved to DB
   * @returns Observable
   */
  public addSchema(studyId: number, schema: StudySchema): Observable<StudySchema> {
    const url = 'studies/' + studyId + '/schema';
    return this.http.post<StudySchema>(encodeURI(`${this.config.apiEndpoint}` + url), schema);
  }
  /**
   * Update the Study Schema
   * @param studyId - studyId for which existing schema events are updated.
   * @param studySchemaId - studySchemaId for which  events are updated.
   * @param schema - The schema that consist the ist of studySchemaEvents .
   * @returns Observable
   */
  public updateStudySchema(studyId: number, studySchemaId: number, schema: StudySchema): Observable<StudySchema> {
    const url = 'studies/' + studyId + '/schema/' + studySchemaId;
    return this.http.put<StudySchema>(`${this.config.apiEndpoint}` + url, schema);
  }

  /**
   * @param studyId  - studyId for which you need to update
   * @param studySchemaId - studySchemaId for which  events are updated.
   * @param schema - schema Object with events of only changed attributes.
   * @returns Observable
   */
  public updateSchemaEventAttributesOnly(
    studyId: number,
    studySchemaId: number,
    schema: StudySchema,
  ): Observable<StudySchema> {
    const url = 'studies/' + studyId + '/schema/' + studySchemaId + '/attributes';
    return this.http.put<StudySchema>(`${this.config.apiEndpoint}` + url, schema);
  }

  /**
   *
   * @returns Get Group types
   */
  public getGroupTypes(): Observable<Array<Code>> {
    return this.http.get<Array<Code>>(`${this.config.apiEndpoint}configuration/reservations/group-types`);
  }

  /**
   *
   * @returns Get Group types
   */
  public getGroupStatus(): Observable<Array<Code>> {
    return this.http.get<Array<Code>>(`${this.config.apiEndpoint}configuration/reservations/group-status`);
  }

  /**
   *
   * @returns Get group-variable-types
   */
  public getGroupVariableTypes(): Observable<Array<ReservationVariableTypeMap>> {
    return this.http.get<Array<ReservationVariableTypeMap>>(
      `${this.config.apiEndpoint}reservations/group-variable-types`,
    );
  }

  /**
   *
   * @returns Get Study reservation valid values
   */
  public getStudyReservationValidValues(studyId: number): Observable<Array<GroupVariableValue>> {
    return this.http.get<Array<GroupVariableValue>>(encodeURI(
      `${this.config.apiEndpoint}studies/` + studyId + `/reservations/valid-values`),
    );
  }

  /*************  Common Code to build the schema event tree  ******************************* */

  /**
   * Evalautes if the pathNumbers are configured for each event in the schema
   * This is for backward compatibility with existing schemas created before
   * PathNumbers were introduced to the event properties
   */
  arePathNumbersConfigured(eventList: StudySchemaEvent[]): boolean {
    let pathNumsExist = true;
    eventList.forEach(eachEvt => {
      pathNumsExist = pathNumsExist && eachEvt.pathNums && eachEvt.pathNums.length > 0;
    });
    return pathNumsExist;
  }

  /**
   * Builds and returns a tree of events by path based on their parent / child relationships and path numbers
   * @param sourceData The source data of Schema Events
   * @param placeholderEventIds An array to hold the schemaEventIs by their location on their schema display
   * @param headerEvents The list of events to be displayed on the header
   * @param eventTree The event Tree to be rendered
   * @param sharedEventIdsOnRow unique list of ids shared across different paths
   * @param arePathNumbersConfigured true if we are dealing with legacy data and need to populate the events with path numbers
   */
  public buildSchemaEventTree(pathBuilderSource: SchemaPathBuilder) {
    const sourceData: StudySchemaEvent[] = pathBuilderSource.sourceData;
    const schemaEvent = sourceData.find(eachEvent => eachEvent.parentStudySchemaEventIds.length == 0);
    if (schemaEvent != null) {
      // tree[0] = new Array();
      // this.populateEventPath(sourceData, schemaEvent.studySchemaEventId, tree, 0, header);
      this.buildPopulatedEventPath(pathBuilderSource); // sourceData, headerEvents, eventTree, placeholderEventIds, arePathNumbersConfigured);
    }
    const placeholderEventIds = pathBuilderSource.placeholderEventIds;
    pathBuilderSource.sharedEventIdsOnRow = _.uniq([].concat.apply([], placeholderEventIds));
  }

  /**
   * Builds the paths of events
   * @param sourceData The source data of schema events
   * @param headerEvents The list of events to be displayed on the header
   * @param eventTree The event Tree to be rendered
   * @param placeholderEventIds An array to hold the schemaEventIs by their location on their schema display
   * @param arePathNumbersConfigured true if we are dealing with legacy data and need to populate the events with path numbers
   */
  buildPopulatedEventPath(pathBuilderSource: SchemaPathBuilder) {
    const sourceData: StudySchemaEvent[] = pathBuilderSource.sourceData;
    const headerEvents: StudySchemaEvent[] = pathBuilderSource.headerEvents;
    // let eventTree: Array<Array<number>> = pathBuilderSource.eventTree;
    const placeholderEventIds: Array<Array<number>> = pathBuilderSource.placeholderEventIds;
    const arePathNumbersConfigured: boolean = pathBuilderSource.arePathNumbersConfigured;

    const parentEvent: StudySchemaEvent = sourceData.find(eachEvent => eachEvent.parentStudySchemaEventIds.length == 0);
    const evtTree: Array<Array<number>> = new Array<Array<number>>();
    let groupedEvents: Array<Array<StudySchemaEvent>>;
    evtTree[0] = new Array<number>();
    const headerList: Array<number> = [];

    let pathBasedTreeCreated = false;

    if (arePathNumbersConfigured) {
      if (parentEvent.pathNums && parentEvent.pathNums.length > 0) {
        const paths: number[] = _(sourceData)
          .map('pathNums')
          .flatten()
          .uniq()
          .value()
          .sort(function (a, b) {
            return a - b;
          });
        if (paths.length > 0) {
          this.buildPathbasedTree(sourceData, paths, evtTree, pathBuilderSource);
          pathBasedTreeCreated = true;
        }
      }
    }
    if (!pathBasedTreeCreated) {
      this.createSingleEventPath(sourceData, parentEvent.studySchemaEventId, 0, evtTree, placeholderEventIds);
    }
    this.buildEventHeaderList(sourceData, evtTree, headerList);
    groupedEvents = this.getGroupedEventsForTable(sourceData, evtTree, headerList);
    // now populate the header with the real events{
    headerList.forEach((eachId, indx) => {
      headerEvents[indx] = sourceData.find(eachSchemaEvent => eachSchemaEvent.schemaEventId == eachId);
    });
    pathBuilderSource.eventTree = evtTree;
    console.log('EVENT Tree=> ', evtTree, 'PlaceHolder =', placeholderEventIds);
    pathBuilderSource.placeholderEventIds = placeholderEventIds;
    pathBuilderSource.headerEvents = headerEvents;
    pathBuilderSource.groupedEventTree = groupedEvents;
  }

  /**
   *
   * @param source Source data of schema events
   * @param allPossiblePaths unique list of all the possible path numbers in the data
   * @param evtTree The event tree to be populated
   * @param placeholderEventIds An array to hold the schemaEventIs by their location on their schema display
   */
  buildPathbasedTree(
    source: StudySchemaEvent[],
    allPossiblePaths: number[],
    evtTree: Array<Array<number>>,
    pathBuilderSource: SchemaPathBuilder,
  ) {
    const placeholderEventIds: Array<Array<number>> = pathBuilderSource.placeholderEventIds;

    const rootEvt: StudySchemaEvent = source.find(schemaEvent => schemaEvent.parentStudySchemaEventIds.length == 0);
    allPossiblePaths.forEach(eachPath => {
      placeholderEventIds[eachPath] = new Array();
      this.buildSinglePathbasedPath(source, rootEvt.studySchemaEventId, eachPath, evtTree, placeholderEventIds);
    });
    pathBuilderSource.placeholderEventIds = placeholderEventIds;
    console.log('NEW Event Tree', evtTree);
  }

  /**
   * Recursive function to build the event tree based on the path and children
   * @param source The source data
   * @param id The id of the event being processed
   * @param pathCounter The current path being evaluated
   * @param tree The tree being built
   * @param placeholderEventIds An array to hold the schemaEventIs by their location on their schema display
   */
  buildSinglePathbasedPath(
    source: Array<StudySchemaEvent>,
    id: number,
    pathCounter: number,
    tree: Array<Array<number>>,
    placeholderEventIds: Array<Array<number>>,
  ) {
    if (pathCounter >= tree.length) {
      tree[pathCounter] = [];
    }

    // console.log("PathCounter =", pathCounter)
    const child = source.find(
      evt =>
        evt.studySchemaEventId == id &&
        evt.pathNums &&
        evt.pathNums.length > 0 &&
        evt.pathNums.indexOf(pathCounter) > -1,
    );
    // if this is a valid event, add it to the list
    if (child) {
      if (tree[pathCounter].indexOf(child.studySchemaEventId) == -1) {
        tree[pathCounter].push(child.studySchemaEventId);
        if (tree.length > 1) {
          const firstTreeBranch = tree[0];
          const sharedEventIdsOnRow: Array<{}> = _.uniq([].concat.apply([], placeholderEventIds));
          const currentPlaceholderRow = placeholderEventIds[tree.length - 1];

          // if this is already in the array, it needs to be part of this row too
          if (
            (sharedEventIdsOnRow.indexOf(id) > -1 || firstTreeBranch.indexOf(id) > -1) &&
            currentPlaceholderRow.indexOf(id) == -1
          ) {
            currentPlaceholderRow.push(id);
          }
          if (tree.length - 1 > 0) {
            const previousTreeRow: number[] = tree[tree.length - 2];
            if (previousTreeRow.indexOf(id) > -1 && currentPlaceholderRow.indexOf(id) == -1) {
              currentPlaceholderRow.push(id);
            }
          }
        }
      }
      // find the next child in this path
      if (child.childStudySchemaEventIds.length > 0) {
        for (let n = 0; n < child.childStudySchemaEventIds.length; n++) {
          const nextChild = source.find(
            evt =>
              child.childStudySchemaEventIds[n] == evt.studySchemaEventId &&
              evt.pathNums &&
              evt.pathNums.length > 0 &&
              evt.pathNums.indexOf(pathCounter) > -1,
          );
          if (nextChild) {
            this.buildSinglePathbasedPath(source, nextChild.studySchemaEventId, pathCounter, tree, placeholderEventIds);
          }
        }
      }
    }
  }

  /**
   * Helper method to build the unique list of header based on the event type
   * @param source The source data of the events
   * @param tree The tree structure built
   * @param headerList The headerList to build based on the events in the tree
   */
  buildEventHeaderList(source: Array<StudySchemaEvent>, tree: Array<Array<number>>, headerList: Array<number>) {
    let evtToProcess: StudySchemaEvent;
    if (headerList == undefined || headerList == null) {
      headerList = [];
    }
    tree.forEach((eachRow, rowIndex) => {
      eachRow.forEach((eachEvt, evtIndex) => {
        evtToProcess = source.find(event => event.studySchemaEventId == eachEvt);
        const headerLocationIndex = headerList.indexOf(evtToProcess.schemaEventId, evtIndex);
        // if the location of the existing eventype is before the evtIndex
        if (headerLocationIndex < evtIndex) {
          // push the event type at the needed location
          headerList.splice(evtIndex, 0, evtToProcess.schemaEventId);
        }
      });
    });
  }

  /**
   * Helper method to build a 2 dimensional array for laying out schema events
   * in their paths for ui display
   * @param sourceData The source data of schemaEvents to build the tree from
   * @param eventTree The eventTree with the ids laid out
   * @param headerList The headerList for the size of the layout
   */
  getGroupedEventsForTable(
    sourceData: StudySchemaEvent[],
    eventTree: Array<Array<number>>,
    headerList: Array<number>,
  ): Array<Array<StudySchemaEvent>> {
    const numberOfPaths: number = eventTree.length;
    const maxEventsOnEachPath: number = headerList.length;
    let matchEvent: StudySchemaEvent;
    let headerIndex: number;
    const rowArray: Array<Array<StudySchemaEvent>> = new Array<Array<StudySchemaEvent>>(numberOfPaths);
    const studySchemaEventIds: Array<number> = new Array<number>();
    for (let rowIdx = 0; rowIdx < numberOfPaths; rowIdx++) {
      // initialize the array on this path
      rowArray[rowIdx] = new Array<StudySchemaEvent>(maxEventsOnEachPath);
      // loop over each of the eventIds in the row
      eventTree[rowIdx].forEach((eventId, eventIdx) => {
        // find each event and put it in the slot
        matchEvent = sourceData.find(schemaEvent => schemaEvent.studySchemaEventId == eventId);
        //To avoid additional event at end of each path we are re-assigning correct pathNums to events
        if (studySchemaEventIds.indexOf(matchEvent.studySchemaEventId) == -1) {
          matchEvent.pathNums = [];
          studySchemaEventIds.push(matchEvent.studySchemaEventId);
        }
        // find the matching slot
        headerIndex = headerList.indexOf(matchEvent.schemaEventId, eventIdx);
        rowArray[rowIdx][headerIndex] = matchEvent;
        if (matchEvent.pathNums.indexOf(rowIdx) == -1) {
          matchEvent.pathNums.push(rowIdx);
        }
      });
    }
    return rowArray;
  }

  /*
   * Builds a tree structure with all the paths that includes the start and the branches
   * @param source The source data from which to build the tree
   * @param id the current id to process
   * @param pathCounter The path which needs to be built
   * @param tree The final tree with all the paths laid out
   * @param placeholderEventIds An array to hold the schemaEventIs by their location on their schema display
   */
  createSingleEventPath(
    source: Array<StudySchemaEvent>,
    id: number,
    pathCounter: number,
    tree: Array<Array<number>>,
    placeholderEventIds: Array<Array<number>>,
  ) {
    const childIds: Array<number> = [];
    const child = source.find(each => each.studySchemaEventId == id);
    if (child) {
      if (tree[pathCounter].indexOf(child.studySchemaEventId) == -1) {
        tree[pathCounter].push(child.studySchemaEventId);
        // if on the second row onwards, if this id is in the previous row
        if (tree.length > 1) {
          const firstTreeBranch = tree[0];
          const sharedEventIdsOnRow: Array<{}> = _.uniq([].concat.apply([], placeholderEventIds));
          // if this is already in the array, it needs to be part of this row too
          if (
            (sharedEventIdsOnRow.indexOf(id) > -1 || firstTreeBranch.indexOf(id) > -1) &&
            placeholderEventIds[tree.length - 1].indexOf(id) == -1
          ) {
            placeholderEventIds[tree.length - 1].push(id);
          }
        }
      }
      if (child.childStudySchemaEventIds.length > 0) {
        const preValue = 0;
        for (let n = 0; n < child.childStudySchemaEventIds.length; n++) {
          if (n > preValue) {
            const idsToAppend = _.slice(tree[pathCounter], 0, tree[pathCounter].indexOf(id) + 1);
            placeholderEventIds[tree.length] = idsToAppend.concat();
            tree[tree.length] = idsToAppend.concat();
            pathCounter = tree.length - 1;
          } else {
            if (placeholderEventIds.length == 0) {
              placeholderEventIds[0] = new Array();
              placeholderEventIds[0].push(0);
            }
          }
          const nextChild = source.find(each => child.childStudySchemaEventIds[n] == each.studySchemaEventId);
          if (nextChild) {
            this.createSingleEventPath(source, nextChild.studySchemaEventId, pathCounter, tree, placeholderEventIds);
          }
        }
      }
    }
  }

  getStudySchemaEventToTrackIfDeleted (studyId: number, studySchemaId: number): Observable<number[]>  {
    return this.http.get<number[]>(encodeURI(`${this.config.apiEndpoint}studies/${studyId}/schema/${studySchemaId}/track-deletes`));
    }

  /*************  Common Code to build the schema event tree  ******************************* */

  /**
   * Gets the events that can be associated with the Intervention Assignment Form
   */
  buildGroupingFactorInterventionAssignmentEvents(
    selectedSchemaEvent: StudySchemaEvent,
    studySchema: StudySchema,
  ): StudySchemaEvent[] {
    const ancestorEnrollmentEvents: StudySchemaEvent[] = [];
    if (this.isGroupingSchemaEvent(selectedSchemaEvent)) {
      this.getGroupingFactorEnrollmentAncestors(
        selectedSchemaEvent,
        selectedSchemaEvent,
        ancestorEnrollmentEvents,
        studySchema,
      );
    }

    return ancestorEnrollmentEvents.sort((aee1, aee2) => aee1.schemaEventSequenceNum - aee2.schemaEventSequenceNum);
  }

  /**
   * Checks if the given schema event is a grouping event
   * @returns True if the given schema event is a grouping event
   */
  isGroupingSchemaEvent(schemaEvent: StudySchemaEvent): boolean {
    return schemaEvent?.schemaEventCode === 'GROUPING_FACTOR';
  }

  /**
   * Recursive function to find all ancestors to the current grouping factor and registration events
   * @param studySchemaEvent Current study schema event to check
   * @param ancestorEnrollmentEvents Current list of enrollment events
   */
  private getGroupingFactorEnrollmentAncestors(
    selectedSchemaEvent: StudySchemaEvent,
    studySchemaEvent: StudySchemaEvent,
    ancestorEnrollmentEvents: StudySchemaEvent[],
    studySchema: StudySchema,
  ): void {
    const parents = studySchema.studySchemaEvents.filter(sce =>
      studySchemaEvent.parentStudySchemaEventIds.includes(sce.studySchemaEventId),
    );
    for (const oneParent of parents) {
      if (
        oneParent.schemaEventEnrollmentEventFlag &&
        studySchemaEvent !== selectedSchemaEvent &&
        // Prevents duplicates from any events with multiple parents
        !ancestorEnrollmentEvents.includes(oneParent)
      ) {
        ancestorEnrollmentEvents.push(oneParent);
      }
      this.getGroupingFactorEnrollmentAncestors(selectedSchemaEvent, oneParent, ancestorEnrollmentEvents, studySchema);
    }
  }

  /**
   * Returns the string to be displayed for the study schema event with the Intervention Assignment form based on the passed in selected study schema event
   * @param selectedGroupingFactorStudySchemaEvent Study Schema Event that is selected
   * @returns Formatted string
   */
  getEventInterventionAssignmentDisplay(
    selectedGroupingFactorStudySchemaEvent: StudySchemaEvent,
    studySchema: StudySchema,
  ): string {
    const studySchemaEvent = this.buildGroupingFactorInterventionAssignmentEvents(
      selectedGroupingFactorStudySchemaEvent,
      studySchema,
    ).find(sse => sse.studySchemaEventId === selectedGroupingFactorStudySchemaEvent.associatedFormStudySchemaEventId);
    return this.formatStudySchemaEvent(studySchemaEvent);
  }

  /**
   * Returns the string to be displayed as a header for the given studySchemaEvent
   * @param studySchemaEvent Study Schema Event to format for display
   * @returns Formatted string
   */
  formatStudySchemaEvent(studySchemaEvent: StudySchemaEvent): string {
    return studySchemaEvent
      ? `${studySchemaEvent.studySchemaEventLabel
        ? studySchemaEvent.studySchemaEventLabel
        : studySchemaEvent.schemaEventDisplayName
      } - (${studySchemaEvent.studySchemaEventId})`
      : '';
  }

  /**
   * Get display text of the selected option in the list.
   * Return null if an option is not selected
   * @param attributeOptions
   */
  getSelectedOptionText(attributeOptions: SchemaEventAttributeOption[]) {
    const selectedAttrOpt = attributeOptions.find(atrOpt => atrOpt.selected == true);
    if (selectedAttrOpt) {
      return selectedAttrOpt.optionDisplayText;
    }
    return null;
  }

  /**
   * Returns a string for the header on the Grouping Factor Grid
   * @param item The SchemaEventGfForm object
   */
  getGfHeader(item: SchemaEventGfForm): string {
    let header = '';
    if (item) {
      header = 'Form = ' + item.formName;
      if (item.studyFormNum) {
        header += '  Form Number: ' + item.studyFormNum;
      }
      if (item.versionNum) {
        header += ' Version Number: ' + item.versionNum;
      }
    }
    return header;
  }

  /**
   * Returns the display name for the event
   * @param eventId The event to be evaluated
   */
  getDisplayNameFromEventId(eventId: number, studySchema: StudySchema): string {
    let name = '';
    const match: StudySchemaEvent = studySchema.studySchemaEvents.find(
      singleEvt => singleEvt.studySchemaEventId == eventId,
    );
    if (match) {
      name = match.studySchemaEventLabel ? match.studySchemaEventLabel : match.schemaEventName;
    }
    // eachEvent.studySchemaEventLabel ? eachEvent.studySchemaEventLabel : eachEvent.schemaEventName));

    return name;
  }

  /**
   * Returns the appropriate Grouping Factor Forms to be displayed based on the if it should use the Intervention Assignment Form
   * @returns List of Grouping Factor Forms that should be displayed
   */
  getGroupingFactorFormList(selectedSchemaEvent: StudySchemaEvent): SchemaEventGfForm[] {
    let idToCompare: number;
    if (this.disableInterventionAssignmentFormEventDropdown(selectedSchemaEvent)) {
      idToCompare = null;
    } else if (selectedSchemaEvent.associatedFormStudySchemaEventId) {
      idToCompare = selectedSchemaEvent.associatedFormStudySchemaEventId;
    } else {
      return [];
    }

    return selectedSchemaEvent.studySchemaEventGfFormList?.filter(ssegff => ssegff.studySchemaEventId === idToCompare);
  }

  /**
   * Checks if the GfAssignmentForm attribute option 'yes' is selected to determine if the dropdown to select the associated event should be disabled
   * @param schemaEvent current study schema event
   * @returns false if the GfAssignmentForm attribute option 'yes' is selected
   */
  disableInterventionAssignmentFormEventDropdown(schemaEvent: StudySchemaEvent): boolean {
    const useIAFormResponse: SchemaEventAttribute = schemaEvent.studySchemaEventAttributeList?.find(
      attr => attr.attributeCode === 'GfAssignmentForm',
    );
    if (useIAFormResponse?.attributeOptions?.find(ao => ao.optionCode === 'Yes' && ao.selected)) {
      return false;
    }
    return true;
  }

  /**
   * Get display text of the checked checkboxes in the list.
   * Return null if an option is not selected
   * @param attributeOptions
   */
  getCheckedOptionText(attributeOptions: SchemaEventAttributeOption[]) {
    const selectedAttrOpts = attributeOptions.filter(atrOpt => atrOpt.selected == true);
    if (selectedAttrOpts) {
      return selectedAttrOpts
        .map(function (each) {
          return each.optionDisplayText;
        })
        .join(', ');
    }
    return null;
  }

  /**
   * True if the ui should display a text box
   * @param attribute the attribute for the schemaEvent being edited
   */
  isDisplayTextBox(attribute: SchemaEventAttribute) {
    const attributeOptions: SchemaEventAttributeOption[] = attribute.attributeOptions;
    return attribute.multiSelectFlag == false && attributeOptions == null;
  }

  /**
   * True if the ui should display radio buttons
   * @param attribute the attribute  for the schemaEvent being edited
   */
  isDisplayRadioButton(attribute: SchemaEventAttribute) {
    const attributeOptions: SchemaEventAttributeOption[] = attribute.attributeOptions;
    return (
      attribute.multiSelectFlag == false &&
      attributeOptions != null &&
      attributeOptions.length == 2 &&
      (attributeOptions[0].optionCode.toUpperCase() == 'YES' || attributeOptions[0].optionCode.toUpperCase() == 'NO')
    );
  }

  /**
   * True if the ui should display a drop down select
   * @param attribute the attribute for the schemaEvent being edited
   */
  isDisplaySelect(attribute: SchemaEventAttribute) {
    const attributeOptions: SchemaEventAttributeOption[] = attribute.attributeOptions;
    return (
      attribute.multiSelectFlag == false &&
      attributeOptions != null &&
      !this.isDisplayTextBox(attribute) &&
      !this.isDisplayRadioButton(attribute) &&
      attributeOptions.length >= 0
    );
  }

  /**
   * True if the ui should display checkboxes for multiple selects
   * @param attribute the attribute for the schemaEvent being edited
   */
  isDisplayCheckBoxes(attribute: SchemaEventAttribute) {
    const attributeOptions: SchemaEventAttributeOption[] = attribute.attributeOptions;
    return attribute.multiSelectFlag && attributeOptions != null && attributeOptions.length > 0;
  }

  /**
   * True if the event being evaluated is the Dynamic Allocation event
   * and it has child events
   * @param evt The studySchemaEvent to evaluate
   */
  shouldDisplayArmWeightGrid(evt: StudySchemaEvent): boolean {
    return (
      evt?.schemaEventCode.toLowerCase() === globalConst.DYNAMIC_ALLOCATION_EVENT_CODE.toLowerCase() &&
      evt?.childStudySchemaEventIds?.length > 0
    );
  }


 
}
