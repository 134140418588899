import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { DrugArmKitInfo } from 'app/common/model/drug-arm-kit-info';
import { DrugDefault } from 'app/common/model/drug-default';
import { DrugKitAssignment } from 'app/common/model/drug-kit-assignment';
import { DrugKitDefinitionInfo } from 'app/common/model/drug-kit-definition-info';
import { DrugOrderArmEvent } from 'app/common/model/drug-order-arm-event';
import { DrugPharmacy } from 'app/common/model/drug-pharmacy';
import { DrugReorderException } from 'app/common/model/drug-reorder-exception';
import { StudyPharmacy } from 'app/common/model/study-pharmacy';
import { Observable, of } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../../../app-config/app-config.module';
import { DrugShipToLevel } from '../../model/drug-ship-to-level';
import { DrugShipToLevelException } from '../../model/drug-ship-to-level-exception';
import { DrugSite } from '../../model/drug-site';
import { DrugStudyShipToLevel } from '../../model/drug-study-ship-to-level';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})

export class DoubleBlindService extends BaseService {


  serviceUrlPrefix = this.config.apiEndpoint;



  /**
     * variable for instance of sectionItems
     */
  private _sectionItemList: any[];





  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  /**
   * returns the sectionItems
   */
  public get sectionItems(): any[] {
    return this._sectionItemList;
  }

  /**
   * sets the sectionItems
   */
  public set sectionItems(items: any[]) {
    this._sectionItemList = items;
  }

  /**
   * Sets the Section enabled flag to true / false;
   * @param id the sectionId to locate
   * @param isEnabled True if the section should be enabled
   */
  setSectionDisabled(id: number, isEnabled: boolean): void {
    const section = this._sectionItemList.find(item => item.id == id);
    if (section) {
      // console.log("Set the Section ", section, isEnabled)
      section.isEnabled = !isEnabled;
    }
  }

  /**
   * Returns the section for the id
   * @param id The id to search by
   */
  getSection(id: number): any {
    return this._sectionItemList.find(item => item.id == id);
  }


  /**
   * Sets the dependency tabs for supply data
   * @param dataExists True if the Data exists for the supply tab
   */
  setSupplyDependency(dataExists: boolean) {
    // this.setSectionDisabled(2, !dataExists);
    // Set the Drug Default Depedency
    this.setSectionDisabled(2, !dataExists);
    // this.setDrugDefaultDependency(dataExists);
  }

  /**
   * Sets the navigation dependency for Drug Defaults
   * @param dataExists True if the Drug Defaults exist
   */
  setDrugDefaultDependency(dataExists: boolean) {
    //  id: 1 "Supply & Exceptions"
    //  id: 2,title: "Drug Defaults"
    //  id: 3, title: "Kit Definitions"
    //  id: 4, title: "Kit Assignments"
    //  id: 5, title: "Manage Kits"
    //  id: 6, title: "Reorders"

    // Set the Drug Default section and cascade to Kit definition
    this.setSectionDisabled(3, !dataExists);
    // this.setKitDefinitionDependency(dataExists);

  }

  /**
   * Sets the dependency tabs for kit definitions data
   * @param dataExists True if the Data exists for the kit definitions tab
   */
  setKitDefinitionDependency(dataExists: boolean) {
    // set the kitassignment tab and cascade the dependencies
    this.setSectionDisabled(4, !dataExists);
    // this.setKitAssignmentDependency(dataExists);
  }


  /**
    * Sets the dependency tabs for kit assignments data
    * @param dataExists True if the Data exists for the kit assignments tab
    */
  setKitAssignmentDependency(dataExists: boolean) {
    // set the Manage Kits and Reorders
    this.setSectionDisabled(5, !dataExists);
    this.setSectionDisabled(6, !dataExists);
  }








  /**
   * Fetches the Drug Intervention Events array from the service
   */
  public getDrugInterventionEvents(studyId: number): Observable<DrugOrderArmEvent[]> {
    return this.http.get<DrugOrderArmEvent[]>
      (this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/drug-defaults/intervention-events');


  }

  //  Endpoint for creating arm drug defaults

  /**
     *
     * @param studyId The studyId to which it belongs
     * @param record The record to be inserted
     */
  public addArmDrugDefaults(studyId: number, record: DrugDefault[]): Observable<DrugDefault[]> {
    // record.studyDrugShipToLevelId = Math.floor(Math.random() * 1500) + 1;
    // record.tupleVersion = 1;
    // return of(record);
    // api/v1/studies/10504/double-blind/drug-defaults
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/drug-defaults';
    return this.http.post<DrugDefault[]>(myUrl, record);
  }


  /**
   * Gets the DrugDefaults array for this study
   * @param studyId The studyID
   */
  public getDrugDefaults(studyId: number): Observable<DrugDefault[]> {
    const dd: DrugDefault[] = [{ 'studyArmDrugDefaultId': 62, 'armDetails': { 'studySchemaEventId': 33993, 'studySchemaEventLabel': 'Interven', 'studySchemaEventDescription': 'Desc' }, 'drugDefaultOrders': [{ 'studyArmDrugDefaultOrderId': 116, 'orderNumber': '1', 'kitsPerOrder': 0, 'siteReorderLevel': 0, 'tupleVersion': 1 }, { 'studyArmDrugDefaultOrderId': 117, 'orderNumber': '2', 'kitsPerOrder': 0, 'siteReorderLevel': 0, 'tupleVersion': 1 }, { 'studyArmDrugDefaultOrderId': 118, 'orderNumber': '3', 'kitsPerOrder': 0, 'siteReorderLevel': 0, 'tupleVersion': 1 }, { 'studyArmDrugDefaultOrderId': 119, 'orderNumber': '4', 'kitsPerOrder': 0, 'siteReorderLevel': 0, 'tupleVersion': 1 }], 'numOfOrders': 4, 'maxKitsPerSubject': 2, 'tupleVersion': 1 }, { 'studyArmDrugDefaultId': 63, 'armDetails': { 'studySchemaEventId': 33994, 'studySchemaEventLabel': 'Intervention-33994', 'studySchemaEventDescription': null }, 'drugDefaultOrders': [{ 'studyArmDrugDefaultOrderId': 120, 'orderNumber': '1', 'kitsPerOrder': 0, 'siteReorderLevel': 0, 'tupleVersion': 1 }, { 'studyArmDrugDefaultOrderId': 121, 'orderNumber': '2', 'kitsPerOrder': 0, 'siteReorderLevel': 0, 'tupleVersion': 1 }, { 'studyArmDrugDefaultOrderId': 122, 'orderNumber': '3', 'kitsPerOrder': 0, 'siteReorderLevel': 0, 'tupleVersion': 1 }, { 'studyArmDrugDefaultOrderId': 123, 'orderNumber': '4', 'kitsPerOrder': 0, 'siteReorderLevel': 0, 'tupleVersion': 1 }], 'numOfOrders': 4, 'maxKitsPerSubject': 2, 'tupleVersion': 1 }];
    return this.http.get<DrugDefault[]>
      (this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/drug-defaults');
  }



  /**
   * Fetches the shipToLevels array from the service
   */
  public getShipToLevels(): Observable<DrugShipToLevel[]> {
    // https://dev.reg-backend.mayo.edu/api/v1/double-blind/drug-defaults/ship-to-levels
    return this.http.get<DrugShipToLevel[]>
      (this.serviceUrlPrefix + 'double-blind/drug-defaults/ship-to-levels');
  }


  /**
     * Updates the DrugDefaults
     * @param studyId The studyId to which it belongs
     * @param record The record to be updated
     */
  public updateDrugDefaults(studyId: number, record: DrugDefault[]): Observable<DrugDefault[]> {
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/drug-defaults';
    return this.http.put<DrugDefault[]>(myUrl, record);
  }

  /**
   * deletes all the study drug defaults.
   * @param  {number} studyId
   * @returns Observable
   */
  public deleteDrugDefaults(studyId: number): Observable<any> {
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/drug-defaults';
    return this.http.delete(myUrl);
  }

  /**
   * Fetches the drug site array for the study from the service
   * @param studyId The study id
   */
  public getDrugSitesForStudy(studyId: number): Observable<DrugSite[]> {
    return this.http.get<DrugSite[]>
      (this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/drug-defaults/sites');
    // studies/13718/double-blind/drug-defaults/sites
  }


  /**
     * Fetches the Drug StudyShipToLevel object for the study from the service
     * @param studyId The study id
     */
  public getDrugShipToLevelForStudy(studyId: number): Observable<DrugStudyShipToLevel> {
    // let returned: DrugStudyShipToLevel =
    // {
    //   studyDrugShipToLevelId: -1,
    //   studyId: studyId,
    //   drugShipToLevelId: 1,
    //   modifiedById: "",
    //   tupleVersion: null
    // };
    // let nullReturn : DrugStudyShipToLevel = null;
    // return of(nullReturn); // returned);
    //  http://localhost:8080/reg-backend-service/api/v1/studies/{studyId}/double-blind/drug-defaults/ship-to-levels

    return this.http.get<DrugStudyShipToLevel>
      (this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/study-ship-to-level');
  }
  /**
   *
   * @param studyId The studyId to which it belongs
   * @param record The record to be inserted
   */
  public addDrugShipToLevelForStudy(studyId: number, record: DrugStudyShipToLevel): Observable<DrugStudyShipToLevel> {
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/study-ship-to-level';
    return this.http.post<DrugStudyShipToLevel>(myUrl, record);
  }



  /**
   * Updates the StudyShipTolevel object with the changes
   * @param studyId The studyId
   * @param studyShipToLevelId The studyShipToLevel Id
   * @param record The studyShipToLevel object to update
   */
  public updateShipToLevelForStudy(studyId: number, studyShipToLevelId: number, record: DrugStudyShipToLevel): Observable<DrugStudyShipToLevel> {
    // record.tupleVersion++;
    // return of(record);
    // //  http://localhost:8080/reg-backend-service/api/v1/studies/{studyId}/double-blind/drug-defaults/study-ship-to-level/{studyShipToLevelId}
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/study-ship-to-level/' + studyShipToLevelId;
    return this.http.put<DrugStudyShipToLevel>(myUrl, record);
  }



  /**
 * Deletes the StudyShipTolevel
 * @param studyId The studyId
 * @param studyShipToLevelExceptionId The studyShipToLevelId
 * @param tupleVersion The tupleVersion of this record
 */
  public deleteShipToLevelForStudy(studyId: number, studyShipToLevelId: number, tupleVersion: number): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/study-ship-to-level/' + studyShipToLevelId;
    return this.http.delete(myUrl, { headers: header, responseType: 'text' });
  }


  /**********  Ship To level exceptions *********************** */
  /**
     * Fetches the drug site array for the study from the service
     * @param studyId The study id
     */
  public getDrugShipToLevelExceptions(studyId: number): Observable<DrugShipToLevelException[]> {
    // let returned: DrugShipToLevelException[] =
    //   [{
    //     drugShipToLevelExceptionId: 12,
    //     drugShipToLevelId: 1,
    //     modifiedById: null,
    //     nonStdOrganizationId: null,
    //     organizationId: 1,
    //     studyId: studyId,
    //     tupleVersion: null
    //   }]      ;
    //   let blanks : DrugShipToLevelException[] =[];
    // return of(blanks); //returned);
    // http://localhost:8080/reg-backend-service/api/v1/studies/{studyId}/double-blind/drug-defaults/ship-to-level-exception
    return this.http.get<DrugShipToLevelException[]>
      (this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/ship-to-level-exceptions');
  }


  /**
   *
   * @param studyId The studyId to which it belongs
   * @param record The record to be inserted
   */
  public addDrugShipToLevelException(studyId: number, record: DrugShipToLevelException): Observable<DrugShipToLevelException> {
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/ship-to-level-exceptions';
    return this.http.post<DrugShipToLevelException>(myUrl, record);
  }



  /**
   * Updates the StudyShipTolevel object with the changes
   * @param studyId The studyId
   * @param studyShipToLevelExceptionId The studyShipToLevelExceptionId
   * @param tupleVersion The tupleVersion of this record
   */
  public deleteShipToLevelException(studyId: number, shipToLevelExceptionId: number, tupleVersion: number): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/ship-to-level-exceptions/' + shipToLevelExceptionId;
    return this.http.delete(myUrl, { headers: header, responseType: 'text' });
  }



  /****** Reorder Exceptions  ************************** */

  /**
 * Fetches the drug site array for the study from the service
 * @param studyId The study id
 */
  public getDrugReorderExceptions(studyId: number): Observable<DrugReorderException[]> {
    // v1/studies/{studyId}/double-blind/reorder-exceptions
    return this.http.get<DrugReorderException[]>
      (this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/reorder-exceptions');
  }

  /**
   *
   * @param studyId The studyId to which it belongs
   * @param record The record to be inserted
   */
  public addReorderException(studyId: number, record: DrugReorderException): Observable<DrugReorderException> {
    // record.drugShipToLevelExceptionId = Math.floor(Math.random() * 1500) + 1;
    // record.tupleVersion = 1;
    // return of(record);
    //  studies/{studyId}/double-blind/reorder-exceptions
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/reorder-exceptions';
    return this.http.post<DrugReorderException>(myUrl, record);
  }
  /**
   * Updates the ReorderException object with the changes
   * @param studyId The studyId
   * @param reorderExceptionId The reorderExceptionId
   * @param tupleVersion The tupleVersion of this record
   */
  public deleteReorderException(studyId: number, reorderExceptionId: number, tupleVersion: number): Observable<any> {
    // return of(true);
    // api/v1/studies/{studyId}/double-blind/reorder-exceptions/{reorderExceptionId}
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/reorder-exceptions/' + reorderExceptionId;
    return this.http.delete(myUrl, { headers: header, responseType: 'text' });
  }

  /****** Reorder Exceptions  ************************** */

  /****** Kit Definitions   ************************** */

  /**
 * Fetches the randomized Kit definitions for the study from the service
 * @param studyId The study id
 */
  public getKitDefinitions(studyId: number): Observable<DrugKitDefinitionInfo> {
    return this.http.get<DrugKitDefinitionInfo>
      (this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/kit-definitions');
  }

  /**
   *
   * @param studyId The studyId to which it belongs
   * @param record The randomize criteria
   */
  public addRandomizeRequest(studyId: number, record: DrugKitDefinitionInfo): Observable<DrugKitDefinitionInfo> {
    //  let results: DrugKitDefinitionInfo = { "totalAccruals": 10, "maxKitsPerSubject": 10, "supplementalPercent": 10, "kits": [{ "caseKitOrderId": 11, "kitNumber": 1, "drugArmInfo": { "studySchemaEventId": 21, "studySchemaEventLabel": "Arm 1", "studySchemaEventDescription": "Drug 1" }, "studyArmDrugDefaultId": 1 }, { "caseKitOrderId": 12, "kitNumber": 2, "drugArmInfo": { "studySchemaEventId": 22, "studySchemaEventLabel": "Arm 2", "studySchemaEventDescription": "Drug 2" }, "studyArmDrugDefaultId": 2 }, { "caseKitOrderId": 13, "kitNumber": 3, "drugArmInfo": { "studySchemaEventId": 23, "studySchemaEventLabel": "Arm 3", "studySchemaEventDescription": "Placebo" }, "studyArmDrugDefaultId": 3 }, { "caseKitOrderId": 14, "kitNumber": 4, "drugArmInfo": { "studySchemaEventId": 24, "studySchemaEventLabel": "Arm 1", "studySchemaEventDescription": "Drug 1" }, "studyArmDrugDefaultId": 4 }, { "caseKitOrderId": 15, "kitNumber": 5, "drugArmInfo": { "studySchemaEventId": 25, "studySchemaEventLabel": "Arm 2", "studySchemaEventDescription": "Drug 2" }, "studyArmDrugDefaultId": 5 }, { "caseKitOrderId": 16, "kitNumber": 6, "drugArmInfo": { "studySchemaEventId": 26, "studySchemaEventLabel": "Arm 3", "studySchemaEventDescription": "Placebo" }, "studyArmDrugDefaultId": 6 }] }
    // return of(results);
    //    /studies/{studyId}/double-blind/kit-definitions
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/kit-definitions';
    return this.http.post<DrugKitDefinitionInfo>(myUrl, record);
  }

  /**
 *
 * @param studyId The studyId to which it belongs
 * @param numAdditionalKits number of kits to add
 */
  public addNewKitDefinitions(studyId: number, numAdditionalKits: number): Observable<DrugArmKitInfo[]> {
    const myUrl = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/kit-definitions/add-definitions?num-kits=' + numAdditionalKits;
    return this.http.put<DrugArmKitInfo[]>(myUrl, null);
  }


  /**
   * Returns the list of pharmacies set up in the db
   */
  public getPharmacyList(): Observable<DrugPharmacy[]> {
    const url = this.serviceUrlPrefix + 'double-blind/kit-definitions/pharmacies';
    return this.http.get<DrugPharmacy[]>(url);
  }


  /**
   * Sends the pharmacy selection to the service
   * @param studyId The Study id
   * @param studyPharmacy The study pharmacy to submit this to
   */
  public sendToPharmacy(studyId, studyPharmacy: StudyPharmacy): Observable<StudyPharmacy> {
    /// studies/{studyId}/double-blind/kit-definitions/send-to-pharmacy
    const url = this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/kit-definitions/send-to-pharmacy';
    return this.http.post<StudyPharmacy>(url, studyPharmacy);

  }

  /****** Kit Definitions  ************************** */


  /****** Kit Assignment  ************************** */


  /**
 * Gets the kit assignments for the study from the service
 * @param studyId The study id
 */
  public getKitAssignments(studyId: number): Observable<DrugKitAssignment[]> {
    return this.http.get<DrugKitAssignment[]>
      (this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/kit-assignments');
  }


  /**
 * Gets the kit assignments for the study from the service
 * @param studyId The study id
 */
  public getKitAssignmentSites(studyId: number): Observable<DrugSite[]> {
    return this.http.get<DrugSite[]>
      (this.serviceUrlPrefix + 'studies/' + studyId + '/double-blind/kit-assignments/sites');
  }


  /**
 * Updates the kit assignments for the study from the service
 * @param studyId The study id
 */
  public updateKitAssignment(studyId: number, record: DrugKitAssignment): Observable<DrugKitAssignment> {
    const url: string = this.serviceUrlPrefix + 'studies/' +
      studyId + '/double-blind/kit-assignments/' + record.caseKitOrderId;
    return this.http.put<DrugKitAssignment>(url, record);
  }

  /****** KitAssignment  ************************** */
  /****** MAnage Kit  ************************** */



  /**
    * Sends the patientId to the service for kit assignment
    * @param studyId The Study id
    * @param patientId The selected patient to assign it to
    */
  public requestKitForPatient(studyId: number, patientId: string): Observable<DrugKitAssignment[]> {
    const url = this.serviceUrlPrefix + 'studies/' + studyId +
      '/double-blind/manage-kits/request/patient/' + patientId;
    return this.http.put<DrugKitAssignment[]>(url, null);
  }

  /// studies/{studyId}/double-blind/manage-kits/revoke/kit/{kitNumber}
  /**
     * Revokes and assigned kit from the patient
     * @param studyId The Study id
     * @param kitNumber The selected kit number to revoke
     */
  public revokeKitAssignment(studyId: number, kitNumber: string): Observable<any> {
    const url = this.serviceUrlPrefix + 'studies/' + studyId +
      '/double-blind/manage-kits/revoke/kit/' + kitNumber;
    return this.http.put(url, null, { responseType: 'text' });
  }

  // assign a requested kit to requested patient
  //  /studies/{studyId}/double-blind/manage-kits/assign/patient/{patientId}/kit/{kitNumber}/order/{orderNumber}



  /**
   * Assigns a kit from an order to the patient
   * @param studyId The studyID
   * @param patientId The PatientId to assign the kit to
   * @param kitNumber The Kit number to assign
   * @param orderNumber The order number that holds the kit number
   */
  public assignKitForOrderAndPatient(studyId: number, patientId: string, kitNumber: number, orderNumber: number): Observable<DrugKitAssignment[]> {
    const url = this.serviceUrlPrefix + 'studies/' + studyId +
      '/double-blind/manage-kits/assign/patient/' + patientId +
      '/kit/' + kitNumber + '/order/' + orderNumber;
    return this.http.put<DrugKitAssignment[]>(url, null);
  }




  /****** Manage Kit  ************************** */

}
