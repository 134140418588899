import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Code } from 'app/common/model/code';
// import { ScrollerDirective } from 'app/common/directives/scroller/scroller.directive';
import { ListOptionModel } from 'app/common/model/list-option';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { Organization } from 'app/common/model/organization';
import { StudyCreate } from 'app/common/model/study-create';
import { ExampleModel } from 'app/common/model/study-create-example-model';
import { StudyReferenceNumSection } from 'app/common/model/study-create-reference-num-section';
import { SectionLabelOptionModel } from 'app/common/model/study-create-section-label-option-model';
import { SectionModel } from 'app/common/model/study-create-section-model';
import { StudyRecord } from 'app/common/model/study-record';
import { StudyTemplateMatch } from 'app/common/model/study-template-match';
import { StudyCreateService } from 'app/common/services/study-create.service';
import { Subscription } from 'rxjs';





@Component({
  selector: 'mc-type-protocol',
  templateUrl: './type-protocol.component.html',
  styleUrls: ['./type-protocol.component.scss']
})
export class TypeProtocolComponent implements OnInit, OnDestroy { 

  domains: Array<ListOptionModel>;

  currentYear: string;
  createdStudyId: number;
  protocolPrefix: string;
  exampleReference: string;
  referenceNumSections: Array<StudyReferenceNumSection>;

  selectedOrganization: Organization = null;
  selectedReferenceTypeId: number = null;
  researchEntityTypes: Code[];
  refList: ListOptionModel[];
  selectedSysGeneratedRefNum: string;
  showStudyTemplateQuestion: boolean = false;

  // @Input() studyTypeId : number;
  @Input() selectedStudyType: Code;
  @Output() resetSelectedStudyType = new EventEmitter<Code>();


  /**
   * Holds the list of study section definitions retrieved based on the selected data center.
   * Page is dynamically built based on the sections with a type of "List", using the sectionLabel
   * and the codeDesc in the list of options for each section.
   */
  studySections: SectionModel[];
  /**
   * A filtered list of studySections, containing only those of type List, to be displayed on the UI
   */
  listStudySections: SectionModel[];
  /**
   * An array to bind the "assign reference number" section selections which are dynamically built.
   * This is needed to keep the bindings separate from each other.
   */
  sectionSelectionModels: SectionLabelOptionModel [] = [];

  // showReferenceNumber: boolean = true;

  subscriptions: Subscription[] = [];
  /**
   * The model used to bind the UI elements to which is later submitted
   */
  model: StudyCreate;

  applyStudyTemplate: boolean = false;

  /**
   * The velos study response returned from the call to Velos
   */
  velosData: StudyRecord;

  /**
   * calls the emitter so that the parent component can reset the 'Study Type' field back to null (-- Select --)
   */
  onReset() {
    this.resetSelectedStudyType.emit(null);
  }

  constructor( @Inject(DOCUMENT) private document: any,
  private viewContainer: ViewContainerRef,
  private studyCreateService: StudyCreateService,
  private router: Router) { }

  ngOnInit() {
    this.initData();
    this.subscriptions.push(this.studyCreateService.getDomains()
      .subscribe(results => this.domains = results));

      this.subscriptions.push(this.studyCreateService.getSystemGeneratedRef()
      .subscribe(results => this.refList = results));

      this.subscriptions.push(this.studyCreateService.getResearchEntityTypes()
      .subscribe(results => this.researchEntityTypes = results));

    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear().toString().slice(2); // only the last two characters

  }


  /**
   * Initializes the model for the UI to bind to
   */
  initData() {
    this.model = this.studyCreateService.initializeBlankStudy(this.selectedStudyType.id, this.model?.studyNumber);
    this.studySections = null;
    this.listStudySections = null;
    this.applyStudyTemplate = false;
    this.showStudyTemplateQuestion = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subs => {
        subs.unsubscribe();
        subs.closed;
      }

    )
  }
  /**
   * This will fetch data(short desc and long desc) from Velos Api
   */
  getVelosData() {
    const studyNumberUpper = this.model.studyNumber.toUpperCase().trim();
    this.subscriptions.push(this.studyCreateService.getVelosDetails(studyNumberUpper,false)
      .subscribe(results => {
        this.velosData = results;
        this.model.longDescription = this.velosData.longDescription;
        this.model.shortDescription = this.velosData.shortDescription;
        this.model.acronym = this.velosData.acronym;
        this.model.dataCenterId = this.velosData.dataCenterId;
        this.model.dataCenter = this.velosData.dataCenter;
        this.model.sponsor = this.velosData.sponsor;
        this.model.sponsorId = this.velosData.sponsorId;
        this.model.randomCenter = this.velosData.randomCenter;
        this.model.randomCenterId = this.velosData.randomCenterId;
        const variableData: string = 'studyNumber:' + this.model.studyNumber;
        const modalArg = new ModalDialogArg('custom-save', 'VelosStudy', variableData);

        this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
          .subscribe(actionResult => {
            this.onDataCenterUpdate();
          });
      }, error => {

        if (error && error.error && error.error.code && error.error.code == 400) {
          const modalArg = new ModalDialogArg('modal-warn', 'Custom', error.error.message);
          this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
            .subscribe(actionResult => {
              this.initData();
            });
        } else {
        const modalArg = new ModalDialogArg('modal-warn', 'VelosStudy', null);
        this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
          .subscribe(actionResult => {
            this.initData();
          }); }
      })
    );
  }

  /**
   * This will get the list of questions and call the example endpoint.
   */
  onDataCenterUpdate() {
      this.model.assignmentGroupId = 0;
      this.model.referenceNumSections = null; // starting over with different data center
      this.exampleReference = '';
      this.subscriptions.push(this.studyCreateService.getListOfQuestions(this.model.dataCenterId, this.model.studyTypeId)
        .subscribe(results => {
          this.model.assignmentGroupId = results.assignmentGroupId;
          this.studySections = results.sections;
          this.filterListStudySections();
          if (this.model.assignmentGroupId > 0) {
            this.getProtocolNumberExample(this.model.assignmentGroupId , this.model.studyTypeId,  []);
          }
          this.selectedSysGeneratedRefNum = 'Yes';

        })
      );


  }

  /**
   * Filter out any non-List type sections from the list of studySections for this data center
   */
  filterListStudySections() {
    this.listStudySections = [];
    this.studySections.forEach(section => {
      if (section.sectionType.toUpperCase().trim() == 'LIST') {
         this.listStudySections.push(section);
      }
    });
  }

  /**
   * Generate example for protocol number based on the information selected
   * @param groupId - protocol numbering assignment group
   * @param selections - values selected
   */
  getProtocolNumberExample(groupId, refTypeId, selections) {
    this.subscriptions.push(this.studyCreateService.getProtocolNumberExample(groupId, refTypeId, null, selections)
    .subscribe(results => {
      this.exampleReference = results.protocolNumber;
    }));
  }

  /**
   * Map the selected value (bound in sectionLabelTemp) to the correct attribute in the study model
   */
  onSelectChange(section: SectionModel, index: number) {
    let existingRns = false;
    const selections = [];
    this.exampleReference = '';
    if (this.model.referenceNumSections != null) {
      this.model.referenceNumSections.forEach(rns => {
        if (rns.sequenceNumSectionId == section.sequenceNumSectionId) {
          existingRns = true;
          rns.codeDesc = this.sectionSelectionModels[index].codeDesc;
          rns.sectionDetailId = this.sectionSelectionModels[index].sectionDetailId;

        }
      });
    } else {
      this.model.referenceNumSections = [];
    }

    if (!existingRns) {
      const rns = new StudyReferenceNumSection(section.sequenceNumSectionId,
        this.sectionSelectionModels[index].sectionDetailId,
        section.sectionLabel,
        this.sectionSelectionModels[index].codeDesc);
      this.model.referenceNumSections.push(rns);

    }

    if (this.model.referenceNumSections != null) {
      this.model.referenceNumSections.forEach(rns => {
        const example: ExampleModel = { 'sequenceNumSectionId': null, 'sectionDetailId': null };
        example.sequenceNumSectionId = rns.sequenceNumSectionId;
        example.sectionDetailId = rns.sectionDetailId;
        selections.push(example);
      }
      );
      this.getProtocolNumberExample(this.model.assignmentGroupId, this.model.studyTypeId, selections)
    }
  }

  /**
   * This will check if the questions is empty or not
   * true, if list of question available else false
   */
  isStudySectionAvailable(): boolean {
    return this.listStudySections != null && this.listStudySections.length > 0;
  }

  /**
   * This will return true if study section is empty or selected system generated refNum is No
   *
   */
  isStudySectionEmpty(): boolean {
    return this.listStudySections.length == 0 || this.selectedSysGeneratedRefNum == 'No';
  }

  /**
   * true, if selected sys generated ref num is Yes
   */
  isSysGeneratedRefNumSelected(): boolean {
    return this.selectedSysGeneratedRefNum != 'No';
  }


  /**
   * Handler for the button to create a new study
   */
  createNewStudy() {
    // Call save service
    this.subscriptions.push(this.studyCreateService.createStudy(this.model)
      .subscribe(results1 => {
        this.createdStudyId = results1.studyId;

        // Now that the study is created, see if we should apply a study configuration template
        if (this.applyStudyTemplate == true) {
          this.subscriptions.push(this.studyCreateService.applyStudyTemplate(this.createdStudyId)
          .subscribe(templateResults => {
            // success - show modal dialog as before
            let templateMsg = " Applied ";
            const variableData: string = 'studyNumber:' + results1.protocolNumber + ' Created' + '|studyTemplate:' + templateMsg;
            // StudySaveConfirm
            const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
            this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
              .subscribe(actionResult1 => {
                if (actionResult1) {
                  this.router.navigate(['/admin/studies/' + this.createdStudyId + '/setup/protocol-info']);
                } else {
                  this.router.navigate(['/home']);
                }
              });
            }, error => {
            if (error && error.error && error.error.code && error.error.code == 400) {
              let templateMsg = " Apply Failed: " + error.error.message;
              const variableData: string = 'studyNumber:' + results1.protocolNumber + ' Created' + '|studyTemplate:' + templateMsg;
              const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
              this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
                .subscribe(actionResult2 => {
                  // this.initData();
                  if (actionResult2) {
                    this.router.navigate(['/admin/studies/' + this.createdStudyId + '/setup/protocol-info']);
                  } else {
                    this.router.navigate(['/home']);
                  }
                  });
            } else {
              let templateMsg = " Apply Failed";
              const variableData: string = 'studyNumber:' + results1.protocolNumber + ' Created' + '|studyTemplate:' + templateMsg;
              const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
              this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
                .subscribe(actionResult3 => {
                  if (actionResult3) {
                    this.router.navigate(['/admin/studies/' + this.createdStudyId + '/setup/protocol-info']);
                  } else {
                    this.router.navigate(['/home']);
                  }
                });
              }
          })
        );
        }
        else {
          //no template to apply, we are done

          const variableData: string = 'studyNumber:' + results1.protocolNumber;
          // StudySaveConfirm
          const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
          this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
            .subscribe(actionResult4 => {
              if (actionResult4) {
                this.router.navigate(['/admin/studies/' + this.createdStudyId + '/setup/protocol-info']);
              } else {
                this.router.navigate(['/home']);
              }
            });
          }
      }, error => {
        if (error && error.error && error.error.code && error.error.code == 400) {
          const modalArg = new ModalDialogArg('modal-warn', 'Custom', error.error.message);
          this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
            .subscribe(actionResult => {
              this.initData();
            });
        } else {
        const modalArg = new ModalDialogArg('modal-warn', 'CreateFailed', null);
        this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
          .subscribe(actionResult => {
            // do nothing
          });
        }
      })
    );
  }

  /**
   * handler for the cancel button. Resets all the information
   */
  cancelChanges(): void {
    const modalArg = new ModalDialogArg('modal-warn', 'Cancel', null);
    this.subscriptions.push(
      this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
        .subscribe(result => {
          if (result) {
            this.initData();
            this.onReset();
          }
        })
    )


  }

  checkForMatchingStudyTemplate() {
    if (this.model.studyTypeId > 0 && this.model.researchEntityId > 0) {
      this.subscriptions.push(
        this.studyCreateService.getMatchingStudyTemplate(this.selectedStudyType.id, this.model.researchEntityId)
          .subscribe(result => {
            if (result) {
              let templateMatch: StudyTemplateMatch = result;
              if (templateMatch.matchFound == true) {
                this.showStudyTemplateQuestion = true;
                this.applyStudyTemplate = true; // default to yes
              }
              else {
                this.showStudyTemplateQuestion = false;
                this.applyStudyTemplate = false;
              }
            }
            else { // no result
              this.showStudyTemplateQuestion = false;
              this.applyStudyTemplate = false;
            }
          })
      )
    }
    else {
      this.showStudyTemplateQuestion = false;
      this.applyStudyTemplate = false;
    }
  }
}
