import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AncillaryStudy } from 'app/common/model/ancillary-study';
import { AncillaryStudyDisplay } from 'app/common/model/ancillary-study-display';
import { ListOptionModel } from 'app/common/model/list-option';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { ExampleModel } from 'app/common/model/study-create-example-model';
import { StudyReferenceNumSection } from 'app/common/model/study-create-reference-num-section';
import { SectionLabelOptionModel } from 'app/common/model/study-create-section-label-option-model';
import { SectionModel } from 'app/common/model/study-create-section-model';
import { StudyCreateService } from 'app/common/services/study-create.service';
import { Subscription } from 'rxjs';
import { Code } from '../../../common/model/code';


@Component({
  selector: 'mc-type-ancillary',
  templateUrl: './type-ancillary.component.html',
  styleUrls: ['./type-ancillary.component.scss']
})
export class TypeAncillaryComponent implements OnInit {

  ancillaryStudyOptions: ListOptionModel[];
  subscriptions: Subscription[] = [];
  studySchemaId: number;
  ancillaryStudydisplay: AncillaryStudyDisplay;
  selectedAncillaryStudy: string;
  parentProtocol: string;
  referenceNumber: string;
  exampleReference: string;
  selectedCommitteeCode: SectionLabelOptionModel;
  createdStudyId = 0;

   referenceNumSections: StudyReferenceNumSection[] = [];

  /**
   * The studyCreate model used to create the ancillary study
   */
  studyModel: AncillaryStudy = null;

  @Input() studyTypeId: number;
  @Output() resetSelectedStudyType = new EventEmitter<Code>();

  /**
   * calls the emitter so that the parent component can reset the 'Study Type' field back to null (-- Select --)
   */
  onReset() {
    this.resetSelectedStudyType.emit(null);
  }

  constructor(@Inject(DOCUMENT) private document: any,
    private viewContainer: ViewContainerRef,
    private studyCreateService: StudyCreateService,
    private router: Router) { }

  ngOnInit() {
    this.setDefault();
    this.studyModel = this.studyCreateService.initializeBlankAncillaryStudy(this.studyTypeId);
    this.subscriptions.push(this.studyCreateService.getSystemGeneratedRef()
      .subscribe(results => this.ancillaryStudyOptions = results));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subs => {
        subs.unsubscribe();
        subs.closed;
      }
    )
  }

  /**
   * This will call for every select dropdown changed to get the Example protocol number.
   *
   * @param  {SectionModel} section- selected committee.
   */
  onSelectChange(section: SectionModel) {
    const selections = [];
    if(this.selectedCommitteeCode){
      const example: ExampleModel = { 'sequenceNumSectionId': null, 'sectionDetailId': null };
      example.sequenceNumSectionId = section.sequenceNumSectionId;
      example.sectionDetailId = this.selectedCommitteeCode.sectionDetailId;
      selections.push(example);

      const refNumSect: StudyReferenceNumSection  =  {
        'sequenceNumSectionId': example.sequenceNumSectionId,
        'sectionDetailId': example.sectionDetailId,
        'sectionLabel': '',
        'codeDesc': '' };

      if (this.referenceNumSections == null) {
        this.referenceNumSections = new Array<StudyReferenceNumSection>();
      } else {
        this.referenceNumSections.splice(0);
      }
      this.referenceNumSections.push(refNumSect);
    }else{
      this.referenceNumSections = null;
    }

    if (this.ancillaryStudydisplay.assignmentGroupId > 0) {
      this.getProtocolNumberExample(this.ancillaryStudydisplay.assignmentGroupId, this.studyTypeId, this.parentProtocol, selections);
    }

    this.studyModel.study.referenceNumSections = this.referenceNumSections;

  }
  /**
   * This will call the backend to fetch all the necessary data to populate on the Ancillary study.
   * Also call the protocolNumberExample endpoint to populate the default example.
   *
   */
  getAncillaryStudyData() {
    this.parentProtocol = this.parentProtocol.trim();
    this.subscriptions.push(this.studyCreateService.getAncillaryStudyDetails(this.parentProtocol)
      .subscribe(results => {
        this.ancillaryStudydisplay = results;
        this.studyModel.parentDataCenterId = this.ancillaryStudydisplay.parentDataCenterId;
        this.studyModel.parentProtocolNumber = this.ancillaryStudydisplay.parentProtocolNumber;
        this.studyModel.parentStudyId = this.ancillaryStudydisplay.parentStudyId;
        this.studyModel.study.shortDescription = this.ancillaryStudydisplay.shortTitle;
        this.studyModel.study.longDescription = this.ancillaryStudydisplay.studyTitle;
        this.studyModel.study.assignmentGroupId = this.ancillaryStudydisplay.assignmentGroupId;
        this.parentProtocol = this.ancillaryStudydisplay.parentProtocolNumber

        if (this.ancillaryStudydisplay.ancillaryCommittees == null) {
          this.selectedAncillaryStudy = 'No';
        } else {
          this.selectedAncillaryStudy = 'Yes';
        }
        this.getProtocolNumberExample(this.ancillaryStudydisplay.assignmentGroupId, this.studyTypeId, this.parentProtocol, []);
      }, error => {
        const modalArg = new ModalDialogArg('modal-warn', 'VelosStudy', null);
        this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
          .subscribe(actionResult => {
            this.setDefault();
          });


      })
    );
  }

  /**
 * Generate example for protocol number based on the information selected
 * @param groupId - protocol numbering assignment group
 * @param selections - values selected
 */
  getProtocolNumberExample(groupId, refTypeId, parentProtocol, selections) {
    this.subscriptions.push(this.studyCreateService.getProtocolNumberExample(groupId, refTypeId, parentProtocol, selections)
      .subscribe(results => {
        this.exampleReference = results.protocolNumber;
      }, error => {
        console.log('unable to create example refernce');
      }));
  }


  /**
   * True if the data is selected and completed for submission
   */
  isDataValidForSubmit(): boolean {
    // TODO: Add additional check for determine if data is valid for submission
    if (this.isParentProtocolEmpty()) {
      return false;
    }
    return true;
  }

  /**
   * Calls the service to create a new ancillary study
   */
  createNewStudy() {
    // Call save service
    this.subscriptions.push(this.studyCreateService.createAncillaryStudy(this.studyModel)
      .subscribe(results => {
        this.createdStudyId = results.studyId;
        const variableData: string = 'studyNumber:' + results.protocolNumber;
        // StudySaveConfirm
        const modalArg = new ModalDialogArg('custom-save', 'Save', variableData);
        this.subscriptions.push(this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
          .subscribe(actionResult => {
            if (actionResult) {
              // TODO: go to first study setup page for the the study (ie use createdStudyId)
              this.router.navigate(['/admin/studies/' + this.createdStudyId + '/setup/ancillary']);
            } else {
              this.router.navigate(['/home']);
            }
          }));
      }, error => {
        if(error && error.error && error.error.code && error.error.code == 400) {
          let modalArg = new ModalDialogArg("modal-warn", "Custom", error.error.message);
          this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
            .subscribe(actionResult => {
            });
        }else {
        let modalArg = new ModalDialogArg("modal-warn", "CreateFailed", null);
        this.subscriptions.push(this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
          .subscribe(actionResult => {
            // do nothing
          }));
        }
      })
    );

  }

  /**
   * Sets the ancillaryStudydisplay back to default values
   */
  public setDefault(): void {
    this.ancillaryStudydisplay = {
      'parentProtocolNumber': '',
      'studySchemaEnrollmentEvents': [
        {
          'studySchemaEventId': 0,
          'keyEventMappingId': 0,
          'studySchemaEventLabel': ''
        }
      ],
      'studyTitle': '',
      'shortTitle': '',
      'ancillaryCommittees': null,
      'assignmentGroupId': 0,
      'parentStudyId': 0,
       'parentDataCenterId': 0
    }
    this.referenceNumber = '';
  }

  /**
   * @returns boolean - return true if just committees are not empty.
   */
  public isOnlyCommitteesAvailable(): boolean {
    return this.ancillaryStudydisplay.ancillaryCommittees != null;
  }
  /**
   * @returns boolean - returns true if committees is not empty and system generated option selected as 'Yes'.
   */
  public isCommitteesAvailable(): boolean {
    return this.ancillaryStudydisplay.ancillaryCommittees != null && this.selectedAncillaryStudy == 'Yes';
  }
  /**
   * @returns boolean - return true if committees is empty or selected option is 'No'.
   */
  public isEmptyCommittesOrSelectedTypeAsNo(): boolean {
    return this.ancillaryStudydisplay.ancillaryCommittees == null || this.selectedAncillaryStudy == 'No';
  }
  /**
   * @returns boolean - return true if parent protocol number is empty.
   */
  isParentProtocolEmpty(): boolean {
    return this.parentProtocol === '' || this.parentProtocol === undefined;
  }
  /**
   * @returns boolean - return true if any selection is made on AncillaryStudy dropdown.
   */
  isAncillaryStudyOptionSelected(): boolean {
    return this.selectedAncillaryStudy !== undefined;
  }
  /**
   * if AncillaryStudy selection is 'No', then assign parent protocol to reference number.
   */
  checkSelectedSysGeneratedOption() {
    if (this.selectedAncillaryStudy == 'No') {
      this.referenceNumber = this.parentProtocol;
    }
  }

  /**
   * handler for the cancel button. Resets all the information
   */
  cancelChanges(): void {
    const modalArg = new ModalDialogArg('modal-warn', 'Cancel', null);
    this.subscriptions.push(
      this.studyCreateService.showModalDialog(this.viewContainer, modalArg)
        .subscribe(result => {
          if (result) {
            // In type-protocol.component an initData() is used.  If that is used in the future for creation, then this can be changed to use that too.
            this.setDefault();
            this.clearFields();
            this.onReset();
          }
        })
    )
  }

  /**
   * Clears variables
   */
  clearFields() {
    this.parentProtocol = '';
    this.studySchemaId = 0;
    this.selectedAncillaryStudy = '';
    this.referenceNumber = '';
    this.selectedCommitteeCode = undefined;
    this.ancillaryStudydisplay.shortTitle = '';
    this.ancillaryStudydisplay.studyTitle = '';
  }

}


