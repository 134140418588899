<div class="columns" *ngIf="searchCriteria">
  <div class="column">
    <section class="section" *ngIf="noResultsFound">
      <div class="container">
        <div class="subtitle has-text-centered">
          <h2 class="has-text-weight-bold">
            No results found. Please check your search criteria.
          </h2>
        </div>
      </div>
    </section>
    <div *ngIf="results && results.length > 0">
      <table class="table is-fullwidth is-striped ">
          <thead>
              <tr role="row" class="is-selected">
            <ng-container *ngFor="let column of columns">
              <th *ngIf="column.display" class="table-header" [mcTableSorting]="config" [column]="column" title="{{column.title}}"
                (sortChanged)="onChangeTable($event)" ngClass="{{column.className || ''}}">
                <span> {{column.title}}</span>
                <span *ngIf="config && column.sort != undefined" class="sort is-text-centered">
                  <span *ngIf="column.sort === ''">
                    <i class="fas fa-sort"></i>
                  </span>
                  <span *ngIf="column.sort === 'desc'">
                    <i class="fas fa-sort-down"></i>
                  </span>
                  <span *ngIf="column.sort === 'asc'">
                    <i class="fas fa-sort-up"></i>
                  </span>
                </span>
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="showFilterRow">
            <td *ngFor="let column of columns">
              <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
               [mcTableFiltering]="column.filtering"
                class="form-control" (tableChanged)="onChangeTable(config)" />
            </td>
          </tr>
          <ng-container *ngFor="let row of rows">
            <tr>
              <td>
                  <a (click)="goToProtocolInfoPage($event, row)">
                      <span class="has-text-weight-bold">
                       {{row.ancillaryStudyRef}}
                      </span>
                   </a>
              </td>
              <td>
                <span>{{row.relatedStudySchemaEventLabel}}</span>
              </td>
              <td>
                <span> {{row.status}} </span>
              </td>
              <td>
                <span>{{row.accrualCount}}</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  
  </div>
  
  
 
