import { Component, OnInit, Input } from '@angular/core';
import { FormVariable } from 'app/common/model/form-variable';

@Component({
  selector: 'mc-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit {


  @Input() formVariable: FormVariable

  constructor() { }

  ngOnInit() {
  }

}
