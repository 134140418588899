<div class="modal" [ngClass]="{'is-active': showModal}">
  <div class="modal-background">

  </div>
  <div class="modal-card" [ngClass]="modalBodyClass()">
    <div class="color-header button " [ngClass]="'is-'+ dialogArgType.dialogType">
      <button class="delete is-pulled-right is-hidden" (click)="action(false)">
      </button>
    </div>
    <div class="modal-card-body ">
      <!-- Warn pending Study Setup changes  -->
      <div *ngIf="isCancel()">
        <div class=" has-text-centered warn-title ">Cancel work in progress? </div>
        <div class="has-text-centered warn-message">
          You will lose unsaved work.
        </div>
        <div class="warn-action has-text-centered">
          <a class="button is-black is-medium is-radiusless" (click)="action(true)">
            <span class="icon">
              <i class="fas fa-check fa-xs"></i>
            </span>
            <span class="option-text">YES</span>
          </a>
          <div class="button-spacer"></div>
          <a class="button is-no is-medium is-radiusless" (click)="action(false)">
            <span class="icon">
              <i class="fas fa-times fa-xs"></i>
            </span>
            <span class="option-text">NO</span>
          </a>
        </div>
      </div>
      <!-- STudy Save Confirmation -->
      <div *ngIf="isSaveSuccess()">
        <div class=" has-text-centered warn-title ">Changes Saved </div>
        <div class="has-text-centered warn-message" *ngIf="hasVariableData()">
          <span *ngIf="variableData.studyId">
            Study Id: {{variableData.studyId}}
          </span>
          <span *ngIf="variableData.studyNumber">
            Study Number: {{variableData.studyNumber}}
          </span>
          <span class="warning-alert" *ngIf="variableData.warning">
            <br />Warning- {{variableData.warning}}
          </span>
          <span *ngIf="variableData.studyTemplate">
            <br />Study Template {{variableData.studyTemplate}}
          </span>
        </div>
        <div class="warn-action has-text-centered">
          <div>
            <a class="button is-no button-wide is-radiusless" (click)="action(true)">
              <span class="icon">
                <i class="fas fa-check"></i>
              </span>
              <span *ngIf="variableData.continueLabel">
                <span>{{variableData.continueLabel}}</span>

              </span>
              <span *ngIf="!variableData.continueLabel">
                Continue Setup
              </span>
            </a>
          </div>
          <div class="vertical-spacer">
          </div>
          <div>
            <a class="button is-no button-wide is-radiusless" (click)="action(false)">
              <span class="icon">
                <i class="fas fa-arrow-left"></i>
              </span>
              <span> {{dialogArgType.displayName}} </span>

            </a>
          </div>
          <div *ngIf="redcapStudy !== null" class="redcap-valid">

            <table class="redcap-table">
              <ng-container *ngIf="redcapStudy.tokenValid else invalid">
                <tr>
                  <td class="label">Environment:</td>
                  <td>{{ redcapStudy.tokenEnvMismatch && redcapStudy.tokenValid ? redcapStudy.tokenEnvMismatchMessage : (redcapStudy.internal ? "Internal" : "External") }}</td>
                </tr>
                
                <tr>
                  <td class="label">REDCap Status:</td>
                  <td>{{ redcapStudy.in_production === 0 ? "Non-Production" : "Production" }}</td>
                </tr>
                
                <tr>
                  <td class="label">IRB Number:</td>
                  <td>{{redcapStudy.project_irb_number}}</td>
                </tr>
                
                <tr>
                  <td class="label">Protocol Number:</td>
                  <td>{{ redcapStudy.tokenValid === true && redcapStudy.irbMatching ? redcapStudy.protocolNumber : "No corresponding Protocol Number found" }}</td>
                </tr>
              </ng-container>
            
              <ng-template #invalid>
                <tr>
                  <td class="label">{{ redcapStudy.tokenInvalidMessage }}</td>
                </tr>
              </ng-template>
            </table>
            

          </div>
        </div>
      </div>

      <!-- STudy Create Failed -->
      <div *ngIf="isCreateFailed()">
        <div class=" has-text-centered warn-title ">Create Failed </div>
        <div class="warn-action has-text-centered">
          <div class="warn-action has-text-centered">
            <a class="button is-no is-medium is-radiusless" (click)="action(true)">
              <span class="icon">
                <i class="fas fa-check"></i>
              </span>
              <span>OK</span>
            </a>
            <div class="button-spacer"></div>
          </div>
        </div>
      </div>
      <!-- Velos Study Check  -->
      <div *ngIf="isVelosStudy()">
        <div class="has-text-centered warn-message">
          <span *ngIf="!hasVariableData()">Study Not </span>
          <span *ngIf="variableData.studyNumber">
            Velos Study
            {{variableData.studyNumber}}
          </span>
          Found.
        </div>
        <div class="warn-action has-text-centered">
          <a class="button is-no is-medium is-radiusless" (click)="action(true)">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
            <span>OK</span>
          </a>
          <div class="button-spacer"></div>

        </div>
      </div>
      <!-- Custom Message Box  -->
      <div *ngIf="isCustom()">
        <div class="has-text-centered warn-message">
          <span *ngIf="hasVariableData">
            {{dialogArgType.variableData}}
          </span>
          <span *ngIf="dialogArgType.displayMessage">
            {{dialogArgType.displayMessage}}
          </span>
        </div>
        <div class="warn-action has-text-centered">
          <a class="button is-no is-medium is-radiusless" (click)="action(true)">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
            <span>OK</span>
          </a>
          <div class="button-spacer"></div>

        </div>
      </div>


      <!-- Warn before Deletion  -->
      <div *ngIf="isDelete()">
        <div class=" has-text-centered warn-title ">
          Confirm delete? </div>
        <div class="has-text-centered warn-message">
          Data will not be recoverable.
        </div>
        <div class="warn-action has-text-centered">
          <a class="button is-black is-medium is-radiusless" (click)="action(true)">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
            <span>Yes</span>
          </a>
          <div class="button-spacer"></div>
          <a class="button is-no is-medium is-radiusless" (click)="action(false)">
            <span class="icon">
              <i class="fas fa-times"></i>
            </span>
            <span>No</span>
          </a>
        </div>
      </div>




      <!-- Generic Web Service Exception  -->
      <div *ngIf="isGeneralException()">
        <div class="has-text-centered error-message">
          <span>An exception occurred</span>
          <span *ngIf="hasVariableData()">
            :
            <br />
            {{variableData.error}}
          </span>

        </div>
        <div class="warn-action has-text-centered">
          <a class="button is-no is-medium is-radiusless" (click)="action(true)">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
            <span>OK</span>
          </a>
          <div class="button-spacer"></div>
        </div>
      </div>

      <!-- Generic Cannot Navigate Notification -->
      <div *ngIf="isCannotNavigate()">
        <div class="has-text-centered warn-message">
          <span *ngIf="!dialogArgType.displayMessage">
            Sorry you cannot perform this action.
          </span>
          <span *ngIf="dialogArgType.displayMessage">
            {{dialogArgType.displayMessage}}
          </span>
        </div>
        <div class="warn-action has-text-centered">
          <a class="button is-no is-medium is-radiusless" (click)="action(true)">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
            <span>OK</span>
          </a>
          <div class="button-spacer"></div>
        </div>
      </div>

      <!-- Generic Cannot Navigate Notification with html tags-->
      <div *ngIf="isCannotNavigateWithHtml()">
        <div class="warn-message-other">
          <span *ngIf="!dialogArgType.displayMessage">
            Sorry you cannot perform this action.
          </span>
          <span *ngIf="dialogArgType.displayMessage">
            <div [innerHTML]="dialogArgType.displayMessage"> </div>
          </span>
        </div>
        <div class="warn-action has-text-centered">
          <a class="button is-no is-medium is-radiusless" (click)="action(true)">
            <span class="icon">
              <i class="fas fa-check"></i>
            </span>
            <span>OK</span>
          </a>
          <div class="button-spacer"></div>
        </div>
      </div>


      <!-- Generic Save Confirmation -->
      <div *ngIf="isGenericSaveSuccess()">
        <div class=" has-text-centered warn-title ">Changes Saved </div>
        <div class="has-text-centered warn-message">
          <span>
            {{dialogArgType.displayMessage}}
          </span>
        </div>
        <div class="warn-action has-text-centered">
          <div *ngIf="variableData.continueLabel">
            <a class="button is-no button-wide is-radiusless" (click)="action(true)">
              <span class="icon">
                <i class="fas fa-check"></i>
              </span>
              <span> {{variableData.continueLabel}}</span>
            </a>
          </div>
          <div class="vertical-spacer" *ngIf="variableData.returnToStart">
          </div>
          <div *ngIf="variableData.returnToStart">
            <a class="button is-no button-wide is-radiusless" (click)="action(false)">
              <span class="icon">
                <i class="fas fa-arrow-left"></i>
              </span>
              <span> {{variableData.returnToStart}} </span>
            </a>
          </div>
        </div>
      </div>

      <!-- Generic Warn for impact -->
      <div *ngIf="isGenericYesNoWarn()">
        <div class=" has-text-centered warn-title ">
          <span>
            {{dialogArgType.displayMessage}}
          </span>
        </div>
        <div class="has-text-centered warn-message">
          <span> {{variableData.genericMessage}}</span>
        </div>
        <div class="warn-action has-text-centered">
          <a class="button is-black is-medium is-radiusless" (click)="action(true)">
            <span class="icon">
              <i class="fas fa-check fa-xs"></i>
            </span>
            <span class="option-text">YES</span>
          </a>
          <div class="button-spacer"></div>
          <a class="button is-no is-medium is-radiusless" (click)="action(false)">
            <span class="icon">
              <i class="fas fa-times fa-xs"></i>
            </span>
            <span class="option-text">NO</span>
          </a>
        </div>
      </div>





    </div>
  </div>
</div>