import { Component, Input, OnInit } from '@angular/core';
import { PostProcessingHistoryRecord } from 'app/common/model/post-processing-history-record';

@Component({
  selector: 'mc-post-processing-history-preview',
  templateUrl: './post-processing-history-preview.component.html',
  styleUrls: ['./post-processing-history-preview.component.scss']
})



export class PostProcessingHistoryPreviewComponent implements OnInit {
  @Input() postProcessingHistoryRecords:PostProcessingHistoryRecord[] ;
  
  rows: PostProcessingHistoryRecord[];
  
  public columns: Array<any> = [
    {
      title: 'Group', name: 'raveGroup', 
      display: true,
      filtering: false
    },
    {
      title: 'Attribute', name: 'attribute', 
      display: true,
      filtering: false
    },
    {
      title: 'Action', name: 'action', 
      display: true,
      filtering: false
    },
    {
      title: 'Status', name: 'status', 
      display:  true,
      filtering: false
    },
    {
      title: 'Error Message', name: 'errorMessage', 
      display: true,
      filtering: false
    },
    {
      title: 'Form Name', name: 'raveFormName', 
      display: true,
      filtering: false
    },
    {
      title: 'Datetime', name: 'dateTime', 
      sort: '', 
      display: true,
      filtering: false
    }
  ];


  /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: false,
    className: ['is-striped', 'is-bordered']
  };
  constructor() { }

  ngOnInit(): void {
    
  }

  

  isRave():boolean {
    if (this.postProcessingHistoryRecords && this.postProcessingHistoryRecords.length > 0) {
      if (this.postProcessingHistoryRecords[0].attribute ==='Rave') {
          return true;
      }
    }
    return false;
  }

   /**
 * Sorts the data in the table based on the column and sort order
 * @param data The data currently being displayed on the table
 * @param config The associated configuration for the data being displayed
 */
   public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (const column of columns) {
      if (column.sort !== '' && column.sort !== false) {
        columnName = column.name;
        sort = column.sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousValue :any;
      let currentValue :any;
      if (columnName === 'dateTime') {     
        previousValue = previous[columnName] ? new Date(previous[columnName]).getTime() : new Date(0).getTime();
        currentValue = current[columnName] ? new Date(current[columnName]).getTime() : new Date(0).getTime();
      } else {
        previousValue = previous[columnName] ? previous[columnName].toLowerCase() : '';
        currentValue = current[columnName] ? current[columnName].toLowerCase() : '';
      }

      if (previousValue > currentValue) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousValue < currentValue) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
  * Main method to handle filtering, sorting of the table
  * @param config Configuration of the datatable
  * @param page The current page being displayed
  */
  public onChangeTable(config: any): any {
    let column: any = null;
    this.rows = [];
    if (config.title !== undefined && config.name !== undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
 
    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    
    const sortedData = this.changeSort(this.postProcessingHistoryRecords, this.config);
    this.rows = sortedData;

  }

 

}
