import { Code } from "./code";

export class StudyNetworkReportCriteria {
    constructor(
        public researchEntities: Code[],
        public studyStatuses: Code[],
        public participatingOrgs: Code[],
        public participatingOrgStatuses: Code[],
        public researchEntitiesTooltipOpen: boolean = false,
        public studyStatusesTooltipOpen: boolean = false,
        public participatingOrgsTooltipOpen: boolean = false,
        public participatingOrgStatusesTooltipOpen: boolean = false
    ) { }
}
