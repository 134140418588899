export class MedDraOptionModel {
    versionId: number;
    version: string;
    constructor(versionId: number, version: string) {
        this.versionId = versionId;
        this.version = version;
    }
}
// export class NameSettingOptionModel {
//     demographicId: number;
//     name: string;
//     constructor(demographicId: number, name: string) {
//         this.demographicId = demographicId;
//         this.name = name;
//     }
// }
