import { ComponentFactoryResolver, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { DatePickerComponent } from 'app/common/directives/date-picker/date-picker.component';
import { CalendarOptions } from 'app/common/model/calendar-options';
import { Helper } from 'app/common/services/helper.service';

/**
 * Enums to define the key codes for the key pressed
 */
enum Key {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Escape = 27,
  ArrowLeft = 37,
  ArrowRight = 39,
  ArrowUp = 38,
  ArrowDown = 40
}


@Directive({
  selector: 'input[mcDatePicker]'
})
export class DatePickerDirective implements OnInit {
  /**
   * The input date which should be displayed
   */
  @Input() inputDate: any = null;
  /**
   * the event passing the selected date
   */
  @Output() dateSelected = new EventEmitter<any>();



  /**
   * Instance of the datepicker component initialized
   */
  datePickerInstance: DatePickerComponent = null;

  /**
   * determines if the datepicker ui is open or closed
   */
  isDatePickerOpen = false;

  dtPicker: any;
  options: CalendarOptions = {
    startDate: new Date(),
    dataFormat: 'mm/dd/yyyy',
    closeOnSelect: true,
    parentComponent: this,
    // callback function
    onSelect: this.selectDate,
    onOpen: this.setOpenDate,
    onClose: this.closeComponent
  };

  private el: HTMLElement;

  private visibleDisplay: string;



  /**
   * Listens for click on the input box to initialize the datepicker
   * @param  handler The handler for the click event
   */
  @HostListener('click', ['$event']) onClick($event) {
    console.log(' CLICK EVent on host');
    this.isDatePickerOpen = true; // !this.isDatePickerOpen;
    this.openDatePicker();
  }

  /**
   * Listener for the click on the document to close the calendar ui
   */
  @HostListener('document:click')
  clickout() {
    if (!this.dtElement.nativeElement.contains(event.target) &&
      this.isDatePickerOpen &&
      this.datePickerInstance != null &&
      !this.datePickerInstance.calendarContainerElement.nativeElement.contains(event.target)) {
      this.closeDatePicker();
    }
  }

  /**
   * Initializes the components if the enter key is pressed and closes it if the esc key is pressed
   * @param event the event for keydown
   */
  @HostListener('keydown', ['$event'])
  handleEnterEsc(event: KeyboardEvent) {
    if (event.keyCode === Key.Enter) {
      this.openDatePicker();
      event.preventDefault();
    }
    if (event.keyCode === Key.Escape) {
      this.closeDatePicker();
      event.preventDefault();
    }
  }

  constructor(private dtElement: ElementRef,
    private helper: Helper,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.el = dtElement.nativeElement;
    if (this.el && this.el.style) {
      this.visibleDisplay = this.el.style.display;
    }
  //
  }

  ngOnInit() {
    this.setVisibility(false);
    this.openDatePicker();
  }

  setVisibility(isVisible: boolean) {
    if (this.el && this.el.style) {
      if (!isVisible) {
        this.el.style.display = 'none';
      } else {
        this.el.style.display = '';
      }
    }
  }

  ngAfterViewInit() {

  // this.openDatePicker();
  }

  /**
   * Handler called when the datepicker object is opened
   * @param dtPickerObject The datepicker element
   */
  setOpenDate(dtPickerObject) {
    if (dtPickerObject.element.value) {
      dtPickerObject.options.startDate = new Date(dtPickerObject.element.value);
    } else {
      dtPickerObject.options.startDate = new Date();
    }
  }

  /**
   * The function passed to the datepicker component as the function to be called when it closes
   * Since we lose the reference to this directive when the function is called, we are passing
   * a reference that can be used to close
   * @param parentComponent The parent (this component)object
   */
  closeComponent(parentComponent: DatePickerDirective) {
    parentComponent.datePickerInstance = null;
    this.setVisibility(true)
  }

  /**
   * Handler called when the date is picked from the calendar ui
   * @param selectedDate The date selected from the component
   * @param parentComponent A reference to this (directive) object
   */
  selectDate(selectedDate: any, parentComponent: any) {
    this.inputDate = selectedDate;
    parentComponent.fireDateUpdatedEvent(selectedDate);
    this.inputDate = (selectedDate);
  }


  /**
   * Fires the event to inform the host and return the selected date
   * @param newDate Selected date from the datepicker
   */
  fireDateUpdatedEvent(newDate: any) {
    this.dateSelected.emit(newDate);
  }

  /**
   * Helper function to close the datepicker component
   */
  closeDatePicker() {
    if (this.datePickerInstance) {
      this.datePickerInstance.isOpen = false;
      this.datePickerInstance = null;
      this.setVisibility(true)
    }
  }

  /**
   * Helper function to initialize and open the datepicker component
   * The component is created using the ComponentFactoryResolver
   */
  openDatePicker() {
    this.setVisibility(false);
    const dtValue = this.inputDate; // this.dtElement.nativeElement.value;
    if (dtValue != null && dtValue > 0) {
      this.options.startDate = new Date(dtValue);
    } else {
      this.options.startDate = new Date();
    }
    if (this.datePickerInstance == null) {
      const componentRef = this.viewContainerRef.createComponent(DatePickerComponent);
      this.datePickerInstance = componentRef.instance;
    }
    this.datePickerInstance.setHostElement(this.dtElement);

   // this.datePickerInstance.options = this.options;
    console.log('SETTING THE DAte ', dtValue, this.options.startDate)
    this.datePickerInstance.isOpen = this.isDatePickerOpen;
  }

}
