<ng-container>
  <div *ngIf="hasError()" class="has-error-indicator">
    <a (click)="showError($event)" class="has-show-link">
      <span class="error-indicator ">
        <i class="fas fa-minus-square"></i>
      </span>
    </a>
  </div>

  <div class="message event-error is-danger" *ngIf="shouldDisplayError()">
    <div class="message-body ">
         <div class=" has-delete-icon">
        <button class="delete" aria-label="delete" (click)="hideError()"></button>
      </div>
      <div>
        <ul class=" has-text-left">
          <li *ngFor="let msg of getErrors()">
            <span>{{msg}} </span>
          </li>
        </ul>
      </div>
   
    </div>
  </div>
</ng-container>