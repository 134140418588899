import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import{SharedModule} from '../../shared.module';
import { McTableComponent } from './mc-table/mc-table.component';
import { McTableFilteringDirective } from './mc-table-filtering.directive';
import { McTableSortingDirective } from './mc-table-sorting.directive';
import { McTablePagingDirective } from './mc-table-paging.directive';
import { SafeHtmlModule } from 'app/common/pipes/safe-html.pipe.module';

@NgModule({
  imports: [
    CommonModule,
    SafeHtmlModule
    //  SharedModule
  ],
  declarations: [
    McTableComponent,
    McTableFilteringDirective,
    McTableSortingDirective,
    McTablePagingDirective],
  exports: [
     McTableComponent ,
    McTableFilteringDirective,
    McTableSortingDirective,
    McTablePagingDirective,

  ]
})
export class McTableModule { }
