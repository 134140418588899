import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderBar } from 'app/common/model/header-bar';
import { HeaderBarComponent } from 'app/common/header-bar/header-bar.component';
import { Subscription } from 'rxjs';
import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { NotificationCode } from 'app/common/model/notification-code';
import { NotificationSearchCriteria } from 'app/common/model/notification-search-criteria';
import { PreviewPanelModel } from 'app/common/model/preview-panel-model';
import { EmailModalComponent } from 'app/common/modal/email-modal/email-modal.component';
import { NotificationTileDetail } from 'app/common/model/notification-tile-detail';
import { DateRange } from 'app/common/model/date-range';
import { DaterangePickerWrapperComponent } from 'app/common/daterange-picker-wrapper/daterange-picker-wrapper.component';

@Component({
  selector: 'mc-notificaition-search',
  templateUrl: './notificaition-search.component.html',
  styleUrls: ['./notificaition-search.component.scss']
})
export class NotificaitionSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
 * Reference to the child modal component for displaying notification email
 */
  @ViewChild(EmailModalComponent) private modalComponent: EmailModalComponent;

  /**
* Reference to the child header component
*/
  @ViewChild(HeaderBarComponent)
  private headerBar: HeaderBarComponent;

  /**
   * Reference to child DaterangePickerWrapperComponent
   */
  @ViewChild(DaterangePickerWrapperComponent) private daterangePickerWrapperComponent: DaterangePickerWrapperComponent;

  subscriptions: Subscription[] = [];

  /**
 * Model to drive the orientation and horizontal/vertical states of the preview window
 */
  previewModelHome: PreviewPanelModel = new PreviewPanelModel();

  previewTitle = 'Notifications Search Results';

  /**
  * model for the header bar
  */
  headerModel: HeaderBar;

  notificationTypes: NotificationCode[] = [];


  notificationSearchCriteria: NotificationSearchCriteria = new NotificationSearchCriteria("", "", null, "", new DateRange(null, null), "");//={ sponsorProtocolNumber:null, subjectId:null, notificationType:null, notificationName: null, dateRange:null}



  constructor(
    private notificationDashboardService: NotificationDashboardService
  ) { }

  ngOnInit() {
    this.headerModel = new HeaderBar('Notification Search', '', false, '', false);

    this.subscriptions.push(this.notificationDashboardService.getNotificationTypes("all")
      .subscribe(results => this.notificationTypes = results));

  }

  ngAfterViewInit() {
    this.headerBar.initHeader();
  }


  /**
 * Destroy implementation - closes all the subscriptions
 */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    )
  }


  clear() {
    this.notificationSearchCriteria.sponsorProtocolNumber = "";
    this.notificationSearchCriteria.subjectId = "";
    this.notificationSearchCriteria.notificationType = null;
    this.notificationSearchCriteria.notificationName = "";
    this.notificationSearchCriteria.dateRange = new DateRange(null, null);
    this.daterangePickerWrapperComponent.modelStartDate = null;
    this.daterangePickerWrapperComponent.modelEndDate = null;
    this.notificationSearchCriteria.recipient = "";

  }

  /**
 * event captured from the preview pane selection and calling modal component with the selected id to populate data.
 * @param  selection
 */
  onEmailDetailSelected(selection: NotificationTileDetail) {
    this.modalComponent.showModal = true;
    this.modalComponent.getEmail(selection.emailLogId);
  }

  searchNotifications() {
    this.previewModelHome.isCollapsed = false;
    this.notificationDashboardService.setNotificationSearchData(this.notificationSearchCriteria);
  }

  geNotificaitonType(notificaitonType: NotificationCode): string {
    if (notificaitonType.level === "Study" || notificaitonType.level === "None") {
      return notificaitonType.value + " (Study)";
    } else if (notificaitonType.level === "Group") {
      return notificaitonType.value + " (Study)";
    } else {
      return notificaitonType.value;
    }
  }

  isSearchDisable(): boolean {
    if (this.notificationSearchCriteria.sponsorProtocolNumber.trim() !== ""
      || this.notificationSearchCriteria.subjectId.trim() !== ""
      || this.notificationSearchCriteria.notificationType != null
      || this.notificationSearchCriteria.notificationName.trim() !== ""
      || (this.notificationSearchCriteria.dateRange.startDate != null && this.notificationSearchCriteria.dateRange.endDate != null
        || this.notificationSearchCriteria.recipient.trim() !== "")) {
      return false;
    }
    return true;
  }

  /**
   * set start date
   * @param date 
   */
  setStartDate(date) {
    this.notificationSearchCriteria.dateRange.startDate = date;
  }

  /**
   * set end date
   * @param date 
   */
  setEndDate(date) {
    this.notificationSearchCriteria.dateRange.endDate = date;
  }

  /** custom handling of the preview for the home page********************* */
  /**
     * True if the orientation is vertical and the state is collapsed
     */
  isCollapsedVertical(): boolean {
    return this.previewModelHome.isCollapsed && this.previewModelHome.isVertical;
  }



  /**
   * True if the orientation is horizontal and is collapsed
   */
  isCollapsedHorizontal(): boolean {
    return (this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }


  /**
   * True if the orientation is vertical and the state is Expanded
   */
  isExpandedVertical(): boolean {
    return (!this.previewModelHome.isCollapsed && this.previewModelHome.isVertical);
  }



  /**
   * True if the orientation is horizontal and is expanded
   */
  isExpandedHorizontal(): boolean {
    return (!this.previewModelHome.isCollapsed && !this.previewModelHome.isVertical);
  }
}
