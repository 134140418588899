import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AppConfigService } from 'app/app-config/app-config.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function msalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect
  };
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: AppConfigService.runConfig.msalConfig.protectedResourceMap
  };
}

export function msalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: AppConfigService.runConfig.msalConfig.clientId,
      authority: AppConfigService.runConfig.msalConfig.authority,
      knownAuthorities: AppConfigService.runConfig.msalConfig.knownAuthorities,
      redirectUri: AppConfigService.runConfig.msalConfig.redirectUri,
      postLogoutRedirectUri: AppConfigService.runConfig.msalConfig.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: AppConfigService.runConfig.msalConfig.cacheLocation,
      storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    },
  });
}

@NgModule({
  providers: [],
  imports: [MsalModule]
})
export class MsalApplicationModule {
  static forRoot() {
    return {
      ngModule: MsalApplicationModule,
      providers: [
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: msalGuardConfigFactory
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: msalInstanceFactory
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: msalInterceptorConfigFactory
        },
        MsalService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
      ]
    };
  }
}
