import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class Helper {

    public convertDateToLocalZone(milliSeconds: string): string {
        // return moment.utc(milliSeconds).local().format("YYYY-MM-DD hh:mm");
        if (milliSeconds == null) {
            return '';
        }
        return moment.utc(milliSeconds).local().format('MM/DD/YYYY');
        // return moment.utc(milliSeconds).local().format("YYYY-MM-DD hh:mm:ss");
    }

    /**
     * Checks the given variable to see if it has a value
     * @param variable Variable to be tested
     * @returns True if the variable has a value
     */
    public variableHasValue(variable: any): boolean {
        if (typeof variable !== 'undefined' && variable != null && typeof variable !== 'object' && variable.toString().length > 0) {
            return true;
        }
        if (typeof variable === 'object' && variable != null && Object.keys(variable).length === 0) {
            return false;
        }
        if (typeof variable === 'undefined' || variable == null || variable.length === 0) {
            return false;
        }
        return true;
    }
}
