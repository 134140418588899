export class PatientTransfer {
    constructor(
        public caseEventId: number | null,
        public caseTransferId: number | null,
        public initials: string,
        public eventName: string,
        public siteName: string,
        public status: string,
        public statusDate: Date | null,
        public transferReason: string,
        public modifiedTs: string,
        public modifiedById: string,
        public organizations: TransferOrganization[],
        public staff: Staff[]
    ) { }
}


export class TransferOrganization {
    constructor(
        public name: string,
        public ctepId: string,
        public siteLevel: string,
        public siteSeq: number
    ) { }
}

export class Staff {
    constructor(
        public name: string,
        public type: string,
        public email: string,
    ) { }
}