/**
 * This model is intended to hold properties that we need to pass
 * as input parameters to a child component
*/
export class ComponentInputModel {
    constructor(
        /**
         * Property to toggle hiding and showing of a modal
         */
         public showModal: boolean
    ) {}
}
