<div class="">
  <div class=" recipient-form" *ngIf="currentNotification()">
    <div class="page-header">
      <div class="columns">
        <div class="column is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd  ">
          <h3>Recipients for
             <span *ngIf="currentNotification().notificationType">{{currentNotification().notificationType.value}}</span>
              notification
          </h3>
        </div>
        <div class="column">
          <div class="columns is-paddingless">
            <div class="column is-paddingless has-add-buttons">
              <div class=" top-add-buttons is-pulled-right has-add-buttons" [ngClass]="{'is-hidden' : !showAddButtonDiv }">
                <button [disabled]="isEditingDisabled()" class="button is-radiusless is-add-role" (click)="addRole()">+ role</button>
                <button [disabled]="isGroupNav || !isEditable" class="button is-radiusless is-add-person" (click)="addPerson()">+ person </button>
                <button [disabled] ="isEditingDisabled()" class="button is-radiusless is-add-shared" (click)="addSharedEmail()">+ shared email </button>
                <button [disabled] ="isEditingDisabled()" class="button is-radiusless is-add-person-type" (click)="addPersonType()">+ reg person type </button>
              </div>
            </div>
            <div class="column is-paddingless  has-edit-bar is-one-half-tablet is-one-half-desktop is-one-half-widescreen is-5-fullhd ">
              <mc-edit-bar [selectionCount]="selectedIds.length" [actionSetting]="actionSetting" (selectedActionEvent)="selectedEditAction($event)">
              </mc-edit-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns"></div>

    <div class="recipient-list">
      <table class="table is-fullwidth " role="table">
        <thead>
          <tr role="row">
            <th class="has-text-centered">
                <span>                 
              </span>
              </th>
            <ng-container *ngFor="let column of columns">
              <th *ngIf="column.display" class="table-header"
              [mcTableSorting]="config" [column]="column" title="{{column.title}}"
                  (sortChanged)="onChangeTable($event)"
                ngClass="{{column.className || ''}}">
                <span> {{column.title}}</span>
                <span *ngIf="config && rows.length > 0 && column.sort != undefined" class="sort  is-text-centered">
                  <span *ngIf="column.sort === ''">
                    <i class="fas fa-sort"></i>
                  </span>
                  <span *ngIf="column.sort === 'desc'">
                    <i class="fas fa-sort-down"></i>
                  </span>
                  <span *ngIf="column.sort === 'asc'">
                    <i class="fas fa-sort-up"></i>
                  </span>
                </span>


              </th>
            </ng-container>
            </tr>
        </thead>
        <tbody>
        <tr *ngIf="showFilterRow">
            <td *ngFor="let column of columns; let i=index">
              <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}" [mcTableFiltering]="column.filtering" class="form-control"
                (tableChanged)="onChangeTable(config)" />
            </td>
          </tr>

          <tr role="row" *ngFor="let row  of rows; let rowIdx = index"
                    [ngClass]="{'is-selected' : isRowSelected(row)}">
            <td class="has-text-centered">
                <a (click)="cellClick($event, row, 'select')"[ngClass] ="{'is-disabled-link': isGroupNav || !isEditable}" >
                  <span class="icon is-small">
                    <span *ngIf="!isRowSelected(row)">                          
                        <i class="far fa-square"></i>
                      </span>
                      <span *ngIf="isRowSelected(row)">                          
                        <i class="far fa-check-square"></i>
                      </span>
                  </span>
                </a>  
            </td>
            
            <td role="cell">
              <div *ngIf="isRoleType(row.notificationDistributionType)">
                <select 
                [(ngModel)]="row.role" [compareWith]="compareRoleFn"
                (change)="roleUpdated()" [disabled] ="!isEditMode(row)">
                  <option [ngValue]="newNotificationCode" selected>-- Select --</option>
                  <option *ngFor="let role of roles" [ngValue]="role">
                    {{role.desc}}
                  </option>
                </select>
              </div>
              <div *ngIf="isSharedEmailType(row.notificationDistributionType)">
                <select [(ngModel)]="row.sharedEmail" [compareWith]="compareRoleFn" [disabled] ="!isEditMode(row)">
                  <option [ngValue]="undefined" selected>-- Select --</option>
                  <option *ngFor="let eachItem of sharedEmails" [ngValue]="eachItem">
                    {{eachItem.desc}}
                  </option>
                </select>
              </div>
              <div *ngIf="isPersonType(row.notificationDistributionType)">
                <select [(ngModel)]="row.personType" [compareWith]="compareRoleFn" (change)="personTypeUpdated()" [disabled] ="!isEditMode(row)">
                  <option [ngValue]="newNotificationCode" selected>-- Select --</option>
                  <option *ngFor="let personType of personTypes" [ngValue]="personType">
                    {{personType.desc}}
                  </option>
                </select>
              </div>

              
              <div  *ngIf="isIndividualType(row.notificationDistributionType)">
                <span>Individual</span>
              </div>
            </td>

            <td role="cell">
                <div *ngIf="isRoleType(row.notificationDistributionType) || isSharedEmailType(row.notificationDistributionType) || isPersonType(row.notificationDistributionType) ">
                  <span>N/A</span>
                </div>

                <div *ngIf="isIndividualType(row.notificationDistributionType)">
                  <div class="field has-addons">
                    <div class="control">
                      <input class="input is-small" type="text" disabled="disabled"
                      [value]="getPersonName(row)" >
                    </div>
                    <div class="control">
                      <button class="button  is-small" [disabled]="!isEditMode(row)"
                      [ngClass]="{'is-primary' : !isRowSelected(row), 
                      'is-custom-save' : isRowSelected(row)}" (click)="editIndividual(row)">
                        Edit 
                      </button>
                    </div>
                  </div>
                
                  <!-- <input type="text" name="person-{{index}}" [(ngModel)]="row.individual.firstName" [disabled]="!isEditMode(row)"> -->
                </div>
            </td>
            <td role="cell">
              <div *ngIf="isRoleType(row.notificationDistributionType) || isPersonType(row.notificationDistributionType)">
                <span>N/A</span>
              </div>
               <div *ngIf="isSharedEmailType(row.notificationDistributionType)">
                <span *ngIf="row.sharedEmail">{{row.sharedEmail.value}} </span>
               </div>
              <div *ngIf="isIndividualType(row.notificationDistributionType)">

                <input type="text" name="email" disabled="disabled"
                 [value]="row.individual.email" >
              </div>
            </td>

          </tr>

        </tbody>
      </table>
    </div>
  </div>

  <div class="modal person-modal" [ngClass]="{'is-active': showModal}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Person Selection</p>
      </header>
      <section class="modal-card-body">
        Provide one or more of the following criteria
        <div class="has-search-criteria">
          <div class="field is-horizontal is-grouped">
            <div class="field-label  ">
              <label class="">First&nbsp;Name: </label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input is-small " type="text" [(ngModel)]="searchCriteriaPerson.firstName">
                </div>
              </div>
            </div>
            <div class="field-label  ">
              <label class=" ">&nbsp;&nbsp;Last&nbsp;Name: </label>
            </div>
            <div class="field-body">
              <div class="field  has-addons">
                <div class="control">
                  <input class="input is-small " type="text" [(ngModel)]="searchCriteriaPerson.lastName">
                </div>
                <p class="control ">
                  <button class="button is-primary is-small" [disabled]="!isValidSearchCriteria()" (click)="findPersonMatches()">
                    Search
                  </button>
                </p>
              </div>
            </div>
            <!-- <div class="field-label is-small">
                    <label class=" ">Email: </label>
                  </div>
                  <div class="field-body">
                    <div class="field  has-addons">
                      <div class="control">
                        <input class="input is-small " type="text"
                        [(ngModel)]="searchCriteriaPerson.email">
                      </div>
                      <p class="control ">
                        <a class="button is-primary is-small">
                          Search
                        </a>
                      </p>
                    </div>            
                  </div> -->
          </div>
        </div>
        <div *ngIf="persons.length > 0">
          <div class="has-fixed-table-header form-table-container">    
            <table class="is-scroll-table header table is-striped is-fullwidth "
            role="grid">
              <thead>
                <tr>
                  <!-- <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th> -->
                  <ng-container *ngFor="let column of personColumns">
                    <th *ngIf="column.display" class="table-header "
                     [mcTableSorting]="personConfig" [column]="column" title="{{column.title}}" 
                     (sortChanged)="onChangePersonTable($event)"
                      ngClass="{{column.className || ''}}">
                      <span> {{column.title}}</span>
                      <span *ngIf="personRows.length > 0 && personConfig && column.sort != undefined" class="sort  is-text-centered">
                        <span *ngIf="column.sort === ''">
                          <i class="fas fa-sort"></i>
                        </span>
                        <span *ngIf="column.sort === 'desc'">
                          <i class="fas fa-sort-down"></i>
                        </span>
                        <span *ngIf="column.sort === 'asc'">
                          <i class="fas fa-sort-up"></i>
                        </span>
                      </span>
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="showPersonFilterRow">
                  <ng-container *ngFor="let column of personColumns" >
                  <td *ngIf="column.display" >
                    <input *ngIf="column.filtering" placeholder="{{column.filtering.placeholder}}"
                     [mcTableFiltering]="column.filtering" 
                      class="form-control filter-width"
                        (tableChanged)="onChangePersonTable(personConfig)" />
                  </td>
                  </ng-container>
                </tr>
                <tr *ngFor="let row of personRows" (click)="selectPerson(row)" 
                    [ngClass]="{'is-selected': isPersonSelected(row)}"
                      id="{{row.pk}}" >
                  <td class="has-fixed-cell-width">
                    {{row.firstName}}
                  </td>
                  <td class="has-fixed-cell-width">
                    {{row.lastName}}
                  </td>
                  <td>
                    {{row.email}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary is-radiusless"
        [disabled]="selectedPerson==null"
         (click)="setSelectedPerson()">Select</button>
        <button class="button is-radiusless" 
        (click)="cancelSelectedPerson()">Cancel</button>
      </footer>
    </div>
</div>

</div>
