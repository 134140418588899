import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';


import { ToastService } from '../../common/services/toast.service';
import { ToastPosition, ToastMessage } from '../../common/model/toast-message';
import { Subscription } from 'rxjs';

/**
 * Toast message component - the UI representation for the message to be displayed
 */
@Component({
  selector: 'mc-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.scss']
})
export class ToastMessagesComponent implements OnInit, OnDestroy {

  messages: any;
  /**
   * Default position if none is specified
   */
  tPosition: ToastPosition = new ToastPosition(150, 750);

  /**
   * Array of subscriptions for cleanup later
   */
  subscriptions: Subscription[] = [];

  pendingHttpCount = 0;

  constructor(private toastService: ToastService
    , private viewContainer: ViewContainerRef) {

    /**
     * Subscribe to the position Queue
     */
    this.subscriptions.push(toastService.messagePosition$
      .subscribe(
      msgPosition => {
        this.tPosition = msgPosition;
      }));
    /**
     * Subscribe to the message Count
     */
    this.subscriptions.push(toastService.toastMessagesCount$
      .subscribe(
      messageCount => {
        if (messageCount > 0) {
          this.getMessages();
        }
      }));
      // subscribe to the pending http count
    this.subscriptions.push(toastService.pendingHttpCountPosition$.subscribe(
      pendingCount => {
        this.pendingHttpCount = pendingCount;
      }));
  }


  ngOnInit() {
    this.getMessages();
  }

  /**
   * Main entry point that retrieves the message from the toastService
   * Additionally if the message is configured with the modalArgs object
   * the message is removed (to prevent display as a toast) and the message
   * is passed on to the modalMethod for display as a modal
   */
  getMessages = function () {
    const msgs: ToastMessage[] = this.toastService.getMessages();
   // if (msgs.length > 0) {
      msgs.forEach(eachMsg => {
        if (eachMsg.modalArg != null) {
          this.dismiss(eachMsg.key);
          /*#1254547 - don't display additional dialogs if there is no pending request */
            if (this.pendingHttpCount > 0) {
              this.toastService.showModalDialog(this.viewContainer, eachMsg.modalArg)
                .subscribe(actionResult => {
                  // do nothing
                });
            }
        }
      });
   // }
    this.messages = msgs; // this.toastService.getMessages();
  }

  /**
   * Removes the message from the list based on the Id
   * @param itemKey The key / identifier the message
   */
  dismiss(itemKey) {
    this.toastService.dismissMessage(itemKey)
  }

  /**
   * helper to set the position of the toast message
   */
  public positionStyle() {
    if (this.tPosition.left == 0 && this.tPosition.top == 0) {
      return {display: 'block', marginLeft: 'auto', marginRight: 'auto'};
    }
    const totalWidth: number = window.innerWidth;
    const toastWidth = 500;
    if ((this.tPosition.left + toastWidth) > totalWidth) {
      this.tPosition.left = (totalWidth - (toastWidth + 20));
    }
    return { left: this.tPosition.left + 'px', top: this.tPosition.top + 'px' };
  }

  /**
   * Destroy calls the subscrition array and cleans up the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      subs => {
        subs.unsubscribe();
        subs.closed;
      });
  }
}
