import { Injectable, ComponentFactoryResolver, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from 'app/common/services/base-service';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import { Observable, of, Subject } from 'rxjs';
import { FormType } from 'app/common/model/form-type';
import { FormSection } from 'app/common/model/form-section.';
import { LibraryVariable } from '../../model/library-variable';
import { VariableClass } from 'app/common/model/variable-class';
import { VariableDataType } from 'app/common/model/variable-datatype';
import { Form } from 'app/common/model/form';
import { VariableResponseType } from 'app/common/model/variable-response-type';
import { FormCopyModel } from '../../model/form-copy-model';
import { Code } from '../../model/code';
import { tap, publishReplay, refCount } from 'rxjs/operators';
import { FormCtsu } from 'app/common/model/form-ctsu';
import { FormCtsuResponse } from 'app/common/model/form-ctsu-response';



@Injectable()
export class FormsDashboardService extends BaseService {



  /**
     * variable for library Variables
     */
    private _libraryVariables: LibraryVariable[] = [];


  // ** Mechanism to communicate the opened Form  between components ** */

  /**
   * The Observable Source for the form being edited
   */
  private formBeingEditedSource = new Subject<Form>();


  /**
   * Observable stream for transmitting Form being edited
   */
  formBeingEdited$ = this.formBeingEditedSource.asObservable();



  /**
     * Fetch Form types
     * @returns Observable - returns array of FormTypes
     */
  private formTypeList: FormType[] = [];
  private tempFormTypesObservable: Observable<any>;

  /**
    * Fetch status codes
    * @returns Observable - returns array of code
    */
  private formStatusCodesList: Code[] = [];
  private tempFormStatusCodesObservable: Observable<any>;


  /**
     * Fetch Response types
     * @returns Observable - returns array of VariableResponseType
     */
  private variableResponseTypeList: VariableResponseType[] = [];
  private tempVariableResponseTypesObservable: Observable<any>;


  /**
     * Fetch Form Variable types
     * @returns Observable - returns array of Code
     */
    private formVariableTypeList: Code[] = [];
    private tempFormVariableTypesObservable: Observable<any>;

  /**
    * Fetch Rule Operators
    * @returns Observable - returns array of Rule Operator code list
    */
   private ruleOperatorsList: Code[] = [];
   private tempRuleOperatorsObservable: Observable<any>;

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

    /**
     * returns the array of library variables
     */
    public get libraryVariables(): LibraryVariable[] {
      console.log('REturning Library Variables', this._libraryVariables.length)
      return this._libraryVariables;
    }

    /**
     * sets the library variables array
     */
    public set libraryVariables(variables: LibraryVariable[]) {
      console.log('SETtting Library Variables ', variables.length)
      this._libraryVariables = variables;
    }

    /**
     * Submit the Form being edited to the Observable Stream
     * @param form The form being edited
     */
  setFormBeingEdited(form: Form) {
    this.formBeingEditedSource.next(form);
  }








  /**
   * Returns all the forms for a studyId
   * @param studyId The studyId whose forms are to be retrieved
   */
  public getFormsForStudy(studyId: number): Observable<Array<Form>> {
    const url = 'studies/' + studyId + '/forms';
    return this.http.get<Array<Form>>
      (`${this.config.apiEndpoint}` + url);
  }


  /**
 * Submits the form from the form management page.
 * @param studyId studyId for which the form is being updated
 * @param formId the formId of the form being updated
 * @param updatedForm the form with the updated data
 */
  public updateInfoForm(studyId: number, formId: number, updatedForm: Form): Observable<Form> {
    const url = 'studies/' + studyId + '/forms/' + formId + '/form-info';
    return this.http.put<Form>
      (`${this.config.apiEndpoint}` + url, updatedForm);
  }
  getFormTypes(): Observable<Array<FormType>> {
    if (this.formTypeList.length > 0) {
      return of(this.formTypeList);
    } else if (this.tempFormTypesObservable) {
      return this.tempFormTypesObservable;
    } else {
      const url = 'form-types';
      this.tempFormTypesObservable = this.http.get<Array<FormType>>
        (`${this.config.apiEndpoint}` + url)
        .pipe(tap (oList => {
          this.tempFormTypesObservable = null;
          this.formTypeList = oList;
          return of(this.formTypeList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempFormTypesObservable;
    }
  }


  /**
   *
   * @param formTypeId - The formTypeId for which it gets all the sections to it.
   * @return FormsSection[] - an array of FormSection objects
   */
  public getFormSections(formTypeId: number): Observable<FormSection[]> {
    const url = 'form-types/' + formTypeId + '/sections';
    return this.http.get<FormSection[]>
      (`${this.config.apiEndpoint}` + url);
  }


  /**
   * Gets all variables
   * @returns Observable - return list of variable objects
   */
  public getLibraryVariables(): Observable<Array<LibraryVariable>> {
    const url = 'library-variables';
    return this.http.get<Array<LibraryVariable>>
      (`${this.config.apiEndpoint}` + url);
  }
  getFormStatusCodes(): Observable<Array<Code>> {
    if (this.formStatusCodesList.length > 0) {
      return of(this.formStatusCodesList);
    } else if (this.tempFormStatusCodesObservable) {
      return this.tempFormStatusCodesObservable;
    } else {
      const url = 'configuration/form-status-codes';
      this.tempFormStatusCodesObservable = this.http.get<Array<Code>>
        (`${this.config.apiEndpoint}` + url)
        .pipe(tap (oList => {
          this.tempFormStatusCodesObservable = null;
          this.formStatusCodesList = oList;
          return of(this.formStatusCodesList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempFormStatusCodesObservable;
    }
  }

  /**
   * Gets variable datatypes
   * @returns Observable - return list of variable datatypes
   */
  public getLibraryVariableDataTypes(): Observable<Array<VariableDataType>> {
    const url = 'library-variables/datatypes';
    return this.http.get<Array<VariableDataType>>
      (`${this.config.apiEndpoint}` + url);
  }


  /**
   * Gets variable classes
   * @returns Observable - return list of variable class objects
   */
  public getLibraryVariableClasses(): Observable<Array<VariableClass>> {
    const url = 'library-variables/classes';
    return this.http.get<Array<VariableClass>>
      (`${this.config.apiEndpoint}` + url);
  }
  getVariableResponseTypes(): Observable<Array<VariableResponseType>> {
    console.log('in getVariableResponseTypes');
    if (this.variableResponseTypeList.length > 0) {
      return of(this.variableResponseTypeList);
    } else if (this.tempVariableResponseTypesObservable) {
      return this.tempVariableResponseTypesObservable;
    } else {
      const url = 'variable-response-types';
      this.tempVariableResponseTypesObservable = this.http.get<Array<VariableResponseType>>
        (`${this.config.apiEndpoint}` + url)
        .pipe(tap (oList => {
          this.tempVariableResponseTypesObservable = null;
          this.variableResponseTypeList = oList;
          return of(this.variableResponseTypeList);
        }))
        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
        .pipe(refCount());
      return this.tempVariableResponseTypesObservable;
    }
  }
    getFormVariableTypes(): Observable<Array<Code>> {
      if (this.formVariableTypeList.length > 0) {
        return of(this.formVariableTypeList);
      } else if (this.tempFormVariableTypesObservable) {
        return this.tempFormVariableTypesObservable;
      } else {
        const url = 'configuration/form-variable-types';
        this.tempFormVariableTypesObservable = this.http.get<Array<Code>>
          (`${this.config.apiEndpoint}` + url)
          .pipe(tap (oList => {
            this.tempFormVariableTypesObservable = null;
            this.formVariableTypeList = oList;
            return of(this.formVariableTypeList);
          }))
          .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
          .pipe(refCount());
        return this.tempFormVariableTypesObservable;
      }
    }


  /**
    * @param  {LibraryVariable} libraryVariable - formLibrary object with all the variables details to save.
    * @returns Observable - return variable object with variableId along with tupleVersion in it.
    */
  public addLibraryVariables(libraryVariable: LibraryVariable): Observable<LibraryVariable> {
    const url = 'library-variables';
    return this.http.post<LibraryVariable>
      (`${this.config.apiEndpoint}` + url, libraryVariable);
  }


  /**
   * @param  {number} studyId - StudyId for which the form are saved to.
   * @param  {Form} form - Form object.
   * @returns Observable
   */
  public addStudyForm(studyId: number, form: Form): Observable<Form> {
    const url = 'studies/' + studyId + '/forms';
    return this.http.post<Form>
      (`${this.config.apiEndpoint}` + url, form);
  }



  /**
   * @param  {number} studyId - StudyId for which the form are saved to.
   * @param  {number} formId - FormId of the Form to be retrieved
   * @returns Observable
   */
  public getStudyForm(studyId: number, formId: number): Observable<Form> {
    const url = 'studies/' + studyId + '/forms/' + formId;
    return this.http.get<Form>
      (`${this.config.apiEndpoint}` + url);
  }


  /**
 * Submits the form containing all the variables. Called when a variable
 * is inserted or updated
 * @param studyId studyId for which the form is being updated
 * @param studyFormId the studyFormId of the form being updated
 * @param updatedForm the form with the updated data
 */
  public updateForm(studyId: number, studyFormId: number, updatedForm: Form): Observable<Form> {
    const url = 'studies/' + studyId + '/forms/' + studyFormId;
    return this.http.put<Form>
      (`${this.config.apiEndpoint}` + url, updatedForm);
  }


  /**
   * Submits the form containing just the updated variables. Called when the
   * Form does not contain any inserted or deleted variables
   * @param studyId studyId for which the form is being updated
   * @param studyFormId the studyFormId of the form being updated
   * @param updatedForm the form with the updated data
   */
  public updateFormVariablesOnly(studyId: number, studyFormId: number, updatedForm: Form): Observable<Form> {
    const url = 'studies/' + studyId + '/forms/' + studyFormId + '/variables';
    return this.http.put<Form>
      (`${this.config.apiEndpoint}` + url, updatedForm);
  }


  /**
   * Copies the Form and returns the newly created form
   * @param studyId The study id of the form
   * @param studyFormId The formId
   * @param formCopyModel The model for the payload
   */
  public copyForm(studyId: number, studyFormId: number, formCopyModel: FormCopyModel)
    : Observable<Form> {
    const url = 'studies/' + studyId + '/forms/' + studyFormId;
    return this.http.post<Form>
      (`${this.config.apiEndpoint}` + url, formCopyModel);
  }

  /**
   * Delete the Form - you can't pass a payload so we pass the tupleVersion via the header
   * @param studyId The study id of the form
   * @param studyFormId The formId
   * @param tupleVersion - used to detect stale data
   */
  public deleteForm(studyId: number, studyFormId: number, tupleVersion: number): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('If-Match', tupleVersion.toString());
    const url = 'studies/' + studyId + '/forms/' + studyFormId;
    return this.http.delete(`${this.config.apiEndpoint}` + url, { headers: header, responseType: 'text' });
  }
  //  getRuleOperatorList(): Observable<Array<Code>> {
  //    if (this.ruleOperatorsList.length > 0) {
  //      return of(this.ruleOperatorsList);
  //    } else if (this.tempRuleOperatorsObservable) {
  //      return this.tempRuleOperatorsObservable;
  //    } else {
  //      const url = 'configuration/nav-rule-operators';
  //      this.tempRuleOperatorsObservable = this.http.get<Array<Code>>
  //        (`${this.config.apiEndpoint}` + url)
  //        .pipe(tap (oList => {
  //          this.tempRuleOperatorsObservable = null;
  //          this.ruleOperatorsList = oList;
  //          return of(this.ruleOperatorsList);
  //        }))
  //        .pipe(publishReplay()) // to ensure that this list is cached and shared with subscribers
  //        .pipe(refCount());
  //      return this.tempRuleOperatorsObservable;
  //    }
  //  }


  /**
* Submits the ctsu Form to create a new one
* @param studyId The study id of the form
* @param formCtsu The model for the payload
*/
  public addCtsuForm(studyId: number, formCtsu: FormCtsu)
    : Observable<FormCtsuResponse> {
    const url = 'studies/' + studyId + '/forms/ctsu';
    return this.http.post<FormCtsuResponse>
      (`${this.config.apiEndpoint}` + url, formCtsu);
  }



  /**  Form builder Dependency***************** */

  /**
     *
     * Gets the list of expression types from the service
     * @returns Observable list of codes
     */
  public getDependencyExpressionTypes(): Observable<Code[]> {
    const url = 'configuration/dependency-expression-types'
    return this.http.get<Code[]>
      (`${this.config.apiEndpoint}` + url);
  }

  // Get …/ api/v1/configuration/dependency-expression-operators

  /**
     *
     * Gets the list of expression Operators from the service
     * @returns Observable list of codes
     */
  public getDependencyExpressionOperators(): Observable<Code[]> {
    const url = 'configuration/dependency-expression-operators'
    return this.http.get<Code[]>
      (`${this.config.apiEndpoint}` + url);
  }

/**  Form builder  Dependency***************** */

/*** start - copy form from study/form */

  /**
   * Returns all the forms for a studyId
   * @param studyId The studyId whose forms are to be retrieved
   */
  public getFormsForStudyByProtocolNumber(studyId: number, copyFromStudyProtocol: string): Observable<Array<Form>> {
    const url = 'studies/' + studyId + '/forms?protocolNumber=' + copyFromStudyProtocol;
    return this.http.get<Array<Form>>
      (`${this.config.apiEndpoint}` + url);
  }

  /**
   * Performs a copy of a form based on formID (same or different study)
   * - same form name, version number is 1, status is in-development, no effective date or associated event
   * @param studyId The study id to own the new form
   * @param studyFormId The formId
   * @param formCopyModel The model for the payload
   */
  public copyFormFromStudy(studyId: number, studyFormId: number)
    : Observable<Form> {
    const url = 'studies/' + studyId + '/forms/' + studyFormId + '?isOtherStudiesFormCopy=' + true;
    return this.http.post<Form>
      (`${this.config.apiEndpoint}` + url, {});
  }



/*** end - copy form from study/form */


}
