import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth-guard.service';
import * as activityConst from 'app/common/model/activity-name-constants';

import { MsalGuard } from '@azure/msal-angular';
import { CanDeactivateGuard } from 'app/can-deactivate-guard.service';
import { EnrollmentCorrectionsComponent } from './enrollment-corrections/enrollment-corrections.component';
import { PatientApprovalComponent } from './patient-approval/patient-approval.component';
import { PatientTransferComponent } from './patient-transfer/patient-transfer.component';
import { PatientComponent } from './patient.component';
import { PostProcessingComponent } from './post-processing/post-processing.component';

const patientRoutes: Routes = [
  {
    path: 'patient',
    component: PatientComponent,
    canActivate: [MsalGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      breadcrumb: 'Patient Landing',
      activityName: activityConst.ACTIVITY_PATIENT_LANDING
    },
  },
  {
    path: 'patient/:studyId/:patientId/:caseDetailId',
    component: PatientComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: {
      breadcrumb: 'Patient Landing',
      activityName: activityConst.ACTIVITY_PATIENT_LANDING,
      customPage: activityConst.ACTIVITY_PATIENT_LANDING_PAGE
    }
  },
  {
    path: 'patient/transfer/:studyId/:patientId/:caseDetailId',
    component: PatientTransferComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: {
      breadcrumb: 'Patient Transfer',
      activityName: activityConst.ACTIVITY_PATIENT_REGISTRATION_TRANSFER
    }
  },

  {
    path: 'approval/:studyId/:patientId/:caseDetailId/:caseEventId/:approvalSchemaEventId',
    component: PatientApprovalComponent,
    canActivate: [MsalGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      breadcrumb: 'Patient Approval',
      activityName: activityConst.ACTIVITY_PATIENT_REGISTRATION_APPROVE
    }
  },
  {
    path: 'enrollment-corrections/:studyId/:patientId/:caseDetailId/:caseEventId',
    component: EnrollmentCorrectionsComponent,
    canActivate: [MsalGuard, AuthGuard],
    data: {
      breadcrumb: 'Enrollment Correction',
      activityName: activityConst.ACTIVITY_PATIENT_ENROLLMENT_CORRECTIONS
    }
  },

  {
    path: 'post-processing/:studyId/:patientId/:caseDetailId/:caseEventId',
    component: PostProcessingComponent,
    canActivate: [MsalGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      breadcrumb: 'Post Processing',
      activityName: activityConst.ACTIVITY_PATIENT_REG_POST_PROCESSING
    }
  },

];

@NgModule({
  imports: [
    RouterModule.forChild(patientRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PatientRoutingModule { }
