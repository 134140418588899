import { Directive, EventEmitter, ElementRef, HostListener, Input, Output, Renderer2 } from '@angular/core';


function setProperty(renderer: Renderer2, elementRef: ElementRef, propName: string, propValue: any): void {
  renderer.setProperty(elementRef, propName, propValue);
}

@Directive({  selector: '[mcTableFiltering]'})

export class McTableFilteringDirective {
  @Input() public mcTableFiltering: any = {
    filterString: '',
    columnName: 'name'
  };

  @Output() public tableChanged: EventEmitter<any> = new EventEmitter();

  private element: ElementRef;
  private renderer: Renderer2;

  @Input()
  public get config(): any {
    return this.mcTableFiltering;
  }

  public set config(value: any) {
    this.mcTableFiltering = value;
  }

  @HostListener('input', ['$event.target.value'])
  public onChangeFilter(event: any): void {
    this.mcTableFiltering.filterString = event;
    this.tableChanged.emit({filtering: this.mcTableFiltering});
  }

  public constructor(element: ElementRef, renderer: Renderer2) {
    this.element = element;
    this.renderer = renderer;
    // Set default value for filter
    setProperty(this.renderer, this.element, 'value', this.mcTableFiltering.filterString);
  }

}
