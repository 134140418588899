import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import * as ACTIVITY_NAMES from 'app/common/model/activity-name-constants';
import * as globalConst from 'app/common/model/app-constants';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { StudyPatientSearchResult } from '../../common/model/study-patient-search-result';
import { SearchService } from '../../common/services/search.service';
import { UserService } from '../../common/services/user.service';
import { AncillaryStudy } from '../model/ancillary-study';
import { SearchCriteria } from '../model/search-criteria';
import { SearchInfo } from '../model/search-info';



@Component({
  selector: 'mc-accrued-patient-search-results',
  templateUrl: './accrued-patient-search-results.component.html',
  styleUrls: ['./accrued-patient-search-results.component.scss']
})
export class AccruedPatientSearchResultsComponent implements OnInit, OnDestroy {

  @Input() hostLandingPageEnum = 0;
  @Input() isAncillaryStudy: boolean = false;
  @Input() ancillaryStudy: AncillaryStudy;
  @Output() returnToSearchEvent = new EventEmitter();

  searchCriteria: SearchCriteria = null;
  subscriptions: Subscription[] = [];

  studyId = 0;

  results: StudyPatientSearchResult[];
  rows: StudyPatientSearchResult[];
  noResultsFound = false;
  isActiveListTitle = false;

  /**
   * The search criteria that identifies the type of patient search
   */
  currentSearchCriteria: SearchInfo = null;

  public columns: Array<any> = [
    {
      title: 'Subject Id', name: 'patientId',
      sort: '',
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Subject Id' }
    },
    {
      title: 'Initials', name: 'initials',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Initals' }
    },
    {
      title: 'Enrollment Event', name: 'eventName',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Event' }
    },
    {
      title: 'Arm Assignment', name: 'armAssignment',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Arm Assignment' }
    },
    {
      title: 'Registration Date', name: 'regDate',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Registration Date' }
    },
    {
      title: 'Treating Location', name: 'treatingLocation',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Treating Location' }
    },
    {
      title: 'Treating Investigator', name: 'treatingInvestigator',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Treating Investigator' }
    },
    {
      title: 'Registrar', name: 'registrar',
      sort: true,
      display: true,
      filtering: { filterString: '', placeholder: 'Filter by Registrar' }
    }
  ];

  /**
   * The configuration for the table being displayed
   */
  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['is-striped', 'is-bordered']
  };

  constructor(private searchService: SearchService,
    private userService: UserService,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private router: Router) {

  }


  ngOnInit() {
    if (this.appConfig.version === 'v1') {
      this.columns[0].title = 'Patient Id';
      this.columns[0].filtering.placeholder = 'Filter by Patient Id';
    }

    if (!this.canSeeArmAssignments()) {
      this.columns.map(column => {
        if (column['name'] === 'armAssignment') {
          column['display'] = false;
        }
      });
    }

    //Hide event, registrar, treatingInvestigator column for ancillary patients list
    if (this.isAncillaryStudy) {
      this.columns.map(column => {
        if (column["name"] === "eventName") {
          column["display"] = false;
        }
        if (column["name"] === "registrar") {
          column["display"] = false;
        }
        if (column["name"] === "treatingInvestigator") {
          column["display"] = false;
        }
      })
    }
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params['studyId']) {
          this.studyId = params['studyId'];
        }
      }));



    this.subscriptions.push(this.searchService.searchQuery$.subscribe(
      criteria => {
        this.currentSearchCriteria = this.searchService.getSearchQuery(this.hostLandingPageEnum)//  this.searchService.currentSearchQuery;
        this.searchCriteria = null;
        if (criteria && criteria.searchType === globalConst.SEARCH_CRITERIA_TYPE_ACCRUED_PATIENT) {
          console.log('here in accrued');
          this.searchCriteria = criteria;
          this.isActiveListTitle = false;
          if (this.isAncillaryStudy) {
            this.studyId = this.ancillaryStudy?.study?.studyId;
          }
          this.getAccruedPatients();
        } else {
          this.results = null;
        }
      }
    ))
  }



  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
        x.closed;
      }
    )
  }






  /**
   * Navigates to the patient landing page
   * @param $event
   * @param patientResult
   */
  goToPatientLanding($event: Event, patientResult: StudyPatientSearchResult) {
    if (this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_PATIENT_LANDING, this.studyId)) {
      if ($event) {
        $event.stopPropagation();
      }
      if (this.isAncillaryStudy) {
        this.searchService.getAccruedPatients(this.ancillaryStudy.parentStudyId, patientResult.patient.patientId)
          .subscribe((result) => {
            const patients: StudyPatientSearchResult[] = result;
            if (patients.length > 0) {
              patientResult = patients.find( patient => patient?.event?.schemaEventCode === patientResult?.event?.schemaEventCode);
            }
            const queryParams= {
              'navBack': this.hostLandingPageEnum,
              'trackingNum': patientResult.patient.trackingNum,
              'selectedCaseEventId': patientResult.caseEventId,
              'navBackAncillaryStudyId': this.ancillaryStudy.study.studyId
            }
            this.routeToPatientLanding(patientResult, queryParams);
          });
      } else {

        const queryParams= {
          'navBack': this.hostLandingPageEnum,
          'trackingNum': patientResult.patient.trackingNum,
          'selectedCaseEventId': patientResult.caseEventId
        }
        this.routeToPatientLanding(patientResult, queryParams);
      }
    } else {
      console.log('not authorize to patient landing page for this study')
    }
  }


  /**
   * 
   * @param patientResult 
   */
  routeToPatientLanding(patientResult: StudyPatientSearchResult, queryParams:any) {
    const path: string = '/patient/' + patientResult.patient.studyId + '/' + patientResult.patient.patientId + '/' + patientResult.patient.caseDetailId;
    this.router.navigate([path], {
      queryParams:  queryParams 
    });
  }
  /**
   * @param  {PatientSearchResult} row - patient record
   * @returns boolean - evaluate whether patientId or TraackingId
   */
  isActivePatient(row: StudyPatientSearchResult): boolean {
    return (row.patient.patientId == null && row.patient.trackingNum != null);
  }

  /**
   * Returns true if the user has the activity for seeing the arms on the Accrual Results preview pane
   * @returns 
   */
  canSeeArmAssignments(): boolean {
    return this.userService.hasMinimumAccessByStudy(ACTIVITY_NAMES.ACTIVITY_STUDY_PATIENT_ACCRUAL_ARM, this.studyId);
  }

  /**
   * @param row
   * @returns string - label if one is specified, otherwise event display name
   */
  getEventName(row: StudyPatientSearchResult): String {
    return (row.event.studySchemaEventLabel != null && row.event.studySchemaEventLabel.length > 0 ? row.event.studySchemaEventLabel : row.event.schemaEventDisplayName);
  }

  returnToSearch() {
    this.fireEvent();
  }

  fireEvent() {
    this.returnToSearchEvent.emit();
  }

  /**
 * Sorts the data in the table based on the column and sort order
 * @param data The data currently being displayed on the table
 * @param config The associated configuration for the data being displayed
 */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousVal: any;
      let currentVal: any;

      // If the following columns are used, then need to get different values for previous and current
      if (columnName === 'patientId') {
        previousVal = previous['patient']['patientId'] ? previous['patient']['patientId'].toLowerCase() : '';
        currentVal = current['patient']['patientId'] ? current['patient']['patientId'].toLowerCase() : '';
      } else if (columnName === 'initials') { // get first and last name
        previousVal = (previous['patient']['firstName'] ? previous['patient']['firstName'].toLowerCase() : '') +
          (previous['patient']['lastName'] ? previous['patient']['lastName'].toLowerCase() : '');
        currentVal = (current['patient']['firstName'] ? current['patient']['firstName'].toLowerCase() : '') +
          (current['patient']['lastName'] ? current['patient']['lastName'].toLowerCase() : '');
      } else if (columnName === 'eventName') {
        previousVal = previous['event']['schemaEventDisplayName'] ? previous['event']['schemaEventDisplayName'].toLowerCase() : '';
        currentVal = current['event']['schemaEventDisplayName'] ? current['event']['schemaEventDisplayName'].toLowerCase() : '';
      } else if (columnName === 'regDate') { // date values
        previousVal = previous[columnName] ? new Date(previous[columnName]) : new Date(0);
        currentVal = current[columnName] ? new Date(current[columnName]) : new Date(0);
      } else if (columnName === 'treatingLocation') {
        previousVal = previous['treatingLocation']['name'] ? previous['treatingLocation']['name'].toLowerCase() : '';
        currentVal = current['treatingLocation']['name'] ? current['treatingLocation']['name'].toLowerCase() : '';
      } else if (columnName === 'treatingInvestigator') { // get treatingInvestigator first and last name
        previousVal = (previous['treatingInvestigator']['firstName'] ? previous['treatingInvestigator']['firstName'].toLowerCase() : '') +
          (previous['treatingInvestigator']['lastName'] ? previous['treatingInvestigator']['lastName'].toLowerCase() : '');
        currentVal = (current['treatingInvestigator']['firstName'] ? current['treatingInvestigator']['firstName'].toLowerCase() : '') +
          (current['treatingInvestigator']['lastName'] ? current['treatingInvestigator']['lastName'].toLowerCase() : '');
      } else if (columnName === 'registrar') { // get registrar first and last name
        previousVal = (previous['registrar']['firstName'] ? previous['registrar']['firstName'].toLowerCase() : '') +
          (previous['registrar']['lastName'] ? previous['registrar']['lastName'].toLowerCase() : '');
        currentVal = (current['registrar']['firstName'] ? current['registrar']['firstName'].toLowerCase() : '') +
          (current['registrar']['lastName'] ? current['registrar']['lastName'].toLowerCase() : '');
      } else { // simple values
        previousVal = previous[columnName] ? previous[columnName].toLowerCase() : '';
        currentVal = current[columnName] ? current[columnName].toLowerCase() : '';
      }

      if (previousVal > currentVal) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousVal < currentVal) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  /**
  * Main method to handle filtering, sorting of the table
  * @param config Configuration of the datatable
  * @param page The current page being displayed
  */
  public onChangeTable(config: any): any {
    let column: any = null;
    this.rows = [];
    if (config.title != undefined && config.name != undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.results, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = sortedData;

  }

  /**
   * Applies the data filter as needed to the rows
   * @param data The data being displayed on the ui
   * @param config the configuration of the table
   */
  public changeFilter(data: any, config: any): any {
    if (data == undefined || data == null) {
      return;
    }
    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (column.name === 'patientId' && item['patient'] && item['patient']['patientId']) {
            return item['patient']['patientId'].toLowerCase().match(column.filtering.filterString.toLowerCase());
          } else if (column.name === 'initials' && item['patient'] && item['patient']['firstName'] && item['patient']['lastName']) {
            const initials = item['patient']['lastName'] + item['patient']['firstName'];
            return initials.toLowerCase().match(column.filtering.filterString.toLowerCase());

          } else if (column.name === 'eventName' && item['event'] && item['event']['studySchemaEventLabel'] && (item['event']['studySchemaEventLabel'].length > 0 || item['event']['studySchemaEventLabel'] == "")) {
            // Using the event label.  The string "match" function uses a regular expression to match, which doesn't work well 
            // when either the label or the filter has hyphens, parenthesis, etc which can be part of a regExp.  So compare them manually.
            let value: String = item['event']['studySchemaEventLabel'].toLowerCase();
            let filterValue: String = column.filtering.filterString.toLowerCase();
            let i: number = 0;
            for (i = 0; i < filterValue.length; i++) {
              if (value.substr(i, 1) != filterValue.substr(i, 1)) {
                return false;
              }
            }
            return true;
          } else if (column.name === 'eventName' && item['event'] && item['event']['schemaEventDisplayName']) {
            // Using the event display name.  The string "match" function uses a regular expression to match, which doesn't work well 
            // when the filter value has hyphens, parenthesis, etc which can be part of a regExp.  So compare them manually.
            let value: String = item['event']['schemaEventDisplayName'].toLowerCase();
            let filterValue: String = column.filtering.filterString.toLowerCase();
            let i: number = 0;
            for (i = 0; i < filterValue.length; i++) {
              if (value.substr(i, 1) != filterValue.substr(i, 1)) {
                return false;
              }
            }
            return true;

          } else if (column.name === 'regDate' && item['regDate']) {
            const regDateString = moment(item['regDate']).format('MM/DD/YYYY');
            return regDateString.match(column.filtering.filterString);
          } else if (column.name === 'treatingLocation' && item['treatingLocation'] && item['treatingLocation']['name']) {
            return item['treatingLocation']['name'].toLowerCase().match(column.filtering.filterString.toLowerCase());
          } else if (column.name === 'treatingInvestigator' && item['treatingInvestigator'] && item['treatingInvestigator']['firstName'] && item['treatingInvestigator']['lastName']) {
            const name = item['treatingInvestigator']['firstName'] + item['treatingInvestigator']['lastName'];
            return name.toLowerCase().match(column.filtering.filterString.toLowerCase());
          } else if (column.name === 'registrar' && item['registrar'] && item['registrar']['firstName'] && item['registrar']['lastName']) {
            const name = item['registrar']['firstName'] + item['registrar']['lastName'];
            return name.toLowerCase().match(column.filtering.filterString.toLowerCase());
          }

          return ''.toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });



    return filteredData;
  }

   /**
   * Calls the service to fetch the accrued patients for the study
   */
    private getAccruedPatients() {
      this.subscriptions.push(
        this.searchService.getAccruedPatients(this.studyId, '')
          .subscribe(res => {
            this.results = res.filter(x => x.patient.studyId > 0 && x.patient.caseDetailId > 0);
            this.noResultsFound = (this.results.length === 0);
            this.onChangeTable(this.config);
          }));
    }
}
