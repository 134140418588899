<mc-header-bar [headerModel]="headerModel" (saveEvent)="saveChanges()" (cancelEvent)="cancelChanges($event)"></mc-header-bar>

<!-- <div class="form-page" [ngClass]="{'switchToHorizontal': previewModelHome.isVertical,'is-vertical-open': previewModelHome.isCollapsed  }">
  <div [ngClass]="{'switchVertical columns':previewModelHome.isVertical }">
    <div class="" [ngClass]="{'is-6':isExpandedVertical(),'column':isExpandedVertical() || isCollapsedVertical()}"> -->
  <div class="" [ngClass]="{ 'switchToHorizontal': previewModelHome.isVertical,'is-vertical-open': previewModelHome.isCollapsed  }">
    <div id="mainContainer" [ngClass]="{'switchVertical columns': previewModelHome.isVertical }">
      <div [ngClass]="{ 'column is-main-section-vertical': previewModelHome.isVertical,
        'is-6' : isExpandedVertical(), 'is-11' : isCollapsedVertical() }"
        class="main-section" id="mainSection">      
      
        <div class="header-panel">
          <div class=" has-header">
            <div class="nav-return-bar">
              <ul>
                <li class="has-link ">
                  <a (click)="navigateBack()">
                    <span class="icon">
                      <i class="fas fa-arrow-left"></i>
                    </span>
                    <span class="link-text">Back to Subject Landing</span>
                  </a>
                </li>
                <li>
                  <a [ngClass]="{'is-nav-active': isLinkActive('requests') } " class=" sectionLink" (click)="showSection('requests')">
                    <span>All Transfers</span>
                  </a>
                </li>
                <li>
                  <a [ngClass]="{'is-disabled-tab': isPendingRegistration(), 'is-nav-active': isLinkActive('newTransfer') } " class=" sectionLink" (click)="showSection('newTransfer')">
                    <span>New Transfer</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      
     
        <div class="  form-page columns is-marginless">
          <div class="column  is-12">
            <div class="main-transfer" [ngClass]="{'is-hidden': isRequestDisplayMode}">
              <div id="transfer-requests">
                <div class="transfer-edit-bar">
                  <div class="columns edit-bar-section">
                    <div class="column ">
                      <div class="">
                        <h3> Transfer Subject </h3>
                      </div>
                    </div>
                    <div class="column ">
                      <mc-edit-bar [selectionCount]="selectedIds.length" [actionSetting]="actionSetting"
                        (selectedActionEvent)="selectedEditAction($event)" [editSelectionCount]="selectedIds.length">
                      </mc-edit-bar>
                    </div>
                  </div>
                </div>

                <table class="table is-fullwidth is-borderless is-striped " role="grid">
                  <thead>
                    <tr>
                      <th>Initials </th>
                      <th>Event</th>
                      <th>Site</th>
                      <th>Status</th>
                      <th>Status Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let row of patientTransferList; let idx = index">
                      <tr [ngClass]="{'is-selected': isRowSelected(row)}" (click)="rowSelectedClick(row )">
                        <td>{{row.initials}} </td>
                        <td>
                            {{row.eventName}}
                         </td>
                        <td [ngClass]="{'selected':isInfoSelected(row)}"
                          class="display-message is-display-message-multiline is-display-message-bottom"
                          [attr.data-display-message]="getInfoMessage(row)">
                          {{row.siteName}}
                        </td>
                        <td>{{row.status}} </td>
                        <td>{{row.statusDate | date:'MM/dd/yyyy'}} </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="main-transfer" [ngClass]="{'is-hidden': !isRequestDisplayMode}">
              <div id="new-transfer">
                <div class="transfer-edit-bar">
                  <div class="columns edit-bar-section">
                    <div class="column ">
                      <div class="">
                        <h3> New Transfer </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-table-container new-transfer-section columns">
                  <div #contentDivs id="{{SECTION_ID}}" class="single-section column">
                    <div *ngIf="currentRegModel() ">
                      <mc-patient-participation-information [SECTION_ID]="SECTION_ID" [studyId]="studyId"
                        [caseDetailId]="caseDetailId" [patientId]="patientId" [isPatientTransfer]="true">
                      </mc-patient-participation-information>
                    </div>
                    <div class="has-comment-box">
                      <div class="columns">
                        <div class=" column is-2">
                          <div class="field ">
                            <label class="label is-size-7">
                              Comments
                            </label>
                          </div>
                        </div>
                        <div class="column is-5">
                          <div class="field">
                            <div class="control">
                              <textarea class="textarea" [(ngModel)]="transferRequest.comments"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="transfer-button-section">
                      <div class="field is-grouped is-grouped-centered">
                        <p class="control">
                          <button class="button  is-primary is-fixed-size is-radiusless"
                            (click)="startTransfer()">Submit</button>
                        </p>

                          <div class="control has-padding" *ngIf="hasOverrideAccess()">
                            <button  type="button" (click)="overrideValidation()"
                              [mcCanNavigate]="'ACTIVITY_PATIENT_REG_VALIDATION_OVERRIDE'" accessType="update"
                              class="button is-primary override-btn-size is-radiusless " *ngIf="shouldShowOverride()">
                              <span class="icon">                   
                                <i class="fas fa-brush" aria-hidden="true"></i>
                              </span>
                              <span>Override Errors</span>
                            </button>
                          </div>
                      
                        <p class="control">
                          <button class="button is-cancel is-fixed-size is-radiusless"
                            (click)="cancelChanges($event)">Clear</button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <!-- <div class="preview-section " id="previewSection" 
        [ngClass]="{'togglePerviewOverlay' : isExpandedHorizontal(), ' column ' : isExpandedVertical()
        , 'column is-1' : isCollapsedVertical()
        , 'preview-vertical-margin-top': previewModelHome.isVertical }"> -->
      <div class="preview-section" id="previewSection"
      [ngClass]="{ 'togglePerviewOverlay' : isExpandedHorizontal(),                
      'is-6 preview-vertical-expanded' : isExpandedVertical(),
        'is-1' : isCollapsedVertical(),
      'column ': previewModelHome.isVertical }">
        <mc-preview [formTitle]="formTitle" [formCaption]="previewTitle" [previewModel]="previewModelHome"
          #previewSection>
          <div preview-data>
            <div class="form-preview">
                  <mc-patient-transfer-preview *ngIf="singleTrackingPreview" [patientTransferInputList]="singleTrackingDtl">              
                  </mc-patient-transfer-preview>
                </div>
          </div>
        </mc-preview>
      </div>

    </div>

</div>