import { ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { ConfirmActionComponent } from 'app/common/confirm-action/confirm-action.component';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { RedcapStudy } from 'app/common/model/redcap-study';
import { Subject } from 'rxjs';
import { ErrorDisplayComponent } from '../error-display/error-display.component';
import { ErrorMessage } from '../model/error-message';
import { PatientErrorDisplayArg } from '../model/patient-error-display-arg';


export class BaseService {

  componentFactoryResolver: ComponentFactoryResolver;


  // ** Mechanism to communicate events between study-setup component ** */
  // Observable string sources
  private emitChangeSource = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();



  // ** Mechanism to communicate Validation Errors  between components ** */
  // Observable ErrorMessages sources
  private emitErrorMessagesSource = new Subject<ErrorMessage[]>();
  // Observable Error Messages streams
  changeErrorMessagesEmitted$ = this.emitErrorMessagesSource.asObservable();


  constructor(
    cfr: ComponentFactoryResolver) {
    this.componentFactoryResolver = cfr;
  }
  // Service message commands
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }
  // Service message commands
  emitErrorMessagesChange(change: any) {
    this.emitErrorMessagesSource.next(change);
  }



  /**
   * Displays the modal dialog with the appropriate infor
   * @param viewContainerRef the container where this modal will be appended
   * @param modalDialogArg the args for the modal
   */
  showModalDialog(viewContainerRef: ViewContainerRef,
    modalDialogArg: ModalDialogArg = null, redcapStudy:RedcapStudy = null) {
    const componentRef = viewContainerRef.createComponent(ConfirmActionComponent);
    componentRef.instance.dialogArgType = modalDialogArg;
    componentRef.instance.redcapStudy = redcapStudy;
    return (<ConfirmActionComponent>componentRef.instance).actionSubject;
  }



  /**
   * Displays the modal dialog with the appropriate infor
   * @param viewContainerRef the container where this modal will be appended
   * @param currentError the args for the modal
   */
  showValidationError(viewContainerRef: ViewContainerRef,
    errArg: PatientErrorDisplayArg = null): ComponentRef<ErrorDisplayComponent> {
    const componentRef = viewContainerRef.createComponent(ErrorDisplayComponent);
    componentRef.instance.errorDisplayArg = errArg;
    // componentRef.instance.dialogArgType = modalDialogArg;
    return componentRef; // .actionSubject;
  }



}
