export class EditBarActions {
    constructor(
        public isAddDisabled: boolean = false,
        public isEditDisabled: boolean = false,
        public isInfoDisabled: boolean | null,
        public isFilterDisabled: boolean |null,
        public isDeleteDisabled: boolean = false,
        public isEditBarDisabled: boolean = false,
        public isUpDisabled ?: boolean | null,
        public isDownDisabled ?: boolean | null
    ) { }
}
