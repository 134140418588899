import { Component, OnInit, Input } from '@angular/core';
import { FormVariable } from 'app/common/model/form-variable';

@Component({
  selector: 'mc-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit {

  @Input() formVariable: FormVariable

  constructor() { }

  ngOnInit() {
  }

}
