/**
 * class to hold nav-rule error information
 */
export class TabbedDataRuleError {
    constructor(
      public formVariableId: number = 0,
      public ruleId: number = 0,
      public parentFormVariableId: number | null ,
      public message: string,
      public ruleType ?: string
    ) { }
  }
