import { FormVariable } from './form-variable';
import { Code } from 'app/common/model/code';
import { StudySchemaEventCode } from './study-schema-event-code';

export class Form {
    constructor(
        public formId: number | null,
        public studyId: number,
        public studyNumber: string | null,
        public formType: Code,
        public formName: string,
        public formDesc: string,
        public studyFormNum: number,
        public versionNum: number,
        public formStatus: Code,
        public studySchemaEvent: StudySchemaEventCode,
        public effectiveDate: Date | null,
        public versionDate: Date | null,
        public modifiedById: string,
        public modifiedTs: number | null,
        public tupleVersion: number | null,
        public useForSimulation: boolean | null,
        public formVariableList: FormVariable[] = [],
        public formSelectionName?: string | null
    ) { }
}
