import { HttpClient } from '@angular/common/http';
import { ComponentFactoryResolver, ElementRef, Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'app/app-config/app-config.module';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorNavigationService {

  http: HttpClient;
  config: AppConfig;
  serviceUrlPrefix: string;

  /**
     * Private observable subject to handle the Patient uiLayoutElements
     */
    private uiElementClickedSource = new Subject<ElementRef>();

    /**
     * Stream through which a user can subscribe to the clicked UiElement
     */
    public uiElementClicked$ = this.uiElementClickedSource.asObservable();


  constructor(http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig,
    componentFactoryResolver: ComponentFactoryResolver) {


    this.config = config;
    this.http = http;
    this.serviceUrlPrefix = config.apiEndpoint;
  }

    /**
     * Allows the clicked UI Elements to be submitted to the queue for
     * subscribers to retrieve
     * @param uiElement ui Element that was clicked pushed into the Q
     */
    public setUiElementClicked(uiElement: ElementRef) {
      this.uiElementClickedSource.next(uiElement);
    }




}
