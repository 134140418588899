import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';

export interface SearchInputOption {
  id: any;
  code: string;
  name: string;
}

@Component({
  selector: 'mc-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnChanges {
  @Input() placeholder: string = "";
  @Input() options: SearchInputOption[] = [];
  @Input() includeChips: boolean = true;
  @Input() allowDuplicateChips: boolean = false;
  @Input() isMultiSelect: boolean = true;

  @Output() selectedOptionsChange = new EventEmitter<SearchInputOption[]>();

  @ViewChild('auto', { static: false }) matAutoComplete!: MatAutocomplete;
  @ViewChild('input', { static: false }) textInput!: ElementRef<HTMLInputElement>;

  inputControl = new FormControl<string | SearchInputOption>('');
  filteredOptions: SearchInputOption[] = [];
  selectedOptions: SearchInputOption[] = [];

  chevronDown = faChevronDown;
  chevronUp = faChevronUp;
  iconSearch = faSearch;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.filterOptions();
    }
  }

  handleInputChanged(): void {
    this.filterOptions();
  }

  handleOnFocus(): void {
    this.filterOptions();
  }

  /**
   * Handles when an autocomplete option is selected
   * @param option Option selected
   */
  handleSelectionChanged(option: SearchInputOption): void {
    if (this.isMultiSelect) {
      this.addChip(option);
      this.clearInputValue();
      this.filterOptions();
    }
    else {
      this.selectedOptionsChange.emit([option]);
    }
  }

  handleIconClick(): void {
    this.textInput.nativeElement.focus();
  }

  addChip(chip: SearchInputOption): void {
    if (chip?.code && !this.selectedOptions.includes(chip) && this.isMultiSelect) {
      this.selectedOptions.push(chip);
      this.emitSelectedOptionsChanged();
    }
  }

  removeChip(chip: SearchInputOption): void {
    if (this.selectedOptions.includes(chip)) {
      this.selectedOptions.splice(this.selectedOptions.indexOf(chip), 1);
      this.emitSelectedOptionsChanged();
    }
  }

  displayOptionSelection(value: any): string {
    return value?.value;
  }

  /**
   * Clears selected values and input box
   */
  clearControl(): void {
    this.clearInputValue();
    this.clearSelections();
  }

  private filterOptions(): void {
    const optionsNotSelected = this.getOptionsNotSelected();
    if (this.inputControl.value) {
      const text: string = this.inputControl.value?.toString() === this.inputControl.value ? this.inputControl.value : (this.inputControl.value as SearchInputOption).code;
      this.filteredOptions = optionsNotSelected.filter(o => o.name.toLowerCase().includes(text.toLowerCase()));
    }
    else {
      this.filteredOptions = [...optionsNotSelected];
    }
  }

  private getOptionsNotSelected(): SearchInputOption[] {
    return this.options
      .filter(o =>
        !this.selectedOptions
          .map(so => so.code.toLowerCase())
          .includes(o.code.toLowerCase()));
  }

  private clearInputValue(): void {
    this.inputControl.setValue(null);
    this.textInput.nativeElement.value = '';
  }

  private clearSelections(): void {
    this.selectedOptions = [];
    this.emitSelectedOptionsChanged();
  }

  private emitSelectedOptionsChanged(): void {
    this.selectedOptionsChange.emit(this.selectedOptions);
  }
}