import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudyPatientSearchResult } from 'app/common/model/study-patient-search-result';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'mc-patient-search-by-study',
  templateUrl: './patient-search-by-study.component.html',
  styleUrls: ['./patient-search-by-study.component.scss']
})
export class PatientSearchByStudyComponent implements OnInit {

  @Input() studyId = 0;
  @Input() patientId = '';

  searchCriteria = '';



  @Output() selectedPatientEvent = new EventEmitter();

  selectedPatient: StudyPatientSearchResult = null;

  previousCriteria = '';

  results: StudyPatientSearchResult[];

  noResultsFound = false;

  subscriptions: Subscription[] = [];

  showModal = false;




  public showFilterRow: boolean = true;
  public rows: Array<StudyPatientSearchResult> = [];
  public page = 1;
  public itemsPerPage = 10;
  public maxSize = 5;
  public numPages = 1;
  public length = 0;

  /**
   * The columns to be displayed on the Details table
   */
  public columns: Array<any> = [
    {
      title: 'Patient Id', name: 'patientId',
      display: true,
      sort: '', className: ['has-patient-id'],
      filtering: { filterString: '', placeholder: 'Id' },
    },
    {
      title: 'Name', name: 'name',
      display: true,
      sort: '', className: ['has-name'],
      filtering: { filterString: '', placeholder: 'Name' },
    },
    {
      title: 'Gender', name: 'genderDesc',
      display: true,
      sort: '', className: ['has-gender'],
      filtering: { filterString: '', placeholder: 'Gender' },
    },
    {
      title: 'DOB', name: 'dateOfBirthString',
      display: true,
      sort: '', className: ['has-dob'],
      filtering: { filterString: '', placeholder: 'DOB' },
    },
    {
      title: 'Treating Location', name: 'treatingLocation',
      display: true,
      sort: '', className: ['has-treating-location'],
      filtering: { filterString: '', placeholder: 'Treating location' },
    }
  ];


  public config: any = {
    paging: false,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: []
  };




  constructor(private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router) {

  }

  ngOnInit() {
    if (this.patientId) {
      this.searchCriteria = this.patientId;
    }
  }

  /**
   * Destroy implementation - closes all the subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      x => {
        x.unsubscribe();
      }
    );
  }

  /**
     * Handler for the search button click
     */
  searchClick() {
    this.showModal = true;
    if (this.previousCriteria !== this.searchCriteria) {
      this.clearExistingSearch();
      this.previousCriteria = this.searchCriteria;
      this.getPatientsForCriteria();
    }

  }





  /**
   * True if the row being evaluated is the selected variable
   * @param row The selected row to evaluate
   */
  isSelected(row: StudyPatientSearchResult) {
    return this.selectedPatient === row;
  }


  /**
   * Selects or deselects
   * @param row The selected row
   */
  rowSelectedClick(row: StudyPatientSearchResult) {
    if (this.selectedPatient === row) {
      this.selectedPatient = null;
    } else {
      this.selectedPatient = row;
    }
  }




  /**
   * True if no value has been provided for patient Id search
   */
  isInvalid(): boolean {
    return this.searchCriteria.trim().length === 0;
  }




  /**
   * Handler for the OK button click
   */
  choosePatient() {
    this.searchCriteria = this.selectedPatient.patient.patientId;
    this.selectedPatientEvent.emit(this.selectedPatient);
    this.closeModal();
  }

  /**
   * Close the modal
   */
  closeModal() {
    this.showModal = false;
    this.selectedPatient = null;
  }


  /**
   * Sorts the data in the table
   * @param data The data to sort
   * @param config The config to use
   */
  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }
    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;
    for (const column of columns) {
      if (column.sort !== '' && column.sort !== false) {
        columnName = column.name;
        sort = column.sort;
      }
    }
    if (!columnName) {
      return data;
    }
    // simple sorting
    return data.sort((previous: any, current: any) => {
      let previousValue: any;
      let currentValue: any;

      if (columnName === 'patientId') {
        previousValue = previous?.patient?.patientId ? previous?.patient?.patientId : '';
        currentValue = current?.patient?.patientId ? current?.patient?.patientId : '';
      } else if (columnName === 'name') { // get first and last name
        previousValue = (previous?.patient?.firstName ? previous?.patient?.firstName.toLowerCase() : '') +
          (previous?.patient?.lastName ? previous?.patient?.lastName.toLowerCase() : '');
        currentValue = (current?.patient?.firstName ? current?.patient?.firstName.toLowerCase() : '') +
          (current?.patient?.lastName ? current?.patient?.lastName.toLowerCase() : '');
      } else if (columnName === 'gender') {
        previousValue = previous?.patient?.gender ? previous?.patient?.gender : '';
        currentValue = current?.patient?.gender ? current?.patient?.gender : '';
      } else if (columnName === 'dateOfBirthString') {
        const dateA: string = previous?.patient?.dateOfBirthString || '';
        const dateB: string = current?.patient?.dateOfBirthString || '';
        previousValue = this.searchService.parseDateString(dateA);
        currentValue = this.searchService.parseDateString(dateB);
      } else if (columnName === 'treatingLocation') {
        previousValue = previous?.treatingLocation?.name ? previous?.treatingLocation?.name : '';
        currentValue = current?.treatingLocation?.name ? current?.treatingLocation?.name : '';
      }

      if (previousValue > currentValue) {
        return sort === 'desc' ? -1 : 1;
      } else if (previousValue < currentValue || previousValue == null) {
        return sort === 'asc' ? -1 : 1;
      }

      return 0;
    });

  }


  /**
   * Filters the data being displayed
   * @param data The data to filter
   * @param config The configuration to use
   */
  public changeFilter(data: any, config: any): any {
    if (data === undefined || data == null) {
      return;
    }

    let filteredData: Array<any> = data;

    if (!config.filtering) {
      return filteredData;
    }

    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          if (column.name === 'patientId' && item?.patient && item?.patient?.patientId) {
            return item?.patient?.patientId.toLowerCase().match(column.filtering.filterString.toLowerCase());
          } else if (column.name === 'name' && item?.patient && item?.patient?.firstName && item?.patient?.lastName) {
            const initials = item?.patient?.lastName + item?.patient?.firstName;
            return initials.toLowerCase().match(column.filtering.filterString.toLowerCase());
          } else if (column.name === 'genderDesc' && item?.patient && item?.patient?.genderDesc) {
            return item?.patient?.genderDesc.toLowerCase().match(column.filtering.filterString.toLowerCase());
          } else if (column.name === 'dateOfBirthString' && item?.patient && item?.patient?.dateOfBirthString) {
            const birthDateString = item?.patient?.dateOfBirthString;
            return birthDateString.match(column.filtering.filterString);
          } else if (column.name === 'treatingLocation' && item?.treatingLocation && item?.treatingLocation?.name) {
            return item?.treatingLocation?.name.toLowerCase().match(column.filtering.filterString.toLowerCase());
          }

          return ''.match(column.filtering.filterString.toLowerCase());
        });
      }
    });



    return filteredData;
  }

  /**
   * Handler when the sort, page, or filter options changes on the table
   * @param config the configuration to use
   * @param page The page to display
   */
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    let column: any = null;
    if (config.title !== undefined && config.name !== undefined) {
      column = { title: config.title, name: config.name, sort: config.sort };
    }
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (column != null) {
      this.columns.forEach((col: any) => {
        if (col.name !== column.name && col.sort !== false) {
          col.sort = '';
        }
      });
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.results, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
    if (sortedData) {
      this.length = sortedData.length;
    }
  }

  /**
   * handler for the change page event
   * @param page The page to display
   * @param data The data to choose from
   */
  public changePage(page: any, data: Array<any> = this.results): Array<any> {
    const start = (page.page - 1) * page.itemsPerPage;
    const end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  /**
 * Calls the service to return the patients matching the search criteria
 */
  private getPatientsForCriteria() {
    this.rows = [];
    this.subscriptions.push(this.searchService.getAccruedPatients(this.studyId, this.searchCriteria)
      .subscribe(res => {
        const tempResults: StudyPatientSearchResult[] = res;
        this.results = this.removeDuplicates(res);
        this.noResultsFound = (this.results.length === 0);
        this.onChangeTable(this.config);
      }));
  }

  /**
   * results include a record for each EVENT patient is registered to however we only want to display one record per patient
   * Return one record per patient
   */
  private removeDuplicates(tempResults: StudyPatientSearchResult[]): StudyPatientSearchResult[] {

    return _.uniqBy(tempResults, (e) => e.patient.caseDetailId);
  }

  /**
* Clears the existing search results;
*/
  private clearExistingSearch(): void {
    this.columns.forEach(eachCol => {
      if (eachCol.filtering && eachCol.filtering.filterString) {
        eachCol.filtering.filterString = '';
      }
    });

  }


}

