<div class="modal" [ngClass]="{'is-active': showModal}">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{title}}</p>
            <button class="delete" (click)="closeModal()"></button>
        </header>
        <section class="modal-card-body">
            <div [innerHTML]="body">
            </div>
        </section>

        <footer class="modal-card-foot">
            <div class="is-pulled-right footer-button ">
                <button class="button is-primary is-radiusless" (click)="closeModal()">
                    Cancel</button>
            </div>
        </footer>
    </div>
</div>