<mc-header-bar [headerModel]="headerModel" (saveEvent)="saveChanges()" (cancelEvent)="cancelChanges()">
</mc-header-bar>
<div class=""
  [ngClass]="{ 'switchToHorizontal': previewModelHome.isVertical,'is-vertical-open': previewModelHome.isCollapsed  }">
  <div [ngClass]="{'switchVertical columns': previewModelHome.isVertical }">
    <div [ngClass]="{ 'column is-6 is-main-section-vertical' : isExpandedVertical(), 'is-11' : isCollapsedVertical() }">
      <div class="search-section form-page" id="main-content">
        <div class="columns is-marginless form-content">
          <div class="column is-paddingless is-12">
            <div class="nav-return-bar">
              <a (click)="navigateBack()">
                <span class="icon has-left-icon">
                  <i class="fas fa-arrow-left"></i>
                </span>
                <span class="link-text">Back to Search Results</span>
              </a>
            </div>
            <div class="columns is-marginless ">
              <div class="column form-content no-bottom-padding">
                <div class="reg-tiles">
                  <div class="tile is-ancestor reg-tile">

                    <!-- Status tile - Register Patient-->
                    <div class="tile is-one-fifth is-parent">
                      <div class="tile is-child box is-radiusless" [ngClass]="{'is-disabled': isStatusTileDisabled()}"
                        [mcCanNavigate]="'ACTIVITY_PATIENT_REGISTRATION'" [studyId]="studyId" accessType='create'>
                        <div class="color-bar green"></div>
                        <div class="content-div">
                          <p class="title is-5 has-text-weight-bold">Status</p>
                          <p class="title is-3 has-text-weight-bold" *ngIf="studyRecord">
                            {{studyRecord.currentMsrStudyStatus.statusDescription}} </p>
                          <p class="subtitle is-6">{{getDate()}}</p>
                        </div>
                        <div>
                          <button type="button" [disabled]="isStatusTileDisabled()" (click)="registerPatient()"
                            class="button is-primary  is-radiusless"
                            [ngClass]="{'is-disabled': isStatusTileDisabled()}">
                            <span class="icon ">
                              <i class="fas fa-plus " aria-hidden="true"></i>
                            </span>
                            <span>{{getRegisterTileButtonText()}}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- /.status-tile -->





                    <div class="tile is-one-fifth is-parent is-vertical ">

                      <!--Notifications subscribe half tile -->
                      <div class="tile is-child box is-radiusless is-half-tile"
                        [ngClass]="{'is-disabled': isNotificationTileDisabled()}"
                        [mcCanNavigate]="'ACTIVITY_NOTIFICATION_SUBSCRIBE'" [studyId]="studyId">
                        <div class="color-bar purple"></div>
                        <p class="title is-5 has-text-weight-bold">Notifications</p>
                        <div>
                          <button type="button" (click)="updateSubscription()" [disabled]="isNotifications"
                            class="button is-primary  is-radiusless is-subscribe">
                            <span class="icon " *ngIf="!isSubscribedToStudy()">
                              <i class="fas fa-plus" aria-hidden="true"></i>
                              <span class="has-left-padding">Subscribe</span>
                            </span>
                            <span class="icon " *ngIf="isSubscribedToStudy()">
                              <i class="fas fa-minus" aria-hidden="true"></i>
                              <span class="has-left-padding">Unsubscribe</span>
                            </span>
                          </button>
                        </div>
                      </div>
                      <!-- /.Notifications subscribe Half Tile -->

                      <!-- contacts half tile-->
                      <div class=" tile is-child box is-radiusless is-half-tile">
                        <div class="color-bar purple"></div>
                        <p class="title is-5 has-text-weight-bold">Contact List</p>
                        <div>
                          <button type="button" (click)="viewStudyContacts()"
                            class="button is-primary  is-radiusless has-manual-width">
                            <span class="icon ">
                              <i class="fas fa-phone " data-fa-transform="flip-h" aria-hidden="true"></i>
                              <span>View Contacts</span>
                            </span>
                          </button>
                        </div>
                      </div>
                      <!--/.Contacts half tile-->



                    </div>

                    <div class="tile is-one-fifth is-parent is-vertical ">

                      <!-- dosage half tile-->
                      <div class=" tile is-child box is-radiusless is-half-tile"
                        [ngClass]="{'is-disabled': isDosageTileDisabled()}" [mcCanNavigate]="'ACTIVITY_DOSAGE'"
                        [studyId]="studyId">
                        <div class="color-bar purple"></div>
                        <p class="title is-5 has-text-weight-bold">Dosage</p>
                        <div>
                          <button type="button" (click)="viewDosage()" class="button is-primary  is-radiusless">
                            <span class="icon ">
                              <i class="fas fa-highlighter" aria-hidden="true"></i>
                            </span>
                            <span>View Dosage</span>
                          </button>
                        </div>
                      </div>
                      <!-- /.dosage half tile -->

                      <!-- double blind half tile-->
                      <div class=" tile is-child box is-radiusless is-half-tile"
                        [mcCanNavigate]="'ACTIVITY_DOUBLE_BLIND_SETUP'" displayType="hide-keep">
                        <div class="color-bar purple"></div>
                        <p class="title is-5 has-text-weight-bold">Double Blind</p>
                        <div>
                          <button type="button" (click)="manageDoubleBlind()" class="button is-primary  is-radiusless">
                            <span class="icon ">
                              <i class="fas fa-stethoscope" aria-hidden="true"></i>
                            </span>
                            <span>Manage</span>
                          </button>
                        </div>
                      </div>
                      <!-- /.double blind half tile-->

                      <!-- problem case events -->
                    </div>
                    <div class="tile is-one-fifth is-parent">
                      <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_STUDY_CASE_EVENT'"
                        [studyId]="studyId" displayType="hide">
                        <div class="color-bar purple"></div>
                        <div class="content-div">
                          <p class="title is-5 has-text-weight-bold">Case Events</p>
                          <p class="title is-2 has-text-weight-bold">{{caseEventCount}}</p>
                          <p class="subtitle is-6">{{getCaseEventsText()}}</p>
                        </div>
                        <div>
                          <button type="button" (click)="manageCaseEventProblems()"
                            class="button is-primary is-radiusless">
                            <span class="icon">
                              <i class="fas fa-sticky-note" aria-hidden="true"></i>
                            </span>
                            <span>Manage</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- /.problem case events -->

                    <!-- Manage Study tile-->
                    <div class="tile is-one-fifth is-parent">
                      <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_STUDY_SETUP'"
                        [studyId]="studyId" displayType="hide">
                        <div class="color-bar green"></div>
                        <div class="content-div">
                          <p class="title is-5 has-text-weight-bold">Manage Study</p>
                          <p class="title is-3 has-text-weight-bold">{{getCurrentValidatedSchemaVersionNumber()}}</p>
                          <p *ngIf="currentSchema?.effectiveDate" class="subtitle is-6">as of  {{currentSchema.effectiveDate | date:'MM/dd/yyyy hh:mm a'}}</p>
                        </div>
                        <div>
                          <button type="button" (click)="editStudy()" class="button is-primary  is-radiusless">
                            <span class="icon ">
                              <i class="fas fa-edit" aria-hidden="true"></i>
                            </span>
                            <span>Make Changes</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- /.Manage Studies -->

                  </div>
                </div>
              </div>
            </div>


            <div class="columns is-marginless">
              <div class="column no-top-padding">
                <div class="reg-tiles">
                  <div class="tile is-ancestor reg-tile">
                    <!-- Accrual tile-->
                    <div class="tile is-one-fifth is-parent">
                      <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_STUDY_PATIENT_ACCRUAL'"
                        [studyId]="studyId">
                        <div class="color-bar peacock-blue"></div>
                        <div class="content-div">
                          <p class="title is-5 has-text-weight-bold">Accruals</p>
                          <div *ngIf="accrualSetting.currentAccrual != null">
                            <p class="title is-2 has-text-weight-bold">
                              {{accrualSetting.currentAccrual}}
                            </p>
                            <p class="subtitle is-6">of
                              {{ accrualSetting.totalAccrual}}
                            </p>
                          </div>
                          <div *ngIf="accrualSetting.currentAccrual == null">
                            <p class="subtitle is-6">-
                            </p>
                            <p class="subtitle is-6">of
                              {{ accrualSetting.totalAccrual}}
                            </p>
                          </div>
                        </div>
                        <div>
                          <button type="button" class="button is-primary is-radiusless"
                            [disabled]="!accrualSetting.accrualButtonEnable" (click)="viewAccrualPatients()">
                            <span class="icon">
                              <i class="fas fa-user" aria-hidden="true"></i>
                            </span>
                            <span>{{getAccrualsButtonText()}}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- /.accruals-tile -->

                    <div class="tile is-one-fifth is-parent">
                      <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_STUDY_PATIENT_ACTIVE'"
                        [studyId]="studyId">
                        <div class="color-bar peacock-blue"></div>
                        <div class="content-div">
                          <p class="title is-5 has-text-weight-bold">Active List</p>
                          <p class="title is-2 has-text-weight-bold">
                            {{ activePatientsCount }}
                          </p>
                          <p class="subtitle is-6">in progress</p>
                        </div>
                        <div>
                          <button type="button" (click)="viewActivePatients()" [disabled]="activePatientsCount==0"
                            class="button is-primary is-radiusless">
                            <span class="icon">
                              <i class="fas fa-check-circle" aria-hidden="true"></i>
                            </span>
                            <span>{{getActiveListButtonText()}}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- /.active-list -tile -->


                    <!-- Slot Reservation tile -->
                    <div class="tile is-3 is-parent" [mcCanNavigate]="'ACTIVITY_SLOT_RESERVATION'" [studyId]="studyId"
                      displayType="hide" *ngIf="reservationEvent">
                      <div class="tile is-child box is-radiusless">
                        <div class="color-bar green"></div>
                        <p class="title is-5 has-text-weight-bold">Reservations</p>
                        <div class="button-spacing">
                          <button type="button" (click)="viewSlotGroupAvailability()"
                            class="button title is-primary is-radiusless" [mcCanNavigate]="'ACTIVITY_SLOT_RESERVATION'"
                            [studyId]="studyId">
                            <span class="icon is-small">
                              <i class="fas fa-check-circle" aria-hidden="true"></i>
                            </span>
                            <span>View Slot Availability</span> 
                          </button>
                          <button type="button" (click)="manageSlotReservations()"
                            class="button title is-primary is-radiusless" [mcCanNavigate]="'ACTIVITY_SLOT_RESERVATION'"
                            [studyId]="studyId">
                            <span class="icon is-small">
                              <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                            </span>
                            <span>Manage Reservations</span>
                          </button>
                          <button type="button" (click)="createReservation()"
                            class="button title is-primary is-radiusless" [mcCanNavigate]="'ACTIVITY_SLOT_RESERVATION'"
                            [studyId]="studyId" accessType="create" [disabled]="!checkSlotReservationOpenStatus()">
                            <span class="icon is-small">
                              <i class="fas fa-plus" aria-hidden="true"></i>
                            </span>
                            <span>Create Reservation</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Ancillary Tile-->
                    <div class="tile is-one-fifth is-parent">
                      <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_STUDY_ANCILLARIES'"
                        [studyId]="studyId" displayType="hide">
                        <div class="color-bar green"></div>
                        <div class="content-div">
                          <p class="title is-5 has-text-weight-bold">Ancillary Studies</p>
                          <p class="title is-2 has-text-weight-bold" *ngIf="studyRecord">
                            {{ancillaryStudyCount}}
                          </p>
                        </div>
                        <div>
                          <button type="button" class="button is-primary  is-radiusless"
                            (click)="viewAncillaryStudies()" [disabled]="ancillaryStudyCount==0">
                            <span class="icon ">
                              <i class="far fa-clipboard " aria-hidden="true"></i>
                            </span>
                            <span>View Studies</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- /.Ancillary Studies -->

                    <!--add simulation here -->

                    <div class="tile is-one-fifth is-parent">
                      <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_STUDY_SIMULATION'"
                        [studyId]="studyId" displayType="hide">
                        <div class="color-bar purple"></div>
                        <div class="content-div">
                          <p class="title is-5 has-text-weight-bold">Simulation</p>
                        </div>
                        <div>
                          <button type="button" class="button is-primary  is-radiusless"
                            (click)="navigateToSimulation()" [disabled]="isSimulationTileDisabled()">
                            <span class="icon ">
                              <i class="far fa-clipboard " aria-hidden="true"></i>
                            </span>
                            <span>Study Simulations</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Study Sign Off -->
                    <div class="tile is-one-fifth is-parent">
                      <div class="tile is-child box is-radiusless" [mcCanNavigate]="'ACTIVITY_STUDY_SIGN_OFF'"
                        [studyId]="studyId" displayType="hide">
                        <div class="color-bar peacock-blue"></div>
                        <div class="content-div">
                          <p class="title is-5 has-text-weight-bold">Study Sign Off</p>
                          <p class="title is-3 has-text-weight-bold">{{studySignOffStatus}}</p>
                        </div>
                        <div>
                          <button type="button" class="button is-primary  is-radiusless" (click)="goToStudySignOff()"
                            [disabled]="isStudySignOffDisabled()">
                            <span class="icon ">
                              <i class="far fa-check-circle " aria-hidden="true"></i>
                            </span>
                            <span>Validate</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- /.Study Sign Off -->

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="preview-section" id="previewSection" [ngClass]="{ 'togglePerviewOverlay' : isExpandedHorizontal(),                
      'is-6 preview-vertical-expanded' : isExpandedVertical(), 'is-1' : isCollapsedVertical(),
      'column ': previewModelHome.isVertical }">
      <mc-preview [formTitle]="formTitle" [formCaption]="previewTitle" [previewModel]="previewModelHome"
        [isPdf]="isPdfDoc" [printDocType]="downloadDocType" #previewSection>
        <div preview-data>
          <div class="form-preview">
            <div [ngClass]="{'is-hidden': shouldHideResults(SEARCH_TYPE_PATIENT)}">
              <mc-patient-search-results (returnToSearchEvent)="collapseResultPanel($event)"
                [hostLandingPageEnum]="studyLandingPageEnum">
              </mc-patient-search-results>
            </div>
            <div [ngClass]="{'is-hidden': shouldHideResults(SEARCH_TYPE_ACCRUED_PATIENT)}">
              <mc-accrued-patient-search-results (returnToSearchEvent)="collapseResultPanel($event)"
                [hostLandingPageEnum]="studyLandingPageEnum">
              </mc-accrued-patient-search-results>
            </div>
            <div [ngClass]="{'is-hidden': shouldHideResults(SEARCH_TYPE_ACTIVE_PATIENT)}">
              <mc-active-patient-search-results (returnToSearchEvent)="collapseResultPanel($event)"
                [hostLandingPageEnum]="studyLandingPageEnum">
              </mc-active-patient-search-results>
            </div>
            <div [ngClass]="{'is-hidden' : shouldHideResults(SEARCH_TYPE_CONTACTS)}">
              <mc-active-contacts (returnToSearchEvent)="collapseResultPanel($event)"
                [hostLandingPageEnum]="studyLandingPageEnum">
              </mc-active-contacts>
            </div>
            <div [ngClass]="{'is-hidden' : shouldHideResults(SEARCH_TYPE_ANCILLARY)}">
              <mc-ancillary-studies (returnToSearchEvent)="collapseResultPanel($event)"
                [hostLandingPageEnum]="studyLandingPageEnum">
              </mc-ancillary-studies>
            </div>
            <div [ngClass]="{'is-hidden' : shouldHideResults(SEARCH_TYPE_DOSAGE)}">
              <mc-active-dosage (returnToSearchEvent)="collapseResultPanel($event)"
                [hostLandingPageEnum]="studyLandingPageEnum">
              </mc-active-dosage>
            </div>
            <div [ngClass]="{'is-hidden' : shouldHideResults(SEARCH_TYPE_SLOT_RESERVATIONS)}">
              <mc-slot-reservations (returnToSearchEvent)="collapseResultPanel($event)"
                [hostLandingPageEnum]="studyLandingPageEnum">
              </mc-slot-reservations>
            </div>
            <div [ngClass]="{'is-hidden' : shouldHideResults(SEARCH_TYPE_SLOT_AVAILABILITY)}">
              <mc-slot-availability (returnToSearchEvent)="collapseResultPanel($event)"
                [hostLandingPageEnum]="studyLandingPageEnum">
              </mc-slot-availability>
            </div>
          </div>
        </div>
      </mc-preview>
    </div>

  </div>
</div>