<mc-header-bar [headerModel]="headerModel">
</mc-header-bar>
<div class="search-section form-page" [ngClass]="{ 'switchToHorizontal': previewModelHome.isVertical,'is-vertical-open': previewModelHome.isCollapsed  }">
    <div id="mainContainer" [ngClass]="{'switchVertical columns': previewModelHome.isVertical }">
      <div [ngClass]="{ 'column is-main-section-vertical': previewModelHome.isVertical,
        'is-6' : isExpandedVertical(), 'is-11' : isCollapsedVertical() }"
        class="main-section" id="main-content">      

    <div class="columns is-marginless form-content">
        <div class="column is-paddingless is-12">
            <div class="nav-return-bar">
                <a (click)="cancel()">
                    <span class="icon">
                        <i class="fas fa-arrow-left"></i>
                    </span>
                    <span class="link-text">Back to Study Landing</span>
                </a>
            </div>
            <div class="columns is-marginless">
                <div class="column form-content no-bottom-padding">
                    <div class="reservation-section">
                        <div class="columns">
                            <div class="column is-2">
                                <div class="field is-option-div">
                                    <label class="label is-size-7">
                                        Reserved by
                                    </label>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <div class="control">
                                        {{ subjectReservation?.reservedBy?.firstName }} {{
                                        subjectReservation?.reservedBy?.lastName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Slot Group -->

                    <div class="reservation-section" *ngIf="showSlotGroup()">
                        <div class="section-heading">
                            <span> Slot Group </span>
                        </div>
                        <div>
                            <div class="columns" [id]="propertySlotGroup" #slotGroup>
                                <div class="column is-2">
                                    <div class="field is-option-div">
                                        <label class="label is-size-7"> Select Slot Group <span>*</span> </label>
                                    </div>
                                </div>

                                <div class="column">
                                    <div *ngIf="isSitesReadOnly">
                                        <span>
                                            {{subjectReservation?.studyReservationSlotGroup?.groupDescription}}
                                            </span>
                                    </div>
                                    <div *ngIf="!isSitesReadOnly" class="field">
                                        <div class="select control" *ngIf="studySlotGroups">
                                            <select
                                                (change)="slotChanged()"
                                                [(ngModel)]="subjectReservation.studyReservationSlotGroup" [compareWith]="compareSlotGroups"
                                                >
                                                <option [ngValue]=null selected>Select..</option>
                                                <option
                                                    *ngFor="let slot of studySlotGroups.studyReservationSlotGroups"
                                                    [ngValue]="slot">
                                                    {{slot.groupDescription}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Participation Info  -->
                    <div>
                        <div class="reservation-section">
                            <div class="section-heading">
                                <span> Participation Info </span>
                            </div>
                            <div>
                                <div *ngFor="let item of siteLevelList; let i=index">
                                    <div *ngIf="item.required" class="columns" #siteInfo>
                                        <ng-container *ngIf="isSiteLevel(item, SITE_CODE_CREDIT_GROUP) ">
                                            <div class="field is-option-div" class="column is-2">
                                                <label class="label is-size-7">
                                                    {{item.siteLevel.name}} <span>*</span>
                                                </label>
                                            </div>
                                            <div class="column">
                                                <div *ngIf="isSitesReadOnly">
                                                    <span>
                                                      {{getSiteName(item, i)}}
                                                    </span>
                                                    
                                                  </div>
                                                <div *ngIf="!isSitesReadOnly">
                                                    <div *ngIf="!isNonStdCreditGroup()">
                                                        <div class="select control">
                                                            <select [(ngModel)]="subjectReservation.organizations[i]"
                                                                (change)="creditGroupSelected(i)"
                                                                [compareWith]="compareFnCg" >
                                                                <option [ngValue]="null" selected>Select..</option>
                                                                <option
                                                                    *ngFor="let singleOrg of sortOrgList(creditGroupOrgList)"
                                                                    [ngValue]="singleOrg">
                                                                    {{singleOrg.name}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="isNonStdCreditGroup()">
                                                        <div class="select control">
                                                            <select [(ngModel)]="subjectReservation.organizations[i]"
                                                                (change)="creditGroupSelected(i)"
                                                                  [compareWith]="compareFnNonStd"> 
                                                                <option [ngValue]="null" selected>Select....</option>
                                                                <option
                                                                    *ngFor="let singleOrg of sortOrgList(creditGroupOrgList)"
                                                                    [ngValue]="singleOrg">
                                                                    {{singleOrg.name}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container
                                            *ngIf="isSiteLevel(item, SITE_CODE_MEMBERSHIP) && !isNonStdCreditGroup() ">
                                            <div class="field is-option-div" class="column is-2">
                                                <label class="label is-size-7">
                                                    {{item.siteLevel.name}} <span>*</span>
                                                </label>
                                            </div>
                                            <div class="column">
                                                <div *ngIf="isSitesReadOnly">
                                                    <span>
                                                      {{getSiteName(item, i)}}
                                                    </span>
                                                    
                                                  </div>
                                                <div *ngIf="!isSitesReadOnly">
                                                    <div class="select control">
                                                        <select [(ngModel)]="subjectReservation.organizations[i]"
                                                            (change)="membershipSelected(i)"
                                                            [compareWith]="compareFnMember">
                                                            <option [ngValue]="null" selected>Select..</option>
                                                            <option
                                                                *ngFor="let singleOrg of sortOrgList(membershipOrgList)"
                                                                [ngValue]="singleOrg">
                                                                <ng-template [ngIf]="singleOrg">
                                                                    {{singleOrg.name}}
                                                                </ng-template>
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="isSiteLevel(item, SITE_CODE_TREATING_LOCATION) ">
                                            <div class="field is-option-div" class="column is-2">
                                                <label class="label is-size-7">
                                                    {{item.siteLevel.name}} <span>*</span>
                                                </label>
                                            </div>
                                            <div class="column">
                                                <div *ngIf="isSitesReadOnly">
                                                    <span>
                                                      {{getSiteName(item, i)}}
                                                    </span>
                                                    
                                                  </div>
                                                <div *ngIf="!isSitesReadOnly">
                                                    <div *ngIf="!isNonStdCreditGroup()">
                                                        <div class="select control">
                                                            <select [(ngModel)]="subjectReservation.organizations[i]"
                                                                (change)="treatingLocationSelected(i)"
                                                                [compareWith]="compareFnTl">
                                                                <option [ngValue]="null" selected>Select..</option>
                                                                <option
                                                                    *ngFor="let singleOrg of sortOrgList(treatingLocationOrgList)"
                                                                    [ngValue]="singleOrg">
                                                                    <ng-template [ngIf]="singleOrg">
                                                                        {{singleOrg.name}}
                                                                        <!-- -{{singleOrg.caseOrganizationId}} -->
                                                                    </ng-template>
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isNonStdCreditGroup()">
                                                        <div class="ctep-pad">
                                                            <input [(ngModel)]="ctepTL" type="text"
                                                                placeholder="CTEP ID"
                                                                (change)="getNonStdSiteInformation(i)" id="ctepTL"
                                                                class="ctep-input" />
                                                            <span class="ctep-name-pad">{{getNonStdTLName()}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>

                            <div class="columns" [id]="propertySiteContact" #participatingPersons>
                                <ng-container>
                                    <div class="field is-option-div" class=" column is-2">
                                        <label class="label is-size-7">
                                            Site Contact <span>*</span>
                                        </label>
                                    </div>
                                    <div class="column ">
                                        <div *ngIf="isReadOnly">
                                            {{getPersonName(subjectReservation.contactStaff)}}
                                        </div>
                                        <div *ngIf="!isReadOnly">
                                            <div class="select control">
                                                <select [(ngModel)]="subjectReservation.contactStaff"   [compareWith]="comparePersonFn">
                                                    <option [ngValue]=blankPerson selected>Select..</option>
                                                    <option *ngFor="let person of siteContacts" [ngValue]="person">
                                                        <ng-template [ngIf]="person">
                                                            {{(getPersonData(person))}}
                                                        </ng-template>
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <!-- Subject Information  -->

                        <div class="reservation-section">
                            <div class="section-heading">
                                <span> Subject Information </span>
                            </div>
                            <div >
                                <div>
                                    <div class="columns" [id]="propertyPiLastName" #patientInfo>
                                        <div class="column is-2">
                                            <div class="field is-option-div">
                                                <label class="label is-size-7"> Last Initial <span>*</span> </label>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <div class="field">
                                                <div class="control">
                                                    <input type="text" maxlength="1" placeholder="Initial" id="lastname" [readonly] = "isReadOnly"
                                                        class="input patient-input"
                                                        [(ngModel)]="subjectReservation.patientInfo.lastName" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="columns"  [id]="propertyPiFirstName" #patientInfo>
                                        <div class="column is-2">
                                            <div class="field is-option-div">
                                                <label class="label is-size-7"> First Initial <span>*</span> </label>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <div class="field">
                                                <div class="control">
                                                    <input type="text" maxlength="1" placeholder="Initial" id="firstName" [readonly] = "isReadOnly"
                                                        class="input patient-input"
                                                        [(ngModel)]="subjectReservation.patientInfo.firstName" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="columns"  [id]="propertyPiMiddleName" #patientInfo>
                                        <div class="column is-2">
                                            <div class="field is-option-div">
                                                <label class="label is-size-7">
                                                    Middle Initial
                                                </label>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <div class="field">
                                                <div class="control">
                                                    <input type="text" maxlength="1" placeholder="Initial" id="middlename" [readonly] = "isReadOnly"
                                                        class="input patient-input"
                                                        [(ngModel)]="subjectReservation.patientInfo.middleName" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="columns" [id]="propertyPiDOB" #patientInfo>
                                        <div class="column is-2">
                                            <div class="field is-option-div">
                                                <label class="label is-size-7">
                                                    Date of Birth <span>*</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <div *ngIf="isReadOnly">
                                                {{subjectReservation.patientInfo.dateOfBirthString }}
                                            </div>
                                            <div *ngIf = "!isReadOnly" class="field">
                                                <div class="control">
                                                    <mc-date-picker-wrapper
                                                        [inputDate]="subjectReservation.patientInfo.dateOfBirthString"
                                                        (dateSelected)="setDOBData($event)"> </mc-date-picker-wrapper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="reservation-section reservation-details" *ngIf="isSitesReadOnly">
                            <div class="section-heading">
                                <span> Reservation Details </span>
                            </div>
                            <table class="table is-fullwidth">
                              <thead>
                                <tr>
                                  <th>
                                    <b>
                                      <span>Reservation ID</span>
                                    </b>
                                  </th>
                                  <th>
                                    <b>
                                      <span>Slot Group Reservation Description</span>
                                    </b>
                                  </th>
                                  <th>
                                    <b>
                                      <span>Date Entered Current State</span>
                                    </b>
                                  </th>
                                  <th>
                                    <b>
                                      <span>Expiration Date</span>
                                    </b>
                                  </th>
                                  <th>
                                    <b>
                                      <span>Current State</span>
                                    </b>
                                  </th>
                                  <th>
                                    <b>
                                      <span>Registration Subject ID</span>
                                    </b>
                                </th>
                        
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container >
                                  <tr>
                                    <td>{{subjectReservation?.subjectReservationId}}</td>
                              
                                    <td>{{subjectReservation?.studyReservationSlotGroup?.groupDescription}}</td>
                                
                                    <td>{{subjectReservation?.reservationStatusDate  | date:'M/d/yyyy'}}</td>
                                  
                                    <td>{{subjectReservation?.expirationDate |  date:'M/d/yyyy'}}</td>
                                 
                                    <td>{{subjectReservation?.reservationStatus?.name}}</td>
                    
                                    <td>{{subjectReservation?.subjectRegistrationId}}</td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                        
                          </div>
                        <div class="reservation-section">
                            <div class="reservation-tiles" id="initialTile">
                                <div class="columns" #reservationTile>
                                    <div class="column is-3">
                                        <div class="tile is-ancestor">
                                            <!-- Resrvation tile -->
                                            <div class="tile is-4 is-parent reg-tile has-no-left-margin">
                                                <div class="tile is-child box is-radiusless">
                                                    <!-- <div class="color-bar" [ngClass]="{
                                                 'peacock-blue' : !isValidateStatus(statusError),
                                                  'red':isValidateStatus(statusError)}"></div> -->
                                                    <div class="color-bar" [ngClass]="{
                                                    'peacock-blue' : !isValidateStatus(statusError),
                                                    'red':isValidateStatus(statusError)}"></div>
                                                    <div class="content-div content-div-r">
                                                        <p class="title is-5 has-text-weight-bold">{{getTileName()}}
                                                        </p>
                                                        <p *ngIf="tileStatus" class="title is-3 has-text-weight-bold">
                                                            {{getReservationTitle()}}</p>
                                                        <p *ngIf="tileStatus" class="subtitle is-6">
                                                            {{getReservationSubtitle()}}</p>

                                                        <button type="button" (click)="reserve()"
                                                            class="button is-primary is-radiusless"
                                                            [attr.disabled]="(isReserveButtonDisabled()) ? '' : null"
                                                            *ngIf="!isReservationUpdate && !isValidateStatus(statusError)">
                                                            <span class="icon">
                                                                <i class="fas fa-check" aria-hidden="true"></i>
                                                            </span>
                                                            <span>Reserve</span>
                                                        </button>

                                                        <button type="button" (click)="reserve()"
                                                            class="button is-primary is-radiusless"
                                                            [disabled] = "!isDataModified()"
                                                            *ngIf="isReservationUpdate && !isValidateStatus(statusError)">
                                                            <span class="icon">
                                                                <i class="fas fa-check" aria-hidden="true"></i>
                                                            </span>
                                                            <span>Update</span>
                                                        </button>


                                                        <button type="button" (click)="cancel()"
                                                            class="button is-primary is-radiusless"
                                                            [disabled] = "!isDataModified()"
                                                            *ngIf="!isValidateStatus(statusError)">
                                                            <span class="icon">
                                                                <i class="fas fa-times" aria-hidden="true"></i>
                                                            </span>
                                                            <span>Cancel</span>
                                                        </button>
                                                        <button type="button" (click)="reviewErrors()"
                                                            class="button is-primary is-radiusless"
                                                            *ngIf="isValidateStatus(statusError)">
                                                            <span class="icon">
                                                                <i class="fas fa-exclamation" aria-hidden="true"></i>
                                                            </span>
                                                            <span>Review Errors</span>
                                                        </button>

                                                        <button type="button" (click)="overrideErrors()" 
                                                            [mcCanNavigate]="'ACTIVITY_PATIENT_RESERVATION_VALIDATION_OVERRIDE'" accessType="update"
                                                            class="button is-primary is-radiusless"
                                                            *ngIf="isValidateStatus(statusError)">
                                                            <span class="icon">
                                                                <i class="fas fa-exclamation" aria-hidden="true"></i>
                                                            </span>
                                                            <span>Overide Errors</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column is-3">
                                        <div *ngIf="isValidateStatus(statusCompleted)">
                                            <div class="tile is-ancestor">
                                                <div class="tile is-4 is-parent reg-tile has-no-left-margin">
                                                    <div class="tile is-child box is-radiusless">
                                                        <div class="color-bar" [ngClass]="{'peacock-blue' : true }">
                                                        </div>
                                                        <div class="content-div content-div-r">
                                                            <p class="title is-5 has-text-weight-bold">GO TO </p>

                                                            <button type="button" (click)="gotoRegisterSubject()"
                                                                class="button is-primary is-radiusless">
                                                                <span class="icon ">
                                                                    <i class="fas fa-arrow-left" aria-hidden="true"></i>
                                                                </span>
                                                                <span>Register Subject</span>
                                                            </button>
                                                            <button type="button" (click)="gotoStudyLanding()"
                                                                class="button is-primary is-radiusless">
                                                                <span class="icon ">
                                                                    <i class="fas fa-arrow-left" aria-hidden="true"></i>
                                                                </span>
                                                                <span>Study Landing</span>
                                                            </button>
                                                            <button type="button" (click)="gotoManageReservations()"
                                                                class="button is-primary is-radiusless">
                                                                <span class="icon ">
                                                                    <i class="fas fa-arrow-left" aria-hidden="true"></i>
                                                                </span>
                                                                <span>Manage Reservations</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="isReservationUpdate ">
                                            <div class="tile is-ancestor">
                                                <div class="tile is-4 is-parent reg-tile has-no-left-margin">
                                                    <div class="tile is-child box is-radiusless">
                                                        <div class="color-bar" [ngClass]="{'peacock-blue' : true }">
                                                        </div>
                                                        <div class="content-div content-div-r">
                                                            <p class="title is-5 has-text-weight-bold">Manage Reservation State </p>
                                                            <p  class="subtitle is-6">
                                                                Reservation ID:  {{subjectReservation.subjectReservationId}}</p>

                                                            <button type="button" 
                                                                class="button is-primary is-radiusless" (click)="removeReservation()"
                                                                [attr.disabled]="!(isCurrentStateReserved() && !this.isDataModified()) ? '' : null">
                                                                <span class="icon ">
                                                                    <i class="fas fa-trash" aria-hidden="true"></i>
                                                                </span>
                                                                <span>Remove</span>
                                                            </button>
                                                            <button type="button"
                                                                class="button is-primary is-radiusless" (click)="renew()"
                                                                [attr.disabled]="!isRenewButtonEnabled() ? '' : null">
                                                                <span class="icon ">
                                                                    <i class="fa fa-retweet" aria-hidden="true"></i>
                                                                </span>
                                                                <span>Renew</span>
                                                            </button>
                                                            <button type="button" (click)="gotoRegisterSubject()"
                                                                class="button is-primary is-radiusless"
                                                                [attr.disabled]="!(isCurrentStateReserved() && !this.isDataModified()) ? '' : null">
                                                                <span class="icon ">
                                                                    <i class="fas fa-user-circle" aria-hidden="true"></i>
                                                                </span>
                                                                <span>Register</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isReservationComplete()" class="preview-section" id="previewSection" [ngClass]="{ 'togglePerviewOverlay' : isExpandedHorizontal(),
'is-6 preview-vertical-expanded' : isExpandedVertical(),
'is-1' : isCollapsedVertical(), 'column ': previewModelHome.isVertical }">
    <mc-preview [formTitle]="formTitle" [formCaption]="previewTitle"
        [previewModel]="previewModelHome" #previewSection>
        <div preview-data>
            <div class="form-preview">
                <mc-reservation-confirmation 
                [subjectReservation]="subjectReservation" 
                [siteLevelList]="siteLevelList" 
                [studyNumber]="studyNumber"
                [studyDesc]="studyDesc">
                </mc-reservation-confirmation>
            </div>
        </div>
    </mc-preview>
</div>