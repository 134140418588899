import { ElementRef } from "@angular/core";
import jsPDF, { jsPDFOptions } from "jspdf";
import autoTable, { Cell, CellHookData, Column } from "jspdf-autotable";

export class PDFGenerator {

    constructor(
        private content: ElementRef<any>,
        private title: string,
        private subtitle?: string
    ) { }

    /**
     * Using the title and content passed in the constructor, this method creates 
     * a PDF containing the HTML content. The PDF is saved automatically.
     */
    generatePDF() {
        const jsPdfOptions: jsPDFOptions = {
            orientation: 'l',
            unit: 'px',
            format: 'a4'
        };

        let pdf = new jsPDF(jsPdfOptions);
        pdf = pdf.setFontSize(20);
        pdf = this.addTitleText(pdf);
        if (this.subtitle && this.subtitle.length > 0) {
            pdf = this.addSubtitleText(pdf);
        }
        pdf = this.addDateText(pdf);

        const tables: NodeList = this.content.nativeElement.querySelectorAll('div:not(.is-hidden) > div > table');
        if (tables.length === 1) {
            this.generatePDFFromTable(pdf);
        } else {
            this.generatePDFFromHTML(pdf);
        }
    }

    private generatePDFFromTable(pdf: jsPDF) {
        const tableEl: HTMLTableElement = this.content.nativeElement.querySelector('table') as HTMLTableElement;
        autoTable(pdf, {
            html: tableEl,
            margin: {
                horizontal: 5,
                vertical: 10
            },
            startY: this.subtitle && this.subtitle.length > 0 ? 90 : 70,
            rowPageBreak: 'avoid',
            styles: {
                fontSize: 8,
                cellPadding: 5
            },
            headStyles: {
                fillColor: '#003da5',
                cellWidth: "wrap"
            },
            didParseCell: (data: CellHookData) => {
                this.didParseCell(pdf, data.cell, data.column);
                data.cell.text = data.cell.text.map(t => t.trim()); // Removes leading and trailing whitespace from text that causes weird indentation issue for wrapped text
            }
        });

        pdf.save(`${this.title}.pdf`);
    }

    /**
     * Prevent AutoTable from breaking lines in the middle of words
     * by setting the minWidth to the longest word in the column
     */
    private didParseCell(doc: jsPDF, cell: Cell, column: Column) {
        if (cell === undefined) {
            return;
        }

        const hasCustomWidth = (typeof cell.styles.cellWidth === 'number');

        if (hasCustomWidth || cell.raw == null || cell.colSpan > 1) {
            return;
        }

        let text;

        if (cell.raw instanceof Node) {
            text = cell.raw.innerText;
        } else {
            if (typeof cell.raw == 'object') {
                // not implemented yet
                // when a cell contains other cells (colSpan)
                return;
            } else {
                text = '' + cell.raw;
            }
        }

        // split cell string by spaces
        const words = text.split(/\s+/);

        // calculate longest word width
        const maxWordUnitWidth = words.map(s => Math.floor(doc.getStringUnitWidth(s) * 100) / 100).reduce((a, b) => Math.max(a, b), 0);
        const maxWordWidth = maxWordUnitWidth * (cell.styles.fontSize / doc.internal.scaleFactor);

        const minWidth = cell.padding('horizontal') + maxWordWidth;

        // update minWidth for cell & column

        if (minWidth > cell.minWidth) {
            cell.minWidth = minWidth;
        }

        if (cell.minWidth > cell.wrappedWidth) {
            cell.wrappedWidth = cell.minWidth;
        }

        if (cell.minWidth > column.minWidth) {
            column.minWidth = cell.minWidth;
        }

        if (column.minWidth > column.wrappedWidth) {
            column.wrappedWidth = column.minWidth;
        }
    }

    private generatePDFFromHTML(pdf: jsPDF) {
        const elements: HTMLCollection = this.content.nativeElement?.children[1]?.children;  // get the div with the actual content, skip the preview header
        if (!elements || elements.length === 0) {
            return;
        }

        let innerContent: HTMLElement;
        for (let i = 0; i < elements.length; i++) {
            const el = elements.item(i) as HTMLElement;
            if (!el.classList.contains('is-hidden')) {
                innerContent = el;
                break;
            }
        }

        if (!innerContent) {
            return;
        }

        const contentWidth: number = innerContent.scrollWidth > 0 ? innerContent.scrollWidth : innerContent.offsetWidth;
        pdf.html(innerContent, {
            x: 0,
            y: this.subtitle && this.subtitle.length > 0 ? 90 : 70,
            autoPaging: true,
            html2canvas: {
                scale: pdf.internal.pageSize.width / contentWidth
            },
            callback: () => {
                pdf.save(`${this.title}.pdf`);
            }
        });
    }

    private addTitleText(pdf: jsPDF): jsPDF {
        return pdf.text(this.title, (pdf.internal.pageSize.width / 2), 30, { align: 'center' });
    }

    private addSubtitleText(pdf: jsPDF): jsPDF {
        return pdf.text(this.subtitle, (pdf.internal.pageSize.width / 2), 50, { align: 'center' });
    }

    private addDateText(pdf: jsPDF): jsPDF {
        const dateStr: string = this.getDateString(new Date());
        return pdf.text(dateStr, (pdf.internal.pageSize.width / 2), this.subtitle && this.subtitle.length > 0 ? 70 : 50, { align: 'center' });
    };

    private getDateString(date: Date): string {
        const month = date.getMonth() + 1;
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        const timezone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        return `${month}/${day}/${year} ${hours}:${minutes} ${timezone}`;
    };
}