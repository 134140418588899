
export const EVENT_SAVE_ACTION = 'saveChanges';
export const EVENT_CANCEL_ACTION = 'cancelChanges';
export const EVENT_WARN_UNSAVED_CHANGES = 'warnChanges';
export const EVENT_DATA_VALID = 'dataValid';
export const EVENT_DATA_UPDATED = 'dataUpdated';
export const PATIENT_DATA_UPDATED = 'patientDataUpdated';
export const EVENT_DATA_RESET = 'eventDataReset';
export const EVENT_NAVIGATE = 'navigateToRoute';
export const DISPLAY_RECENT_STUDIES = 'recentStudies';
export const DISPLAY_AVAILABLE_STUDIES = 'availableStudies';
export const DISPLAY_RECENT_PATIENTS = 'recentPatients';
export const DISPLAY_RECENT_NOTIFICATIONS = 'recentNotifications';
export const NOTIFY_TYPE_NOTIFY = 'Notify';
export const EVENT_DATA_REFRESH = 'refresh';
export const DISPLAY_STUDY_ACCRUAL_RPT = 'reportStudyAcccual';


export const SEARCH_CRITERIA_TYPE_PROTOCOL = 'protocol';
export const SEARCH_CRITERIA_TYPE_PATIENT = 'patient';
export const SEARCH_CRITERIA_TYPE_TRACKING_NUMBER = 'trackingNumber';
export const SEARCH_CRITERIA_TYPE_SUBJECT = 'subject';
export const SEARCH_CRITERIA_TYPE_ACCRUED_PATIENT = 'accrued_patient';
export const SEARCH_CRITERIA_TYPE_ACTIVE_PATIENT = 'active_patient';
export const SEARCH_CRITERIA_TYPE_STUDY = 'study';
export const SEARCH_CRITERIA_TYPE_CONTACTS = 'contacts';
export const SEARCH_CRITERIA_TYPE_ANCILLARY = 'ancilary_studies';
export const SEARCH_CRITERIA_TYPE_DOSAGE = 'dosage';
export const SEARCH_CRITERIA_TYPE_SLOT_RESERVATIONS = 'slot_reservations';
export const SEARCH_CRITERIA_TYPE_SLOT_AVAILABILITY = 'slot_availability';
export const SEARCH_CRITERIA_TYPE_REPORT = 'report';



export const SEARCH_TYPE_ENUM_TEXT = 1001;
export const SEARCH_TYPE_ENUM_STUDIES = 1002;
export const SEARCH_TYPE_ENUM_PATIENTS = 1003;
export const SEARCH_TYPE_ENUM_APPROVALS = 1004;
export const SEARCH_TYPE_ENUM_ACCRUED_PATIENTS_FOR_STUDY = 1005;
export const SEARCH_TYPE_ENUM_ACTIVE_PATIENTS_FOR_STUDY = 1006;
export const SEARCH_TYPE_ENUM_ACTIVE_CONTACTS_FOR_STUDY = 1007;
export const SEARCH_TYPE_ENUM_ANCILLARY_STUDIES_FOR_STUDY = 1008;
export const SEARCH_TYPE_ENUM_STUDIES_AVAILABLE = 1009;
export const SEARCH_TYPE_ENUM_DOSAGE_FOR_STUDY = 1010;
export const SEARCH_TYPE_ENUM_SLOT_RESERVATIONS = 1011;
export const SEARCH_TYPE_ENUM_SLOT_AVAILABILITY = 1012;
export const SEARCH_TYPE_ENUM_STYACC_FOR_REPORT = 1050;


/** Enums tied to landing pages*************** */
export const LANDING_PAGE_ENUM_HOME = 11001;
export const LANDING_PAGE_ENUM_STUDY = 11002;
export const LANDING_PAGE_ENUM_PATIENT = 11002;
export const LANDING_PAGE_ENUM_REPORT = 11050;
export const LANDING_PAGE_ENUM_ANCILLARY_STUDY_INFO = 11051;
export const LANDING_PAGE_ENUM_SCHEMA_DASHBOARD = 11060;


/***** Section Ids for the different components on the Patient Registration***************** */

export const REG_SECTION_SLOT_GROUP = 'section1';
export const REG_SECTION_PARTICIPATION = 'section2';
export const REG_SECTION_PATIENT_INFO = 'section3';
export const REG_SECTION_CHECK = 'section4';
export const REG_SECTION_FORM = 'section5';
export const REG_SECTION_ADDITIONAL_OPTIONS = 'section6';
export const REG_SECTION_REGISTRATION = 'section7';





/**Constants for navigation query String parameter name/value  */
export const QS_PARAM_NAME_ACTION = 'action';
export const QS_PARAM_VALUE_TARGET = 'target';

export const QS_PARAM_ACTION_REGISTER = 'doRegister';
export const QS_PARAM_ACTION_CONTINUE_REGISTER = 'continueRegister';
export const QS_PARAM_ACTION_NEXT_EVENT = 'doNext';
export const QS_PARAM_ACTION_CONTINUE_NEXT = 'continueNext';
export const QS_PARAM_ACTION_EDIT = 'doEdit';
export const QS_PARAM_ACTION_VIEW = 'doView';

export const QS_PARAM_TARGET_PATIENT_INFO = 'patient-info';

/** Enums to download requested PDF's */
export const REVIEW_PATIENT = 101;
export const SIM_STUDY_PARAMS = 102;

/** Enums to download requested excel's */
export const ACCRUAL_PATIENT = 102;
export const ANCILLARY_ACCRUAL_PATIENT = 103;

/**Event codes */
export const DYNAMIC_ALLOCATION_EVENT_CODE = 'DYNAMIC_ALLOCATION';
