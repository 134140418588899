<div class="form-page">

    <div class="columns">
      <div class="column is-2 along-top">
        <span class="label-padding is-small">Parent Protocol:</span>
      </div>
      <div class=" column input-width along-top">
        <div class="field is-grouped ">
          <p class="control  " class="no-right-margin">
            <input placeholder="Parent Protocol" id="parent-protocol" [(ngModel)]="parentProtocol" class="form-control input is-small">
          </p>
          <button type="button" [disabled]="isParentProtocolEmpty()" class="button is-primary is-small is-radiusless" (click)="getAncillaryStudyData()">
            <span class="">
              Find
            </span>
          </button>
        </div>
  
      </div>
    </div>
    
    <div  class="columns">
        <div class="column is-2">
          <span class="label-padding is-small">Related Schema Event</span>
        </div>
        <div class=" column field">
          <div class="control input-width">
            <div class="select is-small" *ngIf="studyModel" >
              <select [(ngModel)]="studyModel.relatedStudySchemaEventId">
                  <option [value]=0 selected>-- Select --</option>
                <option *ngFor="let opt of ancillaryStudydisplay.studySchemaEnrollmentEvents" [value]="opt.studySchemaEventId">
                  {{opt.studySchemaEventLabel}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

    <div *ngIf="isOnlyCommitteesAvailable()" class="columns">
      <div class="column is-2">
        <span class="label-padding is-small">Unique Ancillary Study#:</span>
      </div>
      <div class=" column field">
        <div class="control input-width">
          <div class="select is-small">
            <select [(ngModel)]="selectedAncillaryStudy" (change)= "checkSelectedSysGeneratedOption()">
                <option [value]="undefined" selected>-- Select --</option>
              <option *ngFor="let opt of ancillaryStudyOptions" [value]="opt.listValue">
                {{opt.listLabel}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

 <!--- -------- Assign Reference number -------------------------- -->
  
 <div *ngIf="isAncillaryStudyOptionSelected()" class="assign-ref-margin">
    <div class="columns">
      <div class="column is-6 ">
        <span class="label-padding is-small">Assign Reference Number:</span>
      </div>
    </div>

    <div *ngIf="isEmptyCommittesOrSelectedTypeAsNo()">
      <div class="columns">
        <div class="column is-1 "></div>
        <div class="column is-narrow min-label-width">
          <span class="label-padding is-small">Reference Number:</span>
        </div>
        <div class=" column ">
          <div class="field-body  ">
            <div class="control  ">
              <input placeholder="Reference Number"  id="txt-disease-site"
               [(ngModel)]="studyModel.study.referenceNumber"
                class="form-control input is-small">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf = "isCommitteesAvailable()">
 
        <div class="columns">
          <div class="column is-1 "></div>
          <div class="column is-narrow min-label-width">
            <span class="label-padding is-small">{{ancillaryStudydisplay.ancillaryCommittees.sectionLabel}}:</span>
          </div>
          <div class=" column ">
            <div class="field-body  ">
              <div class="control">
                <div class="select is-small">
                  <select [(ngModel)]="selectedCommitteeCode" (change)="onSelectChange(ancillaryStudydisplay.ancillaryCommittees)">
                      <option [ngValue]="undefined" selected>-- Select --</option>
                    <option *ngFor="let opt of ancillaryStudydisplay.ancillaryCommittees.sectionLabelOptions" [ngValue]="opt">
                      {{opt.codeDesc}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
    
    <div class="columns" *ngIf="exampleReference">
        <div class="column is-1 "></div>
          <div class="column is-narrow min-label-width">
            <span class="label-padding is-small">Example:</span>
        </div>
        <div class=" column field">
            <div class="control example-padding">
            <p>{{exampleReference}}</p>
          </div>
        </div>
      </div>
  </div>
</div>

  
    <div class="columns ">
      <div class="column is-2">
        <span class="label-padding is-small">Short Title:</span>
      </div>
      <div class=" field column ">
        <div class="field-body ">
          <div class="field">
            <div class="control ">
              <input  name="shortdesc" id="txt-short-desc"
               [(ngModel)]="studyModel.study.shortDescription" class="form-control input description is-small">
            </div>
          </div>
        </div>
      </div>
      <div class="column is-1">
      </div>
  
    </div>
    <div class="columns">
      <div class="column is-2">
        <span class="label-padding is-small">Study Title:</span>
      </div>
      <div class=" column ">
        <div class="field  ">
          <p class="control ">
            <textarea  name="longdesc" id="txt-long-desc" rows="3" 
            [(ngModel)]="studyModel.study.longDescription" class="textarea is-small">
            </textarea>
          </p>
        </div>
      </div>
      <div class="column is-1"> </div>
  
    </div>
  
    <div class="columns">
      <div class="column is-2">
  
      </div>
      <div class="column">
        <button [disabled]="!isDataValidForSubmit()" class="button is-primary is-radiusless" (click)="createNewStudy()">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
          <span>Create New</span>
        </button>
        <button class="button is-cancel is-radiusless" (click)="cancelChanges()">
            <span class="icon">
              <i class="fas fa-ban"></i>
            </span>
            <span>Cancel</span>
          </button>
  
      </div>
    </div>
  </div>
  