import { Injectable, ComponentFactoryResolver } from '@angular/core';


import { ToastMessage, ToastPosition } from 'app/common/model/toast-message';
import { BaseService } from 'app/common/services/base-service';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { Subject } from 'rxjs';
// import {} from ./../model/toast-message

/**
 * Extends the BaseService to allow the common methods in the base
 * to be accessible
 */
@Injectable()
export class ToastService extends BaseService {

  messages: Array<ToastMessage>;
  timerRef: any;
  duration = 5000;
  sticky: boolean;

/**
 * Queue that holds the count of pending httpRequests
 */
  private pendingHttpSource = new Subject<number>();
  // Observable pendingHttp streams
/**
 * Observable stream that holds the pending httpCount
 */
  pendingHttpCountPosition$ = this.pendingHttpSource.asObservable();

// Observable toastMessagesCount source
  private toastMessageCountSource = new Subject<number>();
  // Observable toastMessage streams
  toastMessagesCount$ = this.toastMessageCountSource.asObservable();

  // Observable ToastPosition sources
  private messagePositionSource = new Subject<ToastPosition>();

  // Observable ToastPosition streams
  messagePosition$ = this.messagePositionSource.asObservable();

  constructor(cfr: ComponentFactoryResolver) {
    super(cfr);
    this.messages = new Array<ToastMessage>();
  }
  // Service message commands
  /**
   * Sets the next number count of pending http requests
   * @param count : number that sets the next number in the queue
   */
  setPendingHttpCount(count: number) {
    this.pendingHttpSource.next(count);
  }
  // Service message commands
  setToastMessageCount(tMessageCount: number) {
    this.toastMessageCountSource.next(tMessageCount);
  }

  // Service message commands
  setMessagePosition(position: ToastPosition) {
    this.messagePositionSource.next(position);
  }

  /**
   * Returns the messages array
   */
  getMessages(): Array<ToastMessage> {
    return this.messages;
  }

  /**
   * Send the message for display as a toast or a modal
   * @param content : content for the message to be displayed
   * @param style : class or style for the message to be displayed
   * @param position : the postion (if any) where the message is to be displayed
   * @param isSticky : true if the message is sticky
   * @param modalDialogArg : modalArg containing details if the modal is to be displayed
   */
  sendMessage(content: string, style: string,
    position: ToastPosition, isSticky: boolean = false, modalDialogArg: ModalDialogArg= null) {
      let  isMsgDuplicate = false;
      for (let i = 0; i < this.messages.length; i++) {
        if (this.messages[i].content == content) {
          isMsgDuplicate = true;
        }
     }
     if (isMsgDuplicate == false) {
        const key = 'Id' + new Date().getMilliseconds();
        const message = new ToastMessage(key, content, style, isSticky, modalDialogArg);
        this.messages.push(message);
        this.setToastMessageCount(this.messages.length);
        if (position == null) {
          position = new ToastPosition(0, 0);
        // position = new ToastPosition(30, 50);
        }
        this.setMessagePosition(position);
        this.initTimer();
    }

  }
/**
 * Remove the message from the messageArray
 * @param messageKey The key to locate the message and remove from the array
 */
  dismissMessage(messageKey) {
    const match = this.messages.findIndex(msg => msg.key == messageKey);
    if (match !== null && match > -1) {
        this.messages.splice(match, 1);
    }
    if (this.messages.length == 0) {
      this.closeTimer();
    }
  }

  /**
   * Initializes the timer. The timer is used to count down to the specified interval
   * and when the timer is hit removes the last non-sticky message from the queue
   */
  private initTimer() {
    if (!this.timerRef || this.timerRef.state == 'notScheduled') {
      this.timerRef = setInterval(() => {
        if (this.messages.length > 0) {
          // find the last message in the array
          const match = this.messages[this.messages.length - 1];
          // remove it if it's not marked as sticky
          if (!match.sticky) {
            this.dismissMessage(match.key);
          }
        } else {
          this.closeTimer();
        }
      }, this.duration);
    }
  }

  /**
   * Cleanup - to ensure that the timer is closed after completion
   */
  private closeTimer() {
    if (this.timerRef) {
      clearInterval(this.timerRef);
      this.timerRef
    }
  }


}
